import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { UserGroup } from '../../../../models/user-group';


import * as _ from 'lodash';
import { UserContactService } from '../user-contact/user-contact.service';
import { SocketService } from '../../socket/socket.service';
import { LoggerService } from '../../../../utilities/logger/logger.service';
import { UtilitiesService } from '../../../../utilities/service/utilities.service';
import { PresenceTypeConstant } from '../../../../constants/presence-type.constant';
import { AMQPRoutingKey } from '../../../../constants/amqp-routing-key.constant';
import { TeamNoteGeneralConstant } from '../../../../constants/general.constant';
import { FileManagerService } from '../../../../utilities/file-manager/file-manager.service';

interface UserGroups {
  [userGroupId: string]: UserGroup;
}

@Injectable()
export class UserGroupService {
  userGroups: UserGroups = {};
  userGroups$: BehaviorSubject<UserGroup[]> = new BehaviorSubject<UserGroup[]>([]);

  constructor(
    private _fileManagerService: FileManagerService,
    private _userContactService: UserContactService,
    private _socketService: SocketService,
    private _loggerService: LoggerService,
    private _utilitiesService: UtilitiesService
  ) { }

  initUserGroups(): void {
    this._loggerService.debug("Reset userGroups");
    this.userGroups = {};
    this.updateUserGroupSubject();
  }

  receiveUserGroupPresence(ug: UserGroup): void {
    switch (ug.t) {
      case PresenceTypeConstant.USER_GROUP:
        this.insertOrUpdateUserGroup(ug);
        break;
      case PresenceTypeConstant.USER_GROUP_ABSENCE:
        this.deleteUserGroupByUserGroupId(ug.user_group_id);
        break;
    }
  }
  updateUserGroupSubject(): void {
    this.userGroups$.next(this.getAllUserGroups());
  }
  insertOrUpdateUserGroup(ug: UserGroup): void {
    this.userGroups[ug.user_group_id] = _.assign(this.userGroups[ug.user_group_id], ug);

    if (!this.checkIfUserGroupisUnderUserGroup(ug.user_group_id, TeamNoteGeneralConstant.ROOT_USER_GROUP_ID)) {
      return;
    }

    this.getMissingContactsAndUserGroupContactsUnderUserGroupByUserGroupId(ug.user_group_id);
  }
  deleteUserGroupByUserGroupId(userGroupId: string): void {
    delete this.userGroups[userGroupId];
  }

  getUserGroupByUserGroupId(userGroupId: string): UserGroup {
    return this.userGroups[userGroupId];
  }
  getAllUserGroups(): UserGroup[] {
    return _.toArray(this.userGroups);
  }

  getMissingUserGroupContact(userGroupIds: string[], callback?: Function) {
    let missings = [];
    _.each(userGroupIds, (ugid) => {
      if (!this.getUserGroupByUserGroupId(ugid)) {
        missings.push(ugid);
      }
    });
    if (missings.length > 0) {
      this.getUserGroupContact(missings, callback);
    }
  }

  checkIfUserIsUnderUserGroupRecursive(targetUserId: string, parentUserGroupId: string, isFirstLayerOnly?: boolean): boolean {
    let parentGroup = this.getUserGroupByUserGroupId(parentUserGroupId);
    if (!parentGroup) {
      return false;
    }
    for (let memberIndex in parentGroup.members) {
      if (parentGroup.members[memberIndex] == targetUserId) {
        return true;
      }
    }
    if (!isFirstLayerOnly) {
      for (let childIndex in parentGroup.children) {
        if (this.checkIfUserIsUnderUserGroupRecursive(targetUserId, parentGroup.children[childIndex])) {
          return true;
        }
      }
    }

    return false;
  }

  checkIfUserGroupisUnderUserGroup(targetUserGroupId: string, parentUserGroupId: string): boolean {
    if (targetUserGroupId === parentUserGroupId) {
      return true;
    }
    let parentGroup = this.getUserGroupByUserGroupId(parentUserGroupId);
    if (!parentGroup) {
      return false;
    }
    for (let childIndex in parentGroup.children) {
      if (parentGroup.children[childIndex] == targetUserGroupId) {
        return true;
      }
    }
    for (let childIndex in parentGroup.children) {
      if (this.checkIfUserGroupisUnderUserGroup(targetUserGroupId, parentGroup.children[childIndex])) {
        return true;
      }
    }
    return false;
  }

  getMissingContactsAndUserGroupContactsUnderUserGroupByUserGroupId(userGroupId: string): void {
    let ug = this.getUserGroupByUserGroupId(userGroupId);
    if (ug) {
      this._userContactService.getMissingContacts(ug.members);
      this.getMissingUserGroupContact(ug.children);
    }
  }

  getAllUserGroupIdsUnderUserGroupByUserGroupId(userGroupId: string): string[] {
    let ug = this.getUserGroupByUserGroupId(userGroupId);
    let childIds = [];
    if (!ug) {
      return childIds;
    }
    _.each(ug.children, (child) => {
      childIds.push(child);
      let childGroupIds = this.getAllUserGroupIdsUnderUserGroupByUserGroupId(child);
      childIds = _.union(childIds, childGroupIds);
    });
    return childIds;
  }

  // AMQP
  getUserGroupContact(ugids: string[], callback?: Function): void {
    if (ugids.length == 0) {
      this._loggerService.warn("Trying to get user group contact without user group ids, rejected.");
      return;
    }
    let chunks = this._utilitiesService.getChunkedMessageArr(ugids);
    _.each(chunks, (c) => {
      this.getUserGroupContactRequest(c, callback);
    });
  }
  getUserGroupContactRequest(ugids: string[], callback?: Function): void {
    let ugidString = _.uniq(ugids).join(' ');
    let correlationId = 'GET_USERGROUP_CONTACT_' + _.now();
    let routingKey = AMQPRoutingKey.GET_USERGROUP_CONTACT;
    let headers = {
      "correlation-id": correlationId,
      user_group_id: ugidString
    };
    let body = '';
    this._socketService.sendMessage(routingKey, headers, body, correlationId, callback);
  }
}
