import { Component, OnInit } from '@angular/core';

import { UserContact } from '../../models/user-contact';

import { AccountManagerService } from '../services/account/account-manager.service';
import { UserContactService } from '../services/data/user-contact/user-contact.service';

import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FileManagerService } from '../../utilities/file-manager/file-manager.service';
import { FileFactoryService } from '../../utilities/file-factory/file-factory.service';
import { ModuleManagerService } from '../services/module/module-manager.service';
import { ModuleKeyDefinition } from '../../constants/module.constant';

@Component({
  selector: 'tn-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  loggedInUser: UserContact;
  avatarImageSrc: string;

  file: File;

  isEditing: boolean = false;
  publicMsgInput: string;

  isUserPicEditProhibit: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ProfileComponent>,
    private _accountManagerService: AccountManagerService,
    private _fileManagerService: FileManagerService,
    private _userContactService: UserContactService,
    private _fileFactoryService: FileFactoryService,
    private _moduleManagerService: ModuleManagerService
  ) { }

  ngOnInit() {
    this.dialogRef.updateSize('50vw');

    this._accountManagerService.selfContactPresence$.subscribe(user => {
      this.loggedInUser = user;
      this.parseUser();
    });

    this.isUserPicEditProhibit = this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.USER_PIC_EDIT_PROHIBIT);
  }

  parseUser() {
    if (!this.loggedInUser) {
      return;
    }
    if (this.loggedInUser.pic && this.loggedInUser.pic != 'None') {
      this._fileFactoryService.getFileByAttachmentId(
        this.loggedInUser.pic,
        (imageUrl) => {
          this.avatarImageSrc = imageUrl;
        },
        (err) => {},
        false,
        false,
        true
      );
    }
    this.publicMsgInput = this.loggedInUser.public_message;
  }

  handleFileInputChange(e): void {
    if (this.isUserPicEditProhibit) {
      return;
    }
    this.file = e.target.files[0];
    this.uploadChatPic();
  }
  uploadChatPic(): void {
    this._fileManagerService.uploadFileHub(
      this.file,
      (fileId) => {
        this._userContactService.updateSelfProfilePic(fileId);
      },
      false
    );
  }

  startEditing(): void {
    this.isEditing = true;
  }
  savePublicMsg(): void {
    this._userContactService.updateSelfPublicMessage(this.publicMsgInput);
    this.discardPublicMsg();
  }
  discardPublicMsg(): void {
    this.isEditing = false;
    this.publicMsgInput = this.loggedInUser.public_message;
  }

  cancel() {
    this.dialogRef.close();
  }
}
