import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TeamnoteApiService } from '../../api/teamnote-api.service';
import { TnNotificationService } from '../tn-notification/tn-notification.service';
import { TeamnoteConfigService } from '../../configs/teamnote-config.service';
import { WebclientLoginService } from '../../login/webclient-login.service';
import { LoginMethod } from '../../login/models/login-methods-response';
import { LoginMethodConstant } from '../../login/constants/login-methods.constant';
import { LocalStorageManagerService } from '../local-storage/local-storage-manager.service';
import { TeamNoteLocalStorageKeyConstants } from '../../constants/local-storage-key.constant';

import * as _ from 'lodash';
import { OauthService } from '../../login/oauth.service';
import { SamlService } from '../../login/saml.service';
import { OAuthMeResponse } from '../../login/models/oauth-me-response';
import { AccountManagerService } from '../../webclient/services/account/account-manager.service';
import { SamlMeResponse } from '../../login/models/saml-me-response';
import { UtilitiesService } from '../service/utilities.service';

@Component({
  selector: 'tn-password-relogin',
  templateUrl: './password-relogin.component.html',
  styleUrls: ['./password-relogin.component.scss']
})
export class PasswordReloginComponent implements OnInit {

  @Input() titleTranslateKey: string;
  onLoginSuccess: Function;

  @Output() loginSuccess: EventEmitter<boolean> = new EventEmitter<boolean>(null);

  publicKey: string = null;
  passwordInput: string;
  currentLoginMethod: LoginMethod = null;

  constructor(
    // public dialogRef: MatDialogRef<PasswordReloginComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private _teamnoteApiService: TeamnoteApiService,
    private _tnNotificationService: TnNotificationService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _webclientLoginService: WebclientLoginService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _oauthService: OauthService,
    private _samlService: SamlService,
    private _accountManagerService: AccountManagerService,
    private _utilitiesService: UtilitiesService
  ) { }

  ngOnInit() {
    // this.dialogRef.updateSize('50vw');
    // this.titleTranslateKey = this.data.titleTranslateKey;
    // this.onLoginSuccess = this.data.onLoginSuccess;
    this.getLoginMethods();
    this.getE2EEPublicKey();
  }

  cancel() {
    // this.dialogRef.close();
    this.loginSuccess.emit(false);
  }

  getE2EEPublicKey(): void {
    this._teamnoteApiService.getE2EEPublicKey(
      resp => {
        this.publicKey = resp;
      },
      err => {

      }
    );
  }

  inputKeyEnter(isWithShiftKey: boolean) {
    if (this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.SHIFT_ENTER_TO_SEND && isWithShiftKey) {
      this.relogin();
      return false;
    } 
    if (!this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.SHIFT_ENTER_TO_SEND && !isWithShiftKey) {
      this.relogin();
      return false;
    }
  }

  successCallback(): void {
    this.loginSuccess.emit(true);
    this.cancel();
  }

  failureCallback(isPassword?: boolean): void {
    if (isPassword) {
      this._tnNotificationService.showCustomErrorByTranslateKey('LOGIN.PASSWORD.LOGIN_FAIL');
      return;
    }
    this._tnNotificationService.showCustomErrorByTranslateKey('LOGIN.PASSWORD.LOGIN_FAIL_AUTH');
  }

  relogin() {
    if (this.passwordInput && this.passwordInput.length > 0) {
      let encryptedPassword = null;
      let password = null;

      if (this.publicKey) {
        encryptedPassword = this._utilitiesService.getEncryptedValueByPublicKey(this.passwordInput, this.publicKey);
      } else {
        password = this.passwordInput;
      }

      this._teamnoteApiService.reLogin(
        () => {
          this.successCallback();
        },
        () => {
          this.failureCallback(true);
        },
        password,
        encryptedPassword,
        true, // need password when doing relogin
      );
    }
  }

  // Login methods
  getLoginMethods(): void {
    this._webclientLoginService.getLoginMethods(
      (resp: LoginMethod[]) => {
        let externalAuthName = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.AUTH_NAME);

        if (externalAuthName) {
          this.currentLoginMethod = _.find(resp, function (m) {
            return m.auth_name == externalAuthName;
          });
        } else {
          this.currentLoginMethod = null;
        }
      },
      (err) => {
        // Login methods are not available, allow usage of normal teamnote login
        this.currentLoginMethod = null;
      }
    );
  }

  onLoginMethodClick(method: LoginMethod): void {
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.AUTH_TYPE, method.auth_type);
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.AUTH_NAME, method.auth_name);

    switch (method.auth_type) {
      case LoginMethodConstant.AUTH_TYPE.OAUTH:
        this.handleOAuthMethod(method);
        break;
      case LoginMethodConstant.AUTH_TYPE.SAML:
        this.handleSamlMethod(method);
        break;
    }
  }

  onOauthReLogin(): void {
    this._oauthService.getUserNameWithOAuthToken(
      (resp: OAuthMeResponse) => {
        this._oauthService.clearOAuthToken();
        if (!resp || !resp.user_name) {
          this.failureCallback();
          return;
        }
        if (resp.user_name.toLowerCase() != this._accountManagerService.fullLoginResponse.user.user_name.toLowerCase()) {
          this.failureCallback();
          return;
        }
        this.successCallback();
      },
      (err) => {
        this._oauthService.clearOAuthToken();
        this.failureCallback();
      }
    );
  }

  onSamlReLogin(): void {
    this._samlService.getUserNameWithSamlResponse(
      (resp: SamlMeResponse) => {
        if (!resp || !resp.user_name) {
          this.failureCallback();
          return;
        }
        if (resp.user_name.toLowerCase() != this._accountManagerService.fullLoginResponse.user.user_name.toLowerCase()) {
          this.failureCallback();
          return;
        }
        this.successCallback();
      },
      (err) => {
        this.failureCallback();
      }
    );
  }

  handleOAuthMethod(method: LoginMethod): void {
    this._oauthService.goToOAuthEndpointByMethod(
      method, 
      () => this.onOauthReLogin(), 
      true
    );
  }

  handleSamlMethod(method: LoginMethod): void {
    this._samlService.goToSamlEndpointByMethod(
      method, 
      () => this.onSamlReLogin() 
    );
  }

}
