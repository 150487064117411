import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmojiMenuComponent } from './emoji-menu/emoji-menu.component';
import { EmojiService } from './emoji.service';
import { SearchBarModule } from '../search-bar/search-bar.module';
import { EmptyPageModule } from '../empty-page/empty-page.module';

@NgModule({
    imports: [
        CommonModule,
        SearchBarModule,
        EmptyPageModule
    ],
    declarations: [
        EmojiMenuComponent
    ],
    providers: []
})
export class EmojiModule { }
