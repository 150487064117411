import { Component, OnInit } from '@angular/core';

import * as _ from 'lodash';
import { ModuleKeyDefinition } from '../../constants/module.constant';
import { TeamnoteConfigService } from '../../configs/teamnote-config.service';
import { LocalStorageManagerService } from '../local-storage/local-storage-manager.service';
import { TeamNoteLocalStorageKeyConstants } from '../../constants/local-storage-key.constant';
import { RouteParamService } from '../route-param/route-param.service';
import { BaseRoutingService } from '../../routing/base-routing.service';
import { SideNavItem, SideNavService } from './side-nav.service';
import { TranslateManagerService } from '../../utilities/translate/translate-manager.service';
import { PageUrlConstant } from '../../constants/page-url.constant';
import { Router } from '@angular/router';

@Component({
  selector: 'tn-side-nav',
  templateUrl: './tn-side-nav.component.html',
  styleUrls: ['./tn-side-nav.component.scss']
})

export class TnSideNavComponent implements OnInit {
  sideNavItems: SideNavItem[];

  MODULE_KEYS = ModuleKeyDefinition;

  currentNavItemKey: string = null;

  constructor(
    private _teamnoteConfigService: TeamnoteConfigService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _routeParamService: RouteParamService,
    private _baseRoutingService: BaseRoutingService,
    private _sideNavService: SideNavService,
    private _translateManagerService: TranslateManagerService,
    private _router: Router,
  ) { 
    this.sideNavItems = [];

    this._sideNavService.sideNavItems$.subscribe(sideNavs => {
      this.sideNavItems = sideNavs;
      this.currentNavItemKey = this._sideNavService.currentActiveItemKey;

      this.updateSideNavModuleName();
    });

    // translate the side nav module name after switching language
    this._translateManagerService.currentTranslation$.subscribe(lang => {
      this.updateSideNavModuleName();
    });
  }

  ngOnInit() {
    this.restorePrevSideNav();
  }

  isCheckPlaceholder(module_name: string) {
    return module_name.charAt(0) === ' '
  }

  updateSideNavModuleName(): void {
    _.forEach(this.sideNavItems, (module) => {
      if (module._i18n) {
        let i18n = module._i18n[this._translateManagerService.getCurrentLangI18nKey()];
        
        if (i18n && i18n.side_menu_name !== '') {
          module.name = i18n.side_menu_name;
        }
      }
    })
  }

  restorePrevSideNav(): void {
    let prev = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE_MODULE);
    this.currentNavItemKey = prev ? prev : this._teamnoteConfigService.config.WEBCLIENT.GENERAL.WEBCLIENT_DEFAULT_PAGE;

    this._sideNavService.updateActiveSideNav(this.currentNavItemKey);
  }

  changeRoute(navItem: SideNavItem): void {
    this.currentNavItemKey = navItem.key;

    this._sideNavService.updateActiveSideNav(navItem.key);
    
    this._routeParamService.setRouteParam(navItem.params);
  }

  changeTeamflarePageRoute(navItem: SideNavItem): void {
    this.currentNavItemKey = navItem.key;

    this._sideNavService.updateActiveSideNav(navItem.key);
    
    this._routeParamService.setRouteParam(navItem.params);

    if (!navItem.url) {
      alert('TeamFlare URL is invalid')
      return
    }

    // route to path [API_HOST]/#/webclient/teamflare_app/https://xxx.com
    this._router.navigate(['../' + PageUrlConstant.WEBCLIENT.BASE + navItem.link.slice(1), navItem.url]);
  }
}
