import { Component, OnInit, Input } from '@angular/core';
import { StoreReportCustomFieldsDefinition } from '../../models/job-report';

@Component({
  selector: 'tn-field-title',
  templateUrl: './field-title.component.html',
  styleUrls: ['./field-title.component.scss']
})
export class FieldTitleComponent implements OnInit {

  @Input() fieldDefinition: StoreReportCustomFieldsDefinition;
  @Input() isInput: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
