<div class="alert-dialog">
  <div class="alert-msg text-color-primary">
    <ng-container *ngIf="data.msg">{{data.msg}}</ng-container>
    <ng-container *ngIf="data.msgTranslateKey">{{data.msgTranslateKey | translate}}</ng-container>
  </div>
  <div>
    <button class="btn tn-button-primary" (click)="buttonClick()">
      <ng-container *ngIf="data.buttonMsg">{{data.buttonMsg}}</ng-container>
      <ng-container *ngIf="data.buttonMsgTranslateKey">{{data.buttonMsgTranslateKey | translate}}</ng-container>
    </button>
  </div>
</div>