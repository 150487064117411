export const PresenceTypeConstant = {
  QUEUE_BIND: 0,

  USER_CONTACT: 1,

  INDIVIDUAL_CHAT: 2,
  GROUP_CHAT: 3,
  GROUP_BROADCAST: 4,

  CONTACT_ABSENCE: 6,
  CHAT_ABSENCE: 7,

  USER_GROUP: 101,
  USER_GROUP_ABSENCE: 102,

  USER_ONLINE: 201,
  USER_OFFLINE: 202,

  MUTE_CHAT: 301,
  MUTE_CHAT_ABSENSE: 302,

  WIPE_DEVICE: 369,

  NEWS_CHANNEL: 401,
  NEWS_CATEGORY: 402
};
