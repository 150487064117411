export const ZhChsContactStrings = {
  NAME: "名称",
  EMAIL: "电邮",
  DEPARTMENT: "部门",
  TITLE: "职位名称",
  PUBLIC_MESSAGE: "公开讯息",
  MOBILE: "手提电话号码",
  E_PROFILE: "E-Profile",

  DELETED_POSTFIX: "(已删除)"
};
