export const CustomFieldConstant = {
  CUSTOM_FIELD_TYPE: {
    MULTIPLE_CHOICE: 1,
    FREE_TEXT: 2,
    CHECKBOX: 3,
    DROPDOWN: 4,
    CONTACT: 5,
    ATTACHMENT_PHOTO: 6,
    DATE: 7,
    TIME: 8,
    GEOLOCATION_USER_INPUT: 9,
    GEOLOCATION_DETECTION: 10,
    CODE_SCAN: 11,
    TEXT_DISPLAY: 12,
    ATTACHMENT_DOCUMENT: 13,
    ATTACHMENT: 14,
    SIGNATURE: 15
  },
  LIST_FORMAT_MAGIC_STRING_PLACEHOLDER: {
    FIELD_ID: "%%fieldId?={{fieldId}}%%",
    LOCAL_TRANSLATE: {
      "%%imageOne%%": {
        key: "%%imageOne%%",
        translate: "UTILITY.CUSTOM_FIELD.LIST_FORMAT.IMAGE.ONE"
      },
      "%%imageOther%%": {
        key: "%%imageOther%%",
        translate: "UTILITY.CUSTOM_FIELD.LIST_FORMAT.IMAGE.OTHER"
      },
      "%%fileOne%%": {
        key: "%%fileOne%%",
        translate: "UTILITY.CUSTOM_FIELD.LIST_FORMAT.FILE.ONE"
      },
      "%%fileOther%%": {
        key: "%%fileOther%%",
        translate: "UTILITY.CUSTOM_FIELD.LIST_FORMAT.FILE.OTHER"
      },
      "%%userOne%%": {
        key: "%%userOne%%",
        translate: "UTILITY.CUSTOM_FIELD.LIST_FORMAT.USER.ONE"
      },
      "%%userOther%%": {
        key: "%%userOther%%",
        translate: "UTILITY.CUSTOM_FIELD.LIST_FORMAT.USER.OTHER"
      },
      "%%attachmentOne%%": {
        key: "%%attachmentOne%%",
        translate: "UTILITY.CUSTOM_FIELD.LIST_FORMAT.ATTACHMENT.ONE"
      },
      "%%attachmentOther%%": {
        key: "%%attachmentOther%%",
        translate: "UTILITY.CUSTOM_FIELD.LIST_FORMAT.ATTACHMENT.OTHER"
      },
      "%%signed%%": {
        key: "%%signed%%",
        translate: "UTILITY.CUSTOM_FIELD.LIST_FORMAT.SIGNATURE.SIGNED"
      },
      "%%notSigned%%": {
        key: "%%notSigned%%",
        translate: "UTILITY.CUSTOM_FIELD.LIST_FORMAT.SIGNATURE.NOT_SIGNED"
      }
    }
  }
}