import {Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild} from '@angular/core';
import {WatermarkService} from '../watermark/watermark.service';
import {ModuleManagerService} from '../../webclient/services/module/module-manager.service';
import {ModuleKeyDefinition} from '../../constants/module.constant';
import {FileManagerService} from '../file-manager/file-manager.service';
import { TeamnoteConfigService } from '../../configs/teamnote-config.service'
@Component({
  selector: 'tn-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {
  @Input() videoUrl: string;
  @Input() filename: string = null;
  @Input() attachmentId: string;
  @Input() isDisableWatermark: boolean = false;
  @ViewChild('videoWatermarkCanvas', {static: true}) videoWatermarkCanvas: ElementRef;
  @Input() isDisableDownload: boolean = false;

  isAllowAttachmentSave: boolean = false;

  context: CanvasRenderingContext2D;

  constructor(
    private _fileManagerService: FileManagerService,
    private _watermarkService: WatermarkService,
    private _moduleManagerService: ModuleManagerService,
    private _teamnoteConfigService: TeamnoteConfigService
  ) {
  }

  ngOnInit() {
    this.drawWatermark();
  }

  ngOnChanges() {
    this.isAllowAttachmentSave = this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.ATTACHMENT_SAVE);

    // Always allow attachment download
    if (this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.IS_ALWAYS_ALLOW_DOWNLOAD){
      this.isAllowAttachmentSave = true;
    }

    if (!this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.IS_DISABLE_ENCRYPTED_MSG_DOWNLOAD){
      this.isDisableDownload = false;
    }
  }

  drawWatermark(): void {
    if (this.isDisableWatermark) {
      return;
    }
    let canvas = this.videoWatermarkCanvas.nativeElement;
    let vgPlayer = canvas.nextElementSibling;
    let w = vgPlayer.clientWidth;
    let h = vgPlayer.clientHeight;
    canvas.width = w;
    canvas.height = h;
    this.context = canvas.getContext('2d');
    this._watermarkService.drawWatermark(this.context, w, h);
  }

  downloadVideo(): void {
    let filename = this.filename;

    if (!filename) {
      const components = this.attachmentId.split('.');
      const name = components[0];
      const extension = components[components.length - 1];

      filename = `${name}.${(new Date()).toISOString()}.${extension}`;
    }


    this._fileManagerService.downloadFileByAttachmentId(this.attachmentId, filename);
  }
}
