import { Component, OnInit, Input } from '@angular/core';
import { TeamNoteNotification, NotificationType } from '../models/notification';
import { NotificationCenterService } from '../services/notification-center.service';

@Component({
  selector: 'tn-notification-cell',
  templateUrl: './notification-cell.component.html',
  styleUrls: ['./notification-cell.component.scss']
})
export class NotificationCellComponent implements OnInit {

  @Input() notification: TeamNoteNotification;

  notificationTypes = NotificationType;

  constructor(
    private _notificationCenterService: NotificationCenterService
  ) { }

  ngOnInit() {
  }

  onNotificationClick(): void {
    this.notification.onClick();
    this._notificationCenterService.closeNotificationCenter();
  }
}
