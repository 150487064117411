import {Component, OnInit} from '@angular/core';
import {AccountService} from '../../account/account.service';

@Component({
  selector: 'tn-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {

  constructor(
    private _accountService: AccountService,
  ) {
  }

  ngOnInit() {
    window.location.replace('/download?token=' + this._accountService.fullLoginResponse.session_token);
  }
}
