<div class="material-ebook-container" *ngIf="ebook">
  <tn-page-title [title]="ebook.name">
    <div class="left-control" *ngIf="!isDialog">
      <div class="back-button clickable" (click)="ebookBack()">
        <i class="fa fa-fw fa-angle-left"></i>
      </div>
    </div>
    <div class="right-control">
      <div class="button font-size-button clickable" (click)="toggleFontSize()">
      </div>
    </div>
  </tn-page-title>
  <div class="ebook-page-content-wrapper">
    <div class="ebook-page-content">
      <p class="content" 
        [innerHTML]="pageHTMLContent" 
        [ngClass]="{'enlarged': isFontSizeEnlarged}">
      </p>
    </div>

    <div class="ebook-page-footer">
      <div class="control-wrapper language-control-wrapper">
        <div class="lang clickable" 
          *ngFor="let lang of audioLangs" 
          [ngClass]="{'text-color-primary': lang == currentLangKey}"
          (click)="selectAudioLang(lang)">
          {{'LANG.' + lang | translate}}
        </div>
      </div>

      <div class="control-wrapper play-control-wrapper">
        <div class="button play-button clickable" 
          (click)="playAudio()"
          *ngIf="!isPlayingAudio">
        </div>

        <div class="button pause-button clickable" 
          (click)="pauseAudio()"
          *ngIf="isPlayingAudio">
        </div>
      </div>

      <div class="control-wrapper page-control-wrapper">
        <div class="button prev-page-button clickable"
          (click)="goToPrevPage()"
          [ngClass]="{'button-hidden': currentPageNum <= 1}">
        </div>

        <div class="page-num-display">
          {{currentPageNum}} / {{ebook.pages.length}}
        </div>

        <div class="button next-page-button clickable"
          (click)="goToNextPage()"
          [ngClass]="{'button-hidden': currentPageNum == ebook.pages.length}">
        </div>

      </div>
    </div>
  </div>
</div>