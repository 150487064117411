<div 
  class="btn-group tn-dropdown-group notification-center-toggle-button-wrapper" 
  dropdown 
  #notificationCenterDropdown="bs-dropdown" 
  (isOpenChange)="onOpenChange($event)"
  placement="bottom right" 
  [autoClose]="true">
  <button dropdownToggle type="button" class="dropdown-toggle tn-dropdown-button notification-center-toggle-button">
    <i class="fa fa-bell-o fa-fw notification-icon"></i>
    <span class="unread-bubble notification-count" *ngIf="unreadCounts > 0">
      {{unreadCounts}}
    </span>
  </button>
  <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
    <li role="menuitem">
      <tn-notification-body
        [notifications]="notifications"
        (updateUnreadCount)="updateUnreadCount()">
      </tn-notification-body>
    </li>
  </ul>
</div>