import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store, StoreReportType, StoreReportCustomRouteParam } from '../models/job-report';
import { JobReportService } from '../job-report.service';

import * as _ from 'lodash';

@Component({
  selector: 'tn-report-option',
  templateUrl: './report-option.component.html',
  styleUrls: ['./report-option.component.scss']
})
export class ReportOptionComponent implements OnInit {

  storeOptions: Store[];
  storeItems: any[];

  typeOptions: StoreReportType[];
  typeItems: any[];
  isShowTypeOption: boolean = true;

  @Input() customParam: StoreReportCustomRouteParam;

  @Input() selectedStore: Store;
  @Output() selectedStoreChange: EventEmitter<Store> = new EventEmitter<Store>(null);

  @Input() selectedType: StoreReportType;
  @Output() selectedTypeChange: EventEmitter<StoreReportType> = new EventEmitter<StoreReportType>(null);

  @Output() onFinishSelect: EventEmitter<any> = new EventEmitter<any>(null);

  constructor(
    private _jobReportService: JobReportService
  ) { }

  ngOnInit() {
    this._jobReportService.getStoreReportOptions(
      (resp) => {
        this.storeOptions = this._jobReportService.storeOptions;

        if (!this.customParam) {
          this.typeOptions = _.filter(this._jobReportService.typeOptions, (type) => {
            return type.visible_in_app_menu == 0;
          });
          this.isShowTypeOption = true;
        } else {
          this.typeOptions = [this._jobReportService.getTypeByTypeId(this.customParam.typeId)];
          this.selectedType = this.typeOptions[0];
          this.isShowTypeOption = false;
        }
      }
    );
  }

  ngOnChanges() {
  }

  reportOptionDone() {
    this.selectedStoreChange.emit(this.selectedStore);
    this.selectedTypeChange.emit(this.selectedType);
    this.onFinishSelect.emit(true);
  }

}
