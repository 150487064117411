<div class="comment-input">
  <div class="attachment-button clickable" (click)="fileInput.click()">
    <span class="attachment-button-display">+</span>
  </div>

  <input #fileInput type="file" name="file" 
    accept="image/*, video/mp4"
    (change)="handleFileInputChange($event)"/>

  <div class="input-area">
    <textarea class="form-control" 
      [(ngModel)]="inputMessage" 
      (keydown)="inputKeyDown()" 
      (keydown.enter)="inputKeyEnter(false)" 
      (keydown.shift.enter)="inputKeyEnter(true)">
    </textarea>
  </div>
  <div class="send-button" (click)="send()">
    {{'WEBCLIENT.CHATROOM.SEND' | translate}}
  </div>
</div>