import { Component, OnInit, Input } from '@angular/core';
import { CustomFieldsDefinition } from '../models/custom-field';

@Component({
  selector: 'tn-field-title',
  templateUrl: './field-title.component.html',
  styleUrls: ['./field-title.component.scss'],
  host: { 'component-root': 'shared' }
})
export class FieldTitleComponent implements OnInit {

  @Input() fieldDefinition: CustomFieldsDefinition;
  @Input() isInput: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
