// MTR Config
// Created by Shan - 2018-11-14

import { TeamNoteConfig } from "../../configs/models/config";

export const MtrConfig: TeamNoteConfig = {
  HOST: {
    DOMAIN: "mtr"
  },
  GENERAL: {
    PAGE_TITLE: "ISIE"
  },
  IMAGES: {
    NOTIFICATION_ICON: "assets/images/clients/mtr/notification_icon.png",
    FAVICON: "assets/images/clients/mtr/favicon.png",
    FAVICON_ALERT: "assets/images/clients/mtr/favicon_alert.png"
  },
  WEBCLIENT: {
    DATES: {
      IS_24_HOURS_FORMAT: true
    }
  }
  
};

