import { Component, OnInit, Input } from '@angular/core';
import { StoreReportCustomFieldsMapped, StoreReport } from '../../models/job-report';
import { CellFormatHelperService } from '../../helper/cell-format-helper.service';
import { TextMessagePart } from '../../../../utilities/linkify/linkify.service';

export class FieldTextDisplayDetail {
  description: string;
}

@Component({
  selector: 'tn-field-text-display',
  templateUrl: './field-text-display.component.html',
  styleUrls: ['./field-text-display.component.scss']
})
export class FieldTextDisplayComponent implements OnInit {

  @Input() field: StoreReportCustomFieldsMapped;
  @Input() report: StoreReport;

  detail: FieldTextDisplayDetail;

  title: string = "";
  description: string = "";

  descriptionFormatDisplayArr: TextMessagePart[] = [];

  constructor(
    private _cellFormatHelperService: CellFormatHelperService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    try {
      this.detail = JSON.parse(this.field.definition.detail);
    } catch (err) {
      this.detail = {description: null};
    }

    this.title = this.field.definition.name;
    // this.description = this.detail.description;
    if (this.detail.description) {
      this.description = this._cellFormatHelperService.getDisplayingListCellInfoByType(this.report, null, this.detail.description, true);
      this.handleSpcialLocalTranslateStrings();
    }
  }

  handleSpcialLocalTranslateStrings(): void {
    this.descriptionFormatDisplayArr = this._cellFormatHelperService.handleSpcialLocalTranslateStrings(this.description);
  }
}
