<div class="location-selector">
  <div class="modal-header">
    <h4>{{ 'WEBCLIENT.CHATROOM.MESSAGE_OPTION.LOCATION' | translate }}</h4>
  </div>
  <div class="location-container">
    <div class="map-container">
      <!-- <ngui-map 
        [zoom]="zoom" 
        [center]="lat +',' + lng"
        [streetViewControl]="false"
        [fullscreenControl]="false"
        (mapReady$)="onMapReady($event)"
        (mapClick)="onMapClick($event)"
        (idle)="onIdle($event)">
        
      </ngui-map> -->
      <google-map
        height="100%"
        width="100%"
        [zoom]="zoom"
        [center]="{ lat, lng }"
        [options]="options"
        (mapClick)="onMapClick($event)"
        (idle)="onIdle($event)"
      >
        <!-- <map-marker
          [position]="center"
          [options]="markerOptions"
        ></map-marker> -->
      </google-map>
      <div class="map-marker-center"></div>
    </div>
    <div class="map-input-bar">
      <input
        type="text"
        class="form-control"
        [placeholder]="'WEBCLIENT.CHATROOM.MESSAGE_OPTION.LOCATION' | translate"
        [(ngModel)]="address"
      />
    </div>
  </div>
  <!-- location-container -->
  <div class="modal-footer">
    <button class="btn btn-default" (click)="cancel()">
      {{ 'GENERAL.CANCEL' | translate }}
    </button>
    <button class="btn tn-button-primary" (click)="confirm()">
      {{ 'GENERAL.CONFIRM' | translate }}
    </button>
  </div>
</div>
