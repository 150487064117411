import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Question } from '../../models/questionnaire';

@Component({
  selector: 'tn-question-free-text',
  templateUrl: './question-free-text.component.html',
  styleUrls: ['./question-free-text.component.scss']
})
export class QuestionFreeTextComponent implements OnInit {

  @Input() question: Question;
  @Output() questionChange: EventEmitter<Question> = new EventEmitter<Question>(null);

  constructor() { }

  ngOnInit() {
  }

  onChange(newVal) {
    this.questionChange.emit(this.question);
  }

}
