export const AttachmentTypeConstant = {
  IMAGE: 1,
  AUDIO: 2,
  VIDEO: 3,
  PDF: 4,
  GENERAL: 5,
  DOC: 6,
  SHEET: 7,
  PPT: 8,

  IS_VALID: {
    VALID: 0,
    INVALID_TYPE: 1,
    INVALID_SIZE: 2
  },

  IMAGE_TYPES: ['jpeg', 'jpg', 'png'],
  IMAGE_TYPES_DISPLAY: ['jpeg', 'jpg', 'png'],
  AUDIO_TYPES: ['3gp', 'm4a', 'ogg', 'opus'],
  VIDEO_TYPES: ['mp4', 'mov'],
  VIDEO_TYPES_DISPLAY: ['mp4'],
  PDF_TYPES: ['pdf'],
  PDF_TYPES_DISPLAY: ['pdf'],
  DOC_TYPES: ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'],
  DOC_TYPES_DISPLAY: ['doc', 'xls', 'ppt'],
  DOC_TYPE: ['doc', 'docx'],
  EXCEL_TYPE: ['xls', 'xlsx'],
  PPT_TYPE: ['ppt', 'pptx'],
  SHEET_TYPE: ['csv', 'xls', 'xlsx'],
  TXT_TYPES: ['txt', 'html'],

  MIME_TYPE_MAPPING: {
    'application/msword': ['doc', 'docx'],
    'application/vnd.ms-powerpoint': ['ppt', 'pptx'],
    'application/vnd.ms-excel': ['xls', 'xlsx']
  }
};
