<div class="comment-wrapper" *ngIf="comment">
  <div class="avatar-wrapper">
    <div class="avatar default-contact-avatar" [ngStyle]="{'background-image': iconImgSrc}"></div>
  </div>
  <div class="info-wrapper">
    <div class="name text-color-primary">
      {{comment.sender.name}}
    </div>
    <div class="content" [ngClass]="{'clickable': comment.type != MSG_TYPE.TEXT}">
      <tn-text-message 
        [body]="comment.parsedBody" 
        *ngIf="comment.type == MSG_TYPE.TEXT">
      </tn-text-message>
      <tn-attachment-message 
        [body]="comment.parsedBody" 
        [attachments]="comment.attachments" 
        (onAttachmentClick)="onAttachmentClick($event)" 
        *ngIf="comment.type == MSG_TYPE.ATTACHMENT">
      </tn-attachment-message>
      <tn-location-message 
        [body]="comment.parsedBody" 
        *ngIf="comment.type == MSG_TYPE.LOCATION">
      </tn-location-message>
    </div>
    <div class="info-row">
      <div class="date">{{comment.timestamp | tnDate: 'NEWS_DATE'}}</div>
      <div class="action-btn like-btn text-color-primary" (click)="likeComment()" *ngIf="isThreadOpen">
        <span *ngIf="!comment.isLiked">
          {{'WEBCLIENT.NEWS.NEWS.LIKE' | translate}}
        </span>
        <span *ngIf="comment.isLiked">
          {{'WEBCLIENT.NEWS.NEWS.UNLIKE' | translate}}
        </span>
      </div>
      <div class="action-btn reply-btn text-color-primary" (click)="toggleReplyComment()" *ngIf="isFirstLevel && isThreadOpen">
        {{'WEBCLIENT.NEWS.NEWS.REPLY' | translate}}
      </div>
      <div class="like-count text-color-primary" *ngIf="comment.likeCount > 0">
        <img [src]="LIKE_EMOJI_PATH"/>
        {{comment.likeCount}}
      </div>
    </div>
  </div>
  <div class="remove-button-wrapper" *ngIf="isAllowDelete">
    <i class="fa fa-fw fa-times clickable" (click)="removeComment()"></i>
  </div>
</div>