<div class="signautre-dialog-container">
  <div class="modal-body">
    <div class="signature-container">
      <div class="signature-konva" [id]="IMAGE_CONTAINER_ID"></div>
      <div class="signature-tip-overlay-wrapper">
        <div class="signature-tip">
          {{'GENERAL.SIGN_HERE' | translate}}
        </div>
      </div>
    </div>
  </div>

  <input #file type="file" name="file" (change)="handleFileInputChange($event)" accept="image/*">

  <div class="modal-footer">
    <button class="btn btn-default" (click)="cancel()">
      {{'GENERAL.CANCEL' | translate}}
    </button>
    <button class="btn btn-info" (click)="file.click()">
      {{'GENERAL.UPLOAD' | translate}}
    </button>
    <button class="btn btn-warning" (click)="clear()">
      {{'GENERAL.CLEAR' | translate}}
    </button>
    <button class="btn tn-button-primary" (click)="save()">
      {{'GENERAL.SAVE' | translate}}
    </button>
  </div>
</div>