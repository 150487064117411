<div class="message-info-wrapper">
  <h4>{{'WEBCLIENT.CHATROOM.MESSAGE_INFO.TITLE' | translate}}</h4>
  <tabset>
    <ng-container *ngIf="!isShowMessageAckOnly">
      <tab>
        <ng-template tabHeading>
          {{'WEBCLIENT.CHATROOM.MESSAGE_INFO.READ_HEADER' | translate}}
        </ng-template>
        <div class="infos-wrapper reads-wrapper">
          <div class="read-row" *ngFor="let r of reads">
            <tn-contact-cell [contact]="r.account" [isShowDesc]="false" [isClickable]="false">
              <div class="extra-desc">
                <div class="desc timestamp text-color-secondary">{{r.timestamp | tnDate: 'MESSAGE_INFO_DATE'}}</div>
              </div>
            </tn-contact-cell>
          </div>
        </div>
      </tab>
      <tab>
        <ng-template tabHeading>
          {{'WEBCLIENT.CHATROOM.MESSAGE_INFO.DELIVERY_HEADER' | translate}}
        </ng-template>
        <div class="infos-wrapper deliveries-wrapper">
          <div class="delivery-row" *ngFor="let d of deliveries">
            <tn-contact-cell [contact]="d.account" [isShowDesc]="false" [isClickable]="false">
              <div class="extra-desc">
                <div class="desc timestamp text-color-secondary">{{d.timestamp | tnDate: 'MESSAGE_INFO_DATE'}}</div>
              </div>
            </tn-contact-cell>
          </div>
        </div>
      </tab>
      <tab *ngIf="isShowUnreads">
        <ng-template tabHeading>
          {{'WEBCLIENT.CHATROOM.MESSAGE_INFO.UNREAD_HEADER' | translate}}
        </ng-template>
        <div class="infos-wrapper unreads-wrapper">
          <div class="unread-row" *ngFor="let u of unreads">
            <tn-contact-cell [contact]="u" [isShowDesc]="false" [isClickable]="false">
              
            </tn-contact-cell>
          </div>
        </div>
      </tab>
      <tab *ngIf="fallbackStatus && fallbackStatus.length > 0">
        <ng-template tabHeading>
          {{'WEBCLIENT.CHATROOM.MESSAGE_INFO.SMS_HEADER' | translate}}
        </ng-template>
        <div class="infos-wrapper fallback-status-wrapper">
          <div class="fallback-status-row" *ngFor="let f of fallbackStatus">
            <tn-contact-cell [contact]="f.account" [isShowDesc]="false" [isClickable]="false">
              <div class="extra-desc">
                <div class="desc text-color-secondary">
                  Target: {{f.fallback_target}}
                </div>
                <div class="desc text-color-secondary">
                  Status: {{f.statusText | translate}}
                </div>
                <div class="desc text-color-secondary">
                  Remarks: {{f.remarks}}
                </div>
                <div class="desc text-color-secondary" *ngIf="f.create_date">
                  Request Date: {{f.create_date | tnDate: 'MESSAGE_INFO_DATE'}}
                </div>
                <div class="desc text-color-secondary" *ngIf="f.update_date">
                  Last Update Date: {{f.update_date | tnDate: 'MESSAGE_INFO_DATE'}}
                </div>
              </div>
            </tn-contact-cell>
          </div>
        </div>
      </tab>
    </ng-container>
    <tab *ngIf="isShowAcks">
      <ng-template tabHeading>
        <div class="header-wrapper">
          <div class="acknowledged-icon"></div>
          {{'WEBCLIENT.CHATROOM.MESSAGE_INFO.ACKNOWLEDGED_HEADER' | translate}}
        </div>
      </ng-template>
      <div class="infos-wrapper acks-wrapper">
        <div class="ack-row" *ngFor="let a of acks">
          <tn-contact-cell [contact]="a.account" [isShowDesc]="false" [isClickable]="false">
            <div class="extra-desc">
              <div class="desc timestamp text-color-secondary">{{a.timestamp | tnDate: 'MESSAGE_INFO_DATE'}}</div>
            </div>
          </tn-contact-cell>
        </div>
      </div>
    </tab>
  </tabset>
</div>