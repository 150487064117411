<div class="corporate-material-list-container" *ngIf="folder">
  <tn-page-title [title]="!folder.name ? ('WEBCLIENT.MENU.' + rootTranslateKey | translate) : folder.name">
    <div class="left-control">
      <div class="back-button clickable" *ngIf="!isRootFolder" (click)="folderBack()">
        <i class="fa fa-fw fa-angle-left"></i>
      </div>
    </div>

    <div class="right-control" *ngIf="isAllowChangeViewMode">
      <div class="view-mode-button-wrapper" (click)="toggleViewMode()">
        <div class="corporate-material-view-mode-icon clickable" [ngClass]="{'list-icon': isGridView, 'grid-icon': !isGridView}"></div>
      </div>
    </div>
  </tn-page-title>

  <tn-search-bar 
    class="tn-search-bar"
    [keywordDefault]="folder.searchKeyword"
    (onKeywordChanged)="search($event)">
  </tn-search-bar>

  <div class="corporate-material-list" [ngClass]="{'grid-view': isGridView}" *ngIf="folder.childFolders.length > 0 || folder.childMaterials.length > 0">
    <tn-corporate-material-folder
      *ngFor="let f of folder.childFolders"
      [folder]="f"
      (click)="folderClick(f)">
    </tn-corporate-material-folder>
    <tn-corporate-material-item
      *ngFor="let m of folder.childMaterials"
      [material]="m"
      [fileAllowTypes]="fileAllowTypes"
      (click)="materialClick(m)">
    </tn-corporate-material-item>
  </div>

  <ng-container *ngIf="folder.childFolders.length == 0 && folder.childMaterials.length == 0">
    <tn-empty-page></tn-empty-page>
  </ng-container>
</div>
