import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkifyPipe } from './linkify.pipe';
import { LinkifyService } from './linkify.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    LinkifyPipe
  ],
  exports: [
    LinkifyPipe
  ],
  providers: [
    LinkifyService
  ]
})
export class LinkifyModule { }
