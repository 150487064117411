import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store, StoreReportType, StoreReportCustomFieldsMapped, StoreReport } from '../models/job-report';

@Component({
  selector: 'tn-report-input',
  templateUrl: './report-input.component.html',
  styleUrls: ['./report-input.component.scss']
})
export class ReportInputComponent implements OnInit {

  @Input() store: Store;
  @Input() type: StoreReportType;
  @Input() mappedInputCustomFields: StoreReportCustomFieldsMapped[];

  @Output() mappedInputCustomFieldsChange: EventEmitter<StoreReportCustomFieldsMapped[]> = new EventEmitter<StoreReportCustomFieldsMapped[]>(null);

  @Input() report: StoreReport;

  constructor() { }

  ngOnInit() {
    if (!this.report) {
      this.report = new StoreReport();
      this.report.store = this.store;
      this.report.type = this.type;
      this.report.type_id = this.type.type_id;
    }
  }

  onFieldChange(newVal) {
    this.mappedInputCustomFieldsChange.emit(this.mappedInputCustomFields);
  } 

}
