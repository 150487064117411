<div class="attachment-pdf-wrapper" *ngIf="attachmentId">

  <div class="modal-header pdf-header">
    <div class="file-name-wrapper" *ngIf="attachment || filename">
      {{filename || attachment.filename}}
    </div>
    
    <div class="download-wrapper" *ngIf="isAllowAttachmentSave || !isPDF">
      <button class="btn tn-button-primary" [disabled]="isDisableDownload" (click)="downloadFile()">
        {{'GENERAL.DOWNLOAD' | translate}}
      </button>
    </div>

    <!-- <div class="pdf-page-count" *ngIf="isPDF">
      <div class="input-group">
        <input class="form-control" [(ngModel)]="pageNum" (ngModelChange)="onPageChange($event)">
        <span class="input-group-addon">/ {{ pageCount }}</span>
      </div>
    </div> -->

  </div>
  <!-- <button (click)="getFileUrl()">getFileUrl</button> -->
  <div class="pdf-view-container" *ngIf="isPDF">
    <!-- <div class="pdf-navigation"
      [ngClass]="{'active clickable': pageNum > 1}"
      (click)="prevPage()">
      <i class="fa fa-chevron-left"
        *ngIf="pageNum > 1">
      </i>
    </div> -->
    
    <div class="pdf-container">
      <canvas #imageWatermarkCanvas></canvas>
      <!-- render when fileUrl is not undefined -->

      <!-- second way for fixing pdf loading issue, add the follow code line to ng2-pdfjs-viwer component -->
      <!-- *ngIf="fileUrl"  -->
      <ng2-pdfjs-viewer 

        #viewer
        class="full-width"
        viewerId="pdfLoader"
        [page]="page"
        [pdfSrc]="fileUrl"
        [viewBookmark]="false"
        [openFile]="false"
        [fullScreen]="false"
        [find]="false"
        [download]="false"
        [startPrint]="false"
        [print]="false"
        [externalWindow]="false"
        (onDocumentLoad)="hideViewerLayout($event)"
        (onPageChange)="onPageChange()"
      >
      </ng2-pdfjs-viewer>
      
      <!-- <div class="pdf-wrapper" [ngStyle]="{
        'height': canvasHeight + 'px', 
        'width': canvasWidth + 'px'
      }"> -->
        <!-- <canvas id="watermark-canvas"></canvas> -->
        <!-- <canvas id="pdf-canvas"></canvas> -->
      <!-- </div> -->
    </div>
<!-- 
    <div class="pdf-navigation"
      [ngClass]="{'active clickable': pageNum < pageCount}"
      (click)="nextPage()">
      <i class="fa fa-chevron-right"
        *ngIf="pageNum < pageCount">
      </i>
    </div> -->

  </div>

</div>
