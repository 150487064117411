/**
 * SCL Strings
 * 
 * Created by Shan - 2018-12-24
 */

import { LanguageConstant } from "../../constants/language.constant";
import { LanguageString } from "../../strings/string-helper.service";

export const SclStrings: LanguageString[] = [
  {
    key: LanguageConstant.EN.key,
    strings: {
      WEBCLIENT: {
        
      },
      LOGIN: {
        GET_STATE: {
          USERNAME: {
            INPUT_TIP: "Input Phone Number",
            USERNAME: "Phone Number"
          }
        }
      }
    }
  },
  {
    key: LanguageConstant.ZH_CHT.key,
    strings: {
      WEBCLIENT: {
        
      },
      LOGIN: {
        GET_STATE: {
          USERNAME: {
            INPUT_TIP: "輸入電話號碼",
            USERNAME: "電話號碼"
          }
        }
      }
    }
  },
  {
    key: LanguageConstant.ZH_CHS.key,
    strings: {
      WEBCLIENT: {
        
      },
      LOGIN: {
        GET_STATE: {
          USERNAME: {
            INPUT_TIP: "输入电话号码",
            USERNAME: "电话号码"
          }
        }
      }
    }
  }
];