<div class="field-dropdown-wrapper">

  <div class="dropdown-ans" *ngIf="!isInput">
    {{answer}}
  </div>

  <div class="dropdown-input" *ngIf="isInput">
    <mat-form-field>
      <mat-select [(ngModel)]="answer" (ngModelChange)="onChange($event)">
        <mat-option *ngFor="let option of detail.options" [value]="option">
          {{option}}
        </mat-option>
        <mat-option [value]="EXTRA_TEXT_OPTION" *ngIf="detail.is_extra_text">
          {{'WEBCLIENT.JOB_REPORT.NEW_REPORT.FREE_TEXT_OPTION' | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <input type="text" class="form-control" [(ngModel)]="extraTextInput" (ngModelChange)="onChange($event)" *ngIf="answer == EXTRA_TEXT_OPTION"/>
  </div>

</div>