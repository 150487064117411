import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { WatermarkService, WaterMarkSetting, WatermarkMode } from './watermark.service';

@Component({
  selector: 'tn-watermark',
  templateUrl: './watermark.component.html',
  styleUrls: ['./watermark.component.scss']
})
export class WatermarkComponent implements OnInit {

  watermarkSetting: WaterMarkSetting = new WaterMarkSetting();
  isDisplayOverlay: boolean = true;

  private sub: Subscription;

  @ViewChild('watermarkOverlayCanvas', {static: false}) watermarkOverlayCanvas: ElementRef;

  constructor(
    private _watermarkService: WatermarkService
  ) { 
    this.sub = this._watermarkService.setting$.subscribe(setting => {
      if (!setting) {
        this.isDisplayOverlay = false;
        return;
      }
      setTimeout(() => {
        this.watermarkSetting = setting;
        this.applyWatermarkMode();
      }, 0);
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  applyWatermarkMode(): void {
    if (this._watermarkService.checkIfEnabledFullScreenMode()) {
      this.isDisplayOverlay = true;
      setTimeout(() => {
        this.drawFullAppWatermark();
      }, 0);
    } else {
      this.isDisplayOverlay = false;
    }
  }

  drawFullAppWatermark(): void {
    let canvas: HTMLCanvasElement = this.watermarkOverlayCanvas.nativeElement;
    let ctx = canvas.getContext('2d');
    canvas.width = canvas.clientWidth;
    canvas.height = canvas.clientHeight;
    this._watermarkService.drawWatermark(ctx, canvas.width, canvas.height);
  }
}
