<div class="chat-search-container">
  <div class="chat-search-header">
    <tn-page-title [title]="chat ? chat.displayName : ('WEBCLIENT.CHAT.SEARCH_MESSAGE' | translate)">
      <span class="title-addon">
        <ng-container *ngIf="chat && chat.chatTarget && chat.chatTarget.deleted">
          {{ 'WEBCLIENT.CONTACT.DELETED_POSTFIX' | translate }}
        </ng-container>
      </span>

      <div class="left-control">
        <div class="back-button clickable" (click)="chatRoomBack()">
          <i class="fa fa-fw fa-angle-left"></i>
        </div>
      </div>
    </tn-page-title>
  </div>


  <div class="search-message-list-wrapper">
    <tn-search-bar
      class="tn-search-bar"
      [keywordDefault]="keyword"
      [isEnableFilter]="true"
      [chat]="chat"
      (onKeywordChanged)="searchChat($event)"
      (onFilterChanged)="filterSearchedMsgs($event)"
    >
    </tn-search-bar>


    <!-- <div class="search-message-list" *ngIf="searchedMessages.length > 0" #scrollWrapper>
      <div class="tn-chat-message" *ngFor="let message of searchedMessages">
        <tn-chat-message-cell
          [isInChat]="chat"
          [keyword]="searchKeyword"
          [message]="message"
          (messageOnClick)="messageOnClick($event)"
        >
        </tn-chat-message-cell>
      </div>
    </div> -->
    
    <!-- loading mark put here won't affect virtual scroller -->
    <!-- <div class="loading-history-indicator" *ngIf="isLoading">
      <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
    </div> -->
    
    <div
      *ngIf="filteredSearchedMessages.length > 0 || isLoading"
      #scrollWrapper
      virtualScroller
      class="search-message-list"
      
      [items]="filteredSearchedMessages"
      (vsUpdate)="viewPortItems = $event"
      (vsEnd)="loadMore($event)"
    >
      <ng-container *ngFor="let message of viewPortItems">
        <tn-chat-message-cell
          [isEnableImportantUsers]="isEnableImportantUsers"
          [isInChat]="chat"
          [keyword]="searchKeyword"
          [message]="message"
          (messageOnClick)="messageOnClick($event)"
        >
        </tn-chat-message-cell>
      </ng-container>
      <div *ngIf="isLoading" class="loading-history-indicator">
        <i class="fa fa-spinner fa-pulse fa-3x fa-fw loader-icon"></i>
        Loading...
      </div>
    </div>

    <ng-container *ngIf="filteredSearchedMessages.length == 0 && !isLoading">
      <tn-empty-page [msgKey]="!searchKeyword ? 'GENERAL.NO_KEYWORDS' : 'GENERAL.NO_CONTENT'"></tn-empty-page>
    </ng-container>
  </div>
</div>