import { Component, OnInit, Input } from '@angular/core';
import { Store, StoreReportType } from '../../models/job-report';

@Component({
  selector: 'tn-report-info-header',
  templateUrl: './report-info-header.component.html',
  styleUrls: ['./report-info-header.component.scss']
})
export class ReportInfoHeaderComponent implements OnInit {

  @Input() store: Store;
  @Input() type: StoreReportType;

  constructor() { }

  ngOnInit() {
  }

}
