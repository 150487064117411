import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Chat } from '../../../models/chat';
import { FileManagerService } from '../../../utilities/file-manager/file-manager.service';
import { FileFactoryService } from '../../../utilities/file-factory/file-factory.service';
import { NewsMessageService } from '../../services/data/messages/news-message/news-message.service';

@Component({
  selector: 'tn-news-category-cell',
  templateUrl: './news-category-cell.component.html',
  styleUrls: ['./news-category-cell.component.scss']
})
export class NewsCategoryCellComponent implements OnInit {

  @Input() category: Chat;
  @Output() categoryOnClick: EventEmitter<Chat> = new EventEmitter<Chat>();

  avatarImageSrc: string = '';

  unreadCount: number = 0;

  constructor(
    private _fileManagerService: FileManagerService,
    private _fileFactoryService: FileFactoryService,
    private _newsMessageService: NewsMessageService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (!this.category) {
      return;
    }

    if (this.category.pic && this.category.pic != 'None') {
      this._fileFactoryService.getFileByAttachmentId(
        this.category.pic,
        (imageUrl) => {
          this.avatarImageSrc = imageUrl;
        },
        (err) => {},
        false,
        false,
        true
      );
    }

    let unreadNews = this._newsMessageService.getUnreadNewsByChatId(this.category.chat_id);
    this.unreadCount = unreadNews.length;
  }

  onClick(): void {
    this.categoryOnClick.emit(this.category);
  }

}
