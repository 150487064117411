import { UserContact } from "../../../models/user-contact";
import { Attachment } from "../../../models/attachment";

export class CorporateMaterialFolder {
  company_domain: string;
  create_date: string;
  extenstion: string;
  folder_icon_id: string;
  folder_id: string;
  is_manager: number;
  is_store: number;
  name: string;
  order: number;
  store_code: string;
  update_date: string;
  user_group_child_group: UserGroupRelations[];
  user_group_id: string;
  user_group_parent_group: UserGroupRelations[];

  ebooks: CorporateMaterialEbook[];
  files: CorporateMaterialFile[];
  urls: CorporateMaterialUrl[];

  // local
  childFolders: CorporateMaterialFolder[];
  childMaterials: any[];
  searchKeyword: string;
}

export class CorporateMaterialItem {
  create_date: string;
  created_by: string;
  creator: UserContact;
  end_date: string;
  material_id: string;
  name: string;
  order: number;
  start_date: string;
  type: string;
  update_date: string;
}

export class CorporateMaterialEbook extends CorporateMaterialItem {
  book_id: string;
  bookmark: any;
  pages: CorporateMaterialEbookPage[];
}

export class CorporateMaterialEbookPage {
  attachment_id: string;
  audio_tracks: CorporateMaterialEbookPageAudio[];
  book_id: string;
  create_date: string;
  name: string;
  page_id: string;
  page_no: number;
  update_date: string;

  //local
  type: string;
}

export class CorporateMaterialEbookPageAudio {
  attachment_id: string;
  audio_type: string;
  create_date: string;
  page_id: string;
  udpate_date: string;
}

export class CorporateMaterialFile extends CorporateMaterialItem {
  attachment: Attachment;
  attachment_id: string;
  enable_watermark: number;
  allow_download: number;
  file_id: string;
}

export class CorporateMaterialUrl extends CorporateMaterialItem {
  external_browser_android: number;
  external_browser_ios: number;
  url: string;
  url_id: string;
}

export class UserGroupRelations {
  parent_user_group_id: string;
  child_user_group_id: string;
}

export class CorporateMaterialEbookPagePointerCookies {
  b: string;
  p: number;
}

export class CorporateMaterialCustomRouteParam {
  folderName?: string;
  titleTranslateKey?: string;
  
  folderId?: string;
  materialId?: string;

  constructor(folderName?: string, titleTranslateKey?: string, folderId?: string, materialId?: string) {
    this.folderName = folderName;
    this.titleTranslateKey = titleTranslateKey;

    this.folderId = folderId;
    this.materialId = materialId;
  }
}