<div class="news-media-wrapper" *ngIf="media">
    <div *ngFor="let media of mediaArr; let i=index"
         [ngClass]="{'news-media-item': isInModal, clickable: isInModal, 'news-media-preview': !isInModal}"
         (click)="!isInModal || onClick(media.attachmentId)">
        <span class="news-media-name multi-line">{{media.name}}</span>
        <span class="news-media-type" *ngIf="isInModal">
            <!-- <i class="fa"
               [ngClass]="{
            'fa-file-image-o': media.type == ATTACHMENT_TYPE.IMAGE,
            'fa-file-pdf-o': media.type == ATTACHMENT_TYPE.PDF,
            'fa-file-o': media.type == ATTACHMENT_TYPE.GENERAL,
            'fa-file-video-o': media.type == ATTACHMENT_TYPE.VIDEO
          }"></i> -->
          <!-- <div [ngClass]="{'pdf-icon': media.type == ATTACHMENT_TYPE.PDF}"></div> -->
            <div class="media-file-icon icon-to-left" [ngClass]="{
                'doc-file-icon': media.type == ATTACHMENT_TYPE.IMAGE,
                'pdf-file-icon': media.type == ATTACHMENT_TYPE.PDF,
                'excel-file-icon': media.type == ATTACHMENT_TYPE.GENERAL,
                'ppt-file-icon': media.type == ATTACHMENT_TYPE.VIDEO,
                'unknown-file-icon': media.type == ATTACHMENT_TYPE.VIDEO,
                'video-file-icon': media.type == ATTACHMENT_TYPE.VIDEO
            }"></div>
            <span>{{media.typeString}}</span>
        </span>
    </div>
</div>
