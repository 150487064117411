import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TrainingFolder } from '../models/training';
import { TeamNoteTrainingConstant } from '../constants/training.constant';

@Component({
  selector: 'tn-training-list',
  templateUrl: './training-list.component.html',
  styleUrls: ['./training-list.component.scss']
})
export class TrainingListComponent implements OnInit {

  @Input() folder: TrainingFolder;
  @Output() onItemClick: EventEmitter<any> = new EventEmitter<any>(null);
  @Output() onFolderBack: EventEmitter<any> = new EventEmitter<any>(null);

  title: string = '';
  isRootFolder: boolean = true;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (!this.folder) {
      return;
    }
    if (this.folder.name == TeamNoteTrainingConstant.ROOT_FOLDER_NAME) {
      this.isRootFolder = true;
    } else {
      this.isRootFolder = false;
    }
  }

  folderBack() {
    this.onFolderBack.emit();
  }

  folderClick(folder) {
    this.onItemClick.emit(folder);
  }
  fileClick(file) {
    this.onItemClick.emit(file);
  }

}
