import { TeamNoteConfig } from "../../configs/models/config";

export const HkwtiaConfig: TeamNoteConfig = {
  HOST: {
    DOMAIN: "hkwtia"
  },
  IMAGES: {
    NOTIFICATION_ICON: "assets/images/clients/hkwtia/notification_icon.png",
    FAVICON: "assets/images/clients/hkwtia/favicon.png",
    FAVICON_ALERT: "assets/images/clients/hkwtia/favicon_alert.png"
  },
  MAP: {
    API_KEY: "AIzaSyCBR0xW_sfn2FCiJA5Vt7V0T_ouiDBxE-4"
  }
}