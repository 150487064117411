import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

// Components
import { LoginComponent } from "./login.component";
import { GetStateComponent } from "./get-state/get-state.component";
import { OtpComponent } from "./otp/otp.component";
import { PasswordComponent } from "./password/password.component";
import { SecondFactorComponent } from "./second-factor/second-factor.component";
import { SetPasswordComponent } from "./set-password/set-password.component";
import { LoginMethodsComponent } from "./login-methods/login-methods.component";

export const LOGIN_ROUTES: Routes = [
  {
    path: "login",
    component: LoginComponent,
    children: [
      { path: "get-state", component: GetStateComponent },
      { path: "otp", component: OtpComponent },
      { path: "password", component: PasswordComponent },
      { path: "second-factor", component: SecondFactorComponent },
      { path: "set-password", component: SetPasswordComponent },
      { path: "login-methods", component: LoginMethodsComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(LOGIN_ROUTES)],
  exports: [RouterModule]
})
export class LoginRoutingModule {}
