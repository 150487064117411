import { Injectable } from '@angular/core';

import * as EXIF from 'exif-js/exif';
import { TimestampService } from '../timestamp/timestamp.service';
import { DateService } from '../date/date.service';
import { TeamnoteConfigService } from '../../configs/teamnote-config.service';

@Injectable()
export class ImageHelperService {

  imageCaptions: {[attachemntId: string]: string} = {};

  constructor(
    private _timestampService: TimestampService,
    private _dateService: DateService,
    private _teamnoteConfigService: TeamnoteConfigService
  ) { }

  handleImageOrientation(dataUrl: string, img: HTMLImageElement, orientation: number, callbackWithDataUrl: Function): void {
    if (!orientation) {
      callbackWithDataUrl(dataUrl);
      return;
    }

    let canvas = document.createElement("canvas");
    let width = img.width;
    let height = img.height;
    
    // set proper dimensions
    switch (orientation) {
      case 1:
      case 2:
      case 3:
      case 4:
        canvas.width = width;
        canvas.height = height;
        break;
      case 5:
      case 6:
      case 7:
      case 8:
        canvas.width = height;
        canvas.height = width;
        break;
    }

    let ctx = canvas.getContext("2d");

    // transform context before drawing
    switch (orientation) {
      case 1:
        // normal orientation
        break;
      case 2: 
        ctx.transform(-1, 0, 0, 1, width, 0); 
        break;
      case 3: 
        ctx.transform(-1, 0, 0, -1, width, height); 
        break;
      case 4: 
        ctx.transform(1, 0, 0, -1, 0, height); 
        break;
      case 5: 
        ctx.transform(0, 1, 1, 0, 0, 0); 
        break;
      case 6: 
        ctx.transform(0, 1, -1, 0, height , 0); 
        break;
      case 7: 
        ctx.transform(0, -1, -1, 0, height , width); 
        break;
      case 8: 
        ctx.transform(0, -1, 1, 0, 0, width); 
        break;
    }

    ctx.drawImage(img, 0, 0);
    callbackWithDataUrl(canvas.toDataURL());
  }

  getPhotoDateTimeOriginalFromDataUrl(dataUrl: string, callbackWithDateObjAndDataUrl): void {
    let img: any = document.createElement('img');
    img.src = dataUrl;
    img.onload = () => {
      EXIF.getData(img, () => {
        let dateTimeOriginal = EXIF.getTag(img, "DateTimeOriginal");
        // It is in format YYYY:MM:DD HH:mm:ss, need to parse it into a date obj
        let dateObj: Date = null;
        if (dateTimeOriginal) {
          let dateParts = dateTimeOriginal.split(" ");
          let ymd = dateParts[0].split(":").join("-");
          let hms = dateParts[1].split(":");
          dateObj = new Date(ymd);
          let ms = dateObj.setHours(hms[0], hms[1], hms[2]);
          dateObj = new Date(ms);
        }

        let orientation = EXIF.getTag(img, "Orientation");

        this.handleImageOrientation(
          dataUrl,
          img, 
          orientation,
          (dataUrl) => {
            callbackWithDateObjAndDataUrl(dateObj, dataUrl);
          }
        );
      });
    }
  }

  drawDateTimeToImageCtxByDateObj(ctx: CanvasRenderingContext2D, dateObj: Date, width: number, height: number): void {
    let dateTimeStr = this._dateService.getDateDisplayWithKey(dateObj, "UPLOAD_IMAGE_DATE");

    // Give it 5 px space with border.
    width -= 5;
    height -= 5;

    ctx.textAlign = "end";
    ctx.textBaseline = "bottom";
    ctx.font = width / 25 + "px Arial";
    ctx.lineWidth = 2;
    ctx.strokeText(dateTimeStr, width, height);
    ctx.fillStyle = "white";
    ctx.fillText(dateTimeStr, width, height);
  }

  setImageCaptionById(attachemntId: string, caption: string): void {
    this.imageCaptions[attachemntId] = caption;
  }

  getImageCaptionById(attachemntId: string): string {
    return this.imageCaptions[attachemntId];
  }

  getImageDataUrlBySrc(src: string, callbackWithDataUrl: Function): void {
    let image = new Image();
    image.crossOrigin = "anonymous";
    image.onload = () => {
      let canvas: HTMLCanvasElement = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0, image.width, image.height);
      callbackWithDataUrl(canvas.toDataURL("image/jpeg"));
    };
    image.src = src;
  }

  resizeImageByDataUrl(dataUrl: string, callbackWithResizedDataUrl): void {
    let image = new Image();
    image.src = dataUrl;
    image.onload = () => {
      let maxWidth = this._teamnoteConfigService.config.GENERAL.IMAGE_COMPRESSION.MAX_WIDTH_PIXEL;
      let maxHeight = this._teamnoteConfigService.config.GENERAL.IMAGE_COMPRESSION.MAX_HEIGHT_PIXEL;

      let width = image.naturalWidth;
      let height = image.naturalHeight;

      if (width > maxWidth || height > maxHeight) {
        if (width > height) {
          height = height * maxWidth / width;
          width = width > maxWidth ? maxWidth : width;
        } else {
          width = width * maxHeight / height;
          height = height > maxHeight ? maxHeight : height;
        }
      }

      let canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext("2d");
      // handle png transparent image, give it a white background
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, width, height);
      ctx.drawImage(image, 0, 0, width, height);

      let newImageDataUrl = canvas.toDataURL(this._teamnoteConfigService.config.GENERAL.IMAGE_COMPRESSION.MIME_TYPE, 1);

      callbackWithResizedDataUrl(newImageDataUrl);
    };
  }
}
