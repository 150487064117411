<form>
  <section class="form-block">
    <div class="login-body-row input-action-msg">
      {{'LOGIN.OTP.INPUT_TIP' | translate}}
    </div>
    <div class="login-body-row">
        <input #otpInput class="form-control" type="password" [(ngModel)]="otp" name="otp" placeholder="{{'LOGIN.OTP.OTP' | translate}}" autocomplete="off">
    </div>
    <div class="login-body-row">
      <button class="btn tn-button-primary btn-block" (click)="submit()">{{'LOGIN.PASSWORD.SUBMIT' | translate}}</button>
    </div>
    <div class="login-body-row">
      <button class="btn tn-button-primary btn-block" (click)="back()">{{'GENERAL.BACK' | translate}}</button>
    </div>
  </section>
</form>
