<div class="tn-content">
  <div class="tn-content-left" 
    [ngClass]="{
      'current-focus-content': !activeChat, 
      'current-unfocus-content': activeChat
    }">
    <tn-chat-list
      [activeChat]="activeChat"
      (chatCellOnClick)="chatCellOnClick($event)"
      (messageCellOnClick)="messageCellOnClick($event)"
      (searchMessageOnClick)="searchMessageOnClick($event)">
    </tn-chat-list>
  </div>
  <div class="tn-content-right" 
    [ngClass]="{
      'current-focus-content': activeChat || isSearching, 
      'current-unfocus-content': !activeChat && !isSearching
    }">
    <tn-chat-room *ngIf="activeChat && !isSearching"
      [chat]="activeChat"
      [targetMessage]="targetMessage"
      (enterChatSearchMode)="enterChatSearchMode($event)"
      (exitSearchModeAndGoToChat)="exitSearchModeAndGoToChat($event)"
      (clearTargetMessageProps)="clearTargetMessageProps($event)"
      (enterSearchModeAndGoToChat)="enterSearchModeAndGoToChat($event)">
    </tn-chat-room>
    <tn-chat-search
      *ngIf="isSearching"
      [chat]="searchingChat"
      [keyword]="searchingKeyword"
      (exitSearchModeAndGoToChat)="exitSearchModeAndGoToChat($event)"
      (viewSearchedMessage)="viewSearchedMessage($event)">
    </tn-chat-search>
  </div>
</div>