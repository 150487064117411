import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { StoreReport, JobDispatch } from '../models/job-report';
import { JobReportService } from '../job-report.service';
import { ContactPickerService } from '../../contact-picker/contact-picker.service';
import { UserContact } from '../../../models/user-contact';
import { UserGroupService } from '../../services/data/user-group/user-group.service';
import { UserContactService } from '../../services/data/user-contact/user-contact.service';

import * as _ from 'lodash';
import { UserGroup } from '../../../models/user-group';

@Component({
  selector: 'tn-job-report-view',
  templateUrl: './job-report-view.component.html',
  styleUrls: ['./job-report-view.component.scss']
})
export class JobReportViewComponent implements OnInit {

  @Input() report: StoreReport;
  @Input() isViewingStoreReport: boolean;
  @Output() onJobActionClick: EventEmitter<number> = new EventEmitter<number>(null);
  @Output() clearViewingReport: EventEmitter<boolean> = new EventEmitter<boolean>(null);
  @ViewChild('jobReportViewBody', {static: false}) jobReportViewBody: ElementRef;

  storeGroupMembers: UserContact[] = [];

  job: JobDispatch;

  constructor(
    private _jobReportService: JobReportService,
    private _contactPickerService: ContactPickerService,
    private _userGroupService: UserGroupService,
    private _userContactService: UserContactService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.mapStoreReportFields();
    this.scrollToTop();
  }

  reportViewBack(isRefresh: boolean) {
    this.clearViewingReport.emit(isRefresh);
  }

  scrollToTop() {
    this.jobReportViewBody.nativeElement.scrollTop = 0;
  }

  mapStoreReportFields() {
    this.report.mappedCustomFields = this._jobReportService.getMappedReportFieldsByTypeIdAndAnswers(this.report.type_id, this.report.custom_fields);
    
    if (this.report.job_dispatch.length > 0) {
      this.report.job_dispatch[0].mappedCustomFields = this._jobReportService.getMappedReportFieldsByTypeIdAndAnswers(this.report.type_id, this.report.job_dispatch[0].custom_fields, true);
    }
  }

  onJobAction(actionCode: number) {
    this.onJobActionClick.emit(actionCode);
  }

  getStoreGroupMember(callback: Function): void {
    let userGroup = this._userGroupService.getUserGroupByUserGroupId(this.report.store_id);
    if (userGroup) {
      this.parseMemberContactFromUserGroup(userGroup, callback);
    } else {
      this._userGroupService.getUserGroupContactRequest(
        [this.report.store_id],
        () => {
          this.getStoreGroupMember(callback);
        }
      );
    }
  }

  parseMemberContactFromUserGroup(userGroup: UserGroup, callback: Function): void {
    this.storeGroupMembers = _.map(userGroup.members, (userId) => {
      return this._userContactService.getUserContactByUserId(userId);
    });
    callback();
  }

  assign() {
    this.getStoreGroupMember(
      () => {
        this._contactPickerService.openContactPicker(
          'WEBCLIENT.JOB_REPORT.ACTION.ASSIGN',
          false,
          false,
          false,
          (contact: UserContact) => {
            this._jobReportService.assignJobDispatch(
              this.report.report_id, 
              contact.user_id, 
              (resp) => {
                this.reportViewBack(true);
              }
            );
          },
          false,
          null,
          this.storeGroupMembers
        );
      }
    );
  }

}
