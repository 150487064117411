import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PageUrlConstant } from '../../constants/page-url.constant';
import { TeamnoteConfigService } from '../../configs/teamnote-config.service';

@Injectable()
export class WebclientRoutingService {

  basePath: string = PageUrlConstant.WEBCLIENT.BASE;

  constructor(
    private _router: Router,
    private _teamnoteConfigService: TeamnoteConfigService
  ) { }

  goToPageByUrl(url: string): void {
    this._router.navigateByUrl(url);
  }

  goToDefaultPage(): void {
    const url = '/' + [this.basePath, this._teamnoteConfigService.config.WEBCLIENT.GENERAL.WEBCLIENT_DEFAULT_PAGE].join('/');
    this.goToPageByUrl(url);
  }

  goToChatPage(): void {
    const url = '/' + [this.basePath, PageUrlConstant.WEBCLIENT.CHAT].join('/');
    this.goToPageByUrl(url);
  }

  goToContactPage(): void {
    const url = '/' + [this.basePath, PageUrlConstant.WEBCLIENT.CONTACT].join('/');
    this.goToPageByUrl(url);
  }

  goToNewsPage(): void {
    const url = '/' + [this.basePath, PageUrlConstant.WEBCLIENT.NEWS].join('/');
    this.goToPageByUrl(url);
  }

  goToStoreReportPage(): void {
    const url = '/' + [this.basePath, PageUrlConstant.WEBCLIENT.STORE_REPORT].join('/');
    this.goToPageByUrl(url);
  }

  goToQuestionnairePage(): void {
    const url = '/' + [this.basePath, PageUrlConstant.WEBCLIENT.QUESTIONNAIRE].join('/');
    this.goToPageByUrl(url);
  }

  goToTrainingPage(): void {
    const url = '/' + [this.basePath, PageUrlConstant.WEBCLIENT.TRAINING].join('/');
    this.goToPageByUrl(url);
  }

  goToCorporateMaterialPage(): void {
    const url = '/' + [this.basePath, PageUrlConstant.WEBCLIENT.CORPORATE_MATERIAL].join('/');
    this.goToPageByUrl(url);
  }
  goToWebdavMaterialPage(): void {
    const url = '/' + [this.basePath, PageUrlConstant.WEBCLIENT.WEBDAV_MATERIAL].join('/');
    this.goToPageByUrl(url);
  }

}
