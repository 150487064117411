import { Injectable } from '@angular/core';

import { LocationSelectorComponent } from './location-selector.component';
import { TnDialogService } from '../tn-dialog/tn-dialog.service';
import { WatermarkService } from '../watermark/watermark.service';
@Injectable()
export class LocationSelectorService {
  
  constructor(
    private _tnDialogService: TnDialogService,
    private _watermarkService: WatermarkService
  ) { }

  openLocationSelectorModal(locationSelectedFunction: Function): void {
    this._watermarkService.updateWatemarkOverlayZIndexForDialogs(true);
    let dialogRef = this._tnDialogService.openTnDialog(LocationSelectorComponent, {
      locationSelectedFunction: locationSelectedFunction
    });
    dialogRef.beforeClosed().subscribe(result => {
      this._watermarkService.updateWatemarkOverlayZIndexForDialogs(false);
    });
  }

}
