import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { BroadcastGroup } from '../../models/broadcast';
import { BroadcastService } from '../../broadcast.service';
import { UserContact } from '../../../../models/user-contact';

import * as _ from 'lodash';

@Component({
  selector: 'tn-broadcast-set',
  templateUrl: './broadcast-set.component.html',
  styleUrls: ['./broadcast-set.component.scss']
})
export class BroadcastSetComponent implements OnInit {

  @Input() groupId: string = null;
  @Output() onBack: EventEmitter<any> = new EventEmitter<any>();

  isEditing: boolean = false;

  broadcastGroup: BroadcastGroup = new BroadcastGroup("");

  selectedContacts: UserContact[] = [];
  defaultSelectedContactsUserId: string[] = [];

  constructor(
    private _broadcastService: BroadcastService,
    private _cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if (this.groupId) {
      this.isEditing = true;
      this.getBroadcastGroupInfo();
    }
  }

  parseBroadcastGroup(data: BroadcastGroup): void {
    this.defaultSelectedContactsUserId = _.map(data.user_group_members, 'user_id');
    this.broadcastGroup = data;
    this._cdr.detectChanges();
  }

  getBroadcastGroupInfo(): void {
    this._broadcastService.getBroadcastGroupById(
      this.groupId,
      resp => {
        this.parseBroadcastGroup(resp);
      },
      err => {

      }
    );
  }


  back(): void {
    this.onBack.emit(null);
  }

  save(): void {
    let members = [];
    _.each(this.selectedContacts, (c) => {
      if (c && c.user_id) {
        members.push(c.user_id)
      }
    });

    let group: any = {
      name: this.broadcastGroup.name,
      members: members
    };
    if (this.broadcastGroup.user_group_id) {
      group.user_group_id = this.broadcastGroup.user_group_id
    }

    this._broadcastService.setBroadcastGroup(
      group,
      resp => {
        this.back();
      },
      err => {

      }
    );
  }
}
