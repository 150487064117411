import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TeamNoteNotification, NotificationType } from '../models/notification';

@Component({
  selector: 'tn-notification-body',
  templateUrl: './notification-body.component.html',
  styleUrls: ['./notification-body.component.scss']
})
export class NotificationBodyComponent implements OnInit {

  @Input() notifications: TeamNoteNotification[] = [];
  @Output() updateUnreadCount: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  constructor() { }

  ngOnInit() {
    
  }

  preventCloseClick(event: any): void {
    event.stopPropagation();
    this.updateUnreadCount.emit(true);
  }
}
