/**
 * Audio player server
 * 
 * -for controlling HTMLAudioElement singleton
 * 
 * Created by Shan - 2018-05-07
 */

import { Injectable } from '@angular/core';
import { TeamnoteApiService } from '../../api/teamnote-api.service';
import { FileManagerService } from '../file-manager/file-manager.service';
import { BehaviorSubject } from 'rxjs';
import { FileFactoryService } from '../file-factory/file-factory.service';
import { TeamnoteConfigService } from '../../configs/teamnote-config.service';

export class TnPlayingAudio {
  attachmentId?: string;
  totalDuration?: number;
  currentDuration?: number;
  remainingDuration?: number;
  remainingDurationDisplay?: string;

  constructor(attachmentId: string) {
    this.attachmentId = attachmentId;
  }
}

@Injectable()
export class AudioPlayerService {

  audioObj: HTMLAudioElement = new Audio();
  
  cacheConvertedMp3DataUrls: {[attachmentId: string]: string} = {};

  currentlyPlayingAudio: TnPlayingAudio = null;
  currentlyPlayingAudio$: BehaviorSubject<TnPlayingAudio> = new BehaviorSubject<TnPlayingAudio>(null);

  constructor(
    private _teamnoteApiService: TeamnoteApiService,
    private _fileManagerService: FileManagerService,
    private _fileFactoryService: FileFactoryService,
    private _teamnoteConfigService: TeamnoteConfigService
  ) { }

  /**
   * Play audio by src
   * 
   * If audioObj doesn't exist yet, create new one.
   * Try to stop existing audio first
   * Override attribute 'src'
   * 
   * @param {string} src 
   * @memberof AudioPlayerService
   */
  playAudio(src: string, attachmentId?: string): void {
    this.pauseAudio();
    this.audioObj.setAttribute('src', src);
    this.audioObj.setAttribute('crossOrigin', 'anonymous');
    this.audioObj.load();
    this.audioObj.play();

    this.audioObj.ontimeupdate = () => {
      if (this.currentlyPlayingAudio) {
        this.currentlyPlayingAudio.totalDuration = this.audioObj.duration;
        this.currentlyPlayingAudio.currentDuration = this.audioObj.currentTime;
      }
      this.updatePlayingAudioSubject();
    };

    this.audioObj.onended = () => {
      this.pauseAudio();
    };

    this.currentlyPlayingAudio = new TnPlayingAudio(attachmentId);
    this.updatePlayingAudioSubject();
  }

  /**
   * Pause current audio
   * 
   * @returns {void} 
   * @memberof AudioPlayerService
   */
  pauseAudio(): void {
    this.audioObj.pause();

    this.currentlyPlayingAudio = null;
    this.updatePlayingAudioSubject();
  }

  convertAudioToMp3(attachmetId: string, successWithDataUrl: Function): void {
    if (this.cacheConvertedMp3DataUrls[attachmetId]) {
      successWithDataUrl(this.cacheConvertedMp3DataUrls[attachmetId]);
      return;
    }
    let url = "/conversion/" + attachmetId;
    let params = {
      target_mime_type: "audio/mp3"
    };
    this._teamnoteApiService.getFileByUrl(
      url, 
      params, 
      (blob) => {
        this._fileManagerService.fileToDataUrl(
          blob,
          (dataUrl) => {
            this.cacheConvertedMp3DataUrls[attachmetId] = dataUrl;
            successWithDataUrl(dataUrl);
          }
        )
      }, 
      null
    );
  }

  getAudioDataUrlByAttachmentId(attachmentId: string, callbackWithDataUrl: Function): void {
    if (!attachmentId) {
      return;
    }
    if (
      this._teamnoteConfigService.isBrowserIE || 
      this._teamnoteConfigService.isBrowserFirefox || 
      this._teamnoteConfigService.config.GENERAL.IS_RESTRICTED_ATTACHMENT
    ) {
      // If IE/Firefox, need audio to be mp3 in order to play successfully
      // If need to use POST for attachment, it will have DOMException, converting to mp3 works somehow....
      this.convertAudioToMp3(
        attachmentId,
        (dataUrl) => {
          callbackWithDataUrl(dataUrl);
        }
      );
    } else {
      this._fileFactoryService.getFileByAttachmentId(
        attachmentId,
        (dataUrl) => {
          callbackWithDataUrl(dataUrl);
        },
        (err) => {},
        false
      );
    }
  }

  updatePlayingAudioSubject(): void {
    this.currentlyPlayingAudio$.next(this.currentlyPlayingAudio);
  }

  playAudioMessageInChatRoomByAttachmentId(attachmentId: string): void {
    this.getAudioDataUrlByAttachmentId(
      attachmentId,
      (dataUrlSrc) => {
        this.playAudio(dataUrlSrc, attachmentId);
      }
    );
  }

  stopAllAudioMessage(): void {
    this.currentlyPlayingAudio = null;
    this.pauseAudio();
  }

}
