import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StoreReportCustomFieldsMapped } from '../../models/job-report';

@Component({
  selector: 'tn-field-free-text',
  templateUrl: './field-free-text.component.html',
  styleUrls: ['./field-free-text.component.scss']
})
export class FieldFreeTextComponent implements OnInit {

  @Input() field: StoreReportCustomFieldsMapped;
  @Input() isInput: boolean = false;

  @Output() onFieldUpdate: EventEmitter<StoreReportCustomFieldsMapped> = new EventEmitter<StoreReportCustomFieldsMapped>(null);
  freeText: string;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.freeText = this.field.answer.value;
  }

  getAnswerValue(): string {
    return this.freeText;
  }

  onChange() {
    this.field.answer.value = this.getAnswerValue();
    this.onFieldUpdate.emit(this.field);
  }

}
