import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Message } from '../../../models/message'
import { MessageTypeConstant } from '../../../constants/message-type.constant'
import { ModuleKeyDefinition } from '../../../constants/module.constant';
import { TeamNoteLocalStorageKeyConstants } from '../../../constants/local-storage-key.constant';
import { ContactPickerService } from '../../contact-picker/contact-picker.service';

import { Attachment } from '../../../models/attachment'
import { UserContact } from '../../../models/user-contact'
import { ContactCardService } from '../../contact/contact-card/contact-card.service'
import { AttachmentService } from '../../../utilities/attachment/attachment.service'
import { LoggerService } from '../../../utilities/logger/logger.service'
import { ContextMenuService, ContextMenuItem } from '../../../utilities/context-menu/context-menu.service';
import { LocalStorageManagerService } from '../../../utilities/local-storage/local-storage-manager.service';
import { StarredMessagesService } from '../starred-messages.service';
import { ModuleManagerService } from '../../services/module/module-manager.service';
import { ChatRoomService } from '../../chat/chat-room/chat-room.service'
import { UserConstant } from '../../../constants/user.constant'
import { TnNotificationService } from '../../../utilities/tn-notification/tn-notification.service';
import { UtilitiesService } from '../../../utilities/service/utilities.service';
import * as _ from 'lodash';


@Component({
  selector: 'tn-starred-messages-cell',
  templateUrl: './starred-messages-cell.component.html',
  styleUrls: ['./starred-messages-cell.component.scss'],
})
export class StarredMessagesCellComponent implements OnInit {
  @Input() starredMessage: Message
  @Input() isInChat: boolean;
  @Input() isEnableReadTicks: boolean;
  @Input() isEnableStarMessage: boolean;
  @Input() isEnableImportantUsers: boolean;
  @Output() onStarredMessageClick: EventEmitter<Message> = new EventEmitter<Message>(null)

  MSG_TYPE = MessageTypeConstant
  IMPORTANT_LEVEL = UserConstant.IMPORTANT_LEVEL;

  contextMenus: ContextMenuItem[] = [];

  constructor(
    private _attachmentService: AttachmentService,
    private _loggerService: LoggerService,
    private _translate: TranslateService,
    private _contactCardService: ContactCardService,
    private _contextMenuService: ContextMenuService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _starredMessagesService: StarredMessagesService,
    private _moduleManagerService: ModuleManagerService,
    private _contactPickerService: ContactPickerService,
    private _chatRoomService: ChatRoomService,
    private _tnNotificationService: TnNotificationService,
    private _utilitiesService: UtilitiesService,
  ) {}

  ngOnInit() {
    // console.log(this.starredMessage)
  }

  ngOnChanges() {
  }

  getTotalChatMembersCount(chat) {
    return chat.members.length - 1; // except me
  }

  goToMessageInChat(sm: Message) {
    this.onStarredMessageClick.emit(sm)
  }

  onUserAvatarClick(contact: UserContact): void {
    this._loggerService.log('Opening contact card dialog...')

    this._contactCardService.openContactCardModal(contact, false, null, false)
  }

  onAttachmentClick(attachment: Attachment): void {
    this._loggerService.log("Clicked starred message's attachment: " + attachment.attachment_id)

    // Prepare filename Map
    const prefix = this._translate.instant('GENERAL.TEAMNOTE')
    const filenameMap = {}

    const components = attachment.attachment_id.split('.')
    const fileName = components[0]
    const extension = components[components.length - 1]
    // @ts-ignore
    const dateStr = new Date(parseFloat(this.starredMessage.submit_time) * 1000).toISOString().replaceAll('.', '').replaceAll(':', '').replaceAll('Z', '')

    filenameMap[attachment.attachment_id] = `${prefix}_${fileName}.${dateStr}.${extension}`

    this._attachmentService.openAttachmentModal(true, [attachment], fileName, true, filenameMap)
  }

  onLocationClick(body) {
    this._attachmentService.openAttachmentModal(false, body, body.filename, true)
  }

  onReply(m: Message): void {
    this._loggerService.log("Clicked reply on starred message: " + m.message_id);

    this._starredMessagesService.goToChatWithAction(m, 'reply_message')
  }

  onTextMsgCopy(m: Message): void {
    this._loggerService.log("Clicked copy on message: " + m.message_id);
    // this.toggleReply.emit(m);
    this.copyContent(m.parsedBody.message || '')
  }

  /* message context menu */
  onForward(m: Message): void {
    this._loggerService.log("Clicked forward on starred message: " + m.message_id);

    let callback = () => this._starredMessagesService.goToChatWithAction(m, 'forward_message')
  
    this._contactPickerService.openContactPicker(
      'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.FORWARD',
      true,
      true,
      true,
      (targets: any) => this._chatRoomService.forwardMessage(targets, m, null, null, callback),
      true,
      'forward'
    )
  }

  onRecall(m: Message): void {
    this._loggerService.log("Clicked recall on starred message: " + m.message_id);
    // this._chatRoomService.recallMessage(m.message_id);
  }
  onUnStar(m: Message): void {
    this._loggerService.log("Clicked unstar on starred message: " + m.message_id);
    // console.log('onUnStar', m)
    /* 
      unstar here, then recall the star message API, but it hard to know,
      it's hard to determine when to call
    */

    this._chatRoomService.unstarMessage(m.chat_ids[0], m.message_id);
  }

  getContextMenu(m: Message): void {
    this.contextMenus = [];

    if (m.type == MessageTypeConstant.TEXT) {
      this.contextMenus.push({
        label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.COPY',
        action: () => this.onTextMsgCopy(m)
      });
    }

    if (this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.MESSAGE_REPLY)) {
      this.contextMenus.push({
        label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.REPLY',
        action: (event) => this.onReply(m)
      });
    }

    // forward
    this.contextMenus.push({
      label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.FORWARD',
      action: () => this.onForward(m)
    });

    // recall
    if (m.isSentByMe) {
      let recallPeriod = parseInt(this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.MESSAGE_RECALL_PERIOD));
      if (recallPeriod == -1 || (parseFloat(m.timestamp) + recallPeriod) > _.now() / 1000) {
        // this.contextMenus.push({
        //   label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.RECALL',
        //   action: () => this.onRecall(m)
        // });
      }      
    }
    // report

    // untar message
    if (m.message_id) {
      this.contextMenus.push({
        label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.UNSTAR',
        action: () => this.onUnStar(m)
      });
    }
  }


  onContextMenuClick(event: MouseEvent, message: Message): void {
    // event.preventDefault();
    event.stopPropagation(); // prevent parent div event (hideContextMenu() app.component.html)

    // Do not handle click event if chat room is not in normal mode
    // if (this.chatRoomMode != CHAT_ROOM_MODE.NORMAL) {
    //   this._loggerService.debug("Right-Clicked on message when chat room is not normal mode, passing...");
    //   return;
    // }

    this._loggerService.log("Open context menu on starred message: " + message.message_id);
    this.getContextMenu(message);
    this._contextMenuService.showContextMenu(event.clientX, event.clientY, this.contextMenus, message.message_id);
  }

  async copyContent(content: string) {
    try {
      this._utilitiesService.copyValueToClipboard(content);
      // if (navigator.clipboard) {
      //   await window.navigator.clipboard.writeText(content)
      // }
    } catch (err) {
      this._tnNotificationService.showSystemError();
    }
  }
}
