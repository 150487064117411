<div class="form-option-container">
  <div class="option">
    <ng-select
      [clearable]="false"
      [items]="teams"
      [(ngModel)]="team"
      (ngModelChange)="onTeamSelection($event)"
      bindLabel="name"
      [placeholder]="'WEBCLIENT.WORKFLOW.NEW_FORM.TEAM' | translate">
    </ng-select>
  </div>

  <div class="option" *ngIf="isShowTypeOption">
    <ng-select
      [clearable]="false"
      [items]="types"
      [(ngModel)]="type"
      bindLabel="name"
      [placeholder]="'WEBCLIENT.WORKFLOW.NEW_FORM.TYPE' | translate">
    </ng-select>
  </div>

  <div class="form-option-action">
    <button class="btn tn-button-primary" 
      [disabled]="!team || !type" 
      (click)="formOptionDone()">
      {{'GENERAL.CONFIRM' | translate}}
    </button>
  </div>
</div>