<!-- left: menu.isMsgSentByMe ? menu.posX + posX_offset + 'px' : menu.posX + 'px', -->
<div
  #emojiMenu
  class="emoji-menu"
  [ngClass]="{ 'hide-menu': !menu.isShowEmojiMenu }"
  [ngStyle]="{
    zIndex: menu.isShow ? '9' : '-9999',
    left: menu.posX + posX_offset + 'px',
    top: menu.posY + posY_offset + 'px'
  }"
>
  <ng-container *ngIf="menu.isShowEmojiMenu">
    <div
      class="emoji-wrapper"
      [ngClass]="{ 'selected-emoji': menu.emojis.isSentEmoji === e.emoji }"
      *ngFor="let e of menu.emojis.emojiDisplays"
      (click)="menu.emojis.emojiClickCallback(e.emoji)"
    >
      <!-- <div class="emoji" [ngStyle]="{ 'background-image': e.src }"></div> -->
      <div class="emoji">
        {{ e.emoji }}
      </div>
    </div>
    <div class="emoji-wrapper all-emojis" (click)="menu.emojis.emojiSendCallback()">
      <i class="fa fa-ellipsis-h"></i>
    </div>
  </ng-container>
</div>

<div
  #contextMenu
  class="context-menu"
  [ngStyle]="{ zIndex: menu.isShow ? '1001' : '-9999', left: menu.posX + 'px', top: menu.posY + 'px' }"
>
  <div class="context-menu-item clickable" (click)="item.action()" *ngFor="let item of menu.menus">
    <ng-container *ngIf="item.label">{{ item.label | translate }}</ng-container>
    <ng-container *ngIf="item.labelString">{{ item.labelString }}</ng-container>
  </div>
</div>
