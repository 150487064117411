export const ZhChtWorkflowStrings = {
  NEW_FORM: {
    TITLE: "新增表格",
    TEAM: "團隊",
    TYPE: "表格類型",
    NO_PERMISSION: "沒有權限提交{{name}}"
  },

  FILTER: {
    TITLE: "篩選",
    CREATE_DATE: "提交日期",
    TEAM: "團隊",
    TYPE: "表格類型",
    STAGE: "階段",
    CONTENT: "內容",
    APPLY: "應用"
  },

  EXPORT: {
    TITLE: "導出",
    FORMS: "表格",
    TIME_PERIOD: "時間範圍",
    OPTION: {
      AUTOMATIC: "Displaying forms",
      MANUAL: "Configure manually"
    },
    IN_PROGRESS_MSG: "資料導出中，你可以在右上角的通知中心查看進度，你將在檔案準備完成後收到通知。",
    EXPORT_READY_MSG: "檔案已準備完成，你可以在通知中心下載檔案。"
  },

  DETAIL: {
    TITLE: "表格詳情",
    OBSERVER_SUCCESS_MSG: "成功新增觀察者",
    ACCEPT_SUCCESS_MSG: "表格已接受"
  },

  OPTIONS: {
    REJECT: "拒絕",
    SAVE_DRAFT: "儲存草稿",
    SUBMIT: "提交",
    REASSIGN: "重新分配",
    STAGES: "階段",
    ASSIGN: "分派",
    ADD_OBSERVER: "新增觀察者",
    ACCEPT: "接受"
  }
}