import { Injectable } from '@angular/core';
import { TeamnoteApiService } from '../../api/teamnote-api.service';
import { CorporateMaterialFolder, CorporateMaterialEbook, CorporateMaterialEbookPagePointerCookies, CorporateMaterialItem } from './models/corporate-material';

import * as _ from 'lodash';
import { TeamNoteCorporateMaterialConstant } from './constants/corporate-material.constant';
import { TeamNoteApiConstant } from '../../constants/api.constant';
import { AttachmentTypeConstant } from '../../constants/attachment-type.constant';
import { LocalStorageManagerService } from '../../utilities/local-storage/local-storage-manager.service';
import { TeamNoteLocalStorageKeyConstants } from '../../constants/local-storage-key.constant';
import { FtsService } from '../../utilities/fts/fts.service';

@Injectable()
export class CorporateMaterialService {

  folders: CorporateMaterialFolder[];

  ALL_MATERIAL_TYPES: string[] = _.values(TeamNoteCorporateMaterialConstant.TYPE_KEY);
  ROOT_LEVEL_ID: string = TeamNoteCorporateMaterialConstant.ROOT_LEVEL_ID;

  searchingKeywords: {[folderId: string]: string} = {};

  constructor(
    private _teamnoteApiService: TeamnoteApiService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _ftsService: FtsService
  ) { }

  // ----- API -----
  /**
   * Get Corporate material list and store locally
   * 
   * @param {Function} success - success callback
   * @param {Function} failure - failure callback
   * @memberof CorporateMaterialService
   */
  getCorporateMaterialList(success: Function, failure: Function) {
    let url = TeamNoteApiConstant.CORPORATE_MATERIAL.GET_CORPORATE_MATERIAL_LIST;
    this._teamnoteApiService.callApi(
      url,
      {},
      (data) => {
        this.folders = data;

        // reset all search keywords
        this.clearAllSearchKeywords();

        success(data);
      },
      failure
    );
  }

  /**
   * Get ebook object
   * 
   * @param {string} bookId - target ebook id
   * @param {Function} success - success callabck
   * @param {Function} failure - failure callback
   * @memberof CorporateMaterialService
   */
  getCorporateMaterialEbook(bookId: string, success: Function, failure: Function) {
    let url = TeamNoteApiConstant.CORPORATE_MATERIAL.GET_CORPORATE_MATERIAL_EBOOK;
    let params = {
      book_id: bookId
    };
    this._teamnoteApiService.callApi(
      url,
      params,
      success,
      failure
    );
  }

  /**
   * Get full folder display content by folder id,
   * Clear search keyword when getting the full normal folder again.
   * 
   * @param {string} [folderId] - target folder id
   * @param {string[]} [customMatieralTypes] - target material types
   * @returns {CorporateMaterialFolder} - parsed folder
   * @memberof CorporateMaterialService
   */
  getFolderContentByFolderId(folderId?: string, customMatieralTypes?: string[]): CorporateMaterialFolder {
    let materialKeys = this.ALL_MATERIAL_TYPES;
    if (customMatieralTypes) {
      materialKeys = customMatieralTypes;
    }

    this.clearSearchKeywordByFolderId(folderId);

    if (folderId == this.ROOT_LEVEL_ID) {
      // Handle ROOT Folders
      let baseFolder = new CorporateMaterialFolder;
      baseFolder.folder_id = this.ROOT_LEVEL_ID;
      baseFolder.childFolders = this.getRootFolders();
      // Sort Child folders
      baseFolder.childFolders = _.orderBy(baseFolder.childFolders, ['order', 'name']);
      baseFolder.childMaterials = [];
      baseFolder.searchKeyword = this.getSearchKeywordByFolderId(folderId);
      return baseFolder;
    } else {
      let targetFolder = this.getFolderByFolderId(folderId);

      // Get Child Folders
      targetFolder.childFolders = [];
      _.each(targetFolder.user_group_child_group, (r) => {
        let child = this.getFolderByFolderId(r.child_user_group_id);
        if (child) {
          targetFolder.childFolders.push(child);
        }
      });
      // Sort Child folders
      targetFolder.childFolders = _.orderBy(targetFolder.childFolders, [
        'order', 
        (data) => {
          return data.name.toLowerCase();
        }
      ]);

      // Merge all materials
      targetFolder.childMaterials = [];
      _.each(materialKeys, (t) => {
        targetFolder.childMaterials = _.union(targetFolder.childMaterials, targetFolder[t]);
      });
      // Sort all materials
      targetFolder.childMaterials = _.orderBy(targetFolder.childMaterials, [
        'order',
        (data) => {
          return data.name.toLowerCase();
        }
      ]);

      targetFolder.searchKeyword = this.getSearchKeywordByFolderId(folderId);

      return targetFolder;
    }
  }

  /**
   * Store search keyword for each folder, in order to preserve the search result when backing to previous folder.
   */
  setSearchKeywordByFolderId(folderId: string, keyword: string): void {
    this.searchingKeywords[folderId] = keyword;
  }
  
  clearSearchKeywordByFolderId(folderId: string): void {
    this.setSearchKeywordByFolderId(folderId, null);
  }

  clearAllSearchKeywords(): void {
    this.searchingKeywords = {};
  }

  getSearchKeywordByFolderId(folderId: string): string {
    return this.searchingKeywords[folderId];
  }

  /**
   * Search all folders and materials under target folder recursively.
   * Return a dummy folder with current folder's id & name, sort result by folder>name, material>name
   *
   * @param {string} folderId - current folder's id
   * @param {string} keyword - searching keyword
   * @param {string[]} [customMatieralTypes] - target material types
   * @returns {CorporateMaterialFolder}
   * @memberof CorporateMaterialService
   */
  getSearchedFolderContentByFolderId(folderId: string, keyword: string, customMatieralTypes?: string[]): CorporateMaterialFolder {
    // The return will be a dummy folder, with all folders & materials result
    let targetFolders = [];
    let targetMaterials = [];

    // Current folder
    this.setSearchKeywordByFolderId(folderId, keyword);

    let currentFolder = this.getFolderByFolderId(folderId);
    targetMaterials = _.union(targetMaterials, this.getAllMaterialByFolderId(folderId), customMatieralTypes);

    // Child folders
    let allChildFolderIds = this.getAllChildFolderIdsUnderFolderByFolderId(folderId);
    _.each(allChildFolderIds, (folderId) => {
      let childFolder = this.getFolderByFolderId(folderId);
      if (childFolder) {
        targetFolders.push(childFolder);
      }
      
      targetMaterials = _.union(targetMaterials, this.getAllMaterialByFolderId(folderId), customMatieralTypes);
    });

    let searchedFolder = this._ftsService.tnFtsFiltering(targetFolders, keyword, ["name"]);
    let searchedMaterial = this._ftsService.tnFtsFiltering(targetMaterials, keyword, ["name", "description"]);

    let dummyFolder = new CorporateMaterialFolder;
    dummyFolder.folder_id = folderId;
    dummyFolder.name = currentFolder ? currentFolder.name : null;
    dummyFolder.searchKeyword = this.getSearchKeywordByFolderId(folderId);

    dummyFolder.childFolders = _.orderBy(searchedFolder, ["name"]);
    dummyFolder.childMaterials = _.orderBy(searchedMaterial, ["name"]);

    return dummyFolder;
  }

  /**
   * Get ROOT Folders (folder with no user_group_parent_group)
   * 
   * @returns {CorporateMaterialFolder[]} - Root folders
   * @memberof CorporateMaterialService
   */
  getRootFolders(): CorporateMaterialFolder[] {
    return _.filter(this.folders, (f) => {
      return f.user_group_parent_group.length == 0;
    });
  }

  /**
   * Get target folder's parent tree recursively,
   *
   * @param {string} folderId - current folder
   * @returns {string[]}
   * @memberof CorporateMaterialService
   */
  getFolderParentTreeByFolderId(folderId: string): string[] {
    let folderIdTree = [folderId];
    let currentFolder = this.getFolderByFolderId(folderId);
    if (currentFolder && currentFolder.user_group_parent_group.length > 0) {
      let parentId = currentFolder.user_group_parent_group[0].parent_user_group_id;
      let parents = this.getFolderParentTreeByFolderId(parentId);
      folderIdTree = _.union(parents, folderIdTree);
    }
    return folderIdTree;
  }

  /**
   * Get folder by folder id locally
   * 
   * @param {string} folderId - target folder id
   * @returns {CorporateMaterialFolder} 
   * @memberof CorporateMaterialService
   */
  getFolderByFolderId(folderId: string): CorporateMaterialFolder {
    return _.find(this.folders, {folder_id: folderId});
  }

  /**
   * Get folder by folder name locally
   *
   * @param {string} folderName - target folder name
   * @returns {CorporateMaterialFolder}
   * @memberof CorporateMaterialService
   */
  getFolderByFolderName(folderName: string): CorporateMaterialFolder {
    return _.find(this.folders, {name: folderName});
  }

  /**
   * Get all child folders' folder_id recursively
   *
   * @param {string} folderId - current folder id
   * @returns {string[]}
   * @memberof CorporateMaterialService
   */
  getAllChildFolderIdsUnderFolderByFolderId(folderId: string): string[] {
    // Special handling for ROOT level, need to find all ROOT folders as child folders
    if (folderId == this.ROOT_LEVEL_ID) {
      let allRootFolders = this.getRootFolders();
      let childIds = [];
      _.each(allRootFolders, (folder) => {
        childIds.push(folder.folder_id);
        let childFolderIds = this.getAllChildFolderIdsUnderFolderByFolderId(folder.folder_id);
        childIds = _.union(childIds, childFolderIds);
      });
      return childIds;
    }

    let folder = this.getFolderByFolderId(folderId);
    let childIds = [];
    if (!folder) {
      return childIds;
    }
    _.each(folder.user_group_child_group, (ugcg) => {
      childIds.push(ugcg.child_user_group_id);
      let childFolderIds = this.getAllChildFolderIdsUnderFolderByFolderId(ugcg.child_user_group_id);
      childIds = _.union(childIds, childFolderIds);
    });
    return childIds;
  }

  /**
   * Get all materials under folder, based on provided custom types
   *
   * @param {string} folderId - target folder id
   * @param {string[]} [customMatieralTypes] - target material types
   * @returns {any[]}
   * @memberof CorporateMaterialService
   */
  getAllMaterialByFolderId(folderId: string, customMatieralTypes?: string[]): any[] {
    let folder = this.getFolderByFolderId(folderId);
    if (!folder) {
      return [];
    }
    let allMaterials = [];

    let materialKeys = this.ALL_MATERIAL_TYPES;
    if (customMatieralTypes) {
      materialKeys = customMatieralTypes;
    }

    _.each(materialKeys, (t) => {
      allMaterials = _.union(allMaterials, folder[t]);
    });
    return allMaterials;
  }

  /**
   * Get corporate material type by file id 
   * 
   * @param {string} fileIds - target file id
   * @returns {string} - corporate material type
   * @memberof CorporateMaterialService
   */
  getFileTypeByFileIds(fileIds: string): string {
    let fileId = fileIds.split(",")[0];
    let split = fileId.split(".");
    let extension = _.last(split).toLowerCase();

    if (_.includes(AttachmentTypeConstant.IMAGE_TYPES, extension)) {
      return TeamNoteCorporateMaterialConstant.TYPE.IMG;
    }
    if (_.includes(AttachmentTypeConstant.VIDEO_TYPES, extension)) {
      return TeamNoteCorporateMaterialConstant.TYPE.VIDEO;
    }
    if (_.includes(AttachmentTypeConstant.AUDIO_TYPES, extension)) {
      return TeamNoteCorporateMaterialConstant.TYPE.AUDIO;
    }
    if (_.includes(AttachmentTypeConstant.PDF_TYPES, extension)) {
      return TeamNoteCorporateMaterialConstant.TYPE.PDF;
    }
    if (_.includes(AttachmentTypeConstant.DOC_TYPES, extension)) {
      return TeamNoteCorporateMaterialConstant.TYPE.DOC;
    }
    if (_.includes(AttachmentTypeConstant.TXT_TYPES, extension)) {
      return TeamNoteCorporateMaterialConstant.TYPE.TXT;
    }
  }

  // Ebook
  /**
   * Display ebook pages like folder material
   * (deprecated)
   * 
   * @param {CorporateMaterialEbook} ebook - target ebook
   * @returns {CorporateMaterialFolder} - parsed ebook folder
   * @memberof CorporateMaterialService
   */
  getEbookPagesDisplayFolder(ebook: CorporateMaterialEbook): CorporateMaterialFolder {
    let folder = new CorporateMaterialFolder;
    folder.folder_id = ebook.book_id;
    folder.name = ebook.name;
    folder.childFolders = [];
    folder.childMaterials = _.map(ebook.pages, (p) => {
      p.type = TeamNoteCorporateMaterialConstant.TYPE.EBOOK_PAGE;
      return p;
    });
    // Sort all ebook pages
    folder.childMaterials = _.orderBy(folder.childMaterials, ['page_no', 'name']);
    return folder;
  }

  /**
   * Get ebook page cookies
   * 
   * @returns {CorporateMaterialEbookPagePointerCookies[]} - cookies objects array
   * @memberof CorporateMaterialService
   */
  getEbookPageCookies(): CorporateMaterialEbookPagePointerCookies[] {
    let pageCookies = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.CORPORATE_MATERIAL_EBOOK_PAGE_NUMBERS);
    if (!pageCookies || pageCookies.length == 0) {
      return [];
    }
    return JSON.parse(pageCookies);
  }

  /**
   * Set ebook page cookeis
   * 
   * @param {CorporateMaterialEbookPagePointerCookies[]} cookies - latest cookies
   * @memberof CorporateMaterialService
   */
  setEbookPageCookies(cookies: CorporateMaterialEbookPagePointerCookies[]): void {
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.CORPORATE_MATERIAL_EBOOK_PAGE_NUMBERS, JSON.stringify(cookies));
  }

  /**
   * Get ebook page pointer cookie object by book id
   * 
   * @param {string} bookId - target book id
   * @returns {CorporateMaterialEbookPagePointerCookies} - target pointer
   * @memberof CorporateMaterialService
   */
  getEbookPagePointerByBookId(bookId: string): CorporateMaterialEbookPagePointerCookies {
    let current = this.getEbookPageCookies();
    let target = _.find(current, (r) => {
      return r.b == bookId;
    });
    return target;
  }

  /**
   * Set ebook page pointer by book id and page num
   * 
   * @param {string} bookId - target book id
   * @param {number} pageNum - latest page num
   * @memberof CorporateMaterialService
   */
  setEbookPagePointer(bookId: string, pageNum: number): void {
    let current = this.getEbookPageCookies();
    let target = _.find(current, (r) => {
      return r.b == bookId;
    });
    if (target) {
      // If pointer exists already, update its page num
      target.p = pageNum;
      this.setEbookPageCookies(current);
    } else {
      // If pointer doesn't exist yet, add a new cookie obj
      let newCookie: CorporateMaterialEbookPagePointerCookies = {
        b: bookId,
        p: pageNum
      };
      this.setEbookPageCookies(_.union(current, [newCookie]));
    }

  }

}
