export const LanguageConstant = {
  EN: {
    key: 'en',
    label: 'English',
    i18n: 'en'
  },
  ZH_CHT: {
    key: 'zh-cht',
    label: '繁體中文',
    i18n: 'zh-Hant'
  },
  ZH_CHS: {
    key: 'zh-chs',
    label: '简体中文',
    i18n: 'zh-Hans'
  }
}