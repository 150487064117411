<div class="broadcast-modal">
  <div class="modal-header text-color-primary">
    <h4>{{'WEBCLIENT.BROADCAST.TITLE' | translate}}</h4>
  </div>
  <div class="modal-body">
    <tabset>
      <tab (selectTab)="goToSend()">
        <ng-template tabHeading>
          {{'WEBCLIENT.BROADCAST.SEND.TITLE' | translate}}
        </ng-template>

        <tn-broadcast-send 
          *ngIf="currentAction == broadcastActions.send"
          (close)="close()">
        </tn-broadcast-send>
      </tab>
      <tab (selectTab)="goToManage()">
        <ng-template tabHeading>
          {{'WEBCLIENT.BROADCAST.MANAGE.TITLE' | translate}}
        </ng-template>

        <tn-broadcast-manage 
          *ngIf="currentAction == broadcastActions.manage">
        </tn-broadcast-manage>
      </tab>
    </tabset>
  </div>
</div>