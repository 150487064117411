import { TeamNoteConfig } from "../../configs/models/config";

export const ActConfig: TeamNoteConfig = {
  HOST: {
    DOMAIN: "act"
  },
  IMAGES: {
    NOTIFICATION_ICON: "assets/images/clients/act/notification_icon.png",
    FAVICON: "assets/images/clients/act/favicon.png",
    FAVICON_ALERT: "assets/images/clients/act/favicon_alert.png"
  },
  WEBCLIENT: {
    CHATROOM: {
      IS_ENABLE_ATTACH_CORPORATE_MATERIAL: true
    }
  }
}