<div class="message-parent-wrapper">
  <div class="text-wrapper">
    <div class="sender-name">
      <ng-container *ngIf="message.isSentByMe">
        {{'WEBCLIENT.CHATROOM.REPLY.YOU' | translate}}
      </ng-container>
      <ng-container *ngIf="!message.isSentByMe">
        {{message.senderContact.name}}
      </ng-container>
    </div>

    <div class="message-text">
      <ng-container *ngIf="message.parsedBody.is_encrypted">
        <!-- encrypted message preview -->
        <div class="encrypted-message">
          <div
            [ngClass]="{
              'encrypted-message-lock-left-icon': !isSentByMe,
              'encrypted-message-lock-right-icon': isSentByMe
            }"
          ></div>
          <div class="encrypted-message-preview">
            {{'WEBCLIENT.CHATROOM.ENCRYPTED_MESSAGE.MESSAGE_PREVIEW' | translate}}
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!message.parsedBody.is_encrypted">
        <span class="text multiline-text-display" *ngIf="message.type == MSG_TYPE.TEXT">{{message.parsedBody.message}}</span>

        <span class="attachment" *ngIf="message.type == MSG_TYPE.ATTACHMENT">
          <ng-container *ngIf="attachmentType == ATTACHMENT_TYPE.IMAGE">
            <ng-container *ngIf="message.parsedBody.message">
              {{message.parsedBody.message}}
            </ng-container>
            <ng-container *ngIf="!message.parsedBody.message">
              {{'WEBCLIENT.CHATROOM.REPLY.IMAGE' | translate}}
            </ng-container>
          </ng-container>
  
          <ng-container *ngIf="attachmentType == ATTACHMENT_TYPE.VIDEO">
            {{'WEBCLIENT.CHATROOM.REPLY.VIDEO' | translate}}
          </ng-container>
  
          <ng-container *ngIf="attachmentType == ATTACHMENT_TYPE.AUDIO">
            {{'WEBCLIENT.CHATROOM.REPLY.AUDIO' | translate}}
          </ng-container>
  
          <ng-container *ngIf="attachmentType == ATTACHMENT_TYPE.PDF">
            {{message.parsedBody.filename}}
          </ng-container>
  
        </span>
        
        <span class="location" *ngIf="message.type == MSG_TYPE.LOCATION">
          {{message.parsedBody.address}}
        </span>
  
        <span class="sticker" *ngIf="message.type == MSG_TYPE.STICKER">
          {{'WEBCLIENT.CHATROOM.REPLY.STICKER' | translate}}
        </span>
      </ng-container>
    </div>
  </div>

  <div class="image-wrapper" *ngIf="imgSrc && !message.parsedBody.is_encrypted">
    <div class="image-preview" [ngStyle]="{'background-image': imgSrc}"></div>
  </div>
</div>