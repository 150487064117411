import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CorporateMaterialComponent } from './corporate-material.component';
import { UtilitiesModule } from '../../utilities/utilities.module';
import { CorporateMaterialService } from './corporate-material.service';
import { CorporateMaterialListComponent } from './corporate-material-list/corporate-material-list.component';
import { CorporateMaterialFolderComponent } from './corporate-material-folder/corporate-material-folder.component';
import { CorporateMaterialItemComponent } from './corporate-material-item/corporate-material-item.component';
import { MaterialEbookComponent } from './material-ebook/material-ebook.component';

@NgModule({
    imports: [
        CommonModule,
        UtilitiesModule
    ],
    providers: [CorporateMaterialService],
    declarations: [CorporateMaterialComponent, CorporateMaterialListComponent, CorporateMaterialFolderComponent, CorporateMaterialItemComponent, MaterialEbookComponent],
    exports: [CorporateMaterialListComponent]
})
export class CorporateMaterialModule { }
