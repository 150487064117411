<div class="field-attachment-photo-wrapper">
  <ng-container *ngIf="!isInput">
    <div class="field-attachment-view">
      <div class="attachment-wrapper" *ngFor="let ans of answers" (click)="openAttachments(ans)">
        <div class="attachment clickable" [ngStyle]="{'background-image': ans.src}"></div>
      </div>
    </div>
  </ng-container>
  
  <ng-container *ngIf="isInput">
    <div class="btn tn-button-primary" (click)="openFileUploader()">
      {{'GENERAL.SELECT_IMAGE' | translate}}
    </div>
    <div class="field-attachment-input">
      <div class="attachment-row" *ngFor="let ansObj of localAnsObj">
        <div class="attachment-wrapper">
          <div class="attachment" 
            [ngStyle]="{'background-image': 'url(' + ansObj.file.imagePreview + ')'}"
            *ngIf="ansObj.file">
          </div>
          <div class="attachment" 
            [ngStyle]="{'background-image': ansObj.src}"
            *ngIf="ansObj.src">
          </div>
        </div>
        <div class="caption-input-wrapper">
          <input type="text" class="form-control" [(ngModel)]="ansObj.caption" (ngModelChange)="onChange()"/>
        </div>
        <span class="remove-document-button text-color-secondary clickable" 
          (click)="removeAttachment(ansObj)">
          <i class="fa fa-fw fa-times"></i>
        </span>
      </div>
    </div>
  </ng-container>
</div>
