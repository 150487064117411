import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

import { UserContact } from '../../../models/user-contact';
import { UserGroup } from '../../../models/user-group';

import { SearchFilterService } from '../../../utilities/search-filter/search-filter.service';

import * as _ from 'lodash';
import { FtsContactService } from '../../../utilities/fts/contact/fts-contact.service';
import { FtsService } from '../../../utilities/fts/fts.service';
import { WebclientService } from '../../webclient.service';
import { UserContactService } from '../../services/data/user-contact/user-contact.service';
import { Subscription } from 'rxjs'

@Component({
  selector: 'tn-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss']
})
export class ContactListComponent implements OnInit {
  @Input() isShowSearchBar: boolean = true;
  @Input() isAllowSelectAll: boolean = true;
  @Input() excludingUsers: UserContact[] = [];
  @Output() contactCellOnClick = new EventEmitter<UserContact>();
  @Output() userGroupCellOnClick = new EventEmitter<UserGroup>();
  
  @Input() contacts: UserContact[] = [];
  @Input() userGroups: UserGroup[] = [];
  @Input() noJumpToStart: boolean = false;
  @Input() allowSearchUserGroup: boolean = false;
  @Input() allowLocalSearchContact: boolean = false;

  filteredContacts: any[] = [];
  filteredUserGroups: any[] = [];

  displayingList: any[] = [];
  scrollItems: any;

  searchKeyword: string = '';

  isEnableImportantUsers: boolean = false;
  private isImportantUsersLoadedSub: Subscription

  // need to add {read: ElementRef} as there is a directive in this div, we need the ElementRef itself.
  @ViewChild('contactListWrapper', {read: ElementRef, static: false}) contactListWrapper: ElementRef;

  private THROTTLE_THRESHOLD = 100;
  private throttleInitContactList = _.throttle(this.search, this.THROTTLE_THRESHOLD);

  constructor(
    private _searchFilterService: SearchFilterService,
    private _ftsContactService: FtsContactService,
    private _ftsService: FtsService,
    private _userContactService: UserContactService,
    private _webclientService: WebclientService
  ) { 
    this.isImportantUsersLoadedSub = this._userContactService.isImportantUsersLoaded$.subscribe((importantUsers) => {
      this.isEnableImportantUsers = this._webclientService.checkIfEnableImportantUsers();

      if (this.displayingList) {
        this.displayingList = _.cloneDeep(this.displayingList);
      }
    })
  }

  ngOnInit() {
  }

  /**
   * When @Input:contacts or @Input:userGroups is changed, perform search again
   * 
   * @memberof ContactListComponent
   */
  ngOnChanges() {
    // this.search(this.searchKeyword);
    this.throttleInitContactList(this.searchKeyword);
  }

  ngOnDestroy() {
    this.isImportantUsersLoadedSub.unsubscribe()
  }

  /**
   * Emit event when contact is clicked
   * 
   * @param {UserContact} contact - clicked user contact
   * @memberof ContactListComponent
   */
  contactOnClick(contact: UserContact): void {
    this.contactCellOnClick.emit(contact);
  }

  /**
   * Emit event when user group is clicked
   * 
   * @param {UserGroup} userGroup - clicked user group
   * @memberof ContactListComponent
   */
  userGroupOnClick(userGroup: UserGroup): void {
    this.userGroupCellOnClick.emit(userGroup);
  }

  /**
   * Search current contact list
   * 
   * @param {string} keyword - search keyword
   * @memberof ContactListComponent
   */
  search(keyword: string): void {
    if (this.isShowSearchBar) {
      // Only try to search and sort if the search bar is enabled
      this.searchKeyword = keyword;
      if (this.searchKeyword.length > 0) {
        // If user is searching, abandon all user groups and only search users (This will search under ALL contact users under ROOT)
        
        if (this.allowLocalSearchContact) {
          this.filteredContacts = this._ftsContactService.searchContact(this.searchKeyword, this.contacts);
        } else {
          this.filteredContacts = this._ftsContactService.searchContact(this.searchKeyword);
        }
        
        if (this.allowSearchUserGroup) {
          this.filteredUserGroups = this._ftsService.tnFtsFiltering(this.userGroups, this.searchKeyword, ['name']);
          this.filteredContacts = this._ftsContactService.searchContact(this.searchKeyword, this.contacts);
        } else {
          this.filteredUserGroups = [];
        }
      } else {
        // If keyword is empty, simply sort the lists by name relevance
        this.filteredContacts = this._searchFilterService.sortDataByRelevanceByName(this.contacts, this.searchKeyword);
        this.filteredUserGroups = this._searchFilterService.sortDataByRelevanceByName(this.userGroups, this.searchKeyword);
      }
    } else {
      // Do not sort data again if it doesn't have search bar, data should be sorted already
      this.filteredUserGroups = this.userGroups;
      this.filteredContacts = this.contacts;
    }

    // Exclude users
    this.filteredContacts = _.difference(this.filteredContacts, this.excludingUsers);

    // Display user group before user contacts
    this.displayingList = _.union(this.filteredUserGroups, this.filteredContacts);

    // Scroll to the top after searched
    if (!this.noJumpToStart) {
      if (this.contactListWrapper) {
        this.contactListWrapper.nativeElement.scrollTop = 0;
      }
    }
  }


}
