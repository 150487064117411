import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tn-broadcast-manage',
  templateUrl: './broadcast-manage.component.html',
  styleUrls: ['./broadcast-manage.component.scss']
})
export class BroadcastManageComponent implements OnInit {

  manageOptions = {
    list: 'list',
    set: 'set'
  };
  currentManageOption: string;

  editingBroadcastUserGroupId: string = null;

  constructor() { }

  ngOnInit() {
    this.goToList();
  }

  goToList(): void {
    this.currentManageOption = this.manageOptions.list;
  }

  goToSet(id?: string): void {
    this.currentManageOption = this.manageOptions.set;
    this.editingBroadcastUserGroupId = id;
  }

}
