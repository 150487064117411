import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Message } from '../../../../../models/message';

import { SocketService } from '../../../socket/socket.service';

import * as _ from 'lodash';
import { Router } from '@angular/router';
import { LoggerService } from '../../../../../utilities/logger/logger.service';
import { TeamnoteConfigService } from '../../../../../configs/teamnote-config.service';
import { PageUrlConstant } from '../../../../../constants/page-url.constant';
import { AMQPRoutingKey } from '../../../../../constants/amqp-routing-key.constant';
import { Chat } from '../../../../../models/chat';
import { PresenceTypeConstant } from '../../../../../constants/presence-type.constant';
import { SideNavService } from '../../../../../utilities/tn-side-nav/side-nav.service';
import { ModuleKeyDefinition, Module } from '../../../../../constants/module.constant';
import { NewsConstant } from '../../../../../constants/news.constant';
import { FileFactoryService } from '../../../../../utilities/file-factory/file-factory.service';
import { TnDialogService } from '../../../../../utilities/tn-dialog/tn-dialog.service';
import { MessageNewsBody } from '../../../../../models/message-news-body';
import { TimestampService } from '../../../../../utilities/timestamp/timestamp.service';
import { UtilitiesService } from '../../../../../utilities/service/utilities.service';
import { NewsCustomRouteParam } from '../../../../../models/news';
import { NewsCategoryCustomRouteParam } from '../../../../news-category/models/news-category';

interface NewsMessages {
  [newsChannelChatId: string]: Message[]
}
interface UnreadNews {
  [messageId: string] : Message
}

interface NewsChat {
  [chatId: string]: Chat;
}

@Injectable()
export class NewsMessageService {
  public _messageQueueName: string;

  // for tracking all news messages (for news list)
  newsMessages: NewsMessages = {};
  newsMessages$: BehaviorSubject<Message[]> = new BehaviorSubject<Message[]>([]);

  // for tracking all unread news (to update side menu and page title)
  unreadNews: UnreadNews = {};
  unreadNews$: BehaviorSubject<UnreadNews> = new BehaviorSubject<UnreadNews>({});

  // for tracking current opened modal
  activeMessageId: string = '';
  activeMessage$: BehaviorSubject<Message> = new BehaviorSubject<Message>(null);

  newsChats: NewsChat = {};

  constructor(
    private _socketService: SocketService,
    private _router: Router,
    private _loggerService: LoggerService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _sideNavService: SideNavService,
    private _fileFactoryService: FileFactoryService,
    private _tnDialogService: TnDialogService,
    private _timestampService: TimestampService,
    private _utilitiesService: UtilitiesService
  ) { }

  initNewsMessages(): void {
    this.newsMessages = {};
    this.updateNewsSubject();
    this.unreadNews = {};
    this.updateUnreadNewsSubject();
  }

  buildNewsCategoryIdByKey(key: string): string {
    return "news.category." + this._teamnoteConfigService.config.HOST.DOMAIN + "." + key;
  }

  updateNewsMessage(m: Message): void {
    _.each(m.chat_ids, (newsChannelChatId) => {
      this.insertOrUpdateNewsMessageUnderNewsChannel(newsChannelChatId, m);
    });
    // this.updateNewsSubject();
  }
  updateNewsSubject(): void {
    this.newsMessages$.next(this.getAllNews());
  }
  goToNewsPage(): void {
    this._router.navigate(['../' + PageUrlConstant.WEBCLIENT.BASE + '/' + PageUrlConstant.WEBCLIENT.NEWS]);
  }

  // News Chat
  insertOrUpdateNewsChat(chat: Chat, correlationId?: string): void {
    // check if it exists already
    let isExist = false;
    if (this.newsChats[chat.chat_id]) {
      isExist = true;
    }
    // update
    this.newsChats[chat.chat_id] = chat;

    // If this is a new news channel
    if (
      !isExist && 
      chat.t == PresenceTypeConstant.NEWS_CHANNEL &&
      correlationId != AMQPRoutingKey.CORRELATION_ID.GET_ROSTER
    ) {
      this.loadNewsHistoryOfNewsChannel(chat.chat_id);
    }
  }
  deleteNewsChatByChatId(chatId: string): void {
    delete this.newsChats[chatId];
    this.updateNewsSubject();
    this.removeUnreadNewsUnderChat(chatId);
    this.updateUnreadNewsSubject();
  }

  getNewsChannels(): Chat[] {
    return _.filter(this.newsChats, {'t': PresenceTypeConstant.NEWS_CHANNEL});
  }
  getNewsCategories(): Chat[] {
    return _.filter(this.newsChats, {'t': PresenceTypeConstant.NEWS_CATEGORY});
  }
  getAllNewsCategoriesByPrefix(categoryPrefix?: string): Chat[] {
    let cats = this.getNewsCategories();
    if (!categoryPrefix) {
      return cats;
    } else {
      cats = _.filter(cats, (c) => {
        return c.name.indexOf(categoryPrefix) == 0;
      });
      return cats;
    }
  }

  // Unread News
  insertUnreadNews(message: Message): void {
    this.unreadNews[message.message_id] = message;
  }
  getUnreadNewsByMessageId(messageId: string): Message {
    return this.unreadNews[messageId];
  }
  getUnreadNewsByChatId(chatId: string): Message[] {
    return _.filter(this.unreadNews, (unread) => {
      return unread.chat_ids.indexOf(chatId) != -1;
    });
  }
  getUnreadNewsByNewsCategoryPrefix(prefix: string): Message[] {
    let categoriesWithPrefix = this.getAllNewsCategoriesByPrefix(prefix);
    let targetChatIds = _.map(categoriesWithPrefix, "chat_id");
    return _.filter(this.unreadNews, (unread) => {
      return _.intersection(unread.chat_ids, targetChatIds).length > 0;
    });
  }
  removeUnreadNewsByMessageId(messageId: string): void {
    delete this.unreadNews[messageId];
  }
  removeUnreadNewsUnderChat(chatId: string) {
    _.each(this.unreadNews, (message, messageId) => {
      if (message.chat_ids.indexOf(chatId) != -1) {
        this.removeUnreadNewsByMessageId(messageId);
      }
    });
  }
  updateUnreadNewsSubject(): void {
    this._loggerService.debug("Update unread news count: " + _.size(this.unreadNews));
    this._sideNavService.updateSideNavCountByKey(
      ModuleKeyDefinition.NEWS,
      _.size(this.unreadNews)
    );

    // Check extra side nav with News page
    let allNewsExtraSideNav = _.filter(this._teamnoteConfigService.config.WEBCLIENT.SIDE_NAV.SIDE_NAV_EXTRA_MODULES, (module: Module) => {
      return module.PATH == PageUrlConstant.WEBCLIENT.NEWS
    });
    _.each(allNewsExtraSideNav, (sideNavModule: Module) => {
      let param: NewsCustomRouteParam = sideNavModule.PARAMS;
      if (param) {
        if (param.chatId) {
          let chatIdUnread = this.getUnreadNewsByChatId(param.chatId);
          this._sideNavService.updateSideNavCountByKey(
            sideNavModule.KEY,
            chatIdUnread.length
          );
        } else if (param.chatIdKey) {
          let chatIdKeyUnread = this.getUnreadNewsByChatId(this.buildNewsCategoryIdByKey(param.chatIdKey));
          this._sideNavService.updateSideNavCountByKey(
            sideNavModule.KEY,
            chatIdKeyUnread.length
          );
        }
      }
    });

    // Check extra side nav with news category
    let allNewsCategoryExtraSideNav = _.filter(this._teamnoteConfigService.config.WEBCLIENT.SIDE_NAV.SIDE_NAV_EXTRA_MODULES, (module: Module) => {
      return module.PATH == PageUrlConstant.WEBCLIENT.NEWS_CATEGORY
    });
    _.each(allNewsCategoryExtraSideNav, (sideNavModule: Module) => {
      let param: NewsCategoryCustomRouteParam = sideNavModule.PARAMS;
      if (param) {
        if (param.prefix) {
          let prefixedUnread = this.getUnreadNewsByNewsCategoryPrefix(param.prefix);
          this._sideNavService.updateSideNavCountByKey(
            sideNavModule.KEY,
            prefixedUnread.length
          );
        }
      }
    });
    
  }

  insertOrUpdateNewsMessageUnderNewsChannel(newsChannelChatId: string, m: Message): void {
    if (!this.newsMessages[newsChannelChatId]) {
      this.newsMessages[newsChannelChatId] = [];
    }
    let existing = this.getNewsMessageUnderNewsChannelByMessageId(newsChannelChatId, m.message_id);
    if (existing.length > 0) {
      let targetNews = existing[0];
      targetNews = _.assign(targetNews, m);
    } else {
      this.newsMessages[newsChannelChatId].push(m);
    }
  }
  getNewsMessageUnderNewsChannelByMessageId(newsChannelChatId: string, messageId: string): Message[] {
    return _.filter(this.newsMessages[newsChannelChatId], {'message_id': messageId});
  }
  getAllNewsMessageUnderNewsChannel(newsChannelChatId: string): Message[] {
    return this.newsMessages[newsChannelChatId];
  }
  getNewsMessageByMessageId(messageId: string): Message {
    for (var newsChannelChatId in this.newsMessages) {
      var news = this.newsMessages[newsChannelChatId];
      for (var i in news) {
        if (news[i].message_id === messageId) {
          return news[i];
        }
      }
    }
  }

  sortNewsMessageUnderNewsChannel(newsChannelChatId:string): void {
    this.newsMessages[newsChannelChatId] = _.sortBy(this.newsMessages[newsChannelChatId], ['timestamp']);
  }
  
  getEarliestNewsUnderNewsChannels(newsChannelChatIds: string[]): Message {
    let newsArr = this.getAllNewsUnderNewsChannels(newsChannelChatIds);
    if (newsArr) {
      newsArr = _.sortBy(newsArr, ['timestamp']);
      return newsArr[0];
    }
  }
  getAllNewsUnderNewsChannels(newsChannelChatIds: string[]): Message[] {
    let news = [];
    _.each(newsChannelChatIds, (newsChannelChatId) => {
      news = _.union(news, this.getAllNewsMessageUnderNewsChannel(newsChannelChatId));
    });
    return news;
  }
  getAllNews(): Message[] {
    let allNewsChannelChatIds = _.map(this.getNewsChannels(), 'chat_id');
    return this.getAllNewsUnderNewsChannels(allNewsChannelChatIds);
  }
  getLastestAllNewsBySize(size: number): Message[] {
    let all = this.getAllNews();
    all = _.sortBy(all, ['timestamp']);
    return _.takeRight(all, size);
  }
  checkIfTimestampIsEarliestAllNews(timestamp: string): boolean {
    let all = this.getAllNews();
    all = _.sortBy(all, ['timestamp']);
    let firstNews = _.first(all);
    if (firstNews) {
      if (firstNews.timestamp == timestamp) {
        return true;
      }
    }
    return false;
  }

  getNewsUnderNewsCategoryById(newsCategoryId: string): Message[] {
    return this.newsMessages[newsCategoryId];
  }
  getEarliestNewsUnderNewsCategory(newsCategoryId: string): Message {
    let newsArr = this.getNewsUnderNewsCategoryById(newsCategoryId);
    if (newsArr) {
      newsArr = _.sortBy(newsArr, ['timestamp']);
      return newsArr[0];
    }
  }
  getNewsUnderNewsCategoryByIdKey(newsCategoryIdKey: string): Message[] {
    for (let newsCategoryId in this.newsMessages) {
      if (_.last(newsCategoryId.split(".")) == newsCategoryIdKey) {
        return this.getNewsUnderNewsCategoryById(newsCategoryId);
      }
    }
    return [];
  }
  getLastestNewsByCategoryIdAndSize(newsCategoryId: string, size: number): Message[] {
    let all = this.getNewsUnderNewsCategoryById(newsCategoryId);
    all = _.sortBy(all, ['timestamp']);
    return _.takeRight(all, size);
  }
  checkIfTimestampIsEarliestCategoryNews(newsCategoryId: string, timestamp: string): boolean {
    let all = this.getNewsUnderNewsCategoryById(newsCategoryId);
    all = _.sortBy(all, ['timestamp']);
    let firstNews = _.first(all);
    if (firstNews) {
      if (firstNews.timestamp == timestamp) {
        return true;
      }
    }
    return false;
  }


  checkIfThreadOpen(body: MessageNewsBody): boolean {
    if (this._timestampService.checkIfTimeAfterToday(body.thread_start)) {
      return false;
    }
    if (!body.thread_end) {
      return true;
    } else {
      if (this._timestampService.checkIfTimeBeforeToday(body.thread_end)) {
        return false;
      }
    }
    return true;
  }
  checkIfNewsExpired(message: Message): boolean {
    if (this._utilitiesService.checkIfMessageExpired(message.expiry_time)) {
      // Expired
      return true;
    }
    return false;
  }

  openAdvertisementNews(): void {
    // Try to open Advertisement
    let adNewsArr = this.getNewsUnderNewsCategoryByIdKey(NewsConstant.NEWS_CATEGORY.ADVERTISEMENT);
    adNewsArr = _.filter(adNewsArr, (news) => {
      return !this.checkIfNewsExpired(news);
    });
    if (adNewsArr.length > 0) {
      let randomIndex = _.random(adNewsArr.length - 1);
      let adNews = adNewsArr[randomIndex];
      let adNewsParsedBody: MessageNewsBody = JSON.parse(adNews.body);
      let adImage;
      if (adNewsParsedBody.preview_image_v) {
        adImage = adNewsParsedBody.preview_image_v;
      } else {
        adImage = adNewsParsedBody.images ? JSON.parse(adNewsParsedBody.images)[0] : null;
      }
      if (adImage) {
        this._fileFactoryService.getFileByAttachmentId(
          adImage,
          (dataUrl) => {
            this._tnDialogService.openTnAdvertisement(
              dataUrl,
              () => {
                this.activeMessage$.next(adNews);
              }
            );
          },
          (err) => {},
          false
        );
      }
    }
  }

  // AMQP
  subscribeMessageDetail(messageId: string): void {
    if (!messageId) {
      this._loggerService.warn('Trying to subscribe mesage detail without message id, rejected');
      return;
    }
    let correlationId = AMQPRoutingKey.CORRELATION_ID.SUBSCRIBE_MESSAGE_DETAIL;
    let routingKey = AMQPRoutingKey[correlationId];
    correlationId += '_' + messageId;
    let headers = {
      'correlation-id': correlationId,
      'reply_to': this._messageQueueName,
      'message_id': messageId,
      'is_recursive': 1
    };
    let body = '';

    this._socketService.sendMessage(routingKey, headers, body, correlationId);
  }


  loadNewsHistoryOfNewsChannel(newsChannelChatId: string): void {
    this._loggerService.debug("Getting news history (newly received news channel)...");
    let correlationId = AMQPRoutingKey.CORRELATION_ID.LOAD_HISTORY;
    let routingKey = AMQPRoutingKey[correlationId];
    correlationId += '_NEWS_CHANNEL_' + newsChannelChatId + '_' + _.now();
    let headers = {
      'correlation-id': correlationId,
      'chat_id': JSON.stringify([newsChannelChatId]),
      'reply_to': this._messageQueueName,
      'size': this._teamnoteConfigService.config.WEBCLIENT.AMQP.NEWS_LOAD_HISTORY_SIZE
    };
    let body = '';

    this._socketService.sendMessage(routingKey, headers, body, correlationId);
  }

}
