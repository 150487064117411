<div class="tn-content">
  <div class="tn-content-full">
    <tn-page-title [title]="pageTitle ? pageTitle : 'WEBCLIENT.MENU.' + pageTitleKey | translate"></tn-page-title>

    <div class="webview-wrapper">
      <button class="btn tn-button-primary" (click)="openWebview()">
        {{pageTitle ? pageTitle : 'WEBCLIENT.MENU.' + pageTitleKey | translate}}
      </button>
    </div>
  </div>
</div>