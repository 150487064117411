<div class="image-editor">
  <div class="canvas-container">
    <div class="image-editor-canvas" id="image-editor-canvas"></div>
    <div class="preview-blocker-layer">
      <canvas #previewBlockerLayerCanvas class="preview-blocker-layer-canvas"></canvas>
    </div>    
  </div>

  <div class="editor-controls-wrapper">
    <ng-container *ngIf="currentAction == EDITOR_ACTION.PREVIEW">
      <div class="control-row" *ngIf="isAllowImageCaption">
        <input type="text" 
          class="form-control canvas-input" 
          [placeholder]="'UTILITY.IMAGE_CAPTION_PLACEHOLDER' | translate"
          [(ngModel)]="imageCaption"/>
      </div>
      <div class="control-row">
        <div class="control" (click)="draw()">
          <i class="fa fa-fw fa-2x fa-paint-brush"></i>
        </div>
        <div class="control" (click)="crop()">
          <i class="fa fa-fw fa-2x fa-crop"></i>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="currentAction == EDITOR_ACTION.DRAW">
      <div class="control-row">
        <div class="color-option" 
          [ngClass]="{'color-selected': color == currentColor && currentMode == PEN_MODES.PEN}"
          [ngStyle]="{'background-color': color}"
          (click)="setPenColor(color)"
          *ngFor="let color of PEN_COLORS">
        </div>
      </div>
      <div class="control-row">
        <div class="draw-control" [ngClass]="{'draw-control-selected': currentMode == PEN_MODES.PEN}" (click)="setPenMode(PEN_MODES.PEN)">
          <i class="fa fa-fw fa-2x fa-pencil"></i>
        </div>
        <!-- <div class="draw-control" [ngClass]="{'draw-control-selected': currentMode == PEN_MODES.ERASER}" (click)="setPenMode(PEN_MODES.ERASER)">
          <i class="fa fa-fw fa-2x fa-eraser"></i>
        </div> -->
        <div class="draw-control" (click)="setPenMode(PEN_MODES.CLEAR)">
          <i class="fa fa-fw fa-2x fa-undo"></i>
        </div>
      </div>
    </ng-container>
    
  </div>

  <div class="modal-footer">
    <button class="btn btn-default" (click)="cancel()">
      {{'GENERAL.CANCEL' | translate}}
    </button>
    <button class="btn tn-button-primary" (click)="done()">
      {{'GENERAL.DONE' | translate}}
    </button>
  </div>
  
</div>