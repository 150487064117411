import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, HostListener, DoCheck } from '@angular/core';

import { Attachment } from '../../../models/attachment';

import { FileManagerService } from '../../file-manager/file-manager.service';
import { WatermarkService } from '../../watermark/watermark.service';
import { ModuleKeyDefinition } from '../../../constants/module.constant';
import { ModuleManagerService } from '../../../webclient/services/module/module-manager.service';
import { FileFactoryService } from '../../file-factory/file-factory.service';
import { TnNotificationService } from '../../tn-notification/tn-notification.service';
import { TeamnoteConfigService } from '../../../configs/teamnote-config.service';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';

// let PDFJS: any = require('pdfjs-dist');

// import * as PDFJS from "pdfjs-dist/web/pdf_viewer";
// import { getDocument } from "pdfjs-dist/build/pdf.js";

@Component({
  selector: 'tn-attachment-pdf',
  templateUrl: './attachment-pdf.component.html',
  styleUrls: ['./attachment-pdf.component.scss']
})
export class AttachmentPdfComponent implements OnInit, DoCheck {
  @Input() attachment: Attachment;
  @Input() attachmentId: string;
  @Input() filename: string;
  @Input() isDisableWatermark: boolean = false;
  @Input() isDisableDownload: boolean = false;
  @Input() isChatMessage: boolean = false;
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  @ViewChild ('pdfCanvas', {static: false}) pdfCanvas: ElementRef;
  @ViewChild('imageWatermarkCanvas', {static: false}) imageWatermarkCanvas: ElementRef;
  @ViewChild('viewer', {static: false}) viewer: PdfJsViewerComponent;

  isPDF: boolean = false;
  isAllowAttachmentSave: boolean = false;
  fileUrl: string;
  pdfPassword: string = null;
  page: number = 1;
  isLoaded: boolean = false;

  pdfDoc = null;
  pageNum = 1;
  pageCount = 0;
  firstRender = true;
  pageRendering = false;
  pageNumPending = null;
  scale = 1;
  canvas = null;
  ctx = null;

  canvasHeight: number = 0;
  canvasWidth: number = 0;

  constructor(
    private _fileManagerService: FileManagerService,
    private _watermarkService: WatermarkService,
    private _moduleManagerService: ModuleManagerService,
    private _fileFactoryService: FileFactoryService,
    private _tnNotificationService: TnNotificationService,
    private _teamnoteConfigService: TeamnoteConfigService
  ) {

    // PDFJS.GlobalWorkerOptions.workerSrc = `./js/pdf.worker.min.js`;
    // PDFJS.cMapUrl = `./cmaps/`;
    // PDFJS.cMapPacked = true;
  }

  ngOnInit() {
  }
  ngOnChanges() {
    if (!this.attachmentId) {
      this.fileUrl = '';
      this.isLoaded = false;
      return;
    }
    if (this.attachmentId.includes('blob:http')) {
      this.isPDF = this._fileManagerService.checkIfAttachmentIsPdf(this.filename);
    }else {
      this.isPDF = this._fileManagerService.checkIfAttachmentIsPdf(this.attachmentId);
    }

    if (this.isChatMessage) {
      this.isAllowAttachmentSave = this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.ATTACHMENT_SAVE);

      //Always allow attachment download
      if (this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.IS_ALWAYS_ALLOW_DOWNLOAD){
        this.isAllowAttachmentSave = true;
      }

      if (!this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.IS_DISABLE_ENCRYPTED_MSG_DOWNLOAD){
        this.isDisableDownload = false;
      }
      // this.isDisableDownload = !this.isAllowAttachmentSave;
    }

    // this.page = 1;

    this._fileFactoryService.getFileByAttachmentId(
      this.attachmentId,
      (blobUrl) => {
        // this.fileUrl = blobUrl;
        // console.log(this.fileUrl)

        // console.log(this.viewer)
        if (this.viewer) {
          /* 
            assign the blobUrl value to pdfSrc attribute on pdf viewer component
            as soon as the blobUrl value is received from the server, then ask 
            pdf viewer to load pdf manually 
          */
          this.viewer.pdfSrc = blobUrl; // assign the blobUrl to pdfSrc  
          this.viewer.refresh() // ask pdf viewer to load pdf manually 
        } else {
          this.fileUrl = blobUrl
        }
        // this.onPdfInit();
      },
      (err) => {},
      true
    )
    console.log(this.fileUrl)
  }

  ngDoCheck() {
    /* if (this.fileUrl !== undefined || typeof this.fileUrl !== 'undefined') {
        console.log('fileUrl change, new value:',this.fileUrl);
    } */
  }

  // for debug
  getFileUrl() {
    console.log(this.fileUrl)
  }

  ngAfterViewInit() {
    // this.onPdfInit();
    this.drawOverlayWatermark();
  }

  ngOnDestroy() {
    if (this.pdfDoc) {
      this.pdfDoc.destroy();
    }
  }

  drawOverlayWatermark(): void {
    if (this.isDisableWatermark) {
      return;
    }
    if (!this.imageWatermarkCanvas) {
      return;
    }
    let watermarkCanvas: HTMLCanvasElement = this.imageWatermarkCanvas.nativeElement;
    let parent = watermarkCanvas.parentElement;
    let imgW = parent.clientWidth;
    let imgH = parent.clientHeight;
    watermarkCanvas.width = imgW;
    watermarkCanvas.height = imgH;
    let watermarkContext = watermarkCanvas.getContext("2d");
    this._watermarkService.drawWatermark(watermarkContext, imgW, imgH);
  }

  modifyExternalLinks(): void {
    // Modify External Links
    const viewer = this.viewer.iframe.nativeElement.contentWindow.document.getElementById('viewer');

    if (viewer) {
      const externalLinks = viewer.getElementsByTagName('a');

      // console.log(externalLinks);

      for (let i = 0; i < externalLinks.length; i++) {
        externalLinks[i].setAttribute('target', '_blank');
      }
    }
  }

  hideViewerLayout($event): void {
    let appConfig = this.viewer.PDFViewerApplication.appConfig;
    
    appConfig.sidebar.toggleButton.style.display = "none";
    appConfig.secondaryToolbar.toggleButton.style.display = "none";
    // console.warn(appConfig);
    this.modifyExternalLinks();
  }

  downloadFile(): void {
    if (this.attachmentId.includes('blob:http')) {
      const link = document.createElement('a');
      link.download = this.filename;
      link.href = this.attachmentId;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }else {
      this._fileManagerService.downloadFileByAttachmentId(
        this.attachmentId, this.attachment ? this.attachment.filename : this.attachmentId);
    }
  }

  configWatermark (width, height) {
    let watermarkCanvas: any = document.getElementById('watermark-canvas');
    watermarkCanvas.width = width;
    watermarkCanvas.height = height;

    let watermarkCtx = watermarkCanvas.getContext("2d");
    // Use the identity matrix while clearing the canvas
    watermarkCtx.setTransform(1, 0, 0, 1, 0, 0);
    watermarkCtx.clearRect(0, 0, width, height);

    this._watermarkService.drawWatermark(watermarkCtx, width, height);
  };

  renderPage(num) {
    this.pageRendering = true;
    // Using promise to fetch the page
    this.pdfDoc.getPage(parseInt(num)).then((page) => {
      let viewport = page.getViewport(this.scale);

      let w = viewport.width;
      let h = viewport.height;

      // max 80vh & 80vw, *parent has padding:24px;
      // let maxH = window.innerHeight * 0.8;
      // let maxW = window.innerWidth * 0.8 - 48;

      // if (h > maxH) {
      //   w = w * (maxH / h);
      //   h = maxH;
      // }

      // if (w > maxW) {
      //   h = h * (maxW / w);
      //   w = maxW;
      // }

      this.canvas.height = h;
      this.canvasHeight = h;

      this.canvas.width = w;
      this.canvasWidth = w;

      // Render PDF page into canvas context
      var renderContext = {
          canvasContext: this.ctx,
          viewport: viewport
      };
      var renderTask = page.render(renderContext);

      // Wait for rendering to finish
      renderTask.promise.then(() => {
        this.pageRendering = false;

        // Draw watermark after page rendering finishes
        this._watermarkService.drawWatermark(this.ctx, w, h);

        if (this.pageNumPending !== null) {
          // New page rendering is pending
          this.renderPage(this.pageNumPending);
          this.pageNumPending = null;
        }
        if (this.firstRender) {
          this.firstRender = false;
          // this.configWatermark(w, h);
        }
      });
    });
  }

  queueRenderPage(num) {
    if (this.pageRendering) {
      this.pageNumPending = num;
    } else {
      this.renderPage(num);
    }
  }

  prevPage() {
    if (this.pageNum <= 1) {
      return;
    }
    this.pageNum--;
    this.onPageChange();
  }

  nextPage() {
    if (this.pageNum >= this.pageCount) {
      return;
    }
    this.pageNum++;
    this.onPageChange();
  }

  onPageChange() {
    this.modifyExternalLinks();

    if (!this.pageNum) {
      return;
    }
    if (this.pageNum < 1 || this.pageNum > this.pageCount) {
      return;
    }
    this.renderPage(this.pageNum);
  }

  // onPdfPasswordRequest(): void {
  //   this._tnNotificationService.showPrompt(
  //     null,
  //     "GENERAL.FILE_PDF_PASSWORD_REQUIRED",
  //     () => {
  //       this.closePdfWithErrorKey("GENERAL.FILE_PDF_PASSWORD_ERROR");
  //     },
  //     (password) => {
  //       this.pdfPassword = password;
  //       this.onPdfInit();
  //     },
  //     true
  //   );
  // }

  // onPdfInit() {
  //   this.canvas = document.getElementById('pdf-canvas');
  //   console.log('onPdfInit')
  //   if (this.fileUrl && this.canvas) {
  //     this.ctx = this.canvas.getContext('2d');
  //     PDFJS.getDocument({
  //       url: this.fileUrl,
  //       password: this.pdfPassword,
  //       cMapUrl: `./cmaps/`,
  //       cMapPacked: true
  //     }).then((pdfDoc_) => {
  //       this.pdfDoc = pdfDoc_;

  //       // Initial/first page rendering
  //       this.renderPage(this.pageNum);

  //       this.pageCount = this.pdfDoc.numPages;
  //     }, (err) => {
  //       if (err.name == "PasswordException") {
  //         if (this._teamnoteConfigService.config.GENERAL.IS_SUPPORT_PASSWORD_PDF) {
  //           switch (err.code) {
  //             case 1:
  //               // Need password
  //               this.onPdfPasswordRequest();
  //               break;
  //             case 2:
  //               // Incorrect password
  //               this.closePdfWithErrorKey("GENERAL.FILE_PDF_PASSWORD_ERROR");
  //               break;
  //           }
  //         } else {
  //           this.closePdfWithErrorKey("GENERAL.FILE_PDF_PASSWORD_NOT_SUPPORT");
  //         }
  //       } else {
  //         this.closePdfWithErrorKey("GENERAL.FILE_OPEN_ERROR");
  //       }
  //     });
  //   }
  // }

  closePdfWithErrorKey(key: string): void {
    this._tnNotificationService.showCustomErrorByTranslateKey(key);
    this.close.emit(true);
  }

  @HostListener("window:keydown", ["$event"]) handleKeyDown(event: KeyboardEvent) {
    switch (event.key) {
      case "ArrowRight":
        this.nextPage();
        break;
      case "ArrowLeft":
        this.prevPage();
        break;
    }
  }

}

