<div class="field-multiple-choice-wrapper">

  <div class="mc-option" *ngFor="let option of detail.options">
    <label class="mc-label">
      <input type="radio" [name]="fieldId" [(ngModel)]="selectedValue" [value]="option" [disabled]="!isInput" (ngModelChange)="onChange($event)">
      <span class="option-text">&nbsp;{{option}}</span>
    </label>
  </div>

</div>
