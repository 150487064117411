<div class="corporate-material-item clickable ">
  <div class="icon {{fileType}}"></div>
  <div class="info">
    <div class="name">{{name}}</div>
  </div>
  <div class="folder-arrow text-color-secondary" *ngIf="unRead">
    <i class="fa fa-fw fa-circle icon-green "></i>
  </div>
  <div>
    <div class="btn-group tn-dropdown-group " dropdown placement="bottom">
      <button dropdownToggle type="button" class="dropdown-toggle tn-dropdown-button ">
        <div class="more"></div>
      </button>
      <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
        <li role="menuitem">
          <div class="tn-dropdown-item nameVector" *ngIf="!isPin" (click)="onSetPin()">
            <div class="vector"></div>
            {{'WEBCLIENT.WEBDAV.SETPIN' | translate}}
          </div>
        </li>
        <li role="menuitem">
          <div class="tn-dropdown-item nameVector" *ngIf="isPin" (click)="onUnsetPin()">
            <div class="vector"></div>
            {{'WEBCLIENT.WEBDAV.UNSETPIN' | translate}}
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="folder-arrow text-color-secondary" *ngIf="isDisplay">
    <i class="fa fa-fw fa-angle-right "></i>
  </div>
</div>