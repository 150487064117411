<div class="tn-contact-cell" *ngIf="contact" [ngClass]="{'clickable': isClickable}">
  <ng-content select=".additional-left"></ng-content>
  <div class="contact-cell-main-container"
       (click)="onClick()">
    <div class="avatar-container"
         *ngIf="!hideGroupAvatar || contact.user_id">
      <div class="avatar-wrapper">
        <div class="avatar"
          [ngClass]="{'default-contact-avatar': contact.user_id, 'default-group-avatar': contact.user_group_id}"
          [ngStyle]="{'background-image': avatarImageSrc}"></div>
        <div class="device-icon" *ngIf="contact.device_count > 0"></div>
      </div>
    </div>
    <div class="info-wrapper">
      <div
        class="name text-color-primary"
        [ngClass]="{
          'important-user': isEnableImportantUsers,
          'global-vip': contact.importantLevel === IMPORTANT_LEVEL.GLOBAL,
          'individual-vip': contact.importantLevel === IMPORTANT_LEVEL.INDIVIDUAL
        }"
      >
        {{displayName}}
        <ng-container *ngIf="isEnableImportantUsers">
          <div class="global-vip-icon" *ngIf="contact.importantLevel === IMPORTANT_LEVEL.GLOBAL"></div>
          <div class="individual-vip-icon" *ngIf="contact.importantLevel === IMPORTANT_LEVEL.INDIVIDUAL"></div>
        </ng-container>

        <ng-container *ngIf="contact.deleted">
          {{'WEBCLIENT.CONTACT.DELETED_POSTFIX' | translate}}
        </ng-container>
      </div>
      <ng-container *ngIf="isShowDesc">
        <div class="desc text-color-secondary" *ngFor="let desc of descFields;">
          {{contact[desc]}}
        </div>
      </ng-container>
      <ng-content select=".extra-desc"></ng-content>
    </div>
  </div>
  <ng-content select=".additional-right"></ng-content>
  <ng-content select=".additional-selection"></ng-content>
</div>
