import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { TeamnoteConfigService } from '../../configs/teamnote-config.service';
import { TimestampService } from '../timestamp/timestamp.service';
import * as JsEncryptModule from 'jsencrypt';

import * as jwtSimple from 'jwt-simple'

@Injectable()
export class UtilitiesService {

  KEYBOARD_CHAR_REGEX = /[a-zA-Z0-9\t\n ,./<>?;:"'`~!@#$%^&*()\[\]{}_+=|\\-]/;

  constructor(
    private _teamnoteConfigService: TeamnoteConfigService,
    private _timestampService: TimestampService
  ) { }

  getChunkedMessageArr(arr: any[]): any[] {
    return _.chunk(arr, this._teamnoteConfigService.config.WEBCLIENT.GENERAL.MESSAGE_CHUNK_SIZE);
  }

  checkIfMessageExpired(expiryTime: any): boolean {
    if (expiryTime && this._timestampService.checkIfTimeBeforeToday(expiryTime)) {
      return true;
    }
    return false;
  }

  checkIfStrIsEngOrNumOnly(string: string): boolean {
    for (var charIndex = 0; charIndex < string.length; charIndex++) {
      if (!this.checkIfCharIsEngOrNum(string[charIndex])) {
        return false;
      }
    }
    return true;
  }

  checkIfNonKeyboardCharOnly(string: string): boolean {
    for (var charIndex = 0; charIndex < string.length; charIndex++) {
      if (this.checkIfCharIsEngOrNum(string[charIndex])) {
        return false;
      }
    }
    return true;
  }

  checkIfCharIsEngOrNum(char: string): boolean {
    return this.KEYBOARD_CHAR_REGEX.test(char);
  }

  checkIfKeyEnterShouldSend(isWithShiftKey: boolean): boolean {
    if (this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.SHIFT_ENTER_TO_SEND && isWithShiftKey) {
      return true;
    }

    if (!this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.SHIFT_ENTER_TO_SEND && !isWithShiftKey) {
      return true;
    }

    return false;
  }

  copyValueToClipboard(value: string): void {
    // Create dummy element
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.setAttribute("id", "dummyInput");
    dummy.value = value;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  };

  getEncryptedValueByPublicKey(value: string, publicKey: string): string | boolean {
    let encrypt = new JsEncryptModule.JSEncrypt();
    encrypt.setPublicKey(publicKey);
    var encryptedValue = encrypt.encrypt(value);
    return encryptedValue;
  }

  decodeJwt(jwt: string, publicKey: string, callback: Function): void {
    try {
      let decoded = jwtSimple.decode(jwt, publicKey);
      callback(decoded);
    } catch (err) {
      callback(null)
    }
  }
}
