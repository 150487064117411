import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StoreReport, JobDispatch, Store, StoreReportType } from '../models/job-report';
import { JobReportConstant } from '../constants/job-report.constants';
import { CellFormatHelperService } from '../helper/cell-format-helper.service';
import { JobReportService } from '../job-report.service';

import * as _ from 'lodash';
import { TextMessagePart } from '../../../utilities/linkify/linkify.service';
import { DomSanitizer } from '../../../../../node_modules/@angular/platform-browser';

@Component({
  selector: 'tn-job-report-cell',
  templateUrl: './job-report-cell.component.html',
  styleUrls: ['./job-report-cell.component.scss']
})
export class JobReportCellComponent implements OnInit {

  @Input() report: StoreReport;
  @Input() viewingReport: StoreReport;

  @Input() job: JobDispatch;
  @Input() viewingJob: JobDispatch;

  @Input() isEnabledJobDispatch: boolean;
  @Output() onReportCellClick: EventEmitter<StoreReport> = new EventEmitter<StoreReport>(null);
  @Output() onJobCellClick: EventEmitter<JobDispatch> = new EventEmitter<JobDispatch>(null);

  isSelected: boolean = false;
  REPORT_STATUS = JobReportConstant.JOB_DISPATCH_STATUS;

  dateTimestamp: string;
  reportStatusStr: string;
  report_no: string;
  store: Store;
  type: StoreReportType;

  infoListFormatHtml: string = "";
  infoListFormatDisplayArr: TextMessagePart[] = [];

  constructor(
    private _cellFormatHelperService: CellFormatHelperService,
    private _jobReportService: JobReportService,
    private _domSanitizer: DomSanitizer
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.parseDisplay();
  }

  parseDisplay(): void {
    this.isSelected = false;
    if (this.viewingReport && this.viewingReport.report_id == this.report.report_id) {
      this.isSelected = true;
    }
    if (this.viewingJob && this.viewingJob.job_id == this.job.job_id) {
      this.isSelected = true;
    }
    if (this.report) {
      this.reportStatusStr = this.report.reportStatusStr;
    } else {
      this.reportStatusStr = this.job.report.reportStatusStr;
    }

    this.infoListFormatHtml = this._cellFormatHelperService.getDisplayingListCellInfoByType(this.report, this.job);
    this.handleSpcialLocalTranslateStrings();
  }

  handleSpcialLocalTranslateStrings(): void {
    this.infoListFormatDisplayArr = this._cellFormatHelperService.handleSpcialLocalTranslateStrings(this.infoListFormatHtml);
  }

  onClick() {
    if (this.job) {
      this.onJobCellClick.emit(this.job);
    } else {
      this.onReportCellClick.emit(this.report);
    }
  }
}
