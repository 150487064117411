import { Component, OnInit, Inject } from '@angular/core';
import { WorkflowCustomRouteParam, WorkflowTeam, WorkflowType, WorkflowTypeStage } from '../model/workflow';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { TimestampService } from '../../../utilities/timestamp/timestamp.service';
import { WorkflowService } from '../services/workflow.service';

import * as _ from 'lodash';
var subDays = require('date-fns/sub_days');
var format = require('date-fns/format');

import { CronJobTaskInitialResponse, CronJobType, CronjobExportForm } from '../../../utilities/cronjob-tracker/models/cronjob';
import { CronjobTrackerService } from '../../../utilities/cronjob-tracker/cronjob-tracker.service';
import { TnNotificationService } from '../../../utilities/tn-notification/tn-notification.service';

export class FormExportApiParam {
  form_id?: string[];

  team_id?: string;
  type_id?: string;

  create_date?: string[];

  constructor(formIds?: string[], teamId?: string, typeId?: string, startDateSec?: string, endDateSec?: string) {
    if (startDateSec && endDateSec) {
      this.create_date = [startDateSec, endDateSec];
    } else {
      this.create_date = null;
    }
    this.team_id = teamId;
    this.type_id = typeId;
    this.form_id = formIds;
  }
}

@Component({
  selector: 'tn-form-export',
  templateUrl: './form-export.component.html',
  styleUrls: ['./form-export.component.scss']
})
export class FormExportComponent implements OnInit {

  customParam: WorkflowCustomRouteParam = {};

  exportTargetOptions = {
    automatic: 1,
    manual: 2
  };
  exportTarget: number = 0;

  formIds: string[] = [];

  // data

  timePeriod: Date[] = [subDays(new Date(), 7), new Date()];
  dateInputConfig: Partial<BsDatepickerConfig>;

  teams: WorkflowTeam[] = [];
  team: any = null;

  types: WorkflowType[] = [];
  type: any = null;

  allOption: any = {isAll: true, team_id: null, type_id: null, stage_id: null};

  constructor(
    public dialogRef: MatDialogRef<FormExportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _workflowService: WorkflowService,
    private _timestampService: TimestampService,
    private _cronjobTrackerService: CronjobTrackerService,
    private _tnNotificationService: TnNotificationService
  ) { }

  ngOnInit() {
    this.dialogRef.updateSize('50vw');

    this.formIds = this.data.formIds;
    this.customParam = this.data.customParam;

    this.dateInputConfig = Object.assign({}, { 
      containerClass: "theme-default",
      rangeInputFormat: "YYYY-MM-DD"
    });

    this.prepareSelectionOption();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  prepareSelectionOption(): void {
    this.exportTarget = this.exportTargetOptions.manual;

    this.getOptions();
  }

  getOptions(): void {
    this._workflowService.getWorkflowOptions(
      (resp) => {
        this.teams = this._workflowService.teams;
        this.teams = _.union([this.allOption], this.teams);
        this.team = this.allOption;

        this.types =this._workflowService.types;
        this.types = _.union([this.allOption], this.types);
        this.type = this.allOption;
      }
    );
  }

  export(): void {
    let param = null;
    switch (this.exportTarget) {
      case this.exportTargetOptions.automatic:
        param = new FormExportApiParam(this.formIds, null, null, null, null);
        break;
      case this.exportTargetOptions.manual:
        param = new FormExportApiParam(
          null,
          this.team.isAll ? null : this.team.team_id,
          this.type.isAll ? null : this.type.type_id,
          this._timestampService.getStartOfDateSecondStringByDateObj(this.timePeriod[0]),
          this._timestampService.getEndOfDateSecondStringByDateObj(this.timePeriod[1])
        );
        break;
    }

    this._workflowService.exportForm(
      param,
      (resp: CronJobTaskInitialResponse) => {
        let timeDisplay = this._timestampService.dateObjToTimeDisplay(this.timePeriod[0], false) + ' - ' + this._timestampService.dateObjToTimeDisplay(this.timePeriod[1], false);

        let fileNameComponents = [
          "export_workflow_forms",
          format(new Date(), "YYYYMMDD_HHmm")
        ];

        this._cronjobTrackerService.startTrackingCronjob(
          resp.job_id,
          CronJobType.EXPORT_FORM,
          new CronjobExportForm(
            timeDisplay,
            this.team.isAll ? "GENERAL.ALL" : null,
            !this.team.isAll ? this.team.name : null,
            this.type.isAll ? "GENERAL.ALL" : null,
            !this.type.isAll ? this.type.name : null,
            fileNameComponents.join("_"),
            "WEBCLIENT.WORKFLOW.EXPORT.EXPORT_READY_MSG"
          ),
          null,
          "WEBCLIENT.WORKFLOW.EXPORT.TITLE"
        );
        this._tnNotificationService.showCustomInfoByTranslateKey("WEBCLIENT.WORKFLOW.EXPORT.IN_PROGRESS_MSG");
        this.cancel();
      }
    );
  }

}
