import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { Questionnaire, Question, RatingOption } from '../models/questionnaire';
import { QuestionnaireService } from '../questionnaire.service';
import { TeamNoteQuestionnaireConstant } from '../constants/questionnaire.constant';

import * as _ from 'lodash';
import { TnNotificationService } from '../../../utilities/tn-notification/tn-notification.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'tn-questionnaire-view',
  templateUrl: './questionnaire-view.component.html',
  styleUrls: ['./questionnaire-view.component.scss']
})
export class QuestionnaireViewComponent implements OnInit {

  @Input() questionnaire: Questionnaire;
  @Output() backToList: EventEmitter<any> = new EventEmitter<any>();

  questions: Question[];

  CONSTANT = TeamNoteQuestionnaireConstant;

  isDialog: boolean = false;

  constructor(
    private _questionnaireService: QuestionnaireService,
    private _tnNotificationService: TnNotificationService,
    public dialogRef: MatDialogRef<QuestionnaireViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if (this.data.questionnaire) {
      this.dialogRef.updateSize('60vw', '80vh')
      this.questionnaire = this.data.questionnaire;
      this.isDialog = true
      this.parseQuestionnaire()
    }
  }

  ngOnChanges() {
    this.parseQuestionnaire()
  }

  questionnaireBack() {
    if (this.dialogRef && this.dialogRef.close) {
      this.dialogRef.close();
    }
    this.backToList.emit();
  }

  parseQuestionnaire() {
    this.questions = this._questionnaireService.parseQuestionnaireIntoQuestion(this.questionnaire);
  }

  submit() {
    if (!this._questionnaireService.validateAnsweredQuestions(this.questions)) {
      this._tnNotificationService.showCustomErrorByTranslateKey('WEBCLIENT.QUESTIONNAIRE.INPUT_ALL_FIELDS');
      return;
    }
    this._questionnaireService.parseQuestionAnswerAndSubmit(
      this.questionnaire.questionnaire_id, 
      this.questions,
      (resp) => {
        this._tnNotificationService.showCustomInfoByTranslateKey('WEBCLIENT.QUESTIONNAIRE.SUBMITTED_MSG');
        this.questionnaireBack();
      },
      (err) => {
        this._tnNotificationService.showSystemError();
      }
    );
  }
  

}
