<div class="tn-content">
  <div class="tn-content-left" [ngClass]="{'current-focus-content': !activeQuestionnaire, 'current-unfocus-content': activeQuestionnaire}">
    <tn-questionnaire-list
      [questionnaires]="questionnaires"
      [activeQuestionnaire]="activeQuestionnaire"
      (onItemClick)="onItemClick($event)">
    </tn-questionnaire-list>
  </div>
  <div class="tn-content-right" [ngClass]="{'current-focus-content': activeQuestionnaire, 'current-unfocus-content': !activeQuestionnaire}">
    <tn-questionnaire-view
      *ngIf="activeQuestionnaire"
      [questionnaire]="activeQuestionnaire"
      (backToList)="resetView()">
    </tn-questionnaire-view>
  </div>
</div>