<div class="t-and-c-container">
  <div class="modal-header">
    <h4 class="text-color-primary">{{'GENERAL.TERMS_TITLE' | translate}}</h4>
  </div>
  <div class="modal-body t-and-c-body">
    <p [innerHTML]="tAndCHtml"></p>
  </div>
  <div class="modal-footer">
    <div *ngIf="isNeedAccept">
      <button type="button" class="btn btn-default" (click)="decline()">
        {{'GENERAL.TERMS_DECLINE' | translate}}
      </button>
      <button type="button" class="btn tn-button-primary" (click)="accept()">
        {{'GENERAL.TERMS_ACCEPT' | translate}}
      </button>
    </div>
    <div *ngIf="!isNeedAccept">
      <button type="button" class="btn btn-default" (click)="back()">
        {{'GENERAL.BACK' | translate}}
      </button>
    </div>
  </div>
</div>