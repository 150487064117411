import { Component, OnInit, Input } from '@angular/core';
import { TeamNoteCorporateMaterialConstant } from '../constants/corporate-material.constant';
import { CorporateMaterialService } from '../corporate-material.service';

import * as _ from 'lodash';

@Component({
  selector: 'tn-corporate-material-item',
  templateUrl: './corporate-material-item.component.html',
  styleUrls: ['./corporate-material-item.component.scss']
})
export class CorporateMaterialItemComponent implements OnInit {

  @Input() material: any;
  @Input() fileAllowTypes: string[] = _.values(TeamNoteCorporateMaterialConstant.TYPE);

  fileType: string;

  isDisplay: boolean = false;

  constructor(
    private _corporateMaterialService: CorporateMaterialService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.fileType = this.material.type;
    if (this.fileType == TeamNoteCorporateMaterialConstant.TYPE.FILE) {
      this.fileType = this._corporateMaterialService.getFileTypeByFileIds(this.material.attachment_id);
    }
    if (!this.fileAllowTypes) {
      this.isDisplay = true;
    } else if (this.fileAllowTypes.indexOf(this.fileType) != -1) {
      this.isDisplay = true;
    }
  }


}
