<div class="page-title-wrapper theme-color-primary">
  <ng-content select=".left-control"></ng-content>
  <div class="page-title">
    <div class="title-main" 
      [ngStyle]="{cursor: allowClick ? 'pointer' : 'default'}"
      (click)="onClick()">
      {{title}}
      <ng-container *ngIf="showUserField && userField">({{userField}})</ng-container>
      <ng-content select=".title-addon"></ng-content>
    </div>
    <ng-content select=".title-desc"></ng-content>
  </div>
  <ng-content select=".right-control"></ng-content>
</div>