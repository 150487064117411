import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomFieldsMapped, CustomFieldMagicStringMapping } from '../../shared/custom-field/models/custom-field';
import { WorkflowTeam, WorkflowType, WorkflowTypeStage } from '../model/workflow';
import { WorkflowService } from '../services/workflow.service';
import { UserContact } from '../../../models/user-contact';

@Component({
  selector: 'tn-form-detail',
  templateUrl: './form-detail.component.html',
  styleUrls: ['./form-detail.component.scss']
})
export class FormDetailComponent implements OnInit {

  @Input() mappedFields: CustomFieldsMapped[] = [];
  @Output() mappedFieldsChange: EventEmitter<CustomFieldsMapped[]> = new EventEmitter<CustomFieldsMapped[]>(null);

  @Input() team: WorkflowTeam = null;
  @Input() type: WorkflowType = null;
  @Input() stage: WorkflowTypeStage = null;
  @Input() pic: UserContact = null;
  @Input() formNo: string = null;
  @Input() isInput: boolean = false;
  @Input() allCustomFields: CustomFieldsMapped[] = [];
  @Input() staticStringMappings: CustomFieldMagicStringMapping = {};

  availableUserIds: string[] = [];

  constructor(
    private _workflowService: WorkflowService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.getAvailableUserIds();
  }

  getAvailableUserIds(): void {
    if (!this.isInput) {
      return;
    }
    this.availableUserIds = this._workflowService.getAllTeamMemberUserIdsByTeamId(this.team.team_id);
  }

  onFieldChange(field: CustomFieldsMapped): void {
    this.mappedFieldsChange.emit(this.mappedFields);
  }
}
