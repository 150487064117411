<div class="job-report-cell clickable" 
  [ngClass]="{'job-report-cell_selected': isSelected}" 
  (click)="onClick()">
  <div class="info-wrapper">
    <ng-container *ngFor="let text of infoListFormatDisplayArr"><ng-container *ngIf="!text.isStr && !text.isTranslateString"><span class="text" [innerHTML]="text.text"></span></ng-container><ng-container *ngIf="text.isTranslateString"><span class="job-translate-text">{{text.text | translate}}</span></ng-container></ng-container>
  </div>
  <div class="status-wrapper" *ngIf="isEnabledJobDispatch">
    <div class="status {{reportStatusStr}}">
      {{'WEBCLIENT.JOB_REPORT.STATUS.' + reportStatusStr | translate}}
    </div>
  </div>
</div>