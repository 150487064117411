import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'tn-advertisement',
  templateUrl: './advertisement.component.html',
  styleUrls: ['./advertisement.component.scss']
})
export class AdvertisementComponent implements OnInit {

  adImageSrc: string = null;
  adOnClickCallback: Function = null;

  @ViewChild("adImage", {static: false}) adImage: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<AdvertisementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.dialogRef.disableClose = true;
    this.dialogRef.updateSize("80vw", "80vh");

    this.adImageSrc = this.data.adImageSrc;
    this.adOnClickCallback = this.data.adOnClickCallback;
  }

  afterImageLoaded() {
    // this.dialogRef.updateSize(
    //   (this.adImage.nativeElement.width + 48) + 'px'
    // );
  }

  onAdClick() {
    if (this.adOnClickCallback) {
      this.adOnClickCallback();
    }
    this.close();
  }

  close() {
    this.dialogRef.close();
  }

}
