import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StoreReportCustomFieldsMapped } from '../../models/job-report';
import { FieldLocationAnswer } from '../field-geolocation-input/field-geolocation-input.component';
import { LocationService } from '../../../../utilities/location/location.service';

@Component({
  selector: 'tn-field-geolocation-detection',
  templateUrl: './field-geolocation-detection.component.html',
  styleUrls: ['./field-geolocation-detection.component.scss']
})
export class FieldGeolocationDetectionComponent implements OnInit {

  @Input() field: StoreReportCustomFieldsMapped;
  @Input() isInput: boolean = false;

  @Output() onFieldUpdate: EventEmitter<StoreReportCustomFieldsMapped> = new EventEmitter<StoreReportCustomFieldsMapped>(null);

  location: FieldLocationAnswer;

  latLng;
  address;

  constructor(
    private _locationService: LocationService
  ) { }

  ngOnInit() {
    this.detectLocation();
  }

  detectLocation() {
    this._locationService.getCurrentLocation(
      (LAT_LNG) => {
        this.latLng = {
          lat: LAT_LNG.LAT,
          lng: LAT_LNG.LNG
        };
        this.getLocationAddress();
      }
    );
  }

  getLocationAddress() {
    this._locationService.getLocationAddressByLatLng(
      this.latLng,
      (address) => {
        this.address = address;
        this.onChange();
      }
    )
  }

  getAnswerValue(): string {
    this.location = {
      address: this.address,
      lat: this.latLng.lat,
      lng: this.latLng.lng
    };
    return JSON.stringify(this.location);
  }

  onChange() {
    if (!this.latLng) {
      this.detectLocation();
      return;
    }
    if (!this.address) {
      this.getLocationAddress();
      return;
    }
    this.field.answer.value = this.getAnswerValue();
    this.onFieldUpdate.emit(this.field);
  }

}
