import { Injectable } from '@angular/core';
import { StoreReportCustomFieldsMapped, StoreReportCustomFieldsAnswer } from './models/job-report';
import { JobReportConstant } from './constants/job-report.constants';
import { FieldAttachmentParsedAnswer } from './custom-field/field-attachment/field-attachment.component';
import { DateService } from '../../utilities/date/date.service';
import { FieldLocationAnswer } from './custom-field/field-geolocation-input/field-geolocation-input.component';
import { FieldAttachmentDocumentParsedAnswer } from './custom-field/field-attachment-document/field-attachment-document.component';

import * as _ from 'lodash';
import { UserContactService } from '../services/data/user-contact/user-contact.service';

@Injectable()
export class JobFilterHelperService {

  constructor(
    private _dateService: DateService,
    private _userContactService: UserContactService
  ) { }

  getSearchKeywordOfReportByMappedField(mappedField: StoreReportCustomFieldsMapped): string {
    let keyword = "";
    let answer = mappedField.answer;
    if (!answer) {
      return keyword;
    }
    switch (mappedField.definition.field_type) {
      case JobReportConstant.CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE:
        return this.getSearchKeywordMultipleChoice(answer);
      case JobReportConstant.CUSTOM_FIELD_TYPE.FREE_TEXT:
        return this.getSearchKeywordFreeText(answer);
      case JobReportConstant.CUSTOM_FIELD_TYPE.CHECKBOX:
        return this.getSearchKeywordCheckbox(answer);
      case JobReportConstant.CUSTOM_FIELD_TYPE.DROPDOWN:
        return this.getSearchKeywordDropdown(answer);
      case JobReportConstant.CUSTOM_FIELD_TYPE.CONTACT:
        return this.getSearchKeywordContact(answer);
      case JobReportConstant.CUSTOM_FIELD_TYPE.ATTACHMENT_PHOTO:
        return this.getSearchKeywordAttachmentPhoto(answer);
      case JobReportConstant.CUSTOM_FIELD_TYPE.DATE:
        return this.getSearchKeywordDate(answer);
      case JobReportConstant.CUSTOM_FIELD_TYPE.TIME:
        return this.getSearchKeywordTime(answer);
      case JobReportConstant.CUSTOM_FIELD_TYPE.GEOLOCATION_USER_INPUT:
      case JobReportConstant.CUSTOM_FIELD_TYPE.GEOLOCATION_DETECTION:
        return this.getSearchKeywordGeolocation(answer);
      case JobReportConstant.CUSTOM_FIELD_TYPE.CODE_SCAN:
      case JobReportConstant.CUSTOM_FIELD_TYPE.TEXT_DISPLAY:
        return "";
      case JobReportConstant.CUSTOM_FIELD_TYPE.ATTACHMENT_DOCUMENT:
        return this.getSearchKeywordAttachmentDocument(answer);
      default:
        break;
    }

    return keyword;
  }

  getSearchKeywordMultipleChoice(answer: StoreReportCustomFieldsAnswer): string {
    return answer.value;
  }

  getSearchKeywordFreeText(answer: StoreReportCustomFieldsAnswer): string {
    return answer.value;
  }

  getSearchKeywordCheckbox(answer: StoreReportCustomFieldsAnswer): string {
    let checkeds = [];
    try {
      checkeds = JSON.parse(answer.value);
    } catch (e) {};
    return checkeds.join(" ");
  }

  getSearchKeywordDropdown(answer: StoreReportCustomFieldsAnswer): string {
    return answer.value;
  }

  getSearchKeywordContact(answer: StoreReportCustomFieldsAnswer): string {
    let contactIds = [];
    try {
      contactIds = JSON.parse(answer.value);
    } catch (e) {};

    let names = [];
    _.each(contactIds, (userId) => {
      let user = this._userContactService.getUserContactByUserId(userId);
      if (user) {
        names.push(user.name);
      }
    });
    return names.join(" ");
  }

  getSearchKeywordAttachmentPhoto(answer: StoreReportCustomFieldsAnswer): string {
    let photos: FieldAttachmentParsedAnswer[] = [];
    try {
      photos = JSON.parse(answer.value);
    } catch (e) {};
    let captions = [];
    _.each(photos, (photo) => {
      if (photo.caption) {
        captions.push(photo.caption);
      }
    });
    return captions.join(" ");
  }

  getSearchKeywordDate(answer: StoreReportCustomFieldsAnswer): string {
    if (answer.value.length != 0) {
      return this._dateService.getDateDisplayWithKey(
        answer.value,
        "JOB_DISPATCH_DETAIL_REPORT_DATE"
      );
    }
    return "";
  }

  getSearchKeywordTime(answer: StoreReportCustomFieldsAnswer): string {
    if (answer.value.length != 0) {
      return this._dateService.getDateDisplayWithKey(
        answer.value,
        "JOB_DISPATCH_DETAIL_REPORT_TIME"
      );
    }
    return "";
  }

  getSearchKeywordGeolocation(answer: StoreReportCustomFieldsAnswer): string {
    let location: FieldLocationAnswer = {address: "", lat: 0, lng: 0};
    try {
      if (answer.value.length != 0) {
        location = JSON.parse(answer.value);
      }
    } catch (e) {};
    if (location.address) {
      return location.address;
    }
    return "";
  }

  getSearchKeywordAttachmentDocument(answer: StoreReportCustomFieldsAnswer): string {
    let docs: FieldAttachmentDocumentParsedAnswer[] = [];
    try {
      docs = JSON.parse(answer.value);
    } catch (e) {};
    let filenames = [];
    _.each(docs, (doc) => {
      if (doc.name) {
        filenames.push(doc.name);
      }
    });
    return filenames.join(" ");
  }

}
