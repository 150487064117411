import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CorporateMaterialFolder, CorporateMaterialItem } from '../../corporate-material/models/corporate-material';
import { CorporateMaterialService } from '../../corporate-material/corporate-material.service';

import * as _ from 'lodash';
import { TeamNoteCorporateMaterialConstant } from '../../corporate-material/constants/corporate-material.constant';
import { FileManagerService } from '../../../utilities/file-manager/file-manager.service';
import { TnNotificationService } from '../../../utilities/tn-notification/tn-notification.service';

@Component({
  selector: 'tn-corporate-material-picker',
  templateUrl: './corporate-material-picker.component.html',
  styleUrls: ['./corporate-material-picker.component.scss']
})
export class CorporateMaterialPickerComponent implements OnInit {

  currentFolder: CorporateMaterialFolder = null;
  isRootFolder: boolean = false;
  rootTranslateKey: string = "";

  folderIdStack: string[] = [];
  ROOT_LEVEL_ID: string = TeamNoteCorporateMaterialConstant.ROOT_LEVEL_ID;

  // config
  header: string = "";
  isFile: boolean = false;
  isUrl: boolean = false;
  isEbook: boolean = false;
  fileAllowTypes: string[] = [];

  validTypeKeys: string[] = [];

  callback: Function = null;

  isPreviewTxt: boolean = false;
  previewingTxtFile: any = null;
  previewingTxtContent: string = null;

  constructor(
    public dialogRef: MatDialogRef<CorporateMaterialPickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _corporateMaterialService: CorporateMaterialService,
    private _fileManagerService: FileManagerService,
    private _tnNotificationService: TnNotificationService
  ) { }

  ngOnInit() {
    this.dialogRef.updateSize("50vw");

    this.header = this.data.header;
    this.isFile = this.data.isFile;
    this.fileAllowTypes = this.data.fileAllowTypes;
    this.isUrl = this.data.isUrl;
    this.isEbook = this.data.isEbook;
    this.callback = this.data.callback;

    this.validTypeKeys = [];
    if (this.isFile) {
      this.validTypeKeys.push(TeamNoteCorporateMaterialConstant.TYPE_KEY.FILES);
    }
    if (this.isUrl) {
      this.validTypeKeys.push(TeamNoteCorporateMaterialConstant.TYPE_KEY.URLS);
    }
    if (this.isEbook) {
      this.validTypeKeys.push(TeamNoteCorporateMaterialConstant.TYPE_KEY.EBOOKS);
    }

    this.getCorporateMaterialList();
  }

  getCorporateMaterialList(): void {
    this.folderIdStack = [];
    this._corporateMaterialService.getCorporateMaterialList(
      (data) => {
        this.handleDefaultFolder();
        this.getCorporateMaterialFolder();
      },
      () => {

      }
    );
  }

  handleDefaultFolder(): void {
    this.rootTranslateKey = 'CORPORATE_MATERIAL';
    this.folderIdStack.push(this.ROOT_LEVEL_ID);
  }

  /**
   * Get sub level corporate material list by folderIdStack
   * now support keyword, try to get existing search keyword from top folder
   * 
   * @memberof CorporateMaterialComponent
   */
  getCorporateMaterialFolder(): void {
    this.isRootFolder = this.folderIdStack.length == 1;
    this.searchFolderByKeyword(this._corporateMaterialService.getSearchKeywordByFolderId(_.last(this.folderIdStack)));
  }

  /**
   * If keyword exists, 
   *    get searched result under top folder,
   * else,
   *    get normal folder content
   *
   * @param {string} keyword - search keyword, or stored keyword
   * @memberof CorporateMaterialComponent
   */
  searchFolderByKeyword(keyword: string): void {
    if (keyword && keyword.length > 0) {
      this.currentFolder = this._corporateMaterialService.getSearchedFolderContentByFolderId(_.last(this.folderIdStack), keyword, this.validTypeKeys);
    } else {
      this.currentFolder = this._corporateMaterialService.getFolderContentByFolderId(_.last(this.folderIdStack), this.validTypeKeys);
    }
  }


  onItemClick(item: any): void {
    this.resetTxt();
    if (item.folder_id) {
      this.onFolderClick(item);
    } else {
      this.onMaterialClick(item);
    }
  }
  
  /**
   * Handle folder click event, add to folderIdStack and get its list
   * 
   * @param {CorporateMaterialFolder} folder - target folder
   * @memberof CorporateMaterialComponent
   */
  onFolderClick(folder: CorporateMaterialFolder): void {
    this.folderIdStack.push(folder.folder_id);
    this.getCorporateMaterialFolder();
  }

  onFolderBack(event: any): void {
    this.resetTxt();
    this.folderIdStack.pop();
    this.getCorporateMaterialFolder();
  }

  /**
   * Handle Material click event
   * 
   * - find target function by type
   * 
   * @param {*} material - target material
   * @memberof CorporateMaterialComponent
   */
  onMaterialClick(material: any): void {
    if (material.attachment_id.indexOf('.txt') != -1) {
      this.onTxtClick(material);
      return;
    }
    this.onMaterialConfirm(material);
  }

  onTxtClick(material: any): void {
    this.isPreviewTxt = true;
    this.previewingTxtFile = material;
    this._fileManagerService.getHTMLContentByAttachmentId(
      material.attachment_id,
      (data) => {
        this.previewingTxtContent = data;
      }
    )
  }
  
  resetTxt(): void {
    this.isPreviewTxt = false;
    this.previewingTxtFile = null;
    this.previewingTxtContent = null;
  }

  onMaterialConfirm(material: any): void {
    let onConfirm = () => {
      this.callback(material);
      this.dialogRef.close();
    }
    if (material.attachment_id.indexOf('.txt') != -1) {
      this._tnNotificationService.showConfirm(
        `Attach ${material.name}?`,
        resp => {
          onConfirm()
        },
      );
    } else {
      onConfirm()
    }

  }

}
