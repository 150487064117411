<div class="attachment-location">
  <div class="map-container">
    <!-- <ngui-map 
      [zoom]="zoom" 
      [center]="lat +',' + lng" 
      (mapClick)="onMapClick($event)">
      <marker [position]="[lat, lng]"></marker>
    </ngui-map> -->

    <google-map
      height="100%"
      width="100%"
      [center]="{ lat, lng }" 
      [zoom]="zoom"
      [options]="mapOptions"
      (mapClick)="onMapClick($event)"
    >
      <map-marker [position]="{ lat, lng }" [options]="markerOptions"></map-marker>
    </google-map>
  </div>
  <div class="map-address-bar">
    {{address}}
  </div>
</div>