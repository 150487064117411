import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageComponent } from './language.component';
import { UtilitiesModule } from '../../utilities/utilities.module';

@NgModule({
    imports: [
        CommonModule,
        UtilitiesModule
    ],
    declarations: [LanguageComponent]
})
export class LanguageModule { }
