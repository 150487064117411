import { Injectable } from '@angular/core';
import { CustomFieldsMapped, CustomFieldsDefinition, CustomFieldsAnswer, CustomFieldMagicStringMapping } from '../models/custom-field';
import { CustomFieldConstant } from '../constants/custom-field.constants';

import * as _ from 'lodash';
import { FieldAttachmentDocumentParsedAnswer } from '../field-attachment-document/field-attachment-document.component';
import { FieldLocationAnswer } from '../field-geolocation-input/field-geolocation-input.component';
import { FieldAttachmentPhotoParsedAnswer } from '../field-attachment-photo/field-attachment-photo.component';
import { FieldContactDetail } from '../field-contact/field-contact.component';
import { DateService } from '../../../../utilities/date/date.service';
import { AccountService } from '../../../../account/account.service';
import { UserContactService } from '../../../services/data/user-contact/user-contact.service';
import { TextMessagePart } from '../../../../utilities/linkify/linkify.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FieldAttachmentParsedAnswer } from '../field-attachment/field-attachment.component';
import { FieldSignatureAnswer } from '../field-signature/field-signature.component';

@Injectable()
export class FieldHelperService {

  fieldIdRegEx = /%%fieldId\?=(.*)%%/;
  myNameNotifyFormat = "<span style=\"color: #ff0000\">{{myName}}</span>";

  constructor(
    private _dateService: DateService,
    private _accountService: AccountService,
    private _userContactService: UserContactService,
    private _domSanitizer: DomSanitizer
  ) { }

  prepareMappedField(definitions: CustomFieldsDefinition[], answers: CustomFieldsAnswer[]): CustomFieldsMapped[] {
    let mappedFields = _.map(definitions, (def) => {
      let mapped = new CustomFieldsMapped;
      mapped.definition = def;
      mapped.answer = _.find(answers, (a: CustomFieldsAnswer) => {
        return a.field_id == def.field_id;
      });
      return mapped;
    });
    return mappedFields;
  }

  handleListFormatOfCustomField(
    listFormat: string, 
    customFieldDefinitions: CustomFieldsDefinition[], 
    customFieldAnswers: CustomFieldsAnswer[], 
    isTextDisplay: boolean,
    staticStringMappings: CustomFieldMagicStringMapping
  ): string {
    _.each(staticStringMappings, (value, magicString) => {
      listFormat = listFormat.replace(magicString, value);
    });

    do {
      let fieldIdMatch = this.fieldIdRegEx.exec(listFormat);
      if (fieldIdMatch) {
        let found = fieldIdMatch[1];
        let breakIndex = found.indexOf("%%");
        let fieldId = breakIndex != -1 ? found.substr(0, breakIndex) : found;
        let fieldContent = this.getDisplayFormatOfFieldByFieldId(fieldId, customFieldDefinitions, customFieldAnswers, isTextDisplay);
        let toBeReplacedMagicString = CustomFieldConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.FIELD_ID.replace("{{fieldId}}", fieldId);
        listFormat = listFormat.replace(toBeReplacedMagicString, fieldContent);
      } else {
        break;
      }
    } while (true);

    return listFormat;
  }

  getDisplayFormatOfFieldByFieldId(fieldId: string, definitions: CustomFieldsDefinition[], answers: CustomFieldsAnswer[], isTextDisplay: boolean): string {
    let fieldDefinition = _.find(definitions, {field_id: fieldId});
    if (!fieldDefinition) {
      return "";
    }
    let fieldAnswer = _.find(answers, {field_id: fieldId});
    return this.getFormattedDisplayFieldAnswer(fieldDefinition, fieldAnswer, isTextDisplay);
  }

  getFormattedDisplayFieldAnswer(definition: CustomFieldsDefinition, answer: CustomFieldsAnswer, isTextDisplay: boolean): string {
    switch (definition.field_type) {
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE:
        return this.getFormattedMutipleChoiceAnswer(answer);
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.FREE_TEXT:
        return this.getFormattedFreeTextAnswer(answer);
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.CHECKBOX:
        return this.getFormattedCheckboxAnswer(answer);
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.DROPDOWN:
        return this.getFormattedDropdownAnswer(answer);
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.CONTACT:
        return this.getFormattedContactAnswer(answer, definition, isTextDisplay);
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.ATTACHMENT_PHOTO:
        return this.getFormattedAttachmentPhotoAnswer(answer);
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.DATE:
        return this.getFormattedDateAnswer(answer);
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.TIME:
        return this.getFormattedTimeAnswer(answer);
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.GEOLOCATION_USER_INPUT:
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.GEOLOCATION_DETECTION:
        return this.getFormattedGeolocationAnswer(answer);
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.CODE_SCAN:
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.TEXT_DISPLAY:
        return "";
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.ATTACHMENT_DOCUMENT:
        return this.getFormattedAttachmentDocumentAnswer(answer);
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.ATTACHMENT:
        return this.getFormattedAttachmentAnswer(answer);
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.SIGNATURE:
        return this.getFormattedSignatureAnswer(answer);
      default:
        break;
    }
    return "";
  }


  getFormattedMutipleChoiceAnswer(answer: CustomFieldsAnswer): string {
    return answer ? answer.value : "";
  }

  getFormattedFreeTextAnswer(answer: CustomFieldsAnswer): string {
    return answer ? answer.value : "";
  }

  getFormattedCheckboxAnswer(answer: CustomFieldsAnswer): string {
    let checkeds = [];
    try {
      checkeds = JSON.parse(answer.value);
    } catch (e) {};
    return checkeds.join(", ");
  }

  getFormattedDropdownAnswer(answer: CustomFieldsAnswer): string {
    return answer ? answer.value : "";
  }

  getFormattedContactAnswer(answer: CustomFieldsAnswer, definition: CustomFieldsDefinition, isTextDisplay: boolean): string {
    let contactIds = [];
    try {
      contactIds = JSON.parse(answer.value);
    } catch (e) {};

    let display = "";

    let myName = null;

    let selfContactIndex = contactIds.indexOf(this._accountService.userId);
    if (selfContactIndex != -1) {
      // It has "ME"
      let detail: FieldContactDetail = JSON.parse(definition.detail);
      // let c = this._userContactService.getUserContactByUserId(this._accountService.userId);
      // if (c) {
        myName = this._accountService.name;
        if (detail.is_notify && !isTextDisplay) {
          myName = this.myNameNotifyFormat.replace("{{myName}}", myName);
        }
      // }
    }

    if (contactIds.length == 1) {
      if (myName) {
        display = myName;
      } else {
        let c = this._userContactService.getUserContactByUserId(contactIds[0]);
        if (c) {
          display = c.name;
        }
      }
    } else if (contactIds.length > 1) {
      if (myName) {
        display = myName + " & " + (contactIds.length - 1);
        if (contactIds.length == 2) {
          display += CustomFieldConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.LOCAL_TRANSLATE["%%userOne%%"].key;
        } else {
          display += CustomFieldConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.LOCAL_TRANSLATE["%%userOther%%"].key
        }
      } else {
        display = contactIds.length + CustomFieldConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.LOCAL_TRANSLATE["%%userOther%%"].key
      }
    }

    return display;
  }

  getFormattedAttachmentPhotoAnswer(answer: CustomFieldsAnswer): string {
    let photos: FieldAttachmentPhotoParsedAnswer[] = [];
    try {
      photos = JSON.parse(answer.value);
    } catch (e) {};
    if (photos.length == 0) {
      return "";
    }
    if (photos.length == 1) {
      if (typeof photos[0] != "string") {
        if (photos[0].caption) {
          return photos[0].caption;
        }
      }
      return CustomFieldConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.LOCAL_TRANSLATE["%%imageOne%%"].key;
    }
    return photos.length + CustomFieldConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.LOCAL_TRANSLATE["%%imageOther%%"].key;
  }

  getFormattedDateAnswer(answer: CustomFieldsAnswer): string {
    if (answer && answer.value.length != 0) {
      return this._dateService.getDateDisplayWithKey(
        answer.value,
        "JOB_DISPATCH_DETAIL_REPORT_DATE"
      );
    }
    return "";
  }

  getFormattedTimeAnswer(answer: CustomFieldsAnswer): string {
    if (answer && answer.value.length != 0) {
      return this._dateService.getDateDisplayWithKey(
        answer.value,
        "JOB_DISPATCH_DETAIL_REPORT_TIME"
      );
    }
    return "";
  }

  getFormattedGeolocationAnswer(answer: CustomFieldsAnswer): string {
    let location: FieldLocationAnswer = {address: "--", lat: 0, lng: 0};
    try {
      if (answer.value.length != 0) {
        location = JSON.parse(answer.value);
      }
    } catch (e) {};
    if (location.address) {
      return location.address;
    }
    return "";
  }

  getFormattedAttachmentDocumentAnswer(answer: CustomFieldsAnswer): string {
    let docs: FieldAttachmentDocumentParsedAnswer[] = [];
    try {
      docs = JSON.parse(answer.value);
    } catch (e) {};
    if (docs.length == 0) {
      return "";
    }
    if (docs.length == 1) {
      return docs[0].name;
    } 
    return docs.length + CustomFieldConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.LOCAL_TRANSLATE["%%fileOther%%"].key;
  }

  getFormattedAttachmentAnswer(answer: CustomFieldsAnswer): string {
    let attachments: FieldAttachmentParsedAnswer[] = [];
    try {
      attachments = JSON.parse(answer.value);
    } catch (e) {};
    if (attachments.length == 0) {
      return "";
    }
    let targetTranlsate = CustomFieldConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.LOCAL_TRANSLATE["%%attachmentOther%%"];
    if (attachments.length == 1) {
      targetTranlsate = CustomFieldConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.LOCAL_TRANSLATE["%%attachmentOne%%"];
    } 
    return attachments.length + targetTranlsate.key;
  }

  getFormattedSignatureAnswer(answer: CustomFieldsAnswer): string {
    let signature = new FieldSignatureAnswer();
    try {
      signature = JSON.parse(answer.value);
    } catch (e) {};

    if (!signature || !signature.attachment_id) {
      return CustomFieldConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.LOCAL_TRANSLATE["%%notSigned%%"].key;
    }
    return CustomFieldConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.LOCAL_TRANSLATE["%%signed%%"].key;
  }


  handleSpcialLocalTranslateStrings(listFormat: string): TextMessagePart[] {
    let localTranslateKeys = _.keys(CustomFieldConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.LOCAL_TRANSLATE);

    let tempFullStr = listFormat;
    let arr: TextMessagePart[] = [];

    let localTranslateRegEx = new RegExp("(" + localTranslateKeys.join("|") + ")", "g");
    let localTranslateMatch = listFormat.match(localTranslateRegEx);
    if (localTranslateMatch) {
      let keyIndex = 0;
      while (keyIndex < localTranslateMatch.length) {
        let key = localTranslateMatch[keyIndex];
        let index = tempFullStr.indexOf(localTranslateMatch[keyIndex]);
        if (index == -1) {
          // doesn't exist this key, all str is html
          arr.push(new TextMessagePart(false, this._domSanitizer.bypassSecurityTrustHtml(tempFullStr)));
        } else {
          // str before key is html
          arr.push(new TextMessagePart(false, this._domSanitizer.bypassSecurityTrustHtml(tempFullStr.substr(0, index))));
          // key = translate key
          arr.push(new TextMessagePart(false, CustomFieldConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.LOCAL_TRANSLATE[key].translate, true));
          // str after key will be for next iteration
          tempFullStr = tempFullStr.substr(index + key.length);
        }
        keyIndex++;
      }
      // add remaining str as html
      if (tempFullStr) {
        arr.push(new TextMessagePart(false, this._domSanitizer.bypassSecurityTrustHtml(tempFullStr)));
      }
    } else {
      arr.push(new TextMessagePart(false, this._domSanitizer.bypassSecurityTrustHtml(tempFullStr)));
    }
    return arr;
  }
}
