import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Message } from '../../../models/message';

import { UserContactService } from '../../services/data/user-contact/user-contact.service';

import { Attachment } from '../../../models/attachment';
import { AttachmentService } from '../../../utilities/attachment/attachment.service';
import { NewsService } from '../news.service';
import { EmojiService } from '../../../utilities/emoji/emoji.service';
import { LoggerService } from '../../../utilities/logger/logger.service';
import { AccountManagerService } from '../../services/account/account-manager.service';
import { MessageTypeConstant } from '../../../constants/message-type.constant';
import { EmojiConstant } from '../../../constants/emoji.constant';
import { FileFactoryService } from '../../../utilities/file-factory/file-factory.service';

@Component({
  selector: 'tn-news-comment',
  templateUrl: './news-comment.component.html',
  styleUrls: ['./news-comment.component.scss']
})
export class NewsCommentComponent implements OnInit {
  @Input() comment: Message;
  @Input() isFirstLevel: boolean;
  @Input() isThreadOpen: boolean;
  @Input() isAdmin: boolean;

  @Output() onReplyToggle: EventEmitter<Message> = new EventEmitter<Message>(null);
  @Output() onRemoveComment: EventEmitter<Message> = new EventEmitter<Message>(null);

  iconImgSrc: string;

  MSG_TYPE = MessageTypeConstant

  LIKE_EMOJI_PATH: string;

  isReplying: boolean = false;
  isAllowDelete: boolean = false;

  constructor(
    private _userContactService: UserContactService,
    private _attachmentService: AttachmentService,
    private _newsService: NewsService,
    private _emojiService: EmojiService,
    private _loggerService: LoggerService,
    private _accountManagerService: AccountManagerService,
    private _fileFactoryService: FileFactoryService
  ) { }

  ngOnInit() {
    this.LIKE_EMOJI_PATH = 'assets/images/emoji/' + this._emojiService.getFileName(EmojiConstant.LIKE_EMOJI_STR) + '.png';
  }

  ngOnChanges() {
    // console.log(this.comment);
    if (!this.comment) {
      return;
    }
    if (this.comment.sender.pic && this.comment.sender.pic != 'None') {
      this._fileFactoryService.getFileByAttachmentId(
        this.comment.sender.pic,
        (imageUrl) => {
          this.iconImgSrc = imageUrl;
        },
        (err) => {},
        false,
        false,
        true
      );
    }
    this.isAllowDelete = this.isAdmin || this.comment.sender.user_id == this._accountManagerService.userId;
  }

  onAttachmentClick(attachment: Attachment) {
    this._loggerService.log("Clicked news' comment's attachment: " + attachment.attachment_id);
    this._attachmentService.openAttachmentModal(true, [attachment]);
  }

  likeComment() {
    if (!this.isThreadOpen) {
      return;
    }
    this._loggerService.log("Clicked news' comment's like: " + this.comment.message_id);
    this._newsService.onLikeClick(this.comment, this.comment.isLiked);
  }

  toggleReplyComment() {
    if (!this.isThreadOpen) {
      return;
    }
    this._loggerService.log("Clicked news' comment's reply: " + this.comment.message_id);
    this.onReplyToggle.emit(this.comment);
  }

  removeComment() {
    this.onRemoveComment.emit(this.comment);
  }
}
