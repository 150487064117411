/**
 * Full Text Search (Contact) Service
 * 
 * Created by Shan - 2018-04-26
 */
import { Injectable } from '@angular/core';
import { UserContact } from '../../../models/user-contact';

import * as lunr from 'lunr';

import * as _ from 'lodash';

import { UserContactService } from '../../../webclient/services/data/user-contact/user-contact.service';
import { DataManagerService } from '../../../webclient/services/data/data-manager.service';
import { TeamNoteGeneralConstant } from '../../../constants/general.constant';
import { UserGroupService } from '../../../webclient/services/data/user-group/user-group.service';
import { SearchFilterService } from '../../search-filter/search-filter.service';
import { FtsService } from '../fts.service';

@Injectable()
export class FtsContactService {

  contactUsers: UserContact[];
  contactFtsIndex: any;

  contactSearchFields: string[] = [
    "name",
    "title",
    "department",
    "public_message"
  ];

  /**
   * Creates an instance of FtsContactService.
   * 
   * Subscribe to userContact$ and userGroup$ in order to update contact indexes (deprecated)
   * 
   * @param {UserContactService} _userContactService 
   * @param {DataManagerService} _dataManagerService 
   * @param {UserGroupService} _userGroupService 
   * @param {SearchFilterService} _searchFilterService 
   * @memberof FtsContactService
   */
  constructor(
    private _userContactService: UserContactService,
    private _dataManagerService: DataManagerService,
    private _userGroupService: UserGroupService,
    private _searchFilterService: SearchFilterService,
    private _ftsService: FtsService
  ) { 
    this._userContactService.userContacts$.subscribe(contacts => {
      this.updateContactUsers();
      // this.initContactFtsIndex();
    });
    this._userGroupService.userGroups$.subscribe(userGroups => {
      this.updateContactUsers();
      // this.initContactFtsIndex();
    })
  }

  /**
   * Update contact users, get under root group
   * 
   * @memberof FtsContactService
   */
  updateContactUsers(): void {
    this.contactUsers = this._dataManagerService.getAllContactUsersUnderUserGroup(TeamNoteGeneralConstant.ROOT_USER_GROUP_ID);
  }

  /**
   * Create contactFtsIndex by lunrjs
   * Get all contact user under root group
   * 
   * @memberof FtsContactService
   */
  initContactFtsIndex(): void {
    let contacts = this._dataManagerService.getAllContactUsersUnderUserGroup(TeamNoteGeneralConstant.ROOT_USER_GROUP_ID);
    this.contactUsers = contacts;

    let searchFields = this.contactSearchFields;

    this.contactFtsIndex = lunr(function () {
      this.ref('user_id');
      
      _.each(searchFields, (f) => {
        this.field(f);
      });

      _.each(contacts, (c) => {
        this.add(c);
      });
    });
  }

  /**
   * Search contact user by keyword
   * 
   * Apply custom contact filtering on contact users
   * If no contacts are provided, use the full contact user list
   * 
   * ----- (Deprecated) -----
   * 1. Apply FTS
   * 2. Customize FTS-searched array
   * (FTS will return data with AT LEAST one of the keywords)
   * (we need to have all keywords within the user's detail)
   * 3. Apply Customize filtering on all users (FTS library doesn't handle Chinese Char)
   * 
   * If keyword = "lam", we use "lam*".
   * If keyword = "lam wi", we use "lam wi*".
   * Only last keyword allow wildcard, others require exact match
   * 
   * @param {string} keyword - Target search keyword
   * @param {UserContact[]} contacts - Original contacts
   * @returns {UserContact[]} - Sorted filtered user contacts
   * @memberof FtsContactService
   */
  searchContact(keyword: string, contacts?: UserContact[]): UserContact[] {
    if (!contacts) {
      contacts = this.contactUsers;
    }
    // let start = _.now();
    let filteredUsers = [];

    // FTS (Deprecated)
    let ftsSearchUsers = [];
    // let parsedSearchKeyword = keyword.trim()+ "*";
    // let ftsSearchUsers = this.searchContactFts(parsedSearchKeyword);
    // ftsSearchUsers = this.customContactFiltering(ftsSearchUsers, keyword);

    // Bsic
    let basicSearchedUsers = this.customContactFiltering(contacts, keyword);

    // Merge FTS and Basic
    filteredUsers = _.union(ftsSearchUsers, basicSearchedUsers);

    // Sort Contact before returning
    let sorted = this.sortContacts(filteredUsers, keyword);
    // let end = _.now();
    // console.error("time used: " + (end - start));
    return sorted;
  }

  /**
   * Apply FTS on contact users
   * 
   * @param {string} keyword - Target search keyword
   * @returns {UserContact[]} - Filtered user contacts by FTS
   * @memberof FtsContactService
   */
  searchContactFts(keyword: string): UserContact[] {
    let result = this.contactFtsIndex.search(keyword);
    return _.map(result, (r: any) => {
      return this._userContactService.getUserContactByUserId(r.ref);
    });
  }

  /**
   * Basic contact search - exact match
   * 
   * At this stage, allow user to only have one of the keywords, as we will perform custom filtering later.
   * 
   * @param {string} originalKeyword - Original keyword, without parsing for FTS
   * @returns {UserContact[]} - Filtered user contacts by exact match
   * @memberof FtsContactService
   */
  searchContactBasic(originalKeyword: string): UserContact[] {
    let filtered = [];
    let splitedKeywords = originalKeyword.trim().split(" ");

    // Iterate all contact users
    for (let contactIndex in this.contactUsers) {
      let u = this.contactUsers[contactIndex];
      let isMatch = false;

      // Iterate all search fileds
      for (let fieldIndex in this.contactSearchFields) {
        let f = this.contactSearchFields[fieldIndex];
        if (!u[f]) {
          continue;
        }

        // Iterate all keywords
        for (let keywordIndex in splitedKeywords) {
          let keyword = splitedKeywords[keywordIndex];
          let index = u[f].toLowerCase().indexOf(keyword);
          if (index == -1) {
            continue;
          } else {
            // Keyword is found in field, stop looping through keywords, break!
            isMatch = true;
            break;
          }
        }
        // Keyword is found in user, stop looping through search fields, break! go to next user
        if (isMatch) {
          filtered.push(u);
          break;
        }
      }
    }
    return filtered;
  }

  /**
   * Customize filtering on contact
   * 
   * We need user to have all keywords in order to be a valid search result.
   * 
   * @param {UserContact[]} filtered - data array
   * @param {string} originalKeyword - Original keyword, without parsing for FTS
   * @returns {UserContact[]} - Custom filtered user contact array
   * @memberof FtsContactService
   */
  customContactFiltering(filtered: UserContact[], originalKeyword: string): UserContact[] {
    return this._ftsService.tnFtsFiltering(filtered, originalKeyword, this.contactSearchFields);
  }

  /**
   * Sort contacts
   * 
   * @param {UserContact[]} contacts 
   * @param {string} keyword - Target search keyword
   * @returns {UserContact[]} - Sorted user contacts by relevance of "name"
   * @memberof FtsContactService
   */
  sortContacts(contacts: UserContact[], keyword: string): UserContact[] {
    return this._searchFilterService.sortDataByRelevanceByName(contacts, keyword);
  }

}
