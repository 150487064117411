export const EnQuestionnaireStrings = {
  ALREADY_COMPLETED: "You have already completed this questionnaire. This questionnaire can only be submitted once.",
  EXPIRED: "This questionnaire has expired",

  SUBMITTED_MSG: "Thank you for your feedback",
  END: "-- End --",
  SUBMIT: "Submit",
  ITEM_OTHER: "Other",
  INPUT_ALL_FIELDS: "Please answer all questions",
  ITEM_RAITNG_CLICK: "Click a Star to Rate",
  ITEM_RATING_COMMENT: "Other comment (Optional):",
  ITEM_UNFINISHED_INDICATOR: "***"
}
