import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomFieldsMapped } from '../models/custom-field';
import { ParsedFile } from '../../../../utilities/file-uploader/file-uploader.component';

import * as _ from 'lodash';
import { FileFactoryService } from '../../../../utilities/file-factory/file-factory.service';
import { SignatureService } from '../../../../utilities/signature/signature.service';
import { FileManagerService } from '../../../../utilities/file-manager/file-manager.service';
import { AttachmentTypeConstant } from '../../../../constants/attachment-type.constant';
import { ImageHelperService } from '../../../../utilities/image-helper/image-helper.service';
import { SignatureConstants } from '../../../../utilities/signature/signature.component';

export class FieldSignatureAnswer {
  attachment_id: string;
  file?: ParsedFile;
  src?: string;
  bgSrc?: string;

  constructor(file?: ParsedFile, attachmentId?: string) {
    this.file = file;
    this.attachment_id = attachmentId;
  }
}

@Component({
  selector: 'tn-field-signature',
  templateUrl: './field-signature.component.html',
  styleUrls: ['./field-signature.component.scss']
})
export class FieldSignatureComponent implements OnInit {

  @Input() field: CustomFieldsMapped;
  @Input() isInput: boolean = false;

  @Output() onFieldUpdate: EventEmitter<CustomFieldsMapped> = new EventEmitter<CustomFieldsMapped>(null);

  fileInputs: ParsedFile[];
  localAnsObj: FieldSignatureAnswer[] = [];

  isSigned: boolean = false;


  constructor(
    private _fileFactoryService: FileFactoryService,
    private _signatureService: SignatureService,
    private _fileManagerService: FileManagerService,
    private _imageHelperService: ImageHelperService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.localAnsObj = [];
    if (this.field.answer.value) {
      try {
        let signature: FieldSignatureAnswer = JSON.parse(this.field.answer.value);
        if (signature) {
          this.addToLocalAns(null, signature.attachment_id);

          this.isSigned = true;

          this._fileFactoryService.getFileByAttachmentId(
            signature.attachment_id,
            (imageSrc) => {
              this.updateLocalAnsSrcByAttachmentId(signature.attachment_id, imageSrc);
            },
            (err) => {},
            false,
            false,
            false
          );
        }
      } catch (e) {

      }
      this.onChange();
    }
    if (this.localAnsObj.length == 0) {
      this.prepareDefaultSignatureImage();
    }
  }

  prepareDefaultSignatureImage(): void {
    this.addToLocalAns(this._signatureService.getDefaultSignatureFile(), "");
    // this.addToLocalAns(null, "");
  }

  addToLocalAns(file: ParsedFile, attachmentId: string): void {
    this.localAnsObj.push(
      new FieldSignatureAnswer(
        file,
        attachmentId
      )
    );
  }
  
  updateLocalAnsSrcByAttachmentId(attachmentId: string, src: string): void {
    let target: FieldSignatureAnswer = _.find(this.localAnsObj, (ansObj) => {
      return attachmentId == ansObj.attachment_id;
    });
    target.src = src;
    let bgSrc = this._fileManagerService.getBackgroundImgSrcBySrc(src);
    target.bgSrc = bgSrc;
  }

  removeAttachment(ansObj) {
    this.localAnsObj = _.without(this.localAnsObj, ansObj);
    this.onChange();
  }

  onSignatureClick(ansObj: FieldSignatureAnswer): void {
    if (!this.isInput) {
      return;
    }
    if (!ansObj.file) {
      this._imageHelperService.getImageDataUrlBySrc(
        ansObj.src,
        (dataUrl) => {
          // prepare file for editing   
          let realFile = this._fileManagerService.dataUrlToFile(
            dataUrl,
            SignatureConstants.default.name
          );
          ansObj.file = {
            file: realFile,
            imagePreview: dataUrl,
            name: SignatureConstants.default.name,
            isValid: true,
            type: AttachmentTypeConstant.IMAGE
          };
          this.openSignatureDialogOfAns(ansObj);
        }
      );
    } else {
      this.openSignatureDialogOfAns(ansObj);
    }
  }

  openSignatureDialogOfAns(ansObj: FieldSignatureAnswer): void {
    this._signatureService.openSignatureDialog(
      ansObj.file,
      (dataUrl, isSigned) => {
        ansObj.file.imagePreview = dataUrl;
        let newFile = this._fileManagerService.dataUrlToFile(dataUrl, ansObj.file.name);
        ansObj.file.file = newFile;

        // clear original answer value
        ansObj.attachment_id = null;
        ansObj.src = null;
        ansObj.bgSrc = null;

        this.isSigned = isSigned;
        this.onChange();
      },
      this.isSigned
    );
  }

  getAnswerValue(): any {
    if (this.isSigned) {
      return this.localAnsObj;
    }
    return [];
  }

  onChange() {
    this.field.answer.value = this.getAnswerValue();
    this.onFieldUpdate.emit(this.field);
  }

}
