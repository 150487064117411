import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import * as FileSaver from 'file-saver';
import * as _ from 'lodash';
import { TeamnoteConfigService } from '../configs/teamnote-config.service';
import { ModuleKeyDefinition } from '../constants/module.constant';
import { UserContact } from '../models/user-contact';
import { LocalStorageManagerService } from '../utilities/local-storage/local-storage-manager.service';
import { LoggerService } from '../utilities/logger/logger.service';
import { TnDialogService } from '../utilities/tn-dialog/tn-dialog.service';
import { LanguageComponent } from './language/language.component';
import { OutOfOfficeComponent } from './out-of-office/out-of-office.component';
import { ProfileComponent } from './profile/profile.component';
import { AccountManagerService } from './services/account/account-manager.service';
import { ModuleManagerService } from './services/module/module-manager.service';
import { WebclientService } from './webclient.service';
import { DataManagerService } from './services/data/data-manager.service';
import { WebsiteTitleService } from '../utilities/website-title/website-title.service';
import { TeamNoteLocalStorageKeyConstants } from '../constants/local-storage-key.constant';
import { SocketService } from './services/socket/socket.service';
import { RouteParamService } from '../utilities/route-param/route-param.service';
import { PageUrlConstant } from '../constants/page-url.constant';
import { TnLoaderService } from '../utilities/tn-loader/tn-loader.service';
import { FileFactoryService } from '../utilities/file-factory/file-factory.service';
import { SideNavService } from '../utilities/tn-side-nav/side-nav.service';
import { TnNotificationService } from '../utilities/tn-notification/tn-notification.service';
import { TAndCService } from '../utilities/t-and-c/t-and-c.service';


export class SettingMenu {
  labelKey: string;
  action: Function;

  constructor(labelKey: string, action: Function) {
    this.labelKey = labelKey;
    this.action = action;
  }
}

@Component({
  selector: 'tn-webclient',
  templateUrl: './webclient.component.html',
  styleUrls: ['./webclient.component.scss']
})
export class WebclientComponent implements OnInit {
  loggedInUser: UserContact = null;
  avatarImageSrc: string = null;

  VERSION_NO: string;
  IS_ENABLE_DEBUG = false;
  IS_ENABLE_TNC = false;
  ENABLE_NOTIFICATION_CENTER = false;
  IS_UAT = false;
  BUILD_LABEL: string;
  NAV_LABEL: string;
  NAV_UAT_LABEL: string;
  ELECTRON_HIDE_LOGOUT_BTN = false;
  IS_ELECTRON = false;

  // Settings
  availableSettings = {
    outOfOffice: ModuleKeyDefinition.OUT_OF_OFFICE
  };
  settingMenus: SettingMenu[] = [];

  debugClickCount = 0;

  // dev mode
  devClickCount = 0;
  isDevMode = false;
  
  //checkCurrentLocation
  isWebapp: boolean = false;


  constructor(
    private _webclientService: WebclientService,
    private _accountManagerService: AccountManagerService,
    private _tnDialogService: TnDialogService,
    private _router: Router,
    private _loggerService: LoggerService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _moduleManagerService: ModuleManagerService,
    private _dataManagerService: DataManagerService,
    private _websiteTitleService: WebsiteTitleService,
    private _socketService: SocketService,
    private _routeParamService: RouteParamService,
    private _tnLoaderService: TnLoaderService,
    private _fileFactoryService: FileFactoryService,
    private _sideNavService: SideNavService,
    private _tnNotificationService: TnNotificationService,
    private _tAndCService: TAndCService
  ) {
    this._teamnoteConfigService.config$.subscribe((config) => {
      this.VERSION_NO = config.VERSION.VERSION;
      this.IS_ENABLE_DEBUG = config.GENERAL.IS_ENABLE_DEBUG;
      this.ENABLE_NOTIFICATION_CENTER = config.NOTIFICATION.ENABLE_NOTIFICATION_CENTER;
      this.IS_ENABLE_TNC = config.GENERAL.IS_ENABLE_TNC;
      this.IS_UAT = config.GENERAL.IS_UAT;
      this.BUILD_LABEL = config.GENERAL.BUILD_LABEL;
      this.NAV_LABEL = config.GENERAL.NAV_LABEL;
      this.NAV_UAT_LABEL = config.GENERAL.NAV_UAT_LABEL;
      this.ELECTRON_HIDE_LOGOUT_BTN = config.GENERAL.ELECTRON_HIDE_LOGOUT_BTN;
    });

    this._loggerService.debug('Subscribing to unread count update: to update unread count & website title');
    this._sideNavService.unreadCount$.subscribe(count => {
      this._websiteTitleService.setWebsiteTitle(count);
    });

    this._router.events.subscribe((e) => {
      // console.warn(e);
      if (e instanceof NavigationEnd) {
        if (e.urlAfterRedirects.lastIndexOf('/') !== 0) {
          // trip query params
          const routeParts = e.urlAfterRedirects.split('?');
          this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE, routeParts[0]);
        }
      }
    });
  }

  ngOnInit() {
    this._tnLoaderService.hideSpinner();
    this._loggerService.debug('Webclient ngOnInit - Calling function: webclientService.onLoginSuccess...');
    this._webclientService.onLoginSuccess();

    this._loggerService.debug('Subscribing to selfContactPresence: to update current logged in user\'s info');
    this._accountManagerService.selfContactPresence$.subscribe(user => {
      this._loggerService.debug('Updating loggedInUser');
      this.loggedInUser = user;
      this.parseUser();
    });
    this.setUpSettingMenus();
    this.restorePreviousSession();

    this._loggerService.debug('Setting login time');
    this._localStorageManagerService.setSessionStorageByKey(TeamNoteLocalStorageKeyConstants.SESSION.LOGIN_TIME, new Date().getTime());

    // @ts-ignore
    this.IS_ELECTRON = !!window.tnElectronApi;
    this.checkCurrentPort();
  }

  parseUser(): void {
    if (!this.loggedInUser) {
      return;
    }
    if (this.loggedInUser.pic && this.loggedInUser.pic !== 'None') {
      this._loggerService.debug('Setting logged in user\'s avatar iamge...');
      this._fileFactoryService.getFileByAttachmentId(
        this.loggedInUser.pic,
        (imageUrl) => {
          this.avatarImageSrc = imageUrl;
        },
        (err) => {},
        false,
        false,
        true
      );
    }
  }

  setUpSettingMenus(): void {
    _.each(this.availableSettings, (s) => {
      if (this._moduleManagerService.checkIfModuleExists(s)) {
        switch (s) {
          case ModuleKeyDefinition.OUT_OF_OFFICE:
            this.settingMenus.push(new SettingMenu('WEBCLIENT.MENU.SETTINGS.OUT_OF_OFFICE.TITLE', () => {this.openOutOfOfficeModal(); }));
            break;
        }
      }
    });
  }

  restorePreviousSession(): void {
    const previousRoute = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE);
    this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE);

    if (!previousRoute || previousRoute === '/' || previousRoute.indexOf('login') !== -1) {
      this.goToWebclientDefaultPage();
      return;
    }

    this._dataManagerService.prevSessionRoute = previousRoute;

    const metaData = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE_META_DATA);
    let prevQueryString = metaData;
    try {
      prevQueryString = JSON.parse(metaData);
      this._routeParamService.setRouteParam(prevQueryString);
    } catch (e) {}

    this._loggerService.log('Restore previous session: ' + previousRoute);
    this._router.navigateByUrl(previousRoute);
  }

  goToWebclientDefaultPage(): void {
    const defaultPage = this._teamnoteConfigService.config.WEBCLIENT.GENERAL.WEBCLIENT_DEFAULT_PAGE;
    this._router.navigateByUrl('/' + [PageUrlConstant.WEBCLIENT.BASE, defaultPage].join('/'));
  }

  // Profile Modal
  openMyProfileModal(): void {
    this._loggerService.log('Clicked \'Profile\', opening profile dialog...');
    const dialogRef = this._tnDialogService.openTnDialog(ProfileComponent);
  }

  // Language Modal
  openLanguageSelectModal(): void {
    this._loggerService.log('Clicked \'Language\', opening language selection dialog...');
    const languageDialogRef = this._tnDialogService.openTnDialog(LanguageComponent);
  }

  // T&C
  openTAndCModal(): void {
    this._tAndCService.openTAndCDialog(false);
  }

  // Out of office
  openOutOfOfficeModal(): void {
    this._loggerService.log('Clicked \'Language\', opening language selection dialog...');
    const outOfOfficeDialogRef = this._tnDialogService.openTnDialog(OutOfOfficeComponent);
  }

  @HostListener('window:beforeunload') onWindowBeforeUnload() {
    this._loggerService.debug('Webclient is being unload');
    if (this._accountManagerService.userId) {
      this._loggerService.debug('Setting disconnet time...');
      this._socketService.setDisconnectTime();
    }
  }

  manualLogout(): void {
    this._loggerService.log('Clicked \'Logout\', calling webclientService.onLogoutWebclient(false)...');
    this._webclientService.onLogoutWebClient(false);
    this.loggedInUser = null;
  }

  simulateDisconnect(): void {
    this._loggerService.log('Simulating socket disconnection...');
    this._webclientService.simulateDisconnect();
  }
  simulateReconnect(): void {
    this._loggerService.log('Simulating socket reconnecting...');
    this._webclientService.simulateReconnect();
  }

  clearDebugClickCount(): void {
    if (this.debugClickCount > 0) {
      this.debugClickCount = 0;
    }
  }
  onDebugClick(): void {
    this.debugClickCount++;
    if (this.debugClickCount === 10) {
      this.tnDebugger();
      this.clearDebugClickCount();
    }

    setTimeout(() => this.clearDebugClickCount(), 2000);
  }

  tnDebugger(): void {
    const db = this._webclientService.tnDebugger();
    const cookies = this._localStorageManagerService.getAllCookies();
    const logs = this._loggerService.logs;

    const allLogs = {
      db: db,
      cookies: cookies,
      logs: logs
    };

    const file = new Blob([
      JSON.stringify(allLogs, null, 2)
    ], {type: 'text/plain'});
    const fileName = window.location.host + '-' + _.now() + '.log';
    FileSaver.saveAs(file, fileName);
  }

  clearDevClickCount(): void {
    if (this.devClickCount > 0) {
      this.devClickCount = 0;
    }
  }
  onDevToggleClick(): void {
    if (this.isDevMode || !this.IS_ENABLE_DEBUG) {
      return;
    }

    this.devClickCount++;
    if (this.devClickCount === 10) {
      this.enableDevMode();
      this.clearDevClickCount();
    }

    setTimeout(() => this.clearDevClickCount(), 2000);
  }

  enableDevMode(): void {
    this._tnNotificationService.showConfirm(
      'Enable DEV mode?',
      () => {
        this.isDevMode = true;
        this._webclientService.enableDevMode();
      },
      () => {

      }
    );
  }

  checkCurrentPort(): void {
    if (window.location.hash.includes("webapp")){
      this.isWebapp = true;
    }
  }
  
  


}
