<div class="emoji-menu">
  <tn-search-bar class="tn-search-bar" (onKeywordChanged)="searchEmoji($event)"> </tn-search-bar>

  <ng-container *ngIf="!isSearchingMode">
    <div class="emoji-menu-container">
      <div class="emoji-categories">
        <div
          class="category"
          [ngClass]="{ selected: cat == selectedAppCategory }"
          (click)="selectCategory(cat)"
          *ngFor="let cat of emojiAppCategories"
        >
          <div class="category-icon">
            {{ cat.emoji }}
          </div>
        </div>
      </div>

      <div class="emoji-panel">
        <div class="category-label">
          {{ selectedAppCategory.LABEL }}
        </div>
        <div class="emojis-wrapper">
          <div
            class="emoji"
            *ngFor="let e of selectedAppCategory.EMOJI"
            (click)="onEmojiClick(e.emoji)"
            [ngClass]="{ 'selected-emoji': isSentEmoji === e.emoji }"
          >
            {{ e.emoji }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isSearchingMode">
    <div class="emoji-menu-container">
      <div class="emoji-panel">
        <div class="emojis-wrapper" *ngIf="filteredEmoji.length">
          <div
            class="emoji"
            *ngFor="let e of filteredEmoji"
            (click)="onEmojiClick(e.emoji)"
            [ngClass]="{ 'selected-emoji': isSentEmoji === e.emoji }"
          >
            {{ e.emoji }}
          </div>
        </div>

        <tn-empty-page *ngIf="filteredEmoji.length == 0"> </tn-empty-page>
      </div>
    </div>
  </ng-container>
</div>
