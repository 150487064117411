import { LanguageConstant } from "../../constants/language.constant";
import { LanguageString } from "../../strings/string-helper.service";

export const TestStrings: LanguageString[] = [
  {
    key: LanguageConstant.EN.key,
    strings: {
      WEBCLIENT: {
        MENU: {
          LANGUAGE: "Change Language!"
        }
      },
      LOGIN: {
        GET_STATE: {
          MOBILE: {
            INPUT_TIP: "Please input your mobile number?"
          }
        }
      }
    }
  },
  {
    key: LanguageConstant.ZH_CHT.key,
    strings: {

    }
  },
  {
    key: LanguageConstant.ZH_CHS.key,
    strings: {
      
    }
  }
];