export const ZhChtMenuStrings = {
  MY_PROFILE: "個人檔案",

  SETTINGS: {
    OUT_OF_OFFICE: {
      TITLE: "不在辦公室",
      NOW_TO: "現在至"
    }
  },

  NEWS: "消息",
  CHATS: "聊天",
  CONTACTS: "通訊錄",
  PROFILE: "個人檔案",
  DUTY_ROSTER: "值勤表",
  TRAINING: "檔案分享",
  CORPORATE_MATERIAL: "檔案分享",
  QUESTIONNAIRE: "問卷調查",
  STORE_REPORT: "報告",
  JOB_DISPATCH: "指派工作",
  SCHEDULER: "更表",
  EVENT: "活動",
  NEWS_CATEGORY: "News Category",
  WORKFLOW: "工作流程",
  VIDEO_STREAMING: "視訊串流",
  MESSAGE_STAR: '星號信息'
}