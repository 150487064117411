<div class="questionnaire-list-container">
  <tn-page-title [title]="'WEBCLIENT.MENU.QUESTIONNAIRE' | translate">
  </tn-page-title>

  <div class="questionnaire-list">
    <ng-container *ngIf="questionnaires.length > 0">
      <tn-questionnaire-cell 
        *ngFor="let q of questionnaires" 
        [questionnaire]="q" 
        [activeQuestionnaire]="activeQuestionnaire"
        (click)="questionnaireClick(q)">
      </tn-questionnaire-cell>
    </ng-container>

    <tn-empty-page *ngIf="questionnaires.length == 0"></tn-empty-page>    
  </div>
</div>