<div class="job-dispatch-action-wrapper">

  <ng-container *ngIf="job.accept_status === CONSTANT.JOB_DISPATCH_STATUS.PENDING">
    <button class="btn btn-danger" (click)="reject()">
      {{'WEBCLIENT.JOB_REPORT.ACTION.REJECT_BTN' | translate}}
    </button>
    <button class="btn btn-success" (click)="accept()">
      {{'WEBCLIENT.JOB_REPORT.ACTION.ACCEPT_BTN' | translate}}
    </button>
  </ng-container>

  <ng-container *ngIf="job.accept_status === CONSTANT.JOB_DISPATCH_STATUS.ACCEPTED">
      <button class="btn btn-success" (click)="start()">
        {{'WEBCLIENT.JOB_REPORT.ACTION.START_BTN' | translate}}
      </button>
  </ng-container>

  <ng-container *ngIf="job.accept_status === CONSTANT.JOB_DISPATCH_STATUS.STARTED">
      <button class="btn btn-danger" (click)="needFollowUp()">
        {{'WEBCLIENT.JOB_REPORT.ACTION.NEED_FOLLOW_UP_BTN' | translate}}
      </button>
      <button class="btn btn-success" (click)="end()">
        {{'WEBCLIENT.JOB_REPORT.ACTION.END_BTN' | translate}}
      </button>
  </ng-container>

</div>