<div class="message-emoji-info-wrapper">
  <h4>{{'WEBCLIENT.CHATROOM.EMOJI_INFO.TITLE' | translate}}</h4>
  <div>
    <div class="emojis-wrapper">
      <div class="emoji-preview-wrapper">
        <div
          class="all-emoji"
          [ngClass]="{ clickable: currentEmojiMarker !== 'all', selected: currentEmojiMarker === 'all' }"
          (click)="setCurrentEmoji('all')"
        >
          {{ 'WEBCLIENT.CHATROOM.EMOJI_INFO.PREVIEW_TITLE' | translate : { emoji_count: message.emojis.length } }}
        </div>
        <div class="emoji-preview">
          <div
            class="emoji"
            [ngClass]="{ clickable: currentEmojiMarker !== e.content, selected: currentEmojiMarker === e.content }"
            (click)="setCurrentEmoji(e.content)"
            *ngFor="let e of emojiDisplay"
          >
            {{ e.content }}
            <span class="emoji-count">{{ e.count }}</span>
          </div>
        </div>
      </div>
      <div class="emoji-detail-wrapper">
        <div class="emoji-row" *ngFor="let emo of emojis">
          <tn-contact-cell
            [contact]="emo.account"
            [isShowDesc]="false"
            [isClickable]="false"
          >
            <div class="extra-desc">
              <div class="desc timestamp text-color-secondary">
                {{ emo.timestamp | tnDate : 'MESSAGE_INFO_DATE' }}
              </div>
            </div>
            <div class="additional-right emoji-placeholder">
              {{ emo.content }}
            </div>
          </tn-contact-cell>
        </div>
      </div>
    </div>
  </div>
</div>
