<div class="tn-chat-cell search-tn-chat-cell clickable"
  (click)="messageClick($event)">
  <div class="avatar-wrapper">
    <div
      *ngIf="!isInChat"
      class="avatar"
      [ngClass]="{
        'default-contact-avatar': chat.t == PRESENCE_TYPE.INDIVIDUAL_CHAT,
        'default-group-avatar': chat.t != PRESENCE_TYPE.INDIVIDUAL_CHAT
      }"
      [ngStyle]="{ 'background-image': chat.picBgUrl }"
    ></div>

    <div
      *ngIf="isInChat"
      class="avatar default-contact-avatar"
      [ngStyle]="{ 'background-image': senderContact.avatarImageSrc }"
    ></div>
  </div>

  <div class="info-wrapper">
    <div class="row1">
      <div class="name col1 text-color-primary">
        <ng-container *ngIf="!isInChat">
          <div 
            [ngClass]="{
              'important-user': isEnableImportantUsers,
              'global-vip': chat.importantLevel === IMPORTANT_LEVEL.GLOBAL,
              'individual-vip': chat.importantLevel === IMPORTANT_LEVEL.INDIVIDUAL
            }"
          >
            {{ chat.displayName }}
            <ng-container *ngIf="isEnableImportantUsers">
              <div class="global-vip-icon" *ngIf="chat.importantLevel === IMPORTANT_LEVEL.GLOBAL"></div>
              <div class="individual-vip-icon" *ngIf="chat.importantLevel === IMPORTANT_LEVEL.INDIVIDUAL"></div>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="isInChat">
          <div 
            [ngClass]="{
              'important-user': isEnableImportantUsers,
              'global-vip': senderContact.importantLevel === IMPORTANT_LEVEL.GLOBAL,
              'individual-vip': senderContact.importantLevel === IMPORTANT_LEVEL.INDIVIDUAL
            }"
          >
            {{ senderContact.name }}
            <ng-container *ngIf="isEnableImportantUsers">
              <div class="global-vip-icon" *ngIf="senderContact.importantLevel === IMPORTANT_LEVEL.GLOBAL"></div>
              <div class="individual-vip-icon" *ngIf="senderContact.importantLevel === IMPORTANT_LEVEL.INDIVIDUAL"></div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="row2">
      <div class="desc col1 last-msg text-color-secondary">
        <ng-container *ngIf="messageBody.message; else nonMessage">
          <!-- {{messageBody.message}} -->
          <span *ngFor="let mf of msgFragment" [ngClass]="{ 'text-color-primary': mf.isKeyword }">{{ mf.text }}</span>
        </ng-container>
        <ng-template #nonMessage>
          <ng-container *ngIf="message.type == MSG_TYPE.LOCATION">
            <!-- {{messageBody.address}} -->
            <span *ngFor="let mf of msgFragment" [ngClass]="{ 'text-color-primary': mf.isKeyword }">{{ mf.text }}</span>
          </ng-container>
          <ng-container *ngIf="message.type == MSG_TYPE.ATTACHMENT">
            <!-- {{messageBody.filename}} -->
            <span *ngFor="let mf of msgFragment" [ngClass]="{ 'text-color-primary': mf.isKeyword }">{{ mf.text }}</span>
          </ng-container>
        </ng-template>
      </div>

      <div class="desc col2 last-msg-time">
        {{messageTime | relativeDateTime}}
      </div>
    </div>
  </div>
</div>