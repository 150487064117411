import { LanguageConstant } from "../../constants/language.constant";
import { LanguageString } from "../../strings/string-helper.service";

export const HkaaStrings: LanguageString[] = [
  {
    key: LanguageConstant.EN.key,
    strings: {
      WEBCLIENT: {
        MENU: {
          AA_GENERAL_NEWS: "News",
          AA_NOTICE_BOARD: "Notice Board",
          AA_IDEA_SOURCING: "HKIA Innovator"
        }
      }
    }
  },
  {
    key: LanguageConstant.ZH_CHT.key,
    strings: {
      
    }
  },
  {
    key: LanguageConstant.ZH_CHS.key,
    strings: {
      
    }
  }
];
