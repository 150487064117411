<div class="tn-news-cell" [id]="news.message_id" *ngIf="news" [ngClass]="{'news-carousel-display': isCarousel}">
  <div class="news-base-display-wrapper" [ngStyle]="{'flex-direction': !isInModal ? (isEnableNewsHttp ? 'row' : 'column') : 'column'}" [ngClass]="{'news-modal-base-display': isInModal}">
    <div class="news-detail" (click)="onDetailClick()" [ngClass]="{'clickable': news.isNews && !isInModal}" [ngStyle]="{'order': isInModal ? '2' : '0'}">
<!--      <div class="avatar-wrapper">-->
<!--        <div class="avatar" [ngClass]="{'avatar-news': news.isNews, 'avatar-vote': !news.isNews}"></div>-->
<!--      </div>-->
      <div class="info-wrapper" [ngStyle]="{'margin-bottom': isEnableNewsHttp ? '0px' : '10px', 'margin-top': isInModal ? '10px' : '0px'}">
        <div class="title" [ngClass]="{'title-full': !news.contentFragment}">{{news.title}}</div>
        <div [ngClass]="{'create-date': !isInModal, 'create-date-in-modal': isInModal}" [ngStyle]="{'margin-top': isEnableNewsHttp ? '10px' : '0px'}">{{news.date | tnDate: 'WEBCLIENT.NEWS.NEWS.NEWS_DATE'}}</div>
        <div class="news-content-preview" [ngStyle]="{'margin-top': isEnableNewsHttp ? '10px' : '0px'}" *ngIf="(isEnableNewsContentPreview || isEnableNewsHttp) && news.contentFragment && !isInModal">
          {{news.contentFragment}}
        </div>

        <tn-news-media
                [newsHttp]="news"
                [media]="news.media"
                [attachments]="news.originalMessage.attachments"
                [isInModal]="isInModal">
        </tn-news-media>
        <div class="content" *ngIf="isInModal">
          <p [innerHTML]="news.htmlContent">
        </div>
      </div>
    </div>
    
    <tn-news-image
      [attachments]="news.originalMessage.attachments"
      [images]="news.images"
      [media]="news.media"
      [previewImageH]="news.previewImageH"
      [isInModal]="isInModal"
      [isCarousel]="isCarousel"
      (onImageClick)="onImageClick($event)">
    </tn-news-image>
  </div>

  <div class="emoji-wrapper" *ngIf="isEnableEmoji">
    <div class="emoji" 
      [ngClass]="{'emoji-sent': emojiC.isSent, 'clickable': news.isThreadOpen, 'emoji-hover': news.isThreadOpen}" 
      *ngFor="let emojiC of news.emojiCounts"
      (click)="onEmojiClick(emojiC)">
      <div class="emoji-image">
        <img [src]="emojiC.filePath"/>
      </div>
      <span class="emoji-count">{{emojiC.count}}</span>
    </div>

    <div class="emoji emoji-menu" 
      (click)="onEmojiMenuClick()"
      [ngClass]="{'clickable': news.isThreadOpen, 'emoji-hover': news.isThreadOpen}">
      <div class="emoji-image">
        <img [src]="emojiMenuIcon"/>
      </div>
    </div>
  </div>

  <div class="vote-button-row" *ngIf="!news.isNews && !isInModal">
    <div class="vote-button" (click)="onVoteClick(true)">
      <i class="fa fa-thumbs-up fa-fw"></i>
      {{'WEBCLIENT.NEWS.VOTE.VOTE_BTN' | translate}}
    </div>
    <div class="result-button" (click)="onVoteClick(false)">
      <i class="fa fa-bar-chart fa-fw"></i>
      {{'WEBCLIENT.NEWS.VOTE.RESULT_BTN' | translate}}      
    </div>
  </div>

  <div class="like-comment-row" *ngIf="isEnableComment">
    <div class="action-button like-button" 
      (click)="onLikeClick()" 
      [ngClass]="{'clickable': news.isThreadOpen}">
      <img src="assets/images/general/message_like.png"/>
      <div class="action-button-string" *ngIf="news.isThreadOpen">
        <span *ngIf="!news.isLiked">
          {{'WEBCLIENT.NEWS.NEWS.LIKE' | translate}}
        </span>
        <span *ngIf="news.isLiked">
          {{'WEBCLIENT.NEWS.NEWS.UNLIKE' | translate}}
        </span>
      </div>
      <div class="action-count">
        {{news.likeCount}}
      </div>
    </div>
    <div class="action-button comment-button" 
      (click)="onCommentClick()" 
      [ngClass]="{'clickable': !isInModal}">
      <img src="assets/images/general/message_comment.png"/>
      <div class="action-button-string">
        {{'WEBCLIENT.NEWS.NEWS.COMMENT' | translate}}
      </div>
      <div class="action-count" *ngIf="news.commentCount > 0">
        {{news.commentCount}}
      </div>
    </div>
  </div>

</div>
