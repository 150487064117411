// Created by Shan - 2018-06-29

import { PageUrlConstant } from "../../constants/page-url.constant";
import { TeamNoteConfig } from "../../configs/models/config";

export const SclConfig: TeamNoteConfig = {
  HOST: {
    DOMAIN: "scl"
  },
  GENERAL: {
    PAGE_TITLE: "iComm",
    VIDEO_SIZE_LIMIT: null,
    ATTACHMENT_SIZE_LIMIT: 10,
    CHAT_GROUP_NAME_MAX_LENGTH: null,
    IS_ENABLE_TNC: true,
    IS_RESTRICT_CLIPBOARD: true
  },
  IMAGES: {
    NOTIFICATION_ICON: "assets/images/clients/mtrscl/notification_icon.png",
    FAVICON: "assets/images/clients/mtrscl/favicon.png",
    FAVICON_ALERT: "assets/images/clients/mtrscl/favicon_alert.png"
  },
  NOTIFICATION: {
    ENABLE_NOTIFICATION_CENTER: true
  },
  WEBCLIENT: {
    GENERAL: {
      WEBCLIENT_DEFAULT_PAGE: PageUrlConstant.WEBCLIENT.CHAT,
      FORCE_LOGIN_NEW_TAB: true
    },
    CHATROOM: {
      ENABLE_WHISPER: true,
      IS_ENABLE_MENTION: true,
      IS_ENABLE_HASHTAG: true,
      IS_ENABLE_SEARCH: true
    },
    CONTACT: {
      LIST_DESC_FIELDS: [
        'title',
        'department',
        'store_name',
        'public_message'
      ],
      CARD_DESC_FIELDS: [
        'title',
        'department',
        'store_name',
        'public_message'
      ],
      FORCE_CONTACT_ACTIONS: true
    },
    WATERMARK: {
      IS_SHOW_OPEN_TIME: true,
      IS_NEED_DATE_TIME: true,
      IS_DRAW_ON_IMAGE: true
    },
    FORMS: {
      IS_ALLOW_EXPORT_FORMS: true
    }
  }
  
};

