import { Component, OnInit, Input } from '@angular/core';
import { VoteOption } from '../../../../models/api-vote';

@Component({
  selector: 'tn-vote-option',
  templateUrl: './vote-option.component.html',
  styleUrls: ['./vote-option.component.scss']
})
export class VoteOptionComponent implements OnInit {

  @Input() option: VoteOption;

  constructor() { }

  ngOnInit() {
    
  }


}
