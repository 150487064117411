import {Component, OnInit, Input, ViewChild, ElementRef, HostListener} from '@angular/core';

import {Attachment} from '../../../models/attachment';

import {WatermarkService} from '../../watermark/watermark.service';

import * as _ from 'lodash';
import {ImageHelperService} from '../../image-helper/image-helper.service';
import {FileFactoryService} from '../../file-factory/file-factory.service';
import {TeamnoteConfigService} from '../../../configs/teamnote-config.service';
import {ModuleManagerService} from '../../../webclient/services/module/module-manager.service';
import {ModuleKeyDefinition} from '../../../constants/module.constant';
import {FileManagerService} from '../../file-manager/file-manager.service';

@Component({
  selector: 'tn-attachment-image',
  templateUrl: './attachment-image.component.html',
  styleUrls: ['./attachment-image.component.scss']
})
export class AttachmentImageComponent implements OnInit {
  @Input() attachmentIds: string[] = [];
  @Input() defaultFileId: string = null;
  @Input() filename: string = null;
  @Input() filenameMap: object = {};
  @Input() isDisableWatermark = false;
  @Input() editImageCallbackWithImageUrlAndCaption: Function = null;
  @Input() isDisableDownload: boolean = false;

  @ViewChild('imgCanvas', {static: false}) imgCanvas: ElementRef;
  @ViewChild('imageWatermarkCanvas', {static: true}) imageWatermarkCanvas: ElementRef;

  isAllowAttachmentSave: boolean = false;

  imgSrc: string;
  context: CanvasRenderingContext2D;
  image;

  realViewingImageDataUrl: string = null;
  originalImageDataUrl: string = null;
  currentCaption = '';
  currentAttachmentId = '';

  imageNum = 1;

  constructor(
    private _fileManagerService: FileManagerService,
    private _watermarkService: WatermarkService,
    private _imageHelperService: ImageHelperService,
    private _fileFactoryService: FileFactoryService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _moduleManagerService: ModuleManagerService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (!this.attachmentIds) {
      return;
    }
    this.isAllowAttachmentSave = this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.ATTACHMENT_SAVE);
    
    //Always allow attachment download
    if (this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.IS_ALWAYS_ALLOW_DOWNLOAD){
      this.isAllowAttachmentSave = true;
    }

    // default behaviour for encrypyed message download
    if (!this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.IS_DISABLE_ENCRYPTED_MSG_DOWNLOAD){
      this.isDisableDownload = false;
    }

    if (this.defaultFileId) {
      const index = _.indexOf(this.attachmentIds, this.defaultFileId);
      this.imageNum = index === -1 ? 1 : index + 1;
    }

    this.prepareImage(this.imageNum);
    this.drawOverlayWatermark();
  }

  prepareImage(index: number): void {
    this.realViewingImageDataUrl = null;
    this._fileFactoryService.getFileByAttachmentId(
      this.attachmentIds[index - 1],
      (imgSrc, fileName) => {
        const image = new Image();
        image.crossOrigin = 'anonymous';
        image.onload = () => {
          this.drawToCanvas(image);
        };
        image.src = imgSrc;
      },
      () => {
      },
      false
    );
    this.currentCaption = this._imageHelperService.getImageCaptionById(this.attachmentIds[index - 1]);
    this.currentAttachmentId = this.attachmentIds[index - 1];
  }

  drawOverlayWatermark(): void {
    if (this._teamnoteConfigService.config.WEBCLIENT.WATERMARK.IS_DRAW_ON_IMAGE) {
      return;
    }
    if (this.isDisableWatermark) {
      return;
    }
    const watermarkCanvas: HTMLCanvasElement = this.imageWatermarkCanvas.nativeElement;
    const parent = watermarkCanvas.parentElement;
    const imgW = parent.clientWidth;
    const imgH = parent.clientHeight;
    watermarkCanvas.width = imgW;
    watermarkCanvas.height = imgH;
    const watermarkContext = watermarkCanvas.getContext('2d');
    this._watermarkService.drawWatermark(watermarkContext, imgW, imgH);
  }

  drawToCanvas(image): void {
    // let canvas: HTMLCanvasElement = this.imgCanvas.nativeElement;
    const canvas: HTMLCanvasElement = document.createElement('canvas');

    const w = image.width;
    const h = image.height;

    // max 80vh & 80vw, *parent has padding:24px;
    // let maxH = window.innerHeight * 0.8;
    // let maxW = window.innerWidth * 0.8 - 48;

    // if (h > maxH) {
    //   w = w * (maxH / h);
    //   h = maxH;
    // }

    // if (w > maxW) {
    //   h = h * (maxW / w);
    //   w = maxW;
    // }

    canvas.width = w;
    canvas.height = h;
    this.context = canvas.getContext('2d');
    this.context.drawImage(image, 0, 0, w, h);
    this.originalImageDataUrl = canvas.toDataURL('image/jpeg');

    if (this._teamnoteConfigService.config.WEBCLIENT.WATERMARK.IS_DRAW_ON_IMAGE) {
      // draw on pic
      if (!this.isDisableWatermark) {
        this._watermarkService.drawWatermark(this.context, w, h);
      }
      this.realViewingImageDataUrl = canvas.toDataURL('image/jpeg');

      setTimeout(() => {
        this.realViewingImageDataUrl = canvas.toDataURL('image/jpeg');
      }, 50);
    } else {
      // overlay
      this.realViewingImageDataUrl = this.originalImageDataUrl;
      // setTimeout(() => {
      //   let watermarkCanvas = this.imageWatermarkCanvas.nativeElement;
      //   let img = watermarkCanvas.nextElementSibling;
      //   let imgW = img.clientWidth;
      //   let imgH = img.clientHeight;
      //   watermarkCanvas.width = imgW;
      //   watermarkCanvas.height = imgH;
      //   let watermarkContext = watermarkCanvas.getContext("2d");
      //   this._watermarkService.drawWatermark(watermarkContext, imgW, imgH);
      // }, 100);
    }
  }

  prevImage() {
    if (this.imageNum <= 1) {
      return;
    }
    this.imageNum--;
    this.onImageIndexChange();
  }

  nextImage() {
    if (this.imageNum >= this.attachmentIds.length) {
      return;
    }
    this.imageNum++;
    this.onImageIndexChange();
  }

  onImageIndexChange() {
    if (!this.imageNum) {
      return;
    }
    if (this.imageNum < 1 || this.imageNum > this.attachmentIds.length) {
      return;
    }
    this.prepareImage(this.imageNum);
  }

  downloadImage(): void {
    const link = document.createElement('a');
    if (this.attachmentIds[0].includes('blob:http')) {
      link.download = this.filename;
      link.href = this.attachmentIds[0];
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      let fileName = null;
      if (!_.isEmpty(this.filenameMap)) {
        fileName = this.filenameMap[this.currentAttachmentId];
      }

      if (!fileName) {
        const components = this.currentAttachmentId.split('.');
        const name = components[0];
        const extension = components[components.length - 1];
        fileName = `${name}.${(new Date()).toISOString()}.${extension}`;
      }

      this._fileManagerService.downloadFileByAttachmentId(this.currentAttachmentId, fileName);
    }
  }

  editImage(): void {
    if (this.editImageCallbackWithImageUrlAndCaption) {
      this.editImageCallbackWithImageUrlAndCaption(
        this.originalImageDataUrl,
        this.currentCaption,
        this.currentAttachmentId
      );
    }
  }

  @HostListener('window:keydown', ['$event']) handleKeyDown(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowRight':
        this.nextImage();
        break;
      case 'ArrowLeft':
        this.prevImage();
        break;
    }
  }

  onRightClick() {
    // prevent download image via right click
    return this.isAllowAttachmentSave && !this.isDisableDownload;
  }

  // Save image
  // https://stackoverflow.com/questions/15931341/saving-canvas-locally-in-ie
}
