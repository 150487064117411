import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebviewComponent } from './webview.component';
import { UtilitiesModule } from '../../utilities/utilities.module';

@NgModule({
  imports: [
    CommonModule,
    UtilitiesModule
  ],
  declarations: [WebviewComponent]
})
export class WebviewModule { }
