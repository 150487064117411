export const WorkflowConstant = {
  LIST_FORMAT_MAGIC_STRING_PLACEHOLDER: {
    TEAM_NAME: "%%teamName%%",
    TEAM_CODE: "%%teamCode%%",
    TYPE_NAME: "%%typeName%%",
    FORM_NO: "%%formNo%%",
    CREATOR: "%%creator%%",
    CREATE_DATE: "%%createDate%%",
    LAST_UPDATE_DATE: "%%lastUpdateDate%%",
    ACTIVE_STAGE_NAME: "%%activeStageName%%",
    ACTIVE_STAGE_NUMBER: "%%activeStageNumber%%",
    TOTAL_STAGE_NUMBER: "%%totalStageNumber%%"
  },
  PIC_METHOD: {
    CREATOR: "creator",
    ASSIGNMENT: "assignment",
    SELF_NOMINATION: "self_nomination",
    SERVER_NOMINATION: "server_nomination",
    RANDOM: "random",
    NONE: "none"
  }
}