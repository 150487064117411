import { Injectable } from '@angular/core';
import { Question } from '../../models/questionnaire';

@Injectable()
export class QuestionFreeTextService {

  constructor() { }

  getParsedFreeTextQuestion(q: Question): Question {
    q.textDetail = JSON.parse(q.detail);
    q.value = "";
    q.is_mandatory = q.textDetail.is_mandatory === 1;
    return q;
  }

  getCheckedFreeTextQuestion(q: Question): Question {
    if (q.textDetail.is_mandatory === 1 && q.value === '') {
      q.hasError = true;
    }
    return q;
  }

  getParsedFreeTextAnswer(q: Question): any {
    let ansArr = [];
    ansArr.push(q.value);
    return ansArr;
  }
}
