import { Component, OnInit, Input } from '@angular/core';
import { Questionnaire } from '../models/questionnaire';

@Component({
  selector: 'tn-questionnaire-cell',
  templateUrl: './questionnaire-cell.component.html',
  styleUrls: ['./questionnaire-cell.component.scss']
})
export class QuestionnaireCellComponent implements OnInit {

  @Input() questionnaire: Questionnaire;
  @Input() activeQuestionnaire: Questionnaire;

  constructor() { }

  ngOnInit() {
  }

}
