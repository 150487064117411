import { Pipe, PipeTransform } from '@angular/core';

var subWeeks = require('date-fns/sub_weeks');
var getDayOfYear = require('date-fns/get_day_of_year');
var isAfter = require('date-fns/is_after');
import { DateService } from '../date.service';

@Pipe({
  name: 'relativeDateTime'
})
export class RelativeDateTimePipe implements PipeTransform {

  constructor(
    private _dateService: DateService
  ) { }

  transform(timestamp: any): string {
    if (!timestamp) {
      return '';
    }
    let msgTime = new Date(timestamp * 1000);

    let today = new Date();
    let oneWeekBefore = subWeeks(today, 1);

    if (getDayOfYear(today) == getDayOfYear(msgTime)) {
      // Today
      return this._dateService.getDateDisplayWithKey(timestamp, 'TIME');
    } else if (isAfter(msgTime, oneWeekBefore)) {
      // within one week
      return this._dateService.getDateDisplayWithKey(timestamp, 'WEEKDAY');
    } else {
      // more than one week ago
      return this._dateService.getDateDisplayWithKey(timestamp, 'DATE');
    }
  }

}
