import { Component, OnInit, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Message } from '../../../../models/message';
import { TeamnoteApiService } from '../../../../api/teamnote-api.service';

import * as _ from 'lodash';
import { UserContactService } from '../../../services/data/user-contact/user-contact.service';
import { AccountManagerService } from '../../../services/account/account-manager.service';
import { InfoMessageService } from '../../../services/data/messages/info-message/info-message.service';
import { MessageReadAPI } from '../../../../models/message-read';
import { MessageDeliveryAPI } from '../../../../models/message-delivery';
import { TnNotificationService } from '../../../../utilities/tn-notification/tn-notification.service';
import { TeamnoteConfigService } from '../../../../configs/teamnote-config.service';
import { Chat } from '../../../../models/chat';
import { TabsetComponent, TabDirective } from 'ngx-bootstrap/tabs';
import { Emoji } from '../../../../models/emoji';

@Component({
  selector: 'tn-message-emoji-info',
  templateUrl: './message-emoji-info.component.html',
  styleUrls: ['./message-emoji-info.component.scss']
})
export class MessageEmojiInfoComponent implements OnInit {
  currentEmojiMarker: string = ''

  message: Message;
  chat: Chat;

  isShowEmojiDetails: boolean = false;
  emojis: any[];
  emojiDisplay: any[];

  constructor(
    public dialogRef: MatDialogRef<MessageEmojiInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdref: ChangeDetectorRef,
    private _teamnoteApiService: TeamnoteApiService,
    private _userContactService: UserContactService,
    private _accountManagerService: AccountManagerService,
    private _infoMessageService: InfoMessageService,
    private _tnNotificationService: TnNotificationService,
    private _teamnoteConfigService: TeamnoteConfigService
  ) { }

  ngOnInit() {
    this.dialogRef.updateSize('50vw');

    this.message = this.data.message;
    this.chat = this.data.chat;

    this.isShowEmojiDetails = this.checkIfNeedShowEmojiDetail()
    this.emojis = this.prepareEmojiForDisplay(this.message.emojis);
    this.emojiDisplay = this.message.emojiDisplay

    this.setCurrentEmoji('all')
  }

  checkIfNeedShowEmojiDetail(): boolean {
    if (_.isEmpty(this.message.emojiDisplay)) {
      return false
    }

    return true
  }

  prepareEmojiForDisplay(originalEmojis: Emoji[]): any[] {
    let emojis = _.map(originalEmojis, (emo) => {
      return {
        ...emo,
        account: this._userContactService.getUserContactByUserId(emo.sent_by),
        isSentByMe: emo.sent_by === this._accountManagerService.userId
      }
    })

    return _.orderBy(emojis, ['timestamp'], ['desc']);
  }

  setCurrentEmoji(emoji: string): void {
    if (this.currentEmojiMarker === emoji) {
      return
    }
    
    this.currentEmojiMarker = emoji

    this.filterEmojiSender()
  }
  
  filterEmojiSender(): void {
    let filtered = this.prepareEmojiForDisplay(this.message.emojis);

    if (this.currentEmojiMarker === 'all') {
      this.emojis = filtered
    } else {
      this.emojis = _.filter(filtered, (emo) => emo.content === this.currentEmojiMarker)
    }
  }
  
}
