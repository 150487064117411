<form>
  <section class="form-block">
    <div class="login-body-row input-action-msg">
      {{inputTipKey | translate}}
    </div>
    <div class="login-body-row">
        <input #secondFactorInput class="form-control" type="text" [(ngModel)]="secondFactor" name="secondFactor" placeholder="{{inputPlaceholderKey | translate}}" autocomplete="off">
    </div>
    <div class="login-body-row">
      <button class="btn tn-button-primary btn-block" (click)="submit()">{{'LOGIN.PASSWORD.SUBMIT' | translate}}</button>
    </div>
  </section>
</form>
