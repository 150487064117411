import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StoreReport } from '../models/job-report';

@Component({
  selector: 'tn-store-report-list',
  templateUrl: './store-report-list.component.html',
  styleUrls: ['./store-report-list.component.scss']
})
export class StoreReportListComponent implements OnInit {
  
  @Input() storeReports: StoreReport[];  
  @Input() viewingReport: StoreReport;  
  @Input() isEnabledJobDispatch: boolean;
  @Output() onStoreReportClick: EventEmitter<StoreReport> = new EventEmitter<StoreReport>(null);

  scrollItems: any;

  constructor() { }

  ngOnInit() {
  }


  onReportCellClick(report: StoreReport) {
    this.onStoreReportClick.emit(report);
  }



}
