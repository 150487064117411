import { LanguageString } from "../../strings/string-helper.service";
import { LanguageConstant } from "../../constants/language.constant";

export const AvsecoStrings: LanguageString[] = [
  {
    key: LanguageConstant.EN.key,
    strings: {
      WEBCLIENT: {
          MENU: {
          AVSECO_INTRANET: "Intranet",
          AVSECO_ESS: "ESS"
        }
      }
    }
  },
  {
    key: LanguageConstant.ZH_CHT.key,
    strings: {
      WEBCLIENT: {
        MENU: {
          AVSECO_INTRANET: "內部網",
          AVSECO_ESS: "ESS"
        }
      }
    }
  },
  {
    key: LanguageConstant.ZH_CHS.key,
    strings: {
      WEBCLIENT: {
        MENU: {
          AVSECO_INTRANET: "内部网",
          AVSECO_ESS: "ESS"
        }
      }
    }
  }
]