import { Component, OnInit, Inject, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { ImageHelperService } from '../image-helper/image-helper.service';
import { TeamnoteConfigService } from '../../configs/teamnote-config.service';

var TuiImageEditor = require('tui-image-editor');

@Component({
  selector: 'tn-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss']
})
export class ImageEditorComponent implements OnInit {

  imageDataUrl: string = null;
  isAllowImageCaption: string = null;
  modalEditCompleteFunction: Function;
  dateObj: Date;
  isNeedDateTimeOverlay: boolean = false;

  // Caption
  imageCaption: string = '';

  // Constant Settings
  IMAGE_CONTAINER_ID = 'image-container';
  MAX_IMAGE_HEIGHT_RATIO = 0.7;
  MAX_IMAGE_WIDTH_RATIO = 0.7;
  EDITOR_ACTION = {
    PREVIEW: 0,
    DRAW: 1,
    CROP: 2
  };
  PEN_COLORS = ['red', 'green', 'blue'];
  PEN_MODES = {
    PEN: 'pen',
    ERASER: 'eraser',
    CLEAR: 'clear'
  };
  MIN_CROPPED_SIZE_PX = 100;

  image: any;
  optimalScale: number;
  currentAction: number;

  // for draw
  currentColor: string = this.PEN_COLORS[0];
  currentMode: string = this.PEN_MODES.PEN;

  // Tui Image Editor
  tuiImageEditorElement: any;

  @ViewChild('previewBlockerLayerCanvas', {static: false}) previewBlockerLayerCanvas: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<ImageEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _imageHelperService: ImageHelperService,
    private _teamnoteConfigService: TeamnoteConfigService
  ) { }

  ngOnInit() {
    this.imageDataUrl = this.data.imageDataUrl;
    this.isAllowImageCaption = this.data.isAllowImageCaption;
    this.modalEditCompleteFunction = this.data.editCompleteFunction;
    this.dateObj = this.data.dateObj;
    this.isNeedDateTimeOverlay = this.data.isNeedDateTimeOverlay;
    this.imageCaption = this.data.imageCaption;

    this.currentAction = this.EDITOR_ACTION.PREVIEW;
  }

  ngOnChanges() {

  }

  ngAfterViewInit() {
    this.resizeImage(this.imageDataUrl);
  }

  /**
   * Resize image first, then prepare tui-image-editor
   * 
   * @param dataUrl 
   */
  resizeImage(dataUrl: string) {
    this._imageHelperService.resizeImageByDataUrl(
      dataUrl,
      (resizedDataUrl) => {
        this.imageDataUrl = resizedDataUrl;
        this.prepareTuiImageEditor();
      }
    );
  }


  /**
   * Create tui-image-editor with max width & max height,
   * then load the resized image to editor
   * 
   * @memberof ImageEditorComponent
   */
  prepareTuiImageEditor(): void {
    let maxHeight = window.innerHeight * this.MAX_IMAGE_HEIGHT_RATIO;
    let maxWidth = window.innerWidth * this.MAX_IMAGE_WIDTH_RATIO;

    this.tuiImageEditorElement = new TuiImageEditor(
      document.querySelector("#image-editor-canvas"),
      {
        cssMaxWidth: maxWidth,
        cssMaxHeight: maxHeight
      }
    );
    this.loadImageIntoTuiImageEditorByDataUrl(this.imageDataUrl);
  }


  /**
   * Load image to editor, by dataURL,
   * go to preview mode after loaded
   *
   * @param {string} dataUrl
   * @memberof ImageEditorComponent
   */
  loadImageIntoTuiImageEditorByDataUrl(dataUrl: string): void {
    this.tuiImageEditorElement.loadImageFromURL(
      dataUrl, 'image'
    ).then(result => {
      this.preview();
    });
  }


  /**
   * Update preview blocker layer's canvas
   * Usage: To block tui-image-editor when user is in preview mode
   * Draw photo's date time on this layer if config is enabled
   *
   * @param {number} width
   * @param {number} height
   * @returns {void}
   * @memberof ImageEditorComponent
   */
  updatePreviewBlockerLayerCanvas(width: number, height: number): void {
    if (!this.previewBlockerLayerCanvas) {
      return;
    }

    let canvas: HTMLCanvasElement = this.previewBlockerLayerCanvas.nativeElement;
    canvas.width = width;
    canvas.height = height;
    if (!this.isNeedDateTimeOverlay) {
      return;
    }
    if (!this._teamnoteConfigService.config.WEBCLIENT.WATERMARK.IS_NEED_DATE_TIME) {
      return;
    }
    if (!this.dateObj) {
      this.dateObj = new Date();
    }
    let ctx = canvas.getContext("2d");
    this._imageHelperService.drawDateTimeToImageCtxByDateObj(ctx, this.dateObj, width, height);
  }

  /**
   * Control Hub for Cancel function
   */
  cancel() {
    switch (this.currentAction) {
      case this.EDITOR_ACTION.PREVIEW:
        this.previewCancel();
        break;
      case this.EDITOR_ACTION.DRAW:
        this.drawCancel();
        break;
      case this.EDITOR_ACTION.CROP:
        this.cropCancel();
        break;
    }
  }

  /**
   * Control Hub for Done function
   */
  done() {
    switch (this.currentAction) {
      case this.EDITOR_ACTION.PREVIEW:
        this.previewDone();
        break;
      case this.EDITOR_ACTION.DRAW:
        this.drawDone();
        break;
      case this.EDITOR_ACTION.CROP:
        this.cropDone();
        break;
    }
  }

  // PREVIEW
  /**
   * Set currentAction to PREVIEW
   * Stop image editor drawing mode
   * Update preview blocker layer canvas with current image editor's display size
   */
  preview() {
    this.currentAction = this.EDITOR_ACTION.PREVIEW;

    this.tuiImageEditorElement.stopDrawingMode();

    let tuiImageEditorDiv = document.getElementsByClassName("tui-image-editor-canvas-container");
    if (tuiImageEditorDiv.length > 0) {
      this.updatePreviewBlockerLayerCanvas(
        tuiImageEditorDiv[0].clientWidth,
        tuiImageEditorDiv[0].clientHeight
      );
    }
  }

  /**
   * Close dialog
   */
  previewCancel() {
    this.dialogRef.close();
  }

  /**
   * Get dataUrl and call 'CompleteFunction'
   */
  previewDone() {
    // DONE!!!
    this.modalEditCompleteFunction(
      this.tuiImageEditorElement.toDataURL(), 
      this.imageCaption
    );
    this.previewCancel();
  }

  // DRAW
  /**
   * Set currentAction to DRAW
   * "Hide" preview blocker layer
   * Start drawing mode with brush settings
   */
  draw() {
    this.updatePreviewBlockerLayerCanvas(0, 0);
    this.currentAction = this.EDITOR_ACTION.DRAW;

    this.tuiImageEditorElement.stopDrawingMode();
    let brushSettings = {
      width: 5,
      color: this.currentColor
    };
    this.tuiImageEditorElement.startDrawingMode("FREE_DRAWING", brushSettings);
  }

  /**
   * Clear all drawings
   * Go to PREVIEW
   */
  drawCancel() {
    this.setPenMode(this.PEN_MODES.CLEAR);
    this.tuiImageEditorElement.stopDrawingMode();
    this.preview();
  }

  /**
   * Go back to PREVIEW (drawing will be preserved)
   */
  drawDone() {
    this.tuiImageEditorElement.stopDrawingMode();
    this.preview();
  }

  /**
   * Set drawing pen color
   * @param color
   */
  setPenColor(color: string) {
    this.currentColor = color;
    this.currentMode = this.PEN_MODES.PEN;

    this.tuiImageEditorElement.setBrush({
      color: this.currentColor
    });

  }

  /**
   * Set drawing pen mode
   * @param mode 
   */
  setPenMode(mode: string) {
    this.currentMode = mode;
    if (mode == this.PEN_MODES.CLEAR) {
      this.tuiImageEditorElement.clearObjects();
      this.currentMode = this.PEN_MODES.PEN;
    }
  }


  // CROP
  /**
   * Set currentAction to CROP
   * "Hide" preview blocker layer canvas
   */
  crop() {
    this.updatePreviewBlockerLayerCanvas(0, 0);
    this.currentAction = this.EDITOR_ACTION.CROP;
    this.tuiImageEditorElement.startDrawingMode('CROPPER');
  }
  /**
   * Go to PREVIEW and clear cropping layer
   */
  cropCancel() {
    this.tuiImageEditorElement.stopDrawingMode();
    this.preview();
  }

  /**
   * Crop image
   */
  cropDone() {
    this.tuiImageEditorElement.crop(this.tuiImageEditorElement.getCropzoneRect()).then(() => {
      setTimeout(
        () => {
          this.cropCancel();
        },
        100
      );
    });
    this.cropCancel();
  }


}
