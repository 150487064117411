import { Injectable } from '@angular/core';
import { TeamnoteApiService } from '../../api/teamnote-api.service';
import { LocalStorageManagerService } from '../local-storage/local-storage-manager.service';
import { TeamnoteConfigService } from '../../configs/teamnote-config.service';
import { TeamNoteApiConstant } from '../../constants/api.constant';
import { TeamNoteLocalStorageKeyConstants } from '../../constants/local-storage-key.constant';
import { TimestampService } from '../timestamp/timestamp.service';
import { FileManagerService } from '../file-manager/file-manager.service';
import { AccountService } from '../../account/account.service';
import { FileFactoryService } from '../file-factory/file-factory.service';
import { BehaviorSubject } from 'rxjs';
import { AccountManagerService } from '../../webclient/services/account/account-manager.service';

import { UtilitiesService } from '../service/utilities.service';

export class WaterMarkSetting {
  company_domain?: string;
  create_date?: string;
  pic?: string;
  pic_position?: number;
  show_pic?: number;
  show_staff_id?: number;
  staff_id_color?: string;
  staff_id_degree?: number;
  staff_id_format?: string;
  staff_id_spacing?: number;
  update_date?: string;
  mode?: number;
  zIndex?: number;

  constructor() {
    this.zIndex = 1001;
  }
}

export const WatermarkMode = {
  ATTACHMENT_ONLY: 0,
  FULL_APP: 1
};

export const WatermarkFormatValues = {
  timestamp: "timestamp"
};

@Injectable()
export class WatermarkService {

  setting: WaterMarkSetting = new WaterMarkSetting();
  setting$: BehaviorSubject<WaterMarkSetting> = new BehaviorSubject<WaterMarkSetting>(null);

  constructor(
    private _teamnoteApiService: TeamnoteApiService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _accountService: AccountService,
    private _fileManagerService: FileManagerService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _timestampService: TimestampService,
    private _fileFactoryService: FileFactoryService,
    private _accountManagerService: AccountManagerService,
    private _utilitiesService: UtilitiesService
  ) { }

  initWatermarkSetting(): void {
    this.setting = null;
    this.updateWatermarkSettingSubject();
  }

  handleInitialWatermarkContent(): void {
    this.getWatermarkSetting();
  }

  updateWatermarkConfig(watermark): void {
    let originalZIndex = this.setting ? this.setting.zIndex : 1001;
    if (watermark) {
      watermark.staff_id_color = this.hexToString(watermark.staff_id_color);
      watermark.staff_id_degree = parseInt(watermark.staff_id_degree, 10);
      watermark.staff_id_spacing = parseInt(watermark.staff_id_spacing, 10);
      this.setting = watermark;
    }
    this.setting.zIndex = originalZIndex;
    this.updateWatermarkSettingSubject();
  }

  getWatermarkSetting() {
    let url = TeamNoteApiConstant.WATERMARK;
    let params = {
      access_token: this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN)
    };

    this._teamnoteApiService.callApi(
      url, 
      params,
      (resp) => {
        if (resp._jwt) {
          this._teamnoteApiService.getE2EEPublicKey(
            (publicKey) => {
              this._utilitiesService.decodeJwt(resp._jwt, publicKey, 
                (decodedWatermark) => {
                  // if null, jwt verify fails, use default watermark
                  if (!decodedWatermark) {
                    decodedWatermark = this._teamnoteConfigService.config.WEBCLIENT.WATERMARK.DEFAULT_WATERMARK;
                  }
                  this.updateWatermarkConfig(decodedWatermark)
                }
              );
            },
            () => {
              // if no public key, use default watermark
              this.updateWatermarkConfig(this._teamnoteConfigService.config.WEBCLIENT.WATERMARK.DEFAULT_WATERMARK)
            }
          )
        } else {
          this.updateWatermarkConfig(resp)
        }
      },
      err => {

      }
    );
  }

  checkIfEnabledFullScreenMode(): boolean {
    switch (this.setting.mode) {
      case WatermarkMode.FULL_APP:
        return true;
      default:
        return false;
    }
  }

  updateWatemarkOverlayZIndexForDialogs(isDisplay): void {
    if (!this.setting) {
      return;
    }
    this.setting.zIndex = isDisplay ? 999 : 1001;
    this.updateWatermarkSettingSubject();
  }

  updateWatermarkSettingSubject(): void {
    this.setting$.next(this.setting);
  }
  
  hexToString(hex) {
    try {
        let a = parseInt(hex.substr(0, 2), 16) / 255;
        let r = parseInt(hex.substr(2, 2), 16);
        let g = parseInt(hex.substr(4, 2), 16);
        let b = parseInt(hex.substr(6, 2), 16);

        return "rgba(" + r + "," + g + "," + b + "," + a.toString(10).substr(0, 4) + ")";
    } catch(err) {
        return "rgba(0,0,0,0)";
    }
  }

  getWatermarkStringDisplayDefault(): string {
    let staff_id = this._teamnoteConfigService.config.WEBCLIENT.WATERMARK.WATERMARK_SHOW_LOGIN_NAME ? this._accountService.userName : this._accountService.userId;

    if (this._teamnoteConfigService.config.WEBCLIENT.WATERMARK.IS_SHOW_OPEN_TIME) {
      staff_id += ' ' + this._timestampService.getNowDisplayDateTime();
    }
    return staff_id;
  }

  getWatermarkStringDisplayByFormat(): string {
    let valueRegEx = /\[(.*)\]/;
    let format = this.setting.staff_id_format;

    do {
      let valueMatch = valueRegEx.exec(format);
      if (valueMatch) {
        let found = valueMatch[1];
        let breakIndex = found.indexOf("]");
        let value = breakIndex != -1 ? found.substr(0, breakIndex) : found;
        let valueFormat = value.split(":");
        let fieldName = valueFormat[0];
        let targetContent: string;

        if (fieldName == WatermarkFormatValues.timestamp) {
          targetContent = this._timestampService.getNowDisplayDateTime();
        } else {
          targetContent = this._accountManagerService.getAccountFieldByFieldName(fieldName);
        }

        // handle length
        if (valueFormat.length == 2) {
          targetContent = targetContent.substr(0, parseInt(valueFormat[1], 10));
        }

        let toBeReplacedFormat = "[" + value + "]";

        format = format.replace(toBeReplacedFormat, targetContent);

      } else {
        break;
      }
    } while (true);

    return format;
  }

  getWatermarkStringDisplay(): string {
    if (!this.setting.staff_id_format) {
      return this.getWatermarkStringDisplayDefault();
    } else {
      return this.getWatermarkStringDisplayByFormat();
    }
  }

  drawWatermark(ctx: CanvasRenderingContext2D, width, height) {
    let settings = this.setting;
    if (!settings) {
      return;
    }

    // Staff ID
    if (settings.show_staff_id) {
      ctx.font = '15px Arial';
      ctx.fillStyle = settings.staff_id_color;
      
      let watermarkDisplayText = this.getWatermarkStringDisplay();

      let spacing = settings.staff_id_spacing;
      let angle = settings.staff_id_degree;
      let no_of_repetition = Math.ceil(width / Math.cos(angle * Math.PI / 180)) / (ctx.measureText(watermarkDisplayText).width / 1.5);
      let watermark = '';
      for (let i = 0; i < no_of_repetition; i++) {
        watermark += watermarkDisplayText + '     ';
      }
      let no_of_rows = Math.ceil(2 * height / (15 + spacing));
      let displacement = Math.ceil(width * Math.sin(angle * Math.PI / 180));

      ctx.rotate(angle * Math.PI / 180);

      for (let i = 0; i <= no_of_rows; i++) {
        ctx.fillText(watermark, 0, i * (15 + spacing) - displacement);
      }
    }

    // Watermark Image
    if (settings.show_pic) {
      let image = new Image();
      image.crossOrigin = 'anonymous';
      
      let drawWaterMarkImg = function (img) {
        ctx.setTransform(1, 0, 0, 1, 0, 0);
        let ratio = Math.min((width / 3) / img.naturalWidth, (height / 3) / img.naturalHeight);
        let img_width = img.naturalWidth * ratio;
        let img_height = img.naturalHeight * ratio;

        switch (settings.pic_position) {
          case 0: //Top Left
            ctx.drawImage(img, 0, 0, img_width, img_height);
            break;
          case 1: //Top Right
            ctx.drawImage(img, width - img_width, 0, img_width, img_height);
            break;
          case 2: //Bottom Left
            ctx.drawImage(img, 0, height - img_height, img_width, img_height);
            break;
          case 3: //Bottom Right
            ctx.drawImage(img, width - img_width, height - img_height, img_width, img_height);
            break;
          case 4: //Center
            ctx.drawImage(img, (width - img_width) / 2, (height - img_height) / 2, img_width, img_height);
            break;
        }
      };

      this._fileFactoryService.getFileByAttachmentId(
        settings.pic, 
        (dataUrl, fileName) => {
          image.src = dataUrl;
          if (image.naturalWidth) {
            drawWaterMarkImg(image);
          } else {
            image.onload = function () {
                drawWaterMarkImg(image);
            };
          }
        },
        (err) => {

        },
        false
      );

    }
  }

}
