import { Component, OnInit, Input } from '@angular/core';

import { Message } from '../../../../../models/message';
import { MessageJoinLeaveChatBody } from '../../../../../models/message-join-leave-chat-body';
import { MessageChangeAdminBody } from '../../../../../models/message-change-admin-body';

import * as _ from 'lodash';
import { MessageTypeConstant } from '../../../../../constants/message-type.constant';

@Component({
  selector: 'tn-info-message',
  templateUrl: './info-message.component.html',
  styleUrls: ['./info-message.component.scss']
})
export class InfoMessageComponent implements OnInit {
  @Input() message: Message;

  displayMessage: string;
  displayNames: string;

  MESSAGE_TYPE = MessageTypeConstant

  constructor(
  ) { }

  ngOnInit() {
  }
  ngOnChanges() {
    // this.parseInfoMsg();
  }

  parseInfoMsg(): void {
    let m = this.message;
    if (m.date) {
      this.displayMessage = m.date;
    } else if (m.isNewMsgIndicator) {
      // TODO: translate
      this.displayMessage = this.getNewMsgIndicator(m);
    } else if (m.isRecalled) {
      // TODO: translate
      this.displayMessage = this.getRecalledMsg(m);
    } else {
      switch (_.toInteger(m.type)) {
        case this.MESSAGE_TYPE.JOIN_CHAT:
          this.displayMessage = this.getJoinChatMsg(m.parsedBody);
          break;
        case this.MESSAGE_TYPE.LEAVE_CHAT:
          this.displayMessage = this.getLeaveChatMsg(m.parsedBody);
          break;
        case this.MESSAGE_TYPE.CHANGE_CHAT_ADMIN:
          this.displayMessage = this.getAdminChangeMsg(m.parsedBody);
          break;
      }
    }
  }
  
  getNewMsgIndicator(body: Message): string {
    return 'new message below';
  }
  getRecalledMsg(body: Message): string {
    return '';
  }
  getJoinChatMsg(body: MessageJoinLeaveChatBody): string {
    return body.user_names.join(', ') + 'JOINED!!!';
  }
  getLeaveChatMsg(body: MessageJoinLeaveChatBody): string {
    return body.user_names.join(', ') + 'LEAVE!!!';
  }
  getAdminChangeMsg(body: MessageChangeAdminBody): string {
    let msg = '';
    if (body.inserted) {
      msg = body.inserted.join(', ') + 'IS NOW ADMIN!!';
    } else if (body.removed) {
      msg = body.removed.join(', ') + 'IS NO LONGER ADMIN!!';
    }
    return msg;
  }

}
