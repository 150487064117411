export const EnWebdavStrings = {
    USERNAME: 'DPID',
    PASSWORD: 'Password',
    USERNAME_TIP: 'Input your userid',
    PASSWORD_TIP: 'Input your password',
    SETPIN: 'Pin',
    UNSETPIN :  'Unpin',
    SIGN_IN : 'Sign in',
    BACK : 'Back',
};

