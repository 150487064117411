<div class="broadcast-set-wrapper">
  <div class="group-info-wrapper">
    <div class="basic-info-wrapper">
      <div class="header text-color-primary">
        <ng-container *ngIf="!isEditing">
          {{'WEBCLIENT.BROADCAST.MANAGE.ADD_BROADCAST_LIST' | translate}}
        </ng-container>
        <ng-container *ngIf="isEditing">
          {{'WEBCLIENT.BROADCAST.MANAGE.EDIT_BROADCAST_LIST' | translate}}
        </ng-container>
      </div>
      <div class="name-input">
        <div class="input-label">
          {{'WEBCLIENT.BROADCAST.NAME' | translate}}
        </div>
        <div class="input-body">
          <input 
            type="text" 
            class="form-control" 
            [placeholder]="'WEBCLIENT.BROADCAST.NAME' | translate"
            [(ngModel)]="broadcastGroup.name">
        </div>
      </div>
    </div>

    <div class="recipient-info-wrapper">
      <tn-broadcast-recipient-selector
        class="recipient-selector"
        [(selectedContacts)]="selectedContacts"
        [defaultIds]="defaultSelectedContactsUserId">
      </tn-broadcast-recipient-selector>
    </div>
  </div>

  <div class="set-actions-wrapper">
    <button class="btn btn-default" (click)="back()">
      {{'GENERAL.BACK' | translate}}
    </button>
    <button class="btn tn-button-primary" (click)="save()">
      {{'GENERAL.SAVE' | translate}}
    </button>
  </div>
</div>