import { Injectable } from '@angular/core';
import { Chat } from '../../models/chat';
import { ChatService } from '../services/data/chat/chat.service';

import * as _ from 'lodash';

@Injectable()
export class NewsCategoryService {

  constructor(
    private _chatService: ChatService
  ) { }

  parseNewsCategoryWithPrefix(category: Chat, prefix: string): Chat {
    category.displayName = category.name.substr(prefix.length).trim();
    return category;
  }

  getAllNewsCategories(categoryPrefix?: string): Chat[] {
    let cats = this._chatService.getNewsCategories();
    if (!categoryPrefix) {
      return cats;
    } else {
      cats = _.filter(cats, (c) => {
        return c.name.indexOf(categoryPrefix) == 0;
      });
      _.each(cats, (c) => {
        c = this.parseNewsCategoryWithPrefix(c, categoryPrefix);
      });
      return cats;
    }
  }

}
