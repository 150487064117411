<form *ngIf="!isExtAuth">
  <section class="form-block">
    <div class="login-body-row input-action-msg">
      {{'LOGIN.PASSWORD.INPUT_TIP' | translate}}
    </div>
    <div class="login-body-row">
        <input #passwordInput class="form-control" type="password" [(ngModel)]="password" name="password" placeholder="{{'LOGIN.PASSWORD.PASSWORD' | translate}}" autocomplete="off">
        <div id="forgetPassword_div" *ngIf="showForgetPassword">
          <a id="forgetPassword" (click)="forgetPassword()">{{'LOGIN.PASSWORD.FORGET_PASSWORD' | translate}}</a>
        </div>
      </div>   
    <div class="login-body-row">
      <button class="btn tn-button-primary btn-block" (click)="submit()">{{'LOGIN.PASSWORD.SIGN_IN' | translate}}</button>
    </div>
    <div class="login-body-row">
      <button class="btn tn-button-primary btn-block" (click)="back()">{{'GENERAL.BACK' | translate}}</button>
    </div>
  </section>
</form>
