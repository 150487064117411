export const EnContactStrings = {
  NAME: "Name",
  EMAIL: "Email",
  DEPARTMENT: "Department",
  TITLE: "Title",
  PUBLIC_MESSAGE: "Public Message",
  MOBILE: "Mobile",
  E_PROFILE: "E-Profile",

  DELETED_POSTFIX: "(Deleted)"
};
