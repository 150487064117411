<div class="job-dispatch-info-wrapper">

  <ng-container *ngIf="isViewingStoreReport">
    <div class="job-info-field-container">
      <div class="title">
        {{'WEBCLIENT.JOB_REPORT.REPORT_DETAIL.WORKER' | translate}}
      </div>
      <div class="content">
        {{workerName}}
      </div>
    </div>

    <div class="job-info-field-container">
      <div class="title">
        {{'WEBCLIENT.JOB_REPORT.REPORT_DETAIL.STATUS' | translate}}
      </div>
      <div class="content">
        <ng-container *ngIf="job">
          {{'WEBCLIENT.JOB_REPORT.STATUS.' + job.reportStatusStr | translate}}
        </ng-container>
        <ng-container *ngIf="!job">
          {{defaultDisplay}}
        </ng-container>
      </div>
    </div>

    <div class="job-info-field-container">
      <div class="title">
        {{'WEBCLIENT.JOB_REPORT.REPORT_DETAIL.START_TIME' | translate}}
      </div>
      <div class="content">
        {{startTimeDisplay}}
      </div>
    </div>

    <div class="job-info-field-container">
      <div class="title">
        {{'WEBCLIENT.JOB_REPORT.REPORT_DETAIL.END_TIME' | translate}}
      </div>
      <div class="content">
        {{endTimeDisplay}}
      </div>
    </div>
  </ng-container>

  <div class="job-response-wrapper" *ngIf="job && job.custom_fields.length > 0">
    <div class="title">
      {{'WEBCLIENT.JOB_REPORT.REPORT_DETAIL.JOB_RESPONSE' | translate}}
    </div>

    <tn-custom-field 
      [field]="field"
      [report]="report" 
      *ngFor="let field of job.mappedCustomFields">
    </tn-custom-field>
    
  </div>

</div>