<div class="news-modal" *ngIf="data.newsMessage || data.newsHttp" (click)="sendBatchCommentRead()">

  <div class="news-content-wrapper">
      <tn-news-cell *ngIf = "data.newsHttp || data.newsMessage"
      [message]="data.newsMessage"
      [newsHttp]="data.newsHttp"
      [isEnableEmoji]="isEnableEmoji"
      [isEnableComment]="isEnableComment"
      [isInModal]="isInModal"
      [isEnableNewsHttp]="isEnableNewsHttp"
      (onMediaClick)="onMediaClick($event)">
    </tn-news-cell>
    <!-- <tn-news-cell *ngIf = "data.newsMessage"
      [message]="data.newsMessage"
      [isEnableEmoji]="isEnableEmoji"
      [isEnableComment]="isEnableComment"
      [isInModal]="isInModal"
      (onMediaClick)="onMediaClick($event)">
    </tn-news-cell> -->

    <div class="comment-section-wrapper" *ngIf="isEnableComment">
      <div class="comment-first-level" 
        *ngFor="let fc of data.parsedMessageWithComment.originalMessage.comments">
        <tn-news-comment
          [comment]="fc"
          [isThreadOpen]="data.parsedMessageWithComment.isThreadOpen"
          [isFirstLevel]="true"
          [isAdmin]="isAdmin"
          (onRemoveComment)="onRemoveComment($event)"
          (onReplyToggle)="onReplyToggle($event)">
        </tn-news-comment>

        <div class="reply-count-message text-color-primary" 
          *ngIf="!checkIfCommentExpanded(fc) && fc.comments && fc.comments.length > 2"
          (click)="displayAllChildComments(fc)">
          {{'WEBCLIENT.NEWS.NEWS.REPLY_COUNT.MULTIPLE' | translate: {'NUM': fc.comments.length} }}
        </div>

        <div class="comment-second-level" *ngFor="let sc of fc.comments; let i = index">
          <tn-news-comment 
            *ngIf="checkIfCommentExpanded(fc) || (!checkIfCommentExpanded(fc) && i >= fc.comments.length - 2)"
            [comment]="sc"
            [isThreadOpen]="data.parsedMessageWithComment.isThreadOpen"
            [isFirstLevel]="false"
            [isAdmin]="isAdmin"
            (onRemoveComment)="onRemoveComment($event)">
          </tn-news-comment>
        </div>

        <div class="reply-comment-input" *ngIf="fc.isReplying">
          <tn-comment-input 
            [messageId]="fc.message_id" 
            [parentNewsMessageId]="data.newsMessage.message_id">
          </tn-comment-input>
        </div>

      </div>

    </div> <!-- comment-section-wrapper -->
  </div> <!-- news-content-wrapper -->

  <div class="comment-input-wrapper" *ngIf="!data.isEnableNewsHttp && data.parsedMessageWithComment.isThreadOpen && isEnableComment">
    <tn-comment-input 
      [messageId]="data.newsMessage.message_id" 
      [parentNewsMessageId]="data.newsMessage.message_id">
    </tn-comment-input>
  </div>

</div>