import { ZhChtMenuStrings } from './menu/zh_cht-menu.strings';
import { ZhChtChatStrings } from '../chat/strings/zh_cht-chat.strings';
import { ZhChtNewsStrings } from '../news/strings/zh_cht-news.strings';
import { ZhChtContactStrings } from '../contact/strings/zh_cht-contact.strings';
import { ZhChtQuestionnaireStrings } from '../questionnaire/strings/zh_cht-questionnaire.strings';
import { ZhChtJobReportStrings } from '../job-report/strings/zh_cht-job-report.strings';
import { ZhChtBroadcastStrings } from '../broadcast/strings/zh_cht-broadcast.string';
import { ZhChtChatroomStrings } from '../chat/chat-room/strings/zh_cht-chatroom.stirngs';
import { ZhChtWorkflowStrings } from '../workflow/strings/zh_cht-workflow.strings';
import { ZhChtVideoStrings } from '../video-streaming/strings/zh_cht-video.strings';
import { ZhChtWebdavStrings } from '../webdav-material/strings/zh_cht-webdav.strings';
import { ZhChtStarredMessagesStrings } from '../starred-messages/strings/zh_cht-starred-messages.strings';

export const ZhChtWebclientString = {
  MENU: ZhChtMenuStrings,

  CHAT: ZhChtChatStrings,
  CHATROOM: ZhChtChatroomStrings,
  NEWS: ZhChtNewsStrings,
  CONTACT: ZhChtContactStrings,
  QUESTIONNAIRE: ZhChtQuestionnaireStrings,
  JOB_REPORT: ZhChtJobReportStrings,
  BROADCAST: ZhChtBroadcastStrings,
  WORKFLOW: ZhChtWorkflowStrings,
  VIDEO: ZhChtVideoStrings,
  WEBDAV: ZhChtWebdavStrings,
  MESSAGE_STAR: ZhChtStarredMessagesStrings
};
