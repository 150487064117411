import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector:'tn-news-carousel',
    templateUrl: './news-carousel.component.html',
    styleUrls: ['./news-carousel.component.scss']
})
export class NewsCarouselComponent implements OnInit {
    @Input() isCarousel: boolean;
    @Input() isEnableEmoji: boolean;
    @Input() isEnableComment: boolean;
    @Input() isInModal: boolean;
    @Input() isEnableNewsHttp: boolean;
    @Input() newsHttp: any[];
    @Input() newsCategory: any;
    indicators = true;
    autoSlide = true;
    selectedIndex: number = 0;
    currentNews: any;
    title: string;
    slideInterval: number = 5000;
    t_autoSlide: any;
    constructor(){

    }
    ngOnInit() {
        this.selectedIndex = 0;
        this.title = this.newsCategory.name;
        this.currentNews = this.newsHttp[this.selectedIndex];
        if (this.autoSlide){
            this.autoSlideNews();
        }
    }
    ngOnChanges() {
        this.title = this.newsCategory.name;
    }
    selectNews(index: number){
        this.selectedIndex = index;
        this.currentNews = this.newsHttp[this.selectedIndex];
        clearInterval(this.t_autoSlide);
        if (this.autoSlide){
          this.autoSlideNews();  
        }
    }
    onNextClick(): void {
        if (this.selectedIndex === this.newsHttp.length - 1){
            this.selectedIndex = 0;
        } else {
            this.selectedIndex++;
        }
        this.currentNews = this.newsHttp[this.selectedIndex];
    }
    autoSlideNews(){
        this.t_autoSlide = setInterval(() => {
            this.onNextClick();
        }, this.slideInterval);
    }
}