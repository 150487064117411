import { Component, OnInit, Input } from '@angular/core';
import { Message } from '../../../../../models/message';
import { ChatMessageService } from '../../../../services/data/messages/chat-message/chat-message.service';

import * as _ from 'lodash';
import { LocationService } from '../../../../../utilities/location/location.service';
import { MessageTypeConstant } from '../../../../../constants/message-type.constant';
import { AttachmentTypeConstant } from '../../../../../constants/attachment-type.constant';
import { FileManagerService } from '../../../../../utilities/file-manager/file-manager.service';

@Component({
  selector: 'tn-message-parent',
  templateUrl: './message-parent.component.html',
  styleUrls: ['./message-parent.component.scss']
})
export class MessageParentComponent implements OnInit {

  @Input() message: Message;
  @Input() isSentByMe: boolean;
  MSG_TYPE = MessageTypeConstant

  attachmentType: number;
  ATTACHMENT_TYPE = AttachmentTypeConstant;

  imgSrc: string;

  constructor(
    private _chatMessageService: ChatMessageService,
    private _fileManagerService: FileManagerService,
    private _locationService: LocationService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.parseReplyMessage();
  }

  parseReplyMessage() {
    this.message = this._chatMessageService.getSingleParsedMsg(this.message);
    switch (_.toInteger(this.message.type)) {
      case this.MSG_TYPE.TEXT:
        break;
      case this.MSG_TYPE.ATTACHMENT:
        let attachment = this.message.attachments[0];
        this.attachmentType = this._fileManagerService.getAttachmentType(attachment.attachment_id);

        switch (this.attachmentType) {
          case this.ATTACHMENT_TYPE.IMAGE:
          case this.ATTACHMENT_TYPE.VIDEO:
            this.imgSrc = this._fileManagerService.getBackgroundImgSrcByBase64(attachment.preview_image, attachment.content_type);
            break;
          case this.ATTACHMENT_TYPE.PDF:
            
            break;
        }

        break;
      case this.MSG_TYPE.LOCATION:
        this.imgSrc = this._locationService.getLocationPreviewSrc(this.message.parsedBody.latitude, this.message.parsedBody.longitude);
        break;
      case this.MSG_TYPE.STICKER:
        this.imgSrc = this._fileManagerService.getBackgroundImgSrcByAttachmentId(this.message.parsedBody.attachment_id);
        break;
    }
  }
}
