import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { MessageLocationBody } from '../../../models/message-location-body';
import { NgMapConfigLoaderService } from '../../ng-map-config-loader/ng-map-config-loader.service';
import { TeamnoteConfigService } from '../../../configs/teamnote-config.service';
import { GoogleMap } from '@angular/google-maps';

@Component({
  selector: 'tn-attachment-location',
  templateUrl: './attachment-location.component.html',
  styleUrls: ['./attachment-location.component.scss']
})
export class AttachmentLocationComponent implements OnInit {
  @Input() location: MessageLocationBody;

  lat: number;
  lng: number;
  zoom: number;
  address: string;


  mapOptions: google.maps.MapOptions = {
    disableDefaultUI: true,
    mapTypeControl: true,
    backgroundColor: '#ffffff',
    clickableIcons: true,
    disableDoubleClickZoom: true,
    draggable: true,
    zoomControl: true,
    fullscreenControl: false,
    streetViewControl: true
  };

  markerOptions: google.maps.MarkerOptions = {
    draggable: false
  };

  @ViewChild(GoogleMap, { static: false }) map: GoogleMap

  constructor(
    private _ngMapConfigLoaderService: NgMapConfigLoaderService,
    private _teamnoteConfigService: TeamnoteConfigService
  ) { }

  ngOnInit() {
    // this._ngMapConfigLoaderService.changeUrl(this._teamnoteConfigService.config.MAP.API_KEY);
  }

  ngOnChanges() {
    if (this.location) {
      this.lat = this.location.latitude;
      this.lng = this.location.longitude;
      this.zoom = 15;
      this.address = this.location.address;
    }
  }
  
  onMapClick(event) {
    // console.log('onmapclick', event);
    // event.target.panTo(event.latLng);
    this.map.panTo(event.latLng);
  }

}
