import { Injectable } from '@angular/core'
import { Question } from '../../models/questionnaire'
import { TeamNoteQuestionnaireConstant } from '../../constants/questionnaire.constant'
import { TeamnoteApiService } from '../../../../api/teamnote-api.service'

import * as _ from 'lodash'
import { HttpResponse, HttpErrorResponse } from '@angular/common/http'

@Injectable()
export class QuestionAttachmentService {
  constructor(private _teamnoteApiService: TeamnoteApiService) {}

  getParsedAttachmentQuestion(q: Question): Question {
    q.attachmentDetail = JSON.parse(q.detail)
    q.value = ''
    q.is_mandatory = q.attachmentDetail.is_mandatory === 1

    return q
  }

  getCheckedAttachmentQuestion(q: Question): Question {
    if (q.attachmentDetail.is_mandatory === 1) {
      if (q.value === '' || q.ansAttachment.length === 0) {
        q.hasError = true
      }
    }

    return q
  }

  async getParsedAttachmentAnswer(q: Question): Promise<any> {
    let ansAttachmentIdArr = [] // attachment id list

    // handle empty uploading attachment
    if (!q.ansAttachment) {
      return ansAttachmentIdArr
    }

    let uploadPromiseList = q.ansAttachment.map(attachment => {
      return new Promise((resolve, reject) => {
        this._teamnoteApiService.upload(
          attachment.file.file,
          (resp: HttpResponse<any>) => {
            ansAttachmentIdArr.push(resp)
            resolve(resp)
          },
          (error: HttpErrorResponse) => {
            console.error(error)
            reject(error)
          }
        )
      })
    })

    return Promise.all(uploadPromiseList);
  }
}
