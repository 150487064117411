import { Component, OnInit, Input, Output } from '@angular/core';
import { AttachmentService } from '../../../../app/utilities/attachment/attachment.service';
import { TeamnoteConfigService } from '../../../configs/teamnote-config.service';
import { Child, ReadObject, WDResponse, WebDavRoot } from '../models/webdav-material';
import { WebDavMaterialService } from '../webdav-material.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tn-webdav-material-list',
  templateUrl: './webdav-material-list.component.html',
  styleUrls: ['./webdav-material-list.component.scss']
})
export class WebDavMaterialListComponent implements OnInit {

  @Input() isDialog = false;
  name = '';
  folders: WDResponse[];
  pinFolders: WDResponse[];
  pinpaths: string[] = [];
  unreadPaths: Child[] = [];
  // @Input() allPinPaths: string[] = [];

  // @Input() readPaths: string[];
  currentFolder: WDResponse;
  currentPath: string;
  currentPinPath: string;
  rootPath = '/webdav/';
  pinRootPath = '/webdav-pin/';
  isRootFolder = true;
  isGridView = false;
  isAllowChangeViewMode = false;
  isAll = true;
  constructor(
    private _attachmentService: AttachmentService,
    private _webDavMaterialService: WebDavMaterialService,
    private _teamnoteConfigService: TeamnoteConfigService
  ) { }

  ngOnInit() {
    this.isGridView = this._teamnoteConfigService.config.WEBCLIENT.CORPORATE_MATERIAL.IS_GRID_VIEW;
    this.isAllowChangeViewMode = this._teamnoteConfigService.config.WEBCLIENT.CORPORATE_MATERIAL.IS_ALLOW_CHANGE_VIEW_MODE;

  }
  getUnreadList() {
    const path = this.decodeURL2(this.currentPinPath);
    // const path = this.currentPinPath.replace('%20', ' ');
    // console.error(path)
    this._webDavMaterialService.getReadPaths(path,
      (data) => { this.unreadPaths = data.children; },
      (error) => { console.error(error); });
  }
  onInit() {
    this.isAll = true;
    this.currentPinPath = this.pinRootPath;
    this.getWebDavMaterialList(this.rootPath);
    // this.getUnreadList();
  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnChanges() {
    if (this.isDialog) {
      this.onInit();
    }
  }

  showAllClick() {
    this.isAll = true;
    this.currentPath = this.rootPath;
    this.getWebDavMaterialList(this.currentPath);
  }
  showFavouriteClick() {
    this.isAll = false;
    this.currentPinPath = this.pinRootPath;
    this.getWebDavMaterialList(this.currentPinPath);
  }
  serchRead(folder: WDResponse): boolean {
    const found = this.unreadPaths.find(element => element.href === folder.href);
    return found.unread;
  }
  onUnsetPin(folder: WDResponse) {
    const path = folder.href.replace(' ', '%20');
    this._webDavMaterialService.unsetPin(this.decodeURL(path),
      (data) => { this.updatePinFile(); },
      (err) => { console.log(err); }
    );
  }
  onSetPin(folder: WDResponse) {
    this._webDavMaterialService.setPin(this.decodeURL(folder.href),
      (data) => { this.updatePinFile(); },
      (err) => { console.log(err); }
    );
  }
  decodeURL2(y: string) {
    const split = y.split('/');
    // tslint:disable-next-line:forin
    for (const index in split) {
      split[index] = split[index].replace('%20', ' ');
    }
    return split.join('/');
  }
  decodeURL(y: string) {
    const split = y.split('/');
    // tslint:disable-next-line:forin
    for (const index in split) {
      split[index] = decodeURIComponent(split[index]);
    }
    return split.join('/');
  }
  updatePinFile() {
    this._webDavMaterialService.getPinPaths(
      this.currentPath,
      (data: ReadObject) => {
        this.pinpaths = data.children.map(y => y.href);
        // console.error(this.pinpaths);
        this.getAllPinFiles();
        // console.error(this.unreadPaths);
      }, () => { });
  }
  getFileExtension1(filename: string) {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
  }
  getAllPinFiles() {
    this.pinFolders = [];
    if (this.pinpaths) {
      this.pinpaths.forEach(y => {
        const c = y.split('/');
        let filename = '';
        let tpye = null;
        if (c[c.length - 1] !== '') {
          filename = c[c.length - 1];
        } else if (c[c.length - 2] !== '') {
          filename = c[c.length - 2];
          tpye = this.getFileExtension1(filename);
        }
        const temp = {
          href: y,
          propstat: {
            prop: {
              displayname: filename,
              supportedlock: null,
              resourcetype: tpye,
              getcontenttype: tpye,
              getetag: null,
              getcontentlength: null,
              getlastmodified: null
            },
            status: 'HTTP/1.1 200 OK'
          }
        };

        this.pinFolders.push(temp);
      });
    }
  }
  encodeURL(y: string) {
    const split = y.split('/');
    // tslint:disable-next-line:forin
    for (const index in split) {
      split[index] = encodeURIComponent(split[index]);
    }
    return split.join('/');
  }
  checkUnread(folder: WDResponse) {
    if (this.unreadPaths) {
      let path = this.decodeURL(folder.href);
      if (!folder.propstat.prop.getcontenttype && path.endsWith('/')) {
        path = path.substring(0, path.length - 1);
      }
      const found = this.unreadPaths.find(element => element.href === path) as Child;
      if (found) {
        if (found.unread) {
          const path2 = folder.href.replace('%20', ' ' );
          this._webDavMaterialService.readPin(path2, () => this.getUnreadList(), () => { });
        }
      } else {
        const path2 = folder.href.replace('%20', ' ' );
        this._webDavMaterialService.readPin(path2, () => this.getUnreadList(), () => { });
      }
    }
  }
  folderClick(folder: WDResponse, event: MouseEvent) {
    const e = event.target as HTMLDivElement;
    if (folder.propstat.prop.getcontenttype) {
      if (e.className === 'name' || e.className === 'corporate-material-item clickable ') {
        const type = this.getFileExtension1(folder.propstat.prop.displayname);
        this.onMaterialFileClick(folder, type);
      }
      if (!this.isAll) {
        this.checkUnread(folder);
      }
    } else {
      if (e.className === 'name' || e.className === 'corporate-material-item clickable ') {
        if (!this.isAll) {
          this.checkUnread(folder);
          this.currentPinPath = folder.href;
          this.getWebDavMaterialList(this.currentPinPath);
        } else {
          this.currentPath = folder.href;
          this.getWebDavMaterialList(this.currentPath);
        }
      }
    }
    this.checkRoot();
  }
  checkRoot() {
    if (this.isAll) {
      if (this.rootPath === this.currentPath) {
        this.isRootFolder = true;
      } else {
        this.isRootFolder = false;
      }
    } else {
      if (this.pinRootPath === this.currentPinPath) {
        this.isRootFolder = true;
      } else {
        this.isRootFolder = false;
      }
    }
  }
  onMaterialFileClick(item: WDResponse, tpye: string) {
    this._webDavMaterialService.getWebDavMaterialFile(item.href,
      (data: Blob) => {
        const url = window.URL.createObjectURL(data);
        this._attachmentService.prepareAttachmentModalContentByFileId2(
          [url], null, null, false, item.propstat.prop.displayname, true, false);
      },
      () => { });
  }
  getWebDavMaterialList(href: string): void {
    // console.error(href);
    let tempPath = '';
    if (this.isAll) {
      this.currentPath = href;
      tempPath = href;
    } else {
      if (!href.endsWith('/')) { href += '/'; }
      this.currentPinPath = href;
      tempPath = href;
    }
    this._webDavMaterialService.getWebDavMaterialList(tempPath,
      (data) => {
        const t = href.split('/');
        this.name = t[t.length - 2].replace('%20', ' ');
        const customParam = data as WebDavRoot;
        if (customParam.multistatus.response != null) {
          if (customParam.multistatus.response.length > 0) {
            this.currentFolder = customParam.multistatus.response[0] as WDResponse;
            const temp = customParam.multistatus.response.filter(
              (element) => !element.propstat.prop.displayname.startsWith('.') &&
                (element.href !== this.currentPath && element.href !== this.currentPinPath));
            // console.error(temp);
            // tslint:disable-next-line:max-line-length
            if (this.isAll) {
              this.folders = temp.sort((a, b) => (a.href > b.href) ? 1 : ((b.href > a.href) ? -1 : 0));
            } else {
              this.pinFolders = temp.sort((a, b) => (a.href > b.href) ? 1 : ((b.href > a.href) ? -1 : 0));
              this.getUnreadList();
            }

          } else {
            if (this.isAll) {
              this.folders = [];
            } else {
              this.pinFolders = [];
            }
          }
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }
  onFolderBack(item: WDResponse): void {
    if (!item.propstat.prop.getcontenttype) {
      const temp = item.href.split('/');
      let newHref = '';
      if (temp.length > 3) {
        const n = temp.length - 2;
        temp.splice(n, 2);
        newHref = temp.join('/') + '/';
      } else {
        newHref = this.rootPath;
      }
      if (this.isAll) {
        this.currentPath = newHref;
        this.getWebDavMaterialList(this.currentPath);
      } else {
        this.currentPinPath = newHref;
        this.getWebDavMaterialList(this.currentPinPath);
      }
    } else {
      if (this.isAll) {
        this.currentPath = this.rootPath;
        this.getWebDavMaterialList(this.currentPath);
      } else {
        this.currentPinPath = this.pinRootPath;
        this.getWebDavMaterialList(this.currentPinPath);
      }
    }
    this.checkRoot();
  }
  toggleViewMode(): void {
    this.isGridView = !this.isGridView;
  }
  compare(a, b) {
    if (a.last_nom < b.last_nom) {
      return -1;
    }
    if (a.last_nom > b.last_nom) {
      return 1;
    }
    return 0;
  }
}

