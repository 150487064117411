import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsCategoryComponent } from './news-category.component';
import { NewsCategoryService } from './news-category.service';
import { UtilitiesModule } from '../../utilities/utilities.module';
import { NewsCategoryCellComponent } from './news-category-cell/news-category-cell.component';

@NgModule({
  imports: [
    CommonModule,
    UtilitiesModule
  ],
  declarations: [
    NewsCategoryComponent,
    NewsCategoryCellComponent
  ],
  providers: [
    NewsCategoryService
  ]
})
export class NewsCategoryModule { }
