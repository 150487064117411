import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';

import { Attachment } from '../../models/attachment';
import { MessageLocationBody } from '../../models/message-location-body';


import { FileManagerService } from '../file-manager/file-manager.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AttachmentTypeConstant } from '../../constants/attachment-type.constant';

@Component({
  selector: 'tn-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent implements OnInit {
  // test: string;
  ATTACHMENT_TYPE = AttachmentTypeConstant;

  isAttachment: boolean;
  attachments: Attachment[];
  type: number;
  location: MessageLocationBody;

  constructor(
    private _fileManagerService: FileManagerService,
    public dialogRef: MatDialogRef<AttachmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
