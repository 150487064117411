<div class="broadcast-list-wrapper">
  <div class="list-actions-wrapper">
    <tn-search-bar
      class="tn-search-bar"
      (onKeywordChanged)="searchGroup($event)">
    </tn-search-bar>

    <button class="btn tn-button-primary action" (click)="addBroadcastGroup()">
      <i class="fa fa-fw fa-plus"></i> {{'WEBCLIENT.BROADCAST.MANAGE.ADD_BROADCAST_LIST' | translate}}
    </button>
  </div>
  <div class="groups-wrapper">
    <tn-contact-cell
      [isClickable]="false"
      [contact]="group"
      *ngFor="let group of filteredGroups">

      <div class="additional-right broadcast-group-actions">
        <button class="btn btn-default" (click)="editBroadcastGroup(group)">
          <i class="fa fa-fw fa-edit"></i>
        </button>
        <button class="btn btn-danger" (click)="deleteBroadcastGroup(group)">
          <i class="fa fa-fw fa-trash"></i>
        </button>
      </div>

    </tn-contact-cell>
  </div>
</div>