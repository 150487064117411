import { Component, OnInit } from '@angular/core';
import { QuestionnaireService } from './questionnaire.service';
import { Questionnaire, QuestionnaireCustomRouteParam } from './models/questionnaire';
import { RouteParamService } from '../../utilities/route-param/route-param.service';

import * as _ from 'lodash';

@Component({
  selector: 'tn-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {

  paramSub: any;
  customParam: QuestionnaireCustomRouteParam = new QuestionnaireCustomRouteParam("");

  questionnaires: Questionnaire[] = [];

  activeQuestionnaire: Questionnaire = null;

  constructor(
    private _questionnaireService: QuestionnaireService,
    private _routeParamService: RouteParamService
  ) { 
    this.paramSub = this._routeParamService.currentRouteParam$.subscribe(
      param => {
        this.customParam = param;
        this.getQuestionnaireList();
      }
    )
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.paramSub.unsubscribe();
  }

  getQuestionnaireList() {
    this._questionnaireService.getQuestionnaireList(
      (data) => {
        this.questionnaires = this._questionnaireService.filterQuestionnaire(data);
        if (this.customParam) {
          let target = _.find(this.questionnaires, {questionnaire_id: this.customParam.questionnaireId});
          if (target) {
            this.onItemClick(target);
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onItemClick(q: Questionnaire) {
    this.activeQuestionnaire = q;
    this.customParam = null;
  }

  resetView() {
    this.activeQuestionnaire = null;
    this.getQuestionnaireList();
  }

}
