<div class="out-of-office-modal">
  <div class="toggling">
    <div class="text">
      <div class="title">
        {{'WEBCLIENT.MENU.SETTINGS.OUT_OF_OFFICE.TITLE' | translate}}
      </div>
      <div class="time" *ngIf="outOfOfficeExpiredTimestamp != 0">
        {{outOfOfficeExpiredTimestamp | tnDate: 'OUT_OF_OFFICE_UNTIL_DATE'}}
      </div>
    </div>
    <div class="toggle clickable" (click)="toggle()">
      <i class="fa fa-fw fa-2x fa-toggle-on text-success" *ngIf="isOutOfOffice"></i>
      <i class="fa fa-fw fa-2x fa-toggle-off" *ngIf="!isOutOfOffice"></i>
    </div>
  </div>
  <div class="selector" *ngIf="isSelectingDate">
    <div class="msg">
        {{'WEBCLIENT.MENU.SETTINGS.OUT_OF_OFFICE.NOW_TO' | translate}}
    </div>
    <div class="picker-wrapper">
      <div class="picker-item date">
        <input type="text"
          class="form-control"
          placement="bottom"
          #dp="bsDatepicker"
          bsDatepicker 
          [bsConfig]="dateInputConfig"
          [minDate]="minDate"
          [(ngModel)]="dateObj"
          (bsValueChange)="onDateChange($event)">
      </div>
      <div class="picker-item time">
        <timepicker [(ngModel)]="dateObj" (ngModelChange)="onTimeChange()"></timepicker>
      </div>
    </div>
    <div class="footer">
      <button class="btn btn-default" (click)="cancel()">
        {{'GENERAL.CANCEL' | translate}}
      </button>
      <button class="btn tn-button-primary" [disabled]="!isDateValid" (click)="confirm()">
        {{'GENERAL.CONFIRM' | translate}}
      </button>
    </div>
  </div>
</div>