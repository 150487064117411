<div class="tn-content">
  <div class="tn-content-left"
    [ngClass]="{
      'current-focus-content': isFocusLeftContent,
      'current-unfocus-content': !isFocusLeftContent
    }">
    <div class="workflow-form-list-container">
      <tn-page-title [title]="pageTitle ? pageTitle : pageTitleTranslateKey | translate" *ngIf="!hidePageTitle">
        <div *ngIf="!hidePageSideMenu" class="right-control">
          <div class="btn-group tn-dropdown-group" dropdown placement="bottom-right">
            <button dropdownToggle type="button" class="dropdown-toggle tn-dropdown-button">
              <i class="fa fa-angle-down fa-fw"></i>
            </button>
            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
              <li role="menuitem">
                <div class="tn-dropdown-item" 
                  (click)="createForm()">
                  <i class="fa fa-plus fa-fw"></i> {{'WEBCLIENT.WORKFLOW.NEW_FORM.TITLE' | translate}}
                </div>
              </li>
              <li role="menuitem">
                <div class="tn-dropdown-item" 
                  (click)="filterForm()">
                  <i class="fa fa-fw fa-filter"></i> {{'WEBCLIENT.WORKFLOW.FILTER.TITLE' | translate}}
                </div>
              </li>
              <li role="menuitem" *ngIf="!hideExportFormBtn">
                <div class="tn-dropdown-item" 
                  (click)="exportForm()">
                  <i class="fa fa-fw fa-download"></i> {{'WEBCLIENT.WORKFLOW.EXPORT.TITLE' | translate}}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </tn-page-title>

      <div class="form-list-container">
        <tn-form-list 
          [forms]="forms"
          [viewingForm]="viewingForm"
          (onFormClick)="onFormClick($event)">
        </tn-form-list>
      </div>
    </div>
  </div>

  <div class="tn-content-right"
    [ngClass]="{
      'current-focus-content': !isFocusLeftContent,
      'current-unfocus-content': isFocusLeftContent
    }">
    <tn-form-view 
      [form]="viewingForm"
      (onBack)="onFormViewBack($event)"
      *ngIf="currentMainView == MAIN_VIEW_CODE.SET">
    </tn-form-view>

    <tn-form-submit
      [customParam]="customParam"
      (onBack)="onFormSubmitBack($event)"
      *ngIf="currentMainView == MAIN_VIEW_CODE.CREATE">
    </tn-form-submit>

    <tn-form-filter
      [customParam]="customParam"
      (onBack)="onFormFilterBack($event)"
      (onFilterApply)="onFilterApply($event)"
      *ngIf="currentMainView == MAIN_VIEW_CODE.FILTER">
    </tn-form-filter>

  </div>
</div>
