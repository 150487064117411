import { Injectable } from '@angular/core';
import { TeamNoteNotification, NotificationType } from '../models/notification';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { CronjobTracker, CronJob, CronJobStatus } from '../../cronjob-tracker/models/cronjob';

@Injectable()
export class NotificationCenterService {

  notifications: TeamNoteNotification[] = [];
  notifications$: BehaviorSubject<TeamNoteNotification[]> = new BehaviorSubject<TeamNoteNotification[]>([]);

  centerOpenState: boolean = true;
  centerOpenState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() { }

  openNotificationCenter(): void {
    this.centerOpenState = true;
  }

  closeNotificationCenter(): void {
    this.centerOpenState = false;
    this.centerOpenState$.next(false);
  }

  sortNotifications(): void {
    this.notifications = _.orderBy(this.notifications, ['updateTime'], ['desc']);
  }

  clearAllNotifications(): void {
    this.notifications = [];
    this.updateNotificationSubject();
  }

  updateNotificationSubject(): void {
    this.sortNotifications();
    this.notifications$.next(this.notifications);
  }

  addChatMessageNotification(): void {

  }

  addNewsNotification(): void {

  }

  addCronjobNotification(): void {

  }

  updateCronjobNotification(jobTracker: CronjobTracker, onClick: Function): void {
    let original = _.find(this.notifications, {id: jobTracker.jobId});
    if (!original) {
      this.notifications.push(
        new TeamNoteNotification(
          jobTracker.jobId, 
          NotificationType.CRONJOB,
          jobTracker,
          onClick,
          jobTracker.job.update_date
        )
      );
    } else {
      original.body = jobTracker;
      original.updateTime = jobTracker.job.update_date;
    }
    this.updateNotificationSubject();
  }


}
