import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { WebclientLoginService } from '../webclient-login.service';
import { InputValidationService } from '../../utilities/input-validation/input-validation.service';

import { LoginComponent } from '../login.component';

import {TeamNoteApiConstant} from '../../constants/api.constant';
import { CustomLoginResponse } from '../models/custom-login-response';
import { LOGIN_ACTIONS } from '../constants/login-actions.constant';
import { TnNotificationService } from '../../utilities/tn-notification/tn-notification.service';
import { OauthService } from '../oauth.service';
import { TeamNoteLocalStorageKeyConstants } from '../../constants/local-storage-key.constant';
import { LocalStorageManagerService } from '../../utilities/local-storage/local-storage-manager.service';
import { TeamnoteApiService } from '../../api/teamnote-api.service';
import { UtilitiesService } from '../../utilities/service/utilities.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tn-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

  // Login Params
  password: string;
  @ViewChild('passwordInput', {static: false}) passwordInput: ElementRef;

  publicKey: string = null;

  errorMsg: string;
  isForgetPassword: boolean;
  isExtAuth = true;
  extAuthType: string = null;
  showForgetPassword: boolean;

  constructor(
    private _webclientLoginService: WebclientLoginService,
    private _inputValidationService: InputValidationService,
    private _loginComponent: LoginComponent,
    private _tnNotificationService: TnNotificationService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _teamnoteApiService: TeamnoteApiService,
    private _utilitiesService: UtilitiesService,
    private _oauthService: OauthService,
  ) {}

  ngOnInit() {
    // tslint:disable-next-line:max-line-length
    const externalAuthType = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.AUTH_TYPE);

    if (externalAuthType) {
      this.isExtAuth = true;
      this.extAuthType = externalAuthType;
      this.webclientLogin();
    } else {
      this.isExtAuth = false;
    }
    if (this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.LOGIN.FORGETPW)=="registration") {
      this.showForgetPassword = true;
    }
    this.getE2EEPublicKey();
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {
    this.focusPasswordInput();
  }

  focusPasswordInput(): void {
    if (this.passwordInput) {
      this.passwordInput.nativeElement.focus();
    }
  }

  getE2EEPublicKey(): void {
    this._teamnoteApiService.getE2EEPublicKey(
      resp => {
        this.publicKey = resp;
      },
      err => {

      }
    );
  }


  
  submit() {
    this.isForgetPassword = false;
    const isValid = this._inputValidationService.checkIsNotEmpty(this.password);
    if (!isValid) {
      this._tnNotificationService.showCustomWarningByTranslateKey('LOGIN.PASSWORD.EMPTY_ERROR');
      this.passwordInput.nativeElement.focus();
      return;
    }
    this.webclientLogin();
  }

  back() {
    this.isForgetPassword = false;
    this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_NAME);
    this.toNextPage(LOGIN_ACTIONS.TO_GET_STATE_PAGE);
  }

  webclientLogin() {
    let encryptedPassword = null;
    if (this.publicKey) {
      encryptedPassword = this._utilitiesService.getEncryptedValueByPublicKey(this.password, this.publicKey);
    }
    let password = this.password || '';
    // if (!this.publicKey) {
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.LOGIN.PASSWORD, password.toString());
    // }
    this._webclientLoginService.webclientLogin(
      resp => {
        const response = this._webclientLoginService.handleWebclientLoginSuccessResponse(
          resp
        );
        this.handleCustomLoginResponse(response);
      },
      err => {
        const response = this._webclientLoginService.handleWebclientLoginErrorResponse(
          err
        );
        this.handleCustomLoginResponse(response);
      },
      password,
      this.extAuthType,
      encryptedPassword,
      this.publicKey,
    );
  }

  webclientRegisterDevice() {
    this._webclientLoginService.webclientRegisterDevice(
      resp => {
        const response = this._webclientLoginService.handleWebclientRegisterDeviceSuccessResponse(
          resp
        );
        this.handleCustomLoginResponse(response);
      },
      err => {
        const response = this._webclientLoginService.handleWebclientRegisterDeviceErrorResponse(
          err
        );
        this.handleCustomLoginResponse(response);
      }
    );
  }

  handleCustomLoginResponse(resp: CustomLoginResponse) {
    // response.nextApiCall = LOGIN_ACTIONS.API_REG_INIT;
    if (resp.nextApiCall === LOGIN_ACTIONS.API_WEBCLIENT_REG_DEVICE) {
      this.webclientRegisterDevice();
      return;
    }
    if (resp.nextApiCall === LOGIN_ACTIONS.API_WEBCLIENT_LOGIN) {
      this.webclientLogin();
      return;
    }
    if(resp.nextApiCall = LOGIN_ACTIONS.API_REG_INIT){
      this._loginComponent.goToTargetPageWithParams(LOGIN_ACTIONS.TO_GET_STATE_PAGE,{isForgetPassword:this.isForgetPassword});
      // this.toNextPage(LOGIN_ACTIONS.TO_GET_STATE_PAGE);
    }
    if (!resp.isSuccess && !resp.nextPageUrl) {
      this._oauthService.clearOAuthToken()

      this._tnNotificationService.showCustomErrorByTranslateKey(resp.errorMsg);
    }
    if (resp.nextPageUrl) {
      this.toNextPage(resp.nextPageUrl);
      return;
    }
    this.focusPasswordInput();
  }

  toNextPage(nextPageUrl: string) {
    // remove the USER_ID cookie before landing to the webclient base page
    this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_ID);
    this._loginComponent.goToTargetPage(nextPageUrl);
  }


  forgetPassword() {

    // check if same user as previous session
    this.isForgetPassword = true;
    let userName = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_NAME);
    this.apiGetState(userName);
  }

  apiGetState(userName: string): void {
    this._webclientLoginService.registrationGetStateWithForgetPassword(
      userName,
      true,
      resp => {
        const response = this._webclientLoginService.handleGetStateSuccessResponse(
          resp
        );
        this.handleCustomLoginResponse(response);
      },
      err => {
        const response = this._webclientLoginService.handleGetStateErrorResponse(
          err
        );
        this.handleCustomLoginResponse(response);
      }
    );
  }
}
