import { Component, OnInit, Inject, ElementRef, Input, Output, EventEmitter } from '@angular/core'
import { Subscription } from 'rxjs'
import { StarredMessagesService } from './starred-messages.service'
import { ChatService } from '../services/data/chat/chat.service'
import { DataManagerService } from '../services/data/data-manager.service'
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Message } from '../../models/message'
import { MessageStar } from '../../models/message-star'
import { Chat } from '../../models/chat';
import { TnNotificationService } from '../../utilities/tn-notification/tn-notification.service'
import { ChatMessageService } from '../services/data/messages/chat-message/chat-message.service'
import { InfoMessageService } from '../services/data/messages/info-message/info-message.service'
import { MessageTypeConstant } from '../../constants/message-type.constant';
import { ImageHelperService } from '../../utilities/image-helper/image-helper.service'
import { WebclientService } from '../webclient.service';

import _ from 'lodash'

interface prevUnstarMsgType {
  content?: string;
  index?: number;
}

@Component({
  selector: 'tn-starred-messages',
  templateUrl: './starred-messages.component.html',
  styleUrls: ['./starred-messages.component.scss']
})
export class StarredMessagesComponent implements OnInit {
  starredMessages: Message[] = []
  originalStarredMessages: any[] = []
  isChatLoaded: boolean = false
  isEnableReadTicks: boolean = false;
  isEnableStarMessage: boolean = false;
  isEnableImportantUsers: boolean = false;
  
  isInChat: boolean = false;
  chat: Chat = null;

  DEFAULT_SIZE: number = 20;
  starredMsgSize: number = this.DEFAULT_SIZE;
  starredMsgPage: number = 0;
  prevUnstarredMsg: prevUnstarMsgType = {}
  isLoadingHistory: boolean = false;
  isAllMsgsLoaded: boolean = false;

  keyword: string

  private isChatLoadedSub: Subscription
  private refreshSub: Subscription

  constructor(
    public dialogRef: MatDialogRef<StarredMessagesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dataManagerService: DataManagerService,
    private _starredMessagesService: StarredMessagesService,
    private _chatService: ChatService,
    private _tnNotificationService: TnNotificationService,
    private _chatMessageService: ChatMessageService,
    private _infoMessageService: InfoMessageService,
    private _imageHelperService: ImageHelperService,
    private _webClientService: WebclientService,
    private el:ElementRef
  ) {
    
  }

  ngOnInit() {
    this._infoMessageService.starredMsgUpdatedSubject(null)
    this.isInChat = this.data.isInChat;
    this.chat = this.data.chat;

    this.isChatLoadedSub = this._dataManagerService.isChatLoaded$.subscribe(isChatLoaded => {
      // console.log('Chat just Loaded')
      if (isChatLoaded) {
        this.isChatLoaded = true
        this.isEnableReadTicks = this._webClientService.checkIfEnableMessageReadTicks();
        this.isEnableStarMessage = this._webClientService.checkIfEnableMessageStar();
        // this.isEnableImportantUsers = this._webClientService.checkIfEnableImportantUsers();

        this.loadStarMessages()
      }
    })

    this.refreshSub = this._infoMessageService.starredMsgsUpdate$.subscribe((removedStarredMsg: MessageStar) => {
      if (removedStarredMsg) {
        // just unstarred a message via websocket, need refresh the starred message list
        this.prevUnstarredMsg = this.getPrevUnstarMsg(removedStarredMsg);
        // console.log('this.prevUnstarredMsg', this.prevUnstarredMsg)
        this.resetStarredMsgsStates()
        this.loadStarMessages(true) // refresh list
      }
    })

  }

  ngOnDestroy() {
    this.hideContextMenu();
    // this._infoMessageService.starredMsgUpdatedSubject(null)
    this.isChatLoadedSub.unsubscribe()
    this.refreshSub.unsubscribe()
    // this._dataManagerService.updateIsChatLoaded(false)
  }

  hideContextMenu(): void {
    this._starredMessagesService.hideContextMenu();
  }

  resetStarredMsgsStates(): void {
    this.isAllMsgsLoaded = false;
    this.isLoadingHistory = false;
  }

  loadStarMessages(isRefresh?: boolean): void {
    if (this.isLoadingHistory || this.isAllMsgsLoaded) {
      return;
    }

    this.isLoadingHistory = true;

    if (isRefresh) {
      this.starredMsgPage = 0;
      this.starredMsgSize = this.starredMsgPage * this.DEFAULT_SIZE || this.DEFAULT_SIZE
    } 

    let page = this.starredMsgPage;
    let size = this.starredMsgSize;

    // this.getStarredMessages(this.starredMsgSize, this.starredMsgPage)
    this.getStarredMessages(size, page)
  }

  parseStarredMessages(messages: Message[]): void {
    let parsedStarMessages = []

    parsedStarMessages = _.map(messages, (m) => {
      let pm = this._chatMessageService.getSingleParsedMsg(m);
      let chat = this._chatService.getChatRoomByChatId(m.chat_ids[0])

      /* set image caption for image type starred message */
      if (pm.type == MessageTypeConstant.ATTACHMENT && pm.parsedBody.message) {
        if (m.attachments[0] && pm.parsedBody.attachment_id) {
          if (!this._imageHelperService.getImageCaptionById(pm.parsedBody.attachment_id)) {
            this._imageHelperService.setImageCaptionById(pm.parsedBody.attachment_id, pm.parsedBody.message);
          }
        }
      }

      return {
        ...pm,
        chat: chat ? chat : null
      }
    })

    if (!_.isEmpty(this.prevUnstarredMsg)) {
      parsedStarMessages = _.without(this.originalStarredMessages, this.prevUnstarredMsg.content)
    } else {
      // parsedStarMessages = _.union(this.originalStarredMessages, parsedStarMessages)
      parsedStarMessages = _.unionBy(this.originalStarredMessages, parsedStarMessages, 'message_id');
    }

    // parsedStarMessages = _.unionBy(this.originalStarredMessages, parsedStarMessages, 'message_id');
    // if (!_.isEmpty(this.prevUnstarredMsg)) {
    //   parsedStarMessages = _.without(parsedStarMessages, this.prevUnstarredMsg.content)
    // }

    this.originalStarredMessages = _.orderBy(parsedStarMessages, 'timestamp', 'desc')
    this.starredMessages = this.originalStarredMessages.slice() // make a copy of starredMessages
  }

  getStarredMessages(size?: number, page?: number): void {
    this._starredMessagesService.getStarredMessages(
      size,
      page,
      this.chat ? this.chat.chat_id : null,
      (starMsgs: Message[]) => {
        // this.parseStarredMessages(starMsgs)

        if (starMsgs.length === 0) {
          // if server return empty array, then all msgs has been loaded
          this.isAllMsgsLoaded = true;
          this.isLoadingHistory = false;
          this.parseStarredMessages(starMsgs)
          return;
        }

        // all msgs are loaded when requested msg size < page size
        if (starMsgs.length < this.starredMsgSize) {
          this.isAllMsgsLoaded = true;
        }

        this.parseStarredMessages(starMsgs)
        
        if (this.keyword && this.keyword.trim().length) {
          this.filterMsgs(this.keyword)
        }

        if (_.isEmpty(this.prevUnstarredMsg)) {
          // this.starredMsgPage += this.starredMsgSize / this.DEFAULT_SIZE
          this.starredMsgPage += 1;
        } else {
          /* the current load is followed by removing starred message, no need to update the page no */
        }

        this.starredMsgSize = this.DEFAULT_SIZE; // reset size after removing starred message

        this.prevUnstarredMsg = {}; // reset previous unstarred message record
        this.isLoadingHistory = false;

        // recursive load
        if (this.isNeedLoadMore) {
          // _.debounce(this.loadStarMessages, 100)
          this.loadStarMessages()
        }
      },
      (err) => {
        this.resetStarredMsgsStates();
        this._tnNotificationService.showSystemError();
      }
    )
  }

  getPrevUnstarMsg(ms: MessageStar): prevUnstarMsgType {
    let index = _.findIndex(this.originalStarredMessages, (m) => m.message_id === ms.body)

    return {
      content: this.originalStarredMessages[index],
      index: index
    }
  }

  onStarredMessageClick(sm): void {
    this._starredMessagesService.goToChatWithAction(sm, 'message_jumping')
  }

  updateStarredMessages(keyword: string): void {
    this.keyword = keyword;

    if (!keyword || keyword && keyword.trim().length === 0) {
      this.starredMessages = this.originalStarredMessages.slice()
      return
    }

    this.filterMsgs(this.keyword)
  }

  filterMsgs(keyword: string): void {
    // let validMessage = _.filter(this.originalStarredMessages, sm => sm.parsedBody.message)

    this.starredMessages = _.filter(this.originalStarredMessages, sm => {
      switch (_.toInteger(sm.type)) {
        case MessageTypeConstant.TEXT:
          let fields = [sm.parsedBody.message.toLowerCase()]
          // if (sm.comment_parent) {
          //   fields.push(sm.comment_parent.parsedBody.message.toLowerCase())
          // }

          return _.some(fields, (f) => _.includes(f, keyword.toLowerCase()))
          
        case MessageTypeConstant.ATTACHMENT:
          if (sm.parsedBody.filename) {
            return _.includes(sm.parsedBody.filename.toLowerCase(), keyword.toLowerCase())
          } else if (sm.parsedBody.message) {
            return _.includes(sm.parsedBody.message.toLowerCase(), keyword.toLowerCase())
          } else {
            return false
          }
        case MessageTypeConstant.LOCATION:
          return _.includes(sm.parsedBody.address.toLowerCase(), keyword.toLowerCase())
        
      }

      // return _.includes(sm.senderContact.name.toLowerCase(), keyword.toLowerCase())
    })
  }

  get isNeedLoadMore(): boolean {
    let starredMsgEl = this.el.nativeElement.querySelector(".starred-messages-list");

    if (!starredMsgEl) {
      return true;
    }

    return starredMsgEl.scrollHeight <= starredMsgEl.clientHeight
  }
}
