import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { VideoStreamingService } from './video-streaming.service';

import * as _ from 'lodash';

@Component({
  selector: 'tn-video-streaming',
  templateUrl: './video-streaming.component.html',
  styleUrls: ['./video-streaming.component.scss']
})
export class VideoStreamingComponent implements OnInit {

  streams: string[] = [];
  activeStreamIndex: number = 0;

  videoUrl: any;
  videoPlayer: any;

  constructor(
    private _domSanitizer: DomSanitizer,
    private _videoStreamingService: VideoStreamingService
  ) { }

  ngOnInit() {
    this.getActiveStreamList();
  }

  ngAfterViewInit() {
    this.initVideoJsPlayer();
  }

  ngOnDestroy() {
    if (this.videoPlayer) {
      this.videoPlayer.dispose();
    }
  }

  initVideoJsPlayer(): void {
    if (!document.getElementById('videojs_stream')) {
      return;
    }
    this.videoPlayer = videojs(
      document.getElementById('videojs_stream'), 
      {}, 
      () => {
        
      }
    );
  }

  viewTargetStream(index: number): void {
    if (index < 0) {
      index = this.streams.length - 1;
    }

    if (index > this.streams.length - 1) {
      index = 0;
    }

    this.activeStreamIndex = index;

    let streamUrl = this.streams[index];
    if (!streamUrl) {
      this.videoUrl = null;
      return;
    }

    let url = this._videoStreamingService.getParsedStreamingVideoUrl(streamUrl);
    this.videoUrl = this._domSanitizer.bypassSecurityTrustUrl(url);

    this.videoPlayer.src({type: 'rtmp/mp4', src: url});

  }

  refreshList(): void {
    this.getActiveStreamList();
  }

  getActiveStreamList(): void {
    this._videoStreamingService.getSrsActiveStreams(
      resp => {
        this.streams = resp.urls;
        this.viewTargetStream(0);
      },
      err => {

      }
    );
  }
}
