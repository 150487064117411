<div class="attachment-container">
  <div class="field-attachment-wrapper">
    <div class="attachment-wrapper" 
      [ngClass]="{
        'attachment-photo-wrapper': ansObj.type == attachmentTypes.photo,
        'attachment-video-wrapper': ansObj.type == attachmentTypes.video,
        'attachment-file-wrapper': ansObj.type == attachmentTypes.file || ansObj.type == attachmentTypes.audio,
        'input-mode': isInput
      }"
      *ngFor="let ansObj of localAnsObj">
      <div class="attachment-row">
        <!-- <div class="attachment" (click)="openAttachments(ansObj)"> -->
        <div class="attachment" (click)="openAttachments(ansObj)">
          <div class="photo" *ngIf="ansObj.type == attachmentTypes.photo">
            <div class="photo-view" 
              *ngIf="ansObj.file"
              [ngStyle]="{'background-image': 'url(' + ansObj.file.imagePreview + ')'}">
            </div>
            <div class="photo-view" 
              *ngIf="ansObj.src" 
              [ngStyle]="{'background-image': ansObj.src}">
            </div>
          </div>
          <div class="video" *ngIf="ansObj.type == attachmentTypes.video">
            <div class="file-name">
              <div class="file-icon form-attachment-video-icon"></div>
              {{ansObj.name}}
            </div>
          </div>
          <div class="file" *ngIf="ansObj.type == attachmentTypes.file || ansObj.type == attachmentTypes.audio">
            <div class="file-name">
              <div class="file-icon form-attachment-file-icon"></div>
              {{ansObj.name}}
            </div>
          </div>
        </div>
  
        <div class="action-icon-wrapper">
          <div class="form-delete-icon" (click)="removeAttachment(ansObj)"></div>
        </div>
      </div>
    </div>
  
    <div class="attachment-button-row">
      <div class="buttons-wrapper" (click)="openFileUploader()">
        <div class="button">
          <div class="form-attachment-photo-icon"></div>
        </div>
        <!-- <div class="button" *ngIf="detail.allow_type.video">
          <div class="form-attachment-video-icon"></div>
        </div>
        <div class="button" *ngIf="detail.allow_type.file">
          <div class="form-attachment-file-icon"></div>
        </div> -->
      </div>
    </div>
  </div>
</div>