<div class="broadcast-send-wrapper">
  <tn-broadcast-recipient-selector
    class="recipient-selector"
    [(selectedContacts)]="selectedContacts">
  </tn-broadcast-recipient-selector>

  <div class="broadcast-input">
    <div class="message-send-bar">
      <div class="message-option-bar">
          <div class="option" (click)="openUploadFilesModal(null)">
            <i class="fa fa-upload fa-fw"></i> {{'WEBCLIENT.CHATROOM.MESSAGE_OPTION.UPLOAD' | translate}}
          </div>
          <div class="option" (click)="openLocationSelectModal()">
            <i class="fa fa-map-marker fa-fw"></i> {{'WEBCLIENT.CHATROOM.MESSAGE_OPTION.LOCATION' | translate}}
          </div>
        </div>
        <div class="message-content-bar">
            <div class="encrypted-msg-lock-wrapper" *ngIf="isAllowEncryptedMessage">
              <div class="encrypted-msg-lock clickable" 
                [ngClass]="{'on': isToggledEncryptedMessage, 'off': !isToggledEncryptedMessage}" 
                (click)="toggleEncryptedMessage()">
              </div>
            </div>
      
            <div class="input-area">
              <textarea 
                class="form-control" 
                [(ngModel)]="inputMessage" 
                (keydown.enter)="inputKeyEnter(false)" 
                (keydown.shift.enter)="inputKeyEnter(true)">
              </textarea>
            </div>
            <div class="send-button" (click)="send()">
              {{'WEBCLIENT.CHATROOM.SEND' | translate}}
            </div>
        </div>
    </div>   
  </div>
</div>