import { Injectable } from '@angular/core';
import { WorkflowForm, WorkflowTeam, WorkflowType } from '../model/workflow';
import { WorkflowService } from '../services/workflow.service';
import { WorkflowConstant } from '../constants/workflow.constants';
import { DateService } from '../../../utilities/date/date.service';
import { FieldHelperService } from '../../shared/custom-field/helper/field-helper.service';
import { CustomFieldMagicStringMapping } from '../../shared/custom-field/models/custom-field';

@Injectable()
export class WorkflowCellFormatService {

  defaultFormListFormat = '<strong>%%teamName%% - %%typeName%%</strong><br><span style="color: #909192"><font color="#909192">%%createDate%%</font></span><br><span style="color: #909192"><font color="#909192">Ref:%%formNo%%</font></span>';

  constructor(
    private _workflowService: WorkflowService,
    private _dateService: DateService,
    private _fieldHelperService: FieldHelperService
  ) { }

  getWorkflowListCellFormatByForm(form: WorkflowForm, isTextDisplay?: boolean): string {
    let listFormat = this.defaultFormListFormat;

    let type = this._workflowService.getTypeByTypeId(form.type_id);
    if (type) {
      if (type.list_format) {
        listFormat = type.list_format;
      }
    }
    
    let workflowStaticFieldMappings = this.getWorkflowStaticMagicStringMapping(form);

    let allFieldDefinitions = this._workflowService.getAllFieldDefinitionOfTypeByTypeId(form.type_id);
    let allAnswers = form.custom_fields;
    listFormat = this._fieldHelperService.handleListFormatOfCustomField(
      listFormat,
      allFieldDefinitions,
      allAnswers,
      isTextDisplay,
      workflowStaticFieldMappings
    );

    return listFormat;
  }

  getWorkflowStaticMagicStringMapping(
    form: WorkflowForm,
    team?: WorkflowTeam,
    type?: WorkflowType
  ): CustomFieldMagicStringMapping {
    let formNo = "";
    let creator = "";
    let createDate = "";
    let lastUpdateDate = "";
    if (form) {
      formNo = form.form_no;
      creator = form.creator.name;
      createDate = this._dateService.getDateDisplayWithKey(form.create_date, "FORM_LIST_ITEM_DATE");
      lastUpdateDate = this._dateService.getDateDisplayWithKey(form.update_date, "FORM_LIST_ITEM_DATE");
    }

    let teamName = "";
    let teamCode = "";
    if (!team) {
      team = this._workflowService.getTeamByTeamId(form.team_id);
    }
    if (team) {
      teamName = team.name;
      teamCode = team.team_code;
    }

    let typeName = "";
    let activeStageName = "";
    let activeStageNumber = "";
    let totalStageNumber = "";
    if (!type) {
      type = this._workflowService.getTypeByTypeId(form.type_id);
    }
    if (type) {
      typeName = type.name;
      if (form) {
        let activeStage = this._workflowService.getStageOfTypeByTypeIdAndStageId(type.type_id, form.active_stage_id);
        if (activeStage) {
          activeStageName = activeStage.name;
          activeStageNumber = activeStage.order.toString();
        }
        totalStageNumber = type.stages.length.toString();
      }
    }

    return {
      [WorkflowConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.TEAM_NAME]: teamName,
      [WorkflowConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.TEAM_CODE]: teamCode,
      [WorkflowConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.TYPE_NAME]: typeName,
      [WorkflowConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.FORM_NO]: formNo,
      [WorkflowConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.CREATOR]: creator,
      [WorkflowConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.CREATE_DATE]: createDate,
      [WorkflowConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.LAST_UPDATE_DATE]: lastUpdateDate,
      [WorkflowConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.ACTIVE_STAGE_NAME]: activeStageName,
      [WorkflowConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.ACTIVE_STAGE_NUMBER]: activeStageNumber,
      [WorkflowConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.TOTAL_STAGE_NUMBER]: totalStageNumber
    };
  }

}
