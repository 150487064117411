import { Component, OnInit } from '@angular/core';
import { WebDavMaterialService } from './webdav-material.service';
import * as _ from 'lodash';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { WebDavMaterialLoginComponent } from './webdav-material-login/webdav-material-login.component';
import { TnDialogService } from '../../../app/utilities/tn-dialog/tn-dialog.service';
import { LocalStorageManagerService } from '../../../app/utilities/local-storage/local-storage-manager.service';
import { TeamNoteLocalStorageKeyConstants } from '../../../app/constants/local-storage-key.constant';
import { RouteParamService } from '../../../app/utilities/route-param/route-param.service';



@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tn-webdav-material',
  templateUrl: './webdav-material.component.html',
  styleUrls: ['./webdav-material.component.scss']
})

export class WebDavMaterialComponent implements OnInit {
  // Param
  isDialog = false;

  constructor(
    private _webDavMaterialService: WebDavMaterialService,
    private _tnDialogService: TnDialogService,
    public dialog: MatDialog,
    private _routeParamService: RouteParamService,
    private _localStorageManagerService: LocalStorageManagerService,
    ) {
      /*
      this._routeParamService.currentRouteParam$.subscribe(
        params => {
          console.error(params);
        }
      );*/
    }
  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit(): void {
    // this.ClearCookie();
    this.TestAuth();
  }
  ngOnInit() {
  }
  ClearCookie() {
    this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.WEBDAV.USERNAME);
    this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.WEBDAV.PASSWORD);
  }
  showPop() {
    const dialogRef = this._tnDialogService.openTnDialog(WebDavMaterialLoginComponent);
    dialogRef.updateSize('340px', '245px');
    dialogRef.afterClosed().subscribe((result: boolean) => {
      // console.error(result);
      if (result) { this.TestAuth(); }
    });
  }
  TestAuth() {
    this._webDavMaterialService.TestAuth(() => {this.isDialog = true; }, (e) => this.showPop());
  }
  getStringValue(value: any): string {
    return value.toString();
  }
  OnInit() {
  }
}
