/**
 * For news list preview image,
 * only show X attachments message if numOfAttachment > 1,
 * 
 * For news modal image,
 * always show X attachment(s) message
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { LoggerService } from '../../../utilities/logger/logger.service';
import { FileFactoryService } from '../../../utilities/file-factory/file-factory.service';
import { FileManagerService } from '../../../utilities/file-manager/file-manager.service';
import { AttachmentTypeConstant } from '../../../constants/attachment-type.constant';
import _ from 'lodash'

@Component({
  selector: 'tn-news-image',
  templateUrl: './news-image.component.html',
  styleUrls: ['./news-image.component.scss']
})
export class NewsImageComponent implements OnInit {
  @Input() attachments: string[];
  @Input() media: string;
  @Input() images: string;
  @Input() previewImageH: string;
  @Input() isInModal: boolean;
  @Output() onImageClick = new EventEmitter<string>();

  @Input() isCarousel: boolean;

  imagesArr: string[];
  numOfAttachment: number;
  imgSrc: any;

  isVideo: boolean = false;

  constructor(
    private _domSanitizer: DomSanitizer,
    private _loggerService: LoggerService,
    private _fileFactoryService: FileFactoryService,
    private _fileManagerService: FileManagerService
  ) { }

  ngOnInit() {
    if (_.isArray(this.attachments)) {
      this.numOfAttachment = this.attachments.length
    }
    
    // both images and preview_image_h are empty
    if (!this.images && !this.previewImageH) {
      return 
    }
    
    // console.log('media', this.media)

    this.imagesArr = this.images.split(',');
    
    let imageId;

    // if images is empty
    if (!this.images || !this.imagesArr[0]) {

      imageId = this.previewImageH;

      // this.numOfAttachment = 1;
    } else {
      // in news detail page -> use this.imagesArr[0];
      // in news list page -> use previewImageH
      imageId = this.isInModal ? this.imagesArr[0] : this.previewImageH;
      
      // this.numOfAttachment = this.imagesArr.length;
    }

    if (this.media) {
      let type = this._fileManagerService.getAttachmentType(this.media)
      this.isVideo = type === AttachmentTypeConstant.VIDEO
    }

    this._fileFactoryService.getFileByAttachmentId(
      imageId,
      (dataUrl, fileName) => {
        this.imgSrc = this._domSanitizer.bypassSecurityTrustResourceUrl(dataUrl);
      },
      (err) => {},
      false
    );
  }
  ngOnChanges() {
    if (this.isCarousel) {
      let imageId;
      if (this.previewImageH && !this.isInModal) {
        imageId = this.previewImageH;
      } else {
        imageId = this.imagesArr[0];
      }

      this._fileFactoryService.getFileByAttachmentId(
        imageId,
        (dataUrl, fileName) => {
          this.imgSrc = this._domSanitizer.bypassSecurityTrustResourceUrl(dataUrl);
        },
        (err) => {},
        false
      );
    }
  }
  onClick() {
    this._loggerService.log("Clicked news' image: " + this.media);
    this.onImageClick.emit(this.media);
  }

}
