import {Injectable} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Subscription} from 'rxjs';

import {Message} from '../../models/message';
import {MessageNewsBody} from '../../models/message-news-body';
import {MessageVoteBody} from '../../models/message-vote-body';
import {ApiVote} from '../../models/api-vote';
import {News, EmojiDisplay} from '../../models/news';

import {VoteModalComponent} from './vote-modal/vote-modal.component';

import {VoteService} from './vote.service';
import {NewsMessageService} from '../services/data/messages/news-message/news-message.service';
import {MessagesService} from '../services/data/messages/messages.service';

import {TeamnoteApiService} from '../../api/teamnote-api.service';
import {AttachmentService} from '../../utilities/attachment/attachment.service';
import {EmojiService} from '../../utilities/emoji/emoji.service';
import {AccountManagerService} from '../services/account/account-manager.service';
import {TimestampService} from '../../utilities/timestamp/timestamp.service';

import * as _ from 'lodash';
import {TnDialogService} from '../../utilities/tn-dialog/tn-dialog.service';
import {ModuleManagerService} from '../services/module/module-manager.service';
import {InfoMessageService} from '../services/data/messages/info-message/info-message.service';
import {ChatService} from '../services/data/chat/chat.service';
import {MessageTextBody} from '../../models/message-text-body';
import {MessageAttachmentBody} from '../../models/message-attachment-body';
import {UserContactService} from '../services/data/user-contact/user-contact.service';
import {EmojiMenuComponent} from '../../utilities/emoji/emoji-menu/emoji-menu.component';
import {TnNotificationService} from '../../utilities/tn-notification/tn-notification.service';
import {UtilitiesService} from '../../utilities/service/utilities.service';
import {EmojiConstant} from '../../constants/emoji.constant';
import {MessageTypeConstant} from '../../constants/message-type.constant';
import {AMQPRoutingKey} from '../../constants/amqp-routing-key.constant';
import {TeamNoteApiConstant} from '../../constants/api.constant';
import {ModuleKeyDefinition} from '../../constants/module.constant';
import {DataManagerService} from '../services/data/data-manager.service';
import {FileManagerService} from '../../utilities/file-manager/file-manager.service';
import {TeamnoteConfigService} from '../../configs/teamnote-config.service';
import {AttachmentTypeConstant} from '../../constants/attachment-type.constant';

@Injectable()
export class NewsService {

  isEnableEmoji: boolean = false;
  isEnableComment: boolean = false;

  isEnableNewsContentPreview: boolean = false;

  requestedReadMessageIds: string[] = [];

  allReadMessageId: string[] = [];

  realReadMessageId: string[] = [];

  //News enhancement
  newsHttp: any[] = [];
  newsCategoryList: any[] = [];

  constructor(
    private _emojiService: EmojiService,
    private _accountManagerService: AccountManagerService,
    private _domSanitizer: DomSanitizer,
    private _attachmentService: AttachmentService,
    private _teamnoteApiService: TeamnoteApiService,
    private _timestampService: TimestampService,
    private _voteService: VoteService,
    private _newsMessageService: NewsMessageService,
    private _messageService: MessagesService,
    private _tnDialogService: TnDialogService,
    private _moduleManagerService: ModuleManagerService,
    private _infoMessageService: InfoMessageService,
    private _chatService: ChatService,
    private _fileManagerService: FileManagerService,
    private _userContactService: UserContactService,
    private _tnNotificationService: TnNotificationService,
    private _utilitiesService: UtilitiesService,
    private _dataManagerService: DataManagerService,
    private _teamnoteConfigService: TeamnoteConfigService
  ) {
    this._teamnoteConfigService.config$.subscribe((config) => {
      this.isEnableNewsContentPreview = config.WEBCLIENT.NEWS.IS_ENABLE_CONTENT_PREVIEW;
    });
  }

  checkNewsModule(): void {
    this.isEnableEmoji = this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.NEWS_EMOJI);

    // this.isEnableComment = this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.MESSAGE_COMMENT);
    this.isEnableComment = this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.NEWS_EMOJI);
  }

  checkIfThreadOpenByMessageId(messageId: string): boolean {
    let newsMessage = this._newsMessageService.getNewsMessageByMessageId(messageId);
    let body = JSON.parse(newsMessage.body);
    return this.checkIfThreadOpen(body);
  }

  checkIfThreadOpen(body: MessageNewsBody): boolean {
    return this._newsMessageService.checkIfThreadOpen(body);
  }

  checkIfNewsExpired(message: Message): boolean {
    return this._newsMessageService.checkIfNewsExpired(message);
  }

  getLikeCountOfMessage(message: Message): number {
    return message.emoji_counts[EmojiConstant.LIKE_EMOJI_STR];
  }

  checkIfMessageIsLiked(message: Message): boolean {
    if (_.find(message.emojis, {'content': EmojiConstant.LIKE_EMOJI_STR, 'user_id': this._accountManagerService.userId})) {
      return true;
    } else {
      return false;
    }
  }

  parseCommentForDisplay(message: Message): Message {
    message.likeCount = this.getLikeCountOfMessage(message);
    message.isLiked = this.checkIfMessageIsLiked(message);
    message.parsedBody = message.body ? JSON.parse(message.body) : null;

    if (!message.sender) {
      let sender = this._userContactService.getUserContactByUserId(message.sent_by);
      message.sender = {
        name: sender.name,
        pic: sender.pic,
        pic_thumbnail: null,
        user_id: sender.user_id
      };
    }

    return message;
  }

  getMessageWithCommentByMessage(message: Message): Message {
    _.each(message.comments, (c) => {
      _.each(c.comments, (child) => {
        child = this.parseCommentForDisplay(child);
      });
      c = this.parseCommentForDisplay(c);
      c.comments = _.sortBy(c.comments, 'timestamp');
    });
    message.comments = _.sortBy(message.comments, 'timestamp');

    return message;
  }

  parseMessageForDisplay(message: Message, isNeedComments?: boolean): News {
    let display = <News>{};

    // sort comments
    if (isNeedComments) {
      _.each(message.comments, (c) => {
        _.each(c.comments, (child) => {
            child = this.parseCommentForDisplay(child);
        });
        c = this.parseCommentForDisplay(c);
        c.comments = _.sortBy(c.comments, 'timestamp');
      });
      message.comments = _.sortBy(message.comments, 'timestamp');
    }

    display.originalMessage = message;
    if (_.isEmpty(message)) {
      return display;
    }

    display.isNews = message.type == MessageTypeConstant.NEWS;

    let body = JSON.parse(message.body);

    display.isThreadOpen = this.checkIfThreadOpen(body);

    // Image & Media
    display.images = body.images;
    display.media = body.media;

    // let imageList = []
    // if (display.images) {
    //   imageList = display.images.split(',');
    // } 
    // display.previewImageH = body.preview_image_h ? body.preview_image_h : imageList[0];
    display.previewImageH = body.preview_image_h;
    display.previewImageV = body.preview_image_v;

    // Detail part display
    display.date = parseFloat(message.timestamp);

    // try to handle EMBED HOST
    let htmlContent = display.isNews ? body.html_content : body.detail;
    if (
      this._teamnoteConfigService.config.HOST.EMBED_HOST_O &&
      htmlContent
    ) {
      let originalHost = this._teamnoteConfigService.config.HOST.EMBED_HOST_O;
      let targetHost = this._teamnoteConfigService.config.HOST.EMBED_HOST_N;
      if (!targetHost) {
        targetHost = window.location.origin;
      }
      htmlContent = htmlContent.split(originalHost).join(targetHost);
    }

    // translate other external & internal domain pairs when needed
    if (
      this.isNeedTranslate() && htmlContent
    ) {
      _.each(this._teamnoteConfigService.config.HOST.EMBED_HOST_E, (hostname, index) => {
        let originalHost = hostname
        let targetHost = this._teamnoteConfigService.config.HOST.EMBED_HOST_I[index];
        if (!targetHost) {
          targetHost = window.location.origin;
        }
        htmlContent = htmlContent.split(originalHost).join(targetHost);
      })
    }

    if (display.isNews) {
      display.title = body.content;
      display.htmlContent = htmlContent ? this._domSanitizer.bypassSecurityTrustHtml(htmlContent) : '';
    } else {
      // fallback to "content" for old servers
      display.title = body.title ? body.title : body.content;
      display.htmlContent = htmlContent ? this._domSanitizer.bypassSecurityTrustHtml(htmlContent) : body.content;
    }
    display.contentFragment = this.getNewsPreviewContent(display.htmlContent.changingThisBreaksApplicationSecurity, display.title, 100);

    display.likeCount = 0;
    display.isLiked = false;
    display.likeAction = 'Like';
    display.emojiCounts = _.map(message.emoji_counts, (c, e) => {
      let ec = <EmojiDisplay>{};
      ec.emojiStr = e;
      ec.filePath = this._emojiService.getEmojiPathByFileName(this._emojiService.getFileName(e)); 
      ec.count = c;
      ec.isSent = _.find(message.emojis, {'content': e, 'user_id': this._accountManagerService.userId}) ? true : false;

      if (e == EmojiConstant.LIKE_EMOJI_STR) {
        display.likeCount = c;
        display.isLiked = ec.isSent;
        display.likeAction = ec.isSent ? 'Unlike' : 'Like';
      }

      return ec;
    });
    display.commentCount = message.comment_count;

    return display;
  }

  parseNewsHttpForDisplay(news: any, isNeedComments?: boolean): News {
    let display = <News>{};

    // sort comments
    if (isNeedComments) {
      // _.each(message.comments, (c) => {
      //   _.each(c.comments, (child) => {
      //       child = this.parseCommentForDisplay(child);
      //   });
      //   c = this.parseCommentForDisplay(c);
      //   c.comments = _.sortBy(c.comments, 'timestamp');
      // });
      // message.comments = _.sortBy(message.comments, 'timestamp');
    }

    display.originalMessage = news;
    if (_.isEmpty(news)) {
      return display;
    }

    display.isNews = news.type == 'News';

    let body = news;
    display.isThreadOpen = this.checkIfThreadOpen(body);

    // Image & Media
    display.attachments = body.attachments;
    display.images = body.images;
    display.media = body.media;
    display.previewImageH = body.preview_image_h;
    display.previewImageV = body.preview_image_v;

    // Detail part display
    display.date = parseFloat(news.display_start);

    // try to handle EMBED HOST
    let htmlContent = display.isNews ? body.html_content : body.detail;
    if (
      this._teamnoteConfigService.config.HOST.EMBED_HOST_O &&
      htmlContent
    ) {
      let originalHost = this._teamnoteConfigService.config.HOST.EMBED_HOST_O;
      let targetHost = this._teamnoteConfigService.config.HOST.EMBED_HOST_N;
      if (!targetHost) {
        targetHost = window.location.origin;
      }
      htmlContent = htmlContent.split(originalHost).join(targetHost);
    }

    // translate other external & internal domain pairs when needed
    if (
      this.isNeedTranslate() && htmlContent
    ) {
      // translate each pair of external domain & internal domain
      _.each(this._teamnoteConfigService.config.HOST.EMBED_HOST_E, (hostname, index) => {
        let originalHost = hostname
        let targetHost = this._teamnoteConfigService.config.HOST.EMBED_HOST_I[index];
        if (!targetHost) {
          targetHost = window.location.origin;
        }
        htmlContent = htmlContent.split(originalHost).join(targetHost);
      })
    }

    if (display.isNews) {
      display.title = body.content;
      display.htmlContent = htmlContent ? this._domSanitizer.bypassSecurityTrustHtml(htmlContent) : '';
    } else {
      // fallback to "content" for old servers
      display.title = body.title ? body.title : body.content;
      display.htmlContent = htmlContent ? this._domSanitizer.bypassSecurityTrustHtml(htmlContent) : body.content;
    }
    display.contentFragment = this.getNewsPreviewContent(display.htmlContent.changingThisBreaksApplicationSecurity, display.title, 100);

    display.likeCount = 0;
    display.isLiked = false;
    display.likeAction = 'Like';
    display.emojiCounts = _.map(news.emoji_counts, (c, e) => {
      let ec = <EmojiDisplay>{};
      ec.emojiStr = e;
      ec.filePath = this._emojiService.getEmojiPathByFileName(this._emojiService.getFileName(e)); 
      ec.count = c;
      ec.isSent = _.find(news.emojis, {'content': e, 'user_id': this._accountManagerService.userId}) ? true : false;

      if (e == EmojiConstant.LIKE_EMOJI_STR) {
        display.likeCount = c;
        display.isLiked = ec.isSent;
        display.likeAction = ec.isSent ? 'Unlike' : 'Like';
      }

      return ec;
    });
    return display;
  }

  getNewsPreviewContent(str: string, newsTitle: string, wordsCount: number): any {
    if (str === undefined || typeof str === 'undefined') return str = ''
    // remove tag and symbols
    str = str.replace(/\<[^>]*\>(([^<])*)/gi, function () {
      return ' ' + arguments[1];
    });
    // remove all spaces
    str = str.replace(/\s+/g," ");
    // remove the news title
    // let titleRegex = new RegExp("\\b" + newsTitle + "\\b")
    // str = str.replace(titleRegex, '')

    // get first [wordsCount] letters
    if (str.length >= wordsCount) {
      str = str.substring(0, wordsCount) + '...'
    } else {
      str = str.substring(0, str.length)
    }

    //handle special charactor
    let parser = new DOMParser().parseFromString(str, 'text/html');
    str = parser.documentElement.textContent;
    
    return str
  }
  
  // check if there is external domain that need to be translated
  isNeedTranslate(): boolean {
    let needTranslate = false
    const externalDomainsArr = this._teamnoteConfigService.config.HOST.EMBED_HOST_E
    needTranslate = externalDomainsArr.some(domain => domain != '')

    // when using external domain to get web-client, then no need to translate
    if (window.location.origin == this._teamnoteConfigService.config.HOST.EMBED_HOST_O) {
      needTranslate = false
    }

    return needTranslate
  }

  openNewsMedia(message: Message): void {
    let body = JSON.parse(message.body);
    let images = body.images;
    let media = body.media;

    let targetAttachmentId = '';
    let targetAttachments = null;
    if (media) {
      targetAttachmentId = media;
      // targetAttachments = _.filter(message.attachments, {'attachment_id': media});
      targetAttachments = _.filter(message.attachments, (attachment) => {
        return this._fileManagerService.getAttachmentType(attachment.attachment_id) === AttachmentTypeConstant.IMAGE;
      });
    } else {
      targetAttachmentId = images;
      // targetAttachments = message.attachments;
      targetAttachments = _.filter(message.attachments, (attachment) => {
        return this._fileManagerService.getAttachmentType(attachment.attachment_id) === AttachmentTypeConstant.IMAGE;
      });
    }
    this._attachmentService.prepareNewsMediaModalContent(targetAttachments);
  }

  openEmojiMenu(message: Message): void {
    let dialogRef = this._tnDialogService.openTnDialog(EmojiMenuComponent, {
      onEmojiSelectFunction: (emoji) => {
        // let unicodeEmoji = this._emojiService.getUnicodeByEmoji(emoji);
        this.onEmojiClick(message, false, emoji);
      }
    });
  }

  onEmojiClick(message: Message, isSent: boolean, emojiStr: string): void {
    if (isSent) {
      this._messageService.unSendEmoji(message.message_id, emojiStr);
    } else {
      this._messageService.sendEmoji(message.message_id, emojiStr);
    }
  }

  onLikeClick(message: Message, isLiked: boolean): void {
    if (isLiked) {
      this._messageService.unSendEmoji(message.message_id, EmojiConstant.LIKE_EMOJI_STR);
    } else {
      this._messageService.sendEmoji(message.message_id, EmojiConstant.LIKE_EMOJI_STR);
    }
  }

  // ----- Vote -----
  tryOpenVoteModal(message?: Message): void {

    let body = <MessageVoteBody>JSON.parse(message.body);
    let voteId = body.vote_id;

    this._voteService.getVoteByVoteId(
      voteId,
      resp => {
        let vote = <ApiVote>resp;
        let isVoting = true;

        if (this._timestampService.checkIfTimeAfterToday(vote.vote_start)) {
          // Not Started
          this._tnNotificationService.showCustomWarningByTranslateKey('WEBCLIENT.NEWS.ERROR_MSG.VOTE_NOT_YET_START');
          return;
        } else if (this._timestampService.checkIfTimeBeforeToday(vote.vote_end)) {
          // Expired, Allow to show result
          if (this._voteService.checkVoteAllowViewResult(vote)) {
            isVoting = false;
          } else {
            this._tnNotificationService.showCustomWarningByTranslateKey('WEBCLIENT.NEWS.ERROR_MSG.VOTE_RESULT_HIDDEN');
            return;
          }
        }
        vote = this._voteService.parseVoteForDisplay(vote, isVoting);
        let voteDialogRef = this._tnDialogService.openTnDialog(VoteModalComponent, {
          vote: vote,
          isVoting: isVoting
        });

      },
      err => {
        console.log(err);
      }
    );
  }

  openVoteModal(message: Message, isVoting: boolean): void {
    let body = <MessageVoteBody>JSON.parse(message.body);

    let voteId = body.vote_id;

    this._voteService.getVoteByVoteId(
      voteId,
      resp => {
        let vote = <ApiVote>resp;
        if (!this._voteService.checkIfVoteModalShouldOpen(vote, isVoting)) {
          return;
        }
        vote = this._voteService.parseVoteForDisplay(vote, isVoting);
        let voteDialogRef = this._tnDialogService.openTnDialog(VoteModalComponent, {
          vote: vote,
          isVoting: isVoting
        });

      },
      err => {
        console.log(err);
      }
    );
  }
  // News Enhancement
  openNewsHttpMedia(news: any, attachmentId?: string): void {
    let body = JSON.parse(JSON.stringify(news));
    let images = _.isString(body.images) ? body.images.split(',') : body.images;
    let media = body.media;

    let targetAttachmentId = '';
    let targetAttachments = null;
    const attachments = body.originalMessage ? body.originalMessage.attachments : body.attachments

    if (media) {
      targetAttachmentId = media;

      targetAttachments = _.filter(attachments, function(el){
        if (attachmentId) {
          return el.attachment_id === attachmentId;
        } else {
          // return el.attachment_id === images;
          return _.includes(images, el.attachment_id)
        }
      });
    } else {
      targetAttachmentId = images;
      targetAttachments = _.filter(attachments, function(el){
        // return el.attachment_id === images;
        return _.includes(images, el.attachment_id)
      });
    }
    this._attachmentService.prepareNewsMediaModalContent(targetAttachments);
  }

  // openNewsHttpMediaDirect(defaultId: string): void {
  //   let body = JSON.parse(JSON.stringify(news));
  //   let images = body.images;
  //   let media = body.media;
  //
  //   let targetAttachmentId = '';
  //   let targetAttachments = null;
  //   // if (media) {
  //   //   targetAttachmentId = media;
  //   //   targetAttachments = _.filter(news.attachments, {'attachment_id': media});
  //   // } else {
  //   targetAttachmentId = images;
  //   targetAttachments = news.images;
  //   // }
  //   this._attachmentService.prepareNewsMediaModalContent(targetAttachments);
  // }

  openNewsHttpImage(news: any): void {
    let body = JSON.parse(JSON.stringify(news));
    let images = body.images;
    let media = body.media;

    let targetAttachmentId = '';
    let targetAttachments = null;
    // if (media) {
    //   targetAttachmentId = media;
    //   targetAttachments = _.filter(news.attachments, {'attachment_id': media});
    // } else {
    targetAttachmentId = images;
    targetAttachments = news.images;
    // }
    this._attachmentService.prepareNewsMediaModalContent(targetAttachments);
  }

  tryOpenNewsHttpVoteModal(news: any): void {
    let body = JSON.parse(JSON.stringify(news));
    let voteId = body.vote_id;
    this.setVoteRead(voteId);
    this._voteService.getVoteByVoteId(
      voteId,
      resp => {
        let vote = <ApiVote>resp;
        let isVoting = true;

        if (this._timestampService.checkIfTimeAfterToday(vote.vote_start)) {
          // Not Started
          this._tnNotificationService.showCustomWarningByTranslateKey('WEBCLIENT.NEWS.ERROR_MSG.VOTE_NOT_YET_START');
          return;
        } else if (this._timestampService.checkIfTimeBeforeToday(vote.vote_end)) {
          // Expired, Allow to show result
          if (this._voteService.checkVoteAllowViewResult(vote)) {
            isVoting = false;
          } else {
            this._tnNotificationService.showCustomWarningByTranslateKey('WEBCLIENT.NEWS.ERROR_MSG.VOTE_RESULT_HIDDEN');
            return;
          }
        }
        vote = this._voteService.parseVoteForDisplay(vote, isVoting);
        let voteDialogRef = this._tnDialogService.openTnDialog(VoteModalComponent, {
          vote: vote,
          isVoting: isVoting
        });

      },
      err => {
        console.log(err);
      }
    );
  }

  openNewsHttpVoteModal(news: any, isVoting: boolean): void {
    let body = JSON.parse(JSON.stringify(news));
    let voteId = body.vote_id;
    this._voteService.getVoteByVoteId(
      voteId,
      resp => {
        let vote = <ApiVote>resp;
        if (!this._voteService.checkIfVoteModalShouldOpen(vote, isVoting)) {
          return;
        }
        vote = this._voteService.parseVoteForDisplay(vote, isVoting);
        let voteDialogRef = this._tnDialogService.openTnDialog(VoteModalComponent, {
          vote: vote,
          isVoting: isVoting
        });

      },
      err => {
        console.log(err);
      }
    );
  }

  // Local history
  loadLocalNewsHistory(currentMessageBlockSize: number, newsCategoryId?: string): Message[] {
    let targetSize = currentMessageBlockSize + this._teamnoteConfigService.config.WEBCLIENT.AMQP.NEWS_LOAD_HISTORY_SIZE;
    let msgs;
    if (newsCategoryId) {
      msgs = this._newsMessageService.getLastestNewsByCategoryIdAndSize(newsCategoryId, targetSize);
    } else {
      msgs = this._newsMessageService.getLastestAllNewsBySize(targetSize);
    }
    return msgs;
  }

  checkIfTimestampIsEarliestNews(timestamp: string, newsCategoryId?: string): boolean {
    if (newsCategoryId) {
      return this._newsMessageService.checkIfTimestampIsEarliestCategoryNews(newsCategoryId, timestamp);
    } else {
      return this._newsMessageService.checkIfTimestampIsEarliestAllNews(timestamp);
    }
  }

  // AMQP
  loadNewsHistory(newsCategoryId?: string, callback?: Function): void {
    this._dataManagerService.loadNewsHistory(newsCategoryId, callback);
  }

  sendBatchReadOfNews(newsCategoryId?: string): void {
    let news;
    if (newsCategoryId) {
      news = this._newsMessageService.getNewsUnderNewsCategoryById(newsCategoryId);
    } else {
      news = this._newsMessageService.getAllNews();
    }
    let messageIds = [];
    _.each(news, (n) => {
      if (!this._infoMessageService.checkIfMessageIsReadByMe(n.message_id)) {
        if (_.indexOf(this.requestedReadMessageIds, n.message_id) === -1) {
          messageIds.push(n.message_id);
          this.requestedReadMessageIds.push(n.message_id);
        }
      }
    });
    let newsChannels = this._chatService.getNewsChannels();
    if (messageIds.length) {
      this._messageService.sendMessageRead(newsChannels[0].chat_id, messageIds);
    }
  }

  sendBatchReadOfNewsCommentByMessageId(messageId: string): void {
    let news: Message = this._messageService.getFullNewsWithCommentsByMessageId(messageId);
    let messageIds = [];

    if (!news) { return; }

    _.each(news.comments, (c) => {
      if (!c.is_read) {
        if (_.indexOf(this.requestedReadMessageIds, c.message_id) === -1) {
          messageIds.push(c.message_id);
          this.requestedReadMessageIds.push(c.message_id);
        }
      }
      _.each(c.comments, (cc) => {
        if (!cc.is_read) {
          if (_.indexOf(this.requestedReadMessageIds, cc.message_id) === -1) {
            messageIds.push(cc.message_id);
            this.requestedReadMessageIds.push(cc.message_id);
          }
        }
      });
    });

    let newsChannels = this._chatService.getNewsChannels();
    if (messageIds.length) {
      this._messageService.sendMessageRead(newsChannels[0].chat_id, messageIds);
    }

  }

  sendNewsCommentText(messageId: string, text: string): void {
    let body = <MessageTextBody>{
      message: text
    };
    this._messageService.sendMessageComment(messageId, MessageTypeConstant.TEXT, body);
  }

  uploadSendAttachment(messageId: string, files: File[]): void {
    // Upload first
    _.each(files, (f) => {
      this._fileManagerService.uploadFileHub(
        f,
        (fileId, imageCaption) => {
          this.sendNewsCommentAttachment(messageId, fileId, f, imageCaption);
        },
        true,
        AMQPRoutingKey.SEND_MESSAGE
      );
    });
  }

  sendNewsCommentAttachment(messageId: string, fileId: string, file: File, imageCaption: string): void {
    let body = <MessageAttachmentBody>{
      attachment_id: fileId,
      size: file.size,
      content_type: file.type,
      filename: file.name,
      message: imageCaption
    };
    this._messageService.sendMessageComment(messageId, MessageTypeConstant.ATTACHMENT, body);
  }

  // API
  recallNewsComment(messageId: string): void {
    let url = TeamNoteApiConstant.NEWS.RECALL_COMMENT;
    let params = {
      message_id: messageId
    };
    this._teamnoteApiService.callApi(
      url,
      params,
      (resp) => {
        if (!resp.success) {
          this._tnNotificationService.showSystemError();
        } else {
          this._tnNotificationService.showCustomInfoByTranslateKey('WEBCLIENT.NEWS.NEWS.REMOVE_COMMENT_SUCCESS_MSG');
        }
      },
      (err) => {
        this._tnNotificationService.showSystemError();
      }
    );
  }

  realNewsRead(messageId: string): void {
    if (_.indexOf(this.realReadMessageId, messageId) != -1) {
      // read already
      return;
    }

    let url = TeamNoteApiConstant.NEWS.READ;
    let params = {
      message_id: messageId
    };
    this._teamnoteApiService.callApi(
      url,
      params,
      (resp) => {
        this.realReadMessageId.push(messageId);
      },
      (err) => {
        this._tnNotificationService.showSystemError();
      },
      false,
      false,
      true
    );
  }

  // News Enhancement
  getNews(isLoadingHistory: boolean, size?: number, page?: number, category?: string, i18nKey?: string) {
    const url = TeamNoteApiConstant.NEWS.LIST;
    const params = {
      size: size,
      page: page,
      category_id: category
    };
    return new Promise ( resolve => {
      this._teamnoteApiService.callApi(
        url,
        params,
        (resp) => {
          // if (isLoadingHistory && resp.length === this.newsHttp.length) {
          if (isLoadingHistory && resp.length < this.newsHttp.length) {
            resolve('Max');
          }else {
            this.newsHttp = resp;
            resolve('success');
          }

        },
        (err) => {
          this._tnNotificationService.showSystemError();
        },
        false,
        false,
        true,
        false,
        i18nKey
      );
    });
  }
  checkNewsHttp(): boolean{
    if (this._accountManagerService.fullLoginResponse.enable_news_http === 0){
      return false;
    }
    if (this._accountManagerService.fullLoginResponse.enable_news_http === 1){
      return true;
    }
  }
  getNewsCategory(i18n_key: string) {
    let url = TeamNoteApiConstant.NEWS.CATEGORY;
    let param = {

    };
    return new Promise( resolve => {
      this._teamnoteApiService.callApi(
        url,
        param,
        (resp) => {
          this.newsCategoryList = resp;
          resolve('success');
        }
      ,
      (err) => {
        this._tnNotificationService.showSystemError();
      },
      false,
      false,
      true,
      false,
      i18n_key
      )
    })
    
  }

  setNewsRead(newsId: string) {
    let url = TeamNoteApiConstant.NEWS.READ;
    let param = {
      news_id: newsId
    };
    this._teamnoteApiService.callApi(
      url,
      param,
      (resp) => {
      },
    (err) => {
      this._tnNotificationService.showSystemError();
    },
    false,
    false,
    true
    )
  }
  setVoteRead(voteId: string) {
    let url = TeamNoteApiConstant.NEWS.READ;
    let param = {
      vote_id: voteId
    };
    this._teamnoteApiService.callApi(
      url,
      param,
      (resp) => {
      },
    (err) => {
      this._tnNotificationService.showSystemError();
    },
    false,
    false,
    true
    )
  }

  setNewsReadList(newsId: string){
    let url = TeamNoteApiConstant.NEWS.READ_LIST;
    let param = {
      news_id: newsId
    };
    this._teamnoteApiService.callApi(
      url,
      param,
      (resp) => {

      },
      (err) => {
        this._tnNotificationService.showSystemError();
      },
      false,
      false,
      true
    )
  }

  setVoteReadList(voteId: string){
    let url = TeamNoteApiConstant.NEWS.READ_LIST;
    let param = {
      vote_id: voteId
    };
    this._teamnoteApiService.callApi(
      url,
      param,
      (resp) => {

      },
      (err) => {
        this._tnNotificationService.showSystemError();
      },
      false,
      false,
      true
    )
  }
}
