import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Translate
import { TranslateModule } from '@ngx-translate/core';

// Map
// import { NguiMapModule } from '@ngui/map';
import { GoogleMapsModule } from '@angular/google-maps';

import { LocationSelectorComponent } from './location-selector.component';
import { LocationSelectorService } from './location-selector.service';

import { TeamnoteConfigService } from '../../configs/teamnote-config.service';
import { TeamNoteDefaultConfig } from '../../configs/default.config';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        // NguiMapModule,
        GoogleMapsModule
    ],
    declarations: [
        LocationSelectorComponent
    ],
    providers: []
})
export class LocationSelectorModule { }
