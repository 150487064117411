export const ZhChtQuestionnaireStrings = {
  ALREADY_COMPLETED: "你已經完成過此問卷，此問卷只可以填寫一次",
  EXPIRED: "此問卷已過期",

  SUBMITTED_MSG: "感謝您的意見",
  END: "-- 完結 --",
  SUBMIT: "提交",
  ITEM_OTHER: "其他",
  INPUT_ALL_FIELDS: "請回答所有問題",
  ITEM_RAITNG_CLICK: "點擊星星以評分",
  ITEM_RATING_COMMENT: "其他意見（選填）:",
  ITEM_UNFINISHED_INDICATOR: "***"
}
