import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StoreReportCustomFieldsMapped } from '../../models/job-report';

import * as _ from 'lodash';
import { AttachmentService } from '../../../../utilities/attachment/attachment.service';
import { FileUploaderService } from '../../../../utilities/file-uploader/file-uploader.service';
import { ParsedFile } from '../../../../utilities/file-uploader/file-uploader.component';
import { FileManagerService } from '../../../../utilities/file-manager/file-manager.service';
import { ImageHelperService } from '../../../../utilities/image-helper/image-helper.service';
import { FileFactoryService } from '../../../../utilities/file-factory/file-factory.service';

export class FieldAttachmentParsedAnswer {
  attachment_id: string;
  src: string;
  caption: string;
  file: ParsedFile;

  constructor(file?: ParsedFile, attachmentId?: string, caption?: string) {
    this.file = file;
    this.attachment_id = attachmentId;
    this.caption = caption;
  }
}

@Component({
  selector: 'tn-field-attachment',
  templateUrl: './field-attachment.component.html',
  styleUrls: ['./field-attachment.component.scss']
})
export class FieldAttachmentComponent implements OnInit {

  @Input() field: StoreReportCustomFieldsMapped;
  @Input() isInput: boolean = false;

  @Output() onFieldUpdate: EventEmitter<StoreReportCustomFieldsMapped> = new EventEmitter<StoreReportCustomFieldsMapped>(null);

  attachmentIds: string[];
  answers: FieldAttachmentParsedAnswer[];

  fileInputs: ParsedFile[];
  localAnsObj: FieldAttachmentParsedAnswer[] = [];
  localAnsIndex: number = 0;

  constructor(
    private _fileManagerService: FileManagerService,
    private _attachmentService: AttachmentService,
    private _fileUploaderService: FileUploaderService,
    private _imageHelperService: ImageHelperService,
    private _fileFactoryService: FileFactoryService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.field.answer.value) {
      this.attachmentIds = JSON.parse(this.field.answer.value);
      this.answers = _.map(this.attachmentIds, (attachment: any) => {
        let ans = new FieldAttachmentParsedAnswer;
        if (typeof attachment == "string") {
          ans.attachment_id = attachment;
        } else {
          ans.attachment_id = attachment.attachment_id;
          ans.caption = attachment.caption;
        }
        this._fileFactoryService.getFileByAttachmentId(
          ans.attachment_id,
          (imageUrl) => {
            ans.src = imageUrl;
          },
          (err) => {},
          false,
          false,
          true
        );
        return ans;
      });
    }
  }

  openAttachments(ans: FieldAttachmentParsedAnswer): void {
    _.each(this.answers, (ans) => {
      if (ans.caption) {
        this._imageHelperService.setImageCaptionById(ans.attachment_id, ans.caption);
      }
    });
    this._attachmentService.prepareAttachmentModalContentByFileId(
      _.map(this.answers, 'attachment_id'), 
      ans.attachment_id
    );
  }

  openFileUploader() {
    this._fileUploaderService.openFileUploaderModal(
      null,
      (files: ParsedFile[]) => {
        _.each(files, (file) => {
          this.localAnsObj.push(new FieldAttachmentParsedAnswer(file, null, ""));
        });
        this.onChange();
      },
      null,
      true
    );
  }

  removeAttachment(ansObj) {
    this.localAnsObj = _.without(this.localAnsObj, ansObj);
    this.onChange();
  }

  // getAnswerValue(): any {
  //   // return _.map(this.fileInputs, (f) => {
  //   //   return new FieldAttachmentParsedAnswer(f.file, null, f.file.caption);
  //   // });
  //   return this.localAnsObj;

  // }
  getAnswerFile(): any {
    return _.map(this.localAnsObj, (ans) => {
      return ans.file.file;
    });
  }
  getCaptionValue(): any {
    return _.map(this.localAnsObj, (ans) => {
      return ans.caption;
    });
  }

  onChange() {
    this.field.answer.value = this.getCaptionValue();
    this.field.answer.files = this.getAnswerFile();
    this.onFieldUpdate.emit(this.field);
  }

}
