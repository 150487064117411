import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WorkflowCustomRouteParam, WorkflowTeam, WorkflowType } from '../model/workflow';
import { WorkflowService } from '../services/workflow.service';

import * as _ from 'lodash';

@Component({
  selector: 'tn-form-option',
  templateUrl: './form-option.component.html',
  styleUrls: ['./form-option.component.scss']
})
export class FormOptionComponent implements OnInit {

  @Input() customParam: WorkflowCustomRouteParam;

  @Input() team: WorkflowTeam;
  @Output() teamChange: EventEmitter<WorkflowTeam> = new EventEmitter<WorkflowTeam>(null);

  @Input() type: WorkflowType;
  @Output() typeChange: EventEmitter<WorkflowType> = new EventEmitter<WorkflowType>(null);

  @Output() onFinishSelect: EventEmitter<any> = new EventEmitter<any>(null);

  teams: WorkflowTeam[] = [];
  types: WorkflowType[] = [];
  isShowTypeOption: boolean = false;

  constructor(
    private _workflowService: WorkflowService
  ) { }

  ngOnInit() {
    this._workflowService.getWorkflowOptions(
      (resp) => {
        this.teams = this._workflowService.teams;
        this.isShowTypeOption = true;

        if (this.customParam) {
          this.types = [this._workflowService.getTypeByTypeId(this.customParam.typeId)];
          this.type = this.types[0];
          this.isShowTypeOption = false;

          this.teams = _.filter(this.teams, (team: WorkflowTeam) => {
              return this.type.enabled_team_ids.indexOf(team.team_id) != -1;
          });
        }

        // Try to select the ONLY available team for user
        if (this.teams.length == 1) {
          this.team = this.teams[0];
          this.onTeamSelection(this.team);

          // If no need to select type manually, done directly
          if (!this.isShowTypeOption) {
            this.formOptionDone();
          }
        }
      }
    );
  }

  onTeamSelection(team: WorkflowTeam): void {
    // If it has custom type param, no need to calculate the visible types for the selected team.
    if (this.customParam) {
      return;
    }
    this.types = _.filter(
      this._workflowService.getAvailableTypeByTeamTemplateId(team.template_id), 
      (type: WorkflowType) => {
        return type.visible_in_app_menu == 0 && team.enabled_form_type_ids.indexOf(type.type_id) != -1;
      }
    );
    this.isShowTypeOption = true;
    this.type = null;
  }

  formOptionDone(): void {
    this.teamChange.emit(this.team);
    this.typeChange.emit(this.type);
    this.onFinishSelect.emit(true);
  }

}
