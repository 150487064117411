export const TeamNoteQuestionnaireConstant = {
  QUESTION_TYPE: {
    MC: 1,
    FREE_TEXT: 2,
    CHECKBOX: 3,
    RATING: 4,
    ATTACHMENT: 5,
  },
  EXTRA_FREE_TEXT: {
    NONE: "none",
    OPTIONAL: "optional",
    MANDATORY: "mandatory"
  }
}
