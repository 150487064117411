import { HttpClient, HttpErrorResponse, HttpResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as _ from 'lodash';
import { tap, timeout } from 'rxjs/operators';
import { TeamnoteConfigService } from '../configs/teamnote-config.service';
import { TeamNoteApiConstant } from '../constants/api.constant';
import { TeamNoteGeneralConstant } from '../constants/general.constant';
import { TeamNoteLocalStorageKeyConstants } from '../constants/local-storage-key.constant';
import { LocalStorageManagerService } from '../utilities/local-storage/local-storage-manager.service';
import { LoggerService } from '../utilities/logger/logger.service';
import { TnLoaderService } from '../utilities/tn-loader/tn-loader.service';
import { AccountService } from '../account/account.service';
import { TnNotificationService } from '../utilities/tn-notification/tn-notification.service';

import addDays from 'date-fns/add_days';
import { UtilitiesService } from '../utilities/service/utilities.service';

@Injectable()
export class TeamnoteApiService {

  isSecondLoginAttempt = false;

  constructor(
    private _http: HttpClient,
    private _accountService: AccountService,
    private _tnLoaderService: TnLoaderService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _loggerService: LoggerService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _tnNotificationService: TnNotificationService,
    private _utilitiesService: UtilitiesService,
  ) {

  }

  private showLoading(): void {
    this._tnLoaderService.showLoading();
  }
  private hideLoading(): void {
    this._tnLoaderService.hideLoading();
  }

  callApiGet(url: string, success: Function, failure: Function, isAttachment?: boolean) {
    this.showLoading();

    url = this._teamnoteConfigService.config.HOST.API_HOST + url;
    let option = {};
    if (isAttachment) {
      option = {
        observe: 'response',
        responseType: 'arraybuffer'
      };
    }

    return this._http
      .get(url, option)
      .pipe(tap(() => this.hideLoading(), () => this.hideLoading()))
      .subscribe(
        (resp: HttpResponse<any>) => {
          this._loggerService.info(_.join([url, JSON.stringify(resp)], '\n'));
          success(resp);
        },
        (error: HttpErrorResponse) => {
          this._loggerService.error(_.join([url, error.status + '-' + error.statusText + '-' + error.error], '\n'));
          failure(error);
        }
      );
  }

  callApi(url: string, params: any, success: Function, failure: Function,
    isNoNeedToken?: boolean, isTextResponse?: boolean, isBackgroundTask?: boolean, isAttachment?: boolean, i18nKey?: string) {
    if (!isBackgroundTask) {
      this.showLoading();
    }

    const hostUrl = this._teamnoteConfigService.config.HOST.API_HOST + url;

    params.company_domain = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.DOMAIN);

    if (!isNoNeedToken) {
      params.access_token = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN);
    }

    const isLoginApi = _.indexOf(_.toArray(TeamNoteApiConstant.LOGIN), url) !== -1;

    let option = {};
    if (isTextResponse) {
      option = {
        responseType: 'text'
      };
    }
    if (isAttachment) {
      option = {
        observe: 'response',
        responseType: 'arraybuffer'
      };
    }
    if (i18nKey){
      option = {
        headers: 'Accept-Language: ' + i18nKey
      }
    }
    return this._http
      .post(hostUrl, params, option)
      .pipe(
        tap(() => this.hideLoading(), () => this.hideLoading(), () => this.hideLoading())
      )
      // .pipe(
      //   timeout(500)
      // )
      .subscribe(
        (resp: HttpResponse<any>) => {
          delete params.hashed_password;
          delete params.password;
          this._loggerService.info(_.join([url, JSON.stringify(params), JSON.stringify(resp)], '\n'));
          success(resp);
        },
        (error: HttpErrorResponse) => {
          // console.log(error)
          // console.log(JSON.stringify(error))
          delete params.hashed_password;
          delete params.password;
          this.hideLoading();
          this._loggerService.error(_.join([url, JSON.stringify(params),
            error.name + '-' + error.status + '-' + error.statusText + '-' + error.error], '\n'));

          if (!isNoNeedToken && !isLoginApi && error.error && error.error.indexOf
            && error.error.indexOf(TeamNoteGeneralConstant.UNAUTHORIZED_ACCESS_ERROR) !== -1) {
            // try to relogin
            this.reLogin(
              () => {
                this.callApi(url, params, success, failure, isNoNeedToken, isTextResponse, isBackgroundTask, isAttachment);
              }
            );
          } else if (error.name.toString() === 'TimeoutError') {
            this._loggerService.error(_.join(['API Request time out', url,
              JSON.stringify(params), error.name + '-' + error.status + '-' + error.statusText + '-' + error.error], '\n'));
            setTimeout(() => {
              this.callApi(url, params, success, failure, isNoNeedToken);
            }, 1000 * 10);
          } else {
            failure(error);
          }
        }
      );
  }

  // ----- File -----
  upload(file: File, success: Function, failure: Function) {
    this.showLoading();

    const url = this._teamnoteConfigService.config.HOST.API_HOST + TeamNoteApiConstant.UPLOAD;
    const fd = new FormData();
    fd.append('file', file, file.name);
    fd.append('token', this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN));
    return this._http
      .post(url, fd, { responseType: 'text' })
      .pipe(tap(() => this.hideLoading(), () => this.hideLoading()))
      .subscribe(
        (resp) => {
          this._loggerService.info(_.join([url, file.name, resp], '\n'));
          success(resp);
        },
        (error: HttpErrorResponse) => {
          this._loggerService.error(_.join([url, file.name, error.status + '-' + error.statusText + '-' + error.error], '\n'));
          if (error.error && error.error.indexOf(TeamNoteGeneralConstant.UNAUTHORIZED_ACCESS_ERROR) !== -1) {
            // try to relogin
            this.reLogin(
              () => {
                this.upload(file, success, failure);
              }
            );
          } else {
            failure(error);
          }
        }
      );
  }

  getFileByAttachmentId(attachmentId: string, successWithResponse: Function, failure: Function) {
    const url = this._teamnoteConfigService.config.HOST.API_HOST + '/static/' + attachmentId;
    const params = {
      access_token: this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN)
    };

    if (!this._teamnoteConfigService.config.GENERAL.IS_RESTRICTED_ATTACHMENT) {
      // Not restricted, use GET
      return this._http.get(url, { observe: 'response', responseType: 'arraybuffer' })
        .subscribe(
          (resp: HttpResponse<any>) => {
            successWithResponse(resp);
          },
          (err: HttpErrorResponse) => {
            failure(err);
          }
        );
    } else {
      // Is restricted, use POST with access_token
      return this._http.post(url, params, { observe: 'response', responseType: 'arraybuffer' })
        .subscribe(
          (resp: HttpResponse<any>) => {
            successWithResponse(resp);
          },
          (err: HttpErrorResponse) => {
            failure(err);
          }
        );
    }
  }

  getAttachmentById(attachmentId: string, fileName?: string): void {
    const url = '/static/' + attachmentId;
    this.callApiGet(
      url,
      (resp: HttpResponse<any>, param1, param2, param3, param4) => {
        let exportName: string;
        let name: string;
        const disposition = resp.headers.get('Content-Disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            exportName = matches[1].replace(/['"]/g, '');
          }
        }

        const type = resp.headers.get('Content-Type');

        const blob = new Blob([resp.body], { type: type });

        if (fileName) {
          const lastDot = exportName.lastIndexOf('.');
          name = fileName + exportName.substr(lastDot);
        } else {
          name = exportName;
        }

        FileSaver.saveAs(blob, name);
      },
      (err) => {

      },
      true
    );
  }

  getFileByUrl(url: string, params: any, success: Function, failure: Function): void {
    this.callApi(
      url,
      params,
      (resp: HttpResponse<any>, param1, param2, param3, param4) => {
        let exportName: string;
        // let name: string;
        const disposition = resp.headers.get('Content-Disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            exportName = matches[1].replace(/['"]/g, '');
          }
        }

        const type = resp.headers.get('Content-Type');

        const blob = new Blob([resp.body], { type: type });

        success(blob, exportName);
      },
      (err) => {

      },
      false,
      false,
      false,
      true
    );
  }

  // ----- Refresh Session Token -----
  logoutWebClient(isDisconnectByServer) {
    // redirect to onLogoutWebClient in webclientService
  }
  reLogin(success: Function, failure?: Function, passwordInput?: string, encryptedPassword?: string, isNeedPassword: boolean = false) {
    // TODO: have config for allowing auto relogin or not
    this._loggerService.debug('Trying to relogin webclient...');

    const reloginFailure = () => {
      this._loggerService.error('Re-login failed...');
      if (failure) {
        failure();
      } else {
        this.logoutWebClient(true);
        this._tnNotificationService.showAlert(
          null,
          'GENERAL.SESSION_TIMEOUT_MSG',
          null,
          'GENERAL.CONFIRM',
          () => {
            location.reload();
          }
        );
      }
    };

    const userName = this._accountService.userName;
    const password = passwordInput ? passwordInput :
      this._localStorageManagerService.getLocalStorageByKey(TeamNoteLocalStorageKeyConstants.SESSION.PASSWORD);

    if (userName) {
      const params = {
        device_token: this._localStorageManagerService.getDeviceToken(),
        // if chat room is in confidential(highest) level, then need to relogin with password, 
        // otherwise relogin with refresh_token and any password input
        refresh_token: isNeedPassword ? null : this._localStorageManagerService.getSessionStorageByKey(TeamNoteLocalStorageKeyConstants.SESSION.REFRESH_TOKEN),
        require_refresh_token: 1,
        user_name: userName,
        hashed_password: password,
        encrypted_password: encryptedPassword
      };

      const url = TeamNoteApiConstant.LOGIN.WEBCLIENT_LOGIN;
      this.callApi(
        url,
        params,
        resp => {
          if (resp._jwt) {
            this.getE2EEPublicKey(
              (publicKey) => {
                this._utilitiesService.decodeJwt(resp._jwt, publicKey,
                  // tslint:disable-next-line:no-shadowed-variable
                  (resp) => {
                    if (resp.success) {
                      this._localStorageManagerService.setCookiesByKey(
                        TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN, resp.session_token, addDays(new Date(), 30));
                      this._accountService.setUserAccount(resp.user.name, resp.user.pic, resp.user.user_id, resp.user.user_name);
                      this._accountService.setFullLoginResponse(resp);
                      success();
                    } else {
                      reloginFailure();
                    }
                  }
                );
              },
              () => {
                reloginFailure();
              }
            );
          } else {
            if (resp.success) {
              // if (!passwordInput) {
              //   this._localStorageManagerService.setCookiesByKey
              //      (TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN, resp.session_token);
              //   // this._accountManagerService.accessToken = resp.session_token;
              //   // this._accountManagerService.isLoggedIn = true;
              //   // this._accountManagerService.fullLoginResponse = resp;
              // }
              this._localStorageManagerService.setCookiesByKey(
                TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN, resp.session_token, addDays(new Date(), 30));
              this._accountService.setUserAccount(resp.user.name, resp.user.pic, resp.user.user_id, resp.user.user_name);
              this._accountService.setFullLoginResponse(resp);
              success();
            } else {
              reloginFailure();
            }
          }
        },
        err => {
          reloginFailure();
        },
        true
      );
    } else {
      reloginFailure();
    }
  }

  // ----- Get Module -----
  getModule(accessToken: string, success: Function, failure: Function) {
    const params = {
      access_token: accessToken
    };
    const url = TeamNoteApiConstant.MODULE;
    this.callApi(url, params, success, failure, true);
  }

  // ----- webdav Module -----
  getModuleConfig(accessToken: string, success: Function, failure: Function) {
    const params = {
      access_token: accessToken
    };
    this.callApi('/module/config', params, success, failure, true, null, true);
  }

  getAvailableFeatures(accessToken: string, success: Function, failure: Function) {
    const params = {
      access_token: accessToken
    };
    this.callApi('/super_connector', params, success, failure, true, null, true);
  }


  // ----- Get Public Key -----
  getE2EEPublicKey(success: Function, failure: Function): void {
    const url = '/e2ee/public';
    const params = {};
    this.callApi(url, params, success, failure, true, true);
  }
}
