import { TeamNoteConfig } from "../../configs/models/config";

export const DemoConfig: TeamNoteConfig = {
  GENERAL: {
    IS_CUSTOM_DOAMIN: true
  },
  WEBCLIENT: {
    CHATROOM: {
      IS_ENABLE_MENTION: true,
      IS_ENABLE_HASHTAG: true,
      IS_ENABLE_SEARCH: true,
      ENABLE_WHISPER: true
    }
  }
}