import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { AccountService } from "../../../account/account.service";
import { WebclientLoginResponse } from "../../../login/models/webclient-login-response";
import { UserContact } from '../../../models/user-contact';
import _ from 'lodash'

@Injectable()
export class AccountManagerService {
  isLoggedIn: boolean = false;
  userName: string = '';
  userId: string = '';
  accessToken: string = '';

  selfContactPresence: UserContact = null;
  selfContactPresence$: BehaviorSubject<UserContact> = new BehaviorSubject<UserContact>(null);

  fullLoginResponse: Partial<WebclientLoginResponse> = null;

  constructor(
    private _accountService: AccountService
  ) {
    this.init();
  }

  init(): void {
    this.isLoggedIn = false;
    this.userName = '';
    this.userId = '';
    this.accessToken = '';
    this.selfContactPresence = null;
    this.selfContactPresence$ = new BehaviorSubject<UserContact>(null);
    this.fullLoginResponse = null;
  }

  onLogout(): void {
    this.init();
  }

  updateSelfContactPresence(u): void {
    this.userName = u.name;
    this.selfContactPresence = u;
    this.updateSelfContactPresenceSubject();
    this._accountService.setUserAccount(u.name, u.pic, u.user_id);
  }
  updateSelfContactPresenceSubject(): void {
    this.selfContactPresence$.next(this.selfContactPresence);
  }

  storeLoginResponse(resp: WebclientLoginResponse): void {
    this.accessToken = resp.session_token;
    this.isLoggedIn = true;
    this.fullLoginResponse = resp;
  }

  getAccountFieldByFieldName(fieldName: string): string {
    return this.fullLoginResponse.user[fieldName] ? this.fullLoginResponse.user[fieldName] : null;
  }

  getLoginFieldByFieldName(fieldName: string): number {
    if (!this.fullLoginResponse) {
      return null;
    }
    return this.fullLoginResponse[fieldName] ? this.fullLoginResponse[fieldName] : null;
  }
}
