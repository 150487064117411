<div class="tn-content" (click)="sendRead()">
  <div class="tn-content-full">
    <tn-page-title [title]="pageTitle ? pageTitle : ('WEBCLIENT.MENU.' + pageTitleKey | translate)"></tn-page-title>
    <div class="news-list" #newsElement>
      <ng-container *ngIf="isCarousel">
        <tn-news-carousel
          [isCarousel]="isCarousel"
          [isEnableEmoji]="isEnableEmoji"
          [isEnableComment]="isEnableComment"
          [isInModal]="isInModal"
          [isEnableNewsHttp]="isEnableNewsHttp"
          [newsHttp]="carouselNews"
          [newsCategory]="carouselCategory"
        >
        </tn-news-carousel>
      </ng-container>
      <div class="news-category-tabs" *ngIf="isEnableNewsHttp && newsCategoryList.length > 0">
        <div
          class="tab"
          *ngFor="let category of newsCategoryList; let i = index"
          [ngClass]="{ tab_selected: currentCategory === i }"
          (click)="selectCategory(i)"
        >
          <div class="title">
            {{ category.name }}
          </div>
        </div>
      </div>
      <!-- <div class="news-list" #newsElement> -->
      <ng-container *ngIf="newsHttp.length > 0">
        <tn-news-cell
          *ngFor="let news of newsHttp"
          [newsHttp]="news"
          [isEnableEmoji]="isEnableEmoji"
          [isEnableComment]="isEnableComment"
          [isInModal]="isInModal"
          [isEnableNewsHttp]="isEnableNewsHttp"
          [isEnableNewsContentPreview]="isEnableNewsContentPreview"
        >
        </tn-news-cell>
      </ng-container>
      <ng-container *ngIf="newsMessages.length > 0">
        <tn-news-cell
          *ngFor="let message of newsMessages"
          [message]="message"
          [isEnableEmoji]="isEnableEmoji"
          [isEnableComment]="isEnableComment"
          [isInModal]="isInModal"
          [isEnableNewsContentPreview]="isEnableNewsContentPreview"
        >
        </tn-news-cell>
      </ng-container>

      <div class="loading-history-indicator" *ngIf="isLoadingHistory || isSwitchingCategory">
        <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
      </div>

      <tn-empty-page *ngIf="newsMessages.length == 0 && newsHttp.length == 0"></tn-empty-page>
    </div>
  </div>
</div>
