<div class="tn-content">
  <div class="tn-content-left current-focus-content">
    <tn-training-list
      [folder]="currentFolder"
      (onItemClick)="onItemClick($event)"
      (onFolderBack)="onFolderBack($event)">
    </tn-training-list>
  </div>
  <div class="tn-content-right current-unfocus-content">
    
  </div>
</div>