<tn-report-info-header
  [store]="store"
  [type]="type">
</tn-report-info-header>

<div class="report-body">
  <tn-custom-field
    [(field)]="mappedInputCustomFields[i]"
    [isInput]="true"
    [store]="store"
    [report]="report"
    (onFieldChange)="onFieldChange($event)"
    *ngFor="let field of mappedInputCustomFields; let i = index">
  </tn-custom-field>

</div>
