import { Component, OnInit, Input } from '@angular/core';
import { TextMessagePart } from '../../../../utilities/linkify/linkify.service';
import { CustomFieldsMapped, CustomFieldMagicStringMapping } from '../models/custom-field';
import { FieldHelperService } from '../helper/field-helper.service';

import * as _ from 'lodash';

export class FieldTextDisplayDetail {
  description: string;
}

@Component({
  selector: 'tn-field-text-display',
  templateUrl: './field-text-display.component.html',
  styleUrls: ['./field-text-display.component.scss']
})
export class FieldTextDisplayComponent implements OnInit {

  @Input() field: CustomFieldsMapped;
  @Input() allCustomFields: CustomFieldsMapped[];
  @Input() staticStringMappings: CustomFieldMagicStringMapping;

  detail: FieldTextDisplayDetail;

  title: string = "";
  description: string = "";

  descriptionFormatDisplayArr: TextMessagePart[] = [];

  constructor(
    // private _cellFormatHelperService: CellFormatHelperService
    private _fieldHelperService: FieldHelperService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    try {
      this.detail = JSON.parse(this.field.definition.detail);
    } catch (err) {
      this.detail = {description: null};
    }

    this.title = this.field.definition.name;
    if (this.detail.description) {
      this.description = this._fieldHelperService.handleListFormatOfCustomField(
        this.detail.description,
        _.map(this.allCustomFields, "definition"),
        _.map(this.allCustomFields, "answer"),
        true,
        this.staticStringMappings
      );
      this.handleSpcialLocalTranslateStrings();
    }
  }

  handleSpcialLocalTranslateStrings(): void {
    this.descriptionFormatDisplayArr = this._fieldHelperService.handleSpcialLocalTranslateStrings(this.description);
  }
}
