import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StoreReport, JobDispatch } from '../models/job-report';

@Component({
  selector: 'tn-job-dispatch-list',
  templateUrl: './job-dispatch-list.component.html',
  styleUrls: ['./job-dispatch-list.component.scss']
})
export class JobDispatchListComponent implements OnInit {

  @Input() jobDispatchs: JobDispatch[];
  @Input() viewingJob: JobDispatch;
  @Input() isEnabledJobDispatch: boolean;
  @Output() onJobDispatchClick: EventEmitter<JobDispatch> = new EventEmitter<JobDispatch>(null);

  scrollItems: any;

  constructor() { }

  ngOnInit() {
  }

  onJobCellClick(job: JobDispatch) {
    this.onJobDispatchClick.emit(job);
  }

}
