import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TnNotificationService } from './tn-notification.service';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { TranslateModule } from '@ngx-translate/core';
import { AlertComponent } from './alert/alert.component';
import { PromptComponent } from './prompt/prompt.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule
    ],
    providers: [],
    declarations: [ConfirmationComponent, AlertComponent, PromptComponent]
})
export class TnNotificationModule { }
