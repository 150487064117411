import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { TeamnoteConfigService } from '../../configs/teamnote-config.service';

declare var google; 

@Injectable()
export class LocationService {

  constructor(
    private _teamnoteConfigService: TeamnoteConfigService
  ) { }

  /**
   * Sample Return 
   * 'https://maps.googleapis.com/maps/api/staticmap?key='+ MAP_API_KEY +'&center=' + latLng + '&size=100x100&zoom=15&markers=' + latLng
   * 
   * @param {number} lat 
   * @param {number} lng 
   * @returns {string} 
   * @memberof LocationService
   */
  getLocationPreviewSrc(lat: number, lng: number): string {
    let latLng = lat + ',' + lng;
    return 'url(' + 
    this._teamnoteConfigService.config.MAP.STATIC_MAP.PATH_PREFIX + 
      '?key=' + this._teamnoteConfigService.config.MAP.API_KEY + 
      '&center=' + latLng + 
      '&size=' + this._teamnoteConfigService.config.MAP.STATIC_MAP.SIZE + 
      '&zoom=' + this._teamnoteConfigService.config.MAP.STATIC_MAP.ZOOM_LEVEL + 
      '&markers=' + latLng +
      ')';
  }

  getMapDefaultLocation() {
    return this._teamnoteConfigService.config.MAP.DEFAULT_LOCATION;
  }
  getMapDefaultZoom() {
    return this._teamnoteConfigService.config.MAP.DEFAULT_ZOOM_LEVEL;
  }

  getCurrentLocation(callback: Function) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          callback({
            LAT: pos.coords.latitude,
            LNG: pos.coords.longitude
          });
        },
        (err) => {
          callback(this.getMapDefaultLocation());
        }
      );
    } else {
      callback(this.getMapDefaultLocation());      
    }
  }
  getCurrentLocationMapZoom() {
    return _.toInteger(this._teamnoteConfigService.config.MAP.STATIC_MAP.ZOOM_LEVEL);
  }

  getLocationAddressByLatLng(latLng: any, callback: Function) {
    if (!google || google == undefined) {
      return;
    }
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({'location': latLng}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          var result = results[0].formatted_address;
          callback(result);
        } else {
          callback("");
        }
      } else {
        callback("");
      }
    });
  }

}
