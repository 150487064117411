export const ZhChsCustomFieldStrings = {
  LIST_FORMAT: {
    IMAGE: {
      ONE: "图片",
      OTHER: " 图片"
    },
    FILE: {
      ONE: "文件",
      OTHER: " 文件"
    },
    USER: {
      ONE: " 用户",
      OTHER: " 用户"
    },
    ATTACHMENT: {
      ONE: " 附件",
      OTHER: " 附件"
    },
    SIGNATURE: {
      SIGNED: "已签名",
      NOT_SIGNED: "没有签名"
    }
  },

  ATTACHMENT: {
    SELECT_TN_CORPORATE_MATERIAL: "从团信档案分享中选择"
  }
}