<div class="chat-list-container">

  <tn-page-title [title]="'WEBCLIENT.MENU.CHATS' | translate">
    <div class="right-control">

      <div class="btn-group tn-dropdown-group" dropdown placement="bottom right">
        <button dropdownToggle type="button" class="dropdown-toggle tn-dropdown-button">
          <i class="fa fa-angle-down fa-fw"></i>
        </button>
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
          <li role="menuitem">
            <div class="tn-dropdown-item" 
              (click)="openCreateGroupModal()"
              *ngIf="IS_ALLOW_CREATE_GROUP">
              {{'WEBCLIENT.CHAT.NEW_GROUP' | translate}}
            </div>
          </li>
          <li role="menuitem">
            <div class="tn-dropdown-item" 
              (click)="openBroadcastModal()"
              *ngIf="IS_ALLOW_BROADCAST">
              {{'WEBCLIENT.CHAT.BROADCAST' | translate}}
            </div>
          </li>
          <li role="menuitem">
            <div class="tn-dropdown-item" (click)="searchAllMessage()">
              {{'WEBCLIENT.CHAT.SEARCH_MESSAGE' | translate}}
            </div>
          </li>
          <li role="menuitem">
            <div class="tn-dropdown-item" (click)="toggleDisplayMethod(true)">
              {{'WEBCLIENT.CHAT.SORT_GROUP' | translate}}
              <i class="fa fa-check fa-fw" *ngIf="isSortByGroup"></i>
            </div>
          </li>
          <li role="menuitem">
            <div class="tn-dropdown-item" (click)="toggleDisplayMethod(false)">
              {{'WEBCLIENT.CHAT.SORT_TIME' | translate}}
              <i class="fa fa-check fa-fw" *ngIf="!isSortByGroup"></i>
            </div>
          </li>
        </ul>
      </div>
      
    </div>
  </tn-page-title>

  <tn-search-bar 
    #searchBar
    class="tn-search-bar" 
    (onKeywordChanged)="updateChatList($event)">
  </tn-search-bar>

  <div class="tn-chat-list-wrapper">
    <div
      virtualScroller 
      #chatList
      class="tn-chat-list"
      *ngIf="fullDisplayList.length > 0"
      [childHeight]="30"
      [bufferAmount]="10"
      [enableUnequalChildrenSizes]="true"
      [useMarginInsteadOfTranslate]="true"
      [items]="fullDisplayList" 
      (vsUpdate)="scrollItems = $event"
      (vsEnd)="searchKeyword && loadMore($event)">

      <ng-container *ngFor="let chat of scrollItems">
        <ng-container *ngIf="chat.isLabel; else chatCell">
          <div class="chat-category-label"
            [ngClass]="{'clickable': chat.allowCollapse}"
            (click)="toggleCategoryDisplay(chat.key, chat.allowCollapse)">
            <div class="category-name">
              {{chat.label | translate}} ({{chat.itemsLength}}<span *ngIf="chat.key === 'MESSAGE' && searchedMessages.length > 0">+</span>) 
              <div class="unread-marker unread-bubble" *ngIf="!chat.isShow && chat.unreadCounts > 0"></div>
            </div>

            <!-- <ng-container *ngIf="!isSearching"> -->
            <div
              class="see-more text-color-primary clickable"
              (click)="searchAllMessage(true)"
              *ngIf="chat.key === 'MESSAGE' && searchedMessages.length > 0"
            >
              <span>{{'WEBCLIENT.CHAT.SEE_MORE' | translate}}</span>
              <i class="fa fa-angle-right fa-fw"></i>
            </div>
            <!-- </ng-container> -->
            
            <div class="category-arrow" *ngIf="chat.allowCollapse">
              <i class="fa fa-angle-up fa-fw" *ngIf="chat.isShow"></i>
              <i class="fa fa-angle-down fa-fw" *ngIf="!chat.isShow"></i>
            </div>
          </div>
        </ng-container>

        <ng-template #chatCell>
          <tn-chat-cell 
            *ngIf="chat.chat_id"
            [chat]="chat" 
            [activeChat]="activeChat" 
            [isShowContextMenu]="true" 
            [isShowLastMsg]="true" 
            [isEnableImportantUsers]="isEnableImportantUsers" 
            (chatOnClick)="chatOnClick($event)">
          </tn-chat-cell>

          <tn-chat-message-cell
            class="global-search"
            *ngIf="chat.message_id"
            [isEnableImportantUsers]="isEnableImportantUsers"
            [keyword]="searchKeyword"
            [message]="chat"
            (messageOnClick)="messageOnClick($event)">
          </tn-chat-message-cell>
        </ng-template>
      </ng-container>
    </div>

    <!-- <div class="tn-chat-list search-message-list" *ngIf="searchedMessages.length > 0">
      <div class="chat-category-label">
        <div class="category-name">
          {{'WEBCLIENT.CHAT.HEADER_MESSAGE' | translate}}
        </div>
      </div>
      <ng-container *ngFor="let msg of searchedMessages">
        <tn-chat-message-cell
          [keyword]="searchKeyword"
          [message]="msg"
          (messageOnClick)="messageOnClick($event)">
        </tn-chat-message-cell>
      </ng-container>
    </div> -->
  </div>
</div>
