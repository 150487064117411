import { Component, OnInit } from '@angular/core';
import { TnLoaderService, TnLoaderState } from './tn-loader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'tn-loader',
  templateUrl: './tn-loader.component.html',
  styleUrls: ['./tn-loader.component.scss']
})
export class TnLoaderComponent implements OnInit {

  loadingState: TnLoaderState = {
    isLoading: false,
    isShowSpinner: false,
    message: '',
    isShowingAlert: false
  };

  private sub: Subscription;

  constructor(
    private _tnLoaderService: TnLoaderService
  ) { 
    this.sub = this._tnLoaderService.loader$.subscribe(state => {
      if (!state) {
        return;
      }
      setTimeout(() => {
        this.loadingState = state;
      }, 0);
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
