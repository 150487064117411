<div class="tn-login-wrapper base-bg">
  <div class="login-header login-page-header">
    <div class="tn-logo login-logo"> 
      <div class="tn-title">
        {{'GENERAL.TEAMNOTE' | translate}}
      </div>
    </div>
  </div>
  <div class="system-label" *ngIf="SYSTEM_LABEL">
    {{SYSTEM_LABEL}}
  </div>
  <div class="site-label" *ngIf="SITE_LABEL">
    {{SITE_LABEL}}
  </div>
  <div class="build-label" *ngIf="BUILD_LABEL">
    {{BUILD_LABEL}}
  </div>
  <div class="login-body-wrapper login-page-body">
    <div class="login-body">
      <router-outlet></router-outlet>
    </div>

    <div class="login-footer">
      <div class="version-number">
        <span>{{VERSION_NO}}</span>
      </div>
      <div class="copyright-wording">
        {{'GENERAL.POWER_BY_MSG' | translate}}
      </div>
    </div>
  </div>
</div>