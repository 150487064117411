import { Injectable } from '@angular/core';
import { ContactPickerComponent } from './contact-picker.component';
import { TnDialogService } from '../../utilities/tn-dialog/tn-dialog.service';

@Injectable()
export class ContactPickerService {

  constructor(
    private _tnDialogService: TnDialogService
  ) { }

  openContactPicker(
    header: string,
    isAnnouncement: boolean,
    isChat: boolean,
    isContact: boolean,
    callback: Function,
    isMultiple?: boolean,
    pickerAction?: any,
    selectionData?: any[],
    isUserGroup?: boolean,
    selectedContacts?: any[],
    isFilterMode?: boolean,
    isAllowSelectAll?: boolean
  ) {
    let dialogRef = this._tnDialogService.openTnDialog(ContactPickerComponent, {
      header: header,
      isAnnouncement: isAnnouncement,
      isChat: isChat,
      isContact: isContact,
      isMultiple: isMultiple,
      callback: callback,
      pickerAction: pickerAction,
      selectionData: selectionData,
      isUserGroup: isUserGroup,
      selectedContacts: selectedContacts,
      isFilterMode: isFilterMode,
      isAllowSelectAll: isAllowSelectAll
    })
  }

}
