import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '../date.service';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'tnDate'
})
export class TnDatePipe implements PipeTransform {

  constructor(
    private _dateService: DateService,
    private _translate: TranslateService,
  ) { }

  transform(timestamp: any, formatKey?: string): string {
    return this._dateService.getDateDisplayWithKey(timestamp, this._translate.instant(formatKey));
  }

}
