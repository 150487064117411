export const EnCustomFieldStrings = {
  LIST_FORMAT: {
    IMAGE: {
      ONE: "Image",
      OTHER: " Images"
    },
    FILE: {
      ONE: "File",
      OTHER: " Files"
    },
    USER: {
      ONE: " User",
      OTHER: " Users"
    },
    ATTACHMENT: {
      ONE: " Attachment",
      OTHER: " Attachments"
    },
    SIGNATURE: {
      SIGNED: "Signed",
      NOT_SIGNED: "Not signed"
    }
  },

  ATTACHMENT: {
    SELECT_TN_CORPORATE_MATERIAL: "Select from TeamNote Document Sharing"
  }
}