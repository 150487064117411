<div class="tn-content corporate-material full-height overflow-hidden">
  <div class="tn-content-left current-focus-content full-height" *ngIf="!isDialog || (isDialog && !currentViewingEbook)">
    <tn-corporate-material-list
      [folder]="currentFolder"
      [isRootFolder]="isRootFolder"
      [rootTranslateKey]="rootTranslateKey"
      (onItemClick)="onItemClick($event)"
      (onFolderBack)="onFolderBack($event)"
      (searchFolderByKeyword)="searchFolderByKeyword($event)">
    </tn-corporate-material-list>
  </div>

  <div class="tn-content-right current-unfocus-content full-height">
    <tn-material-ebook
      [ebook]="currentViewingEbook"
      (onEbookBack)="onEbookBack($evnet)"
      *ngIf="currentViewingEbook">
    </tn-material-ebook>
  </div>
</div>