<form *ngIf="!isExtAuth">
  <section class="form-block">
    <div class="login-body-row input-action-msg">
      {{'LOGIN.PASSWORD.NEW_PASSWORD_INPUT_TIP' | translate}}
    </div>
    <div class="login-body-row">
        <input #passwordInput class="form-control" type="password" [(ngModel)]="password" name="password" placeholder="{{'LOGIN.PASSWORD.NEW_PASSWORD' | translate}}" autocomplete="off">
    </div>
    <div class="login-body-row">
        <input #passwordConfirmInput class="form-control" type="password" [(ngModel)]="passwordConfirm" name="passwordConfirm" placeholder="{{'LOGIN.PASSWORD.CONFIRM' | translate}}" autocomplete="off">
    </div>
    <div class="login-body-row">
      <button class="btn tn-button-primary btn-block" (click)="submit()">{{'LOGIN.PASSWORD.SUBMIT' | translate}}</button>
    </div>
    <div class="login-body-row">
      <button class="btn tn-button-primary btn-block" (click)="back()">{{'GENERAL.BACK' | translate}}</button>
    </div>
  </section>
</form>
