import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationCenterComponent } from './notification-center.component';
import { CommonNgxBootstrapModule } from '../common-ngx-bootstrap/common-ngx-bootstrap.module';
import { NotificationBodyComponent } from './notification-body/notification-body.component';
import { NotificationCellComponent } from './notification-cell/notification-cell.component';
import { NotificationCenterService } from './services/notification-center.service';
import { TranslateModule } from '@ngx-translate/core';
import { CronjobNotificationComponent } from './notification-cell/cronjob-notification/cronjob-notification.component';
import { ChatNotificationComponent } from './notification-cell/chat-notification/chat-notification.component';
import { NewsNotificationComponent } from './notification-cell/news-notification/news-notification.component';

@NgModule({
  imports: [
    CommonModule,
    CommonNgxBootstrapModule,
    TranslateModule
  ],
  declarations: [NotificationCenterComponent, NotificationBodyComponent, NotificationCellComponent, CronjobNotificationComponent, ChatNotificationComponent, NewsNotificationComponent],
  exports: [NotificationCenterComponent],
  providers: [
    
  ]
})
export class NotificationCenterModule { }
