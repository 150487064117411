import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WorkflowCustomRouteParam, WorkflowTeam, WorkflowType, WorkflowTypeStage, WorkflowFormCustomFieldAnswer } from '../model/workflow';
import { CustomFieldsMapped, CustomFieldsAnswer, CustomFieldMagicStringMapping } from '../../shared/custom-field/models/custom-field';
import { FormHelperService } from '../helper/form-helper.service';
import { WorkflowService } from '../services/workflow.service';

import * as _ from 'lodash';
import { TnNotificationService } from '../../../utilities/tn-notification/tn-notification.service';
import { WorkflowCellFormatService } from '../helper/workflow-cell-format.service';
import { ContactPickerService } from '../../contact-picker/contact-picker.service';
import { UserContact } from '../../../models/user-contact';

@Component({
  selector: 'tn-form-submit',
  templateUrl: './form-submit.component.html',
  styleUrls: ['./form-submit.component.scss']
})
export class FormSubmitComponent implements OnInit {

  @Input() customParam: WorkflowCustomRouteParam;

  @Output() onBack: EventEmitter<boolean> = new EventEmitter<boolean>(null);

  team: WorkflowTeam = null;
  type: WorkflowType = null;

  isFinishSelection: boolean = false;

  activeStage: WorkflowTypeStage = null;
  mappedInputCustomFields: CustomFieldsMapped[] = [];
  staticStringMappings: CustomFieldMagicStringMapping = {};

  constructor(
    private _formHelperService: FormHelperService,
    private _workflowService: WorkflowService,
    private _tnNotificationService: TnNotificationService,
    private _workflowCellFormatService: WorkflowCellFormatService
  ) { }

  ngOnInit() {
  }

  formSubmitBack(): void {
    this.onBack.emit(false);
  }

  onFinishSelect(): void {
    this.activeStage = this._workflowService.getFirstStageOfType(this.type);

    // Check if user is PIC of first stage
    let isPIC = this._workflowService.checkIfUserIsPicOfStageByTypeIdAndStageIdAndTeamId(
      this.type.type_id,
      this.activeStage.stage_id,
      this.team.team_id
    );
    if (!isPIC) {
      this._tnNotificationService.showCustomWarningByTranslateKey(
        "WEBCLIENT.WORKFLOW.NEW_FORM.NO_PERMISSION",
        {name: this.type.name}
      );
      return;
    }

    this.isFinishSelection = true;

    // Prepare custom field inputs
    this.mappedInputCustomFields = this._formHelperService.prepareMappedInputFieldsByTypeAndStageId(
      this.type,
      this.activeStage.stage_id,
      []
    );
    this.staticStringMappings = this._workflowCellFormatService.getWorkflowStaticMagicStringMapping(null, this.team, this.type);

  }

  submit(): void {
    this._formHelperService.submitAWorkflowForm(
      this.mappedInputCustomFields,
      this.activeStage,
      this.type,
      this.team,
      () => {
        this.onBack.emit(true);
      }
    );
  }
}
