import { ZhChsLoginStrings } from "../login/strings/zh_chs-login.strings";
import { ZhChsWebclientString } from "../webclient/strings/zh_chs-webclient.string";
import { ZhChsCustomFieldStrings } from "../webclient/shared/custom-field/strings/zh_chs-custom-field.strings";
import { ZhChsDateStrings } from './date/zh_chs-date.strings';


export const ZhChsGeneralStrings = {
  LANGUAGE: "语言",
  TEAMNOTE: "团信",
  TERMS_AND_CONDITIONS: "服务条款",
  LOGOUT: "登出",

  POWER_BY_MSG: "Powered by TeamNote®",
  COPYRIGHT_MSG: "© 2021 TeamNote Limited. 版权所有，并保留一切权利。",

  TERMS_TITLE: "服务条款",
  TERMS_ACCEPT: "接受",
  TERMS_DECLINE: "拒绝",

  SYSTEM_ERROR: "发生错误，请稍后再试",
  RESOURCE_NOT_FOUND: "目标资源不存在",

  FILE_TIP: "支援格式: JPEG, JPG, PNG, PDF, DOC, XLS, PPT, MP4; 大小限制: {{SIZE}}MB",
  FILE_TIP_DYNAMIC: "支援格式: {{FORMATS}}; 大小限制: {{SIZE}}MB",
  FILE_TYPE_ERROR: "不支援此文件格式",
  FILE_SIZE_ERROR: "文件大小超过限制",
  NO_FILE: "请上载最少一个有效的文件",
  SELECT_FILE: "选择档案",
  SELECT_IMAGE: "选择图片",
  SIGN_HERE: "在此签名",

  FILE_OPEN_ERROR: "无法打开文件",
  FILE_PDF_PASSWORD: "此PDF受密码保护，因此无法打开",
  FILE_PDF_PASSWORD_REQUIRED: "请输入PDF密码",
  FILE_PDF_PASSWORD_ERROR: "错误的PDF密码",

  NO_CONTENT: "没有内容",
  NO_PHOTO: "没有相片",
  NO_VIDEO: "没有影片",
  NO_DOCUMENT: "没有文件",
  NO_KEYWORDS: "输入关键字查找信息",

  NOTIFICATION: "通知",
  NO_NOTIFICATION: "没有通知",
  NEW_MESSAGE_NOTIFICATION: "新讯息: {{CHAT}}",

  NEW_VERSION_MSG: "新版本已推出。请重新整理此页面。",
  SESSION_TIMEOUT_MSG: "您的使用期限已过，请重新登入。",

  DONE: "完成",
  EDIT: "编辑",
  DELETE: "删除",
  CANCEL: "取消",
  SAVE: "储存",
  BACK: "返回",
  CONFIRM: "确定",
  UPLOAD: "上载",
  EXPORT: "导出",
  IMPORT: "导入",
  DOWNLOAD: "下载",
  NAME: "名称",
  BROWSE_CAPS: "浏覧",
  REFRESH: "重新整理",
  ALL: "全部",
  SUBMIT: "提交",
  CLEAR: "清除",
  OR: "或",
  SEARCH: "搜寻",

  FROM: "由",
  TO: "至",
  PROCESSING: "处理中...",

  WEBSOCKET: {
    OFFLINE: '你已经处于离线状态, 1秒后重连...',
  },

  DOWNLOADED_TIP: '文档已下载'
};

export const ZhChsLoadingStrings = {
  LOADING: "载入中...",
  CONNECTING: "连接中...",
  RECONNECTING: "重新连接中...",
  LOADING_CONTACTS: "载入通讯录中..."
};

export const ZhChsUtilityStrings = {
  IMAGE_CAPTION_PLACEHOLDER: "添加照片描述...",
  FILE_UPLOAD_DRAG_FILE: "拖曳文件至此",

  CUSTOM_FIELD: ZhChsCustomFieldStrings
};

export const ZhChsLangStrings = {
  CANTONESE: "粤语",
  MANDARIN: "普通话"
};

export const ZhChsString = {
  GENERAL: ZhChsGeneralStrings,
  LOGIN: ZhChsLoginStrings,
  LOADING: ZhChsLoadingStrings,
  UTILITY: ZhChsUtilityStrings,
  LANG: ZhChsLangStrings,
  DATE: ZhChsDateStrings,

  WEBCLIENT: ZhChsWebclientString
}
