import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrainingComponent } from './training.component';
import { UtilitiesModule } from '../../utilities/utilities.module';
import { TrainingService } from './training.service';
import { TrainingListComponent } from './training-list/training-list.component';
import { TrainingFolderComponent } from './training-folder/training-folder.component';
import { TrainingFileComponent } from './training-file/training-file.component';

@NgModule({
  imports: [
    CommonModule,
    UtilitiesModule
  ],
  providers: [
    TrainingService
  ],
  declarations: [TrainingComponent, TrainingListComponent, TrainingFolderComponent, TrainingFileComponent]
})
export class TrainingModule { }
