import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomFieldConstant } from '../../../shared/custom-field/constants/custom-field.constants';
import { Question } from '../../models/questionnaire';

import { ParsedFile } from '../../../../utilities/file-uploader/file-uploader.component';
import { AttachmentService } from '../../../../utilities/attachment/attachment.service';
import { FileUploaderService } from '../../../../utilities/file-uploader/file-uploader.service';
import { ImageHelperService } from '../../../../utilities/image-helper/image-helper.service';
import { FileFactoryService } from '../../../../utilities/file-factory/file-factory.service';

import * as _ from 'lodash';
import { AttachmentTypeConstant } from '../../../../constants/attachment-type.constant';
import { FileManagerService } from '../../../../utilities/file-manager/file-manager.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ImageModalComponent } from './image-modal/image-modal.component';

export class FieldAttachmentDetail {
  allow_type: {
    photo: number;
    video: number;
    file: number;
  }
}

export class FieldAttachmentParsedAnswer {
  attachment_id: string;
  src: string;
  description: string;
  name: string;
  file: ParsedFile;
  type?: number;

  constructor(file?: ParsedFile, attachmentId?: string, description?: string, name?: string, type?: number) {
    this.file = file;
    this.attachment_id = attachmentId;
    this.description = description;
    this.name = name;
    this.type = type;
  }
}

export const FieldAttachmentTypeMapping = {
  photo: 1,
  video: 3,
  file: 4,
  audio: 2
}

@Component({
  selector: 'tn-question-attachment',
  templateUrl: './question-attachment.component.html',
  styleUrls: ['./question-attachment.component.scss']
})
export class QuestionAttachmentComponent implements OnInit {

  @Input() question: Question;
  @Output() questionChange: EventEmitter<Question> = new EventEmitter<Question>(null);

  @Input() isInput: boolean = true;

  detail: FieldAttachmentDetail;
  validFileExtensions: string[] = [];
  allowAttachmentType: string[] = ['photo']
  attachmentTypes = FieldAttachmentTypeMapping;

  answers: FieldAttachmentParsedAnswer[];

  fileInputs: ParsedFile[];
  localAnsObj: FieldAttachmentParsedAnswer[] = [];
  localAnsIndex: number = 0;

  isAllowCorporateMaterial: boolean = false;

  FIELD_TYPE = CustomFieldConstant.CUSTOM_FIELD_TYPE;

  constructor(
    private _attachmentService: AttachmentService,
    private _fileUploaderService: FileUploaderService,
    private _imageHelperService: ImageHelperService,
    private _fileFactoryService: FileFactoryService,
    private _fileManagerService: FileManagerService,
    private _matDialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getAllValidExtensions();
  }

  ngOnChanges() {
    this.getAllValidExtensions();
  }

  getAllValidExtensions(): void {
    this.validFileExtensions = [];

    _.each(this.allowAttachmentType, (type) => {
      let extensions = this._fileManagerService.getAvailableFileExtensionByType(this.attachmentTypes[type]);
      this.validFileExtensions = _.union(this.validFileExtensions, extensions);
    });
  }

  addToLocalAns(file: ParsedFile, attachmentId: string, description: string, name: string): void {
    this.localAnsObj.push(
      new FieldAttachmentParsedAnswer(
        file, 
        attachmentId, 
        description, 
        name,
        this._fileManagerService.getAttachmentType(attachmentId ? attachmentId : name)
      )
    );
  }

  sortFileByType(): void {
    this.localAnsObj = _.orderBy(this.localAnsObj, ["type", "name"]);
  }

  openFileUploader(): void {
    this._fileUploaderService.openFileUploaderModal(
      null,
      (files: ParsedFile[]) => {
        _.each(files, (file) => {
          this.addToLocalAns(file, "", "", file.name);
        });
        this.sortFileByType();
        this.onChange();
      },
      null,
      true,
      this.validFileExtensions,
      [],
      this.validFileExtensions
    );
  }

  removeAttachment(ansObj): void {
    this.localAnsObj = _.without(this.localAnsObj, ansObj);
    this.onChange();
  }

  getAnswerValue(): any {
    return this.localAnsObj;
  }

  onChange() {
    this.question.value = this.getAnswerValue()
    this.question.ansAttachment = this.getAnswerValue()
    this.questionChange.emit(this.question);
  }

  openAttachments(ans: FieldAttachmentParsedAnswer): void {
    switch (ans.type) {
      case this.attachmentTypes.photo:
        this.openAttachmentPhoto(ans);
        break;
      case this.attachmentTypes.video:
      case this.attachmentTypes.audio:
        this.openAttachmentVideo(ans);
        break;
      case this.attachmentTypes.file:
        this.openAttachmentFile(ans);
        break;
    }
  }

  openAttachmentPhoto(ans: FieldAttachmentParsedAnswer): void {
    const dialogRef = this._matDialog.open(ImageModalComponent, {
      width: '60vw',
      autoFocus: false,
      data: { imgSrc: URL.createObjectURL(ans.file.file) }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }

  openAttachmentVideo(ans: FieldAttachmentParsedAnswer): void {
    this._attachmentService.prepareAttachmentModalContentByFileId(
      [ans.attachment_id]
    );
  }

  openAttachmentFile(ans: FieldAttachmentParsedAnswer): void {
    this._attachmentService.prepareAttachmentModalContentByFileId(
      [ans.attachment_id]
    );
  }
}
