export class CustomFieldsDefinition {
  detail: string;
  field_id: string;
  field_index: number;
  field_type: number;
  is_nullable: number;
  is_submit: number;
  name: string;
  type_id: string;
}

export class CustomFieldsAnswer {
  field_id: string;
  name?: string;
  value: string;
  files?: File[];
}

export class CustomFieldsMapped {
  definition: CustomFieldsDefinition;
  answer: CustomFieldsAnswer;
}

export class CustomFieldMagicStringMapping {
  [magicString: string]: string;
}