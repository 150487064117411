<div class="file-uploader">
  <div class="modal-header">
    <h4>{{'GENERAL.UPLOAD' | translate}}</h4>
  </div>
  <div class="modal-body upload-container">

    <div class="uploaded-file-list" *ngIf="files.length > 0">
      <div class="file-item"
        [ngClass]="{'file-item-valid': file.isValid, 'file-item-invalid': !file.isValid}"
        *ngFor="let file of files">
        <div class="file-status">
          <i class="fa fa-fw fa-2x fa-check" *ngIf="file.isValid"></i>
          <i class="fa fa-fw fa-2x" *ngIf="!file.isValid"></i>
        </div>
        <div class="file-preview">
          <ng-container *ngIf="file.isValid">
            <div class="image-preview" 
              [ngStyle]="{'background-image': 'url(' + file.imagePreview + ')'}"
              *ngIf="file.imagePreview">
            </div>
            <div class="doc-preview" *ngIf="!file.imagePreview">
              <i class="fa fa-fw fa-2x fa-video-camera" *ngIf="file.type == ATTACHMENT_TYPE.VIDEO"></i>
              <i class="fa fa-fw fa-2x fa-file" *ngIf="file.type == ATTACHMENT_TYPE.PDF"></i>
            </div>
          </ng-container>
          <ng-container *ngIf="!file.isValid">
            <i class="fa fa-fw fa-2x fa-exclamation-triangle"></i>
          </ng-container>
        </div>
        <div class="file-desc">
          <div class="file-name text-color-primary">
            {{file.name}}
          </div>
          <div class="file-caption text-color-secondary" *ngIf="file.file.caption">
            {{file.file.caption}}
          </div>
          <div class="file-error" *ngIf="!file.isValid">
            <span *ngIf="file.validCode == ATTACHMENT_TYPE.IS_VALID.INVALID_TYPE">
              {{'GENERAL.FILE_TYPE_ERROR' | translate}}
            </span>
            <span *ngIf="file.validCode == ATTACHMENT_TYPE.IS_VALID.INVALID_SIZE">
              {{'GENERAL.FILE_SIZE_ERROR' | translate}}
            </span>
          </div>
        </div>
        <div class="file-action text-color-secondary">
          <div class="action edit-action clickable" *ngIf="file.type == ATTACHMENT_TYPE.IMAGE" (click)="editImage(file)">
            <i class="fa fa-fw fa-2x fa-pencil"></i>
          </div>
          <div class="action remove-action clickable" (click)="removeFile(file)">
            <i class="fa fa-fw fa-2x fa-trash"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="upload-file-zone" 
      [ngClass]="{'upload-file-zone-full': files.length == 0, 
      'upload-file-zone-file-over': isDraggingFileOver}"
      ng2FileDrop
      [uploader]="draggingFileUploader"
      (fileOver)="fileOver($event)"
      (onFileDrop)="onFileDrop($event)">
      <div>
        {{'UTILITY.FILE_UPLOAD_DRAG_FILE' | translate}} /
        <label class="upload-button-label text-color-primary clickable">
          {{'GENERAL.BROWSE_CAPS' | translate}}
          <input type="file" name="file" (change)="handleFileInputChange($event)" multiple [accept]="fileAcceptString">
        </label>
      </div>
      <div class="tip">
        ({{fileUploadTip}})
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="cancel()">{{'GENERAL.CANCEL' | translate}}</button>
    <button type="button" class="btn tn-button-primary" (click)="upload()">{{'GENERAL.UPLOAD' | translate}}</button>
  </div>
</div>