import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StoreReportCustomFieldsMapped, Store, StoreReport, JobDispatch } from '../models/job-report';
import { JobReportConstant } from '../constants/job-report.constants';

@Component({
  selector: 'tn-custom-field',
  templateUrl: './custom-field.component.html',
  styleUrls: ['./custom-field.component.scss']
})
export class CustomFieldComponent implements OnInit {

  @Input() field: StoreReportCustomFieldsMapped;
  @Input() store: Store;
  @Input() report: StoreReport;
  @Input() isInput: boolean = false;

  @Output() onFieldChange: EventEmitter<StoreReportCustomFieldsMapped> = new EventEmitter<StoreReportCustomFieldsMapped>(null);

  FIELD_TYPE = JobReportConstant.CUSTOM_FIELD_TYPE;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {

  }

  onFieldUpdate(newVal) {
    this.onFieldChange.emit(this.field);
  }

}
