import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { JobDispatch } from '../models/job-report';
import { JobReportConstant } from '../constants/job-report.constants';
import { JobReportService } from '../job-report.service';

@Component({
  selector: 'tn-job-dispatch-action',
  templateUrl: './job-dispatch-action.component.html',
  styleUrls: ['./job-dispatch-action.component.scss']
})
export class JobDispatchActionComponent implements OnInit {

  @Input() job: JobDispatch;
  @Output() onJobAction: EventEmitter<number> = new EventEmitter<number>(null);

  CONSTANT = JobReportConstant;

  constructor() { }

  ngOnInit() {
  }

  reject() {
    this.onJobAction.emit(this.CONSTANT.JOB_DISPATCH_ACTION_CODE.REJECT);
  }

  accept() {
    this.onJobAction.emit(this.CONSTANT.JOB_DISPATCH_ACTION_CODE.ACCEPT);
  }

  start() {
    this.onJobAction.emit(this.CONSTANT.JOB_DISPATCH_ACTION_CODE.START);
  }

  end() {
    this.onJobAction.emit(this.CONSTANT.JOB_DISPATCH_ACTION_CODE.END);
  }

  needFollowUp() {
    this.onJobAction.emit(this.CONSTANT.JOB_DISPATCH_ACTION_CODE.NEED_FOLLOW_UP);
  }

}
