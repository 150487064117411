<div class="store-report-list">
  <ng-container *ngIf="storeReports.length > 0">
    <div virtualScroller
      class="full-height"
      [ssrChildHeight]="80"
      [items]="storeReports"
      (vsUpdate)="scrollItems = $event">
      <ng-container *ngFor="let r of scrollItems">
        <tn-job-report-cell 
          [report]="r" 
          [viewingReport]="viewingReport"
          [isEnabledJobDispatch]="isEnabledJobDispatch"
          (onReportCellClick)="onReportCellClick($event)">
        </tn-job-report-cell>
      </ng-container>
    </div>
  </ng-container>

  <tn-empty-page *ngIf="storeReports.length == 0"></tn-empty-page>
</div>
