<div class="corporate-material-item clickable">
  <div class="icon folder" 
    [ngStyle]="{'background-image': folderIconSrc}">
  </div>
  <div class="info">
    <div class="name">{{folder.name}}</div>
  </div>
  <div class="folder-arrow text-color-secondary">
    <i class="fa fa-fw fa-angle-right"></i>
  </div>
</div>