import { Injectable } from '@angular/core';

import { ApiVote } from '../../models/api-vote';

import { TeamnoteApiService } from '../../api/teamnote-api.service';
import { AccountManagerService } from '../services/account/account-manager.service';
import { TimestampService } from '../../utilities/timestamp/timestamp.service';

import * as _ from 'lodash';
import { TnNotificationService } from '../../utilities/tn-notification/tn-notification.service';
import { TeamNoteApiConstant } from '../../constants/api.constant';

@Injectable()
export class VoteService {

  constructor(
    private _accountManagerService: AccountManagerService,
    private _teamnoteApiService: TeamnoteApiService,
    private _timestampService: TimestampService,
    private _tnNotificationService: TnNotificationService
  ) { }

  getVoteByVoteId(voteId: string, success: Function, failure: Function) {
    let params = {
      vote_id: voteId
    };
    let url = TeamNoteApiConstant.VOTE.GET_VOTE_DETAILS;
    this._teamnoteApiService.callApi(url, params, success, failure);
  }

  checkVoteAllowVote(vote: ApiVote): boolean {
    if (this._timestampService.checkIfTimeAfterToday(vote.vote_start)) {
      this._tnNotificationService.showCustomWarningByTranslateKey('WEBCLIENT.NEWS.ERROR_MSG.VOTE_NOT_YET_START');
      return false;
    }
    if (this._timestampService.checkIfTimeBeforeToday(vote.vote_end)) {
      this._tnNotificationService.showCustomWarningByTranslateKey('WEBCLIENT.NEWS.ERROR_MSG.VOTE_EXPIRED');
      return false;
    }
    return true;
  }

  checkVoteIsAllowSubmit(vote: ApiVote): boolean {
    return (vote.has_my_vote === 0 || vote.is_revisible === 1);
  }

  checkVoteAllowViewResult(vote: ApiVote): boolean {
    return vote.should_display_result == 1;
  }

  checkIfVoteModalShouldOpen(vote: ApiVote, isVoting: boolean): boolean {
    if (isVoting && !this.checkVoteAllowVote(vote)) {
      return false;
    }
    if (!isVoting && !this.checkVoteAllowViewResult(vote)) {
      this._tnNotificationService.showCustomWarningByTranslateKey('WEBCLIENT.NEWS.ERROR_MSG.VOTE_RESULT_HIDDEN');
      return false;
    }

    return true;
  }

  parseVoteForVoteModal(vote: ApiVote): ApiVote {
    vote.isAllowSubmit = this.checkVoteIsAllowSubmit(vote);
    vote.checkboxOptionsIds = [];

    _.each(vote.options, function(o) {
      if (o.is_my_option) {
        if (vote.allow_multiple_choice) {
          o.value = true;
          vote.checkboxOptionsIds.push(o.option_id);
        } else {
          vote.radioOptionId = o.option_id;
        }
      }
    });

    return vote;
  }

  parseVoteForResultModal(vote: ApiVote): ApiVote {
    let totalVote = _.reduce(vote.options, function (sum, o) {
      return sum + o.count;
    }, 0);

    _.each(vote.options, function (o) {
      let p = totalVote > 0 ? (o.count / totalVote * 100) : 0;
      o.percentage = p.toFixed(2);
    });

    return vote; 
  }

  parseVoteForDisplay(vote: ApiVote, isVoting: boolean): ApiVote {
    if (isVoting) {
      return this.parseVoteForVoteModal(vote);
    } else {
      return this.parseVoteForResultModal(vote);
    }
  }

  submitVote(voteId: string, optionId: string, success: Function, failure: Function) {
    let params = {
      vote_id: voteId,
      option_id: optionId
    };
    let url = TeamNoteApiConstant.VOTE.SUBMIT_VOTE;
    this._teamnoteApiService.callApi(url, params, success, failure);
  }

}
