import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WorkflowForm } from '../model/workflow';

@Component({
  selector: 'tn-form-list',
  templateUrl: './form-list.component.html',
  styleUrls: ['./form-list.component.scss']
})
export class FormListComponent implements OnInit {

  @Input() forms: WorkflowForm[] = [];
  @Input() viewingForm: WorkflowForm = null;
  @Output() onFormClick: EventEmitter<WorkflowForm> = new EventEmitter<WorkflowForm>(null);

  scrollItems: any;

  constructor() { }

  ngOnInit() {
  }

  onFormCellClick(form: WorkflowForm): void {
    this.onFormClick.emit(form);
  }

}
