<div class="confirmation">
  <div class="msg">
    {{msg}}
  </div>
  <div class="confirm-actions">
    <button class="btn btn-default cancel" (click)="cancel()">
      {{'GENERAL.CANCEL' | translate}}
    </button>
    <button class="btn tn-button-primary confirm" (click)="confirm()">
      {{'GENERAL.CONFIRM' | translate}}
    </button>
  </div>
</div>