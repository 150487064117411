import {Injectable, NgZone} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PageUrlConstant} from '../constants/page-url.constant';
import {TeamNoteLocalStorageKeyConstants} from '../constants/local-storage-key.constant';
import {LocalStorageManagerService} from '../utilities/local-storage/local-storage-manager.service';

@Injectable()
export class BaseRoutingService {

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _localStorageManagerService: LocalStorageManagerService,
    private _zone: NgZone
  ) {
  }

  goToLoginPage(): void {
    this._router.navigateByUrl('/' + PageUrlConstant.LOGIN.BASE);
  }

  goToWebclientPage(): void {
    const previousRoute = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE);
    this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE);
    if (!previousRoute || previousRoute === '/' || previousRoute.indexOf('login') !== -1) {
      this._router.navigateByUrl('/' + PageUrlConstant.WEBCLIENT.BASE);
    } else {
      this._router.navigateByUrl(previousRoute);
    }
  }


}
