export const TeamNoteGeneralConstant = {
  ROOT_USER_GROUP_ID: '00000000-0000-0000-0000-000000000001',

  DEFAULT_GROUP_AVATAR: 'default_group.png',
  DEFAULT_PERSON_AVATAR: 'default_avatar.png',

  // UnauthorizedAccess error when calling API
  UNAUTHORIZED_ACCESS_ERROR: "UnauthorizedAccess",

  // Access refuse error in AMQP
  ACCESS_REFUSED_ERROR: "Access refused",

  // window popup name
  POP_UP_WINDOW_NAME: "TNWC-POPUP",
  // ID of temp input field to check if user closed the popup window by themselves
  POP_UP_CHECK_SELF_CLOSED_ID: "tempIsClosedFromSelfField"
};
