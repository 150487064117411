import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { UtilitiesModule } from '../../utilities/utilities.module';

import { NewsService } from './news.service';
import { VoteService } from './vote.service';

import { NewsComponent } from './news.component';
import { NewsCellComponent } from './news-cell/news-cell.component';
import { NewsImageComponent } from './news-image/news-image.component';
import { NewsMediaComponent } from './news-media/news-media.component';
import { NewsModalComponent } from './news-modal/news-modal.component';
import { VoteModalComponent } from './vote-modal/vote-modal.component';
import { NewsCommentComponent } from './news-comment/news-comment.component';
import { NewsCarouselComponent } from './news-carousel/news-carousel.component';

import { ChatRoomModule } from '../chat/chat-room/chat-room.module';
import { CommonMaterialModule } from '../../utilities/common-material/common-material.module';
import { NewsModalService } from './news-modal/news-modal.service';
import { CommentInputComponent } from './news-comment/comment-input/comment-input.component';
import { VoteOptionComponent } from './vote-modal/vote-option/vote-option.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UtilitiesModule,
        ChatRoomModule
    ],
    declarations: [
        NewsComponent,
        NewsCellComponent,
        NewsImageComponent,
        NewsMediaComponent,
        NewsModalComponent,
        VoteModalComponent,
        NewsCommentComponent,
        CommentInputComponent,
        VoteOptionComponent,
        NewsCarouselComponent
    ],
    providers: [
        NewsService,
        VoteService,
        NewsModalService
    ]
})
export class NewsModule { }
