import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { UtilitiesModule } from '../../utilities/utilities.module';

// Components
import { ContactComponent } from './contact.component';
import { ContactListComponent } from './contact-list/contact-list.component';
import { ContactCellComponent } from './contact-cell/contact-cell.component';
import { ContactCardComponent } from './contact-card/contact-card.component';
import { ContactCardService } from './contact-card/contact-card.service';
import { ContactService } from './contact.service';
import { ContactListContainerComponent } from './contact-list-container/contact-list-container.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UtilitiesModule
    ],
    declarations: [
        ContactComponent,
        ContactListComponent,
        ContactCellComponent,
        ContactCardComponent,
        ContactListContainerComponent
    ],
    exports: [
        ContactListComponent,
        ContactCellComponent,
        ContactListContainerComponent
    ],
    providers: [
        ContactCardService,
        ContactService
    ]
})
export class ContactModule { }
