export const ZhChsBroadcastStrings = {
  TITLE: "广播",

  RECIPIENTS: "收件人",
  RECIPIENT_COUNT: {
    ONE: "{{NUM}} 收件人",
    MULTIPLE: "{{NUM}} 收件人"
  },
  RECIPIENT_REMOVE: "全部删除 ({{NUM}})",
  ADD_FROM_TEAMNOTE: "从团信新增",
  ADD_FROM_BROADCAST_LIST: "从广播列表新增",

  SEND: {
    TITLE: "发送广播消息",
  },

  MANAGE: {
    TITLE: "管理广播列表",
    ADD_BROADCAST_LIST: "新增广播列表",
    EDIT_BROADCAST_LIST: "更改广播列表",
    DELETE_BROADCAST_LIST_CONFIRM: "删除广播列表 - {{NAME}} ?"
  }
}
