/**
 * Idle Timeout Service
 * To handle webclient session timeout logics
 * Implemented using library @ng-idle
 * 
 * Created by Shan - 2018-04-25
 */

import { Injectable } from '@angular/core';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { LoggerService } from '../logger/logger.service';
import { TeamnoteApiService } from '../../api/teamnote-api.service';
import { TnNotificationService } from '../tn-notification/tn-notification.service';

@Injectable()
export class IdleTimeoutService {

  // DEFAULT_INTERRUPTSOURCES = 'mousemove keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll'

  constructor(
    private _idle: Idle,
    private _loggerService: LoggerService,
    private _teamnoteApiService: TeamnoteApiService,
    private _tnNotificationService: TnNotificationService
  ) { }

  initIdleTimeout(timeoutSec: number): void {
    if (!timeoutSec || timeoutSec == 0 || timeoutSec == -1) {
      return;
    }

    this._loggerService.debug("Init idle timeout: " + timeoutSec);
    this._idle.setIdle(timeoutSec);
    // Set timeout to 1, this will timeout user 1 second after user has idled for {timeoutSec} seconds (effect = immediately)
    this._idle.setTimeout(1);

    this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this._idle.onTimeout.subscribe(() => {
      this.timeoutWebclient();
    });

    this._idle.watch();
  }

  timeoutWebclient(): void {
    this._loggerService.error("Session timed out! Logout webclient");
    this._teamnoteApiService.logoutWebClient(true);

    this._tnNotificationService.showAlert(
      null,
      "GENERAL.SESSION_TIMEOUT_MSG",
      null,
      "GENERAL.CONFIRM",
      () => {
        location.reload();
      }
    );
  }

}
