import { Component, OnInit, Inject } from '@angular/core';

import { ApiVote } from '../../../models/api-vote';

import { VoteService } from '../vote.service';

import * as _ from 'lodash';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'tn-vote-modal',
  templateUrl: './vote-modal.component.html',
  styleUrls: ['./vote-modal.component.scss']
})
export class VoteModalComponent implements OnInit {
  vote: ApiVote;
  isVoting: boolean;

  iconImgSrc: string = 'url(assets/images/general/voting-icon.png)';

  constructor(
    private _voteService: VoteService,
    public dialogRef: MatDialogRef<VoteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _domSanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.vote = this.data.vote;
    this.vote.htmlContent = this.vote.detail ? this._domSanitizer.bypassSecurityTrustHtml(this.vote.detail) : '';

    this.isVoting = this.data.isVoting;
  }

  close() {
    this.dialogRef.close();
  }

  updateSelection(state, optionId) {
    if (state) {
      this.vote.checkboxOptionsIds.push(optionId);
      this.vote.checkboxOptionsIds = _.uniq(this.vote.checkboxOptionsIds);
    } else {
      this.vote.checkboxOptionsIds = _.without(this.vote.checkboxOptionsIds, optionId);
    }
  }

  submitVote() {
    // console.log(this.vote.radioOptionId);
    // console.log(this.vote.checkboxOptionsIds);
    let optionId = this.vote.allow_multiple_choice ? JSON.stringify(this.vote.checkboxOptionsIds) : this.vote.radioOptionId;
    this._voteService.submitVote(
      this.vote.vote_id, 
      optionId,
      resp => {
        this.close();
      },
      err => {

      }
    )
  }


}
