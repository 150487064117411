import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AttachmentTypeConstant } from '../../../../app/constants/attachment-type.constant';
// import { WebDavMaterialFolder } from '../models/webdav-material';
import { TeamNoteWebDavMaterialConstant } from '../constants/webdav-material.constant';
import { Child, WDResponse } from '../models/webdav-material';
import * as _ from 'lodash';
import { ContextMenuItem } from '../../../../app/utilities/context-menu/context-menu.service';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tn-webdav-material-folder',
  templateUrl: './webdav-material-folder.component.html',
  styleUrls: ['./webdav-material-folder.component.scss']
})
export class WebDavMaterialFolderComponent implements OnInit {

  @Input() unreadPaths: Child[];
  @Input() pinFolders: WDResponse[] = [];
  @Input() folder: WDResponse = { href: '', propstat: null };
  @Input() fileAllowTypes: string[] = _.values(TeamNoteWebDavMaterialConstant.TYPE);
  @Input() isShowContextMenu = false;

  @Output() onUnsetPinClick: EventEmitter<WDResponse> = new EventEmitter<WDResponse>(null);
  @Output() onSetPinClick: EventEmitter<WDResponse> = new EventEmitter<WDResponse>(null);
  unRead = false;
  contextMenus: ContextMenuItem[] = [];
  isDisplay = false;
  fileType: string;
  isPin = false;
  name = '';
  constructor(
  ) { }

  ngOnInit() {
  }
  onUnsetPin(event) {
    this.isPin = false;
    this.onUnsetPinClick.emit(this.folder);
  }
  onSetPin(event) {
    this.isPin = true;
    this.onSetPinClick.emit(this.folder);
  }
  getFileExtension1(filename: string) {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
  }
  getFileTypeByFileIds(extension: string): string {
    if (extension) {
      extension = extension.toLowerCase();
      if (_.includes(AttachmentTypeConstant.IMAGE_TYPES, extension)) {
        return TeamNoteWebDavMaterialConstant.TYPE.IMG;
      }
      if (_.includes(AttachmentTypeConstant.VIDEO_TYPES, extension)) {
        return TeamNoteWebDavMaterialConstant.TYPE.VIDEO;
      }
      if (_.includes(AttachmentTypeConstant.AUDIO_TYPES, extension)) {
        return TeamNoteWebDavMaterialConstant.TYPE.AUDIO;
      }
      if (_.includes(AttachmentTypeConstant.PDF_TYPES, extension)) {
        return TeamNoteWebDavMaterialConstant.TYPE.PDF;
      }
      if (_.includes(AttachmentTypeConstant.DOC_TYPES, extension)) {
        return TeamNoteWebDavMaterialConstant.TYPE.DOC;
      }
      if (_.includes(AttachmentTypeConstant.TXT_TYPES, extension)) {
        return TeamNoteWebDavMaterialConstant.TYPE.TXT;
      }
    } else {
      return 'folder';
    }
  }
  decodeURL(y: string) {
    const split = y.split('/');
    // tslint:disable-next-line:forin
    for (const index in split) {
      split[index] = decodeURIComponent(split[index]);
    }
    return split.join('/');
  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnChanges() {
    if (this.pinFolders) {
      this.isPin = this.pinFolders.includes(this.folder);
    }
    if (this.unreadPaths) {
      let path = this.decodeURL(this.folder.href);
      if (!this.folder.propstat.prop.getcontenttype && path.endsWith('/')) {
        path = path.substring(0, path.length - 1);
      }
      const found = this.unreadPaths.find(element => element.href === path);
      if (found) { this.unRead = found.unread; }
    } else {
      this.unRead = false;
    }

    const n = this.folder.href.split('/');
    if (n[n.length - 1] !== '') {
      this.name = decodeURIComponent(n[n.length - 1]); // .replace('%20', ' ');
      this.fileType = this.getFileTypeByFileIds(this.getFileExtension1(this.name));
      this.isDisplay = false;
    } else {
      this.name = decodeURIComponent(n[n.length - 2]); // .replace('%20', ' ');
      this.fileType = 'folder';
      this.isDisplay = true;
    }
  }
}
