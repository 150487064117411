let JOB_DISPATCH_STATUS =  {
  NOT_ASSIGNED: -999,
  REJECTED: -1,
  PENDING: 0,
  ACCEPTED: 1,
  STARTED: 2,
  FINISHED: 9,
  NEED_FOLLOW_UP: 10
};

export const JobReportConstant = {
  CUSTOM_FIELD_TYPE: {
    MULTIPLE_CHOICE: 1,
    FREE_TEXT: 2,
    CHECKBOX: 3,
    DROPDOWN: 4,
    CONTACT: 5,
    ATTACHMENT_PHOTO: 6,
    DATE: 7,
    TIME: 8,
    GEOLOCATION_USER_INPUT: 9,
    GEOLOCATION_DETECTION: 10,
    CODE_SCAN: 11,
    TEXT_DISPLAY: 12,
    ATTACHMENT_DOCUMENT: 13
  },
  JOB_DISPATCH_STATUS: JOB_DISPATCH_STATUS,
  UNFINISHED_JOB_STATUS: [
    JOB_DISPATCH_STATUS.PENDING,
    JOB_DISPATCH_STATUS.ACCEPTED,
    JOB_DISPATCH_STATUS.STARTED
  ],
  JOB_ORDERING: [
    JOB_DISPATCH_STATUS.NEED_FOLLOW_UP,
    JOB_DISPATCH_STATUS.NOT_ASSIGNED,
    JOB_DISPATCH_STATUS.PENDING,
    JOB_DISPATCH_STATUS.ACCEPTED,
    JOB_DISPATCH_STATUS.REJECTED,
    JOB_DISPATCH_STATUS.STARTED,
    JOB_DISPATCH_STATUS.FINISHED
  ],
  JOB_DISPATCH_ACTION_CODE: {
    ACCEPT: 1,
    REJECT: 2,
    START: 3,
    END: 4,
    NEED_FOLLOW_UP: 5
  },
  LIST_FORMAT_MAGIC_STRING_PLACEHOLDER: {
    STORE_NAME: "%%storeName%%",
    TYPE_NAME: "%%typeName%%",
    REPORT_DATE: "%%reportDate%%",
    JOB_DISPATCH_DATE: "%%jobDispatchDate%%",
    REPORT_NO: "%%refId%%",
    FIELD_ID: "%%fieldId?={{fieldId}}%%",
    CREATOR: "%%creator%%",
    ASSIGNER: "%%assigner%%",
    LOCAL_TRANSLATE: {
      "%%imageOne%%": {
        key: "%%imageOne%%",
        translate: "WEBCLIENT.JOB_REPORT.LIST_FORMAT.IMAGE.ONE"
      },
      "%%imageOther%%": {
        key: "%%imageOther%%",
        translate: "WEBCLIENT.JOB_REPORT.LIST_FORMAT.IMAGE.OTHER"
      },
      "%%fileOne%%": {
        key: "%%fileOne%%",
        translate: "WEBCLIENT.JOB_REPORT.LIST_FORMAT.FILE.ONE"
      },
      "%%fileOther%%": {
        key: "%%fileOther%%",
        translate: "WEBCLIENT.JOB_REPORT.LIST_FORMAT.FILE.OTHER"
      },
      "%%userOne%%": {
        key: "%%userOne%%",
        translate: "UTILITY.CUSTOM_FIELD.LIST_FORMAT.USER.ONE"
      },
      "%%userOther%%": {
        key: "%%userOther%%",
        translate: "UTILITY.CUSTOM_FIELD.LIST_FORMAT.USER.OTHER"
      },
      "%%attachmentOne%%": {
        key: "%%attachmentOne%%",
        translate: "UTILITY.CUSTOM_FIELD.LIST_FORMAT.ATTACHMENT.ONE"
      },
      "%%attachmentOther%%": {
        key: "%%attachmentOther%%",
        translate: "UTILITY.CUSTOM_FIELD.LIST_FORMAT.ATTACHMENT.OTHER"
      },
      "%%signed%%": {
        key: "%%signed%%",
        translate: "UTILITY.CUSTOM_FIELD.LIST_FORMAT.SIGNATURE.SIGNED"
      },
      "%%notSigned%%": {
        key: "%%notSigned%%",
        translate: "UTILITY.CUSTOM_FIELD.LIST_FORMAT.SIGNATURE.NOT_SIGNED"
      }
    }
  }
}
