import { Component, OnInit, Input } from '@angular/core';
import { InputValidationService } from '../../../../utilities/input-validation/input-validation.service';
import { TnNotificationService } from '../../../../utilities/tn-notification/tn-notification.service';
import { NewsService } from '../../news.service';

import * as _ from 'lodash';
import { AttachmentTypeConstant } from '../../../../constants/attachment-type.constant';
import { UtilitiesService } from '../../../../utilities/service/utilities.service';

@Component({
  selector: 'tn-comment-input',
  templateUrl: './comment-input.component.html',
  styleUrls: ['./comment-input.component.scss']
})
export class CommentInputComponent implements OnInit {

  @Input() messageId: string;
  @Input() parentNewsMessageId: string;

  inputMessage: string = '';

  constructor(
    private _inputValidationService: InputValidationService,
    private _tnNotificationService: TnNotificationService,
    private _newsService: NewsService,
    private _utilitiesService: UtilitiesService
  ) { }

  ngOnInit() {
  }

  handleFileInputChange(e) {
    let files = e.target.files;
    let validFiles = [];
    _.each(files, (f) => {
      let validCode = this._inputValidationService.isFileImageOrVideo(f);
      switch (validCode) {
        case AttachmentTypeConstant.IS_VALID.VALID:
          validFiles.push(f);
          break;
        case AttachmentTypeConstant.IS_VALID.INVALID_SIZE:
          this._tnNotificationService.showCustomWarningByTranslateKey('GENERAL.FILE_SIZE_ERROR');
          break;
        case AttachmentTypeConstant.IS_VALID.INVALID_TYPE:
          this._tnNotificationService.showCustomWarningByTranslateKey('GENERAL.FILE_TYPE_ERROR');        
          break;
      }
    });
    this.onFinishSelectFile(validFiles);
  }
  onFinishSelectFile(files: File[]): void {
    if (!this._newsService.checkIfThreadOpenByMessageId(this.parentNewsMessageId)) {
      return;
    }
    this._newsService.uploadSendAttachment(this.messageId, files);
  }

  inputKeyDown(): void {
    // Send read when user type in textarea
    // this.sendRead();
  }

  inputKeyEnter(isWithShiftKey: boolean) {
    if (this._utilitiesService.checkIfKeyEnterShouldSend(isWithShiftKey)) {
      this.send();
      return false;
    }
    return true;
  }

  send(): void {
    if (this.inputMessage.length > 0) {
      if (!this._newsService.checkIfThreadOpenByMessageId(this.parentNewsMessageId)) {
        return;
      }
      this._newsService.sendNewsCommentText(this.messageId, this.inputMessage);
      this.inputMessage = '';
    }
  }

}
