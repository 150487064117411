import { Injectable } from '@angular/core';
import { CustomFieldsMapped, CustomFieldsAnswer } from '../models/custom-field';
import { CustomFieldConstant } from '../constants/custom-field.constants';
import { UserContactService } from '../../../services/data/user-contact/user-contact.service';
import { DateService } from '../../../../utilities/date/date.service';
import { FieldAttachmentPhotoParsedAnswer } from '../field-attachment-photo/field-attachment-photo.component';
import { FieldLocationAnswer } from '../field-geolocation-input/field-geolocation-input.component';
import { FieldAttachmentDocumentParsedAnswer } from '../field-attachment-document/field-attachment-document.component';

import * as _ from 'lodash';
import { FieldAttachmentParsedAnswer } from '../field-attachment/field-attachment.component';

@Injectable()
export class FilterHelperService {

  constructor(
    private _userContactService: UserContactService,
    private _dateService: DateService
  ) { }

  getSearchKeywordOfReportByMappedField(mappedField: CustomFieldsMapped): string {
    let keyword = "";
    let answer = mappedField.answer;
    if (!answer) {
      return keyword;
    }
    switch (mappedField.definition.field_type) {
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE:
        return this.getSearchKeywordMultipleChoice(answer);
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.FREE_TEXT:
        return this.getSearchKeywordFreeText(answer);
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.CHECKBOX:
        return this.getSearchKeywordCheckbox(answer);
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.DROPDOWN:
        return this.getSearchKeywordDropdown(answer);
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.CONTACT:
        return this.getSearchKeywordContact(answer);
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.ATTACHMENT_PHOTO:
        return this.getSearchKeywordAttachmentPhoto(answer);
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.DATE:
        return this.getSearchKeywordDate(answer);
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.TIME:
        return this.getSearchKeywordTime(answer);
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.GEOLOCATION_USER_INPUT:
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.GEOLOCATION_DETECTION:
        return this.getSearchKeywordGeolocation(answer);
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.ATTACHMENT_DOCUMENT:
        return this.getSearchKeywordAttachmentDocument(answer);
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.ATTACHMENT:
        return this.getSearchKeywordAttachment(answer);
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.CODE_SCAN:
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.TEXT_DISPLAY:
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.SIGNATURE:
          return "";
      default:
        break;
    }

    return keyword;
  }

  getSearchKeywordMultipleChoice(answer: CustomFieldsAnswer): string {
    return answer.value;
  }

  getSearchKeywordFreeText(answer: CustomFieldsAnswer): string {
    return answer.value;
  }

  getSearchKeywordCheckbox(answer: CustomFieldsAnswer): string {
    let checkeds = [];
    try {
      checkeds = JSON.parse(answer.value);
    } catch (e) {};
    return checkeds.join(" ");
  }

  getSearchKeywordDropdown(answer: CustomFieldsAnswer): string {
    return answer.value;
  }

  getSearchKeywordContact(answer: CustomFieldsAnswer): string {
    let contactIds = [];
    try {
      contactIds = JSON.parse(answer.value);
    } catch (e) {};

    let names = [];
    _.each(contactIds, (userId) => {
      let user = this._userContactService.getUserContactByUserId(userId);
      if (user) {
        names.push(user.name);
      }
    });
    return names.join(" ");
  }

  getSearchKeywordAttachmentPhoto(answer: CustomFieldsAnswer): string {
    let photos: FieldAttachmentPhotoParsedAnswer[] = [];
    try {
      photos = JSON.parse(answer.value);
    } catch (e) {};
    let captions = [];
    _.each(photos, (photo) => {
      if (photo.caption) {
        captions.push(photo.caption);
      }
    });
    return captions.join(" ");
  }

  getSearchKeywordDate(answer: CustomFieldsAnswer): string {
    if (answer.value.length != 0) {
      return this._dateService.getDateDisplayWithKey(
        answer.value,
        "FORM_DETAIL_REPORT_DATE"
      );
    }
    return "";
  }

  getSearchKeywordTime(answer: CustomFieldsAnswer): string {
    if (answer.value.length != 0) {
      return this._dateService.getDateDisplayWithKey(
        answer.value,
        "FORM_DETAIL_REPORT_TIME"
      );
    }
    return "";
  }

  getSearchKeywordGeolocation(answer: CustomFieldsAnswer): string {
    let location: FieldLocationAnswer = {address: "", lat: 0, lng: 0};
    try {
      if (answer.value.length != 0) {
        location = JSON.parse(answer.value);
      }
    } catch (e) {};
    if (location && location.address) {
      return location.address;
    }
    return "";
  }

  getSearchKeywordAttachmentDocument(answer: CustomFieldsAnswer): string {
    let docs: FieldAttachmentDocumentParsedAnswer[] = [];
    try {
      docs = JSON.parse(answer.value);
    } catch (e) {};
    let filenames = [];
    _.each(docs, (doc) => {
      if (doc.name) {
        filenames.push(doc.name);
      }
    });
    return filenames.join(" ");
  }

  getSearchKeywordAttachment(answer: CustomFieldsAnswer): string {
    let attachments: FieldAttachmentParsedAnswer[] = [];
    try {
      attachments = JSON.parse(answer.value);
    } catch (e) {};
    let searchKeys = [];
    _.each(attachments, (attachment) => {
      if (attachment.name) {
        searchKeys.push(attachment.name);
      }
      if (attachment.description) {
        searchKeys.push(attachment.description);
      }
    });
    return searchKeys.join(" ");
  }
}
