import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobReportComponent } from './job-report.component';
import { JobReportViewComponent } from './job-report-view/job-report-view.component';
import { UtilitiesModule } from '../../utilities/utilities.module';
import { JobReportService } from './job-report.service';
import { JobReportCellComponent } from './job-report-cell/job-report-cell.component';
import { StoreReportListComponent } from './store-report-list/store-report-list.component';
import { JobDispatchListComponent } from './job-dispatch-list/job-dispatch-list.component';
import { CustomFieldModule } from './custom-field/custom-field.module';
import { JobDispatchInfoComponent } from './job-dispatch-info/job-dispatch-info.component';
import { JobDispatchActionComponent } from './job-dispatch-action/job-dispatch-action.component';
import { ReportSubmitComponent } from './report-submit/report-submit.component';
import { ReportOptionComponent } from './report-option/report-option.component';
import { ReportInputComponent } from './report-input/report-input.component';
import { ReportInfoHeaderComponent } from './job-report-view/report-info-header/report-info-header.component';
import { ReportExportComponent } from './report-export/report-export.component';
import { ReportFilterComponent } from './report-filter/report-filter.component';
import { CellFormatHelperService } from './helper/cell-format-helper.service';
import { FormsModule } from '@angular/forms';
import { JobFilterHelperService } from './job-filter-helper.service';

@NgModule({
    imports: [
        CommonModule,
        UtilitiesModule,
        CustomFieldModule,
        FormsModule
    ],
    providers: [
        JobReportService,
        CellFormatHelperService,
        JobFilterHelperService
    ],
    declarations: [JobReportComponent, JobReportViewComponent, JobReportCellComponent, StoreReportListComponent, JobDispatchListComponent, JobDispatchInfoComponent, JobDispatchActionComponent, ReportSubmitComponent, ReportOptionComponent, ReportInputComponent, ReportInfoHeaderComponent, ReportExportComponent, ReportFilterComponent]
})
export class JobReportModule { }
