import { Component, OnInit, Input } from '@angular/core';
import { CorporateMaterialFolder } from '../models/corporate-material';
import { FileFactoryService } from '../../../utilities/file-factory/file-factory.service';

@Component({
  selector: 'tn-corporate-material-folder',
  templateUrl: './corporate-material-folder.component.html',
  styleUrls: ['./corporate-material-folder.component.scss']
})
export class CorporateMaterialFolderComponent implements OnInit {

  @Input() folder: CorporateMaterialFolder;

  folderIconSrc: string = '';

  constructor(
    private _fileFactoryService: FileFactoryService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (!this.folder) {
      return;
    }
    if (this.folder.folder_icon_id && this.folder.folder_icon_id != 'None') {
      this._fileFactoryService.getFileByAttachmentId(
        this.folder.folder_icon_id,
        (imageUrl) => {
          this.folderIconSrc = imageUrl;
        },
        (err) => {},
        false,
        false,
        true
      );
    }
  }

}
