<div class="form-filter-container">

  <tn-page-title [title]="'WEBCLIENT.WORKFLOW.FILTER.TITLE' | translate">

    <div class="left-control">
      <div class="back-button clickable" (click)="formFilterBack()">
        <i class="fa fa-fw fa-angle-left"></i>
      </div>
    </div>
  </tn-page-title>

  <div class="form-filter-body">
    <form novalidate>
      <div class="form-group">
        <label>{{'WEBCLIENT.WORKFLOW.FILTER.CREATE_DATE' | translate}}</label>
        <div class="date-range-pickers">
          <div class="date-picker">
            <input type="text"
              class="form-control"
              name="date-from"
              bsDatepicker
              [bsConfig]="dateInputConfig"
              [(ngModel)]="dateFrom">
          </div>
          <div class="date-to">
            {{'WEBCLIENT.JOB_REPORT.FILTER.TO' | translate}}
          </div>
          <div class="date-picker">
            <input type="text"
              class="form-control"
              name="date-to"
              bsDatepicker
              [bsConfig]="dateInputConfig"
              [(ngModel)]="dateTo">
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>{{'WEBCLIENT.WORKFLOW.FILTER.TEAM' | translate}}</label>
        <div class="option">
          <ng-select
            [clearable]="false"
            [items]="teams"
            [(ngModel)]="team"
            appendTo="body"
            bindLabel="name"
            name="team">
            <ng-template ng-label-tmp ng-option-tmp let-item="item">
              <ng-container *ngIf="item.isAll">
                {{'GENERAL.ALL' | translate}}
              </ng-container>
              <ng-container *ngIf="!item.isAll">
                {{item.name}}
              </ng-container>
            </ng-template>
          </ng-select>
        </div>
      </div>
    
      <div class="form-group" *ngIf="types && types.length > 1">
        <label>{{'WEBCLIENT.WORKFLOW.FILTER.TYPE' | translate}}</label>
        <div class="option">
          <ng-select
            [clearable]="false"
            [items]="types"
            [(ngModel)]="type"
            (ngModelChange)="onTypeOptionChange($event)"
            appendTo="body"
            bindLabel="name"
            name="type">
            <ng-template ng-label-tmp ng-option-tmp let-item="item">
              <ng-container *ngIf="item.isAll">
                {{'GENERAL.ALL' | translate}}
              </ng-container>
              <ng-container *ngIf="!item.isAll">
                {{item.name}}
              </ng-container>
            </ng-template>
          </ng-select>
        </div>
      </div>
    
      <div class="form-group" *ngIf="type && type.type_id">
        <label>{{'WEBCLIENT.WORKFLOW.FILTER.STAGE' | translate}}</label>
        <div class="option">
          <ng-select
            [clearable]="false"
            [items]="stages"
            [(ngModel)]="stage"
            appendTo="body"
            bindLabel="name"
            name="stage">
            <ng-template ng-label-tmp ng-option-tmp let-item="item">
              <ng-container *ngIf="item.isAll">
                <div class="empty-option"></div>
              </ng-container>
              <ng-container *ngIf="!item.isAll">
                {{item.name}}
              </ng-container>
            </ng-template>
          </ng-select>
        </div>
      </div>

      <div class="form-group">
        <label>{{'WEBCLIENT.WORKFLOW.FILTER.CONTENT' | translate}}</label>
        <div class="text-input">
          <div class="input-group">
            <input type="text"
              class="form-control"
              name="field_content"
              [(ngModel)]="contentKeyword">
            <div class="input-group-btn">
              <button class="btn btn-default" (click)="contentKeyword = ''">
                <i class="fa fa-fw fa-times"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="apply-button-wrapper">
      <button class="btn btn-success" (click)="apply()">
        {{'WEBCLIENT.WORKFLOW.FILTER.APPLY' | translate}}
      </button>
    </div>
  </div>
</div>