export const ZhChsWebdavStrings = {
    USERNAME: 'DPID',
    PASSWORD: 'Password',
    USERNAME_TIP: '請輸入帳號',
    PASSWORD_TIP: '請輸入密碼',
    SETPIN: '釘選',
    UNSETPIN :  '取消釘選',
    SIGN_IN : '登入',
    BACK : '取消',
};

