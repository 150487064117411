import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { EmojiSetContent, EmojiSetCategory } from '../../../models/emoji';

class EmojiMenuDisplay {
  emoji: any;
  src: string;
}

class EmojiCategory {
  LABEL: string;
  ICON_NAME: string;
  EMOJI: number[];
  emojiDisplays?: EmojiMenuDisplay[];
  iconSrc?: string;
}

import * as _ from 'lodash';
import { FileManagerService } from '../../file-manager/file-manager.service';
import { Emoji } from '../../../models/emoji';
import { EmojiService } from '../emoji.service';
import { EmojiConstant } from '../../../constants/emoji.constant';
import { EmojiAppConstant } from '../../../constants/emoji_app.constant';

@Component({
  selector: 'tn-emoji-menu',
  templateUrl: './emoji-menu.component.html',
  styleUrls: ['./emoji-menu.component.scss']
})
export class EmojiMenuComponent implements OnInit {

  onEmojiSelectFunction: Function;
  isSentEmoji: boolean | string

  emojiCategories: EmojiCategory[];
  selectedCategory: EmojiCategory;

  emojiAppCategories: EmojiSetCategory[];
  selectedAppCategory: EmojiSetCategory;

  isSearchingMode: boolean = false;
  filteredEmoji: EmojiSetContent[] = [];

  constructor(
    public dialogRef: MatDialogRef<EmojiMenuComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fileManagerService: FileManagerService,
    private _emojiService: EmojiService
  ) { }

  ngOnInit() {
    this.dialogRef.updateSize('50vw');
    this.onEmojiSelectFunction = this.data.onEmojiSelectFunction;
    this.isSentEmoji = this.data.sentEmoji;
    this.buildMenuTabs();
  }

  buildMenuTabs() {
    this.emojiAppCategories = _.toArray(this._emojiService.getAppEmojiSet());

    _.forEach(this.emojiAppCategories, (c) => {
      c.emoji = c.BODY.emoji;
    })

    this.selectCategory(_.find(this.emojiAppCategories, (c) => {
      return c.LABEL == EmojiAppConstant.CATEGORIES.Recents.category;
    }));

    /* old version of emoji set */
    // this.emojiCategories = _.toArray(EmojiConstant.CATEGORIES);

    // _.each(this.emojiCategories, (c) => {
    //   c.iconSrc = this._fileManagerService.getBackgroundImgSrcBySrc(this._emojiService.getEmojiPathByFileName(c.ICON_NAME));

    //   c.emojiDisplays = _.map(c.EMOJI, (e) => {
    //     return {
    //       emoji: e,
    //       src: this._fileManagerService.getBackgroundImgSrcBySrc(this._emojiService.getEmojiPathByFileName(this._emojiService.getFileName(e)))
    //     };
    //   });

    // });

    // console.log(this.emojiCategories)

    // this.selectCategory(_.find(this.emojiCategories, (c) => {
    //   return c.LABEL == EmojiConstant.CATEGORIES.RECENT.LABEL;
    // }));
  }

  // selectCategory(cat: EmojiCategory) {
  selectCategory(cat: EmojiSetCategory) {
    this.selectedAppCategory = cat;
    
    if (cat.LABEL == EmojiAppConstant.CATEGORIES.Recents.category) {
      cat.EMOJI = _.map(this._emojiService.getRecentEmojis(), (e) => {
        return {
          emoji: e
        };
      });
    }
  }

  onEmojiClick(emoji: number) {
    this._emojiService.addToRecent(emoji);
    this.onEmojiSelectFunction(emoji);
    this.dialogRef.close();
  }

  getEmojiString(unicode): string {
    return this._emojiService.getUnicodeByEmoji(unicode);
  }

  searchEmoji(keyword?: string): void {
    this.filteredEmoji = [] // clear the list first
    let search = keyword.trim()

    if (search && search.length > 0) {
      for (let cat = 0; cat < this.emojiAppCategories.length; cat++) {
        let currentCat = this.emojiAppCategories[cat]

        if (currentCat.LABEL === 'Recents') {
          continue
        }

        _.forEach(currentCat.EMOJI, emoji => {
          let fields = []

          fields = _.concat(emoji.tags || [], emoji.description || []);
          fields = _.concat(fields, emoji.aliases || []);

          _.forEach(fields, (f) => {
            if (_.includes(f, search)) {
              this.filteredEmoji = _.union(this.filteredEmoji, [emoji])
            }
          })
        })
      }

      this.isSearchingMode = true
    } else {
      this.filteredEmoji = []
      this.isSearchingMode = false
    }
  }
}
