import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StoreReportCustomFieldsMapped } from '../../models/job-report';
import { TimestampService } from '../../../../utilities/timestamp/timestamp.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DateService } from '../../../../utilities/date/date.service';

@Component({
  selector: 'tn-field-date',
  templateUrl: './field-date.component.html',
  styleUrls: ['./field-date.component.scss']
})
export class FieldDateComponent implements OnInit {

  @Input() field: StoreReportCustomFieldsMapped;
  @Input() isInput: boolean = false;

  @Output() onFieldUpdate: EventEmitter<StoreReportCustomFieldsMapped> = new EventEmitter<StoreReportCustomFieldsMapped>(null);

  dateDisplay: string;
 
  dateInput: Date = null;
  dateInputConfig: Partial<BsDatepickerConfig>;

  constructor(
    private _timestampService: TimestampService,
    private _dateService: DateService
  ) { }

  ngOnInit() {
    this.dateInputConfig = Object.assign({}, { 
      containerClass: "theme-default",
      dateInputFormat: "YYYY-MM-DD"
    });
  }

  ngOnChanges() {
    this.dateDisplay = this._dateService.getDateDisplayWithKey(
      this.field.answer.value,
      "JOB_DISPATCH_DETAIL_REPORT_DATE"
    );
  }

  getAnswerValue(): string {
    return this.dateInput ? this._timestampService.dateObjToSecondString(this.dateInput) : "";
  }

  onChange(newVal) {
    this.dateInput = newVal;
    this.field.answer.value = this.getAnswerValue();
    this.onFieldUpdate.emit(this.field);
  }

}
