import { Injectable } from '@angular/core';
import { TnDialogService } from '../tn-dialog/tn-dialog.service';
import { PasswordReloginComponent } from './password-relogin.component';

@Injectable()
export class PasswordReloginService {

  constructor(
    private _tnDialogService: TnDialogService
  ) { }

  openPasswordReloginDialog(titleTranslateKey: string, onLoginSuccess: Function) {
    let dialogRef = this._tnDialogService.openTnDialog(PasswordReloginComponent, {
      titleTranslateKey: titleTranslateKey,
      onLoginSuccess: onLoginSuccess
    });
  }

}
