export const TnDateFormatConfiguration = {
  NEWS_DATE: "MMM DD, YYYY",  // 15:11, Feb 03, 2018
  NEWS_DATE_ZH: "YYYY年M月D日",

  CHAT_MSG_TIME: "hh:mm A",   // 03:02 AM
  CHAT_DATE_HEADER: "ddd, D MMM YYYY",  // Fri, 3 Feb 2018
  CHAT_LAST_SEEN: "YYYY-MM-DD hh:mm A",    // 2018-02-03 03:01 PM

  DATE: "D MMM YY",
  TIME: "hh:mm A",
  DAY: "D",
  WEEKDAY: "dddd",

  CORPORATE_MATERIAL_DATE: "ddd, MMM DD, YYYY hh:mm A",    // Fri, Feb 17, 2018 03:02 AM

  MESSAGE_INFO_DATE: "DD MMMM YYYY h:mmA",   // 16 April 2018 5:44PM
  MESSAGE_INFO_DATE_ZH: "YYYY年M月D日 Ah:mm",

  DOC_DATE: "DD MMMM YYYY h:mmA",   // 16 April 2018 5:44PM
  DOC_DATE_ZH: "YYYY年M月D日 Ah:mm",

  JOB_DISPATCH_LIST_ITEM_DATE: "YYYY-MM-DD HH:mm",    // 2018-03-02 18:29
  JOB_DISPATCH_DETAIL_START_END_TIME: "YYYY-MM-DD HH:mm",    // 2018-03-02 18:29
  JOB_DISPATCH_DETAIL_REPORT_DATE: "YYYY-MM-DD",    // 2018-03-02
  JOB_DISPATCH_DETAIL_REPORT_TIME: "HH:mm",    // 18:29
  JOB_DISPATCH_FILTER_TIME: "YYYY-MM-DD",    // 18:29

  FORM_LIST_ITEM_DATE: "YYYY-MM-DD HH:mm",    // 2018-03-02 18:29
  FORM_DETAIL_START_END_TIME: "YYYY-MM-DD HH:mm",    // 2018-03-02 18:29
  FORM_DETAIL_REPORT_DATE: "YYYY-MM-DD",    // 2018-03-02
  FORM_DETAIL_REPORT_TIME: "HH:mm",    // 18:29
  FORM_FILTER_TIME: "YYYY-MM-DD",    // 18:29

  GROUP_NOTIFICATION_UNTIL_DATE: "DD/MM/YYYY, HH:mm",   // 15/03/2018, 04:08
  OUT_OF_OFFICE_UNTIL_DATE: "DD/MM/YYYY, HH:mm",   // 15/03/2018, 04:08

  UPLOAD_IMAGE_DATE: "DD/MM/YYYY, HH:mm:ss",   // 15/03/2018, 04:08
  STARRED_MESSAGES_DATE: "DD/MM/YYYY"
};

export const TIME_TWELVE_HOUR_FORMATS = [
  "hh:mm A",
  "h:mmA"
];

export const TIME_TWENTY_FOUR_HOUR_FORMAT = "HH:mm";

export class TnDateFormat {
  key: string;
  format: string;

  constructor(key: string, format: string) {
    this.key = key;
    this.format = format;
  }
}
