import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TeamnoteConfigService } from '../../configs/teamnote-config.service';
import { TranslateManagerService } from '../translate/translate-manager.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { LoggerService } from '../logger/logger.service';
import { TeamnoteApiService } from '../../api/teamnote-api.service';
import { TeamNoteApiConstant } from '../../constants/api.constant';
import { LocalStorageManagerService } from '../local-storage/local-storage-manager.service';
import { TeamNoteLocalStorageKeyConstants } from '../../constants/local-storage-key.constant';
var addDays = require('date-fns/add_days');

export class TAndCResponse {
  company_domain: string;
  create_date: string;
  html_id: string;
  update_date: string;
}

@Component({
  selector: 'tn-t-and-c',
  templateUrl: './t-and-c.component.html',
  styleUrls: ['./t-and-c.component.scss']
})
export class TAndCComponent implements OnInit {

  tAndCHtml: any;

  isNeedAccept: boolean = false;
  acceptCallback: Function;
  declineCallback: Function;
  tAndCData: TAndCResponse;

  constructor(
    public dialogRef: MatDialogRef<TAndCComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _translateManagerService: TranslateManagerService,
    private _http: HttpClient,
    private _domSanitizer: DomSanitizer,
    private _loggerService: LoggerService,
    private _teamnoteApiService: TeamnoteApiService,
    private _localStorageManagerService: LocalStorageManagerService
  ) { }

  ngOnInit() {
    this.dialogRef.updateSize("60vw");

    this.isNeedAccept = this.data.isNeedAccept;
    this.acceptCallback = this.data.acceptCallback;
    this.declineCallback = this.data.declineCallback;
    this.tAndCData = this.data.tAndCData;

    if (this.isNeedAccept) {
      this.dialogRef.disableClose = true;
    }

    this.getCompanyTAndC();
  }

  getCompanyTAndC(): void {
    let serverTAndCCheckingFunc = (tncData: TAndCResponse) => {
      if (!tncData) {
        this.getDefaultTAndC();
        this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_PRESERVE_COOKIES.TNC_CUSTOM_LAST_UPDATE_TIME);
      } else {
        this.getTnCFileByUrl(
          this._teamnoteConfigService.config.HOST.API_HOST + "/static/" + tncData.html_id
        );
        this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_PRESERVE_COOKIES.TNC_CUSTOM_LAST_UPDATE_TIME, tncData.update_date);
      }
    };

    if (this.tAndCData != undefined) {
      serverTAndCCheckingFunc(this.tAndCData);
      return;
    }

    // Check if there are customize T&C first
    let url = TeamNoteApiConstant.GET_TNC;
    this._teamnoteApiService.callApi(
      url,
      {},
      (tncData: TAndCResponse) => {
        serverTAndCCheckingFunc(tncData);
      },
      (err) => {
        this.getDefaultTAndC();
      }
    );

  }

  getDefaultTAndC(): void {
    let currentLang = this._translateManagerService.getCurrentLangKey();
    let tAndCPath = this._teamnoteConfigService.config.WEBCLIENT.GENERAL.TERMS_AND_CONDITION_FILE_PATH[currentLang];
    if (!tAndCPath) {
      tAndCPath = this._teamnoteConfigService.config.WEBCLIENT.GENERAL.TERMS_AND_CONDITION_FILE_DEFAULT;
    }
    this.getTnCFileByUrl(tAndCPath);
  }

  getTnCFileByUrl(url: string): void {
    this._http.get(url, {responseType: 'text'})
      .subscribe(
        (data) => {
          this.tAndCHtml = this._domSanitizer.bypassSecurityTrustHtml(data);
        },
        (err) => {
          this._loggerService.error(err);
        }
      );
  }

  setTnCLastAcceptTime(): void {
    // set last update time
    let tenYearsFromNow = addDays(new Date(), 3650);
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_PRESERVE_COOKIES.TNC_LAST_ACCEPT_TIME, new Date().getTime() / 1000, tenYearsFromNow);
  }

  decline(): void {
    this.declineCallback();
    this.dialogRef.close();
  }

  accept(): void {
    this.setTnCLastAcceptTime();
    this.acceptCallback();
    this.dialogRef.close();
  }

  back(): void {
    this.dialogRef.close();
  }


}
