import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export class ContextMenuItem {
  label?: string;
  labelString?: string;
  action: Function;
}

export class EmojiMenuItem {
  EMOJI: number[];
  LABEL: string;
  emojiDisplays?: any[];
  emojiClickCallback?: Function;
  emojiSendCallback?: Function;
  isSentEmoji?: boolean
}

export class ContextMenu {
  id: string;
  isShow: boolean;
  posX: number;
  posY: number;
  menus: ContextMenuItem[];
  emojis?: EmojiMenuItem;
  isShowEmojiMenu?: boolean;
  messageRow?: number
}

@Injectable()
export class ContextMenuService {

  menu: ContextMenu = new ContextMenu;
  menu$: BehaviorSubject<ContextMenu> = new BehaviorSubject<ContextMenu>(null);
  
  isOpening: boolean = false;

  constructor() { 
    this.hideContextMenu();
  }

  updateMenuSubject(): void {
    this.menu$.next(this.menu);
  }

  showContextMenu(
    posX: number,
    posY: number,
    menus: ContextMenuItem[],
    id?: string,
    emojis?: EmojiMenuItem,
    isShowEmojiMenu?: boolean,
    messageRow?: number
  ): void {
    this.menu.isShow = true
    this.menu.posX = posX
    this.menu.posY = posY
    this.menu.menus = menus
    this.menu.emojis = emojis
    this.menu.isShowEmojiMenu = isShowEmojiMenu
    this.menu.messageRow = messageRow
    this.menu.id = id

    this.isOpening = true
    this.updateMenuSubject()
  }

  hideContextMenu(): void {
    this.menu.isShow = false;
    this.menu.menus = [];
    this.menu.emojis = null;
    this.menu.isShowEmojiMenu = false;
    this.menu.messageRow = null
    this.menu.posX = null;
    this.menu.posY = null;
    this.menu.id = null;
    
    this.isOpening = false;
    this.updateMenuSubject();
  }

}
