import { Injectable } from '@angular/core';


import { AccountService } from '../account/account.service';

@Injectable()
export class WebclientAuthGuardService  {
  constructor(
    private _accountService: AccountService
  ) { }

  canActivate(): boolean {
    // console.error('checking if user is logged in');
    return this.checkIsLogin();
  }

  checkIsLogin(): boolean {
    return this._accountService.userId != '';
  }



}
