import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { WebDAVService } from '../../api/web-dav.service';

@Injectable()
export class WebDavMaterialService {
  constructor(
    private _webDAVService: WebDAVService,
  ) { }
  setAuth(userName: string, password: string) {
    this._webDAVService.setAuth( userName, password, () => {}, () => {});
  }
  TestAuth(success: Function, failure: Function) {
    this._webDAVService.TestAuth(success, failure);
  }
  //
  setPin(path: string, success: Function, failure: Function) {
    this._webDAVService.setPin(path, success, failure);
  }
  unsetPin(path: string, success: Function, failure: Function) {
    this._webDAVService.unsetPin(path, success, failure);
  }
  readPin(path: string, success: Function, failure: Function) {
    this._webDAVService.readPin(path, success, failure);
  }
  unReadPin(path: string, success: Function, failure: Function) {
    this._webDAVService.unreadPin(path, success, failure);
  }
  //
  getReadPaths(path: string, success: Function, failure: Function) {
    this._webDAVService.queryPin(path, success, failure);
  }
  checkNeedData(success: Function, failure: Function) {
    this._webDAVService.checkNeedData(success, failure);
  }
  getPinPaths(path: string, success: Function, failure: Function) {
    this._webDAVService.queryPin(path, success, failure);
  }
  getFile(path: string, success: Function, failure: Function) {
    this._webDAVService.getFolder(path, success, failure);
  }
  getWebDavMaterialList(url: string, success: Function, failure: Function) {
    this._webDAVService.getList(url, '1', (data) => success(data), failure);
  }
  getWebDavMaterialFile(url: string, success: Function, failure: Function) {
   this._webDAVService.download(url, (data) => success(data), failure);
  }
}
