import { Message } from "./message";


export class EmojiDisplay {
  emojiStr: string;
  filePath: string;
  count: any;
  isSent: boolean;
}

export class News {
  // originalMessage: Message;
  originalMessage ?: Message;
  isNews: boolean;
  isThreadOpen: boolean;

  attachments: object[];
  images: string;
  media: string;
  previewImageH: string;
  previewImageV: string;

  title: string;
  date: number;
  htmlContent: any;

  iconImgSrc: string;
  emojiCounts: EmojiDisplay[];
  likeCount: any;
  likeAction: string;
  isLiked: boolean;
  commentCount: number;
  contentFragment: string;
}

export class NewsCustomRouteParam {
  chatId?: string;
  chatIdKey?: string;
  title?: string;
  titleTranslateKey?: string;
  isReadOnly?: boolean;               // This will override module config of emoji and comment, and only allow reading News

  constructor(chatId?: string, chatIdKey?: string, titleTranslateKey?: string, isReadOnly?: boolean, title?: string) {
    this.chatId = chatId;
    this.chatIdKey = chatIdKey;
    this.title = title;
    this.titleTranslateKey = titleTranslateKey;
    this.isReadOnly = isReadOnly;
  }
}