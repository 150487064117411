import { Injectable } from '@angular/core';
import { Question } from '../../models/questionnaire';
import { TeamNoteQuestionnaireConstant } from '../../constants/questionnaire.constant';

import * as _ from 'lodash';
@Injectable()
export class QuestionCheckboxService {

  constructor() { }

  getParsedCheckboxQuestion(q: Question): Question {
    q.checkboxDetail = JSON.parse(q.detail);
    q.value = "";
    q.is_mandatory = true;
    q.checkboxDetail.options = _.map(q.checkboxDetail.options, (o) => {
      o.value = false;
      o.hasError = false;
      o.textAns = "";
      return o;
    });
    return q;
  }

  getCheckedCheckboxQuestion(q: Question): Question {
    let isAnyChecked = false;
    _.each(q.checkboxDetail.options, (o) => {
      if (o.value) {
        isAnyChecked = true;
      }
      if (o.free_text === TeamNoteQuestionnaireConstant.EXTRA_FREE_TEXT.MANDATORY &&
        o.value &&
        o.textAns.length == 0) {
        q.hasError = true;
      }
    });
    if (!isAnyChecked) {
      q.hasError = true;
    }
    return q;
  }

  getParsedCheckboxAnswer(q: Question): any {
    let ansArr = [];
    _.each(q.checkboxDetail.options, (o) => {
      if (o.value) {
        ansArr.push(o.option);
        if (o.textAns != '') {
          ansArr.push(o.textAns);
        }
      }
    });
    return ansArr;
  }
}
