export const ZhChtJobReportStrings = {
  ITEM_REF: "Ref: {{REF}}",

  LIST_FORMAT: {
    IMAGE: {
      ONE: "圖片",
      OTHER: " 圖片"
    },
    FILE: {
      ONE: "文件",
      OTHER: " 文件"
    },
    USER: {
      ONE: " 用戶",
      OTHER: " 用戶"
    },
    ATTACHMENT: {
      ONE: " 附件",
      OTHER: " 附件"
    }
  },

  EXPORT: {
    REPORT: "導出報告",
    JOB: "導出指派工作",
    TIME_PERIOD: "時間範圍",
    IN_PROGRESS_MSG: "資料導出中，你可以在右上角的通知中心查看進度，你將在檔案準備完成後收到通知。",
    EXPORT_READY_MSG: "檔案已準備完成，你可以在通知中心下載檔案。"
  },

  STATUS: {
    NOT_ASSIGNED: "未分派",
    PENDING: "等候回應中",
    ACCEPTED: "已接受",
    REJECTED: "已拒絕",
    STARTED: "已開始",
    FINISHED: "已完成",
    NEED_FOLLOW_UP: "待跟進"
  },

  NEW_REPORT: {
    STORE: "店鋪",
    TYPE: "類型",
    TITLE: "新增報告",
    SUBMIT: "提交",
    INPUT_MANDATORY: "請輸入所有必填項目",
    CONFIRM_SUBMIT: "確定要發送這個報告嗎?",
    SUBMIT_SUCCESS: "報告已發送",
    FREE_TEXT_OPTION: "其他 (請註明)"
  },

  MSG: {
    NO_STORE_OPTION: "沒有可用的店鋪選項",
    NO_TYPE_OPTION: "沒有可用的類型選項",
    ASSIGN_SUCCESS: "分派成功",
    ASSIGN_NO_PERMISSION: "沒有權限"
  },

  REPORT_DETAIL: {
    TITLE: "詳細報告",
    WORKER: "負責人士",
    STATUS: "狀態",
    START_TIME: "開始時間",
    END_TIME: "完結時間",
    JOB_RESPONSE: "工作回應"
  },
  
  ACTION: {
    ACCEPT_BTN: "接受",
    REJECT_BTN: "拒絕",
    START_BTN: "開始",
    END_BTN: "完結",
    NEED_FOLLOW_UP_BTN: "再分配",
    ASSIGN: "分派"
  },

  FILTER: {
    REPORT: "篩選報告",
    JOB: "篩選工作",
    REPORT_DATE: "報告日期",
    JOB_DISPATCH_DATE: "指派日期",
    TO: "至",
    STORE: "店舖",
    TEMPLATE: "表單範本",
    STATUS: "工作狀態",
    APPLY: "應用",
    CONTENT: "內容"
  }


}
