<div class="report-submit-container">

  <tn-page-title [title]="'WEBCLIENT.JOB_REPORT.NEW_REPORT.TITLE' | translate">

    <div class="left-control">
      <div class="back-button clickable" (click)="reportSubmitBack()">
        <i class="fa fa-fw fa-angle-left"></i>
      </div>
    </div>

    <div class="right-control">

      <div class="report-submit-button-wrapper"
        (click)="submit()" 
        *ngIf="isFinishSelection">
        <div class="report-submit-button"></div>
      </div>
      
    </div>
  </tn-page-title>

  <div class="report-input-container">
    <tn-report-option 
      [customParam]="customParam"
      [(selectedStore)]="selectedStore" 
      [(selectedType)]="selectedType" 
      (onFinishSelect)="onFinishSelect($event)"
      *ngIf="!isFinishSelection">
    </tn-report-option>

    <tn-report-input
      [store]="selectedStore"
      [type]="selectedType"
      [report]="report"
      [(mappedInputCustomFields)]="mappedInputCustomFields"
      *ngIf="isFinishSelection">
    </tn-report-input>
  </div>
</div>