import { Injectable } from '@angular/core';
import { StoreReportType, StoreReport, JobDispatch, StoreReportCustomFieldsAnswer, StoreReportCustomFieldsDefinition } from '../models/job-report';
import { JobReportService } from '../job-report.service';

import * as _ from 'lodash';
import { TimestampService } from '../../../utilities/timestamp/timestamp.service';
import { JobReportConstant } from '../constants/job-report.constants';
import { FieldAttachmentParsedAnswer } from '../custom-field/field-attachment/field-attachment.component';
import { FieldLocationAnswer } from '../custom-field/field-geolocation-input/field-geolocation-input.component';
import { FieldAttachmentDocumentParsedAnswer } from '../custom-field/field-attachment-document/field-attachment-document.component';
import { UserContactService } from '../../services/data/user-contact/user-contact.service';
import { DateService } from '../../../utilities/date/date.service';
import { AccountService } from '../../../account/account.service';
import { FieldContactDetail } from '../custom-field/field-contact/field-contact.component';
import { TextMessagePart } from '../../../utilities/linkify/linkify.service';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class CellFormatHelperService {

  fieldIdRegEx = /%%fieldId\?=(.*)%%/;

  defaultReportListFormat = "<strong>%%storeName%% - %%typeName%%</strong><br><span style=\"color: #909192\"><font color=\"#909192\">%%reportDate%%</font></span><br><span style=\"color: #909192\"><font color=\"#909192\">Ref:%%refId%%</font></span>";
  defaultJobListFormat = "<strong>%%storeName%% - %%typeName%%</strong><br><span style=\"color: #909192\"><font color=\"#909192\">%%jobDispatchDate%%</font></span><br><span style=\"color: #909192\"><font color=\"#909192\">Ref:%%refId%%</font></span>";

  myNameNotifyFormat = "<span style=\"color: #ff0000\">{{myName}}</span>";

  typeId: string;
  type: StoreReportType = null;
  isJobDispatch: boolean = false;

  constructor(
    private _jobReportService: JobReportService,
    private _timestampService: TimestampService,
    private _userContactService: UserContactService,
    private _dateService: DateService,
    private _accountService: AccountService,
    private _domSanitizer: DomSanitizer
  ) { }

  getDisplayingListCellInfoByType(report?: StoreReport, job?: JobDispatch, format?: string, isTextDisplay?: boolean): string {
    let listFormat;
    let storeName;
    let typeName;
    let reportDate;
    let jobDispatchDate;
    let reportNo;
    let customFields;
    let creator;
    let assigner;

    if (report) {
      this.isJobDispatch = false;
      this.typeId = report.type_id;
      this.type = null;

      listFormat = report.type.list_format ? report.type.list_format : this.defaultReportListFormat;
      storeName = report.store.name;
      typeName = report.type.name;
      reportDate = report.create_date ? this._dateService.getDateDisplayWithKey(report.create_date, "JOB_DISPATCH_LIST_ITEM_DATE") : "";
      jobDispatchDate = "";
      reportNo = report.report_no;
      customFields = report.custom_fields;
      if (report.job_dispatch.length > 0) {
        customFields = _.union(customFields, report.job_dispatch[0].custom_fields);
      }
      creator = report.creator_name;
      assigner = report.job_dispatch.length > 0 ? (report.job_dispatch[0].assigned_by ? report.job_dispatch[0].assigner_name : "") : "";
    } else {
      this.isJobDispatch = true;
      this.typeId = job.type_id;
      this.type = null;

      listFormat = job.type.list_format ? job.type.list_format : this.defaultJobListFormat;
      storeName = job.report.store.name;
      typeName = job.type.name;
      reportDate = job.report.create_date ? this._dateService.getDateDisplayWithKey(job.report.create_date, "JOB_DISPATCH_LIST_ITEM_DATE") : "";
      jobDispatchDate = job.dispatch_date ? this._dateService.getDateDisplayWithKey(job.dispatch_date, "JOB_DISPATCH_LIST_ITEM_DATE") : "";
      reportNo = job.report.report_no;
      customFields = _.union(job.custom_fields,job.report.custom_fields);
      creator = job.report.creator_name;
      assigner = job.assigned_by ? job.assigner_name : "";
    }

    if (isTextDisplay) {
      listFormat = format;
    }

    return this.listFormatToContent(
      listFormat,
      storeName,
      typeName,
      reportDate,
      jobDispatchDate,
      reportNo,
      creator,
      assigner,
      customFields,
      isTextDisplay
    );
  }

  listFormatToContent(listFormat: string, storeName: string, typeName: string, reportDate: string, jobDispatchDate: string, reportNo: string, creator: string, assigner: string, customFields: StoreReportCustomFieldsAnswer[], isTextDisplay: boolean): string {
    let content = listFormat;

    content = content
      .replace(JobReportConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.STORE_NAME, storeName ? storeName : "")
      .replace(JobReportConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.TYPE_NAME, typeName ? typeName : "")
      .replace(JobReportConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.REPORT_DATE, reportDate ? reportDate : "")
      .replace(JobReportConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.JOB_DISPATCH_DATE, jobDispatchDate ? jobDispatchDate : "")
      .replace(JobReportConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.REPORT_NO, reportNo ? reportNo : "")
      .replace(JobReportConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.CREATOR, creator ? creator : "")
      .replace(JobReportConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.ASSIGNER, assigner ? assigner : "");

    do {
      let fieldIdMatch = this.fieldIdRegEx.exec(content);
      if (fieldIdMatch) {
        let found = fieldIdMatch[1];
        let breakIndex = found.indexOf("%%");
        let fieldId = breakIndex != -1 ? found.substr(0, breakIndex) : found;
        let fieldContent = this.getDisplayFormatOfFieldByFieldId(fieldId, customFields, isTextDisplay);
        let toBeReplacedMagicString = JobReportConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.FIELD_ID.replace("{{fieldId}}", fieldId);
        content = content.replace(toBeReplacedMagicString, fieldContent);
      } else {
        break;
      }
    } while (true);

    return content;
  }

  handleSpcialLocalTranslateStrings(listFormat: string): TextMessagePart[] {
    let localTranslateKeys = _.keys(JobReportConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.LOCAL_TRANSLATE);

    let tempFullStr = listFormat;
    let arr: TextMessagePart[] = [];

    let localTranslateRegEx = new RegExp("(" + localTranslateKeys.join("|") + ")", "g");
    let localTranslateMatch = listFormat.match(localTranslateRegEx);
    if (localTranslateMatch) {
      let keyIndex = 0;
      while (keyIndex < localTranslateMatch.length) {
        let key = localTranslateMatch[keyIndex];
        let index = tempFullStr.indexOf(localTranslateMatch[keyIndex]);
        if (index == -1) {
          // doesn't exist this key, all str is html
          arr.push(new TextMessagePart(false, this._domSanitizer.bypassSecurityTrustHtml(tempFullStr)));
        } else {
          // str before key is html
          arr.push(new TextMessagePart(false, this._domSanitizer.bypassSecurityTrustHtml(tempFullStr.substr(0, index))));
          // key = translate key
          arr.push(new TextMessagePart(false, JobReportConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.LOCAL_TRANSLATE[key].translate, true));
          // str after key will be for next iteration
          tempFullStr = tempFullStr.substr(index + key.length);
        }
        keyIndex++;
      }
      // add remaining str as html
      if (tempFullStr) {
        arr.push(new TextMessagePart(false, this._domSanitizer.bypassSecurityTrustHtml(tempFullStr)));
      }
    } else {
      arr.push(new TextMessagePart(false, this._domSanitizer.bypassSecurityTrustHtml(tempFullStr)));
    }
    return arr;
  }

  getDisplayFormatOfFieldByFieldId(fieldId: string, customFields: StoreReportCustomFieldsAnswer[], isTextDisplay: boolean): string {
    if (!this.type) {
      this.type = this._jobReportService.getTypeByTypeId(this.typeId);
    }
    let fieldCandidates = _.union(this.type.job_type.custom_fields, this.type.custom_fields);
    let fieldDefinition = _.find(fieldCandidates, {field_id: fieldId});
    if (!fieldDefinition) {
      return "";
    }
    let fieldAnswer = _.find(customFields, {field_id: fieldId});
    if (!fieldAnswer) {
      return "";
    }

    return this.getFormattedDisplayFieldAnswer(fieldDefinition, fieldAnswer, isTextDisplay);
  }

  getFormattedDisplayFieldAnswer(definition: StoreReportCustomFieldsDefinition, answer: StoreReportCustomFieldsAnswer, isTextDisplay: boolean): string {
    switch (definition.field_type) {
      case JobReportConstant.CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE:
        return this.getFormattedMutipleChoiceAnswer(answer);
      case JobReportConstant.CUSTOM_FIELD_TYPE.FREE_TEXT:
        return this.getFormattedFreeTextAnswer(answer);
      case JobReportConstant.CUSTOM_FIELD_TYPE.CHECKBOX:
        return this.getFormattedCheckboxAnswer(answer);
      case JobReportConstant.CUSTOM_FIELD_TYPE.DROPDOWN:
        return this.getFormattedDropdownAnswer(answer);
      case JobReportConstant.CUSTOM_FIELD_TYPE.CONTACT:
        return this.getFormattedContactAnswer(answer, definition, isTextDisplay);
      case JobReportConstant.CUSTOM_FIELD_TYPE.ATTACHMENT_PHOTO:
        return this.getFormattedAttachmentPhotoAnswer(answer);
      case JobReportConstant.CUSTOM_FIELD_TYPE.DATE:
        return this.getFormattedDateAnswer(answer);
      case JobReportConstant.CUSTOM_FIELD_TYPE.TIME:
        return this.getFormattedTimeAnswer(answer);
      case JobReportConstant.CUSTOM_FIELD_TYPE.GEOLOCATION_USER_INPUT:
      case JobReportConstant.CUSTOM_FIELD_TYPE.GEOLOCATION_DETECTION:
        return this.getFormattedGeolocationAnswer(answer);
      case JobReportConstant.CUSTOM_FIELD_TYPE.CODE_SCAN:
      case JobReportConstant.CUSTOM_FIELD_TYPE.TEXT_DISPLAY:
        return "";
      case JobReportConstant.CUSTOM_FIELD_TYPE.ATTACHMENT_DOCUMENT:
        return this.getFormattedAttachmentDocumentAnswer(answer);
      default:
        break;
    }
    return "";
  }

  getFormattedMutipleChoiceAnswer(answer: StoreReportCustomFieldsAnswer): string {
    return answer.value;
  }

  getFormattedFreeTextAnswer(answer: StoreReportCustomFieldsAnswer): string {
    return answer.value;
  }

  getFormattedCheckboxAnswer(answer: StoreReportCustomFieldsAnswer): string {
    let checkeds = [];
    try {
      checkeds = JSON.parse(answer.value);
    } catch (e) {};
    return checkeds.join(", ");
  }

  getFormattedDropdownAnswer(answer: StoreReportCustomFieldsAnswer): string {
    return answer.value;
  }

  getFormattedContactAnswer(answer: StoreReportCustomFieldsAnswer, definition: StoreReportCustomFieldsDefinition, isTextDisplay: boolean): string {
    let contactIds = [];
    try {
      contactIds = JSON.parse(answer.value);
    } catch (e) {};

    let display = "-";

    let myName = null;

    let selfContactIndex = contactIds.indexOf(this._accountService.userId);
    if (selfContactIndex != -1) {
      // It has "ME"
      let detail: FieldContactDetail = JSON.parse(definition.detail);
      // let c = this._userContactService.getUserContactByUserId(this._accountService.userId);
      // if (c) {
        myName = this._accountService.name;
        if (detail.is_notify && !isTextDisplay) {
          myName = this.myNameNotifyFormat.replace("{{myName}}", myName);
        }
      // }
    }

    if (contactIds.length == 1) {
      if (myName) {
        display = myName;
      } else {
        let c = this._userContactService.getUserContactByUserId(contactIds[0]);
        if (c) {
          display = c.name;
        }
      }
    } else if (contactIds.length > 1) {
      if (myName) {
        display = myName + " & " + (contactIds.length - 1);
        if (contactIds.length == 2) {
          display += JobReportConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.LOCAL_TRANSLATE["%%userOne%%"].key;
        } else {
          display += JobReportConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.LOCAL_TRANSLATE["%%userOther%%"].key
        }
      } else {
        display = contactIds.length + JobReportConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.LOCAL_TRANSLATE["%%userOther%%"].key
      }
    }

    return display;
  }

  getFormattedAttachmentPhotoAnswer(answer: StoreReportCustomFieldsAnswer): string {
    let photos: FieldAttachmentParsedAnswer[] = [];
    try {
      photos = JSON.parse(answer.value);
    } catch (e) {};
    if (photos.length == 0) {
      return "";
    }
    if (photos.length == 1) {
      if (typeof photos[0] != "string") {
        if (photos[0].caption) {
          return photos[0].caption;
        }
      }
      return JobReportConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.LOCAL_TRANSLATE["%%imageOne%%"].key;
    }
    return photos.length + JobReportConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.LOCAL_TRANSLATE["%%imageOther%%"].key;
  }

  getFormattedDateAnswer(answer: StoreReportCustomFieldsAnswer): string {
    if (answer.value.length != 0) {
      return this._dateService.getDateDisplayWithKey(
        answer.value,
        "JOB_DISPATCH_DETAIL_REPORT_DATE"
      );
    }
    return "";
  }

  getFormattedTimeAnswer(answer: StoreReportCustomFieldsAnswer): string {
    if (answer.value.length != 0) {
      return this._dateService.getDateDisplayWithKey(
        answer.value,
        "JOB_DISPATCH_DETAIL_REPORT_TIME"
      );
    }
    return "";
  }

  getFormattedGeolocationAnswer(answer: StoreReportCustomFieldsAnswer): string {
    let location: FieldLocationAnswer = {address: "--", lat: 0, lng: 0};
    try {
      if (answer.value.length != 0) {
        location = JSON.parse(answer.value);
      }
    } catch (e) {};
    if (location.address) {
      return location.address;
    }
    return "";
  }

  getFormattedAttachmentDocumentAnswer(answer: StoreReportCustomFieldsAnswer): string {
    let docs: FieldAttachmentDocumentParsedAnswer[] = [];
    try {
      docs = JSON.parse(answer.value);
    } catch (e) {};
    if (docs.length == 0) {
      return "";
    }
    if (docs.length == 1) {
      return docs[0].name;
    } 
    return docs.length + JobReportConstant.LIST_FORMAT_MAGIC_STRING_PLACEHOLDER.LOCAL_TRANSLATE["%%fileOther%%"].key;
  }

}
