export const AMQPRoutingKey = {
  CORRELATION_ID: {
    GET_ROSTER: 'GET_ROSTER',
    LOAD_RECENT_MESSAGE: 'LOAD_RECENT_MESSAGE',
    LOAD_OFFLINE_MESSAGE: 'LOAD_OFFLINE_MESSAGE',
    LOAD_OFFLINE_PRESENCE: 'LOAD_OFFLINE_PRESENCE',
    LOAD_HISTORY: 'LOAD_HISTORY',
    SUBSCRIBE_MESSAGE_DETAIL: 'SUBSCRIBE_MESSAGE_DETAIL',
    SEND_EMOJI: 'SEND_EMOJI',
    SWAP_EMOJI: 'SWAP_EMOJI',
    UNSEND_EMOJI: 'UNSEND_EMOJI',
    DECLARE_GROUP_CHAT: 'DECLARE_GROUP_CHAT',
    LEAVE_CHAT: 'LEAVE_CHAT',
    CHANGE_CHAT_CREATOR: 'CHANGE_CHAT_CREATOR',
    SEND_COMMENT: 'SEND_COMMENT'
  },

  GET_ROSTER: '/request.get_roster',
  GET_CONTACT: '/request.get_contact',
  GET_USERGROUP_CONTACT: '/request.get_usergroup_contact',

  LOAD_RECENT_MESSAGE: '/request.load_recent_message',
  LOAD_OFFLINE_MESSAGE: '/request.load_offline_message',
  LOAD_OFFLINE_PRESENCE: '/request.load_offline_presence',
  
  LOAD_HISTORY: '/request.load_history',
  SUBSCRIBE_CHAT: '/request.subscribe_chat',
  DECLARE_USER_CHAT: '/request.declare_user_chat',
  DECLARE_GROUP_CHAT: '/request.declare_group_chat',
  LEAVE_CHAT: '/request.leave_chat',
  KICK_MEMBER: '/request.kick_member',
  KICK_ALL_MEMBERS: '/request.kick_all_members',
  ADD_CHAT_ADMIN: '/request.add_chat_admin',
  CHANGE_CHAT_CREATOR: '/request.change_chat_creator',
  MUTE_CHAT: '/request.mute_chat',
  UNMUTE_CHAT: '/request.unmute_chat',

  SEND_MESSAGE: '/{chat_id}',
  SEND_COMMENT: '/comment.{message_id}',
  SEND_EMOJI: '/emoji.{message_id}',
  SWAP_EMOJI: '/emojiswap.{message_id}',
  UNSEND_EMOJI: '/unemoji.{message_id}',
  SUBSCRIBE_MESSAGE_DETAIL: '/request.subscribe_message_detail',

  DECLARE_PROFILE: '/request.declare_profile',
  DECLARE_USER_STATE: '/request.declare_user_state',

  REGISTER_NOTIFICATION: '/request.register_notification',


};