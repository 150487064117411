export const NotificationType = {
  NEWS: "news",
  CHAT: "chat",
  CRONJOB: "cronjob"
}

export class TeamNoteNotification {
  id?: string;
  type?: string;

  body?: any;

  onClick?: Function;
  updateTime?: any;


	constructor(id?: string, type?: string, body?: any, onClick?: Function, updateTime?: any) {
    this.id = id;
    this.type = type;

    this.body = body;

    this.onClick = onClick;
    this.updateTime = updateTime;
	}

}
