import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StoreReportCustomFieldsMapped } from '../../models/job-report';

import * as _ from 'lodash';

export class FieldCheckboxDetail {
  options: string[];
}
export class FieldCheckboxParsedOptionAnswer {
  option: string;
  isChecked: boolean;
}

@Component({
  selector: 'tn-field-checkbox',
  templateUrl: './field-checkbox.component.html',
  styleUrls: ['./field-checkbox.component.scss']
})
export class FieldCheckboxComponent implements OnInit {

  @Input() field: StoreReportCustomFieldsMapped;
  @Input() isInput: boolean = false;

  @Output() onFieldUpdate: EventEmitter<StoreReportCustomFieldsMapped> = new EventEmitter<StoreReportCustomFieldsMapped>(null);

  detail: FieldCheckboxDetail;
  answers: string[];
  parsedAnswers: FieldCheckboxParsedOptionAnswer[];

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.detail = JSON.parse(this.field.definition.detail);
    if (this.field.answer.value) {
      this.answers = JSON.parse(this.field.answer.value);
    } else {
      this.answers = [];
    }

    this.parsedAnswers = _.map(this.detail.options, (o) => {
      let ans = new FieldCheckboxParsedOptionAnswer;
      ans.isChecked = _.indexOf(this.answers, o) !== -1;
      ans.option = o;
      return ans;
    });

  }

  getAnswerValue(): string {
    let checkedAns = _.filter(this.parsedAnswers, (a) => {
      return a.isChecked;
    });
    let checkedOption = _.map(checkedAns, 'option');
    return JSON.stringify(checkedOption);
  }

  onChange() {
    this.field.answer.value = this.getAnswerValue();
    this.onFieldUpdate.emit(this.field);
  }

}
