<div class="custom-field-container" 
  [ngClass]="{'no-background-color': field.definition.field_type == FIELD_TYPE.TEXT_DISPLAY}"
  *ngIf="field.definition.field_type != FIELD_TYPE.GEOLOCATION_DETECTION">


  <ng-container *ngIf="field.definition.field_type == FIELD_TYPE.TEXT_DISPLAY">
    <tn-field-text-display 
      [field]="field"
      [report]="report">
    </tn-field-text-display>
  </ng-container>

  <ng-container *ngIf="field.definition.field_type != FIELD_TYPE.TEXT_DISPLAY">
    
    <tn-field-title 
      [fieldDefinition]="field.definition" 
      [isInput]="isInput" 
      *ngIf="field.definition.field_type != FIELD_TYPE.GEOLOCATION_DETECTION">
    </tn-field-title>

    <div class="field-answer-wrapper">

      <ng-container *ngIf="!isInput && (!field.answer || !field.answer.value)">
        <div class="empty-answer">
          --
        </div>
      </ng-container>

      <ng-container *ngIf="field.answer">
        <ng-container [ngSwitch]="field.definition.field_type">
      
          <ng-container *ngSwitchCase="FIELD_TYPE.MULTIPLE_CHOICE">
            <tn-field-multiple-choice 
              [field]="field" 
              [isInput]="isInput" 
              (onFieldUpdate)="onFieldUpdate($event)">
            </tn-field-multiple-choice>
          </ng-container>

          <ng-container *ngSwitchCase="FIELD_TYPE.FREE_TEXT">
            <tn-field-free-text 
              [field]="field" 
              [isInput]="isInput" 
              (onFieldUpdate)="onFieldUpdate($event)">
            </tn-field-free-text>
          </ng-container>
          
          <ng-container *ngSwitchCase="FIELD_TYPE.CHECKBOX">
            <tn-field-checkbox 
              [field]="field" 
              [isInput]="isInput" 
              (onFieldUpdate)="onFieldUpdate($event)">
            </tn-field-checkbox>
          </ng-container>
          
          <ng-container *ngSwitchCase="FIELD_TYPE.DROPDOWN">
            <tn-field-dropdown 
              [field]="field" 
              [isInput]="isInput" 
              (onFieldUpdate)="onFieldUpdate($event)">
            </tn-field-dropdown>
          </ng-container>
          
          <ng-container *ngSwitchCase="FIELD_TYPE.CONTACT">
            <tn-field-contact 
              [field]="field" 
              [isInput]="isInput" 
              [store]="store"
              (onFieldUpdate)="onFieldUpdate($event)">
            </tn-field-contact>
          </ng-container>

          <ng-container *ngSwitchCase="FIELD_TYPE.ATTACHMENT_PHOTO">
            <tn-field-attachment 
              [field]="field" 
              [isInput]="isInput" 
              (onFieldUpdate)="onFieldUpdate($event)">
            </tn-field-attachment>
          </ng-container>

          <ng-container *ngSwitchCase="FIELD_TYPE.DATE">
            <tn-field-date 
              [field]="field" 
              [isInput]="isInput" 
              (onFieldUpdate)="onFieldUpdate($event)">
            </tn-field-date>
          </ng-container>

          <ng-container *ngSwitchCase="FIELD_TYPE.TIME">
            <tn-field-time 
              [field]="field" 
              [isInput]="isInput" 
              (onFieldUpdate)="onFieldUpdate($event)">
            </tn-field-time>
          </ng-container>

          <ng-container *ngSwitchCase="FIELD_TYPE.GEOLOCATION_USER_INPUT">
            <tn-field-geolocation-input 
              [field]="field" 
              [isInput]="isInput" 
              (onFieldUpdate)="onFieldUpdate($event)">
            </tn-field-geolocation-input>
          </ng-container>

          <ng-container *ngSwitchCase="FIELD_TYPE.ATTACHMENT_DOCUMENT">
            <tn-field-attachment-document 
              [field]="field" 
              [isInput]="isInput" 
              (onFieldUpdate)="onFieldUpdate($event)">
            </tn-field-attachment-document>
          </ng-container>

        </ng-container>
      </ng-container>
    </div>

  </ng-container>
</div>

<tn-field-geolocation-detection
  *ngIf="isInput && field.definition.field_type == FIELD_TYPE.GEOLOCATION_DETECTION"
  [field]="field" 
  [isInput]="isInput" 
  (onFieldUpdate)="onFieldUpdate($event)">
</tn-field-geolocation-detection>