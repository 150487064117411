import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StoreReportCustomFieldsMapped } from '../../models/job-report';
import { TimestampService } from '../../../../utilities/timestamp/timestamp.service';

import * as _ from 'lodash';
import { DateService } from '../../../../utilities/date/date.service';

@Component({
  selector: 'tn-field-time',
  templateUrl: './field-time.component.html',
  styleUrls: ['./field-time.component.scss']
})
export class FieldTimeComponent implements OnInit {

  @Input() field: StoreReportCustomFieldsMapped;
  @Input() isInput: boolean = false;

  @Output() onFieldUpdate: EventEmitter<StoreReportCustomFieldsMapped> = new EventEmitter<StoreReportCustomFieldsMapped>(null);

  timeDisplay: string;

  timeInput: Date = null;

  // TODO: handle 12/24 hour format

  constructor(
    private _timestampService: TimestampService,
    private _dateService: DateService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.timeDisplay = this._dateService.getDateDisplayWithKey(
      this.field.answer.value,
      "JOB_DISPATCH_DETAIL_REPORT_TIME"
    );
  }

  getAnswerValue(): string {
    return this.timeInput ? this._timestampService.dateObjToSecondString(this.timeInput) : "";;
  }

  onChange() {
    this.field.answer.value = this.getAnswerValue();
    this.onFieldUpdate.emit(this.field);
  }

}
