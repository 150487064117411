
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import _ from 'lodash';
import { tap } from 'rxjs/operators';
import { TeamnoteConfigService } from '../configs/teamnote-config.service';
import { LoggerService } from '../utilities/logger/logger.service';
import { TnLoaderService } from '../utilities/tn-loader/tn-loader.service';
import { Parser, processors } from 'xml2js';
import { ReadObject, ModuleConfig } from '../webclient/webdav-material/models/webdav-material';
import { TeamnoteApiService } from './teamnote-api.service';
import { LocalStorageManagerService } from '../utilities/local-storage/local-storage-manager.service';
import { TeamNoteLocalStorageKeyConstants } from '../constants/local-storage-key.constant';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class WebDAVService implements OnInit {
  // serverHost: string; // this._teamnoteConfigService.config.WEB_DAV.HOST;
  // rootPath = '/webdav/'; // this._teamnoteConfigService.config.WEB_DAV.ROOT_PATH;
  content = '<?xml version="1.0" encoding="utf-8" ?><propfind xmlns="DAV:"><allprop/></propfind>';
  propFindMethod = 'PROPFIND';
  moveMethod = 'MOVE';
  copyMethod = 'COPY';
  mkColMethod = 'MKCOL';
  // tslint:disable-next-line:max-line-length
  // auth: string; //  this._teamnoteConfigService.config.WEB_DAV.USERNAME + ':' + this._teamnoteConfigService.config.WEB_DAV.PASSWORD;
  token: string;
  pinFiles: string[] = [];
  // tslint:disable-next-line:max-line-length
  readFiles: ReadObject = { href: '/webdav-pin/', children: [] };
  ngOnInit(): void {
  }

  OnInit(): void {
  }
  private showLoading(): void {
    this._tnLoaderService.showLoading();
  }
  private hideLoading(): void {
    this._tnLoaderService.hideLoading();
  }
  private TestAuthAcc(url: string, token: string, name: string, password: string, success: Function, failure: Function) {

    this._http
      .post(url, null, {
        params: {
          access_token: token,
          smb_username: name,
          smb_password: password
        }, withCredentials: true
      })
      .pipe(
        tap(() => this.hideLoading(), () => this.hideLoading(), () => this.hideLoading())
      )
      .subscribe(
        (d: { success: boolean }) => {
          if (d.success) {
            success();
          } else {
            failure();
          }
        },
        (e) => { failure(e); });

  }
  TestAuth(success: Function, failure: Function) {
    const token = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN);
    const userName = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.WEBDAV.USERNAME);
    const password = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.WEBDAV.PASSWORD);
    const credentialDomains = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.LOGIN.CREDDOMAIN);
    this._teamnoteApiService.getModuleConfig(token,
      (resp: ModuleConfig) => {
        const host: string = resp.modules.file_sharing.host;
        const url = `${host}/auth`;
        if (host.includes(credentialDomains)) {
          const loginUserName = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.LOGIN.USERNAME);
          const loginPassword = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.LOGIN.PASSWORD);
          if (loginUserName && loginUserName) {
            // 若有帳密cookie 先測試Login 帳密
            // 再來未通過再測試 webdav cookie
            this.TestAuthAcc(url, token, loginUserName, loginPassword,
              () => {
                this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.WEBDAV.USERNAME, loginUserName);
                this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.WEBDAV.PASSWORD, loginPassword);
                success();
              }, () => this.TestAuthAcc(url, token, userName, password, success, failure));
          } else {
            this.TestAuthAcc(url, token, userName, password, success, failure);
          }
        } else if (userName && password) {
          // 直接測試Login 帳密
          this.TestAuthAcc(url, token, userName, password, success, failure);
        } else {
          failure();
        }
      }, () => failure);
  }
  checkNeedData(success: Function, failure: Function) {
    const username = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.WEBDAV.USERNAME);
    const password = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.WEBDAV.PASSWORD);

    if (username && password) {
      success();
    } else {
      failure();
    }
  }
  getFolder(path = '/', success: Function, failure: Function) {
    // this.showLoading();
    const token = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN);
    let serverHost: string;
    this._teamnoteApiService.getModuleConfig(token,
      (data: ModuleConfig) => {
        serverHost = data.modules.file_sharing.host as string;
        let headers = new HttpHeaders({ 'Depth': '0' });
        headers = this.setDefaultHeader(headers);
        if (path) { serverHost += path; }
        return this._http.request(
          this.propFindMethod, serverHost,
          { body: this.content, headers: headers, withCredentials: true, responseType: 'text' })
          .pipe(
            tap(() => this.hideLoading(), () => this.hideLoading(), () => this.hideLoading())
          )
          .subscribe(
            (resp) => {
              this._loggerService.info(_.join([serverHost, resp], '\n'));
              xmlToJson(resp, success, failure);
            },
            (error) => {
              // this._loggerService.error(_.join([serverHost, error.status + '-' + error.statusText + '-' + error.error], '\n'));
              failure(error);
            }
          );
      }, (err) => {
        // this._loggerService.error(_.join([serverHost, err.status + '-' + err.statusText + '-' + err.error], '\n'));
        failure(err);
      });
  }
  getList(path: string, depth = '1', success: Function, failure: Function) {
    // this.showLoading();
    const token = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN);
    let serverHost: string;
    this._teamnoteApiService.getModuleConfig(token,
      (data: ModuleConfig) => {
        serverHost = data.modules.file_sharing.host as string;
        let headers = new HttpHeaders({
          'Depth': depth
        });
        headers = this.setDefaultHeader(headers);
        if (path) {
          serverHost += path;
          // console.error(serverHost);
        }
        return this._http.request(
          this.propFindMethod,
          serverHost,
          { body: this.content, headers: headers, withCredentials: true, responseType: 'text' })
          .pipe(
            tap(() => this.hideLoading(), () => this.hideLoading(), () => this.hideLoading())
          )
          .subscribe(
            (resp) => {
              this._loggerService.info(_.join([serverHost, resp], '\n'));
              xmlToJson(resp, success, failure);
            },
            (error: HttpErrorResponse) => {
              this._loggerService.error(_.join([serverHost, error.status + '-' + error.statusText + '-' + error.error], '\n'));
              failure(error);
            }
          );
      }, (err) => {
        this._loggerService.error(_.join([serverHost, err.status + '-' + err.statusText + '-' + err.error], '\n'));
        failure(err);
      });
  }
  download(remoteFilePath: string, success: Function, failure: Function) {
    // this.showLoading();
    const username = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.WEBDAV.USERNAME);
    const password = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.WEBDAV.PASSWORD);
    const auth = `${username}:${password}`;
    const token = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN);
    let serverHost: string;
    this._teamnoteApiService.getModuleConfig(token,
      (ss) => {
        const mapped = ss as ModuleConfig;
        serverHost = mapped.modules.file_sharing.host;
        const headers = new HttpHeaders({
          'translate': 'f',
          'Authorization': 'Basic ' + btoa(auth)
        });
        // tslint:disable-next-line:curly
        // headers = this.setDefaultHeader(headers);
        const uri = serverHost + remoteFilePath;
        return this.downloadFile(uri, headers, success, failure);
      }, (err) => {
        this._loggerService.error(_.join([serverHost, err.status + '-' + err.statusText + '-' + err.error], '\n'));
        failure(err);
      });
  }
  private downloadFile(remoteFilePath: string, header: HttpHeaders, success: Function, failure: Function) {
    return this._http
      .post(remoteFilePath, null, {
        withCredentials: true,
        headers: header,
        observe: 'response',
        responseType: 'arraybuffer'
      })
      .pipe(
        tap(() => this.hideLoading(), () => this.hideLoading(), () => this.hideLoading())
      )
      .subscribe(
        (resp: HttpResponse<any>) => {
          let exportName: string;
          // let name: string;
          const disposition = resp.headers.get('Content-Disposition');
          if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              exportName = matches[1].replace(/['"]/g, '');
            }
          }
          const type = resp.headers.get('Content-Type');
          const blob = new Blob([resp.body], { type: type });
          success(blob);
        }, (err) => {
          this._loggerService.error(_.join([remoteFilePath, err.status + '-' + err.statusText + '-' + err.error], '\n'));
          failure(err);
        });
  }
  setDefaultHeader(headers: HttpHeaders): HttpHeaders {
    const username = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.WEBDAV.USERNAME);
    const password = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.WEBDAV.PASSWORD);
    const auth = `${username}:${password}`;
    headers = headers.set('Authorization', 'Basic ' + btoa(auth));
    headers = headers.set('Content-Type', 'text/xml');
    return headers;
  }
  setAuth(userName: string, password: string, success: Function, failure: Function) {
    const token = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN);
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.WEBDAV.USERNAME, userName.toString());
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.WEBDAV.PASSWORD, password.toString());
    this._teamnoteApiService.getModuleConfig(token,
      (ss) => {
        const mapped = ss as ModuleConfig;
        const url = `${mapped.modules.file_sharing.host}/auth`; // + '/webdav/';
        const params = {
          access_token: token,
          smb_username: userName,
          smb_password: password
        };
        this._http
          .post(url, null, { params: params, withCredentials: true, })
          .pipe(
            tap(() => this.hideLoading(), () => this.hideLoading(), () => this.hideLoading())
          )
          .subscribe((d) => {
            success(d);
          }, (e) => {
            this._loggerService.error(_.join([url, e.status + '-' + e.statusText + '-' + e.error], '\n'));
            failure(e);
          });
      }, (error) => {
        failure(error);
      }
    );
  }
  setPin(data: string, success: Function, failure: Function) {
    this.auth((host) => this.pinSet(host, data, success, failure), (e) => failure(e));
  }
  pinSet(host: string, data: string, success: Function, failure: Function) {
    const url = `${host}/pin/set`;
    const formData = new FormData();
    formData.append('href', data);
    this._http
      .post(url, formData, { withCredentials: true })
      .pipe(
        tap(() => this.hideLoading(), () => this.hideLoading(), () => this.hideLoading())
      )
      .subscribe(
        (s) => { success(s); }
        , (e) => {
          this._loggerService.error(_.join([host, e.status + '-' + e.statusText + '-' + e.error], '\n'));
          failure(e);
        });

  }
  unsetPin(data: string, success: Function, failure: Function) {
    this.auth((host) => this.pinUnset(host, data, success, failure), (e) => failure(e));
  }
  pinUnset(host: string, data: string, success: Function, failure: Function) {
    const url = `${host}/pin/unset`;
    const formData = new FormData();
    formData.append('href', data);
    this._http
      .post(url, formData, { withCredentials: true })
      .pipe(
        tap(() => this.hideLoading(), () => this.hideLoading(), () => this.hideLoading())
      )
      .subscribe(
        (s) => { success(s); }
        , (e) => {
          this._loggerService.error(_.join([host, e.status + '-' + e.statusText + '-' + e.error], '\n'));
          failure(e);
        });
  }
  queryPin(data: string, success: Function, failure: Function) {
    this.auth((host) => this.pinQuery(data, host, success, failure), (e) => failure(e));
  }
  pinQuery(data: string, host: string, success: Function, failure: Function) {
    const url = `${host}/pin/query`;
    const formData = new FormData();
    formData.append('href', data);
    this._http
      .post(url, formData, { withCredentials: true })
      .pipe(
        tap(() => this.hideLoading(), () => this.hideLoading(), () => this.hideLoading())
      )
      .subscribe((s) => { success(s); },
        (e) => {
          this._loggerService.error(_.join([host, e.status + '-' + e.statusText + '-' + e.error], '\n'));
          failure(e);
        });
  }
  readPin(data: string, success: Function, failure: Function) {
    this.auth((host) => this.pinRead(host, data, success, failure), (e) => failure(e));
  }
  pinRead(host: string, data: any, success: Function, failure: Function) {
    const url = `${host}/pin/read`;
    const formData = new FormData();
    formData.append('href', data);
    this._http
      .post(url, formData, { withCredentials: true })
      .pipe(
        tap(() => this.hideLoading(), () => this.hideLoading(), () => this.hideLoading())
      )
      .subscribe((s) => { success(s); },
        (e) => {
          this._loggerService.error(_.join([host, e.status + '-' + e.statusText + '-' + e.error], '\n'));
          failure(e);
        });
  }
  unreadPin(data: string, success: Function, failure: Function) {
    this.auth((host) => this.pinUnread(host, data, success, failure), (e) => failure(e));
  }
  pinUnread(host: string, data: string, success: Function, failure: Function) {
    const url = `${host}/pin/unread`;
    const formData = new FormData();
    formData.append('href', data);
    this._http
      .post(url, formData, { withCredentials: true })
      .pipe(
        tap(() => this.hideLoading(), () => this.hideLoading(), () => this.hideLoading())
      )
      .subscribe((s) => { success(s); },
        (e) => {
          this._loggerService.error(_.join([url, e.status + '-' + e.statusText + '-' + e.error], '\n'));
          failure(e);
        });
  }
  auth(success: Function, failure: Function) {
    const token = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN);
    // success(this.pinFiles);
    const userName = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.WEBDAV.USERNAME);
    const password = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.WEBDAV.PASSWORD);
    this._teamnoteApiService.getModuleConfig(token,
      (ss) => {
        const mapped = ss as ModuleConfig;
        const url = `${mapped.modules.file_sharing.host}/auth`; // + '/webdav/';
        const params = {
          access_token: token,
          smb_username: userName,
          smb_password: password
        };
        this._http
          .post(url, null, { params: params, withCredentials: true })
          .pipe(
            tap(() => this.hideLoading(), () => this.hideLoading(), () => this.hideLoading())
          )
          .subscribe((d: { success: boolean }) => {
            if (d.success) {
              success(mapped.modules.file_sharing.host);
            } else {
              failure(d);
            }
          },
            (e) => {
              this._loggerService.error(_.join([url, e.status + '-' + e.statusText + '-' + e.error], '\n'));
              failure(e);
            });
      }, (e) => {
        failure(e);
      });
  }

  constructor(
    private _tnLoaderService: TnLoaderService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _loggerService: LoggerService,
    private _teamnoteApiService: TeamnoteApiService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _http: HttpClient) { }
}


export function stringToArrayBuffer8(input: String): ArrayBuffer {
  const view = new Uint8Array(input.length);
  for (let i = 0, strLen = input.length; i < strLen; i++) {
    view[i] = input.charCodeAt(i);
  }
  return view.buffer;
}


export function xmlToJson(xml: string, success: Function, failure: Function) {
  const parser = new Parser(
    {
      trim: true,
      explicitArray: false,
      tagNameProcessors: [processors.stripPrefix]
    });
  parser.parseString(xml, function (err, jsonData) {
    if (err) {
      failure(err);
    }
    success(jsonData);
  });
}
