export class BroadcastGroup {
  update_date: string;
  is_store: number;
  create_date: string;
  name: string;
  extension: string;
  store_code: string;
  created_by: string;
  company_domain: string;
  is_manager: number;
  user_group_id: string;

  user_group_members: BroadcastGroupMember[];

  constructor(name: string) {
    this.name = name;
  }
}

export class BroadcastGroupMember {
  user_group_id: string;
  user_id: string;
}