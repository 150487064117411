export const ZhChtContactStrings = {
  NAME: "名稱",
  EMAIL: "電郵",
  DEPARTMENT: "部門",
  TITLE: "職位名稱",
  PUBLIC_MESSAGE: "公開訊息",
  MOBILE: "手提電話號碼",
  E_PROFILE: "E-Profile",

  DELETED_POSTFIX: "(已刪除)"
};
