<div class="tn-content">
  <div class="tn-content-left"
    [ngClass]="{
      'current-focus-content': (currentMainView != MAIN_VIEW_CODE.VIEW_REPORT && currentMainView != MAIN_VIEW_CODE.NEW_REPORT && currentMainView != MAIN_VIEW_CODE.FILTER && currentMainView != MAIN_VIEW_CODE.JOB_RESPONSE_FORM), 
      'current-unfocus-content': (currentMainView == MAIN_VIEW_CODE.VIEW_REPORT || currentMainView == MAIN_VIEW_CODE.NEW_REPORT || currentMainView == MAIN_VIEW_CODE.FILTER || currentMainView == MAIN_VIEW_CODE.JOB_RESPONSE_FORM)
    }">
    
    <div class="job-report-list-container">
      <tn-page-title [title]="pageTitle ? pageTitle : pageTitleTranslateKey | translate">
        <div class="right-control">

          <div class="btn-group tn-dropdown-group" dropdown placement="bottom right"
            *ngIf="isViewingStoreReport || isEnabledExport">
            <button dropdownToggle type="button" class="dropdown-toggle tn-dropdown-button">
              <i class="fa fa-angle-down fa-fw"></i>
            </button>
            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
              <li role="menuitem">
                <div class="tn-dropdown-item" 
                  (click)="createNewReport()"
                  *ngIf="isViewingStoreReport">
                  <i class="fa fa-plus fa-fw"></i> {{'WEBCLIENT.JOB_REPORT.NEW_REPORT.TITLE' | translate}}
                </div>
              </li>
              <li role="menuitem">
                <div class="tn-dropdown-item" 
                  (click)="filterReportOrJob()">
                  <i class="fa fa-fw fa-filter"></i> 
                  <ng-container *ngIf="isViewingStoreReport">{{'WEBCLIENT.JOB_REPORT.FILTER.REPORT' | translate}}</ng-container>
                  <ng-container *ngIf="!isViewingStoreReport">{{'WEBCLIENT.JOB_REPORT.FILTER.JOB' | translate}}</ng-container>
                </div>
              </li>
              <li role="menuitem">
                <div class="tn-dropdown-item" 
                  (click)="openExportReportOrJobDialog(false)"
                  *ngIf="isViewingStoreReport && isEnabledExport">
                  <i class="fa fa-fw fa-download"></i> {{'WEBCLIENT.JOB_REPORT.EXPORT.REPORT' | translate}}
                </div>
              </li>
              <li role="menuitem">
                <div class="tn-dropdown-item" 
                  (click)="openExportReportOrJobDialog(true)"
                  *ngIf="!isViewingStoreReport && isEnabledExport">
                  <i class="fa fa-fw fa-download"></i> {{'WEBCLIENT.JOB_REPORT.EXPORT.JOB' | translate}}
                </div>
              </li>
            </ul>
          </div>
          
        </div>
      </tn-page-title>

      <div class="job-report-tabs" *ngIf="isEnabledJobDispatch">
        <div class="tab" 
          [ngClass]="{'tab_selected': isViewingStoreReport}" 
          (click)="onViewingTabChanged(true)">
          <div class="icon-wrapper">
            <i class="fa fa-fw fa-2x fa-edit icon"></i>
          </div>
          <div class="title"> 
            {{'WEBCLIENT.MENU.STORE_REPORT' | translate}}
          </div>
        </div>
        <div class="tab" 
        *ngIf="isEnabledJobDispatch"
        [ngClass]="{'tab_selected': !isViewingStoreReport}" 
        (click)="onViewingTabChanged(false)">
          <div class="icon-wrapper">
            <i class="fa fa-fw fa-2x fa-briefcase icon"></i>
            <div class="count unread-bubble" *ngIf="jobCount > 0">{{jobCount}}</div>
          </div>
          <div class="title">
            {{'WEBCLIENT.MENU.JOB_DISPATCH' | translate}}
          </div>
        </div>
      </div>

      <div class="job-report-list-container">
        <tn-store-report-list 
          [storeReports]="storeReports"
          [viewingReport]="viewingReport"
          [isEnabledJobDispatch]="isEnabledJobDispatch" 
          (onStoreReportClick)="onStoreReportClick($event)"
          *ngIf="isViewingStoreReport">
        </tn-store-report-list>
        
        <tn-job-dispatch-list 
          [jobDispatchs]="jobDispatchs"
          [viewingJob]="viewingJob"
          [isEnabledJobDispatch]="isEnabledJobDispatch" 
          (onJobDispatchClick)="onJobDispatchClick($event)" 
          *ngIf="!isViewingStoreReport">
        </tn-job-dispatch-list>
      </div>
    </div>

  </div>
  <div class="tn-content-right"
    [ngClass]="{
      'current-focus-content': (currentMainView == MAIN_VIEW_CODE.VIEW_REPORT || currentMainView == MAIN_VIEW_CODE.NEW_REPORT || currentMainView == MAIN_VIEW_CODE.FILTER || currentMainView == MAIN_VIEW_CODE.JOB_RESPONSE_FORM), 
      'current-unfocus-content': (currentMainView != MAIN_VIEW_CODE.VIEW_REPORT && currentMainView != MAIN_VIEW_CODE.NEW_REPORT && currentMainView != MAIN_VIEW_CODE.FILTER && currentMainView != MAIN_VIEW_CODE.JOB_RESPONSE_FORM)
    }">
    <tn-job-report-view 
      [report]="viewingReport" 
      [isViewingStoreReport]="isViewingStoreReport"
      (onJobActionClick)="onJobActionClick($event)"
      (clearViewingReport)="clearViewingReport($event)"
      *ngIf="currentMainView == MAIN_VIEW_CODE.VIEW_REPORT">
    </tn-job-report-view>

    <tn-report-submit
      [job]="viewingJob"
      [jobActionCode]="jobActionCode"
      [customParam]="customParam"
      (onBack)="onReportSubmitBack($event)"
      (onJobResponseSubmitBack)="onJobResponseSubmitBack($event)"
      *ngIf="currentMainView == MAIN_VIEW_CODE.NEW_REPORT || currentMainView == MAIN_VIEW_CODE.JOB_RESPONSE_FORM">
    </tn-report-submit>

    <tn-report-filter
      [isViewingStoreReport]="isViewingStoreReport"
      [customParam]="customParam"
      (onBack)="onReportFilterBack($event)"
      (onReportFilterApply)="onReportFilterApply($event)"
      *ngIf="currentMainView == MAIN_VIEW_CODE.FILTER">
    </tn-report-filter>

  </div>
</div>