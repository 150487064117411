import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Attachment } from '../../../models/attachment';

import { FileFactoryService } from '../../file-factory/file-factory.service';

@Component({
  selector: 'tn-attachment-video',
  templateUrl: './attachment-video.component.html',
  styleUrls: ['./attachment-video.component.scss']
})
export class AttachmentVideoComponent implements OnInit {
  @Input() filename: string = null;
  @Input() attachment: Attachment;
  @Input() attachmentId: string;
  @Input() isDisableWatermark: boolean = false;
  @Input() isDisableDownload: boolean = false;

  videoUrl: string;

  constructor(
    private _fileFactoryService: FileFactoryService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (!this.attachmentId) {
      return;
    }
    this._fileFactoryService.getFileByAttachmentId(
      this.attachmentId,
      (dataUrl) => {
        this.videoUrl = dataUrl;
      },
      (err) => {},
      false
    );
  }
  sanitize(url: string ) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
}

}

