/**
 * DSE Strings
 * 
 * Created by Shan - 2018-11-12
 */

import { LanguageConstant } from "../../constants/language.constant";
import { LanguageString } from "../../strings/string-helper.service";

export const DseStrings: LanguageString[] = [
  {
    key: LanguageConstant.EN.key,
    strings: {
      WEBCLIENT: {
        MENU: {
          STORE_REPORT: "Job Report"
        }
      }
    }
  },
  {
    key: LanguageConstant.ZH_CHT.key,
    strings: {
      WEBCLIENT: {
        MENU: {
          STORE_REPORT: "工作報告"
        }
      }
    }
  },
  {
    key: LanguageConstant.ZH_CHS.key,
    strings: {
      WEBCLIENT: {
        MENU: {
          STORE_REPORT: "工作报告"
        }
      }
    }
  }
];