/**
 * For news list preview image,
 * only show X attachments message if numOfAttachment > 1,
 *
 * For news modal image,
 * always show X attachment(s) message
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { NewsService } from '../news.service';
import { LoggerService } from '../../../utilities/logger/logger.service';
import { FileFactoryService } from '../../../utilities/file-factory/file-factory.service';
import { FileManagerService } from '../../../utilities/file-manager/file-manager.service';
import { AttachmentTypeConstant } from '../../../constants/attachment-type.constant';
import * as _ from 'lodash';

@Component({
    selector: 'tn-news-media',
    templateUrl: './news-media.component.html',
    styleUrls: ['./news-media.component.scss']
})
export class NewsMediaComponent implements OnInit {
    @Input() newsHttp;
    @Input() media: string;
    @Input() isInModal: boolean;
    @Input() attachments: object[];

    knownAttachments: object;
    mediaArr: object[];
    numOfRemainingAttachment: number;

    ATTACHMENT_TYPE = AttachmentTypeConstant;

    constructor(
        private _domSanitizer: DomSanitizer,
        private _newsService: NewsService,
        private _loggerService: LoggerService,
        private _fileFactoryService: FileFactoryService,
        private _fileManagerService: FileManagerService
    ) { }

    ngOnInit() {
        if (!this.attachments) return

        this.knownAttachments = {};
        for (let i = 0; i < this.attachments.length; i++) {
            this.knownAttachments[this.attachments[i]['attachment_id']] = this.attachments[i];
        }

        this.mediaArr = [];
        if (this.media) {
            const attachmentIds = this.media.split(',');
            for (let i = 0; i < attachmentIds.length; i++) {
                const split = attachmentIds[i].split('.');
                const typeString = _.last(split).toLowerCase();
                const type = this._fileManagerService.getAttachmentType(attachmentIds[i]);

                this.mediaArr.push({
                    attachmentId: attachmentIds[i],
                    type: type,
                    typeString: typeString,
                    name: this.knownAttachments[attachmentIds[i]]['filename']});
            }
            // this.isVideo = type === AttachmentTypeConstant.VIDEO;
        }

        // this._fileFactoryService.getFileByAttachmentId(
        //     imageId,
        //     (dataUrl, fileName) => {
        //         this.imgSrc = this._domSanitizer.bypassSecurityTrustResourceUrl(dataUrl);
        //     },
        //     (err) => {},
        //     false
        // );
    }
    ngOnChanges() {
        if (!this.attachments) return

        this.knownAttachments = {};
        for (let i = 0; i < this.attachments.length; i++) {
            this.knownAttachments[this.attachments[i]['attachment_id']] = this.attachments[i];
        }

        this.mediaArr = [];
        if (this.media) {
            const attachmentIds = this.media.split(',');
            for (let i = 0; i < attachmentIds.length; i++) {
                const type = this._fileManagerService.getAttachmentType(attachmentIds[i]);
                this.mediaArr.push({
                    attachmentId: attachmentIds[i],
                    type: type,
                    name: this.knownAttachments[attachmentIds[i]]['filename']
                })
            }
            // this.isVideo = type === AttachmentTypeConstant.VIDEO;
        }

        // if (this.isCarousel) {
        //     let imageId;
        //     if (this.previewImageH && !this.isInModal) {
        //         imageId = this.previewImageH;
        //     } else {
        //         imageId = this.imagesArr[0];
        //     }
        //
        //     this._fileFactoryService.getFileByAttachmentId(
        //         imageId,
        //         (dataUrl, fileName) => {
        //             this.imgSrc = this._domSanitizer.bypassSecurityTrustResourceUrl(dataUrl);
        //         },
        //         (err) => {},
        //         false
        //     );
        // }
    }
    onClick(attachmentId: string) {
        this._loggerService.log("Clicked news' media: " + attachmentId);
        // console.log("Clicked news' newsHttp: ", this.newsHttp);
        this._newsService.openNewsHttpMedia(this.newsHttp, attachmentId);
        // this.onImageClick.emit(this.media);
    }

}
