/**
 * DSE Configs
 * 
 * Created by Shan - 2018-11-12
 */

import { TeamNoteConfig } from "../../configs/models/config";

export const DseConfig: TeamNoteConfig = {
  HOST: {
    DOMAIN: "dse"
  },
  GENERAL: {
    
  },
  IMAGES: {
    NOTIFICATION_ICON: "assets/images/clients/dse/notification_icon.png",
    FAVICON: "assets/images/clients/dse/favicon.png",
    FAVICON_ALERT: "assets/images/clients/dse/favicon_alert.png"
  },
  MAP: {
    API_KEY: "AIzaSyA1aE0_j-nvvjtjqoB8-0wcMTWJg-iKLjM"
  },
  WEBCLIENT: {
    
  }
};
