<div class="form-list">
  <ng-container *ngIf="forms.length > 0">
    <div virtualScroller
      class="full-height"
      [ssrChildHeight]="80"
      [items]="forms"
      (vsUpdate)="scrollItems = $event">
      <ng-container *ngFor="let f of scrollItems">
        <tn-form-cell
          [form]="f"
          [viewingForm]="viewingForm"
          (onFormCellClick)="onFormCellClick($event)">
        </tn-form-cell>
      </ng-container>
    </div>
  </ng-container>

  <tn-empty-page *ngIf="forms.length == 0"></tn-empty-page>
</div>