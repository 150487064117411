<div class="tn-content starred-messages" (click)="hideContextMenu()">
  <div class="modal-header" *ngIf="isInChat">
    <h4 class="text-color-primary">{{'WEBCLIENT.CHATROOM.MESSAGE_STAR' | translate}}</h4>
  </div>

  <div
    class="tn-content-left full-height"
    [ngClass]="{ 'starred-messages-container': !isInChat }"
    [ngStyle]="{ height: isInChat ? '65vh' : 'unset'}"
  >
    <tn-starred-messages-list
      [isInChat]="isInChat"
      [isChatLoaded]="isChatLoaded"
      [isEnableReadTicks]="isEnableReadTicks"
      [isEnableStarMessage]="isEnableStarMessage"
      [isEnableImportantUsers]="isEnableImportantUsers"
      [isLoadingHistory]="isLoadingHistory"
      [starredMessages]="starredMessages"
      (onItemClick)="onStarredMessageClick($event)"
      (updateStarredMessages)="updateStarredMessages($event)"
      (updateMessages)="loadStarMessages($event)"
    >
    </tn-starred-messages-list>
  </div>
</div>
