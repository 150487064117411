import { Injectable } from '@angular/core';
import { TeamnoteApiService } from '../../api/teamnote-api.service';
import { Questionnaire, Question } from './models/questionnaire';

import * as _ from 'lodash';
import { TeamNoteQuestionnaireConstant } from './constants/questionnaire.constant';
import { TimestampService } from '../../utilities/timestamp/timestamp.service';
import { QuestionFreeTextService } from './questionnaire-view/question-free-text/question-free-text.service';
import { QuestionMultipleChoiceService } from './questionnaire-view/question-multiple-choice/question-multiple-choice.service';
import { QuestionCheckboxService } from './questionnaire-view/question-checkbox/question-checkbox.service';
import { QuestionRatingService } from './questionnaire-view/question-rating/question-rating.service';
import { QuestionAttachmentService } from './questionnaire-view/question-attachment/question-attachment.service';
import { TeamNoteApiConstant } from '../../constants/api.constant';
import { SideNavService } from '../../utilities/tn-side-nav/side-nav.service';
import { ModuleKeyDefinition } from '../../constants/module.constant';

@Injectable()
export class QuestionnaireService {

  constructor(
    private _teamnoteApiService: TeamnoteApiService,
    private _timestampService: TimestampService,
    private _questionFreeTextService: QuestionFreeTextService,
    private _questionMultipleChoiceService: QuestionMultipleChoiceService,
    private _questionCheckboxService: QuestionCheckboxService,
    private _questionRatingService: QuestionRatingService,
    private _questionAttachmentService: QuestionAttachmentService,
    private _sideNavService: SideNavService
  ) { }

  handleInitialQuestionnaireContent(): void {
    this.getQuestionnaireList(()=>{}, ()=>{});
  }

  getQuestionnaireList(success: Function, failure: Function, isIgnoreSideNav?: boolean) {
    let url = TeamNoteApiConstant.QUESTIONNAIRE.GET_QUESTIONNAIRE_LIST;
    this._teamnoteApiService.callApi(
      url,
      {},
      (resp: Questionnaire[]) => {
        if (!isIgnoreSideNav) {
          this._sideNavService.updateSideNavCountByKey(
            ModuleKeyDefinition.QUESTIONNAIRE,
            this.getNumOfUnansweredQuestionnaire(resp)
          );
        }
        success(resp);
      },
      failure
    );
  }

  getNumOfUnansweredQuestionnaire(questionnaires: Questionnaire[]): number {
    return _.filter(questionnaires, (q) => {
      return !q.answered;
    }).length;
  }

  filterQuestionnaire(questionnaires: Questionnaire[]): Questionnaire[] {
    return _.filter(questionnaires, (q) => {
      return (q.revisable === 1 || q.revisable === 2 || (q.revisable === 0 && !q.answered)) && (this._timestampService.checkIfTimeAfterToday(q.expiry_date));
    });
  }

  checkIfUserCanAnswerQuestionnaire(q: Questionnaire): boolean {
    if (q.revisable === 1 || q.revisable === 2 || (q.revisable === 0 && !q.answered)) {
      return true
    }
    return false
  }

  checkIfQuestionnaireIsExpiried(q: Questionnaire): boolean {
    if (this._timestampService.checkIfTimeAfterToday(q.expiry_date)) {
      return false
    }
    return true
  }

  parseQuestionnaireIntoQuestion(questionnaire: Questionnaire): Question[] {
    var ques = _.map(questionnaire.questions, (q) => {
      q.hasError = false;
      switch (q.type) {
        case TeamNoteQuestionnaireConstant.QUESTION_TYPE.FREE_TEXT:
          return this._questionFreeTextService.getParsedFreeTextQuestion(q);
        case TeamNoteQuestionnaireConstant.QUESTION_TYPE.MC:
          return this._questionMultipleChoiceService.getParsedMultipleChoiceQuestion(q);
        case TeamNoteQuestionnaireConstant.QUESTION_TYPE.CHECKBOX:
          return this._questionCheckboxService.getParsedCheckboxQuestion(q);
        case TeamNoteQuestionnaireConstant.QUESTION_TYPE.RATING:
          return this._questionRatingService.getParsedRatingQuestion(q);
        case TeamNoteQuestionnaireConstant.QUESTION_TYPE.ATTACHMENT:
          return this._questionAttachmentService.getParsedAttachmentQuestion(q);
      }
    });

    ques = _.sortBy(ques, 'question_index');

    return ques;
  }

  validateAnsweredQuestions(questions: Question[]): boolean {
    let isPass = true;
    _.each(questions, (q) => {
      // reset hasError everytime question is being checked again
      q.hasError = false;
      switch (q.type) {
        case TeamNoteQuestionnaireConstant.QUESTION_TYPE.FREE_TEXT:
          q = this._questionFreeTextService.getCheckedFreeTextQuestion(q);
          break;
        case TeamNoteQuestionnaireConstant.QUESTION_TYPE.MC:
          q = this._questionMultipleChoiceService.getCheckedMultipleChoiceQuestion(q);
          break;
        case TeamNoteQuestionnaireConstant.QUESTION_TYPE.CHECKBOX:
          q = this._questionCheckboxService.getCheckedCheckboxQuestion(q);
          break;
        case TeamNoteQuestionnaireConstant.QUESTION_TYPE.RATING:
          q = this._questionRatingService.getCheckedRatingQuestion(q);
          break;
        case TeamNoteQuestionnaireConstant.QUESTION_TYPE.ATTACHMENT:
          q = this._questionAttachmentService.getCheckedAttachmentQuestion(q);
      }
      if (q.hasError) {
        isPass = false;
      }
    });
    return isPass;
  }

  async parseQuestionAnswerAndSubmit(questionnaireId: string, questions: Question[], success: Function, failure: Function): Promise<any> {
    let ansObj = {};

    try {
      await Promise.all(questions.map(async (q) => {
        let ansArr = [];
        switch (q.type) {
          case TeamNoteQuestionnaireConstant.QUESTION_TYPE.FREE_TEXT:
            ansArr = this._questionFreeTextService.getParsedFreeTextAnswer(q);
            break;
          case TeamNoteQuestionnaireConstant.QUESTION_TYPE.MC:
            ansArr = this._questionMultipleChoiceService.getParsedMultipleChoiceAnswer(q);
            break;
          case TeamNoteQuestionnaireConstant.QUESTION_TYPE.CHECKBOX:
            ansArr = this._questionCheckboxService.getParsedCheckboxAnswer(q);
            break;
          case TeamNoteQuestionnaireConstant.QUESTION_TYPE.RATING:
            ansArr = this._questionRatingService.getParsedRatingAnswer(q);
            break;
          case TeamNoteQuestionnaireConstant.QUESTION_TYPE.ATTACHMENT:
            ansArr = await this._questionAttachmentService.getParsedAttachmentAnswer(q);
            break;
        }
        ansObj[q.question_id] = ansArr;
      }))

      let answers = JSON.stringify(ansObj);

      let url = TeamNoteApiConstant.QUESTIONNAIRE.ANSWER_QUESTIONNAIRE;
      let params = {
        questionnaire_id: questionnaireId,
        answers: answers
      };

      this._teamnoteApiService.callApi(
        url,
        params,
        success,
        failure
      );
    } catch (error) {
      alert(error)
    }
  }

}
