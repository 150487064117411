import { TeamNoteConfig } from "../../configs/models/config";

export const AmcmConfig: TeamNoteConfig = {
  HOST: {
    DOMAIN: "amcm"
  },
  WEBCLIENT: {
    CHATROOM: {
      IS_ALLOW_ALL_FILE_TYPE: true
    }
  }
}