export const ZhChtWebdavStrings = {

    USERNAME: 'DPID',
    PASSWORD: 'Password',
    USERNAME_TIP: '请输入帐号',
    PASSWORD_TIP: '请输入密码',
    SETPIN: '钉选',
    UNSETPIN :  '取消钉选',
    SIGN_IN : '登入',
    BACK : '取消',
};
