import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Inject } from '@angular/core';

import { LocationService } from '../location/location.service';

import { MessageLocationBody } from '../../models/message-location-body';

import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NgMapConfigLoaderService } from '../ng-map-config-loader/ng-map-config-loader.service';
import { TeamnoteConfigService } from '../../configs/teamnote-config.service';
import { GoogleMap } from '@angular/google-maps';

@Component({
  selector: 'tn-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss']
})
export class LocationSelectorComponent implements OnInit {

  MAP_LANG: string = 'en';
  address: string;
  lat: number;
  lng: number;
  zoom: number;

  latLng;

  locationSelectedFunction: Function;
  self: any;

  options: google.maps.MapOptions = {
    disableDefaultUI: true,
    mapTypeControl: true,
    backgroundColor: '#ffffff',
    clickableIcons: true,
    disableDoubleClickZoom: true,
    draggable: true,
    zoomControl: true,
    fullscreenControl: false,
    streetViewControl: false
  };

  // markerOptions: google.maps.MarkerOptions = {
  //   draggable: false
  // };

  @ViewChild(GoogleMap, { static: false }) map: GoogleMap

  constructor(
    private _locationService: LocationService,
    public dialogRef: MatDialogRef<LocationSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _ngMapConfigLoaderService: NgMapConfigLoaderService,
    private _teamnoteConfigService: TeamnoteConfigService
  ) { }

  ngOnInit() {
    this.init();
  }

  init() {
    // this._ngMapConfigLoaderService.changeUrl(this._teamnoteConfigService.config.MAP.API_KEY);
    this.locationSelectedFunction = this.data.locationSelectedFunction;
    this.address = '';
    let location = this._locationService.getMapDefaultLocation();
    this.lat = location.LAT;
    this.lng = location.LNG;
    this.latLng = {
      lat: this.lat,
      lng: this.lng
    };
    this.zoom = this._locationService.getMapDefaultZoom();

    this._locationService.getCurrentLocation(
      (LAT_LNG) => {
        this.lat = LAT_LNG.LAT;
        this.lng = LAT_LNG.LNG;
        this.latLng = {
          lat: this.lat,
          lng: this.lng
        };
        this.zoom = this._locationService.getCurrentLocationMapZoom();
      }
    );
  }

  ngAfterViewChecked() {
    
  }

  cancel() {
    this.dialogRef.close();
  }

  openStateChange(state: boolean): void {
    if (!state) {
      this.cancel();
    }
  }

  confirm(): void {
    let body = <MessageLocationBody>{
      address: this.address,
      latitude: this.latLng.lat(),
      longitude: this.latLng.lng()
    };
    this.locationSelectedFunction(body, this.self);
    this.cancel();
  }

  onMapReady(map) {
    // console.log('map', map);
  }
  onIdle(event) {
    let center = this.map.getCenter()
    this.getCurrentMapCenter(center);
  }
  onMapClick(event) {
    // event.target.panTo(event.latLng);
    this.map.panTo(event.latLng)
  }

  getCurrentMapCenter(center) {
    this.latLng = center;
    this.getCenterAddress();
  }

  getCenterAddress() {
    this._locationService.getLocationAddressByLatLng(
      this.latLng,
      (address) => {
        this.address = address;
      }
    );
  }

  // TODO: search location
}
