<div class="form-export-container">

  <div class="modal-header text-color-primary">
    <h4>
      {{'WEBCLIENT.WORKFLOW.EXPORT.TITLE' | translate}}
    </h4>
  </div>

  <div class="modal-body form-export-body">

    <form novalidate>

      <!-- <div class="radio">
        <label>
          <input type="radio" name="option" [(ngModel)]="exportTarget" [value]="exportTargetOptions.manual"/>
          {{'WEBCLIENT.WORKFLOW.EXPORT.OPTION.MANUAL' | translate}}
        </label>
      </div> -->

      <!-- <div class="option-description-wrapper"> -->
      <div class="">
        <div class="form-group">
          <label>{{'WEBCLIENT.WORKFLOW.EXPORT.TIME_PERIOD' | translate}}</label>
          <div class="range-picker">
            <input type="text"
              [placeholder]="'WEBCLIENT.WORKFLOW.EXPORT.TIME_PERIOD' | translate"
              class="form-control"
              name="time-period"
              bsDaterangepicker
              [disabled]="exportTarget != exportTargetOptions.manual"
              [bsConfig]="dateInputConfig"
              [(ngModel)]="timePeriod">
          </div>
        </div>
        <div class="form-group">
          <label>{{'WEBCLIENT.WORKFLOW.FILTER.TEAM' | translate}}</label>
          <div class="option">
            <ng-select
              [clearable]="false"
              [items]="teams"
              [disabled]="exportTarget != exportTargetOptions.manual"
              [(ngModel)]="team"
              appendTo="body"
              bindLabel="name"
              name="team"
              [placeholder]="'WEBCLIENT.WORKFLOW.FILTER.TEAM' | translate">
              <ng-template ng-label-tmp ng-option-tmp let-item="item">
                <ng-container *ngIf="item.isAll">
                  {{'GENERAL.ALL' | translate}}
                </ng-container>
                <ng-container *ngIf="!item.isAll">
                  {{item.name}}
                </ng-container>
              </ng-template>
            </ng-select>
          </div>
        </div>
      
        <div class="form-group">
          <label>{{'WEBCLIENT.WORKFLOW.FILTER.TYPE' | translate}}</label>
          <div class="option">
            <ng-select
              [clearable]="false"
              [items]="types"
              [(ngModel)]="type"
              [disabled]="exportTarget != exportTargetOptions.manual"
              appendTo="body"
              bindLabel="name"
              name="type"
              [placeholder]="'WEBCLIENT.WORKFLOW.FILTER.TYPE' | translate">
              <ng-template ng-label-tmp ng-option-tmp let-item="item">
                <ng-container *ngIf="item.isAll">
                  {{'GENERAL.ALL' | translate}}
                </ng-container>
                <ng-container *ngIf="!item.isAll">
                  {{item.name}}
                </ng-container>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>


      <!-- <div class="radio">
        <label>
          <input type="radio" name="option" [(ngModel)]="exportTarget" [value]="exportTargetOptions.automatic"/>
          {{'WEBCLIENT.WORKFLOW.EXPORT.OPTION.AUTOMATIC' | translate}}
        </label>
      </div>

      <div class="form-group option-description-wrapper">
        <div class="form-control-static">
          {{formIds.length}} {{'WEBCLIENT.WORKFLOW.EXPORT.FORMS' | translate}}
        </div>
      </div> -->

    </form>

  </div>

  <div class="modal-footer">
    <button class="btn btn-default" (click)="cancel()">
      {{"GENERAL.CANCEL" | translate}}
    </button>
    <button class="btn tn-button-primary" (click)="export()">
      {{"GENERAL.EXPORT" | translate}}
    </button>
  </div>

</div>