export const EnNewsStrings = {
  NEWS: {
    NEWS_DATE: "NEWS_DATE",
    LIKE: "Like",
    UNLIKE: "Unlike",
    COMMENT: "Comment",
    REPLY: "Reply",
    REPLY_COUNT: {
      ONE: "{{NUM}} reply",
      MULTIPLE: "{{NUM}} replies"
    },
    ATTACHMENTS_COUNT: {
      ONE: "{{NUM}} attachment",
      MULTIPLE: "{{NUM}} attachments"
    },
    REMOVE_COMMENT_SUCCESS_MSG: "Comment deleted successfully"
  },

  VOTE: {
    VOTE_BTN: "Vote",
    RESULT_BTN: "Result",
    VOTE_OPTION_DESC: {
      ONE: "({{PERCENTAGE}}%, {{COUNT}} vote)",
      MULTIPLE: "({{PERCENTAGE}}%, {{COUNT}} votes)"
    },
    SUCCESS_MSG: "Vote success"
  },

  ERROR_MSG: {
    VOTE_NOT_YET_START: "Voting not yet started. Please try again later.",
    VOTE_EXPIRED: "Voting expired",
    VOTE_RESULT_HIDDEN: "Vote result is not viewable by public",
    VOTED: "You have voted already, answer cannot be changed",
    THREAD_LOCKED: "Thread Locked"
  }
};
