import { Component, OnInit, Input } from '@angular/core';

import { MessageLocationBody } from '../../../../../models/message-location-body';
import { LocationService } from '../../../../../utilities/location/location.service';

@Component({
  selector: 'tn-location-message',
  templateUrl: './location-message.component.html',
  styleUrls: ['./location-message.component.scss']
})
export class LocationMessageComponent implements OnInit {
  @Input() body: MessageLocationBody;

  previewSrc: string;
  address: string;

  constructor(
    private _locationService: LocationService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.previewSrc = this._locationService.getLocationPreviewSrc(this.body.latitude, this.body.longitude);
    this.address = this.body.address;
  }

}
