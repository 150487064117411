import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Message } from '../../../../../models/message';
import { MessageRead } from '../../../../../models/message-read';

import { SocketService } from '../../../socket/socket.service';

import * as _ from 'lodash';
import { MessageDelivery } from '../../../../../models/message-delivery';
import { AccountManagerService } from '../../../account/account-manager.service';
import { UserContactService } from '../../user-contact/user-contact.service';
import { UserContact } from '../../../../../models/user-contact';
import { InfoMessageService } from '../info-message/info-message.service';
import { LoggerService } from '../../../../../utilities/logger/logger.service';
import { UtilitiesService } from '../../../../../utilities/service/utilities.service';
import { MessageTypeConstant } from '../../../../../constants/message-type.constant';
import { TeamnoteApiService } from '../../../../../api/teamnote-api.service';
import { TeamNoteApiConstant } from '../../../../../constants/api.constant';
import { TeamnoteConfigService } from '../../../../../configs/teamnote-config.service';
import { FileManagerService } from '../../../../../utilities/file-manager/file-manager.service';
import { ImageHelperService } from '../../../../../utilities/image-helper/image-helper.service';
import { FileFactoryService } from '../../../../../utilities/file-factory/file-factory.service';
import { SideNavService } from '../../../../../utilities/tn-side-nav/side-nav.service';
import { ModuleKeyDefinition } from '../../../../../constants/module.constant';
import { UserConstant } from '../../../../../constants/user.constant';

interface ChatMessages {
  [chatId: string]: Message[]
}
interface UnreadMessages {
  [messageId: string] : Message
}

@Injectable()
export class ChatMessageService {
  // for tracking all messages (to update chat list last msg)
  chatMessages: ChatMessages = {};
  chatMessages$: BehaviorSubject<ChatMessages> = new BehaviorSubject<ChatMessages>({});

  // for tracking all unread  messages (to update side menu and page title)
  unreadMessages: UnreadMessages = {};
  unreadMessages$: BehaviorSubject<UnreadMessages>  = new BehaviorSubject<UnreadMessages>({});

  // for tracking current active chat room
  activeChatRoomId: string = '';
  activeChatRoomMessages$: BehaviorSubject<Message[]> = new BehaviorSubject<Message[]>([]);
  
  activeChatRoomSearchedMessages: ChatMessages = {};
  activeChatRoomSearchedMessages$: BehaviorSubject<ChatMessages> = new BehaviorSubject<ChatMessages>({});

  queuingMessages: {[correlationId: string] : any} = {};
  queuingMessagesCorrelationId: string[] = [];
  currentSendingMessageCorrelationId: string = null;

  messageResendTimer: any = null
  
  constructor(
    private _socketService: SocketService,
    private _accountManagerService: AccountManagerService,
    private _userContactService: UserContactService,
    private _infoMessageService: InfoMessageService,
    private _loggerService: LoggerService,
    private _utilitiesService: UtilitiesService,
    private _teamnoteApiService: TeamnoteApiService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _fileManagerService: FileManagerService,
    private _imageHelperService: ImageHelperService,
    private _fileFactoryService: FileFactoryService,
    private _sideNavService: SideNavService
  ) { }

  initChatMessages(): void {
    this.chatMessages = {};
    this.updateChatMessageSubject();
    this.activeChatRoomSearchedMessages = {};
    this.updateChatSearchedMessageSubject();
    this.unreadMessages = {};
    this.updateUnreadMessageSubject();
    this.activeChatRoomId = '';
    this.updateActiveChatRoomMessageSubject();
  }

  updateChatMessage(m: Message): void {
    _.each(m.chat_ids, (chatId) => {
      this.insertOrUpdateChatMessageUnderChat(chatId, m);
      this.sortChatMessageUnderChat(chatId);
    });
    // this.updateChatMessageSubject();
  }
  updateChatMessageSubject(): void {
    this.chatMessages$.next(this.chatMessages);
  }

  removeChatMessageByChatId(chatId: string): void {
    this.chatMessages[chatId] = [];
    this.updateChatMessageSubject();
  }

  // Unreads Message
  getUnreadMessageByMessageId(messageId: string) {
    return this.unreadMessages[messageId];
  }
  insertUnreadMessage(message: Message) {
    this.unreadMessages[message.message_id] = message;
  }
  removeUnreadMessageByMessageId(messageId: string) {
    delete this.unreadMessages[messageId];
  }
  getUnreadMessagesUnderChat(chatId: string) {
    let targetMessages = _.filter(this.unreadMessages, (m) => {
      return _.indexOf(m.chat_ids, chatId) !== -1 && !m.isLocalRead;
    });
    return targetMessages;
  }
  updateUnreadMessageSubject(): void {
    this._loggerService.debug("Update unread message count: " + _.size(this.unreadMessages));
    this._sideNavService.updateSideNavCountByKey(
      ModuleKeyDefinition.CHAT,
      _.size(this.unreadMessages)
    );
  }
  removeUnreadMessageUnderChat(chatId: string) {
    _.each(this.unreadMessages, (message, messageId) => {
      if (message.chat_ids.indexOf(chatId) != -1) {
        this.removeUnreadMessageByMessageId(messageId);
      }
    });
  }

  // Deliveries
  updateMessageDeliveryStatus(md: MessageDelivery) {
    let messageIds = md.body.split(' ');
    _.each(messageIds, (id) => {
      let m = this.getChatMessageByMessageId(id);
      if (m) {
        if (!m.delivery) {
          m.delivery = [];
        }
        let d = {
          body: id,
          sent_by: md.sent_by,
          timestamp: md.timestamp,
          type: md.type
        };
        if (!_.find(m.delivery, d)) {
          m.delivery.push(d);
        }
      }
    });
  }
  

  insertOrUpdateChatMessageUnderChat(chatId: string, m: Message): void {
    if (!this.chatMessages[chatId]) {
      this.chatMessages[chatId] = [];
    }
    let existing = this.getChatMessageUnderChatByMessageId(chatId, m.message_id);
    if (existing.length > 0) {
      let targetMessage = existing[0];
      targetMessage = _.assign(targetMessage, m);
    } else {
      let newlySent = this.getChatMessageUnderChatByCorrelationId(chatId, m.correlation_id);
      if (newlySent.length > 0) {
        let targetMessage = newlySent[0];
        targetMessage = _.assign(targetMessage, m);
        this.removeChatMessageFromQueueByCorrelationId(m.correlation_id);
      } else {
        this.chatMessages[chatId].push(m);
      }
    }
  }
  getChatMessageUnderChatByMessageId(chatId: string, messageId: string): Message[] {
    return _.filter(this.chatMessages[chatId], {'message_id': messageId});
  }
  getChatMessageUnderChatByCorrelationId(chatId: string, correlationId: string): Message[] {
    return _.filter(this.chatMessages[chatId], {'correlation_id': correlationId});
  }
  getChatMessageByMessageId(messageId: string): Message {
    for (var chatId in this.chatMessages) {
      let cm = this.chatMessages[chatId];
      let result = _.find(cm, {'message_id': messageId});
      if (result) {
        return result;
      }
    }
  }
  getAllChatMessagesUnderChat(chatId: string): Message[] {
    return this.chatMessages[chatId] ? this.chatMessages[chatId] : [];
  }
  getLastestChatMessagesUnderChatByChatIdAndSize(chatId: string, size: number): Message[]  {
    let all = this.getAllChatMessagesUnderChat(chatId);
    return _.takeRight(all, size);
  }
  checkIfTimestampIsEarliestMessage(chatId: string, timestamp: string): boolean {
    if (!timestamp) {
      // if no target timestamp, chat is empty, so it is earliest.
      return true;
    }
    let all = this.getAllChatMessagesUnderChat(chatId);
    let firstMsg = _.first(all);
    if (firstMsg) {
      if (firstMsg.timestamp == timestamp) {
        return true;
      }
    }
    return false;
  }

  removeChatMessageUnderChatByMessageId(messageId: string): void {
    let targetMessage = this.getChatMessageByMessageId(messageId);
    if (!targetMessage) {
      return;
    }

    _.each(targetMessage.chat_ids, (chatId) => {
      this.chatMessages[chatId] = _.filter(this.chatMessages[chatId], (message) => {
        return message.message_id != messageId;
      });
    });
  }

  sortChatMessageUnderChat(chatId: string): void {
    this.chatMessages[chatId] = _.sortBy(this.chatMessages[chatId], ['timestamp']);
  }

  getLastMessageOfChat(chatId: string): Message {
    let messages = this.getAllChatMessagesUnderChat(chatId);
    if (messages) {
      for (var i = messages.length-1; i >= 0; i--) {
        let m = messages[i];
        if (m && 
            (m.type == MessageTypeConstant.TEXT ||
            m.type == MessageTypeConstant.ATTACHMENT ||
            m.type == MessageTypeConstant.LOCATION ||
            m.type == MessageTypeConstant.STICKER
          )
        ) {
          return this.getSingleParsedMsg(m);
        }
      }
    }
  }
  getFirstMessageOfChat(chatId: string): Message {
    let messages = this.getAllChatMessagesUnderChat(chatId);
    return messages ? messages[0] : null;
  }
  getUnreadCountOfChat(chatId: string): number {
    let unreads = this.getUnreadMessagesUnderChat(chatId);
    return unreads.length;
  }

  getSingleParsedMsg(m: Message): Message {
    m.isSentByMe = m.sent_by == this._accountManagerService.userId;
    if (this._utilitiesService.checkIfMessageExpired(m.expiry_time)) {
      m.isRecalled = true;
    }
    m.parsedBody = JSON.parse(m.body);
    m.isWhisper = (m.received_by !== "" && m.received_by) ? true : false;
    if (m.isWhisper) {
      m.whisperContact = this._userContactService.getUserContactByUserId(m.received_by);

      // parse important user for whisperContact
      m.whisperContact.importantLevel = this._userContactService.checkIfUserIsImportant(m.whisperContact.uid)
    }

    m.senderContact = this.getUserContact(m.sent_by);

    m.readByOtherCount = this._infoMessageService.getReadByOtherCountByMessageId(m.message_id);
    m.isReadByMe = this._infoMessageService.checkIfMessageIsReadByMe(m.message_id);

    m.emojis = this._infoMessageService.getEarliestMessageEmojisByMessageId(m.message_id);
    m.emojiDisplay = this._infoMessageService.getComputedEmojiDisplay(m);
    m.isSentEmoji = this._infoMessageService.checkIfMeSentAnEmoji(m);

    if (m.parsedBody.acknowledgement) {
      m.isAcked = this._infoMessageService.checkIfMessageIsAcked(m);
    }

    m.deliverByOtherCount = this._infoMessageService.getDeliverByOtherCountByMessageId(m.message_id);
    
    m.isStarredByMe = this._infoMessageService.checkIfMessageIsStarredByMe(m.message_id);

    return m;
  }

  getUserContact(userId): UserContact {
    let user = this._userContactService.getUserContactByUserId(userId);
    if (!user || _.isEmpty(user)) {
      user = new UserContact;
      // Get missing contact when required.
      if (userId && userId != "None") {
        this._userContactService.getContact([userId]);
      }
    }
    if (user.pic && user.pic != 'None') {
      this._fileFactoryService.getFileByAttachmentId(
        user.pic,
        (imageUrl) => {
          user.avatarImageSrc = imageUrl;
        },
        (err) => {},
        false,
        false,
        true
      );
    }

    // parse important user
    user.importantLevel = this._userContactService.checkIfUserIsImportant(user.user_id)
    return user
  }
  
  getAllAttachmentUnderChatByType(chatId: string, targetType: number, isAttachmentIdOnly?: boolean, isSearchMode?: boolean): any[] {
    let attachments = [];
    let chatMessages: Message[] = [];

    if (isSearchMode) {
      chatMessages = this.getAllChatSearchedMessagesUnderChat(chatId);
    } else {
      chatMessages = this.getAllChatMessagesUnderChat(chatId);
    }
    
    let allAttachmentMessage = _.filter(chatMessages, (m) => m.type == MessageTypeConstant.ATTACHMENT);
    _.each(allAttachmentMessage, (m: Message) => {
      if (!this._utilitiesService.checkIfMessageExpired(m.expiry_time)) {
        if (m.attachments[0] && m.attachments[0].attachment_id) {
          let type = this._fileManagerService.getAttachmentType(m.attachments[0].attachment_id);
          if (type == targetType && m.parsedBody.is_encrypted === 0) {
            // just get non-encrypted attachment type message
            m.attachments[0].timestamp = m.timestamp;
            attachments.push(m.attachments[0]);
          }
          if (m.parsedBody.message) {
            this._imageHelperService.setImageCaptionById(m.attachments[0].attachment_id, m.parsedBody.message);
          }
        }
      }
    });
    if (isAttachmentIdOnly) {
      return _.map(attachments, 'attachment_id');
    }
    return attachments;
  }


  // Active Chat Room Message & Message under searching mode
  initActiveChatRoomMessageSubject(chatId: string, isSearchMode?: boolean): void {
    this.activeChatRoomId = chatId;
    this.activeChatRoomMessages$ = new BehaviorSubject<Message[]>([]);
    this.activeChatRoomSearchedMessages$ = new BehaviorSubject<ChatMessages>({});
    this.removeChatSearchedMessageByChatId();

    if (!isSearchMode) {
      this.updateActiveChatRoomMessageSubject();
    } else {
      this.updateActiveChatRoomSearchedMessageSubject();
    }
  }
  deleteActiveChatRoomMessageSubject(): void {
    this.activeChatRoomId = '';
    this.activeChatRoomMessages$ = new BehaviorSubject<Message[]>([]);
    this.activeChatRoomSearchedMessages$ = new BehaviorSubject<ChatMessages>({});
  }
  tryUpdateActiveChatRoomMessageSubject(allChatIds: string[]): void {
    if (_.indexOf(allChatIds, this.activeChatRoomId) != -1) {
      this.updateActiveChatRoomMessageSubject();
      this.updateActiveChatRoomSearchedMessageSubject(); // try to update message under searching mode
    }
  }
  updateActiveChatRoomMessageSubject(): void {
    if (this.activeChatRoomId) {
      this.activeChatRoomMessages$.next(this.getActiveChatRoomMessage());
    }
  }

  /* for updating all messages under searching mode */
  updateActiveChatRoomSearchedMessageSubject(): void {
    if (this.activeChatRoomId) {
      this.activeChatRoomSearchedMessages$.next({});
    }
  }

  storeChatSearchedMessage(msgs: Message[], chatId: string): void {
    this.activeChatRoomSearchedMessages[chatId] = msgs
  }

  getAllChatSearchedMessagesUnderChat(chatId: string): Message[] {
    return this.activeChatRoomSearchedMessages[chatId] ? this.activeChatRoomSearchedMessages[chatId] : [];
  }

  getAllChatSearchedMessageUnderChat(chatId: string, currSearchMsgSize: number): Message[] {
    let targetSize = currSearchMsgSize + this._teamnoteConfigService.config.WEBCLIENT.AMQP.CHAT_LOAD_HISTORY_SIZE;
    let all = this.getAllChatSearchedMessagesUnderChat(chatId);
    return _.takeRight(all, targetSize);
  }

  updateChatSearchedMessageSubject(): void {
    this.activeChatRoomSearchedMessages$.next(this.activeChatRoomSearchedMessages);
  }

  removeChatSearchedMessageByChatId(chatId?: string): void {
    this.activeChatRoomSearchedMessages = {}
    // this.updateChatSearchedMessageSubject();
  }

  getActiveChatRoomMessage(): Message[] {
    return []

    // let msgs = this.getAllChatMessagesUnderChat(this.activeChatRoomId);
    // return _.map(msgs, (m) => {
    //   return this.getSingleParsedMsg(m);
    // });
  }

  // 
  addChatMessageToQueue(correlationId, routingKey, headers, body, callback) {
    this.queuingMessages[correlationId] = {
      routingKey: routingKey,
      headers: headers,
      body: body,
      correlationId: correlationId,
      callback: callback
    };
    this.queuingMessagesCorrelationId.push(correlationId);
  }
  removeChatMessageFromQueueByCorrelationId(correlationId: string) {
    delete this.queuingMessages[correlationId];
    this.queuingMessagesCorrelationId = _.without(this.queuingMessagesCorrelationId, correlationId);
    this.currentSendingMessageCorrelationId = null;
    clearTimeout(this.messageResendTimer);
    this.sendFirstMessageInQueue();
  }
  sendFirstMessageInQueue(isForceSent?: boolean): void {
    if (this.queuingMessagesCorrelationId.length == 0) {
      return;
    }

    let firstInQueueCorrelationId = _.first(this.queuingMessagesCorrelationId);
    let m = this.queuingMessages[firstInQueueCorrelationId];
    this._socketService.sendMessage(m.routingKey, m.headers, m.body, m.correlationId, m.callback);
    this.currentSendingMessageCorrelationId = firstInQueueCorrelationId;

    // the first message in the message queue may not send immediately via web socket, then
    // try to send the message of message queue by order every second until sent them all successfully
    clearTimeout(this.messageResendTimer);
    this.messageResendTimer = setTimeout(() => {
      if (_.includes(this.queuingMessagesCorrelationId, this.currentSendingMessageCorrelationId)) {
        this.sendFirstMessageInQueue()
      }
    }, 1000);
  }

  // Search message
  searchMessageByKeywordApi(keyword: string, success: Function, failure: Function, chatId?: string, timestamp?: any, timestampFrom?: any): void {
    if (!keyword || keyword.length == 0) {
      return;
    }
    let url = TeamNoteApiConstant.MESSAGE.SEARCH;
    let params = {
      keyword: keyword.trim(),
      chat_id: null,
      timestamp: null,
      timestamp_from: null,
      size: 20
    };
    if (chatId) {
      params.chat_id = chatId;
    }
    if (timestamp) {
      params.timestamp = timestamp;
    }
    if (timestampFrom) {
      params.timestamp_from = timestampFrom;
    }

    this._teamnoteApiService.callApi(
      url,
      params,
      success,
      failure,
      false,
      false,
      true
    );
  }

  /**
   * Load chat history via API
   * 
   * @param {string} chatId - target chat id
   * @param {*} messageTime - base message timestamp
   * @param {boolean} loadBeforeTimestamp - load before or after timestamp
   * @param {Function} success - success callback
   * @param {Function} failure - failure callback
   * @returns {void} 
   * @memberof ChatMessageService
   */
  chatLoadHistoryApi(chatId: string, messageTime: any, loadBeforeTimestamp: boolean, success: Function, failure: Function): void {
    if (!chatId || !messageTime) {
      return;
    }
    let url = TeamNoteApiConstant.MESSAGE.LOAD_HISTORY;
    let params = {
      chat_id: chatId,
      message_time: messageTime,
      load_before_timestamp: loadBeforeTimestamp ? 1 : 0,
      size: this._teamnoteConfigService.config.WEBCLIENT.AMQP.CHAT_LOAD_HISTORY_SIZE
    };
    this._teamnoteApiService.callApi(
      url,
      params,
      success,
      failure
    );
  }

  /**
   * Parse loaded messages,
   * only return REAL messages,
   * add READ messages to infoMessageService
   * add STAR pr UNSTAR messages to infoMessageService
   * 
   * @param {any[]} msgs - messages from load history API
   * @returns {Message[]} 
   * @memberof ChatMessageService
   */
  parseSearchedMsgResultIntoMessages(msgs: any[]): Message[] {
    let realMsgs: Message[] = [];
    let msgReads: MessageRead[] = [];

    _.each(msgs, (m) => {
      switch (_.toInteger(m.type)) {
        case MessageTypeConstant.TEXT:
        case MessageTypeConstant.ATTACHMENT:
        case MessageTypeConstant.LOCATION:
        case MessageTypeConstant.STICKER:
          realMsgs.push(m);
          break;
        case MessageTypeConstant.READ:
          this._infoMessageService.insertMessageReadStatus(m);
          break;
        case MessageTypeConstant.EMOJI:
        case MessageTypeConstant.UNEMOJI:
          this._infoMessageService.insertMessageEmojiStatus(m);
          break;
        case MessageTypeConstant.MESSAGE_STAR:
        case MessageTypeConstant.MESSAGE_UNSTAR:
          this._infoMessageService.insertMessageStarStatus(m);
          break;
        case MessageTypeConstant.MESSAGE_ACKNOWLEDGEMENT:
          this._infoMessageService.insertMessageAckStatus(m);
          break;
      }
    });

    return realMsgs;
  }

  getHighestImportantLevelOfMessages(msgs: Message[]): number {
    let highest = 0;
    _.each(msgs, (m) => {
      m.parsedBody = JSON.parse(m.body)
      if (m.parsedBody.important_level > highest)
      highest = Math.max(highest, parseInt(m.parsedBody.important_level, 10))
    })
    return highest;
  }

  // checkIfMessageIsRecalled(messageId: string): boolean {
  checkIfMessageIsRecalled(msg: Message): boolean {
    // let msg = this.getChatMessageByMessageId(messageId);
    // logic here:
    // if can not find target message in the chat in this.chatMessages(whole messages)
    // then load history??
    if (this._utilitiesService.checkIfMessageExpired(msg.expiry_time)) {
      return true;
    }
    return false;
  }
}
