/**
 * Define company configs here, to override default settings by company domain
 */

import { TeamNoteConfig } from "../configs/models/config";

import { ApptaskConfig } from "./apptask/apptask.config";
import { TestConfig } from "./test/test.config";
import { FwdConfig } from "./fwd/fwd.config";
import { ActConfig } from "./act/act.config";
import { BocConfig } from "./boc/boc.config";
import { HkaaConfig } from "./hkaa/hkaa.config";
import { AvsecoConfig, AvsecoProdConfig } from "./avseco/avseco.config";
import { SclConfig } from "./scl/scl.config";
import { AmcmConfig } from "./amcm/amcm.config";
import { HkwtiaConfig } from "./hkwtia/hkwtia.config";
import { DseConfig } from "./dse/dse.config";
import { FsdConfig } from "./fsd/fsd.config";
import { IsaConfig } from "./isa/isa.config";
import { MtrConfig } from "./mtr/mtr.config";
import { OidsConfig } from './oids/oids.config';
import { ArchsdConfig } from './archsd/archsd.config';
import {CeddConfig} from './cedd/cedd.config';

export const TeamNoteClientsConfig: {[domain: string]: TeamNoteConfig} = {
  "apptask": ApptaskConfig,
  "test": TestConfig,
  "fwd": FwdConfig,
  "act": ActConfig,
  "boc": BocConfig,
  "hkaa": HkaaConfig,
  "avseco": AvsecoConfig,
  "avsecoProd": AvsecoProdConfig,
  "scl": SclConfig,
  "amcm": AmcmConfig,
  "hkwtia": HkwtiaConfig,
  "dse": DseConfig,
  "fsd": FsdConfig,
  "isa": IsaConfig,
  "mtr": MtrConfig,
  "oids": OidsConfig,
  "archsd": ArchsdConfig,
  "cedd": CeddConfig,
};
