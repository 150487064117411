<div class="corporate-material-list-container otherSize" *ngIf="currentFolder">
  <tn-page-title [title]="name">
    <div class="left-control">
      <div class="back-button clickable" *ngIf="!isRootFolder" (click)="onFolderBack(currentFolder)">
        <i class="fa fa-fw fa-angle-left"></i>
      </div>
    </div>
    <div class="right-control" *ngIf="isAllowChangeViewMode">
      <div class="view-mode-button-wrapper" (click)="toggleViewMode()">
        <div class="corporate-material-view-mode-icon clickable" [ngClass]="{'list-icon': isGridView, 'grid-icon': !isGridView}"></div>
      </div>
    </div>
  </tn-page-title>
  <div class="favourite-bar">
      <div class="corporate-material-item clickable" (click)="showAllClick($event)" [ngClass]="[isAll ? 'active' : 'button-standard']" >
        <div class="icon2"></div>
      </div>
      <div class="corporate-material-item clickable" (click)="showFavouriteClick($event)" [ngClass]="[!isAll ? 'active' : 'button-standard']">
        <div class="icon1"></div>
      </div>
  </div>
  <div class="corporate-material-list" [ngClass]="{'grid-view': isGridView}" *ngIf="folders.length > 0 && isAll">
    <tn-webdav-material-folder
      *ngFor="let f of folders"
      [folder]="f"
      [pinFolders]="pinFolders"
      (onUnsetPinClick)="onUnsetPin(f)"
      (onSetPinClick)="onSetPin(f)"
      (click)="folderClick(f, $event)"
      >
    </tn-webdav-material-folder>
  </div>
  <div class="corporate-material-list" [ngClass]="{'grid-view': isGridView}" *ngIf="pinFolders && !isAll">
    <tn-webdav-material-folder
      *ngFor="let f of pinFolders"
      [folder]="f"
      [pinFolders]="pinFolders"
      (onUnsetPinClick)="onUnsetPin(f)"
      (onSetPinClick)="onSetPin(f)"
      [unreadPaths]="unreadPaths"
      (click)="folderClick(f, $event)">
    </tn-webdav-material-folder>
  </div>
  <ng-container *ngIf="folders.length == 0 ">
    <tn-empty-page></tn-empty-page>
  </ng-container>
</div>
