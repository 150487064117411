import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdvertisementComponent } from './advertisement/advertisement.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [AdvertisementComponent],
    exports: [AdvertisementComponent]
})
export class TnDialogModule { }
