import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { TeamnoteApiService } from '../../api/teamnote-api.service';
import { HttpRequest } from '@angular/common/http';
import { FileManagerService } from '../file-manager/file-manager.service';
import { TeamnoteConfigService } from '../../configs/teamnote-config.service';

export class LocalFile {
  dataUrl: string;
  blob: Blob;
  blobUrl: string;
  fileName: string;

  constructor(fileName: string, dataUrl: string, blob: Blob, blobUrl: string) {
    this.fileName = fileName;
    this.dataUrl = dataUrl;
    this.blob = blob;
    this.blobUrl = blobUrl;
  }
}

@Injectable()
export class FileFactoryService {

  localFiles: {[attachmentId: string]: LocalFile} = {};
  
  requestingAttachmentIds: string[] = [];

  constructor(
    private _teamnoteApiService: TeamnoteApiService,
    private _fileManagerService: FileManagerService,
    private _teamnoteConfigService: TeamnoteConfigService
  ) { }

  // local storage
  pushRequestingAttachmentId(attachmentId: string): void {
    this.requestingAttachmentIds.push(attachmentId);
  }
  
  popRequestingAttachmentId(attachmentId: string): void {
    this.requestingAttachmentIds = _.without(this.requestingAttachmentIds, attachmentId);
  }

  checkIfAttachmentIsRequesting(attachmentId: string): boolean {
    if (_.indexOf(this.requestingAttachmentIds, attachmentId) != -1) {
      return true;
    }
    return false;
  }

  getLocalFileByAttachmentId(attachmentId: string): LocalFile {
    return this.localFiles[attachmentId];
  }

  saveLocalFileByAttachmentId(attachmentId: string, fileName: string, dataUrl: string, blob: Blob, blobUrl: string): void {
    this.localFiles[attachmentId] = new LocalFile(fileName, dataUrl, blob, blobUrl);
  }

  // Get
  apiGetAttachmentById(attachmentId: string, successWithLocalFile: Function, failure: Function) {
    if (this.checkIfAttachmentIsRequesting(attachmentId)) {
      return;
    }
    this.pushRequestingAttachmentId(attachmentId);

    this._teamnoteApiService.getFileByAttachmentId(
      attachmentId,
      (resp: HttpRequest<any>) => {
        this.popRequestingAttachmentId(attachmentId);

        let name: string;
        let disposition = resp.headers.get('Content-Disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              name = matches[1].replace(/['"]/g, '');
            }
        }

        let type = resp.headers.get('Content-Type');

        let blob = new Blob([resp.body], {type: type});
        let urlCreator = window.URL;
        let blobUrl = urlCreator.createObjectURL(blob);

        this._fileManagerService.fileToDataUrl(
          blob,
          (dataUrl) => {
            this.saveLocalFileByAttachmentId(
              attachmentId,
              name,
              dataUrl,
              blob,
              blobUrl
            );
            successWithLocalFile(this.localFiles[attachmentId]);
          }
        );
      },
      (err) => {
        failure(err);
      }
    );

  }

  getFileByAttachmentId(attachmentId: string, successWithFileAndFileName: Function, failure: Function, isBlob: boolean, isSave?: boolean, isBackground?: boolean): void {
    if (!attachmentId || attachmentId == "None") {
      return;
    }
    let localFile = this.getLocalFileByAttachmentId(attachmentId);
    // console.log(this.localFiles)

    let successFunction = (localFile) => {
      let result = isBlob ? localFile.blobUrl : localFile.dataUrl;
      if (isBackground) {
        result = "url(" + result + ")";
      }
      successWithFileAndFileName(
        result, 
        localFile.fileName
      );
    };

    if (localFile) {
      successFunction(localFile);
    } else {
      if (this._teamnoteConfigService.config.GENERAL.IS_RESTRICTED_ATTACHMENT) {
        this.apiGetAttachmentById(
          attachmentId,
          (localFile) => {
            successFunction(localFile);
          },
          (err) => {
            failure();
          }
        );
      } else {
        let result = this._teamnoteConfigService.config.HOST.API_HOST + "/static/" + attachmentId;
        if (attachmentId.includes('http')) {
          result = attachmentId;
        }
        if (isBackground) {
          result = "url(" + result + ")";
        }
        successWithFileAndFileName(
          result
        );
      }
    }
  }


}
