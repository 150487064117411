<div class="field-signature-wrapper">
  <div class="signature-wrapper" *ngFor="let ansObj of localAnsObj">
    <div class="signature-view-wrapper" (click)="onSignatureClick(ansObj)">
      <div class="signature-view"
        [ngClass]="{'clickable': isInput}"
        [ngStyle]="{
          'background-image': ansObj.bgSrc ? ansObj.bgSrc : (ansObj.file ? 'url(' + ansObj.file.imagePreview + ')' : '')
        }">
      </div>
    </div>
    <div class="signature-tip" *ngIf="isInput">
      {{'GENERAL.SIGN_HERE' | translate}}
    </div>
  </div>
</div>