import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { ChatService } from '../services/data/chat/chat.service';

import { Chat } from '../../models/chat';
import { LoggerService } from '../../utilities/logger/logger.service';
import { PasswordReloginService } from '../../utilities/password-relogin/password-relogin.service';
import { LocalStorageManagerService } from '../../utilities/local-storage/local-storage-manager.service';
import { TeamNoteLocalStorageKeyConstants } from '../../constants/local-storage-key.constant';
import { Message } from '../../models/message';
import {ChatConstant} from '../../constants/chat.constant';
import {TnNotificationService} from '../../utilities/tn-notification/tn-notification.service';
import { Subscription } from 'rxjs';
import { WebclientService } from '../webclient.service';

@Component({
  selector: 'tn-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  activeChat: Chat;
  targetMessage: Message = null;
  prevMessage: Message = null;

  isSearching: boolean = false;
  searchingChat: Chat = null;
  searchingKeyword: string = null;

  isPageVisible: boolean = true;
  reconnectedCount: number = 0;
  private activeChatSub: Subscription;
  private pageVisibleSub: Subscription;
  private reconnectCountSub: Subscription;
  private targetMessageSub: Subscription;

  constructor(
    private _chatService: ChatService,
    private _loggerService: LoggerService,
    private _passwordReloginService: PasswordReloginService,
    private _tnNotificationService: TnNotificationService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _webclientService: WebclientService,
    private _changeDetectorRef: ChangeDetectorRef
  ) { 
    this.pageVisibleSub = this._webclientService.isPageVisible$.subscribe(visible => {
      this._loggerService.debug("Is Page Visible?: [[[ {visibility} ]]]".replace('{visibility}', visible ? 'Yes' : 'No'));
      // console.log('isPageVisible in chat.components', visible);
      this.isPageVisible = visible

      if (visible) {
        // console.log('Change Detector just reattached ---> [ restart to detect rendering ]\n------------------------------------------------------------------------')
        this._loggerService.debug('Change Detector just reattached --- restart to detect rendering')
        this._changeDetectorRef.reattach()
      } else {
        // console.log('Change Detector just detached ---> [ stop to detect rendering ]\n------------------------------------------------------------------------')
        this._loggerService.debug('Change Detector just detached --- stop to detect rendering')
        this._changeDetectorRef.detach()
      }
    });

    this.reconnectCountSub = this._webclientService.reconnectionStopCount$.subscribe(count => {
      // console.log('reconnectCountSub in chat.components', count);
      this.reconnectedCount = count
    });

    this.targetMessageSub = this._chatService.targetMessage$.subscribe(message => {
      if (message) {
        // message is passed from starred messages page
        // this.messageCellOnClick(message);
        this.enterSearchModeAndGoToChat({msg: message, prevMsg: null})
      } else {
        this.clearTargetMessageProps()
      }
    });
  }

  ngOnInit() {
    this._loggerService.debug("Chat ngOnInit");

    // try to get activeChat from chatService (enter from clicking contact)
    this.activeChatSub = this._chatService.activeChat$.subscribe(chat => {
      this.activeChat = chat;
    });
    this.goToActiveChat();
  }
  ngOnDestroy() {
    this.chatCellOnClick(null);

    this.activeChatSub.unsubscribe();
    this.pageVisibleSub.unsubscribe();
    this.reconnectCountSub.unsubscribe();

    this.clearTargetMessageProps()
    this._chatService.updateTargetMessageSubject(null)
    this.targetMessageSub.unsubscribe();
  }
  goToActiveChat() {
    this.activeChat = this._chatService.activeChat;
  }

  /**
   * Handle clicked on Chat cell in chat list
   *
   * @param {Chat} chat - target chat
   * @memberof ChatComponent
   */
  chatCellOnClick(chat: Chat): void {
    if (chat) {
      this._loggerService.log("Clicked on chat cell in chat list: " + chat.chat_id + chat.name);
    }
    this.targetMessage = null;
    this.goToChat(chat);
  }

  /**
   * Navigate to chat
   *
   * @param {Chat} chat - target chat
   * @memberof ChatComponent
   */
  goToChat(chat: Chat): void {
    this.activeChat = chat;
    this._chatService.updateActiveChatSubject(chat);
    this.clearChatSearchMode();

    // Update route meta data
    if (chat) {
      this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE_META_DATA, chat.chat_id);
    }
  }

  /**
   * Handle clicked on searched message cell in chat list
   *
   * @param {Message} message - target searched message
   * @memberof ChatComponent
   */
  messageCellOnClick(message: Message): void {
    this.targetMessage = message;
    // Find corresponding chat
    let chat = this._chatService.getChatByChatId(message.chat_ids[0]);
    this.goToChat(chat);
  }

  /**
   * Handle exit search mode from chat room
   *
   * @param {Chat} chat - target chat
   * @memberof ChatComponent
   */
  exitSearchModeAndGoToChat(event: {chat: Chat, isQuickTravel: boolean, isNeedClearPrevMessageFirst?: boolean}): void {
    this.targetMessage = null;
    
    if (event && event.isNeedClearPrevMessageFirst) {
      this.prevMessage = null;
    }

    if (this.prevMessage && event && event.isQuickTravel) {
      this.enterSearchModeAndGoToChat({msg: this.prevMessage, prevMsg: null});
      this.prevMessage = null;
    } else {
      if (event && event.chat) {
        this.goToChat(event.chat);
      }
    }
    this.clearChatSearchMode();
  }

  enterSearchModeAndGoToChat(event: {msg: Message, prevMsg: Message}): void {
    this.prevMessage = event.prevMsg;
    this.messageCellOnClick(event.msg);
  }

  // Chat search
  enterChatSearchMode(chatKeyword: {chat: Chat, keyword: string}): void {
    this.searchingChat = chatKeyword.chat;
    this.searchingKeyword = chatKeyword.keyword;
    this.isSearching = true;
  }
  clearChatSearchMode(): void {
    this.searchingChat = null;
    this.searchingKeyword = null;
    this.isSearching = false;
  }
  clearTargetMessageProps(): void {
    this.targetMessage = null;
    this.clearChatSearchMode();
  }
  viewSearchedMessage(message: Message): void {
    this.messageCellOnClick(message);
  }

  searchMessageOnClick(option: {isStart: boolean, isSeeMore: boolean, searchKeyword: string}): void {
    if (option) {
      if (option.isSeeMore) {
        this.enterChatSearchMode({chat: null, keyword: option.searchKeyword});
        return
      }
    } 

    this.enterChatSearchMode({chat: null, keyword: ""});
  }

}
