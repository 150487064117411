<div class="report-filter-container">

  <tn-page-title [title]="titleTranslateKey | translate">

    <div class="left-control">
      <div class="back-button clickable" (click)="reportFilterBack()">
        <i class="fa fa-fw fa-angle-left"></i>
      </div>
    </div>
  </tn-page-title>

  <div class="report-filter-body">
    <form novalidate>
      <div class="form-group">
        <label *ngIf="isViewingStoreReport">{{'WEBCLIENT.JOB_REPORT.FILTER.REPORT_DATE' | translate}}</label>
        <label *ngIf="!isViewingStoreReport">{{'WEBCLIENT.JOB_REPORT.FILTER.JOB_DISPATCH_DATE' | translate}}</label>
        <div class="date-range-pickers">
          <div class="date-picker">
            <input type="text"
              class="form-control"
              name="date-from"
              bsDatepicker
              [bsConfig]="dateInputConfig"
              [(ngModel)]="dateFrom">
          </div>
          <div class="date-to">
            {{'WEBCLIENT.JOB_REPORT.FILTER.TO' | translate}}
          </div>
          <div class="date-picker">
            <input type="text"
              class="form-control"
              name="date-to"
              bsDatepicker
              [bsConfig]="dateInputConfig"
              [(ngModel)]="dateTo">
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>{{'WEBCLIENT.JOB_REPORT.FILTER.STORE' | translate}}</label>
        <div class="option">
          <ng-select
            [clearable]="false"
            [items]="storeOptions"
            [(ngModel)]="selectedStore"
            appendTo="body"
            bindLabel="name"
            name="store">
            <ng-template ng-label-tmp ng-option-tmp let-item="item">
              <ng-container *ngIf="item.isAll">
                <div class="empty-option"></div>
              </ng-container>
              <ng-container *ngIf="!item.isAll">
                {{item.name}}
              </ng-container>
            </ng-template>
          </ng-select>
        </div>
      </div>
    
      <div class="form-group" *ngIf="typeOptions && typeOptions.length > 1">
        <label>{{'WEBCLIENT.JOB_REPORT.FILTER.TEMPLATE' | translate}}</label>
        <div class="option">
          <ng-select
            [clearable]="false"
            [items]="typeOptions"
            [(ngModel)]="selectedType"
            appendTo="body"
            bindLabel="name"
            name="type">
            <ng-template ng-label-tmp ng-option-tmp let-item="item">
              <ng-container *ngIf="item.isAll">
                <div class="empty-option"></div>
              </ng-container>
              <ng-container *ngIf="!item.isAll">
                {{item.name}}
              </ng-container>
            </ng-template>
          </ng-select>
        </div>
      </div>
    
      <div class="form-group" *ngIf="selectedType && (selectedType.isAll || selectedType.allow_job_dispatch)">
        <label>{{'WEBCLIENT.JOB_REPORT.FILTER.STATUS' | translate}}</label>
        <div class="option">
          <ng-select
            [clearable]="false"
            [searchable]="false"
            [items]="statusOptions"
            [(ngModel)]="selectedStatus"
            appendTo="body"
            bindLabel="statusTranslateKey"
            name="status">
            <ng-template ng-label-tmp ng-option-tmp let-item="item">
              <ng-container *ngIf="!item.statusTranslateKey">
                <div class="empty-option"></div>
              </ng-container>
              <ng-container *ngIf="item.statusTranslateKey">
                {{'WEBCLIENT.JOB_REPORT.STATUS.' + item.statusTranslateKey | translate}}
              </ng-container>
            </ng-template>
          </ng-select>
        </div>
      </div>

      <div class="form-group">
        <label>{{'WEBCLIENT.JOB_REPORT.FILTER.CONTENT' | translate}}</label>
        <div class="text-input">
          <div class="input-group">
            <input type="text"
              class="form-control"
              name="field_content"
              [(ngModel)]="contentKeyword">
            <div class="input-group-btn">
              <button class="btn btn-default" (click)="contentKeyword = ''">
                <i class="fa fa-fw fa-times"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="apply-button-wrapper">
      <button class="btn btn-success" (click)="apply()">
        {{'WEBCLIENT.JOB_REPORT.FILTER.APPLY' | translate}}
      </button>
    </div>
  </div>
</div>