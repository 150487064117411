import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CorporateMaterialPickerComponent } from './corporate-material-picker.component';
import { CorporateMaterialPickerService } from './corporate-material-picker.service';
import { CorporateMaterialModule } from '../../corporate-material/corporate-material.module';

@NgModule({
    imports: [
        CommonModule,
        CorporateMaterialModule
    ],
    declarations: [CorporateMaterialPickerComponent],
    providers: [CorporateMaterialPickerService]
})
export class CorporateMaterialPickerModule { }
