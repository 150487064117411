<div class="tn-chat-cell" 
  [ngClass]="{
    'clickable': isClickable, 
    'tn-chat-cell_selected': activeChat ? chat.chat_id === activeChat.chat_id : false,
    'tn-chat-cell_flashing': isEnableFlashAnimation && chat.newMessageCount > 0
  }" 
  (contextmenu)="onChatContextMenu($event)"
  (click)="chatClick($event, true)">
  <div class="avatar-wrapper">
    <div class="avatar" 
      [ngClass]="{'default-contact-avatar': chat.t == PRESENCE_TYPE.INDIVIDUAL_CHAT, 'default-group-avatar': chat.t != PRESENCE_TYPE.INDIVIDUAL_CHAT}"
      [ngStyle]="{'background-image': chat.picBgUrl}"></div>
    <div class="unread-count unread-bubble" *ngIf="chat.newMessageCount > 0">
      {{chat.newMessageCount}}
    </div>
    <div class="device-icon" *ngIf="chat.chatTarget && chat.chatTarget.device_count > 0"></div>
  </div>
  <div class="info-wrapper">
    <div class="row1">
      <div 
        class="name col1 text-color-primary"
        [ngClass]="{
          'important-user': isEnableImportantUsers,
          'global-vip': chat.importantLevel === IMPORTANT_LEVEL.GLOBAL,
          'individual-vip': chat.importantLevel === IMPORTANT_LEVEL.INDIVIDUAL
        }"
      >
        {{chat.displayName}}
        <ng-container *ngIf="!chat.isGroup && showUserField && chat.chatTarget[userField]">
          <br>({{chat.chatTarget[userField]}})
        </ng-container>
        <ng-container *ngIf="isEnableImportantUsers">
          <div class="global-vip-icon" *ngIf="chat.importantLevel === IMPORTANT_LEVEL.GLOBAL"></div>
          <div class="individual-vip-icon" *ngIf="chat.importantLevel === IMPORTANT_LEVEL.INDIVIDUAL"></div>
        </ng-container>
        <ng-container *ngIf="chat.chatTarget && chat.chatTarget.deleted">
          {{'WEBCLIENT.CONTACT.DELETED_POSTFIX' | translate}}
        </ng-container>
      </div>
      <div class="col2">
        <div class="member-count" *ngIf="chat.t == PRESENCE_TYPE.GROUP_CHAT">
          <span class="badge count-badge">
            <i class="fa fa-user fa-fw"></i>&nbsp;{{chat.members.length}}
          </span>
        </div>
        <div class="icon-announcement" *ngIf="chat.t == PRESENCE_TYPE.GROUP_BROADCAST">
          <div class="icon">
          </div>
        </div>
        <tn-hover-dropdown-menu
          [id]="chat.chat_id"
          (click)="chatClick($event, false)"
          *ngIf="contextMenus.length > 0"></tn-hover-dropdown-menu>
      </div>
    </div>
    <div class="row2" *ngIf="isShowLastMsg && chat.lastMessage">
      <div class="desc col1 last-msg text-color-secondary">

        <ng-container *ngIf="chat.security_level && chat.security_level >= SECURITY_LEVEL.INTERNAL">
          {{'WEBCLIENT.CHAT.CONFIDENTIAL_MSG_PREVIEW' | translate}}
        </ng-container>

        <ng-container *ngIf="!chat.security_level || chat.security_level < SECURITY_LEVEL.INTERNAL">
          <ng-container *ngIf="chat.lastMessage.isRecalled">
            <ng-container *ngIf="chat.lastMessage.isSentByMe">
                {{'WEBCLIENT.CHATROOM.MESSAGE_RECALLED.ME' | translate}}
            </ng-container>
            <ng-container *ngIf="!chat.lastMessage.isSentByMe">
              {{'WEBCLIENT.CHATROOM.MESSAGE_RECALLED.OTHERS' | translate: {'USER': chat.lastMessage.senderContact.name} }}
            </ng-container>
          </ng-container>

          <ng-container *ngIf="!chat.lastMessage.isRecalled && chat.lastMessage.parsedBody.is_encrypted">
            {{'WEBCLIENT.CHAT.ENCRYPTED_MSG_PREVIEW' | translate}}
          </ng-container>

          <ng-container *ngIf="!chat.lastMessage.isRecalled && !chat.lastMessage.parsedBody.is_encrypted">
            <ng-container *ngIf="chat.lastMessage.parsedBody.message; else nonMessage">
              {{chat.lastMessage.parsedBody.message}}
            </ng-container>
            <ng-template #nonMessage>
              <ng-container *ngIf="chat.lastMessage.type == MSG_TYPE.LOCATION">
                {{chat.lastMessage.parsedBody.address}}
              </ng-container>
              <ng-container *ngIf="chat.lastMessage.type == MSG_TYPE.ATTACHMENT">
                <ng-container *ngIf="chat.lastMessage.isSentByMe">
                  {{'WEBCLIENT.CHAT.SENT_FILE' | translate}}
                </ng-container>
                <ng-container *ngIf="!chat.lastMessage.isSentByMe">
                  {{'WEBCLIENT.CHAT.RECEIVED_FILE' | translate}}
                </ng-container>
              </ng-container>
              <ng-container *ngIf="chat.lastMessage.type == MSG_TYPE.STICKER">
                <ng-container *ngIf="chat.lastMessage.isSentByMe">
                  {{'WEBCLIENT.CHAT.SENT_STICKER' | translate}}
                </ng-container>
                <ng-container *ngIf="!chat.lastMessage.isSentByMe">
                  {{'WEBCLIENT.CHAT.RECEIVED_STICKER' | translate: {'SENDER': chat.lastMessage.senderContact.name} }} 
                </ng-container>
              </ng-container>
            </ng-template>
          </ng-container>
        </ng-container>
        
      </div>
      <div class="desc col2 last-msg-time">
        {{chat.lastMessage.timestamp | relativeDateTime}}
      </div>
    </div>
  </div>
  <ng-content select=".additional-right"></ng-content>
  <ng-content select=".additional-selection"></ng-content>
</div>
