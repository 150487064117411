import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AccountManagerService } from '../services/account/account-manager.service';
import { UserContact } from '../../models/user-contact';
import { UserConstant } from '../../constants/user.constant';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { TimestampService } from '../../utilities/timestamp/timestamp.service';
import { UserContactService } from '../services/data/user-contact/user-contact.service';

@Component({
  selector: 'tn-out-of-office',
  templateUrl: './out-of-office.component.html',
  styleUrls: ['./out-of-office.component.scss']
})
export class OutOfOfficeComponent implements OnInit {

  loggedInUser: UserContact;

  isOutOfOffice: boolean = false;
  outOfOfficeExpiredTimestamp: number = 0;

  isSelectingDate: boolean = false;
  minDate: Date = null;
  dateObj: Date = null;
  dateInputConfig: Partial<BsDatepickerConfig>;
  isDateValid: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<OutOfOfficeComponent>,
    private _accountManagerService: AccountManagerService,
    private _timestampService: TimestampService,
    private _userContactService: UserContactService
  ) { }

  ngOnInit() {
    this.dialogRef.updateSize('50vw');

    this._accountManagerService.selfContactPresence$.subscribe(user => {
      this.loggedInUser = user;
      this.checkUserOutOfOfficeState();
    });

    this.dateInputConfig = Object.assign({}, { 
      containerClass: "theme-default",
      dateInputFormat: "YYYY-MM-DD"
    });
    this.minDate = new Date();
  }

  checkUserOutOfOfficeState(): void {
    if (this.loggedInUser.user_state.user_state == UserConstant.USER_STATE.OUT_OF_OFFICE) {
      this.isOutOfOffice = true;
      this.outOfOfficeExpiredTimestamp = this.loggedInUser.user_state.expired_at;
    } else {
      this.isOutOfOffice = false;
      this.outOfOfficeExpiredTimestamp = 0;
    }
  }

  toggle(): void {
    if (this.isSelectingDate) {
      return;
    }

    if (this.isOutOfOffice) {
      this.updateUserState(false);
    } else {
      this.isSelectingDate = true;
    }
  }

  onDateChange(newVal): void {
    this.dateObj = newVal;
    this.checkDateInput();
  }

  onTimeChange(newVal): void {
    this.checkDateInput(); 
  }

  checkDateInput(): void {
    let date = this._timestampService.dateObjToSecondString(this.dateObj);
    if (!this._timestampService.checkIfTimeAfterToday(date)) {
      this.isDateValid = false;
    } else {
      this.isDateValid = true;
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    // Check input
    if (!this.isDateValid) {
      return;
    }

    let date = this._timestampService.dateObjToSecondString(this.dateObj);
    // Update
    this.updateUserState(true, date);
    this.isSelectingDate = false;
    this.dateObj = null;
  }

  updateUserState(isOutOfOffice: boolean, expireAt?: any): void {
    let state = isOutOfOffice ? UserConstant.USER_STATE.OUT_OF_OFFICE : UserConstant.USER_STATE.NORMAL;
    this._userContactService.updateSelfUserState(state, expireAt);
  }

}
