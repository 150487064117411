import { Injectable } from '@angular/core';
import { LoginMethod } from './models/login-methods-response';
import { LocalStorageManagerService } from '../utilities/local-storage/local-storage-manager.service';
import { TeamNoteLocalStorageKeyConstants } from '../constants/local-storage-key.constant';
import * as _ from 'lodash';
import { LoginMethodConstant } from './constants/login-methods.constant';
import { TeamNoteApiConstant } from '../constants/api.constant';
import { TeamnoteApiService } from '../api/teamnote-api.service';
import { TeamNoteGeneralConstant } from '../constants/general.constant';
import {UtilitiesService} from '../utilities/service/utilities.service';

@Injectable()
export class SamlService {

  popupWindow: any = null;
  popupCheckInterval: any = null;

  constructor(
    private _localStorageManagerService: LocalStorageManagerService,
    private _teamnoteApiService: TeamnoteApiService,
    private _utilitiesService: UtilitiesService,
  ) { }

  getPopUpOption(): string {
    let windowHeight = window.outerHeight;
    let windowWidth = window.outerWidth;

    let height = windowHeight / 2;
    let width = windowWidth / 2;

    let top = windowHeight / 4;
    let left = windowWidth / 4;

    return `top=${top},left=${left},height=${height},width=${width}`;
  }

  goToSamlEndpointByMethod(method: LoginMethod, callback?: Function): void {
    let endpoint = method.config.auth_endpoint;

    let tempForm = document.createElement("form");
    tempForm.setAttribute("method", "post");
    tempForm.setAttribute("action", endpoint.url);
    tempForm.setAttribute("target", TeamNoteGeneralConstant.POP_UP_WINDOW_NAME);

    let samlRequestField = document.createElement("input");
    samlRequestField.setAttribute("type", "text");
    samlRequestField.setAttribute("name", "SAMLRequest");
    samlRequestField.setAttribute("value", endpoint.SAMLRequest);

    let relayStateField = document.createElement("input");
    relayStateField.setAttribute("type", "text");
    relayStateField.setAttribute("name", "RelayState");
    relayStateField.setAttribute("value", window.location.pathname);

    tempForm.appendChild(samlRequestField);
    tempForm.appendChild(relayStateField);

    document.body.appendChild(tempForm);

    // console.warn(endpoint.SAMLRequest);

    let option = this.getPopUpOption();
    this.popupWindow = window.open(
      '', 
      TeamNoteGeneralConstant.POP_UP_WINDOW_NAME,
      option
    );

    tempForm.submit();

    document.body.removeChild(tempForm);

    let tempIsClosedFromSelfField = document.createElement("input");
    tempIsClosedFromSelfField.setAttribute("id", TeamNoteGeneralConstant.POP_UP_CHECK_SELF_CLOSED_ID);
    tempIsClosedFromSelfField.setAttribute("hidden", "hidden");
    tempIsClosedFromSelfField.setAttribute("value", "0");
    document.body.appendChild(tempIsClosedFromSelfField);

    this.popupCheckInterval = window.setInterval(() => {
      if (this.popupWindow.closed) {
        window.clearInterval(this.popupCheckInterval);

        let isClosedFromSelf = tempIsClosedFromSelfField.value;
        document.body.removeChild(tempIsClosedFromSelfField);

        if (isClosedFromSelf == "1" && callback) {
          callback();
        }
      }
    }, 1000);
  }

  getUserNameWithSamlResponse(success: Function, failure: Function): void {
    let url = TeamNoteApiConstant.LOGIN.SAML_ME;
    let params = {
      auth_name: this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.AUTH_NAME)
    };

    this._teamnoteApiService.callApi(
      url,
      params,
      success,
      failure
    );
  }

  webclientLoginWithSaml(success: Function, failure: Function, otp?: string): void {
    let url = TeamNoteApiConstant.LOGIN.SAML_LOGIN;
    let params = {
      auth_name: this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.AUTH_NAME),
      device_token: this._localStorageManagerService.getDeviceToken(),
      require_refresh_token: 1,
      otp: otp
    };
    this._teamnoteApiService.callApi(
      url,
      params,
      (resp) => {
        if (resp._jwt) {
          this._teamnoteApiService.getE2EEPublicKey(
            (publicKey) => {
              this._utilitiesService.decodeJwt(resp._jwt, publicKey,
                (resp) => {
                  success(resp);
                }
              );
            },
            () => {
              this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.AUTH_NAME);
              this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_NAME);
              failure();
            }
          );
        } else {
          success(resp);
        }
      },
      () => {
        this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.AUTH_NAME);
        this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_NAME);
        failure();
      }
    );
  }


}
