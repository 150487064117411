/**
 * Register / Login Process
 *
 * Register Flow:
 * Basic: [user name] -> get-state -> reg-init -> [set password] -> reg-finish -> reg-device -> login
 * With OTP: [user name] -> get-state -> reg-init -> [otp] -> reg-otp -> [set password] -> reg-finish -> reg-device -> login
 * With Second Factor: [user name] -> get-state -> [second factor] -> reg-init -> [set password] -> reg-finish -> reg-device -> login
 * With Second Factor & OTP: [user name] -> get-state -> [second factor] -> reg-init -> [otp] -> reg-otp -> [set password] -> reg-finish -> reg-device -> login
 *
 */

import {Injectable} from '@angular/core';
import {TeamnoteApiService} from '../api/teamnote-api.service';
import {TeamNoteApiConstant} from '../constants/api.constant';
import {TeamNoteLocalStorageKeyConstants} from '../constants/local-storage-key.constant';
import {PageUrlConstant} from '../constants/page-url.constant';
import {ErrorResponse} from '../models/error-response';
import {LocalStorageManagerService} from '../utilities/local-storage/local-storage-manager.service';
import {TnNotificationService} from '../utilities/tn-notification/tn-notification.service';
import {LOGIN_ACTIONS} from './constants/login-actions.constant';
import {RegistrationStateConstant} from './constants/registration-state.constant';
import {ChangePasswordResponse} from './models/change-password-response';
import {CustomLoginResponse} from './models/custom-login-response';
import {GetStateResponse} from './models/get-state-response';
import {RegisterDeviceResponse} from './models/register-device-response';
import {RegistrationFinishResponse} from './models/registration-finish-response';
import {RegistrationInitResponse} from './models/registration-init-response';
import {RegistrationOtpResponse} from './models/registration-otp-response';
import {WebclientLoginResponse} from './models/webclient-login-response';
import {AccountService} from '../account/account.service';
import {OauthService} from './oauth.service';
import {LoginMethodConstant} from './constants/login-methods.constant';
import {SamlService} from './saml.service';
import {HttpErrorResponse} from '@angular/common/http';
import {TeamnoteConfigService} from '../configs/teamnote-config.service';

import {UtilitiesService} from '../utilities/service/utilities.service';
import { includes }from 'lodash';

@Injectable()
export class WebclientLoginService {
  userName: string;
  secondFactorType: string;
  secondFactor: string;
  otp: string;
  hashedPassword: string;
  encryptedPassword: string;
  deviceToken: string;

  otpMethod: string;
  otpRecipient: string;
  OTP_METHOD = {
    NONE: 'none',
    EMAIL: 'email',
    SMS: 'sms',
    EXTERNAL: 'external'
  };
  getStateAction: string;

  SET_PASSWORD_PAGE_STATE = {
    PENDING_REG_FINISH: 1,
    PENDING_CHANGE_PW: 2
  };
  setPasswordPageState = 0;

  constructor(
    private _teamnoteApiService: TeamnoteApiService,
    private _accountService: AccountService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _tnNotificationService: TnNotificationService,
    private _oauthService: OauthService,
    private _samlService: SamlService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _utilitiesService: UtilitiesService,
  ) {
    this.resetLoginInfo();
  }

  resetLoginInfo(): void {
    this.userName = '';
    this.secondFactorType = '';
    this.secondFactor = '';
    this.otp = '';
    this.hashedPassword = '';
    this.deviceToken = '';
    this.otpMethod = '';
    this.otpRecipient = '';
    this.getStateAction = '';
    // if (!this._teamnoteConfigService.config.WEBCLIENT.GENERAL.IS_ALLOW_AUTO_EXTEND_SESSION) {
    //   this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.SESSION.PASSWORD);
    // }
  }

  removeUserPreserveCookies() {
    const localSettingsKeys = TeamNoteLocalStorageKeyConstants.USER_PRESERVE_COOKIES;
    for (const i in localSettingsKeys) {
      this._localStorageManagerService.removeCookiesByKey(localSettingsKeys[i]);
    }
  }

  getGeneralSystemErrorResposne() {
    const response = new CustomLoginResponse;
    response.isSuccess = false;
    response.errorMsg = null;
    return response;
  }

  generateDeviceToken() {
    this.deviceToken = this._localStorageManagerService.getDeviceToken();
  }

  // Handle Responses & Login Flow
  handleGetStateSuccessResponse(resp: GetStateResponse) {
    const response = new CustomLoginResponse;
    this.getStateAction = resp.action;
    if (resp.action === RegistrationStateConstant.ACTION.REGISTER) {
      this.secondFactorType = resp.require;
      return this.handleSecondFactor();
    } else if (resp.action === RegistrationStateConstant.ACTION.LOGIN) {
      // Go to enter password page
      response.isSuccess = true;
      response.nextPageUrl = PageUrlConstant.LOGIN.PASSWORD;
      return response;
    }
  }

  handleGetStateErrorResponse(err: ErrorResponse) {
    let response = new CustomLoginResponse;
    response.isSuccess = false;
    if (err.status === 400) {
      // wrong username
      response.errorStatus = 400;
      response.errorMsg = 'LOGIN.GET_STATE.FAIL_ERROR';
    } else if (err.status === 403) {
      // account locked
      response.errorStatus = 403;
      response.errorMsg = 'LOGIN.GET_STATE.ACCOUNT_LOCKED';
    } else {
      // show general error
      response = this.getGeneralSystemErrorResposne();
    }
    return response;
  }

  handleSecondFactor() {
    const response = new CustomLoginResponse;
    response.isSuccess = true;
    if (this.secondFactorType !== RegistrationStateConstant.REQUIRE.NONE) {
      // normal (has second factor)
      // Go to second factor input page
      response.nextPageUrl = PageUrlConstant.LOGIN.SECOND_FACTOR;
    } else {
      // second factor method = none
      // call Init Registration
      response.nextApiCall = LOGIN_ACTIONS.API_REG_INIT;
    }
    return response;
  }

  // Login Methods
  getLoginMethods(success: Function, failure: Function): void {
    const url = TeamNoteApiConstant.LOGIN.LOGIN_METHODS;
    const param = {
      device_type: 'web'
    };
    this._teamnoteApiService.callApi(
      url,
      param,
      success,
      failure
    );
  }

  // Get State
  getAreaCode(success: Function, failure: Function) {
    const url = TeamNoteApiConstant.LOGIN.GET_AREA_CODE;
    this._teamnoteApiService.callApiGet(url, success, failure);
  }

  registrationGetState(userName: string, success: Function, failure: Function) {
    this.registrationGetStateWithForgetPassword(userName,false, success,failure);
  }

  registrationGetStateWithForgetPassword(userName: string, isForgetPassword: boolean, success: Function, failure: Function) {
    this.userName = userName;
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_NAME, userName);
    
    if(isForgetPassword){
      const params = {
        user_name: this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_NAME),
        forget_password: 1
      };
      const url = TeamNoteApiConstant.LOGIN.REG_GET_STATE_FOR_FORGET_PASSWORD;
      this._teamnoteApiService.callApi(url, params, success, failure, true);
    }
    else{
      const params = {
        user_name: this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_NAME)
      };
      const url = TeamNoteApiConstant.LOGIN.REG_GET_STATE;
      this._teamnoteApiService.callApi(url, params, success, failure, true);
    }
  }

  registrationInit(success: Function, failure: Function, isForgetPassword:boolean, secondFactor?: string) {
    if (secondFactor) {
      this.secondFactor = secondFactor;
    }
    let params = {
      user_name: this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_NAME),
      second_factor: this.secondFactor
    };
    if(isForgetPassword){
      params["forget_password"] = isForgetPassword;
    }

    const url = TeamNoteApiConstant.LOGIN.WEBCLIENT_REG_INIT;

    // Fallback to old /registration/init API if /webclient/init is not valid.
    const fallbackOldApi = () => {
      const url = TeamNoteApiConstant.LOGIN.REG_INIT;
      this._teamnoteApiService.callApi(url, params, success, failure, true);
    };

    this._teamnoteApiService.callApi(url, params, success, fallbackOldApi, true);
  }

  handleRegistrationInitSuccessResponse(resp: RegistrationInitResponse) {
    let response = new CustomLoginResponse;
    if (resp.success) {
      response.isSuccess = true;
      if (resp.method === this.OTP_METHOD.NONE) {
        this.otp = resp.recipient;
        this.setPasswordPageState = this.SET_PASSWORD_PAGE_STATE.PENDING_REG_FINISH;
        response.nextPageUrl = PageUrlConstant.LOGIN.SET_PASSWORD;
      } else {
        this.otpMethod = resp.method;
        this.otpRecipient = resp.recipient;
        response.nextPageUrl = PageUrlConstant.LOGIN.OTP;
        this.promptOtpInfoDialog(resp.method, resp.recipient);
      }
    } else {
      response = this.getGeneralSystemErrorResposne();
    }
    return response;
  }

  registrationOTP(otp: string, success: Function, failure: Function) {
    this.otp = otp;
    const params = {
      user_name: this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_NAME),
      otp: otp
    };
    const url = TeamNoteApiConstant.LOGIN.REG_OTP;
    this._teamnoteApiService.callApi(url, params, success, failure);
  }

  handleRegistrationOtpSuccessResponse(resp: RegistrationOtpResponse) {
    let response = new CustomLoginResponse;
    if (resp.success) {
      // set password
      response.isSuccess = true;
      if (this.getStateAction === RegistrationStateConstant.ACTION.LOGIN) {
        response.nextApiCall = LOGIN_ACTIONS.API_WEBCLIENT_REG_DEVICE;
      } else {
        response.nextPageUrl = PageUrlConstant.LOGIN.SET_PASSWORD;
      }
    } else {
      response = this.getGeneralSystemErrorResposne();
    }
    return response;
  }

  handleRegistrationOtpErrorResponse(err) {
    let response = new CustomLoginResponse;
    response.isSuccess = false;
    if (err.status === 401) {
      response.errorMsg = 'LOGIN.OTP.FAIL_ERROR';
    } else {
      // show general error
      response = this.getGeneralSystemErrorResposne();
    }
    return response;
  }

  promptOtpInfoDialog(method: string, recipient: string) {
    let infoKey;
    switch (method) {
      case this.OTP_METHOD.EMAIL:
        infoKey = 'LOGIN.SECOND_FACTOR.OTP_EMAIL';
        break;
      case this.OTP_METHOD.SMS:
        infoKey = 'LOGIN.SECOND_FACTOR.OTP_SMS';
        break;
    }
    if (infoKey) {
      this._tnNotificationService.showCustomInfoByTranslateKey(infoKey);
    }
  }

  registrationFinish(hashedPassword: string, success: Function, failure: Function, encryptedPassword?: string) {
    if (hashedPassword) {
      this.hashedPassword = hashedPassword;
    }
    if (encryptedPassword) {
      this.encryptedPassword = encryptedPassword;
    }

    if (this._teamnoteConfigService.config.WEBCLIENT.GENERAL.IS_ALLOW_AUTO_EXTEND_SESSION) {
      this._localStorageManagerService.setLocalStorageByKey(TeamNoteLocalStorageKeyConstants.SESSION.PASSWORD, encryptedPassword || hashedPassword);
    }

    const params = {
      user_name: this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_NAME),
      otp: this.otp,
      password: this.hashedPassword
      // encrypted_password: this.encryptedPassword
    };
    const url = TeamNoteApiConstant.LOGIN.WEBCLIENT_REG_FINISH;

    // Fallback to old /registration/finish API if /webclient/finish is not valid.
    const fallbackOldApi = () => {
      const url = TeamNoteApiConstant.LOGIN.REG_FINISH;
      this._teamnoteApiService.callApi(url, params, success, failure, true);
    };

    this._teamnoteApiService.callApi(url, params, success, fallbackOldApi, true);
  }

  handleRegistrationFinishSuccessResponse(resp: RegistrationFinishResponse) {
    let response = new CustomLoginResponse;
    if (resp.success) {
      // reg device
      response.isSuccess = true;
      response.nextApiCall = LOGIN_ACTIONS.API_WEBCLIENT_REG_DEVICE;
    } else if (resp.reuse_password) {
      response.isSuccess = false;
      response.errorMsg = 'LOGIN.PASSWORD.PASSWORD_REUSED';
      response.nextPageUrl = PageUrlConstant.LOGIN.SET_PASSWORD;
      this.setPasswordPageState = this.SET_PASSWORD_PAGE_STATE.PENDING_REG_FINISH;
    } else {
      response = this.getGeneralSystemErrorResposne();
    }
    return response;
  }

  handleRegistrationFinishErrorResponse(err) {
    return this.getGeneralSystemErrorResposne();
  }

  registrationChangePassword(newPassword: string, success: Function, failure: Function, encryptedPassword?: string) {
    if (encryptedPassword) {
      this.encryptedPassword = encryptedPassword;
    }

    if (this._teamnoteConfigService.config.WEBCLIENT.GENERAL.IS_ALLOW_AUTO_EXTEND_SESSION) {
      // this._localStorageManagerService.setLocalStorageByKey(TeamNoteLocalStorageKeyConstants.SESSION.PASSWORD, encryptedPassword || newPassword);
    }

    const params = {
      user_name: this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_NAME),
      hashed_password: this.hashedPassword,
      new_password: newPassword
      // encrypted_password: this.encryptedPassword
    };
    const url = TeamNoteApiConstant.LOGIN.REG_CHANGE_PW;
    this._teamnoteApiService.callApi(url, params, success, failure);
  }

  handleRegistrationChangePasswordSuccessResponse(resp: ChangePasswordResponse) {
    let response = new CustomLoginResponse;
    if (resp.success) {
      response.isSuccess = true;
      response.nextApiCall = LOGIN_ACTIONS.API_WEBCLIENT_LOGIN;
    } else if (resp.reuse_password) {
      response.isSuccess = false;
      response.errorMsg = 'LOGIN.PASSWORD.PASSWORD_REUSED';
    } else {
      response = this.getGeneralSystemErrorResposne();
    }
    return response;
  }

  handleRegistrationChangePasswordErrorResponse(err) {
    return this.getGeneralSystemErrorResposne();
  }


  webclientRegisterDevice(success: Function, failure: Function) {
    const params = {
      user_name: this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_NAME),
      otp: this.otp,
      device_token: this._localStorageManagerService.getDeviceToken(),
      device_type: 'web',
      device_name: 'web-client',
      device_model: navigator.userAgent
    };
    const url = TeamNoteApiConstant.LOGIN.WEBCLIENT_REG_DEVICE;
    this._teamnoteApiService.callApi(url, params, success, failure, true);
  }

  handleWebclientRegisterDeviceSuccessResponse(resp: RegisterDeviceResponse) {
    let response = new CustomLoginResponse;
    if (resp.success) {
      // call webclient login again
      response.isSuccess = true;
      response.nextApiCall = LOGIN_ACTIONS.API_WEBCLIENT_LOGIN;
    } else {
      // return error
      response = this.getGeneralSystemErrorResposne();
    }
    return response;
  }

  handleWebclientRegisterDeviceErrorResponse(err) {
    return this.getGeneralSystemErrorResposne();
  }


  webclientLogin(success: Function, failure: Function, hashedPassword?: string, extAuthType?: string, encryptedPassword?: string, publicKey?) {
    if (extAuthType) {
      switch (extAuthType) {
        case LoginMethodConstant.AUTH_TYPE.OAUTH:
          this._oauthService.webclientLoginWithOAuth(
            success,
            failure,
            this.otp
          );
          break;
        case LoginMethodConstant.AUTH_TYPE.SAML:
          this._samlService.webclientLoginWithSaml(
            success,
            failure,
            this.otp
          );
          break;
      }
      return;
    }

    if (hashedPassword) {
      this.hashedPassword = hashedPassword;
    }
    if (encryptedPassword) {
      this.encryptedPassword = encryptedPassword;
    }

    if (this._teamnoteConfigService.config.WEBCLIENT.GENERAL.IS_ALLOW_AUTO_EXTEND_SESSION) {
      // this._localStorageManagerService.setLocalStorageByKey(TeamNoteLocalStorageKeyConstants.SESSION.PASSWORD, encryptedPassword || hashedPassword);
    }

    const params = {
      device_token: this._localStorageManagerService.getDeviceToken(),
      refresh_token: this._localStorageManagerService.getSessionStorageByKey(TeamNoteLocalStorageKeyConstants.SESSION.REFRESH_TOKEN),
      require_refresh_token: 1,
      user_name: this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_NAME),
      hashed_password: this.hashedPassword,
      encrypted_password: this.encryptedPassword,
      timestamp: Math.floor(new Date().getTime() / 1000),
    };

    // Sign param with public key
    let encrypted_body = null;
    let encrypted_key = null;
    if (publicKey) {
      // Generate AES Key
      const CryptoJS = require('crypto-js');

      const convertToByteArray = function (hex) {
        return hex.words.map(function (v) {
          // create an array of 4 bytes (less if sigBytes says we have run out)
          const bytes = [0, 0, 0, 0].slice(0, Math.min(4, hex.sigBytes))
            // grab that section of the 4 byte word
            .map(function (d, i) {
              return (v >>> (8 * i)) % 256;
            })
            // flip that
            .reverse()
          ;
          // remove the bytes we've processed
          // from the bytes we need to process
          hex.sigBytes -= bytes.length;
          return bytes;
        })
          // concatinate all the arrays of bytes
          .reduce(function (a, d) {
            return a.concat(d);
          }, [])
          // convert the 'bytes' to 'characters'
          .map(function (d) {
            return String.fromCharCode(d);
          })
          // create a single block of memory
          .join('');
      };

      const iv = CryptoJS.lib.WordArray.random(128 / 8);
      const salt = CryptoJS.lib.WordArray.random(128 / 8);
      // const key = CryptoJS.PBKDF2('TeamNote', salt, {
      //   keySize: 128 / 32
      // });
      const key = CryptoJS.enc.Hex.parse(salt.toString());
      const encrypted_params = CryptoJS.AES.encrypt(
        JSON.stringify(params),
        key,
        {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        }
      ).toString();
      encrypted_body = btoa(convertToByteArray(iv) + atob(encrypted_params));

      encrypted_key = this._utilitiesService.getEncryptedValueByPublicKey(btoa(convertToByteArray(key)), publicKey);

      // encrypted_body = this._utilitiesService.getEncryptedValueByPublicKey(JSON.stringify(params), publicKey);
    }

    const url = TeamNoteApiConstant.LOGIN.WEBCLIENT_LOGIN;
    this._teamnoteApiService.callApi(url, {...params, encrypted_body, encrypted_key}, (resp) => {
      if (resp._jwt) {
        this._teamnoteApiService.getE2EEPublicKey(
          (publicKey) => {
            this._utilitiesService.decodeJwt(resp._jwt, publicKey,
              (resp) => {
                success(resp);
              }
            );
          },
          () => {
            failure();
          }
        );
      } else {
        success(resp);
      }
    }, failure, true);
  }

  webclientLoginByToken(sessionToken: string, success: Function, failure: Function) {
    const params = {
      device_token: this._localStorageManagerService.getDeviceToken(),
      refresh_token: this._localStorageManagerService.getSessionStorageByKey(TeamNoteLocalStorageKeyConstants.SESSION.REFRESH_TOKEN),
      require_refresh_token: 1,
      user_name: this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_NAME),
      timestamp: Math.floor(new Date().getTime() / 1000),

      access_token: sessionToken,
    };

    const url = TeamNoteApiConstant.LOGIN.WEBCLIENT_LOGIN;
    this._teamnoteApiService.callApi(url, params, (resp) => {
      if (resp._jwt) {
        this._teamnoteApiService.getE2EEPublicKey(
          (publicKey) => {
            this._utilitiesService.decodeJwt(resp._jwt, publicKey,
              (resp) => {
                success(resp);
              }
            );
          },
          () => {
            failure();
          }
        );
      } else {
        success(resp);
      }
    }, failure, true);
  }

  handleWebclientLoginSuccessResponse(resp: WebclientLoginResponse) {
    const response = new CustomLoginResponse;
    if (resp.success) {
      // Login Success!! Set user name & password to session storage. Redirect to web client
      this._accountService.setUserAccount(resp.user.name, resp.user.pic, resp.user.user_id, resp.user.user_name);
      this._accountService.setFullLoginResponse(resp);
      if (resp.refresh_token) {
        this._localStorageManagerService.setSessionStorageByKey(TeamNoteLocalStorageKeyConstants.SESSION.REFRESH_TOKEN, resp.refresh_token);
      }
      response.isSuccess = true;
      response.nextPageUrl = PageUrlConstant.WEBCLIENT.BASE;
    } else {
      response.isSuccess = false;
      if (resp.enter_otp) {
        if (resp.method === RegistrationStateConstant.REQUIRE.NONE) {
          // Set recipient as OTP
          this.otp = resp.recipient;
          // call webclientRegisterDevice
          response.nextApiCall = LOGIN_ACTIONS.API_WEBCLIENT_REG_DEVICE;
        } else {
          this.otpMethod = resp.method;
          this.otpRecipient = resp.recipient;
          // Go to OTP page
          response.nextPageUrl = PageUrlConstant.LOGIN.OTP;
        }
      } else if (resp.device_limit) {
        // return device limite reach error
        response.errorMsg = 'LOGIN.PASSWORD.MAX_REG_DEVICE';
      } else if (resp.password_expired) {
        // go to reset password page
        response.nextPageUrl = PageUrlConstant.LOGIN.SET_PASSWORD;
        this.setPasswordPageState = this.SET_PASSWORD_PAGE_STATE.PENDING_CHANGE_PW;
      }
    }
    return response;
  }

  handleWebclientLoginErrorResponse(err: HttpErrorResponse) {
    // clear hash password
    this.hashedPassword = null;

    let response = new CustomLoginResponse;
    response.isSuccess = false;
    if (err.status === 401) {
      // login fail
      response.errorMsg = 'LOGIN.PASSWORD.LOGIN_FAIL';
      if (err.error) {
        if (err.error.suspended) {
          response.errorMsg = 'LOGIN.PASSWORD.ACCOUNT_SUSPENDED';
        }
      }
      this._localStorageManagerService.removeSessionStorageByKey(TeamNoteLocalStorageKeyConstants.SESSION.REFRESH_TOKEN);
    } else {
      response = this.getGeneralSystemErrorResposne();

      if (includes(err.error, 'User not found')) {
        // response.errorMsg = 'User not found';
        response.errorMsg = 'GENERAL.SYSTEM_ERROR';
      } else if (includes(err.error, 'Wipe Device')) {
        // should clear the device token in cookies
        this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.DEVICE_TOKEN);
        // also clear the previous refresh token
        this._localStorageManagerService.removeSessionStorageByKey(TeamNoteLocalStorageKeyConstants.SESSION.REFRESH_TOKEN);
        
        response.errorMsg = 'LOGIN.PASSWORD.DEVICE_TOKEN_WIPE';
      }
    }
    return response;
  }

  loginWithQRCode(sessionId: string, success: Function, failure: Function): void {
    const params = {
      session_id: sessionId,
      device_token: this._localStorageManagerService.getDeviceToken(),
      require_refresh_token: 1,
      device_type: 'web',
      device_name: 'web-client',
      device_model: navigator.userAgent,
    };

    const url = TeamNoteApiConstant.LOGIN.QRCODE_LOGIN;
    this._teamnoteApiService.callApi(url, params, (resp) => {
      if (resp._jwt) {
        this._teamnoteApiService.getE2EEPublicKey(
          (publicKey) => {
            this._utilitiesService.decodeJwt(resp._jwt, publicKey,
              (resp) => {
                success(resp);
              }
            );
          },
          () => {
            failure();
          }
        );
      } else {
        success(resp);
      }
    }, failure, true, false, true);
  }

}
