export const ZhChsNewsStrings = {
  NEWS: {
    NEWS_DATE: "NEWS_DATE_ZH",
    LIKE: "赞好",
    UNLIKE: "取消赞好",
    COMMENT: "留言",
    REPLY: "回覆",
    REPLY_COUNT: {
      ONE: "{{NUM}}个回覆",
      MULTIPLE: "{{NUM}}个回覆"
    },
    ATTACHMENTS_COUNT: {
      ONE: "{{NUM}}个附件",
      MULTIPLE: "{{NUM}}个附件"
    },
    REMOVE_COMMENT_SUCCESS_MSG: "删除留言成功"
  },

  VOTE: {
    VOTE_BTN: "投票",
    RESULT_BTN: "结果",
    VOTE_OPTION_DESC: {
      ONE: "({{PERCENTAGE}}%, {{COUNT}}个投票)",
      MULTIPLE: "({{PERCENTAGE}}%, {{COUNT}}个投票)"
    },
    SUCCESS_MSG: "投票成功"
  },

  ERROR_MSG: {
    VOTE_NOT_YET_START: "投票尚未开始。请稍后再试",
    VOTE_EXPIRED: "投票已经过时",
    VOTE_RESULT_HIDDEN: "本次投票结果不可公开浏览",
    VOTED: "你已经投过票了，答案不可更改",
    THREAD_LOCKED: "主题已锁定"
  }
};
