import { UserContact } from "../../../models/user-contact";

export class StoreReport {
  company_domain: string;
  create_date: string;
  created_by: string;
  creator: UserContact;
  creator_name: string;
  custom_fields: StoreReportCustomFieldsAnswer[];
  is_deleted: number;
  is_dispatchable: number;
  job_dispatch: JobDispatch[];
  report_id: string;
  report_no: string;
  report_no_seq: number;
  store: Store;
  store_id: string;
  type: StoreReportType;
  type_id: string;

  // local
  reportStatus?: number;
  reportStatusStr?: string;
  order?: number;
  mappedCustomFields?: StoreReportCustomFieldsMapped[];

  constructor() {
    this.job_dispatch = [];
  }
}

export class StoreReportOptions {
  stores: Store[];
  types: StoreReportType[];
}

export class Store {
  company_domain: string;
  create_date: string;
  extension: string;
  is_manageable: number;
  is_manager: number;
  is_store: number;
  location: string;
  location_en: string;
  name: string;
  store_code: string;
  store_id: string;
  updaet_date: string;
  user_group_id: string;
}

export class StoreReportType {
  allow_job_dispatch: number;
  company_domain: string;
  custom_fields: StoreReportCustomFieldsDefinition[];
  job_type: StoreReportJobType;
  name: string;
  type_id: string;
  visible_in_app_menu: number;
  visible_to_member: number;
  list_format: string;
}

export class StoreReportCustomFieldsDefinition {
  detail: string;
  field_id: string;
  field_index: number;
  field_type: number;
  is_nullable: number;
  is_submit: number;
  name: string;
  type_id: string;
}

export class StoreReportJobType {
  company_domain: string;
  custom_fields: StoreReportCustomFieldsDefinition[];
  name: string;
  type_id: string;
  list_format: string;
}

export class JobDispatch {
  accept_status: number;
  accept_time: string;
  assigned_by: string;
  assigner: UserContact;
  assigner_name: string;
  custom_fields: StoreReportCustomFieldsAnswer[];
  declined_time: string;
  dispatch_date: string;
  end_time: string;
  job_id: string;
  report: StoreReport;
  report_id: string;
  response_time: string;
  start_time: string;
  type: StoreReportJobType;
  type_id: string;
  worker_id: string;

  // local
  reportStatus?: number;
  reportStatusStr?: string;
  order?: number;
  mappedCustomFields?: StoreReportCustomFieldsMapped[];
}

export class StoreReportCustomFieldsAnswer {
  field_id: string;
  name?: string;
  value: string;
  files?: File[];
}

export class StoreReportCustomFieldsMapped {
  definition: StoreReportCustomFieldsDefinition;
  answer: StoreReportCustomFieldsAnswer;
}

export class StoreReportFilterParam {
  reportDateFrom?: string;
  reportDateTo?: string;
  jobDispatchDateFrom?: string;
  jobDispatchDateTo?: string;
  storeId?: string;
  typeId?: string;
  statusTranslateKey?: string;

  content?: string;

  constructor(
    reportDateFrom?: string,
    reportDateTo?: string,
    jobDispatchDateFrom?: string,
    jobDispatchDateTo?: string,
    storeId?: string,
    typeId?: string,
    statusTranslateKey?: string,
    content?: string
  ) {
    this.reportDateFrom = reportDateFrom;
    this.reportDateTo = reportDateTo;
    this.jobDispatchDateFrom = jobDispatchDateFrom;
    this.jobDispatchDateTo = jobDispatchDateTo;
    this.storeId = storeId;
    this.typeId = typeId;
    this.statusTranslateKey = statusTranslateKey;
    this.content = content;
  }
}

export class StoreReportCustomRouteParam {
  typeId?: string;

  constructor(typeId?: string) {
    this.typeId = typeId;
  }
}