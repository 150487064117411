/**
 * ISA Strings
 * 
 * Created by Shan - 2018-11-14
 */

import { LanguageConstant } from "../../constants/language.constant";
import { LanguageString } from "../../strings/string-helper.service";

export const IsaStrings: LanguageString[] = [
  {
    key: LanguageConstant.EN.key,
    strings: {
      LOGIN: {
        PASSWORD: {
          NOT_MATCH_POLICY: "Password must be at least {{NUM_CHAR}} characters long"
        }
      }
    }
  },
  {
    key: LanguageConstant.ZH_CHT.key,
    strings: {
      LOGIN: {
        PASSWORD: {
          NOT_MATCH_POLICY: "密碼長度最少為{{NUM_CHAR}}個字"
        }
      }
    }
  },
  {
    key: LanguageConstant.ZH_CHS.key,
    strings: {
      LOGIN: {
        PASSWORD: {
          NOT_MATCH_POLICY: "密码长度最少为{{NUM_CHAR}}个字"
        }
      }
    }
  }
];