<div class="notification-body" (click)="preventCloseClick($event)">
  <div class="header">
    {{"GENERAL.NOTIFICATION" | translate}}
  </div>
  <div class="content">
    <tn-notification-cell 
      *ngFor="let notification of notifications"
      [notification]="notification">
    </tn-notification-cell>

    <div class="empty-notification text-color-secondary" *ngIf="notifications.length == 0">
      {{"GENERAL.NO_NOTIFICATION" | translate}}
    </div>
  </div>
</div>