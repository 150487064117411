import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Chat } from '../../models/chat';
import { ContactPickerService } from '../../webclient/contact-picker/contact-picker.service';
import { UserContactService } from '../../webclient/services/data/user-contact/user-contact.service'
import { UserContact } from '../../models/user-contact';
import * as _ from 'lodash';

@Component({
  selector: 'tn-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

  searchBarDebounceTimeMs: number = 500;

  keyword: string;
  filteredItems: UserContact[] = []

  @Input() keywordDefault: string = null;

  @Input() chat: Chat = null;
  @Input() isEnableFilter: boolean = false;
  @Input() isLoading: boolean = false;

  @Output() onKeywordChanged = new EventEmitter<string>();
  @ViewChild('keywordInput', {static: false}) keywordElement: ElementRef;
  @Output() onFilterChanged = new EventEmitter<any>();

  keywordChangeSubject: Subject<string> = new Subject<string>();

  constructor(
    private _contactPickerService: ContactPickerService,
    private _userContactService: UserContactService
  ) { }

  ngOnInit() {
    this.keywordChangeSubject
      .pipe(
        debounceTime(this.searchBarDebounceTimeMs)
      )
      .pipe(
        distinctUntilChanged()
      )
      .subscribe(keyword => {
        this.onKeywordChanged.emit(this.keyword);
      });
  }

  ngOnChanges() {
    if (this.keywordDefault != this.keyword) {
      this.keyword = this.keywordDefault;
      this.onKeywordChanged.emit(this.keyword);
    }
  }

  search(): void {
    this.keywordChangeSubject.next(this.keyword);
  }

  clear(): void {
    this.keyword = '';
    this.search();
    this.keywordElement.nativeElement.focus();
  }

  filter(): void {
    if (!this.chat) {
      return
    }
    
    let groupChatMembers = this.chat.members.map((m) => {
      return this._userContactService.getUserContactByUserId(m);
    });

    this._contactPickerService.openContactPicker(
      'WEBCLIENT.CHAT.SEARCH_UNFILTERED',
      false,
      false,
      true,
      (contacts: UserContact[]) => {
        this.filteredItems = _.cloneDeep(contacts)
        this.onFilterChanged.emit(contacts)
      },
      true,
      null,
      groupChatMembers,
      false,
      this.filteredItems,
      true, // filter mode
      true // allow select all
    )
  }

}
