import { Component, OnInit, Input } from '@angular/core';
import { TrainingFile } from '../models/training';
import { TrainingService } from '../training.service';

@Component({
  selector: 'tn-training-file',
  templateUrl: './training-file.component.html',
  styleUrls: ['./training-file.component.scss']
})
export class TrainingFileComponent implements OnInit {

  @Input() file: TrainingFile;

  constructor(
    private _trainingService: TrainingService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.file.fileType = this._trainingService.getFileTypeByFileIds(this.file.file_ids);
  }

}
