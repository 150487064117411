import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { MessageTextBody } from '../../../../../models/message-text-body';
import { LinkifyService, TextMessagePart } from '../../../../../utilities/linkify/linkify.service';
import { TeamnoteConfigService } from '../../../../../configs/teamnote-config.service';
import { UserContact } from '../../../../../models/user-contact';
import { UserContactService } from '../../../../services/data/user-contact/user-contact.service';

@Component({
  selector: 'tn-text-message',
  templateUrl: './text-message.component.html',
  styleUrls: ['./text-message.component.scss']
})
export class TextMessageComponent implements OnInit {
  @Input() body: MessageTextBody;
  @Output() onUserClick: EventEmitter<UserContact> = new EventEmitter<UserContact>(null);
  @Output() onHashtagClick: EventEmitter<string> = new EventEmitter<string>(null);

  texts: TextMessagePart[] = [];
  
  isHtml: boolean = false;

  constructor(
    private _linkifyService: LinkifyService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _userContactService: UserContactService
  ) { }

  ngOnInit() {
    if (!this.body.message) {
      return;
    }
    
    let mentionReplacer = this.body.message_mention;
    this.isHtml = this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.ENABLE_HTML_MESSAGE;
    if (this.isHtml) {
      // If we enable HTML message, no need to parse the message, display as innerHTML direcly
      this.texts = this._linkifyService.getMessagePart(false, this.body.message, false, mentionReplacer);
    } else {
      // We need to parse the message first in order to display properly
      this.texts = this._linkifyService.parseTextMessageForDisplay(this.body.message, mentionReplacer);
    }
  }

  mentionClick(text: TextMessagePart): void {
    if (!text.isMention) {
      return;
    }
    let user = this._userContactService.getUserContactByUserId(text.mentionUserId);
    this.onUserClick.emit(user);
    
  }

  hashtagClick(text: TextMessagePart): void {
    if (!text.isHashtag) {
      return;
    }
    this.onHashtagClick.emit(text.text);
  }
}
