import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WorkflowForm } from '../model/workflow';
import { TextMessagePart } from '../../../utilities/linkify/linkify.service';
import { WorkflowCellFormatService } from '../helper/workflow-cell-format.service';
import { FieldHelperService } from '../../shared/custom-field/helper/field-helper.service';

@Component({
  selector: 'tn-form-cell',
  templateUrl: './form-cell.component.html',
  styleUrls: ['./form-cell.component.scss']
})
export class FormCellComponent implements OnInit {

  @Input() form: WorkflowForm = null;
  @Input() viewingForm: WorkflowForm = null;
  @Output() onFormCellClick: EventEmitter<WorkflowForm> = new EventEmitter<WorkflowForm>(null);

  isSelected: boolean = false;

  infoListFormatHtml: string = "";
  infoListFormatDisplayArr: TextMessagePart[] = [];

  constructor(
    private _workflowCellFormatService: WorkflowCellFormatService,
    private _fieldHelperService: FieldHelperService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.parseDisplay();
  }

  parseDisplay(): void {
    this.isSelected = false;
    if (this.viewingForm && this.viewingForm.form_id == this.form.form_id) {
      this.isSelected = true;
    }

    this.infoListFormatHtml = this._workflowCellFormatService.getWorkflowListCellFormatByForm(this.form, false);
    this.handleSpcialLocalTranslateStrings();
  }

  handleSpcialLocalTranslateStrings(): void {
    this.infoListFormatDisplayArr = this._fieldHelperService.handleSpcialLocalTranslateStrings(this.infoListFormatHtml);
  }

  onClick(): void {
    this.onFormCellClick.emit(this.form);
  }

}
