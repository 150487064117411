export const EnBroadcastStrings = {
  TITLE: "Broadcast",

  NAME: "Name",
  RECIPIENTS: "Recipients",
  RECIPIENT_COUNT: {
    ONE: "{{NUM}} Recipient",
    MULTIPLE: "{{NUM}} Recipients"
  },
  RECIPIENT_REMOVE: "Remove All ({{NUM}})",
  ADD_FROM_TEAMNOTE: "Add from TeamNote",
  ADD_FROM_BROADCAST_LIST: "Add from Broadcast List",


  SEND: {
    TITLE: "Send Broadcast Message",
    CONFIRM_MSG: {
      ONE: "Send broadcast message to {{NUM}} recipient?",
      MULTIPLE: "Send broadcast message to {{NUM}} recipients?"
    },
    NO_RECIPIENT_ERROR_MSG: "Please select recipients.",
    SEND_BROADCAST_SUCCESS_MSG: "Broadcast message sent successfully."
  },

  MANAGE: {
    TITLE: "Manage Broadcast List",
    ADD_BROADCAST_LIST: "Add Broadcast List",
    EDIT_BROADCAST_LIST: "Edit Broadcast List",
    DELETE_BROADCAST_LIST_CONFIRM: "Delete Broadcast List - {{NAME}} ?"
  }
}
