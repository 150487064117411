export const ZhChsWorkflowStrings = {
  NEW_FORM: {
    TITLE: "新增表格",
    TEAM: "团队",
    TYPE: "表格类型",
    NO_PERMISSION: "没有权限提交{{name}}"
  },

  FILTER: {
    TITLE: "筛选",
    CREATE_DATE: "提交日期",
    TEAM: "团队",
    TYPE: "表格类型",
    STAGE: "阶段",
    CONTENT: "内容",
    APPLY: "应用"
  },

  EXPORT: {
    TITLE: "导出",
    FORMS: "表格",
    TIME_PERIOD: "时间范围",
    OPTION: {
      AUTOMATIC: "Displaying forms",
      MANUAL: "Configure manually"
    },
    IN_PROGRESS_MSG: "资料导出中，你可以在右上角的通知中心查看进度，你将在档案准备完成后收到通知。",
    EXPORT_READY_MSG: "档案已准备完成，你可以在通知中心下载档案。"
  },

  DETAIL: {
    TITLE: "表格详情",
    OBSERVER_SUCCESS_MSG: "成功新增观察者",
    ACCEPT_SUCCESS_MSG: "表格已接受"
  },

  OPTIONS: {
    REJECT: "拒绝",
    SAVE_DRAFT: "储存草稿",
    SUBMIT: "提交",
    REASSIGN: "重新分配",
    STAGES: "阶段",
    ASSIGN: "分派",
    ADD_OBSERVER: "新增观察者",
    ACCEPT: "接受"
  }
}