import { Injectable } from '@angular/core';
import { Question } from '../../models/questionnaire';

import * as _ from 'lodash';
import { TeamNoteQuestionnaireConstant } from '../../constants/questionnaire.constant';

@Injectable()
export class QuestionMultipleChoiceService {

  constructor() { }

  getParsedMultipleChoiceQuestion(q: Question): Question {
    q.mcDetail = JSON.parse(q.detail);
    q.value = "";
    q.is_mandatory = true;
    q.mcDetail.options = _.map(q.mcDetail.options, (o) => {
      o.value = false;
      o.hasError = false;
      o.textAns = "";
      return o;
    });
    return q;
  }

  getCheckedMultipleChoiceQuestion(q: Question): Question {
    if (q.value === '') {
      q.hasError = true;
    } else {
      _.each(q.mcDetail.options, (o) => {
        if (o.free_text === TeamNoteQuestionnaireConstant.EXTRA_FREE_TEXT.MANDATORY && 
          q.value == o.option && 
          o.textAns.length == 0) {
          q.hasError = true;
        }
      });
    }
    return q;
  }

  getParsedMultipleChoiceAnswer(q: Question): any {
    let ansArr = [];
    _.each(q.mcDetail.options, (o) => {
      if (o.option === q.value) {
        ansArr.push(o.option);
        if (o.textAns != '') {
          ansArr.push(o.textAns);
        }
      }
    });
    return ansArr;
  }
}
