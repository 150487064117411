<div class="form-cell clickable"
  [ngClass]="{'form-cell_selected': isSelected}"
  (click)="onClick()">
  <div class="info-wrapper">
    <ng-container *ngFor="let text of infoListFormatDisplayArr"><ng-container *ngIf="!text.isStr && !text.isTranslateString"><span class="text" [innerHTML]="text.text"></span></ng-container><ng-container *ngIf="text.isTranslateString"><span class="form-translate-text">{{text.text | translate}}</span></ng-container></ng-container>
  </div>
  <div class="status-icon" *ngIf="form.isFormActive || form.isFormPending">
    <div class="icon" 
      [ngClass]="{
        'unread-bubble': form.isFormActive, 
        'pending-bubble': form.isFormPending
      }">
    </div>
  </div>
</div>