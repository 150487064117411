import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ContextMenuService, ContextMenu } from './context-menu.service';
import { EmojiService } from '../emoji/emoji.service';

@Component({
  selector: 'tn-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit {

  menu: ContextMenu = new ContextMenu;
  @ViewChild('contextMenu', {static: false}) contextMenu: ElementRef;
  @ViewChild('emojiMenu', {static: false}) emojiMenu: ElementRef;

  baseHeight = 20;
  itemHeight = 48;

  contextMenuWidth: number = null;

  posX_offset = 0
  posY_offset = -50
  emojiMenuEleWidth = 328
  emojiMenuEleHeight = 45

  constructor(
    private _contextMenuService: ContextMenuService,
    private _emojiService: EmojiService
  ) { 
    this._contextMenuService.menu$.subscribe(menu => {
      this.menu = menu;
      this.checkMenuPosition();

      setTimeout(() => { 
        this.menu.isShowEmojiMenu && this.checkEmojiMenuPosition()
      }, 0)
    });
  }
  
  ngOnInit() {
  }

  checkMenuPosition() {
    let ww = window.innerWidth;
    let wh = window.innerHeight;

    // check window width and set x & y
    if (this.contextMenu) {
      let element = this.contextMenu.nativeElement;

      let w = element.clientWidth;
      let h = this.itemHeight * this.menu.menus.length;

      if (this.menu.posX + w > ww) {
        this.menu.posX = this.menu.posX - w;
      }

      if (this.menu.posY + h > wh) {
        this.menu.posY = this.menu.posY - h;
      }
    }

    if (!this.menu.isShow) {
      this.menu.posX = ww;
      this.menu.posY = wh;
      this.posX_offset = 0
      this.posY_offset = -50
    }
  }

  checkEmojiMenuPosition() {
    let ww = window.innerWidth;
    
    if (this.emojiMenu) {
      let element = this.contextMenu.nativeElement;
      let contextMenuWidth = element.clientWidth + 2;
      let chatroomLeftX = ww - this.menu.messageRow
      // 40 is the menu item height after rendering view, 12 is the x-axis padding
      let h = 40 * this.menu.menus.length + 12; 

      if (this.emojiMenuEleHeight + 150 >= this.menu.posY) {
        this.posY_offset = h + 5
      }

      if (this.menu.posX + this.emojiMenuEleWidth > ww) {
        let offset_x = contextMenuWidth - this.emojiMenuEleWidth
        this.posX_offset = offset_x
      }

      if (this.menu.posX + this.posX_offset <= chatroomLeftX) {
        this.posX_offset = -115
      }
    }
  }

  getEmojiString(unicode): string {
    return this._emojiService.getUnicodeByEmoji(unicode);
  }
}
