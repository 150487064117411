import { LanguageConstant } from "../../constants/language.constant";
import { LanguageString } from "../../strings/string-helper.service";

export const ArchsdStrings: LanguageString[] = [
  {
    key: LanguageConstant.EN.key,
    strings: {
      GENERAL: {
        TEAMNOTE: 'ArchSD Chat',
      },
      LOGIN: {
        SECOND_FACTOR: {
          LDAP_PW: {
            TITLE: 'Domain Password',
            INPUT_TIP: 'Input your domain password',
          }
        },
        PASSWORD: {
          INPUT_TIP: 'Input your domain password',
        }
      },
      WEBCLIENT: {
        MENU: {
          CORPORATE_MATERIAL: "Shared from ArchSD"
        },
        CHATROOM: {
          MESSAGE_OPTION: {
            SELECT_FROM_DOCUMENT_SHARING: "Select from Shared from ArchSD"
          }
        }
      }
    }
  },
  {
    key: LanguageConstant.ZH_CHT.key,
    strings: {
      GENERAL: {
        TEAMNOTE: 'ArchSD Chat',
      },
      LOGIN: {
        SECOND_FACTOR: {
          LDAP_PW: {
            TITLE: '網域密碼',
            INPUT_TIP: '輸入網域密碼',
          }
        },
        PASSWORD: {
          INPUT_TIP: '輸入網域密碼',
        }
      },
      WEBCLIENT: {
        MENU: {
          CORPORATE_MATERIAL: "部門分享檔案"
        },
        CHATROOM: {
          MESSAGE_OPTION: {
            SELECT_FROM_DOCUMENT_SHARING: "從部門分享檔案中選擇"
          }
        }
      }
    }
  },
  {
    key: LanguageConstant.ZH_CHS.key,
    strings: {
      GENERAL: {
        TEAMNOTE: 'ArchSD Chat',
      },
      WEBCLIENT: {
        MENU: {
          CORPORATE_MATERIAL: "部门分享档案"
        },
        CHATROOM: {
          MESSAGE_OPTION: {
            SELECT_FROM_DOCUMENT_SHARING: "从部门分享档案中选择"
          }
        }
      }
    }
  }
];
