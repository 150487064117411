import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AttachmentService } from './attachment.service';
import { AttachmentComponent } from './attachment.component';
import { AttachmentAudioComponent } from './attachment-audio/attachment-audio.component';
import { AttachmentImageComponent } from './attachment-image/attachment-image.component';
import { AttachmentLocationComponent } from './attachment-location/attachment-location.component';
import { AttachmentPdfComponent } from './attachment-pdf/attachment-pdf.component';
import { AttachmentVideoComponent } from './attachment-video/attachment-video.component';

import { AudioPlayerModule } from '../audio-player/audio-player.module';
import { VideoPlayerModule } from '../video-player/video-player.module';

// Map
// import { NguiMapModule } from '@ngui/map';
import { GoogleMapsModule } from '@angular/google-maps';

import { CommonMaterialModule } from '../common-material/common-material.module';
import { AttachmentImageGridComponent } from './attachment-image-grid/attachment-image-grid.component';
import { TranslateModule } from '@ngx-translate/core';
import { AttachmentVideoGridComponent } from './attachment-video-grid/attachment-video-grid.component';
import { AttachmentPdfListComponent } from './attachment-pdf-list/attachment-pdf-list.component';
import { EmptyPageModule } from '../empty-page/empty-page.module';
import { AttachmentTimelinedComponent } from './attachment-timelined/attachment-timelined.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AudioPlayerModule,
        VideoPlayerModule,
        // NguiMapModule,
        GoogleMapsModule,
        CommonMaterialModule,
        TranslateModule,
        EmptyPageModule,
        PdfJsViewerModule
    ],
    providers: [],
    declarations: [
        AttachmentComponent,
        AttachmentAudioComponent,
        AttachmentImageComponent,
        AttachmentLocationComponent,
        AttachmentPdfComponent,
        AttachmentVideoComponent,
        AttachmentImageGridComponent,
        AttachmentVideoGridComponent,
        AttachmentPdfListComponent,
        AttachmentTimelinedComponent
    ]
})
export class AttachmentModule { }
