import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WatermarkComponent } from './watermark.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [WatermarkComponent],
  exports: [
    WatermarkComponent
  ]
})
export class WatermarkModule { }
