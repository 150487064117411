<div class="tn-empty-page">
  <div class="empty-page-logo"></div>
  <div class="empty-page-text">
    <ng-container *ngIf="!msgKey">
      {{'GENERAL.NO_CONTENT' | translate}}
    </ng-container>
    <ng-container *ngIf="msgKey">
      {{msgKey | translate}}
    </ng-container>
  </div>
</div>