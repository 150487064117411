<div class="news-category-cell clickable" *ngIf="category" (click)="onClick()">
  <div class="avatar-container">
    <div class="avatar-wrapper">
      <div class="avatar" [ngStyle]="{'background-image': avatarImageSrc}"></div>
    </div>
  </div>
  <div class="info-wrapper">
    <div class="name text-color-primary">{{category.displayName}}</div>
  </div>
  <div class="unread-count-wrapper">
    <div class="unread-count unread-bubble" *ngIf="unreadCount > 0">
      {{unreadCount}}
    </div>
  </div>
</div>