import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomFieldsMapped } from '../models/custom-field';
import { ParsedFile } from '../../../../utilities/file-uploader/file-uploader.component';
import { AttachmentService } from '../../../../utilities/attachment/attachment.service';
import { FileUploaderService } from '../../../../utilities/file-uploader/file-uploader.service';
import { ImageHelperService } from '../../../../utilities/image-helper/image-helper.service';
import { FileFactoryService } from '../../../../utilities/file-factory/file-factory.service';

import * as _ from 'lodash';
import { AttachmentTypeConstant } from '../../../../constants/attachment-type.constant';
import { FileManagerService } from '../../../../utilities/file-manager/file-manager.service';
import { ModuleManagerService } from '../../../services/module/module-manager.service';
import { ModuleKeyDefinition } from '../../../../constants/module.constant';
import { TeamNoteCorporateMaterialConstant } from '../../../corporate-material/constants/corporate-material.constant';
import { CorporateMaterialFile } from '../../../corporate-material/models/corporate-material';
import { CorporateMaterialPickerService } from '../../corporate-material-picker/corporate-material-picker.service';

export class FieldAttachmentDetail {
  allow_type: {
    photo: number;
    video: number;
    file: number;
  }
}

export class FieldAttachmentParsedAnswer {
  attachment_id: string;
  src: string;
  description: string;
  name: string;
  file: ParsedFile;
  type?: number;

  constructor(file?: ParsedFile, attachmentId?: string, description?: string, name?: string, type?: number) {
    this.file = file;
    this.attachment_id = attachmentId;
    this.description = description;
    this.name = name;
    this.type = type;
  }
}

export const FieldAttachmentTypeMapping = {
  photo: 1,
  video: 3,
  file: 4,
  audio: 2
}

@Component({
  selector: 'tn-field-attachment',
  templateUrl: './field-attachment.component.html',
  styleUrls: ['./field-attachment.component.scss']
})
export class FieldAttachmentComponent implements OnInit {

  @Input() field: CustomFieldsMapped;
  @Input() isInput: boolean = false;

  @Output() onFieldUpdate: EventEmitter<CustomFieldsMapped> = new EventEmitter<CustomFieldsMapped>(null);

  detail: FieldAttachmentDetail;
  validFileExtensions: string[] = [];
  attachmentTypes = FieldAttachmentTypeMapping;

  answers: FieldAttachmentParsedAnswer[];

  fileInputs: ParsedFile[];
  localAnsObj: FieldAttachmentParsedAnswer[] = [];
  localAnsIndex: number = 0;

  isAllowCorporateMaterial: boolean = false;

  constructor(
    private _attachmentService: AttachmentService,
    private _fileUploaderService: FileUploaderService,
    private _imageHelperService: ImageHelperService,
    private _fileFactoryService: FileFactoryService,
    private _fileManagerService: FileManagerService,
    private _moduleManagerService: ModuleManagerService,
    private _corporateMaterialPickerService: CorporateMaterialPickerService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.isAllowCorporateMaterial = this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.CORPORATE_MATERIAL);

    this.detail = JSON.parse(this.field.definition.detail);
    
    if (this.isInput) {
      this.getAllValidExtensions();
    }

    let attachments = [];
    this.localAnsObj = [];
    if (this.field.answer.value) {
      try {
        attachments = JSON.parse(this.field.answer.value);

        _.each(attachments, (attachment: FieldAttachmentParsedAnswer) => {
          this.addToLocalAns(
            null,
            attachment.attachment_id,
            attachment.description,
            attachment.name
          );
          this._fileFactoryService.getFileByAttachmentId(
            attachment.attachment_id,
            (imageUrl) => {
              this.updateLocalAnsSrcByAttachmentId(attachment.attachment_id, imageUrl);
            },
            (err) => {},
            false,
            false,
            true
          );

        });

        this.sortFileByType();
      } catch (e) {
        this.localAnsObj = [];
        this.answers = [];
      }
      this.onChange();
    }
  }

  getAllValidExtensions(): void {
    this.validFileExtensions = [];
    _.each(this.detail.allow_type, (isEnabled, key) => {
      if (isEnabled) {
        let extensions = this._fileManagerService.getAvailableFileExtensionByType(this.attachmentTypes[key]);
        this.validFileExtensions = _.union(this.validFileExtensions, extensions);
      }
    });
  }

  addToLocalAns(file: ParsedFile, attachmentId: string, description: string, name: string): void {
    this.localAnsObj.push(
      new FieldAttachmentParsedAnswer(
        file, 
        attachmentId, 
        description, 
        name,
        this._fileManagerService.getAttachmentType(attachmentId ? attachmentId : name)
      )
    );
  }

  updateLocalAnsSrcByAttachmentId(attachmentId: string, src: string): void {
    let target = _.find(this.localAnsObj, (ansObj) => {
      return attachmentId == ansObj.attachment_id;
    });
    target.src = src;
  }

  sortFileByType(): void {
    this.localAnsObj = _.orderBy(this.localAnsObj, ["type", "name"]);
  }

  openFileUploader(): void {
    this._fileUploaderService.openFileUploaderModal(
      null,
      (files: ParsedFile[]) => {
        _.each(files, (file) => {
          this.addToLocalAns(file, "", "", file.name);
        });
        this.sortFileByType();
        this.onChange();
      },
      null,
      true,
      this.validFileExtensions,
      ['M4A', 'OPUS', 'OGG']
    );
  }

  openCorporateMatieralSelector(): void {
    let fileAllowTypes = [];
    if (this.detail.allow_type.photo) {
      fileAllowTypes.push(TeamNoteCorporateMaterialConstant.TYPE.IMG);
    }
    if (this.detail.allow_type.video) {
      fileAllowTypes.push(TeamNoteCorporateMaterialConstant.TYPE.VIDEO);
    }
    if (this.detail.allow_type.file) {
      fileAllowTypes.push(TeamNoteCorporateMaterialConstant.TYPE.PDF);
      fileAllowTypes.push(TeamNoteCorporateMaterialConstant.TYPE.DOC);
    }

    this._corporateMaterialPickerService.openCorporateMaterialPicker(
      this.field.definition.name,
      true,
      false,
      false,
      (file: CorporateMaterialFile) => {
        this.addToLocalAns(null, file.attachment_id, "", file.name);

        this._fileFactoryService.getFileByAttachmentId(
          file.attachment_id,
          (imageUrl) => {
            this.updateLocalAnsSrcByAttachmentId(file.attachment_id, imageUrl);
          },
          (err) => {},
          false,
          false,
          true
        );

        this.sortFileByType();
        this.onChange();
      },
      fileAllowTypes
    );
  }

  removeAttachment(ansObj): void {
    this.localAnsObj = _.without(this.localAnsObj, ansObj);
    this.onChange();
  }

  getAnswerValue(): any {
    return this.localAnsObj;
  }

  onChange() {
    this.field.answer.value = this.getAnswerValue();
    this.onFieldUpdate.emit(this.field);
  }

  openAttachments(ans: FieldAttachmentParsedAnswer): void {
    if (this.isInput) {
      return;
    }
    switch (ans.type) {
      case this.attachmentTypes.photo:
        this.openAttachmentPhoto(ans);
        break;
      case this.attachmentTypes.video:
      case this.attachmentTypes.audio:
        this.openAttachmentVideo(ans);
        break;
      case this.attachmentTypes.file:
        this.openAttachmentFile(ans);
        break;
    }
  }

  openAttachmentPhoto(ans: FieldAttachmentParsedAnswer): void {
    let allPhotos = _.filter(this.localAnsObj, (ans) => {
      return this.attachmentTypes.photo == ans.type;
    });
    _.each(allPhotos, (ans) => {
      if (ans.description) {
        this._imageHelperService.setImageCaptionById(ans.attachment_id, ans.description);
      }
    });
    this._attachmentService.prepareAttachmentModalContentByFileId(
      _.map(allPhotos, 'attachment_id'), 
      ans.attachment_id
    );
  }

  openAttachmentVideo(ans: FieldAttachmentParsedAnswer): void {
    this._attachmentService.prepareAttachmentModalContentByFileId(
      [ans.attachment_id]
    );
  }

  openAttachmentFile(ans: FieldAttachmentParsedAnswer): void {
    this._attachmentService.prepareAttachmentModalContentByFileId(
      [ans.attachment_id]
    );
  }


}
