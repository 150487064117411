<section class="form-block">
    <div class="login-body-row input-action-msg">
        {{'WEBCLIENT.WEBDAV.USERNAME_TIP' | translate}}
    </div>
    <div class="login-body-row">
        <input #userNameInput class="form-control" type="text" [(ngModel)]="userName" name="userName"
            placeholder="{{'WEBCLIENT.WEBDAV.USERNAME' | translate}}" size="30" autocomplete="off">
    </div>
    <div class="login-body-row input-action-msg">
        {{'WEBCLIENT.WEBDAV.PASSWORD_TIP' | translate}}
    </div>
    <div class="login-body-row">
        <input #passwordInput class="form-control" type="password" [(ngModel)]="password" name="password"
            placeholder="{{'WEBCLIENT.WEBDAV.PASSWORD' | translate}}" autocomplete="off">
    </div>
    <div class="login-body-row ssssss">
        <button class="btn tn-button-primary fifty-percen" (click)="save()">{{'WEBCLIENT.WEBDAV.SIGN_IN' | translate}}</button>
    </div>
    <div class="login-body-row ssssss">
        <button class="btn tn-button-primary fifty-percen" (click)="close()">{{'WEBCLIENT.WEBDAV.BACK' | translate}}</button>
    </div>
</section>