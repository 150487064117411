import { Injectable } from '@angular/core';
import { AttachmentService } from '../attachment/attachment.service';

import * as _ from 'lodash';
import { LoggerService } from '../logger/logger.service';
import { RouteParamService } from '../route-param/route-param.service';
import { Questionnaire, QuestionnaireCustomRouteParam } from '../../webclient/questionnaire/models/questionnaire';
import { CorporateMaterialEbook, CorporateMaterialFile, CorporateMaterialItem, CorporateMaterialUrl } from '../../webclient/corporate-material/models/corporate-material'
import { Router } from '@angular/router';
import { PageUrlConstant } from '../../constants/page-url.constant';
import { TnDialogService } from '../tn-dialog/tn-dialog.service';
import { CorporateMaterialCustomRouteParam } from '../../webclient/corporate-material/models/corporate-material';
import { SideNavService } from '../tn-side-nav/side-nav.service';
import { ModuleKeyDefinition } from '../../constants/module.constant';
import { QuestionnaireViewComponent } from '../../webclient/questionnaire/questionnaire-view/questionnaire-view.component';
import { QuestionnaireService } from '../../webclient/questionnaire/questionnaire.service';
import { TnNotificationService } from '../tn-notification/tn-notification.service';
import { CorporateMaterialService } from '../../webclient/corporate-material/corporate-material.service';
import { TeamNoteCorporateMaterialConstant } from '../../webclient/corporate-material/constants/corporate-material.constant';
import { MaterialEbookComponent } from '../../webclient/corporate-material/material-ebook/material-ebook.component';
import { CorporateMaterialComponent } from '../../webclient/corporate-material/corporate-material.component';
//import { WebDavMaterialComponent } from '../../webclient/webdav-material/webdav-material.component';

@Injectable()
export class TnLinkHelperService {

  teamNoteLinkPrefix = 'teamnote://';

  // teamnote://webview/image?src={{src}}
  webViewImagePathRegEx = /teamnote:\/\/webview\/image\?src=(.*)/;

  webViewPdfPathRegEx = /teamnote:\/\/webview\/pdf\?src=(.*)/;

  // teamnote://corporatematerial/{{folderId}}/{{materialId}}
  corporateMaterialPathRegEx = /teamnote:\/\/corporatematerial\/(.*)/;

  // teamnote://questionnaire/{{questionnaireId}}
  questionnairePathRegEx = /teamnote:\/\/questionnaire\/(.*)/;

  webdavMaterialPathRegEx = /teamnote:\/\/webdavmaterial\/(.*)/;

  constructor(
    private _attachmentService: AttachmentService,
    private _loggerService: LoggerService,
    private _routeParamService: RouteParamService,
    private _router: Router,
    private _tnDialogService: TnDialogService,
    private _sideNavService: SideNavService,
    private _questionnaireService: QuestionnaireService,
    private _tnNotificationService: TnNotificationService,
    private _corporateMaterialService: CorporateMaterialService,
    // private _webdavMaterialService: WebDavMaterialComponent
  ) { }

  checkIfLinkIsTeamNoteLink(link: string): boolean {
    if (!link || link.length == 0) {
      return false;
    }
    if (link.substr(0, this.teamNoteLinkPrefix.length) == this.teamNoteLinkPrefix) {
      return true;
    }
    return false;
  }

  handleTeamNoteLink(link: string): void {
    if (!this.checkIfLinkIsTeamNoteLink(link)) {
      // safe guard checking
      return;
    }

    this._loggerService.debug("Clicked a TeamNote Link: " + link);

    // Try webview image
    let webViewMatch = this.webViewImagePathRegEx.exec(link);
    if (webViewMatch) {
      this.handleWebViewImageSrc(webViewMatch[1]);
      return;
    }

    // Try webview pdf
    let webViewPdfMatch = this.webViewPdfPathRegEx.exec(link);
    if (webViewPdfMatch) {
      this.handleWebViewPdfSrc(webViewPdfMatch[1]);
      return;
    }
    
    // Try Corporate Material
    let corporateMaterialMatch = this.corporateMaterialPathRegEx.exec(link);
    if (corporateMaterialMatch) {
      let folderAndMaterial = corporateMaterialMatch[1].split("/");
      this.handleCorporateMaterialFolderAndMaterial(
        folderAndMaterial[0],
        folderAndMaterial[1]
      );
      return;
    }

    // Try Questionnaire
    let questionnaireMatch = this.questionnairePathRegEx.exec(link);
    if (questionnaireMatch) {
      this.handleQuestionnaireId(questionnaireMatch[1]);
      return;
    }
    /*
    let webdavMaterialMatch = this.webdavMaterialPathRegEx.exec(link);
    if (webdavMaterialMatch) {
      let folderAndMaterial = webdavMaterialMatch[1].split("/");
      console.error(folderAndMaterial);
      
      this.handleCorporateMaterialFolderAndMaterial(
        folderAndMaterial[0],
        folderAndMaterial[1]
      );
      
      return;
    }
    */
  }

  handleWebViewImageSrc(src: string): void {
    let imageAttachmentId = _.last(src.split("/"));
    this._loggerService.debug("Opening attachment modal from TeamNote link: " + src + "\n" + imageAttachmentId);
    this._attachmentService.prepareAttachmentModalContentByFileId([imageAttachmentId]);
  }

  handleWebViewPdfSrc(src: string): void {
    let pdfAttachmentId = _.last(src.split("/"));
    this._loggerService.debug("Opening attachment pdf modal from TeamNote link: " + src + "\n" + pdfAttachmentId);
    this._attachmentService.prepareAttachmentModalContentByFileId([pdfAttachmentId]);
  }

  handleCorporateMaterialFolderAndMaterial(folderId: string, materialId: string): void {
    this._loggerService.debug("Redirecting to corporate material page from TeamNote link: " + folderId + "_" + materialId ? materialId : "no-material");

    let notExistAction = () => {
      this._tnNotificationService.showCustomErrorByTranslateKey('GENERAL.RESOURCE_NOT_FOUND')
    }

    let onDialogMaterialClick = (material: CorporateMaterialItem) => {
      if (!material) {
        notExistAction()
        return
      }

      switch (material.type) {
        case TeamNoteCorporateMaterialConstant.TYPE.FILE:
          let file = <CorporateMaterialFile> material;
          this._attachmentService.prepareAttachmentModalContentByFileId([file.attachment_id]);
          return;
        case TeamNoteCorporateMaterialConstant.TYPE.URL:
          let url = <CorporateMaterialUrl> material;
          window.open(url.url, "_blank");
          return;
        case TeamNoteCorporateMaterialConstant.TYPE.EBOOK:
          let ebook = <CorporateMaterialEbook> material;
          let dialogRef = this._tnDialogService.openTnDialog(
            MaterialEbookComponent,
            {
              ebookId: ebook.book_id
            },
            {panelClass: 'corporate-material'}
          )
          return;
      }
    }

    this._corporateMaterialService.getCorporateMaterialList(
      (data) => {
        let targetFolder = this._corporateMaterialService.getFolderByFolderId(folderId)

        if (!targetFolder) {
          notExistAction()
          return
        }

        if (!materialId) {
          // open folder modal

          let dialogRef = this._tnDialogService.openTnDialog(
            CorporateMaterialComponent,
            {
              folderId: folderId
            },
            {panelClass: 'corporate-material'}
          )

        } else {
          let allMaterials: CorporateMaterialItem[] = this._corporateMaterialService.getAllMaterialByFolderId(folderId)
          let targetMaterial = _.find(allMaterials, {material_id: materialId})

          onDialogMaterialClick(targetMaterial)
        }
      },
      (err) => {
        notExistAction()
      }
    )

    // // close current modals
    // this._tnDialogService.closeAllDialogs();

    // let param = new CorporateMaterialCustomRouteParam("", "", folderId, materialId);
    // this._routeParamService.setRouteParam(param);
    // this._router.navigateByUrl('/' + PageUrlConstant.WEBCLIENT.BASE + '/' + PageUrlConstant.WEBCLIENT.CORPORATE_MATERIAL);
    // this._sideNavService.updateActiveSideNav(ModuleKeyDefinition.CORPORATE_MATERIAL);
  }

  handleQuestionnaireId(questionnaireId: string): void {
    this._loggerService.debug("Redirecting to questionnaire page from TeamNote link: " + questionnaireId);

    // close current modals
    // this._tnDialogService.closeAllDialogs();

    let notExistAction = () => {
      this._tnNotificationService.showCustomErrorByTranslateKey('GENERAL.RESOURCE_NOT_FOUND')
    }

    this._questionnaireService.getQuestionnaireList(
      (data: Questionnaire[]) => {
        let target = _.find(data, {questionnaire_id: questionnaireId})
        if (!target) {
          notExistAction()
          return
        }

        if (this._questionnaireService.checkIfQuestionnaireIsExpiried(target)) {
          this._tnNotificationService.showCustomErrorByTranslateKey('WEBCLIENT.QUESTIONNAIRE.EXPIRED')
          return
        }
        if (!this._questionnaireService.checkIfUserCanAnswerQuestionnaire(target)) {
          this._tnNotificationService.showCustomErrorByTranslateKey('WEBCLIENT.QUESTIONNAIRE.ALREADY_COMPLETED')
          return
        }

        let dialogRef = this._tnDialogService.openTnDialog(
          QuestionnaireViewComponent,
          {
            questionnaire: target
          }
        )
      },
      (err) => {
        notExistAction()
      },
      true
    )


    // // set route param, and navigate
    // let param = new QuestionnaireCustomRouteParam(questionnaireId);
    // this._routeParamService.setRouteParam(param);
    // this._router.navigateByUrl('/' + PageUrlConstant.WEBCLIENT.BASE + '/' + PageUrlConstant.WEBCLIENT.QUESTIONNAIRE);
    // this._sideNavService.updateActiveSideNav(ModuleKeyDefinition.QUESTIONNAIRE);
  }
}
