import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkflowComponent } from './workflow.component';
import { WorkflowService } from './services/workflow.service';
import { FormListComponent } from './form-list/form-list.component';
import { FormCellComponent } from './form-cell/form-cell.component';
import { FormViewComponent } from './form-view/form-view.component';
import { UtilitiesModule } from '../../utilities/utilities.module';
import { CustomFieldModule } from '../shared/custom-field/custom-field.module';
import { FormSubmitComponent } from './form-submit/form-submit.component';
import { FormOptionComponent } from './form-option/form-option.component';
import { FormDetailComponent } from './form-detail/form-detail.component';
import { FormHelperService } from './helper/form-helper.service';
import { WorkflowCellFormatService } from './helper/workflow-cell-format.service';
import { FormFilterComponent } from './form-filter/form-filter.component';
import { FormExportComponent } from './form-export/form-export.component';

@NgModule({
    imports: [
        CommonModule,
        UtilitiesModule,
        CustomFieldModule
    ],
    declarations: [WorkflowComponent, FormListComponent, FormCellComponent, FormViewComponent, FormSubmitComponent, FormOptionComponent, FormDetailComponent, FormFilterComponent, FormExportComponent],
    providers: [
        WorkflowService,
        FormHelperService,
        WorkflowCellFormatService
    ]
})
export class WorkflowModule { }
