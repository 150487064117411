import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { WebclientLoginService } from '../webclient-login.service';
import { InputValidationService } from '../../utilities/input-validation/input-validation.service';
import { LoginComponent } from '../login.component';
import { TnNotificationService } from '../../utilities/tn-notification/tn-notification.service';
import { CustomLoginResponse } from '../models/custom-login-response';
import { LOGIN_ACTIONS } from '../constants/login-actions.constant';

@Component({
  selector: 'tn-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {
  // Otp params
  otp: string;
  @ViewChild('otpInput', {static: false}) otpInput: ElementRef;

  errorMsg: string;

  constructor(
    private _webclientLoginService: WebclientLoginService,
    private _inputValidationService: InputValidationService,
    private _loginComponent: LoginComponent,
    private _tnNotificationService: TnNotificationService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.focusInputBox();
  }

  focusInputBox(): void {
    this.otpInput.nativeElement.focus();
  }

  submit() {
    let isValid = this._inputValidationService.checkIsNotEmpty(this.otp);
    if (!isValid) {
      this._tnNotificationService.showCustomWarningByTranslateKey('LOGIN.OTP.FAIL_ERROR');
      this.focusInputBox();
      return;
    }
    this.regOtp();
  }

  regOtp() {
    this._webclientLoginService.registrationOTP(
      this.otp,
      resp => {
        let response = this._webclientLoginService.handleRegistrationOtpSuccessResponse(resp);
        this.handleCustomLoginResponse(response);
      },
      err => {
        let response = this._webclientLoginService.handleRegistrationOtpErrorResponse(err);
        this.handleCustomLoginResponse(response);
      }
    );
  }

  webclientRegisterDevice() {
    this._webclientLoginService.webclientRegisterDevice(
      resp => {
        let response = this._webclientLoginService.handleWebclientRegisterDeviceSuccessResponse(
          resp
        );
        this.handleCustomLoginResponse(response);
      },
      err => {
        let response = this._webclientLoginService.handleWebclientRegisterDeviceErrorResponse(
          err
        );
        this.handleCustomLoginResponse(response);
      }
    );
  }

  webclientLogin() {
    this._webclientLoginService.webclientLogin(
      resp => {
        let response = this._webclientLoginService.handleWebclientLoginSuccessResponse(
          resp
        );
        this.handleCustomLoginResponse(response);
      },
      err => {
        let response = this._webclientLoginService.handleWebclientLoginErrorResponse(
          err
        );
        this.handleCustomLoginResponse(response);
      }
    );
  }

  back() {
    this.toNextPage(LOGIN_ACTIONS.TO_GET_STATE_PAGE);
  }

  handleCustomLoginResponse(resp: CustomLoginResponse) {
    if (resp.nextApiCall == LOGIN_ACTIONS.API_WEBCLIENT_REG_DEVICE) {
      this.webclientRegisterDevice();
      return;
    }
    if (resp.nextApiCall == LOGIN_ACTIONS.API_WEBCLIENT_LOGIN) {
      this.webclientLogin();
      return;
    }
    if (!resp.isSuccess) {
      this._tnNotificationService.showCustomErrorByTranslateKey(resp.errorMsg);
    }
    if (resp.nextPageUrl) {
      this.toNextPage(resp.nextPageUrl);
      return;
    }
    this.focusInputBox();
  }

  toNextPage(nextPageUrl: string) {
    this._loginComponent.goToTargetPage(nextPageUrl);
  }

}
