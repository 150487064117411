import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageManagerService } from '../local-storage/local-storage-manager.service';
import { TeamNoteLocalStorageKeyConstants } from '../../constants/local-storage-key.constant';

@Injectable()
export class RouteParamService {

  currentRouteParam: any = null;
  currentRouteParam$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private _localStorageManagerService: LocalStorageManagerService
  ) { }

  setRouteParam(param: any): void {
    this.currentRouteParam = param;

    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE_META_DATA, JSON.stringify(param));

    this.currentRouteParam$.next(this.currentRouteParam);
  }

  getRouteParam(): any {
    return this.currentRouteParam;
  }

}
