import { Component, OnInit } from '@angular/core';
import { Chat } from '../../models/chat';
import { NewsCategoryService } from './news-category.service';
import { RouteParamService } from '../../utilities/route-param/route-param.service';
import { NewsCategoryCustomRouteParam } from './models/news-category';
import { ChatService } from '../services/data/chat/chat.service';
import { NewsCustomRouteParam } from '../../models/news';
import { Router } from '@angular/router';
import { PageUrlConstant } from '../../constants/page-url.constant';

@Component({
  selector: 'tn-news-category',
  templateUrl: './news-category.component.html',
  styleUrls: ['./news-category.component.scss']
})
export class NewsCategoryComponent implements OnInit {

  newsCategories: Chat[] = [];

  scrollItems: any;

  // Param
  paramSub: any;
  customParam: NewsCategoryCustomRouteParam = new NewsCategoryCustomRouteParam(null, "NEWS_CATEGORY");
  defaultTitleKey: string = "NEWS_CATEGORY";
  pageTitleKey: string = null;

  constructor(
    private _newsCategoryService: NewsCategoryService,
    private _routeParamService: RouteParamService,
    private _chatService: ChatService,
    private _router: Router
  ) { 
    this.paramSub = this._routeParamService.currentRouteParam$.subscribe(
      params => {
        this.customParam = params;
        if (this.customParam) {
          this.pageTitleKey = this.customParam.titleTranslateKey;
        } else {
          this.pageTitleKey = this.defaultTitleKey;
        }
        this.getNewsCategories();
      }
    );
  }

  ngOnInit() {
    this._chatService.chats$.subscribe(chats => {
      this.getNewsCategories();
    });
  }

  ngOnDestroy() {
    this.paramSub.unsubscribe();
  }

  getNewsCategories(): void {
    this.newsCategories = this._newsCategoryService.getAllNewsCategories(this.customParam ? this.customParam.prefix : null);
  }

  categoryOnClick(category: Chat): void {
    let newsRouteParam = new NewsCustomRouteParam(
      category.chat_id,
      null,
      null,
      false,
      category.displayName
    );
    this._routeParamService.setRouteParam(newsRouteParam);
    this._router.navigate(['./' + PageUrlConstant.WEBCLIENT.BASE + '/' + PageUrlConstant.WEBCLIENT.NEWS]);
  }

}
