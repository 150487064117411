import { Injectable, Inject } from '@angular/core';

import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { LoggerService } from '../utilities/logger/logger.service';
import { TeamNoteDefaultConfig } from './default.config';
import { TeamNoteClientsConfig } from '../clients/clients.config';
// import { NG_MAP_CONFIG_TOKEN } from '@ngui/map';
import { NgMapConfigLoaderService } from '../utilities/ng-map-config-loader/ng-map-config-loader.service';
import { TeamNoteConfig, TN_CONFIG_MODEL } from './models/config';
import { TeamNoteCategoryConfig } from '../category/category.config';

@Injectable()
export class TeamnoteConfigService {

  config: TeamNoteConfig = {};
  config$: BehaviorSubject<TeamNoteConfig> = new BehaviorSubject<TeamNoteConfig>(null);

  configJsonPath = 'config/TN_CONFIG.json';

  devHost = 'https://tntsgw01.teamnoteapp.com';
  devDomain = 'apptask';

  isBrowserIE = false;
  isBrowserFirefox = false;

  constructor(
    private _http: HttpClient,
    private _ngMapConfigLoaderService: NgMapConfigLoaderService
  ) {
    this.buildDefaultConfig();
    this.updateConfigSubject();
    this.configJsonPath = window.location.pathname + this.configJsonPath;
    this.checkBrowser();
  }

  checkBrowser(): void {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      this.isBrowserIE = true;
      return;
    }

    if (ua.indexOf('Firefox') > 0) {
      this.isBrowserFirefox = true;
      return;
    }
  }

  buildDefaultConfig(): void {
    // this._loggerService.debug("Setting default config...");
    this.config = TeamNoteDefaultConfig;
  }

  updateConfigSubject(): void {
    this.config$.next(this.config);
  }

  getTnConfigFromJSON(callback: Function): void {
    // this._loggerService.debug("Getting TN_CONFIG.json...")
    this._http.get(this.configJsonPath)
      .subscribe(
        (configJson: TN_CONFIG_MODEL) => {
          // this._loggerService.debug("Setting API_HOST, DOMAIN, VERSION_NUMBER from TN_CONFIG.json...");
          this.config.HOST.API_HOST = configJson.API_HOST;
          this.config.HOST.EMBED_HOST_O = configJson.EMBED_HOST_O;
          this.config.HOST.EMBED_HOST_N = configJson.EMBED_HOST_N;
          this.config.HOST.EMBED_HOST_E = configJson.EMBED_HOST_E;
          this.config.HOST.EMBED_HOST_I = configJson.EMBED_HOST_I;
          this.config.HOST.DOMAIN = configJson.DOMAIN;
          this.config.HOST.CONFIG_DOMAIN = configJson.CONFIG_DOMAIN;
          this.config.HOST.FALLBACK_DOMAIN = configJson.FALLBACK_DOMAIN;
          this.config.VERSION.VERSION = configJson.VERSION;
          this.config.VERSION.VERSION_CODE = configJson.VERSION_CODE;
          this.config.HOST.CATEGORY = configJson.CATEGORY;
          this.config.GENERAL.BUILD_LABEL = configJson.BUILD_LABEL;
          this.config.GENERAL.SYSTEM_LABEL = configJson.SYSTEM_LABEL;
          this.config.GENERAL.SITE_LABEL = configJson.SITE_LABEL;
          this.config.GENERAL.NAV_LABEL = configJson.NAV_LABEL;
          this.config.GENERAL.NAV_UAT_LABEL = configJson.NAV_UAT_LABEL;
          this.config.GENERAL.TITLE_LABEL = configJson.TITLE_LABEL;
          if (configJson.WEB_CLIENT_PORT != null) {
            // Only apply port if not null
            this.config.HOST.WEB_CLIENT_PORT = configJson.WEB_CLIENT_PORT;
          }
          this.applyCompanyConfigs();

          callback();
        },
        (err) => {
          this.applyCompanyConfigs();
          callback();
        }
      );
  }

  detectComapnyDomain(): string {
    // Spcial handling for dev convenience
    if (!this.config.GENERAL.IS_CUSTOM_DOAMIN && window.location.hostname === 'localhost') {
      return this.devDomain;
    }

    // Just set in TN_CONFIG.json, no more hardcode
    // Hardcode for internal staging
    // if (window.location.host == "tntsgw01.teamnoteapp.com") {
    //   return "apptask";
    // }

    // General detection
    // webclient URL structure
    // https://domain.teamnoteapp.com/domain_web-client

    // If it is /xxxxx_web-client, always use the xxxxx as first priority
    // console.warn(window.location);
    const webclientLink = window.location.pathname.split('/')[1];
    const lastUnderScore = webclientLink.lastIndexOf('_');
    if (lastUnderScore !== -1) {
      return webclientLink.substr(0, lastUnderScore);
    }

    // No underscore in link:
    // If it has fallback domain, use it
    if (this.config.HOST.FALLBACK_DOMAIN) {
      return this.config.HOST.FALLBACK_DOMAIN;
    }

    // If is custom domain input, return null so it won't detect domain in URL
    if (this.config.GENERAL.IS_CUSTOM_DOAMIN) {
      return null;
    }

    // else get the first wording in host URL
    return window.location.host.split('.')[0];
  }

  applyCompanyConfigs(): void {
    let domain = this.config.HOST.DOMAIN;

    if (this.config.HOST.API_HOST === '') {
      // Special handling for dev convenience
      if (window.location.hostname === 'localhost') {
        this.config.HOST.API_HOST = this.devHost;
      }
    }

    this.config = _.merge(this.config, TeamNoteCategoryConfig[this.config.HOST.CATEGORY]);

    // this._loggerService.debug("Applying company setting of domain: " + domain);
    this.config = _.merge(this.config, TeamNoteClientsConfig[domain]);

    if (this.config.HOST.CONFIG_DOMAIN) {
      this.config = _.merge(this.config, TeamNoteClientsConfig[this.config.HOST.CONFIG_DOMAIN]);
    }

    if (domain == null) {
      domain = this.detectComapnyDomain();
    }

    this.config.HOST.DOMAIN = domain;

    // Apply Ng Map config
    this._ngMapConfigLoaderService.changeUrl(this.config.MAP.API_KEY);

    this.updateConfigSubject();
    this.applyCompanyManifest();
  }

  applyCompanyManifest(): void {
    if (!this.config.MANIFEST.PATH) {
      return;
    }
    const link = document.createElement('link');
    link.href = this.config.MANIFEST.PATH;
    link.rel = 'manifest';
    document.getElementsByTagName('head')[0].appendChild(link);
  }

}
