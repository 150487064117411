import { Injectable } from '@angular/core';
import { TeamNoteApiConstant } from '../../../constants/api.constant';
import { TeamnoteApiService } from '../../../api/teamnote-api.service';
import { TnNotificationService } from '../../../utilities/tn-notification/tn-notification.service';
import { WorkflowTeam, WorkflowType, WorkflowForm, WorkflowCustomRouteParam, WorkflowOptions, WorkflowTypeStage, WorkflowTeamRoleGroup, WorkflowFormFilterParam } from '../model/workflow';
import { ModuleKeyDefinition } from '../../../constants/module.constant';

import * as _ from 'lodash';
import { SideNavService, SideNavItem } from '../../../utilities/tn-side-nav/side-nav.service';
import { PageUrlConstant } from '../../../constants/page-url.constant';
import { UserGroupService } from '../../services/data/user-group/user-group.service';
import { WorkflowListSampleData } from '../data/workflow.data';
import { CustomFieldsDefinition } from '../../shared/custom-field/models/custom-field';
import { AccountService } from '../../../account/account.service';
import { FilterHelperService } from '../../shared/custom-field/helper/filter-helper.service';
import { FieldHelperService } from '../../shared/custom-field/helper/field-helper.service';
import { TimestampService } from '../../../utilities/timestamp/timestamp.service';
import { UserContact } from '../../../models/user-contact';
import { UserContactService } from '../../services/data/user-contact/user-contact.service';
import { ContactPickerService } from '../../contact-picker/contact-picker.service';
import { WorkflowConstant } from '../constants/workflow.constants';
import { CONTACT_PICKER_ACTION } from '../../contact-picker/contact-picker.component';
import { UserGroup } from '../../../models/user-group';

@Injectable()
export class WorkflowService {

  teams: WorkflowTeam[] = [];

  types: WorkflowType[] = [];
  visibleInAppMenuTypes: WorkflowType[] = [];
  baseSideNavKey = ModuleKeyDefinition.WORKFLOW;
  currentNavKeys: string[] = [];

  forms: WorkflowForm[] = [];

  formFilterParam: WorkflowFormFilterParam = new WorkflowFormFilterParam();

  formSearchableIndex: {[formId: string]: string} = {};

  constructor(
    private _teamnoteApiService: TeamnoteApiService,
    private _tnNotificationService: TnNotificationService,
    private _sideNavService: SideNavService,
    private _userGroupService: UserGroupService,
    private _accountService: AccountService,
    private _filterHelperService: FilterHelperService,
    private _fieldHelperService: FieldHelperService,
    private _timestampService: TimestampService,
    private _userContactService: UserContactService,
    private _contactPickerService: ContactPickerService
  ) { }

  handleInitialWorkflowContent(): void {
    this.getWorkflowOptions(
      (resp) => {
        this.handleInitialPreviousSession();
        this.getWorkflowFormList(
          () => {

          }
        );
      }
    );
  }

  handleInitialPreviousSession(): void {
    // TODO:
  }

  // Handle Typed Side Nav
  handleTypeSideNavChanges(): void {
    // remomve all current extra side navs first
    _.each(this.currentNavKeys, (key) => {
      this._sideNavService.removeModuleByModuleKey(key);
    });

    this.currentNavKeys = [];

    this.visibleInAppMenuTypes = _.sortBy(_.filter(this.types, {visible_in_app_menu: 1}), "name");
    if (this.visibleInAppMenuTypes.length > 0) {
      _.each(this.visibleInAppMenuTypes, (type, index) => {
        let key = [this.baseSideNavKey, type.type_id].join("_");
        let fractionIndex = (index + 1) / (this.visibleInAppMenuTypes.length * 2);
        let newSideNavItem = new SideNavItem(
          key,
          PageUrlConstant.WEBCLIENT.WORKFLOW,
          "edit",
          null,
          type.name,
          null,
          new WorkflowCustomRouteParam(type.type_id),
          true
        );
        this._sideNavService.appendNewSideNavFromBaseKey(
          newSideNavItem,
          this.baseSideNavKey,
          fractionIndex
        );
        this.currentNavKeys.push(key);
      });
    }

    if (this.visibleInAppMenuTypes.length == this.types.length) {
      // remove base store report tab
      this._sideNavService.removeModuleByModuleKey(this.baseSideNavKey);
    } else {
      // show base store report tab
      this._sideNavService.showModuleByModuleKey(this.baseSideNavKey);
    }
  }

  // Unread counts
  updateWorkflowUnreadCount(): void {
    _.each(this.visibleInAppMenuTypes, (type) => {
      let key = [this.baseSideNavKey, type.type_id].join("_");
      let formUnderType = _.filter(this.forms, {type_id: type.type_id});
      this._sideNavService.updateSideNavCountByKey(
        key,
        this.getActiveFormCountByForms(formUnderType)
      );
    });

    let otherForms = _.filter(this.forms, (form: WorkflowForm) => {
      let type = this.getTypeByTypeId(form.type_id);
      if (!type) {
        return false;
      }
      if (type.visible_in_app_menu) {
        return false;
      }
      return true;
    });
    this._sideNavService.updateSideNavCountByKey(
      this.baseSideNavKey,
      this.getActiveFormCountByForms(otherForms)
    );
  }

  getActiveFormCountByForms(forms: WorkflowForm[]): number {
    let count = 0;
    _.each(forms, (form) => {
      if (this.checkIfUserIsPicOfFormActiveStageByFormIdAndStageId(form.form_id, form.active_stage_id)) {
        count++;
      } else if (this.checkIfUserIsPendingPicOfFormByFormId(form.form_id)) {
        count++;
      }
    });
    return count;
  }

  /**
   * API
   */

  getWorkflowOptions(success?: Function): void {
    let url = TeamNoteApiConstant.WORKFLOW.OPTION;
    let params = {};
    this._teamnoteApiService.callApi(
      url,
      params,
      (resp: WorkflowOptions) => {
        this.teams = _.orderBy(resp.teams, 'name');
        this.types = _.orderBy(resp.form_types, 'name');
        this.tryToGetAllRoleGroupsAndMembers(this.teams);
        this.handleTypeSideNavChanges();
        if (success) {
          success();
        }
      },
      (err) => {
        this._tnNotificationService.showSystemError();
      }
    );
  }

  getWorkflowFormList(success: Function, typeId?: string): void {
    let url = TeamNoteApiConstant.WORKFLOW.LIST;
    let params = {
      type_id: typeId
    };
    this._teamnoteApiService.callApi(
      url,
      params,
      (resp: WorkflowForm[]) => {
        this.forms = resp;
        this.forms = this.sortWorkflowForm(this.forms);
        this.buildFormSearchableIndex(this.forms);
        this.updateWorkflowUnreadCount();
        success(this.forms);
      },
      (err) => {
        this._tnNotificationService.showSystemError();
      }
    );
  }

  getWorkflowForm(formId: string, success: Function): void {
    let url = TeamNoteApiConstant.WORKFLOW.GET;
    let params = {
      form_id: formId
    };
    this._teamnoteApiService.callApi(
      url,
      params,
      (resp) => {
        success(resp);
      },
      (err) => {
        this._tnNotificationService.showSystemError();
      }
    );
  }

  setWorkflowForm(teamId: string, typeId: string, customFields: any, activeStageId: string, picUserIds: string[], success: Function, formId?: string, picUserGroupId?: string[]): void {
    let url = TeamNoteApiConstant.WORKFLOW.SET_FORM;
    let params = {
      team_id: teamId,
      type_id: typeId,
      custom_fields: customFields,
      form_id: formId,
      active_stage_id: activeStageId,
      pic_user_ids: picUserIds,
      pic_target_groups: picUserGroupId
    };
    this._teamnoteApiService.callApi(
      url,
      params,
      (resp) => {
        success(resp);
      },
      (err) => {
        this._tnNotificationService.showSystemError();
      }
    );
  }

  changeWorkflowFormStage(formId: string, activeStageId: string, picUserIds: string[], success: Function): void {
    let url = TeamNoteApiConstant.WORKFLOW.CHANGE_STAGE;
    let params = {
      form_id: formId,
      active_stage_id: activeStageId,
      pic_user_ids: picUserIds
    };
    this._teamnoteApiService.callApi(
      url,
      params,
      (resp) => {
        success(resp);
      },
      (err) => {
        this._tnNotificationService.showSystemError();
      }
    );
  }

  setObservers(formId: string, observerIds: string[], success: Function): void {
    let url = TeamNoteApiConstant.WORKFLOW.SET_OBSERVERS;
    let params = {
      form_id: formId,
      observer_ids: observerIds
    };
    this._teamnoteApiService.callApi(
      url,
      params,
      (resp) => {
        this._tnNotificationService.showCustomInfoByTranslateKey("WEBCLIENT.WORKFLOW.DETAIL.OBSERVER_SUCCESS_MSG");
        success(resp);
      },
      (err) => {
        this._tnNotificationService.showSystemError();
      }
    );
  }

  // Exports
  exportForm(param: any, success: Function): void {
    let url = TeamNoteApiConstant.WORKFLOW.EXPORT;
    this._teamnoteApiService.callApi(
      url,
      param,
      (resp) => {
        success(resp);
      },
      (err) => {
        this._tnNotificationService.showSystemError();
      }
    );
  }

  // Team
  getTeamByTeamId(teamId: string): WorkflowTeam {
    return _.find(this.teams, (t) => {
      return t.team_id == teamId;
    });
  }

  getTeamsByTeamTemplateId(templateId: string): WorkflowTeam[] {
    return _.filter(this.teams, (t: WorkflowTeam) => {
      return t.template_id == templateId;
    });
  }

  tryToGetAllRoleGroupsAndMembers(teams: WorkflowTeam[]): void {
    let allUserGroupIds = [];
    let allMemberUserIds = [];

    _.each(teams, (team) => {
      allUserGroupIds.push(team.user_group_id);
      _.each(team.role_groups, (roleGroup) => {
        allUserGroupIds.push(roleGroup.user_group_id);
      });
    });
    this._userGroupService.getMissingUserGroupContact(
      allUserGroupIds,
      () => {
        // get their child groups too
        let allChildGroupIds = [];
        _.each(allUserGroupIds, (ugid) => {
          let userGroup = this._userGroupService.getUserGroupByUserGroupId(ugid);
          if (userGroup) {
            allMemberUserIds = _.union(allMemberUserIds, userGroup.members);
            allChildGroupIds = _.union(allChildGroupIds, userGroup.children);
          }
        });
        this._userGroupService.getMissingUserGroupContact(
          allChildGroupIds,
          () => {
            // get all role groups's members
            _.each(allChildGroupIds, (cugid) => {
              let childUserGroup = this._userGroupService.getUserGroupByUserGroupId(cugid);
              if (childUserGroup) {
                allMemberUserIds = _.union(allMemberUserIds, childUserGroup.members);
              }
            });

            this._userContactService.getMissingContacts(allMemberUserIds);
          }
        );
      }
    );
  }

  getTeamRoleGroupUserGroupIdByTeamIdAndRoleId(teamId: string, roleId: string): string {
    let team = this.getTeamByTeamId(teamId);
    let roleGroup = _.find(team.role_groups, (rg: WorkflowTeamRoleGroup) => {
      return rg.role_id == roleId;
    });
    return roleGroup ? roleGroup.user_group_id : null;
  }

  getAllTeamMemberUserIdsByTeamId(teamId: string): string[] {
    let team = this.getTeamByTeamId(teamId);
    let teamUserGroup = this._userGroupService.getUserGroupByUserGroupId(team.user_group_id);
    if (teamUserGroup) {
      return teamUserGroup.members;
    }
    return [];
  }

  // Type
  getTypeByTypeId(typeId: string): WorkflowType {
    return _.find(this.types, (t) => {
      return t.type_id == typeId;
    });
  }

  checkIfTypeIsVisibleInAppMenuByTypeId(typeId: string): boolean {
    let type = this.getTypeByTypeId(typeId);
    if (type && type.visible_in_app_menu) {
      return true;
    }
    return false;
  }

  getAvailableTypeByTeamTemplateId(templateId: string): WorkflowType[] {
    return _.filter(this.types, (t) => {
      return t.template_id == templateId;
    });
  }

  getAllFieldDefinitionOfTypeByTypeId(typeId: string): CustomFieldsDefinition[] {
    let defs = [];
    let type = this.getTypeByTypeId(typeId);
    if (!type) {
      return defs;
    }
    _.each(type.stages, (stage) => {
      defs = _.union(defs, stage.custom_fields);
    });
    return defs;
  }

  /// Type stage
  getFirstStageOfType(type: WorkflowType): WorkflowTypeStage {
    let stages = _.sortBy(type.stages, "order");
    return _.first(stages);
  }

  getPrevStageByCurrentStageIdAndTypeId(stageId: string, typeId: string): WorkflowTypeStage {
    let type = this.getTypeByTypeId(typeId);
    let stages = _.sortBy(type.stages, "order");
    let currentStageIndex = _.findIndex(stages, (stage) => {
      return stage.stage_id == stageId;
    });
    if (currentStageIndex != -1 && currentStageIndex > 0) {
      return stages[currentStageIndex - 1];
    }
    return null;
  }

  getNextStageByCurrentStageIdAndTypeId(stageId: string, typeId: string): WorkflowTypeStage {
    let type = this.getTypeByTypeId(typeId);
    let stages = _.sortBy(type.stages, "order");
    let currentStageIndex = _.findIndex(stages, (stage) => {
      return stage.stage_id == stageId;
    });
    if (currentStageIndex != -1 && currentStageIndex < stages.length) {
      return stages[currentStageIndex + 1];
    }
    return null;
  }

  checkIfStageIsFirstStageOfTypeByStageIdAndTypeId(stageId: string, typeId: string): boolean {
    let type = this.getTypeByTypeId(typeId);
    let stages = _.sortBy(type.stages, "order");
    if (_.first(stages).stage_id == stageId) {
      return true;
    }
    return false;
  }

  checkIfStageIsLastStageOfTypeByStageIdAndTypeId(stageId: string, typeId: string): boolean {
    let type = this.getTypeByTypeId(typeId);
    let stages = _.sortBy(type.stages, "order");
    if (_.last(stages).stage_id == stageId) {
      return true;
    }
    return false;
  }

  getStageOfTypeByTypeIdAndStageId(typeId: string, stageId: string): WorkflowTypeStage {
    let type = this.getTypeByTypeId(typeId);
    return _.find(type.stages, (stage) => {
      return stage.stage_id == stageId;
    });
  }

  checkIfUserIsPicOfStageByTypeIdAndStageIdAndTeamId(typeId: string, stageId: string, teamId: string): boolean {
    let stage = this.getStageOfTypeByTypeIdAndStageId(typeId, stageId);
    let allRoleIds = [];
    _.each(stage.stage_pic_roles, (role) => {
      allRoleIds = _.union(allRoleIds, [role.role_id]);
    });
    
    for (let i = 0; i < allRoleIds.length; i++) {
      let roleId = allRoleIds[i];
      let userGroupId = this.getTeamRoleGroupUserGroupIdByTeamIdAndRoleId(teamId, roleId);
      let isUnderUserGroup = this._userGroupService.checkIfUserIsUnderUserGroupRecursive(
        this._accountService.userId,
        userGroupId
      );
      if (isUnderUserGroup) {
        return true;
      }
    }
    
    return false;
  }

  getAllPicOfStageByTypeIdAndStageIdAndTeamId(typeId: string, stageId: string, teamId: string): UserContact[] {
    let stage = this.getStageOfTypeByTypeIdAndStageId(typeId, stageId);
    let allRoleIds = [];
    _.each(stage.stage_pic_roles, (role) => {
      allRoleIds = _.union(allRoleIds, [role.role_id]);
    });

    let memberUserIds = [];
    
    _.each(allRoleIds, (roleId) => {
      let userGroupId = this.getTeamRoleGroupUserGroupIdByTeamIdAndRoleId(teamId, roleId);
      let userGroup = this._userGroupService.getUserGroupByUserGroupId(userGroupId);
      if (userGroup) {
        memberUserIds = _.union(memberUserIds, userGroup.members);

        // Get user group child groups member too
        _.each(userGroup.children, (childUserGroupId) => {
          let childGroup = this._userGroupService.getUserGroupByUserGroupId(childUserGroupId);
          if (childGroup) {
            memberUserIds = _.union(memberUserIds, childGroup.members);
          }
        });
      }
    });

    return _.map(memberUserIds, (userId) => {
      return this._userContactService.getUserContactByUserId(userId);
    });
  }

  getAllPicUserGroupOfStageByTypeIdAndStageIdAndTeamId(typeId: string, stageId: string, teamId: string): UserGroup[] {
    let stage = this.getStageOfTypeByTypeIdAndStageId(typeId, stageId);
    let allRoleIds = [];
    _.each(stage.stage_pic_roles, (role) => {
      allRoleIds = _.union(allRoleIds, [role.role_id]);
    });

    let picUserGroupIds = [];
    _.each(allRoleIds, (roleId) => {
      let userGroupId = this.getTeamRoleGroupUserGroupIdByTeamIdAndRoleId(teamId, roleId);
      let userGroup = this._userGroupService.getUserGroupByUserGroupId(userGroupId);
      if (userGroup) {
        if (userGroup.members.length > 0) {
          picUserGroupIds = _.union(picUserGroupIds, [userGroup.user_group_id]);
        }
        _.each(userGroup.children, (cugid) => {
          let childGroup = this._userGroupService.getUserGroupByUserGroupId(cugid);
          if (childGroup) {
            if (childGroup.members.length > 0) {
              picUserGroupIds = _.union(picUserGroupIds, [childGroup.user_group_id]);
            }
          }
        })
      }
    });

    return _.map(picUserGroupIds, (ugid) => {
      return this._userGroupService.getUserGroupByUserGroupId(ugid);
    });
  }

  selectNewPicByTargetStageAndTeamId(targetStage: WorkflowTypeStage, teamId: string, callbackWithContactUserIdAndUserGroupId?: Function): void {
    switch (targetStage.pic_method) {
      case WorkflowConstant.PIC_METHOD.CREATOR:
      case WorkflowConstant.PIC_METHOD.ASSIGNMENT:
        this.selectPicUserByTargetStageAndTeam(targetStage, teamId, callbackWithContactUserIdAndUserGroupId);
        return;
      case WorkflowConstant.PIC_METHOD.SELF_NOMINATION:
        this.selectPicUserGroupByTargetStageAndTeam(targetStage, teamId, callbackWithContactUserIdAndUserGroupId);
        return;
      case WorkflowConstant.PIC_METHOD.SERVER_NOMINATION:
      case WorkflowConstant.PIC_METHOD.RANDOM:
      case WorkflowConstant.PIC_METHOD.NONE:
        callbackWithContactUserIdAndUserGroupId(null, null);
        return;
    }
  }

  selectPicUserByTargetStageAndTeam(targetStage: WorkflowTypeStage, teamId: string, callbackWithContactUserIdAndUserGroupId: Function): void {
    let availableUsers = this.getAllPicOfStageByTypeIdAndStageIdAndTeamId(
      targetStage.type_id,
      targetStage.stage_id,
      teamId
    );
    this._contactPickerService.openContactPicker(
      "WEBCLIENT.WORKFLOW.OPTIONS.ASSIGN",
      false,
      false,
      false,
      (contact: UserContact) => {
        callbackWithContactUserIdAndUserGroupId(contact.user_id, null);
      },
      false,
      CONTACT_PICKER_ACTION.WORKFLOW,
      availableUsers
    );
  }

  selectPicUserGroupByTargetStageAndTeam(targetStage: WorkflowTypeStage, teamId: string, callbackWithContactUserIdAndUserGroupId: Function): void {
    let allPicUserGroups = this.getAllPicUserGroupOfStageByTypeIdAndStageIdAndTeamId(
      targetStage.type_id, 
      targetStage.stage_id, 
      teamId
    );

    this._contactPickerService.openContactPicker(
      "WEBCLIENT.WORKFLOW.OPTIONS.ASSIGN",
      false,
      false,
      false,
      (userGroups: UserGroup[]) => {
        callbackWithContactUserIdAndUserGroupId(null, _.map(userGroups, "user_group_id"));
      },
      true,
      CONTACT_PICKER_ACTION.WORKFLOW,
      allPicUserGroups,
      true
    );
  }

  selectNewObserversByTeamId(teamId: string, originalObserverId: string[], callbackWithContactUserIds: Function): void {
    let teamMembers = this.getAllTeamMemberUserIdsByTeamId(teamId);
    let availableUserIds = _.difference(teamMembers, originalObserverId);

    let availableUsers = _.map(availableUserIds, (userId) => {
      return this._userContactService.getUserContactByUserId(userId);
    });

    this._contactPickerService.openContactPicker(
      "WEBCLIENT.WORKFLOW.OPTIONS.ADD_OBSERVER",
      false,
      false,
      false,
      (contacts: UserContact[]) => {
        callbackWithContactUserIds(_.map(contacts, "user_id"));
      },
      true,
      CONTACT_PICKER_ACTION.WORKFLOW,
      availableUsers
    );
  }

  // Form
  getFormByFormId(formId: string): WorkflowForm {
    return _.find(this.forms, (form) => {
      return formId == form.form_id;
    });
  }
  sortWorkflowForm(forms: WorkflowForm[]): WorkflowForm[] {
    _.each(forms, (f) => {
      if (this.checkIfUserIsPicOfFormActiveStageByFormIdAndStageId(f.form_id, f.active_stage_id)) {
        f.isFormActive = true;
        f.order = 1;
      } else {
        if (this.checkIfUserIsPendingPicOfFormByFormId(f.form_id)) {
          f.isFormPending = true;
          f.order = 2;
        } else {
          f.isFormActive = false;
          f.order = 0;
        }
      }
    });
    return _.orderBy(forms, ['order', 'create_date'], ['desc', 'desc']);
  }

  buildFormSearchableIndex(forms: WorkflowForm[]): void {
    _.each(forms, (form) => {
      let allFieldDefinition = this.getAllFieldDefinitionOfTypeByTypeId(form.type_id);
      let mappedFields = this._fieldHelperService.prepareMappedField(allFieldDefinition, form.custom_fields);
      let fieldKeywords = _.map(mappedFields, (mappedField) => {
        return this._filterHelperService.getSearchKeywordOfReportByMappedField(mappedField).toLowerCase();
      });
      this.formSearchableIndex[form.form_id] = fieldKeywords.join(" ");
    });
  }

  checkIfUserIsPicOfFormByFormId(formId: string): boolean {
    let form = this.getFormByFormId(formId);
    let formStagePics = form.form_stage_pic;
    let userPicStage = _.find(formStagePics, (stagePic) => {
      return this._accountService.userId == stagePic.user_id;
    });
    if (userPicStage) {
      return true;
    }
    return false;
  }

  checkIfUserIsPicOfFormActiveStageByFormIdAndStageId(formId: string, stageId: string): boolean {
    let form = this.getFormByFormId(formId);
    let formStagePics = form.form_stage_pic;
    let targetStage = _.find(formStagePics, (stagePic) => {
      return stageId == stagePic.stage_id;
    });
    if (targetStage) {
      if (targetStage.user_id == this._accountService.userId) {
        return true;
      }
    }
    return false;
  }

  checkIfUserIsPendingPicOfFormByFormId(formId: string): boolean {
    let form = this.getFormByFormId(formId);
    
    // check if there is a pic already, if yes, form is no longer pending.
    let targetStage = _.find(form.form_stage_pic, (stagePic) => {
      return form.active_stage_id == stagePic.stage_id;
    });
    if (targetStage) {
      return false;
    }

    for (let i = 0; i < form.pic_target_groups.length; i++) {
      let ugid = form.pic_target_groups[i];
      if (this._userGroupService.checkIfUserIsUnderUserGroupRecursive(
        this._accountService.userId,
        ugid,
        true
      )) {
        return true;
      }
    }

    return false;
  }

  getPicUserOfStageByFormIdAndStageId(formId: string, stageId: string): UserContact {
    let form = this.getFormByFormId(formId);
    let formStagePics = form.form_stage_pic;
    let targetStage = _.find(formStagePics, (stagePic) => {
      return stageId == stagePic.stage_id;
    });
    if (targetStage) {
      let user = this._userContactService.getUserContactByUserId(targetStage.user_id);
      if (user) {
        return user;
      }
    }
    return null;
  }

  // Filter
  clearWorkflowFilterParam(): void {
    this.formFilterParam = new WorkflowFormFilterParam();
  }
  cacheWorkflowFilterParam(param: WorkflowFormFilterParam): void {
    this.formFilterParam = param;
  }
  getCachedWorkflowFilterParam(): WorkflowFormFilterParam {
    return this.formFilterParam;
  }
  applyFilterOnForms(customParam: WorkflowCustomRouteParam): WorkflowForm[] {
    let filter = this.formFilterParam;
    let filtered = this.forms;

    if (filter.createDateFrom) {
      filtered = _.filter(filtered, (form) => {
        return this._timestampService.checkIfTimeCorrectOrder(filter.createDateFrom, form.create_date);
      });
    }

    if (filter.createDateTo) {
      filtered = _.filter(filtered, (form) => {
        return this._timestampService.checkIfTimeCorrectOrder(form.create_date, filter.createDateTo);
      });
    }

    if (filter.teamId) {
      filtered = _.filter(filtered, (form) => {
        return filter.teamId == form.team_id;
      });
    }

    if (filter.typeId) {
      filtered = _.filter(filtered, (form) => {
        return filter.typeId == form.type_id;
      });
    }

    if (!customParam) {
      filtered = _.filter(filtered, (form) => {
        let type = this.getTypeByTypeId(form.type_id);
        return type.visible_in_app_menu == 0;
      });
    }

    if (filter.stageId) {
      filtered = _.filter(filtered, (form) => {
        return filter.stageId == form.active_stage_id;
      });
    }

    if (filter.content && filter.content.length > 0) {
      filtered = _.filter(filtered, (form) => {
        return this.formSearchableIndex[form.form_id].indexOf(filter.content.toLowerCase()) != -1;
      });
    }

    return filtered;
  }
}
