import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AttachmentService } from '../../../../utilities/attachment/attachment.service';
import { MessageLocationBody } from '../../../../models/message-location-body';
import { LocationSelectorService } from '../../../../utilities/location-selector/location-selector.service';
import { CustomFieldsMapped } from '../models/custom-field';

export class FieldLocationAnswer {
  lat: number;
  lng: number;
  address: string;
}

@Component({
  selector: 'tn-field-geolocation-input',
  templateUrl: './field-geolocation-input.component.html',
  styleUrls: ['./field-geolocation-input.component.scss'],
  host: { 'component-root': 'shared' }
})
export class FieldGeolocationInputComponent implements OnInit {

  @Input() field: CustomFieldsMapped;
  @Input() isInput: boolean = false;

  @Output() onFieldUpdate: EventEmitter<CustomFieldsMapped> = new EventEmitter<CustomFieldsMapped>(null);

  location: FieldLocationAnswer;

  constructor(
    private _attachmentService: AttachmentService,
    private _locationSelectorService: LocationSelectorService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.field.answer.value) {
      this.location = JSON.parse(this.field.answer.value);
    } else {
      this.location = new FieldLocationAnswer;
    }
  }

  openLocation() {
    let parsedLocation = new MessageLocationBody;
    parsedLocation.address = this.location.address;
    parsedLocation.latitude = this.location.lat;
    parsedLocation.longitude = this.location.lng;
    this._attachmentService.openAttachmentModal(false, parsedLocation);
  }

  openLocationSelector() {
    this._locationSelectorService.openLocationSelectorModal(
      (location: MessageLocationBody) => {
        this.location = {
          address: location.address,
          lat: location.latitude,
          lng: location.longitude
        };
        this.onChange();
      }
    );
  }

  getAnswerValue(): string {
    return JSON.stringify(this.location);
  }

  onChange() {
    this.field.answer.value = this.getAnswerValue();
    this.onFieldUpdate.emit(this.field);
  }

}
