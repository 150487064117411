import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { UtilitiesModule } from '../../utilities/utilities.module';

// Components
import { ChatComponent } from './chat.component';
import { ChatListComponent } from './chat-list/chat-list.component';
import { ChatCellComponent } from './chat-cell/chat-cell.component';

import { ChatGroupNewComponent } from './chat-group-new/chat-group-new.component';

// Sub Modules
import { ChatRoomModule } from './chat-room/chat-room.module';

import { ContactModule } from '../contact/contact.module';

// UI
import { ChatGroupSettingComponent } from './chat-group-setting/chat-group-setting.component';
import { ChatMessageCellComponent } from './chat-message-cell/chat-message-cell.component';
import { BroadcastModule } from '../broadcast/broadcast.module';
import { MuteGroupComponent } from './chat-group-setting/mute-group/mute-group.component';
import { ChatSearchComponent } from './chat-search/chat-search.component';
import { StarredMessagesModule } from '../starred-messages/starred-messages.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ChatRoomModule,
        UtilitiesModule,
        ContactModule,
        BroadcastModule
    ],
    declarations: [
        ChatComponent,
        ChatListComponent,
        ChatCellComponent,
        ChatGroupNewComponent,
        ChatGroupSettingComponent,
        ChatMessageCellComponent,
        MuteGroupComponent,
        ChatSearchComponent
    ],
    exports: [
        ChatListComponent,
        ChatCellComponent
    ]
})
export class ChatModule { }
