<div class="multiple-choice-container">
  <div class="options" *ngFor="let o of question.mcDetail.options">
    <label>
      <input type="radio" [(ngModel)]="question.value" [value]="o.option" (ngModelChange)="onChange($event)" [name]="question.question_id">
      {{o.option}}
    </label>
    <br>
    <input class="form-control" 
      type="text" 
      [(ngModel)]="o.textAns" 
      (ngModelChange)="onChange($event)"
      *ngIf="o.free_text !== EXTRA_FREE_TEXT.NONE">
  </div>
</div>