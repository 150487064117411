import { Injectable } from '@angular/core';
import { FtsService } from '../fts.service';
import { Chat } from '../../../models/chat';

@Injectable()
export class FtsChatService {

  chatSearchFields: string[] = [
    "displayName",
    "name"
  ];

  constructor(
    private _ftsService: FtsService
  ) { }

  searchChat(keyword: string, chats: Chat[]): Chat[] {
    return this._ftsService.tnFtsFiltering(chats, keyword, this.chatSearchFields);
  }

}
