import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Chat } from '../../../../models/chat';
import { BsDaterangepickerConfig, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { TnNotificationService } from '../../../../utilities/tn-notification/tn-notification.service';
import { TimestampService } from '../../../../utilities/timestamp/timestamp.service';
import { ChatRoomService } from '../chat-room.service';
import { CronjobTrackerService } from '../../../../utilities/cronjob-tracker/cronjob-tracker.service';
import { CronJobTaskInitialResponse, CronJobType, CronjobExportChatMessage } from '../../../../utilities/cronjob-tracker/models/cronjob';
import { ModuleManagerService } from '../../../services/module/module-manager.service';
import { ModuleKeyDefinition } from '../../../../constants/module.constant';
import { TeamnoteConfigService } from '../../../../configs/teamnote-config.service';

@Component({
  selector: 'tn-export-message',
  templateUrl: './export-message.component.html',
  styleUrls: ['./export-message.component.scss']
})
export class ExportMessageComponent implements OnInit {

  chat: Chat = null;

  timePeriod: Date[] = [new Date(), new Date()];

  dateInputConfig: Partial<BsDatepickerConfig>;

  exportWithWatermark: boolean = true;
  isAllowExportRaw: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ExportMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _tnNotificationService: TnNotificationService,
    private _timestampService: TimestampService,
    private _chatRoomService: ChatRoomService,
    private _cronjobTrackerService: CronjobTrackerService,
    private _moduleManagerService: ModuleManagerService,
    private _teamnoteConfigService: TeamnoteConfigService
  ) { }

  ngOnInit() {
    this.dialogRef.updateSize('50vw');

    this.dateInputConfig = Object.assign({}, { 
      containerClass: "theme-default",
      rangeInputFormat: "YYYY-MM-DD"
    });

    this.chat = this.data.chat;

    this.isAllowExportRaw = this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.MESSAGE_EXPORT_RAW);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  export(): void {
    if (!this.timePeriod) {
      this._tnNotificationService.showCustomErrorByTranslateKey("WEBCLIENT.CHATROOM.EXPORT_CHAT.NO_TIME_PERIOD_ERROR_MSG");
      return;
    }
    this._tnNotificationService.showConfirmByTranslateKey(
      "WEBCLIENT.CHATROOM.EXPORT_CHAT.CONFIRM_MSG",
      resp => {
        this.exportChatMessage();
      },
      null,
      {
        FROM: this._timestampService.dateObjToTimeDisplay(this.timePeriod[0], false),
        TO: this._timestampService.dateObjToTimeDisplay(this.timePeriod[1], false)
      }
    )
  }

  exportChatMessage(): void {
    let timeDisplay = this._timestampService.dateObjToTimeDisplay(this.timePeriod[0], false) + ' - ' + this._timestampService.dateObjToTimeDisplay(this.timePeriod[1], false);
    this._chatRoomService.exportChatMessage(
      this.chat.chat_id,
      this._timestampService.getStartOfDateSecondStringByDateObj(this.timePeriod[0]),
      this._timestampService.getEndOfDateSecondStringByDateObj(this.timePeriod[1]),
      (resp: CronJobTaskInitialResponse) => {
        this._cronjobTrackerService.startTrackingCronjob(
          resp.job_id,
          CronJobType.EXPORT_CHAT_MESSAGE,
          new CronjobExportChatMessage(
            this.chat.displayName, 
            timeDisplay,
            [
              CronJobType.EXPORT_CHAT_MESSAGE,
              this.chat.displayName,
              timeDisplay
            ].join("_"),
            "WEBCLIENT.CHATROOM.EXPORT_CHAT.EXPORT_READY_MSG"
          ),
          null,
          "WEBCLIENT.CHATROOM.EXPORT_CHAT.TITLE"
        );
        this._tnNotificationService.showCustomInfoByTranslateKey("WEBCLIENT.CHATROOM.EXPORT_CHAT.IN_PROGRESS_MSG");
        this.cancel();
      },
      err => {

      },
      this.exportWithWatermark ? 0 : 1,
      this._teamnoteConfigService.config.WEBCLIENT.WATERMARK.IS_SHOW_OPEN_TIME ? 1 : 0
    );
  }

}
