import { Component, OnInit, Inject } from '@angular/core';

import * as _ from 'lodash';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FileManagerService } from '../file-manager/file-manager.service';
import { InputValidationService } from '../input-validation/input-validation.service';
import { ImageEditorService } from '../image-editor/image-editor.service';
import { TnNotificationService } from '../tn-notification/tn-notification.service';
import { FileUploader } from 'ng2-file-upload';
import { AttachmentTypeConstant } from '../../constants/attachment-type.constant';
import { AMQPRoutingKey } from '../../constants/amqp-routing-key.constant';
import { TeamnoteConfigService } from '../../configs/teamnote-config.service';
import { FileUploadTarget } from './file-upload-target';
import { TranslateManagerService } from '../translate/translate-manager.service';

export class ParsedFile {
  file: any; // File
  type: number;
  name: string;
  imagePreview?: any;
  isValid: boolean;
  validCode?: number;
  dateObj?: Date;
}

@Component({
  selector: 'tn-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {

  files: ParsedFile[];
  filesDisplay: any[];

  target: string;
  fileUploadFunction: Function;
  defaultFiles: File[];
  isGetParsedFile: boolean;
  validExtensions: string[];
  validExtensionsDisplays: string[];
  fileAcceptString: string = "";

  // drag file
  draggingFileUploader: FileUploader = new FileUploader({ url: null });
  isDraggingFileOver: boolean = false;

  ATTACHMENT_TYPE = AttachmentTypeConstant;

  fileUploadTip: string = "";

  constructor(
    public dialogRef: MatDialogRef<FileUploaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fileManagerService: FileManagerService,
    private _inputValidationService: InputValidationService,
    private _imageEditorService: ImageEditorService,
    private _tnNotificationService: TnNotificationService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _translateManagerService: TranslateManagerService
  ) { }

  ngOnInit() {
    this.init();
  }

  init() {
    this.dialogRef.updateSize('60vw');
    this.target = this.data.target;
    this.fileUploadFunction = this.data.fileUploadFunction;
    this.defaultFiles = this.data.defaultFiles ? this.data.defaultFiles : [];
    this.isGetParsedFile = this.data.isGetParsedFile;
    this.validExtensions = this.data.validExtensions;
    this.validExtensionsDisplays = this.data.validExtensionsDisplays;

    this.fileAcceptString = _.map(this.validExtensions, (ext) => {
      return "." + ext;
    }).join(",");

    this.files = [];

    this.handleFileObjInput(this.defaultFiles);

    this._translateManagerService.getTranslationByKey(
      "GENERAL.FILE_TIP_DYNAMIC",
      (string) => {
        this.fileUploadTip = string;
      },
      {
        SIZE: this._teamnoteConfigService.config.GENERAL.ATTACHMENT_SIZE_LIMIT,
        FORMATS: this.validExtensionsDisplays.join(', ').toUpperCase()
      }
    );
  }

  cancel() {
    this.dialogRef.close();
  }

  /**
   * Finish selecting and confirm upload.
   * 
   * - At least need one valid file
   * - Call fileUploadFunction
   * 
   * @returns {void} 
   * @memberof FileUploaderComponent
   */
  upload(): void {
    var validFiles = [];
    _.each(this.files, (f) => {
      f.file.dateObj = f.dateObj;
      if (f.isValid) {
        if (this.isGetParsedFile) {
          validFiles.push(f);
        } else {
          validFiles.push(f.file);
        }
      }
    });

    if (validFiles.length == 0) {
      this._tnNotificationService.showCustomErrorByTranslateKey('GENERAL.NO_FILE');
      return;
    }

    this.fileUploadFunction(validFiles);
    this.cancel();
  }

  /**
   * Handle file input from <input type="file">
   * 
   * - only handle files where filename doesn't exist yet
   * 
   * @param {any} e - File input event
   * @returns {void}
   * @memberof FileUploaderComponent
   */
  handleFileInputChange(e): void {
    var files = e.target.files;

    var parsed = [];
    _.each(files, (f: File) => {
      if (this.isSameFileExistAlready(f)) {
        return;
      }
      parsed.push(this.parseFilesDisplay(f));
    });

    this.files = _.union(this.files, parsed);
  }

  /**
   * Handle file input from file object
   * (Main use: for store report, where user selected files before and trying to edit again)
   * 
   * @param {File[]} files - input files
   * @returns {void}
   * @memberof FileUploaderComponent
   */
  handleFileObjInput(files: File[]): void {
    var parsed = [];
    _.each(files, (f: File) => {
      if (this.isSameFileExistAlready(f)) {
        return;
      }
      parsed.push(this.parseFilesDisplay(f));
    });

    this.files = _.union(this.files, parsed);
  }

  /**
   * File Drag and Drop events
   * 
   * @param {any} event - input event
   * @memberof FileUploaderComponent
   */
  fileOver(event) {
    // console.log(event);
    this.isDraggingFileOver = event;
  }
  onFileDrop(event) {
    this.handleFileObjInput(event);
  }

  /**
   * Check if file exists already by comparing filename
   * 
   * @param {File} newF - new input file
   * @returns {boolean} - whether file exists already
   * @memberof FileUploaderComponent
   */
  isSameFileExistAlready(newF: File): boolean {
    let exist = _.find(this.files, (f) => {
      return f.name == newF.name;
    });
    return exist ? true : false;
  }

  /**
   * Parse file object into ParsedFile
   * 
   * @param {File} f - original file object
   * @returns {ParsedFile} - parsed file object]
   * @memberof FileUploaderComponent
   */
  parseFilesDisplay(f: File): ParsedFile {
    let type = this._fileManagerService.getAttachmentType(f.name);

    // If file is image, load the file and get its dataURL
    if (type == this.ATTACHMENT_TYPE.IMAGE) {
      let reader  = new FileReader();
      reader.onload = (e: any) => {
        let imagePreview = e.target.result;
        this.setImagePreviewByFileName(imagePreview, f.name);

        this._imageEditorService.getDateTimeOfImage(
          imagePreview, 
          (dateObj, dataUrl) => {
            this.setImageDateByFileName(dateObj, f.name);
            this.setImageFileByFileNameAndPreview(dataUrl, f.name);
          }
        );
      };
      reader.readAsDataURL(f);
    }

    let validCode = 0;
    if (this.validExtensions) {
      validCode = this._inputValidationService.checkIsFileValidByType(f, this.validExtensions);
    } else {
      // different target action will have different condition
      if (this.target === FileUploadTarget.CHATROOM) {
        validCode = this._inputValidationService.isFileValidForChatRoom(f);
      } else if (this.target == FileUploadTarget.DOCUMENT) {
        validCode = this._inputValidationService.isFileDoc(f);
      } else {
        // default check if file is a image
        validCode = this._inputValidationService.isFileImage(f);
      }
    }
    let isValid = validCode == this.ATTACHMENT_TYPE.IS_VALID.VALID;
    console.log(validCode);

    return {
      file: f,
      name: f.name,
      type: type,
      isValid: isValid,
      validCode: validCode,
      imagePreview: null
    };
  }

  /**
   * Set imagePreview of existing file by filename
   * 
   * @param {*} imagePreview - image dataURL
   * @param {*} fileName - target file name
   * @memberof FileUploaderComponent
   */
  setImagePreviewByFileName(imagePreview: any, fileName: any) {
    let target = _.find(this.files, (f) => {
      return f.name == fileName;
    });
    target.imagePreview = imagePreview;
  }
  /**
   * Set imagePreview of existing file by filename
   * 
   * @param {*} imagePreview - image dataURL
   * @param {*} fileName - target file name
   * @memberof FileUploaderComponent
   */
  setImageFileByFileNameAndPreview(imagePreview: any, fileName: any) {
    let target = _.find(this.files, (f) => {
      return f.name == fileName;
    });
    let newFile: any = this._fileManagerService.dataUrlToFile(imagePreview, fileName);
    target.file = newFile;
    target.imagePreview = imagePreview;
  }

  /**
   * Set image date obj of existing file by filename
   * 
   * @param {Date} dateObj - image date obj
   * @param {*} fileName - target file name
   * @memberof FileUploaderComponent
   */
  setImageDateByFileName(dateObj: Date, fileName: any) {
    let target = _.find(this.files, (f) => {
      return f.name == fileName;
    });
    target.dateObj = dateObj;
  }

  /**
   * Set imagePreview and file
   * 
   * @param {*} imagePreview - new imagePreview
   * @param {ParsedFile} file - original ParsedFile
   * @memberof FileUploaderComponent
   */
  setFileByImagePreviewAndFile(imagePreview: any, file: ParsedFile, imageCaption: string) {
    let newFile: any = this._fileManagerService.dataUrlToFile(imagePreview, file.name);
    newFile.caption = imageCaption;
    file.file = newFile;
    file.imagePreview = imagePreview;
  }

  /**
   * Remvoe file 
   * 
   * @param {ParsedFile} file - target file
   * @memberof FileUploaderComponent
   */
  removeFile(file: ParsedFile) {
    this.files = _.filter(this.files, (f) => {
      return f.name !== file.name;
    });
  }

  /**
   * Edit image, open image editor
   * 
   * @param {ParsedFile} file - target file
   * @memberof FileUploaderComponent
   */
  editImage(file: ParsedFile) {
    this._imageEditorService.openImageEditorModal(
      file.imagePreview, 
      (imageDataUrl, imageCaption) => {
        this.updateImage(file, imageDataUrl, imageCaption);
      },
      this.target != FileUploadTarget.IMAGE,
      this.target,
      file.dateObj,
      file.file.caption
    );
  }

  /**
   * Update image dataURL of file
   * 
   * @param {ParsedFile} original - original file
   * @param {string} imageDataUrl - new image dataURL
   * @param {string} imageCaption - new image caption
   * @memberof FileUploaderComponent
   */
  updateImage(original: ParsedFile, imageDataUrl: string, imageCaption: string) {
    this.setFileByImagePreviewAndFile(imageDataUrl, original, imageCaption);
  }


}
