import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TAndCComponent } from './t-and-c.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    declarations: [TAndCComponent]
})
export class TAndCModule { }
