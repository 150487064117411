import { Injectable } from '@angular/core';
import { LoggerService } from '../logger/logger.service';

@Injectable()
export class WindowFocusService {

  isWindowFocued: boolean = true;

  constructor(
    private _loggerService: LoggerService
  ) { }

  onWindowFocusEvent() {
    this.isWindowFocued = true;
    this._loggerService.debug("Window focused");
  }

  onWindowBlurEvent() {
    this.isWindowFocued = false;
    this._loggerService.debug("Window blured");
  }

}
