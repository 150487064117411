<div class="tn-content">
  <div class="tn-content-full">
    <iframe
      #teamflare
      *ngIf="teamflareAppUrl"
      width="100%"
      height="100%"
      frameBorder="0"
      [src]="teamflareAppUrl"
      (load)="iframeLoaded()"
    ></iframe>
  </div>
</div>
