import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserContact } from '../../../../models/user-contact';
import { UserContactService } from '../../../services/data/user-contact/user-contact.service';
import { ContactPickerService } from '../../../contact-picker/contact-picker.service';

import * as _ from 'lodash';
import { UserGroupService } from '../../../services/data/user-group/user-group.service';
import { UserGroup } from '../../../../models/user-group';
import { CustomFieldsMapped } from '../models/custom-field';

export class FieldContactDetail {
  is_visible: boolean;
  is_notify: boolean;
}

@Component({
  selector: 'tn-field-contact',
  templateUrl: './field-contact.component.html',
  styleUrls: ['./field-contact.component.scss']
})
export class FieldContactComponent implements OnInit {

  @Input() field: CustomFieldsMapped;
  @Input() availableUserIds: string[];
  @Input() isInput: boolean = false;

  @Output() onFieldUpdate: EventEmitter<CustomFieldsMapped> = new EventEmitter<CustomFieldsMapped>(null);

  contacts: UserContact[] = [];
  contactName: string = "";

  storeGroupMembers: UserContact[] = [];
  isGottenMissingContact: boolean = false;

  constructor(
    private _userContactService: UserContactService,
    private _contactPickerService: ContactPickerService,
    private _userGroupService: UserGroupService
  ) { }

  ngOnInit() {
    
  }

  ngOnChanges() {
    this.parseAnswerContact();
  }

  parseAnswerContact(): void {
    if (this.field.answer.value) {
      let missingContacts = [];
      this.contacts = [];
      _.each(JSON.parse(this.field.answer.value), (userId: string) => {
        let contact = this._userContactService.getUserContactByUserId(userId);
        if (_.isEmpty(contact)) {
          missingContacts.push(userId);
        } else {
          this.contacts.push(contact);
        }
      });
      if (!this.isGottenMissingContact && missingContacts.length > 0) {
        this._userContactService.getContactRequest(
          missingContacts,
          () => {
            this.isGottenMissingContact = true;
            this.parseAnswerContact();
          }
        );
      }
      this.sortContactByName();
    }
  }

  sortContactByName(): void {
    this.contacts = _.orderBy(this.contacts, [(c: UserContact) => {
      return c.name.toLowerCase();
    }]);
  }

  openContactPicker() {
    let availableUsers = _.map(this.availableUserIds, (userId) => {
      return this._userContactService.getUserContactByUserId(userId);
    });
    this._contactPickerService.openContactPicker(
      this.field.definition.name,
      false,
      false,
      false,
      (contacts) => {
        this.contacts = contacts;
        this.sortContactByName();
        this.onChange();
      },
      true,
      null,
      availableUsers
    );
  }

  getAnswerValue(): string {
    return JSON.stringify(_.map(this.contacts, 'user_id'));
  }

  onChange() {
    this.field.answer.value = this.getAnswerValue();
    this.onFieldUpdate.emit(this.field);
  }

}
