import { ZhChsMenuStrings } from './menu/zh_chs-menu.strings';
import { ZhChsChatStrings } from '../chat/strings/zh_chs-chat.strings';
import { ZhChsChatroomStrings } from '../chat/chat-room/strings/zh_chs-chatroom.strings';
import { ZhChsNewsStrings } from '../news/strings/zh_chs-news.strings';
import { ZhChsContactStrings } from '../contact/strings/zh_chs-contact.strings';
import { ZhChsQuestionnaireStrings } from '../questionnaire/strings/zh_chs-questionnaire.strings';
import { ZhChsJobReportStrings } from '../job-report/strings/zh_chs-job-report.strings';
import { ZhChsBroadcastStrings } from '../broadcast/strings/zh_chs-broadcast.string';
import { ZhChsWorkflowStrings } from '../workflow/strings/zh_chs-workflow.strings';
import { ZhChsVideoStrings } from '../video-streaming/strings/zh_chs-video.strings';
import { ZhChsWebdavStrings } from '../webdav-material/strings/zh_chs-webdav.strings';
import { ZhChsStarredMessagesStrings } from '../starred-messages/strings/zh_chs-starred-messages.strings';

export const ZhChsWebclientString = {
  MENU: ZhChsMenuStrings,

  CHAT: ZhChsChatStrings,
  CHATROOM: ZhChsChatroomStrings,
  NEWS: ZhChsNewsStrings,
  CONTACT: ZhChsContactStrings,
  QUESTIONNAIRE: ZhChsQuestionnaireStrings,
  JOB_REPORT: ZhChsJobReportStrings,
  BROADCAST: ZhChsBroadcastStrings,
  WORKFLOW: ZhChsWorkflowStrings,
  VIDEO: ZhChsVideoStrings,
  WEBDAV: ZhChsWebdavStrings,
  MESSAGE_STAR: ZhChsStarredMessagesStrings
};
