<div class="broadcast-manage-wrapper">
  <tn-broadcast-list
    *ngIf="currentManageOption == manageOptions.list"
    (onEditGroup)="goToSet($event)">
  </tn-broadcast-list>

  <tn-broadcast-set
    *ngIf="currentManageOption == manageOptions.set"
    [groupId]="editingBroadcastUserGroupId"
    (onBack)="goToList()">
  </tn-broadcast-set>
</div>