import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";

// TeamNote services
import { AccountManagerService } from './account/account-manager.service';
import { SocketService } from './socket/socket.service';
import { ModuleManagerService } from './module/module-manager.service';

import { DataManagerService } from './data/data-manager.service';
import { ChatService } from './data/chat/chat.service';
import { MessagesService } from './data/messages/messages.service';
import { UserContactService } from './data/user-contact/user-contact.service';
import { UserGroupService } from './data/user-group/user-group.service';
import { NewsMessageService } from './data/messages/news-message/news-message.service';
import { ChatMessageService } from './data/messages/chat-message/chat-message.service';
import { InfoMessageService } from "./data/messages/info-message/info-message.service";

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    AccountManagerService,
    SocketService,
    ModuleManagerService,

    DataManagerService,
    ChatService,
    MessagesService,
    UserContactService,
    UserGroupService,
    NewsMessageService,
    ChatMessageService,
    InfoMessageService
  ]
})
export class TeamNoteServicesModule {}
