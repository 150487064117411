export const ZhChsMenuStrings = {
  MY_PROFILE: "个人档案",

  SETTINGS: {
    OUT_OF_OFFICE: {
      TITLE: "不在办公室",
      NOW_TO: "现在至"
    }
  },

  NEWS: "消息",
  CHATS: "聊天",
  CONTACTS: "通讯录",
  PROFILE: "个人档案",
  DUTY_ROSTER: "值勤表",
  TRAINING: "档案分享",
  CORPORATE_MATERIAL: "档案分享",
  QUESTIONNAIRE: "问卷调查",
  STORE_REPORT: "报告",
  JOB_DISPATCH: "指派工作",
  SCHEDULER: "更表",
  EVENT: "活动",
  NEWS_CATEGORY: "News Category",
  WORKFLOW: "工作流程",
  VIDEO_STREAMING: "视频串流",
  WEBDAV_MATERIAL: "WEBDAV",
  MESSAGE_STAR: '星号信息'
};
