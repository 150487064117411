export const RegistrationStateConstant = {
  ACTION: {
    REGISTER: "register",
    LOGIN: "login"
  },
  REQUIRE: {
    NONE: "none",
    EMAIL: "email",
    MOBILE: "mobile",
    HKID: "hkid",
    FULLNAME: "fullname",
    LDAP_PW: "ldap_pw",
    STAFF_ID: "staff_id"
  }
};