import { Component, OnInit, Inject } from '@angular/core';
import { Attachment } from '../../../models/attachment';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AttachmentService, TimelinedAttachment } from '../attachment.service';
import { AttachmentTypeConstant } from '../../../constants/attachment-type.constant';

import * as _ from 'lodash';
import { DateService } from '../../date/date.service';

@Component({
  selector: 'tn-attachment-pdf-list',
  templateUrl: './attachment-pdf-list.component.html',
  styleUrls: ['./attachment-pdf-list.component.scss']
})
export class AttachmentPdfListComponent implements OnInit {
  ATTACHMENT_TYPE = AttachmentTypeConstant;
  attachments: Attachment[] = [];

  timelinedAttachments: TimelinedAttachment[] = [];

  constructor(
    public dialogRef: MatDialogRef<AttachmentPdfListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _attachmentService: AttachmentService,
    private _dateService: DateService
  ) { }

  ngOnInit() {
    this.dialogRef.updateSize('50vw');
    this.attachments = _.orderBy(this.data.attachments, 'timestamp', 'desc');
    this.parsePdfDateDisplay();

    this.timelinedAttachments = this._attachmentService.buildTimelinedAttachments(this.attachments);
  }

  parsePdfDateDisplay() {
    _.each(this.attachments, (a) => {
      a.timestampDisplay = this._dateService.getDateDisplayWithKey(a.timestamp, "CORPORATE_MATERIAL_DATE")
    });
  }

  onClick(pdf: Attachment): void {
    this._attachmentService.openAttachmentModal(true, [pdf], null, true);
  }

  getFileTypeByFileIds(attachmentId: string): number {
    const split = attachmentId.split('.');
    const ext = _.last(split).toLowerCase();

    if (_.includes(AttachmentTypeConstant.DOC_TYPE, ext)) {
      return AttachmentTypeConstant.DOC;
    }
    if (_.includes(AttachmentTypeConstant.EXCEL_TYPE, ext)) {
      return AttachmentTypeConstant.SHEET;
    }
    if (_.includes(AttachmentTypeConstant.PPT_TYPE, ext)) {
      return AttachmentTypeConstant.PPT;
    }
    if (_.includes(AttachmentTypeConstant.PDF_TYPES, ext)) {
      return AttachmentTypeConstant.PDF;
    }
    return AttachmentTypeConstant.GENERAL;
  }

}
