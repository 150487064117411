import { Component, OnInit, Inject, ElementRef, HostListener, ViewChild } from '@angular/core';

import { Message } from '../../../models/message';

import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import * as _ from 'lodash';
import { NewsService } from '../news.service';
import { ChatService } from '../../services/data/chat/chat.service';
import { AccountManagerService } from '../../services/account/account-manager.service';
import { PresenceTypeConstant } from '../../../constants/presence-type.constant';

@Component({
  selector: 'tn-news-modal',
  templateUrl: './news-modal.component.html',
  styleUrls: ['./news-modal.component.scss']
})
export class NewsModalComponent implements OnInit {

  isInModal: boolean = true;

  messageId: string;
  newsMessage: Message;
  isEnableEmoji: boolean;
  isEnableComment: boolean;

  sortedComment: any;
  isAdmin: boolean = false;

  inputMessage: string = '';

  expandedCommentMessageIds: string[] = [];

  //News enhancement
  newsHttp: any;
  isEnableNewsHttp: boolean = false;

  constructor(
    private _newsService: NewsService,
    public dialogRef: MatDialogRef<NewsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _chatService: ChatService,
    private _accountManagerService: AccountManagerService
  ) { 
    
  }

  ngOnInit() {
    this.isEnableNewsHttp = this.data.isEnableNewsHttp;
    if (this.isEnableNewsHttp){
      this.newsHttp = this.data.newsHttp;
      if (!this.data.newsHttp.is_read){
        this._newsService.setNewsRead(this.data.newsHttp.news_id);
      }
    } else {
      this.newsMessage = this.data.newsMessage;
      this.messageId = this.newsMessage.message_id;
      this._newsService.realNewsRead(this.messageId);
      this.checkIsAdmin();
    }
    
    this.isEnableEmoji = this.data.isEnableEmoji;
    this.isEnableComment = this.data.isEnableComment;
    
  }

  checkIsAdmin() {
    // in order to have delete permission of a news item, user need to be an admin of at least one news.category,
    // and admin of at least one non news.category group, must satisfy both criteria
    let isCategoryAdmin = false;
    let isChannelAdmin = false;
    _.each(this.data.newsMessage.chat_ids, (chatId) => {
      let chat = this._chatService.getChatByChatId(chatId);
      if (chat) {
        if (chat.chat_id.indexOf('news.company.') == 0) {
          isChannelAdmin = true;
        }
        if (chat.admins.indexOf(this._accountManagerService.userId) != -1) {
          switch (chat.t) {
            case PresenceTypeConstant.NEWS_CHANNEL:
            isChannelAdmin = true;
              break;
            case PresenceTypeConstant.NEWS_CATEGORY:
              isCategoryAdmin = true;
              break;
          }
        }
      }
    });
    this.isAdmin = isCategoryAdmin && isChannelAdmin;
  };

  close() {
    this.dialogRef.close();
  }

  onReplyToggle(firstLevelComment: Message) {
    firstLevelComment.isReplying = true;
  }

  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    // console.log(event);
    this.mouseWheelFunc(event, 'chrome');
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    // console.log(event);
    this.mouseWheelFunc(event, 'firefox');
  }

  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
    // console.log(event);
    this.mouseWheelFunc(event, 'ie');
  }

  mouseWheelFunc(event, from): void {
    // Send read when user scroll news list
    if(!this.isEnableNewsHttp){
      this.sendBatchCommentRead();
    }
    
  }

  sendBatchCommentRead() {
    this._newsService.sendBatchReadOfNewsCommentByMessageId(this.messageId);
  }

  displayAllChildComments(firstLevelComment: any) {
    firstLevelComment.showAll = true;
    this.expandedCommentMessageIds.push(firstLevelComment.message_id);
  }

  checkIfCommentExpanded(comment: Message) {
    return _.indexOf(this.expandedCommentMessageIds, comment.message_id) != -1;
  }

  // Remove Comment
  onRemoveComment(comment: Message) {
    this._newsService.recallNewsComment(comment.message_id)
  }

}
