import { Component, OnInit, Input } from '@angular/core';
import { FileFactoryService } from '../../../../../utilities/file-factory/file-factory.service';

@Component({
  selector: 'tn-sticker-message',
  templateUrl: './sticker-message.component.html',
  styleUrls: ['./sticker-message.component.scss']
})
export class StickerMessageComponent implements OnInit {

  @Input() body: any;

  imgSrc: string;

  constructor(
    private _fileFactoryService: FileFactoryService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.parseSticker();
  }

  parseSticker(): void {
    this._fileFactoryService.getFileByAttachmentId(
      this.body.attachment_id,
      (imageUrl) => {
        this.imgSrc = imageUrl
      },
      (err) => {},
      false,
      false,
      true
    )
  }

}
