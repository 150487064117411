export const UserConstant = {
  USER_STATE: {
    NORMAL: 0,
    OUT_OF_OFFICE: 1
  },
  IMPORTANT_LEVEL: {
    NORMAL: 0,
    GLOBAL: 1,
    INDIVIDUAL: 2
  }
};

export interface ImportantUsers {
  company?: string[];
  user?: string[];
}