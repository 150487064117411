<div (click)="hideContextMenu()">
  <button class="no-css-reload-btn" onclick="window.location.reload()" style="position: absolute; top: 12px; left: 12px; width: 90vw; height: 50px; background-color: blue; color: white; margin-left: 5vw;">
    We have encountered an error. Please click here to reload the page and try again.
  </button>
  <div class="init-overlay" *ngIf="isInitializing"></div>
  <tn-loader></tn-loader>
  <tn-watermark></tn-watermark>
  <tn-context-menu></tn-context-menu>
  <router-outlet></router-outlet>
</div>
