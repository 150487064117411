import { Injectable } from '@angular/core';
// import { NgMapAsyncCallbackApiLoader } from '@ngui/map';

@Injectable()
export class NgMapConfigLoaderService {
  apiUrl = null;

  changeUrl(apiKey) {
    let url = "https://maps.google.com/maps/api/js?key=" + apiKey;
    // this.config.apiUrl = url;
    this.apiUrl = url;

    // console.log(this.config);
    this.loadGoogleMapJs();
  }

  loadGoogleMapJs(): void {
    // let src = this.config.apiUrl;
    let src = this.apiUrl;

    let script = document.createElement("script");
    script.src = src;
    document.head.appendChild(script);
  }

}
