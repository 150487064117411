<div class="mute-group">
  <div class="modal-header text-color-primary">
    <h4>{{'WEBCLIENT.CHAT.CHAT_GROUP.NOTIFICATION.TITLE' | translate}}</h4>
  </div>
  <div class="modal-body">
    <div class="mute-option" *ngFor="let option of mutePeriodOptions">
      <label>
        <input type="radio" [(ngModel)]="selectedMutePeriodOption" [value]="option" name="muteOption">
        <span>{{option.key | translate}}</span>
      </label>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-default cancel" (click)="cancel()">
      {{'GENERAL.CANCEL' | translate}}
    </button>
    <button class="btn tn-button-primary confirm" (click)="confirm()">
      {{'GENERAL.CONFIRM' | translate}}
    </button>
  </div>
</div>