import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataManagerService } from '../../services/data/data-manager.service';
import { BroadcastService } from '../broadcast.service';
import { TeamNoteGeneralConstant } from '../../../constants/general.constant';
import { UserContact } from '../../../models/user-contact';
import { BroadcastGroup } from '../models/broadcast';

import * as _ from 'lodash';

@Component({
  selector: 'tn-broadcast-recipient-selector',
  templateUrl: './broadcast-recipient-selector.component.html',
  styleUrls: ['./broadcast-recipient-selector.component.scss']
})
export class BroadcastRecipientSelectorComponent implements OnInit {

  @Input() defaultIds: string[] = [];
  prevDefaultIds: string[] = [];

  @Input() selectedContacts: UserContact[] = [];
  @Output() selectedContactsChange: EventEmitter<UserContact[]> = new EventEmitter<UserContact[]>();

  // User
  contactUsers: UserContact[] = [];
  unSelectedContacts: UserContact[] = [];

  // Broadcast group
  broadcastGroups: BroadcastGroup[] = [];

  // Selection targets
  selectionTargets = {
    teamNoteContact: 'contact',
    broadcastGroup: 'broadcast_group'
  };
  currentSelectionTarget: string;

  constructor(
    private _dataManagerService: DataManagerService,
    private _broadcastService: BroadcastService
  ) { }

  ngOnInit() {
    this.getAllContactUsers();
    this.currentSelectionTarget = this.selectionTargets.teamNoteContact;
  }

  ngOnChanges() {
    this.restoreSelectedMembers();
  }

  getAllContactUsers(): void {
    this.contactUsers = this._dataManagerService.getAllContactUsersUnderUserGroup(TeamNoteGeneralConstant.ROOT_USER_GROUP_ID);
    this.unSelectedContacts = this.contactUsers;
    this.selectedContacts = [];
    this.updateSelectedContacts();
  }

  restoreSelectedMembers(): void {
    if (this.prevDefaultIds.toString() == this.defaultIds.toString()) {
      return;
    }
    _.each(this.defaultIds, (id) => {
      let member = _.find(this.contactUsers, {user_id: id});
      if (member) {
        this.selectMember(member);
      }
    });
    this.prevDefaultIds = _.cloneDeep(this.defaultIds);
  }

  selectMember(contact: UserContact): void {
    this.selectedContacts = _.union(this.selectedContacts, [contact]);
    this.unSelectedContacts = _.without(this.unSelectedContacts, contact);
    this.updateSelectedContacts();
  }

  unSelectMember(contact: UserContact): void {
    this.selectedContacts = _.without(this.selectedContacts, contact);
    this.unSelectedContacts = _.union(this.unSelectedContacts, [contact]);
    this.updateSelectedContacts();
  }

  removeAllSelected(): void {
    this.getAllContactUsers();
  }

  toggleSelectTeamNote(): void {
    this.currentSelectionTarget = this.selectionTargets.teamNoteContact;
  }

  toggleSelectBroadcastList(): void {
    this._broadcastService.getBroadcastGroupList(
      resp => {
        this.broadcastGroups = resp;
        this.currentSelectionTarget = this.selectionTargets.broadcastGroup;
      },
      err => {

      }
    );
  }

  selectBroadcastGroup(broadcastGroup: BroadcastGroup): void {
    this._broadcastService.getBroadcastGroupById(
      broadcastGroup.user_group_id,
      resp => {
        let group: BroadcastGroup = resp;
        let memberIds = _.map(group.user_group_members, 'user_id');
        _.each(memberIds, (id) => {
          let member = _.find(this.contactUsers, {user_id: id});
          if (member) {
            this.selectMember(member);
          }
        });
      },
      err => {

      }
    );
  }

  updateSelectedContacts(): void {
    this.selectedContactsChange.emit(this.selectedContacts);
  }

}
