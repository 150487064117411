import { Injectable } from '@angular/core';
import { TnDialogService } from '../tn-dialog/tn-dialog.service';
import { ImageEditorComponent } from './image-editor.component';
import { AMQPRoutingKey } from '../../constants/amqp-routing-key.constant';
import { ImageHelperService } from '../image-helper/image-helper.service';
import { TimestampService } from '../timestamp/timestamp.service';
import { TeamnoteConfigService } from '../../configs/teamnote-config.service';
import { FileUploadTarget } from '../file-uploader/file-upload-target';

@Injectable()
export class ImageEditorService {

  constructor(
    private _tnDialogService: TnDialogService,
    private _imageHelperService: ImageHelperService,
    private _timestampService: TimestampService,
    private _teamnoteConfigService: TeamnoteConfigService
  ) { }

  openImageEditorModal(imageDataUrl: string, editCompleteFunction: Function, isNeedDateTimeOverlay: boolean, targetAction?: string, dateObj?: Date, imageCaption?: string): void {
    let isAllowImageCaption = targetAction == FileUploadTarget.CHATROOM;
    let dialogRef = this._tnDialogService.openTnDialog(ImageEditorComponent, {
      imageDataUrl: imageDataUrl,
      isAllowImageCaption: isAllowImageCaption,
      editCompleteFunction: editCompleteFunction,
      dateObj: dateObj,
      isNeedDateTimeOverlay: isNeedDateTimeOverlay,
      imageCaption: imageCaption
    });
  }

  getDateTimeOfImage(imageDataUrl: string, callbackOfDateObjAndDataUrl: Function): void {
    this._imageHelperService.getPhotoDateTimeOriginalFromDataUrl(
      imageDataUrl,
      (dateObj, dateUrl) => {
        if (!dateObj) {
          dateObj = new Date();
        }
        callbackOfDateObjAndDataUrl(dateObj, dateUrl);
      }
    );
  }
}
