import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebDavMaterialComponent } from './webdav-material.component';
import { UtilitiesModule } from '../../utilities/utilities.module';
import { WebDavMaterialService } from './webdav-material.service';
import { WebDavMaterialListComponent } from './webdav-material-list/webdav-material-list.component';
import { WebDavMaterialFolderComponent } from './webdav-material-folder/webdav-material-folder.component';
import { WebDavMaterialLoginComponent } from './webdav-material-login/webdav-material-login.component';
import { MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/legacy-dialog';

@NgModule({
    imports: [
        CommonModule,
        UtilitiesModule
    ],
    providers: [
        WebDavMaterialService,
        // {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}
    ],
    declarations: [
        WebDavMaterialComponent,
        WebDavMaterialListComponent,
        WebDavMaterialFolderComponent,
        WebDavMaterialLoginComponent
    ],
    exports: [
        WebDavMaterialListComponent
    ]
})
export class WebDavMaterialModule {

}

