<div class="field-geolocation-input-wrapper">
  <div class="geolocation-ans clickable" (click)="openLocation()" *ngIf="!isInput">
    {{location.address}}
  </div>

  <div class="geolocation-input" *ngIf="isInput">
    {{location.address}}
    <button class="btn tn-button-primary geolocation-button" (click)="openLocationSelector()">
      <i class="fa fa-fw fa-plus"></i>
    </button>
  </div>
</div>