import {Component, OnInit} from '@angular/core';
import {WorkflowService} from './services/workflow.service';
import {WorkflowCustomRouteParam, WorkflowForm, WorkflowFormFilterParam} from './model/workflow';
import {RouteParamService} from '../../utilities/route-param/route-param.service';

import * as _ from 'lodash';
import {TnDialogService} from '../../utilities/tn-dialog/tn-dialog.service';
import {FormExportComponent} from './form-export/form-export.component';
import {TeamnoteConfigService} from '../../configs/teamnote-config.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'tn-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss']
})
export class WorkflowComponent implements OnInit {

  paramSub: any;
  customParam: WorkflowCustomRouteParam = new WorkflowCustomRouteParam();

  pageTitle: string;
  pageTitleTranslateKey: string;
  hidePageTitle: boolean;
  hidePageSideMenu: boolean;
  hideExportFormBtn: boolean;

  forms: WorkflowForm[] = [];
  viewingForm: WorkflowForm = null;

  currentMainView: number;
  isFocusLeftContent: boolean = false;
  MAIN_VIEW_CODE = {
    NONE: 0,
    SET: 1,
    CREATE: 2,
    FILTER: 3
  };

  isWebapp: boolean = false;

  constructor(
    private _teamnoteConfigService: TeamnoteConfigService,
    private _workflowService: WorkflowService,
    private _routeParamService: RouteParamService,
    private _tnDialogService: TnDialogService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.paramSub = this._routeParamService.currentRouteParam$.subscribe(
      param => {
        // @ts-ignore
        const queryParams = this.route.queryParams.getValue();
        if (queryParams.type_id) {
          this.customParam = new WorkflowCustomRouteParam(queryParams.type_id);
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {},
            queryParamsHandling: '',
          });
        } else {
          this.customParam = param;
        }
        // let isParamUpdated = this.customParam != param;

        this.getWorkflowInfo();
      }
    );
    this.checkIsWebApp();
    this.hidePageTitle = this.isWebapp || (this._teamnoteConfigService.config.WEBCLIENT.WORK_FLOW && this._teamnoteConfigService.config.WEBCLIENT.WORK_FLOW.HIDE_PAGE_TITLE_BAR);
    this.hidePageSideMenu = this._teamnoteConfigService.config.WEBCLIENT.WORK_FLOW && this._teamnoteConfigService.config.WEBCLIENT.WORK_FLOW.HIDE_FORM_LIST_MENU;
    this.hideExportFormBtn = this._teamnoteConfigService.config.WEBCLIENT.WORK_FLOW && this._teamnoteConfigService.config.WEBCLIENT.WORK_FLOW.HIDE_FORM_LIST_EXPORT_BTN;
  }

  ngOnDestroy() {
    this.paramSub.unsubscribe();
  }

  getWorkflowInfo(): void {
    this.forms = [];

    this.getWorkflowOptions();
    this.clearViewingForm(false);

    // clear filter param
    this._workflowService.clearWorkflowFilterParam();
  }

  updatePageTitle(): void {
    if (this.customParam) {
      this.pageTitle = this._workflowService.getTypeByTypeId(this.customParam.typeId).name;
      this.pageTitleTranslateKey = null;
    } else if (this._teamnoteConfigService.config.WEBCLIENT.WORK_FLOW && this._teamnoteConfigService.config.WEBCLIENT.WORK_FLOW.HIDE_PAGE_TITLE) {
      this.pageTitle = ' ';
      this.pageTitleTranslateKey = null;
    } else {
      this.pageTitle = null;
      this.pageTitleTranslateKey = 'WEBCLIENT.MENU.WORKFLOW';
    }
  }

  getWorkflowOptions(): void {
    this._workflowService.getWorkflowOptions(
      () => {
        this.updatePageTitle();
        this.getWorkflowForms();
      }
    );
  }

  getWorkflowForms(): void {
    this._workflowService.getWorkflowFormList(
      (resp) => {
        resp = _.filter(resp, (form: WorkflowForm) => {
          if (this.customParam && this.customParam.typeId) {
            return form.type_id == this.customParam.typeId;
          }
          let type = this._workflowService.getTypeByTypeId(form.type_id);
          if (!type) {
            return false;
          }
          return !type.visible_in_app_menu;
        });
        this.forms = resp;
      },
      // this.customParam ? this.customParam.typeId : null
    );
  }

  // Action
  clearViewingForm(isRefresh: boolean): void {
    this.viewingForm = null;
    this.currentMainView = this.MAIN_VIEW_CODE.NONE;
    this.isFocusLeftContent = true;
    if (isRefresh) {
      this.getWorkflowForms();
    }
  }

  // View
  onFormClick(form: WorkflowForm): void {
    this.viewingForm = form;
    this.currentMainView = this.MAIN_VIEW_CODE.SET;
    this.isFocusLeftContent = false;
  }

  onFormViewBack(isRefresh: boolean): void {
    this.clearViewingForm(isRefresh);
  }

  // Create
  createForm(): void {
    this.clearViewingForm(false);
    this.currentMainView = this.MAIN_VIEW_CODE.CREATE;
    this.isFocusLeftContent = false;
  }

  onFormSubmitBack(isRefresh: boolean): void {
    this.clearViewingForm(isRefresh);
  }

  // Filter
  filterForm(): void {
    this.viewingForm = null;
    this.currentMainView = this.MAIN_VIEW_CODE.FILTER;
    this.isFocusLeftContent = false;
  }

  onFormFilterBack(isRefresh: boolean): void {
    this.clearViewingForm(isRefresh);
  }

  onFilterApply(filter: WorkflowFormFilterParam): void {
    this._workflowService.cacheWorkflowFilterParam(filter);

    this.forms = this._workflowService.applyFilterOnForms(this.customParam);
  }

  // Export
  exportForm(): void {
    this._tnDialogService.openTnDialog(FormExportComponent, {
      formIds: _.map(this.forms, 'form_id'),
      customParam: this.customParam
    });
  }

  checkIsWebApp(): void {
    if (window.location.hash.includes('webapp')) {
      this.isWebapp = this._teamnoteConfigService.config.WEBCLIENT.WORK_FLOW && !this._teamnoteConfigService.config.WEBCLIENT.WORK_FLOW.SHOW_PAGE_TITLE_BAR_IN_WEBAPP;
    }
  }

}
