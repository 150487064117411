<div class="checkbox-container">
  <div class="options" *ngFor="let o of question.checkboxDetail.options">
    <label>
      <input type="checkbox" [(ngModel)]="o.value" (ngModelChange)="onChange($event)">
      {{o.option}}
    </label>
    <br>
    <input class="form-control" 
      type="text" 
      [(ngModel)]="o.textAns" 
      (ngModelChange)="onChange($event)"
      *ngIf="o.free_text !== EXTRA_FREE_TEXT.NONE">
  </div>
</div>