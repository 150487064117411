<div class="video-player-container">
  <div class="download-button" *ngIf="isAllowAttachmentSave && !isDisableDownload">
    <i class="fa fa-fw fa-save fa-lg text-color-primary clickable" (click)="downloadVideo()"></i>
  </div>
  <canvas #videoWatermarkCanvas></canvas>
  <vg-player>

    <vg-overlay-play></vg-overlay-play>

    <vg-controls>
      <vg-play-pause></vg-play-pause>

      <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
      <vg-scrub-bar>
        <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
      </vg-scrub-bar>
      <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
      <vg-mute></vg-mute>
      <vg-volume></vg-volume>

    </vg-controls>


    <video [vgMedia]="media" #media id="tn-video-player" preload="auto" crossorigin>
      <source [src]="videoUrl" type="video/mp4">
    </video>
  </vg-player>
</div>