/**
 * String helper service
 * 
 * To get all available strings of languages
 * 
 * Try to apply client specific strings if it exists
 * 
 * Created by Shan - 2018-05-10
 */

import { Injectable } from '@angular/core';
import { LanguageConstant } from '../constants/language.constant';
import { EnString } from './en.strings';
import { ZhChtString } from './zh_cht.strings';
import { ZhChsString } from './zh_chs.strings';

import * as _ from 'lodash';
import { TeamNoteClientStrings } from '../clients/clients.strings';

export class LanguageString {
  key: string;
  strings: any;
}

@Injectable()
export class StringHelperService {

  constructor() { }

  getDefaultStringOfLanguage(lang: string): any {
    switch (lang) {
      case LanguageConstant.EN.key:
        return EnString;
      case LanguageConstant.ZH_CHT.key:
        return ZhChtString;
      case LanguageConstant.ZH_CHS.key:
        return ZhChsString;
    }
  }

  getCompanyStringOfLanguage(domain: string, lang: string): LanguageString {
    let str = this.getDefaultStringOfLanguage(lang);
    let companyStr = TeamNoteClientStrings[domain];
    if (companyStr) {
      let companyLangStr = _.find(companyStr, {key: lang});
      if (companyLangStr) {
        str = _.merge(str, companyLangStr.strings);
      }
    }
    return {
      key: lang,
      strings: str
    };
  }

  getAllCompanyStrings(domain: string): LanguageString[] {
    let strs = [];
    _.each(LanguageConstant, (lang) => {
      strs.push(this.getCompanyStringOfLanguage(domain, lang.key));
    });
    return strs;
  }
}
