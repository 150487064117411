import { ParsedFile } from '../../../utilities/file-uploader/file-uploader.component';

export class Questionnaire {
  answered: boolean;
  company_domain: string;
  create_date: string;
  expiry_date: string;
  is_deleted: number;
  is_enabled: number;
  questionnaire_id: string;
  questions: Question[];
  revisable: number;
  title: string;
  update_date: string;
}

export class Question {
  create_date: string;
  detail: string;
  question_id: string;
  question_index: string;
  questionnaire_id: string;
  title: string;
  type: number;
  update_date: string;

  // local
  parsedDetail?: any;
  hasError?: boolean;
  value?: string;
  ansAttachment?: FieldAttachmentParsedAnswer[];
  is_mandatory: boolean;

  // Text
  textDetail?: FreeTextDetail;

  // MC
  mcDetail?: MultipleChoiceDetail;

  // Checkbox
  checkboxDetail?: CheckboxDetail;

  // Rating
  ratingDetail?: RatingDetail;
  attachmentDetail: AttachmentDetail;
}

export class AnswerOption {
  option: string;
  free_text: string;

  // local
  value?: boolean;
  hasError?: boolean;
  textAns?: string;
}

export class MultipleChoiceDetail {
  options: AnswerOption[];
}

export class CheckboxDetail {
  options: AnswerOption[];
}

export class FreeTextDetail {
  is_mandatory: number;

  // local
  value?: string;
}

export class RatingOption {
  section: string;

  // local
  label?: string;
  rate?: number;
  hover?: number;
  hasError?: boolean;
}

export class RatingDetail {
  max_star: number;
  one_star_caption: string;
  max_star_caption: string;
  options: RatingOption[];

  // local
  indexCounter: any[];
}

export class AttachmentDetail {
  is_mandatory: number;

  value?: string;
}

export class QuestionnaireCustomRouteParam {
  questionnaireId: string;

  constructor(questionnaireId: string) {
    this.questionnaireId = questionnaireId;
  }
}

export class FieldAttachmentParsedAnswer {
  attachment_id: string;
  src: string;
  description: string;
  name: string;
  file: ParsedFile;
  type?: number;

  constructor(file?: ParsedFile, attachmentId?: string, description?: string, name?: string, type?: number) {
    this.file = file;
    this.attachment_id = attachmentId;
    this.description = description;
    this.name = name;
    this.type = type;
  }
}