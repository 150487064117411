export const ZhChsJobReportStrings = {
  ITEM_REF: "Ref: {{REF}}",

  LIST_FORMAT: {
    IMAGE: {
      ONE: "图片",
      OTHER: " 图片"
    },
    FILE: {
      ONE: "文件",
      OTHER: " 文件"
    },
    USER: {
      ONE: " 用户",
      OTHER: " 用户"
    },
    ATTACHMENT: {
      ONE: " 附件",
      OTHER: " 附件"
    }
  },

  EXPORT: {
    REPORT: "导出报告",
    JOB: "导出指派工作",
    TIME_PERIOD: "时间范围",
    IN_PROGRESS_MSG: "资料导出中，你可以在右上角的通知中心查看进度，你将在档案准备完成后收到通知。",
    EXPORT_READY_MSG: "档案已准备完成，你可以在通知中心下载档案。"
  },

  STATUS: {
    NOT_ASSIGNED: "未分派",
    PENDING: "等候回应中",
    ACCEPTED: "已接受",
    REJECTED: "已拒绝",
    STARTED: "已开始",
    FINISHED: "已完成",
    NEED_FOLLOW_UP: "待跟进"
  },

  NEW_REPORT: {
    STORE: "店铺",
    TYPE: "类型",
    TITLE: "新增报告",
    SUBMIT: "提交",
    INPUT_MANDATORY: "请输入所有必填项目",
    CONFIRM_SUBMIT: "确定要发送这个报告吗?",
    SUBMIT_SUCCESS: "报告已发送",
    FREE_TEXT_OPTION: "其他 (请注明)"
  },

  MSG: {
    NO_STORE_OPTION: "没有可用的店铺选项",
    NO_TYPE_OPTION: "没有可用的类型选项",
    ASSIGN_SUCCESS: "分派成功",
    ASSIGN_NO_PERMISSION: "没有权限"
  },

  REPORT_DETAIL: {
    TITLE: "详细报告",
    WORKER: "负责人士",
    STATUS: "状态",
    START_TIME: "开始时间",
    END_TIME: "完结时间",
    JOB_RESPONSE: "工作回应"
  },
  ACTION: {
    ACCEPT_BTN: "接受",
    REJECT_BTN: "拒绝",
    START_BTN: "开始",
    END_BTN: "完结",
    NEED_FOLLOW_UP_BTN: "再分配",
    ASSIGN: "分派"
  },

  FILTER: {
    REPORT: "筛选报告",
    JOB: "筛选工作",
    REPORT_DATE: "报告日期",
    JOB_DISPATCH_DATE: "指派日期",
    TO: "至",
    STORE: "店铺",
    TEMPLATE: "表单范本",
    STATUS: "工作状态",
    APPLY: "应用",
    CONTENT: "内容"
  }
}
