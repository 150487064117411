import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';

export enum IdleUserTimes {
  IdleTime = 0,
  CountdownTime = 60000,
  THROTTLE_THRESHOLD = 1000
}

@Injectable()
export class IdleChatroomService {
  private timeoutId: any;
  private countdownId: any;
  private countdownValue: number;

  userInactive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    // this.reset();
    // this.initListener();
  }

  initListener() {
    console.log('initListener');
    window.addEventListener('mousemove', this.throttleReset);
    window.addEventListener('click', this.throttleReset);
    window.addEventListener('keypress', this.throttleReset);
    window.addEventListener('DOMMouseScroll', this.throttleReset);
    window.addEventListener('mousewheel', this.throttleReset);
    window.addEventListener('touchmove', this.throttleReset);
    window.addEventListener('MSPointerMove', this.throttleReset);
  }

  clearListener() {
    window.removeEventListener('mousemove', this.throttleReset);
    window.removeEventListener('click', this.throttleReset);
    window.removeEventListener('keypress', this.throttleReset);
    window.removeEventListener('DOMMouseScroll', this.throttleReset);
    window.removeEventListener('mousewheel', this.throttleReset);
    window.removeEventListener('touchmove', this.throttleReset);
    window.removeEventListener('MSPointerMove', this.throttleReset);
  }

  private throttleReset = _.throttle(this.reset.bind(this), IdleUserTimes.THROTTLE_THRESHOLD);

  reset() {
    clearTimeout(this.timeoutId);
    clearTimeout(this.countdownId);

    // console.log('Chatroom not idle');
    this.userInactive$.next(false)
    this.startIdleTimer();
  }

  startIdleTimer() {
    this.timeoutId = setTimeout(() => {
      // console.log('Inactivity detected');
      this.startCountdown();
    }, IdleUserTimes.IdleTime);
  }

  startCountdown() {
    this.countdownValue = IdleUserTimes.CountdownTime / 1000;
    this.countdownId = setInterval(() => {
      this.countdownValue--;
      // console.log('will idle in:', this.countdownValue, 'seconds');
      if (this.countdownValue <= 0) {
        clearInterval(this.countdownId);
        // console.log('Chatroom idle');
        this.userInactive$.next(true);
      } 
    }, 1000);
  }
}