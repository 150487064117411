<div class="search-bar">
  <div class="search-input-container">
    <div class="search-input-wrapper">
      <i class="fa fa-search fa-fw"></i>
      <input #keywordInput type="text" class="form-control" [(ngModel)]="keyword" (keyup)="search()">
      <div class="clear-button">
        <i class="fa fa-times-circle fa-fw" *ngIf="keyword" (click)="clear()"></i>
      </div>
    </div>
    
    <div
      *ngIf="isEnableFilter && chat"
      class="filter-wrapper"
      [ngStyle]="{ cursor: !isLoading ? 'pointer' : 'not-allowed' }"
    >
      <div
        class="filter border-color-primary clickable"
        [ngClass]="{
          unfiltered: filteredItems.length === 0,
          'filtered theme-color-primary': filteredItems.length > 0
        }"
        (click)="!isLoading && filter($event)"
      >
        <ng-container *ngIf="filteredItems.length === 0">
          <div class="unfiltered-icon"></div>
          {{ 'WEBCLIENT.CHAT.SEARCH_UNFILTERED' | translate }}
        </ng-container>
        <ng-container *ngIf="filteredItems.length > 0">
          <div class="filtered-icon"></div>
          <span *ngIf="filteredItems.length === 1">{{
            'WEBCLIENT.CHAT.SEARCH_FILTERED_ONE' | translate : { COUNT: filteredItems.length }
          }}</span>
          <span *ngIf="filteredItems.length > 1">{{
            'WEBCLIENT.CHAT.SEARCH_FILTERED_MANY' | translate : { COUNT: filteredItems.length }
          }}</span>
        </ng-container>
      </div>
    </div>
  </div>
</div>