<div class="form-view-container">
  <tn-page-title [title]="'WEBCLIENT.WORKFLOW.DETAIL.TITLE' | translate">
    <div class="left-control">
      <div class="back-button clickable" (click)="formViewBack()">
        <i class="fa fa-fw fa-angle-left"></i>
      </div>
    </div>
    <div *ngIf="!hideTitleSideMenu" class="right-control">
      <div class="btn-group tn-dropdown-group" dropdown placement="bottom-right">
        <button dropdownToggle type="button" class="dropdown-toggle tn-dropdown-button">
          <i class="fa fa-angle-down fa-fw"></i>
        </button>
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right workflow-dropdown-menu" role="menu">
          <li role="menuitem">
            <div class="tn-dropdown-item"
              *ngIf="isActiveStage && isPic && previousStage && !isLastStage"
              (click)="rejectForm()">
              <i class="fa fa-ban fa-fw"></i> {{'WEBCLIENT.WORKFLOW.OPTIONS.REJECT' | translate}}
            </div>
          </li>
          <li role="menuitem">
            <div class="tn-dropdown-item"
              *ngIf="form.isFormPending"
              (click)="acceptForm()">
              <i class="fa fa-fw fa-check"></i> {{'WEBCLIENT.WORKFLOW.OPTIONS.ACCEPT' | translate}}
            </div>
          </li>
          <li role="menuitem">
            <div class="tn-dropdown-item"
              *ngIf="isActiveStage && isPic && !isLastStage"
              (click)="submitForm()">
              <i class="fa fa-fw fa-check"></i> {{'WEBCLIENT.WORKFLOW.OPTIONS.SUBMIT' | translate}}
            </div>
          </li>
          <li role="menuitem">
            <div class="tn-dropdown-item"
              *ngIf="isActiveStage && isPic && !isLastStage"
              (click)="reassignForm()">
              <i class="fa fa-fw fa-retweet"></i> {{'WEBCLIENT.WORKFLOW.OPTIONS.REASSIGN' | translate}}
            </div>
          </li>
          <li role="menuitem">
            <div class="tn-dropdown-item"
              *ngIf="isPicOfForm"
              (click)="addObserver()">
              <i class="fa fa-fw fa-users"></i> {{'WEBCLIENT.WORKFLOW.OPTIONS.ADD_OBSERVER' | translate}}
            </div>
          </li>
          <li class="divider dropdown-divider"
            *ngIf="(isActiveStage && isPic && !isLastStage) || isPicOfForm || form.isFormPending"></li>
          <li role="menuitem">
            <div class="tn-dropdown-info">
              {{'WEBCLIENT.WORKFLOW.OPTIONS.STAGES' | translate}}
            </div>
          </li>
          <li class="divider dropdown-divider"></li>
          <li role="menuitem" *ngFor="let stage of type.stages">
            <div class="tn-dropdown-item" (click)="goToStage(stage)">
              {{stage.name}}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </tn-page-title>

  <div class="form-input-container">
    <tn-form-detail
      [team]="team"
      [type]="type"
      [stage]="activeStage"
      [pic]="activeStagePic"
      [formNo]="form.form_no"
      [isInput]="isActiveStage && isPic && !isLastStage"
      [allCustomFields]="allCustomFields"
      [staticStringMappings]="staticStringMappings"
      [(mappedFields)]="mappedInputCustomFields">
    </tn-form-detail>
  </div>

  <div *ngIf="appendSubmitButton" class="form-submit-button-container">
    <button class="btn tn-button-primary btn-block" (click)="submitForm()">
      {{'WEBCLIENT.WORKFLOW.OPTIONS.SUBMIT' | translate}}
    </button>
  </div>
</div>
