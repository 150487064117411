<div class="tn-content">
  <div class="tn-content-left current-focus-content">
    <tn-contact-list-container 
      class="contact-list-container">
    </tn-contact-list-container>
  </div>
  <div class="tn-content-right current-unfocus-content">
    <!--No content-right for contact list-->
  </div>
</div>
