<div class="news-carousel-container">
    <div class="news-carousel-title"></div>
    <div class="news-carousel-items">
        <span class="news-carousel-title">{{title}}</span>
            <tn-news-cell
                [newsHttp]="currentNews"
                [isEnableEmoji]="isEnableEmoji"
                [isEnableComment]="isEnableComment"
                [isInModal]="isInModal"
                [isEnableNewsHttp]="isEnableNewsHttp"
                [isCarousel]="isCarousel">
            </tn-news-cell>
    </div>
    <div *ngIf="indicators" class="news-carousel-dot-container">
        <span *ngFor="let dot of newsHttp; let i=index"
            class="news-carousel-dot"
            [ngClass]="{'active': selectedIndex === i}"
            (click)="selectNews(i)">
        </span>
    </div>
</div>