import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { UserContact } from '../../../models/user-contact';
import { UserGroup } from '../../../models/user-group';

import { TeamnoteConfigService } from '../../../configs/teamnote-config.service';
import { FileFactoryService } from '../../../utilities/file-factory/file-factory.service';
import { TranslateManagerService } from '../../../utilities/translate/translate-manager.service';
import { WebclientService } from '../../webclient.service';
import { UserConstant } from '../../../constants/user.constant';
import { UserContactService } from '../../services/data/user-contact/user-contact.service';

@Component({
  selector: 'tn-contact-cell',
  templateUrl: './contact-cell.component.html',
  styleUrls: ['./contact-cell.component.scss']
})
export class ContactCellComponent implements OnInit {
  @Input() contact: any;
  @Input() isShowDesc: boolean = true;
  @Input() isMe: boolean = false;
  @Input() isClickable: boolean = true;
  @Input() isEnableImportantUsers: boolean = false;
  @Output() contactOnClick = new EventEmitter<UserContact>();
  @Output() userGroupOnClick = new EventEmitter<UserGroup>();

  displayName: string = '';
  avatarImageSrc: string = '';

  descFields: string[] = [];
  IMPORTANT_LEVEL = UserConstant.IMPORTANT_LEVEL;

  hideGroupAvatar: boolean = true;

  constructor(
    private _teamnoteConfigService: TeamnoteConfigService,
    private _fileFactoryService: FileFactoryService,
    private _translateManagerService: TranslateManagerService,
    private _webclientService: WebclientService,
    private _userContactService: UserContactService
  ) { 
    this.isEnableImportantUsers = this._webclientService.checkIfEnableImportantUsers()
    this.descFields = this._teamnoteConfigService.config.WEBCLIENT.CONTACT.LIST_DESC_FIELDS;

    this._translateManagerService.currentTranslation$.subscribe(lang => {
      this.updateDisplayName();
    });
  }

  ngOnInit() {
    this.hideGroupAvatar = this._teamnoteConfigService.config.WEBCLIENT.CONTACT.HIDE_GROUP_AVATAR;
  }

  ngOnChanges() {
    if (!this.contact) {
      return;
    }
    if (this.contact.pic && this.contact.pic != 'None') {
      this._fileFactoryService.getFileByAttachmentId(
        this.contact.pic,
        (imageUrl) => {
          this.avatarImageSrc = imageUrl;
        },
        (err) => {},
        false,
        false,
        true
      );
    }

    this.contact.importantLevel = this._userContactService.checkIfUserIsImportant(this.contact.user_id)

    this.updateDisplayName();
  }

  updateDisplayName(): void {
    if (!this.contact) {
      return;
    }
    this.displayName = this.contact.name;
    if (this.contact._i18n) {
      let i18n = this.contact._i18n[this._translateManagerService.getCurrentLangI18nKey()];
      if (i18n) {
        this.displayName = i18n.name;
      }
    }
  }

  contactClick(): void {
    this.contactOnClick.emit(this.contact);
  }

  userGroupClick(): void {
    this.userGroupOnClick.emit(this.contact);
  }

  onClick(): void {
    if (this.contact.user_id) {
      this.contactClick();
    } else if (this.contact.user_group_id) {
      this.userGroupClick();
    }
  }

}
