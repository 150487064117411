import { Injectable } from '@angular/core';
import { Question } from '../../models/questionnaire';

import * as _ from 'lodash';

@Injectable()
export class QuestionRatingService {

  constructor() { }

  ratingOptionsNumberToLetter(num): string {
    let mod = num % 26;
    let pow = num / 26 | 0;
    let out = mod ? String.fromCharCode(96 + mod) : (--pow, 'z');
    return pow ? this.ratingOptionsNumberToLetter(pow) + out : out;
  }
  getParsedRatingQuestion(q: Question): Question {
    q.ratingDetail = JSON.parse(q.detail);
    q.is_mandatory = true;
    q.ratingDetail.indexCounter = _.range(1, q.ratingDetail.max_star + 1);
    q.ratingDetail.options = _.map(q.ratingDetail.options, (o, index) => {
      o.label = this.ratingOptionsNumberToLetter(index + 1);
      o.rate = 0;
      o.hover = 0;
      return o;
    });
    return q;
  }

  getCheckedRatingQuestion(q: Question): Question {
    _.each(q.ratingDetail.options, (o) => {
      o.hasError = false;
      if (o.rate == 0) {
        q.hasError = true;
        o.hasError = true;
      }
    });
    return q;
  }

  getParsedRatingAnswer(q: Question): any {
    let ansArr = [];
    _.each(q.ratingDetail.options, (o) => {
      ansArr.push(o.rate.toString());
    });
    return ansArr;
  }
}
