import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionnaireComponent } from './questionnaire.component';
import { UtilitiesModule } from '../../utilities/utilities.module';
import { QuestionnaireService } from './questionnaire.service';
import { QuestionnaireListComponent } from './questionnaire-list/questionnaire-list.component';
import { QuestionnaireCellComponent } from './questionnaire-cell/questionnaire-cell.component';
import { QuestionnaireViewComponent } from './questionnaire-view/questionnaire-view.component';
import { QuestionMultipleChoiceComponent } from './questionnaire-view/question-multiple-choice/question-multiple-choice.component';
import { QuestionFreeTextComponent } from './questionnaire-view/question-free-text/question-free-text.component';
import { QuestionCheckboxComponent } from './questionnaire-view/question-checkbox/question-checkbox.component';
import { QuestionRatingComponent } from './questionnaire-view/question-rating/question-rating.component';
import { QuestionFreeTextService } from './questionnaire-view/question-free-text/question-free-text.service';
import { QuestionRatingService } from './questionnaire-view/question-rating/question-rating.service';
import { QuestionCheckboxService } from './questionnaire-view/question-checkbox/question-checkbox.service';
import { QuestionMultipleChoiceService } from './questionnaire-view/question-multiple-choice/question-multiple-choice.service';
import { QuestionAttachmentService } from './questionnaire-view/question-attachment/question-attachment.service';
import { QuestionAttachmentComponent } from './questionnaire-view/question-attachment/question-attachment.component';
import { ImageModalComponent } from './questionnaire-view/question-attachment/image-modal/image-modal.component';

@NgModule({
    imports: [
        CommonModule,
        UtilitiesModule
    ],
    providers: [QuestionnaireService, QuestionFreeTextService, QuestionMultipleChoiceService, QuestionCheckboxService, QuestionRatingService, QuestionAttachmentService],
    declarations: [QuestionnaireComponent, QuestionnaireListComponent, QuestionnaireCellComponent, QuestionnaireViewComponent, QuestionMultipleChoiceComponent, QuestionFreeTextComponent, QuestionCheckboxComponent, QuestionRatingComponent, QuestionAttachmentComponent, ImageModalComponent]
})
export class QuestionnaireModule { }
