import { Component, OnInit } from '@angular/core';
import { RouteParamService } from '../../utilities/route-param/route-param.service';
import { WebViewCustomRouteParam } from './models/webview';

@Component({
  selector: 'tn-webview',
  templateUrl: './webview.component.html',
  styleUrls: ['./webview.component.scss']
})
export class WebviewComponent implements OnInit {
  
  paramSub: any;
  customParam: WebViewCustomRouteParam = new WebViewCustomRouteParam("", "", "");

  pageTitle: string = null;
  pageTitleKey: string = null;
  webviewURL: string = null;
  
  constructor(
    private _routeParamService: RouteParamService
  ) { 
    this.paramSub = this._routeParamService.currentRouteParam$.subscribe(
      param => {
        this.customParam = param;
        this.pageTitle = null;
        this.pageTitleKey = null;
        this.webviewURL = null;

        if (this.customParam) {
          this.pageTitle = this.customParam.title;
          this.pageTitleKey = this.customParam.titleTranslateKey;
          this.webviewURL = this.customParam.link;
        }
      }
    )
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.openWebview();
  }

  ngOnDestroy() {
    this.paramSub.unsubscribe();
  }

  openWebview() {
    window.open(
      this.webviewURL,
      "_blank"
    );
  }
}
