import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'tn-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit {
  @Input() title: string;
  @Input() userField: any;
  @Input() showUserField: boolean;
  @Input() allowClick: boolean = false;
  @Output() onTitleClick = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onClick() {
    if (this.allowClick) {
      this.onTitleClick.emit();
    }
  }

}
