<div class="field-date-wrapper">
  <div class="date-ans" *ngIf="!isInput">
    {{dateDisplay}}
  </div>

  <div class="date-input" *ngIf="isInput">
    <input type="text"
           class="form-control"
           placement="top"
           #dp="bsDatepicker"
           bsDatepicker 
           [bsConfig]="dateInputConfig"
           [(ngModel)]="dateInput"
           (bsValueChange)="onChange($event)">
  </div>
</div>