<div class="profile-modal" *ngIf="loggedInUser">
  <div class="modal-header">
    <h4 class="text-color-primary">{{'WEBCLIENT.MENU.MY_PROFILE' | translate}}</h4>
  </div>
  <div class="modal-body">
    <div class="image-upload-button">
      <label [ngClass]="{'clickable': !isUserPicEditProhibit}">
        <input type="file" name="file" accept="image/*" (change)="handleFileInputChange($event)" *ngIf="!isUserPicEditProhibit"/>
        <div class="avatar default-contact-avatar" [ngStyle]="{'background-image': avatarImageSrc}"></div>
      </label>
    </div>
    <div class="name">{{loggedInUser.name}}</div>
    <div class="detail-row">
      <div class="detail-icon">
        <i class="fa fa-fw fa-envelope-o fa-2x"></i>
      </div>
      <div class="detail-display">
        <div class="detail">{{loggedInUser.email}}</div>
        <div class="detail-placeholder text-color-secondary">{{'WEBCLIENT.CONTACT.EMAIL' | translate}}</div>
      </div>
    </div>
    <div class="detail-row">
      <div class="detail-icon">
        <i class="fa fa-fw fa-comment-o fa-2x"></i>
      </div>
      <div class="detail-display" *ngIf="!isEditing">
        <div class="detail">
          {{loggedInUser.public_message}}
          <i class="fa fa-fw fa-pencil" (click)="startEditing()"></i>
        </div>
        <div class="detail-placeholder">{{'WEBCLIENT.CONTACT.PUBLIC_MESSAGE' | translate}}</div>
      </div>
      <div class="detail-display" *ngIf="isEditing">
        <div class="detail-input">
          <div class="input-group">
            <input 
              type="text" 
              class="form-control" 
              [placeholder]="'WEBCLIENT.CONTACT.PUBLIC_MESSAGE' | translate"
              [(ngModel)]="publicMsgInput"/>
            <span class="input-group-addon">
              <i class="fa fa-fw fa-check" (click)="savePublicMsg()"></i>
            </span>
            <span class="input-group-addon">
              <i class="fa fa-fw fa-times" (click)="discardPublicMsg()"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="detail-row" *ngIf="loggedInUser.skype_id">
      <div class="detail-icon">
        <embed src="assets/icons/e-profile.svg" alt="E-Profile"/>
      </div>
      <div class="detail-display">
        <a class="detail" href="{{loggedInUser.skype_id}}" target="_blank">{{loggedInUser.skype_id}}</a>
        <div class="detail-placeholder text-color-secondary">{{'WEBCLIENT.CONTACT.E_PROFILE' | translate}}</div>
      </div>
    </div>
  </div>
</div>