/**
 * BOC Strings
 * 
 * Created by Shan - 2018-05-28
 */

import { LanguageConstant } from "../../constants/language.constant";
import { LanguageString } from "../../strings/string-helper.service";

export const BocStrings: LanguageString[] = [
  {
    key: LanguageConstant.EN.key,
    strings: {
      LOGIN: {
        GET_STATE: {
          USERNAME: {
            INPUT_TIP: "Input your Staff ID",
            USERNAME: "Staff ID"
          },
          QR_CODE: {
            TIP: `<p>
            To use BOCHK EIM on your computer:
            </p>
            <ol>
              <li>Open BOCHK EIM on your phone</li>
              <li>(Android) Tap More... → Login web client<br>
                (iOS) Tap More... → About → Login web client</li>
              <li>Point your phone to this screen to capture the QR code</li>
            </ol>`
          }
        },
        SECOND_FACTOR: {
          STAFFID: {
            INPUT_TIP: "Re-enter your Staff ID"
          }
        },
        PASSWORD: {
          NOT_MATCH_POLICY: "At least {{NUM_CHAR}} characters, including upper case , lower case, numeric character"
        }
      }
    }
  },
  {
    key: LanguageConstant.ZH_CHT.key,
    strings: {
      LOGIN: {
        GET_STATE: {
          USERNAME: {
            INPUT_TIP: "輸入員工編號",
            USERNAME: "員工編號"
          },
          QR_CODE: {
            TIP: `<p>
            在您的電腦上使用EIM Web Client:
            </p>
            <ol>
              <li>在您的手機開啟EIM</li>
              <li>(Android) 點撃更多... → 登入網頁客戶端<br>
                (iOS) 點撃更多... → 關於 → 登入網頁客戶端</li>
              <li>以您的手機讀取螢幕上的二維條碼</li>
            </ol>`
          }
        },
        SECOND_FACTOR: {
          STAFFID: {
            INPUT_TIP: "再輸入員工編號"
          }
        },
        PASSWORD: {
          NOT_MATCH_POLICY: "最少 {{NUM_CHAR}} 字元密碼，組合包括大寫、小寫及數字"
        }
      }
    }
  },
  {
    key: LanguageConstant.ZH_CHS.key,
    strings: {
      LOGIN: {
        GET_STATE: {
          USERNAME: {
            INPUT_TIP: "输入员工编号",
            USERNAME: "员工编号"
          },
          QR_CODE: {
            TIP: `<p>
            在您的电脑上使用EIM Web Client:
            </p>
            <ol>
              <li>在您的手机开启EIM</li>
              <li>(Android) 点撃更多... → 登入网页客户端<br>
                (iOS) 点撃更多... → 关于 → 登入网页客户端</li>
              <li>以您的手机读取萤幕上的二维条码</li>
            </ol>`
          }
        },
        SECOND_FACTOR: {
          STAFFID: {
            INPUT_TIP: "再输入员工编号"
          }
        },
        PASSWORD: {
          NOT_MATCH_POLICY: "最少 {{NUM_CHAR}} 字元密码，组合包括大写、小写及数字"
        }
      }
    }
  }
];