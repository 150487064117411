import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WorkflowForm, WorkflowTeam, WorkflowType, WorkflowTypeStage, WorkflowFormCustomFieldAnswer } from '../model/workflow';
import { WorkflowService } from '../services/workflow.service';
import { CustomFieldsMapped, CustomFieldMagicStringMapping } from '../../shared/custom-field/models/custom-field';
import { FormHelperService } from '../helper/form-helper.service';

import * as _ from 'lodash';
import { WorkflowCellFormatService } from '../helper/workflow-cell-format.service';
import { ContactPickerService } from '../../contact-picker/contact-picker.service';
import { UserContact } from '../../../models/user-contact';
import { AccountService } from '../../../account/account.service';
import { TnNotificationService } from '../../../utilities/tn-notification/tn-notification.service';
import {TeamnoteConfigService} from '../../../configs/teamnote-config.service';

@Component({
  selector: 'tn-form-view',
  templateUrl: './form-view.component.html',
  styleUrls: ['./form-view.component.scss']
})
export class FormViewComponent implements OnInit {

  @Input() form: WorkflowForm = null;
  @Output() onBack: EventEmitter<boolean> = new EventEmitter<boolean>(null);

  team: WorkflowTeam = null;
  type: WorkflowType = null;
  activeStage: WorkflowTypeStage = null;
  activeStagePic: UserContact = null;

  mappedInputCustomFields: CustomFieldsMapped[] = [];
  allCustomFields: CustomFieldsMapped[] = [];
  staticStringMappings: CustomFieldMagicStringMapping = {};

  isInput: boolean = false;
  isActiveStage: boolean = false;
  isLastStage: boolean = false;
  isPic: boolean = false;
  isPicOfForm: boolean = false;
  
  previousStage: WorkflowTypeStage = null;
  nextStage: WorkflowTypeStage = null;

  hideTitleSideMenu: boolean;
  appendSubmitButton: boolean;

  constructor(
    private _teamnoteConfigService: TeamnoteConfigService,
    private _workflowService: WorkflowService,
    private _formHelperService: FormHelperService,
    private _workflowCellFormatService: WorkflowCellFormatService,
    private _accountService: AccountService,
    private _tnNotificationService: TnNotificationService
  ) { }

  ngOnInit() {
    this.hideTitleSideMenu = this._teamnoteConfigService.config.WEBCLIENT.WORK_FLOW.HIDE_FORM_DETAIL_MENU;
    this.appendSubmitButton = this._teamnoteConfigService.config.WEBCLIENT.WORK_FLOW.APPEND_FORM_SUBMIT_BTN;
  }

  ngOnChanges() {
    if (this.form) {
      this.prepareFromContent();
    }
  }

  prepareFromContent(): void {
    this.team = this._workflowService.getTeamByTeamId(this.form.team_id);
    this.type = this._workflowService.getTypeByTypeId(this.form.type_id);
    this.activeStage = this._workflowService.getStageOfTypeByTypeIdAndStageId(this.form.type_id, this.form.active_stage_id);

    this.staticStringMappings = this._workflowCellFormatService.getWorkflowStaticMagicStringMapping(this.form);

    this.allCustomFields = [];
    _.each(this.type.stages, (stage) => {
      let mapped = this._formHelperService.prepareMappedInputFieldsByTypeAndStageId(
        this.type,
        stage.stage_id,
        this.form.custom_fields
      );
      this.allCustomFields = _.union(this.allCustomFields, mapped);
    });

    this.isPicOfForm = this._workflowService.checkIfUserIsPicOfFormByFormId(this.form.form_id);

    this.parseStageContent();
  }

  parseStageContent(): void {
    if (!this.activeStage) {
      this.allCustomFields = [];
      this.mappedInputCustomFields = [];
      return;
    }
    this.isActiveStage = this.activeStage.stage_id == this.form.active_stage_id;
    if (!this.isActiveStage) {
      this.isPic = false;
    } else {
      this.isPic = this._workflowService.checkIfUserIsPicOfFormActiveStageByFormIdAndStageId(
        this.form.form_id,
        this.activeStage.stage_id
      );
      this.previousStage = this._workflowService.getPrevStageByCurrentStageIdAndTypeId(
        this.activeStage.stage_id,
        this.type.type_id
      );
      this.nextStage = this._workflowService.getNextStageByCurrentStageIdAndTypeId(
        this.activeStage.stage_id,
        this.type.type_id
      );
    }

    if (!this.nextStage) {
      this.isLastStage = true;
    } else {
      this.isLastStage = false;
    }

    let currentStageInputFields = this._formHelperService.prepareMappedInputFieldsByTypeAndStageId(
      this.type,
      this.activeStage.stage_id,
      this.form.custom_fields
    );

    this.mappedInputCustomFields = JSON.parse(JSON.stringify(currentStageInputFields));

    // pic
    this.activeStagePic = this._workflowService.getPicUserOfStageByFormIdAndStageId(this.form.form_id, this.activeStage.stage_id);
  }

  formViewBack(): void {
    this.onBack.emit(false);
  }

  rejectForm(): void {
    let prevStage = this._workflowService.getPrevStageByCurrentStageIdAndTypeId(
      this.activeStage.stage_id,
      this.type.type_id
    );

    this._workflowService.selectNewPicByTargetStageAndTeamId(
      prevStage,
      this.team.team_id,
      (userId) => {
        let picUserIds = [];
        if (userId) {
          picUserIds.push(userId);
        }
        this._workflowService.changeWorkflowFormStage(
          this.form.form_id,
          prevStage.stage_id,
          picUserIds,
          (resp) => {
            this.onBack.emit(true);
          }
        );
      }
    );

  }

  submitForm(): void {
    this._formHelperService.submitAWorkflowForm(
      this.mappedInputCustomFields,
      this.activeStage,
      this.type,
      this.team,
      () => {
        this.onBack.emit(true);
      },
      this.form
    );
  }

  reassignForm(): void {
    this._workflowService.selectNewPicByTargetStageAndTeamId(
      this.activeStage,
      this.team.team_id,
      (userId) => {
        let picUserIds = [];
        if (userId) {
          picUserIds.push(userId);
        }
        this._workflowService.changeWorkflowFormStage(
          this.form.form_id,
          this.activeStage.stage_id,
          picUserIds,
          (resp) => {
            this.onBack.emit(true);
          }
        );
      }
    );
  }

  addObserver(): void {
    this._workflowService.getWorkflowForm(
      this.form.form_id,
      (form: WorkflowForm) => {
        this.form.observer_ids = form.observer_ids;

        this._workflowService.selectNewObserversByTeamId(
          this.team.team_id,
          this.form.observer_ids,
          (userIds) => {
            let allObserverIds = _.union(this.form.observer_ids, userIds);
    
            this._workflowService.setObservers(
              this.form.form_id,
              allObserverIds,
              (resp) => {
                
              }
            );
          }
        );
      }
    );
  }

  acceptForm(): void {
    this._workflowService.changeWorkflowFormStage(
      this.form.form_id,
      this.activeStage.stage_id,
      [this._accountService.userId],
      (resp) => {
        this._tnNotificationService.showCustomInfoByTranslateKey("WEBCLIENT.WORKFLOW.DETAIL.ACCEPT_SUCCESS_MSG");
        this.onBack.emit(true);
      }
    );
  }

  goToStage(stage: WorkflowTypeStage): void {
    this.activeStage = stage;
    this.parseStageContent();
  }
}
