import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Translate
import { TranslateModule } from '@ngx-translate/core';

// File Upload
import { FileUploadModule } from 'ng2-file-upload';
import { FileUploaderComponent } from './file-uploader.component';
import { FileUploaderService } from './file-uploader.service';

@NgModule({
    imports: [
        CommonModule,
        FileUploadModule,
        TranslateModule
    ],
    providers: [],
    declarations: [FileUploaderComponent]
})
export class FileUploaderModule { }
