import {TeamNoteConfig} from '../../configs/models/config';

export const CeddConfig: TeamNoteConfig = {
  HOST: {
    DOMAIN: 'cedd',
  },
  WEBCLIENT: {
    WORK_FLOW: {
      HIDE_PAGE_TITLE: true,
      SHOW_PAGE_TITLE_BAR_IN_WEBAPP: true,
      HIDE_FORM_LIST_EXPORT_BTN: true,
      APPEND_FORM_SUBMIT_BTN: true,
    },
  },
};
