import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { StickerService } from './sticker.service';
import { StickerCategory, Sticker } from '../../models/sticker';
import { each } from 'lodash';
import { FileFactoryService } from '../file-factory/file-factory.service';

@Component({
  selector: 'tn-sticker',
  templateUrl: './sticker.component.html',
  styleUrls: ['./sticker.component.scss']
})
export class StickerComponent implements OnInit {

  @Output() onStickerClick = new EventEmitter<any>()

  stickerCategoryArr: StickerCategory[] = []
  displayingStickerArr: Sticker[] = []

  isShowingRecent: boolean = true;
  recentStickers: Sticker[] = []

  constructor(
    private _stickerService: StickerService,
    private _fileFactoryService: FileFactoryService
  ) { 
    this._stickerService.recentStickers$.subscribe(stickers => {
      this.recentStickers = stickers
    })
  }

  ngOnInit() {
    this.getStickerCategories()
  }

  getStickerCategories(): void {
    this._stickerService.getStickersCategories(
      (resp) => {
        this.stickerCategoryArr = resp
      },
      (err) => {

      }
    )
  }

  onRecentClick(): void {
    this.isShowingRecent = true;
  }

  onCategoryClick(category: StickerCategory): void {
    this.isShowingRecent = false;
    this._stickerService.getStickerByCategory(
      category.category_id,
      (resp: Sticker[]) => {
        each(resp, (s) => {
          this._fileFactoryService.getFileByAttachmentId(
            s.attachment_id,
            (imageUrl) => {
              s.stickerBgUrl = imageUrl
            },
            (err) => {},
            false,
            false,
            true
          )
        })
        this.displayingStickerArr = resp
      },
      (err) => {

      }
    )
  }

  onClick(sticker: Sticker): void {
    this._stickerService.addStickerToRecents(sticker)
    this.onStickerClick.emit(sticker);
  }

}
