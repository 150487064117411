import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ProfileComponent } from './profile.component';
import { ProfileService } from './profile.service';
import { UtilitiesModule } from '../../utilities/utilities.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UtilitiesModule
    ],
    declarations: [
        ProfileComponent
    ],
    providers: [
        ProfileService
    ]
})
export class ProfileModule { }
