import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Message, MessageMention } from '../../../models/message';
import { MessageTextBody } from '../../../models/message-text-body';
import { MessageAttachmentBody } from '../../../models/message-attachment-body';
import { MessageLocationBody } from '../../../models/message-location-body';

import { DataManagerService } from '../../services/data/data-manager.service';
import { ChatMessageService } from '../../services/data/messages/chat-message/chat-message.service';
import { ChatService } from '../../services/data/chat/chat.service';
import { AccountManagerService } from '../../services/account/account-manager.service';

import * as _ from 'lodash';
import { UserContactService } from '../../services/data/user-contact/user-contact.service';
import { TimestampService } from '../../../utilities/timestamp/timestamp.service';
import { TeamnoteApiService } from '../../../api/teamnote-api.service';
import { MessagesService } from '../../services/data/messages/messages.service';
import { InfoMessageService } from '../../services/data/messages/info-message/info-message.service';
import { UserContact } from '../../../models/user-contact';
import { TnNotificationService } from '../../../utilities/tn-notification/tn-notification.service';
import { MessageTypeConstant } from '../../../constants/message-type.constant';
import { TeamNoteApiConstant } from '../../../constants/api.constant';
import { TeamnoteConfigService } from '../../../configs/teamnote-config.service';
import { FileManagerService } from '../../../utilities/file-manager/file-manager.service';
import { AttachmentTypeConstant } from '../../../constants/attachment-type.constant';
import { MessageStickerBody } from '../../../models/message-sticker-body';

@Injectable()
export class ChatRoomService {

  requestedReadMessageIds: string[] = [];

  replyingMessage: Message = null;
  replyingMessage$:  BehaviorSubject<Message> = new BehaviorSubject<Message>(null);

  constructor(
    private _chatMessageService: ChatMessageService,
    private _accountManagerService: AccountManagerService,
    private _chatService: ChatService,
    private _dataManagerService: DataManagerService,
    private _fileManagerService: FileManagerService,
    private _userContactService: UserContactService,
    private _timestampService: TimestampService,
    private _teamnoteApiService: TeamnoteApiService,
    private _messagesService: MessagesService,
    private _infoMessageService: InfoMessageService,
    private _tnNotificationService: TnNotificationService,
    private _teamnoteConfigService: TeamnoteConfigService
  ) { }

  /**
   * Get full message list with date headers and unread message indicator
   * 
   * @param {Message[]} msgs - real message array
   * @param {Message} lastMessagePointer - current last message pointer
   * @returns {Message[]} - full message list
   * @memberof ChatRoomService
   */
  getParsedMessages(msgs: Message[], lastMessagePointer: Message): Message[] {
    let parsedMessages = this.insertDateHeaders(msgs);

    // put all sending message at the back
    let sendingMessages = _.filter(parsedMessages, (m) => {
      return !m.message_id;
    });
    parsedMessages = _.union(_.difference(parsedMessages, sendingMessages), sendingMessages);

    // Insert new message indicator after sorting
    parsedMessages = this.insertNewMessageIndicator(parsedMessages, lastMessagePointer);

    return parsedMessages;
  }

  /**
   * Insert date headers to messages
   * 
   * @param {Message[]} msgs - real messages array
   * @returns {Message[]} - message list with date headers
   * @memberof ChatRoomService
   */
  insertDateHeaders(msgs: Message[]): Message[] {
    if (msgs && msgs.length > 0) {
      let arr = [];
      let currentDateStr = '';

      // Handle the first message first, in order to get a starting pointer for comparisons
      let firstMsg = msgs[0];
      currentDateStr = this.getDateStrOfMsg(firstMsg);
      arr.push(this.getDateRowObj(currentDateStr));
      arr.push(this._chatMessageService.getSingleParsedMsg(firstMsg));

      // Start looping through messages from 2nd message
      for (var i = 1; i < msgs.length; i++) {
        let m = msgs[i];

        // Get the dateStr to compare if it is current date
        let dateStr = this.getDateStrOfMsg(m);

        if (dateStr != currentDateStr) {
          // If dateStr is different, set the currentDateStr pointer to latest.
          currentDateStr = dateStr;
          arr.push(this.getDateRowObj(currentDateStr));
        }
        arr.push(this._chatMessageService.getSingleParsedMsg(m));

      }
      return arr;
    } else {
      return msgs;
    }
  }

  /**
   * Get date str of message
   * 
   * @param {Message} msg - target message
   * @returns {string} - date str
   * @memberof ChatRoomService
   */
  getDateStrOfMsg(msg: Message): string {
    if (msg.timestamp) {
      return this._timestampService.getMessageDateStringInChatrooom(msg.timestamp);
    } else {
      return '';
    }
  }

  /**
   * Get date row object by dateStr
   * 
   * @param {string} date - dateStr
   * @returns {Message} - date row object
   * @memberof ChatRoomService
   */
  getDateRowObj(date: string): Message {
    let row = <Message>{};
    row.date = date;
    row.message_id = date;
    row.timestamp = date;
    return row;
  }

  /**
   * Try to insert "UNREAD MESSAGES" indicator
   * 
   * @param {Message[]} msgs - all dated messages
   * @param {Message} lastMessagePointer - current last message pointer
   * @returns {Message[]} - full parsed list (with date row and UNREAD MESSAGES)
   * @memberof ChatRoomService
   */
  insertNewMessageIndicator(msgs: Message[], lastMessagePointer: Message): Message[] {
    // Find the index of last message pointer first
    let lastMessageIndex;
    if (lastMessagePointer) {
      lastMessageIndex = _.indexOf(msgs, lastMessagePointer);
    }

    // The first potential target index is the next message
    let curIndex;
    if (lastMessageIndex) {
      // Find the next "unread" message, starting from the first potential message
      for (let i = lastMessageIndex + 1; i <= msgs.length; i++) {
        curIndex = i;
        let m = msgs[i];
        if (!m) {
          break;
        }
        if (!m.date && !m.isSentByMe) {
          // If this message is not sent by "me", this is the tartet newMsg bar position
          break;
        }
      }
    } else {
      // If no last message pointer, use the old logic
      for (let i = msgs.length - 1; i >= 0; i--) {
        let m = msgs[i];
        if (m.date) {
          curIndex = i;
          continue;
        }
        if (!m.isReadByMe && !m.isSentByMe) {
          curIndex = i;
          continue;
        } else {
          break;
        }
      }
    }

    // If target index exists and less than original message length, insert newMsg bar at target index
    if (curIndex && curIndex < msgs.length) {
      let newMsg = <Message>{};
      newMsg.isNewMsgIndicator = true;
      msgs.splice(curIndex, 0, newMsg);
    }

    return msgs;
  }

  // AMQP
  /**
   * Subscribe to chat
   * 
   * @param {string} chatId - target chat id
   * @memberof ChatRoomService
   */
  subscribeChat(chatId: string): void {
    this._dataManagerService.subscribeChat(chatId);
  }

  /**
   * Load chat history locally, find target block size and get the latest N messages
   *
   * @param {string} chatId
   * @param {number} currentMessageBlockSize
   * @returns {Message[]} - chat messages with loaded N messages before current block size
   * @memberof ChatRoomService
   */
  loadLocalChatHistory(chatId: string, currentMessageBlockSize: number, noNeedAddLoadHistorySize?: boolean): Message[] {
    let targetSize = currentMessageBlockSize + this._teamnoteConfigService.config.WEBCLIENT.AMQP.CHAT_LOAD_HISTORY_SIZE;

    if (noNeedAddLoadHistorySize) {
      targetSize = currentMessageBlockSize;
    }

    let msgs = this._chatMessageService.getLastestChatMessagesUnderChatByChatIdAndSize(chatId, targetSize);
    return msgs;
  }

  /**
   * Load chat history
   * 
   * @param {string} chatId - target chat id
   * @param {Function} callback - callback
   * @memberof ChatRoomService
   */
  loadChatHistory(chatId: string, callback: Function, size?: number): void {
    let firstMsg = this._chatMessageService.getFirstMessageOfChat(chatId);
    let timestamp = null;
    if (firstMsg) {
      timestamp = firstMsg.timestamp;
    }
    this._dataManagerService.loadChatHistory(
      chatId, 
      timestamp, 
      size ? size : this._teamnoteConfigService.config.WEBCLIENT.AMQP.CHAT_LOAD_HISTORY_SIZE, 
      callback
    );
    this._chatService.setChatIsLoadedHistory(chatId);
  }

  removeLocalChatHistoryByChatId(chatId: string): void {
    let allChatMessages = this._chatMessageService.getAllChatMessagesUnderChat(chatId);
    _.each(allChatMessages, (msg) => {
      this._messagesService.deleteMessageByMessageId(msg.message_id);
    });
    this._chatMessageService.removeChatMessageByChatId(chatId);
  }

  /**
   * Send message hub, separate by message type
   * 
   * @param {number} messageType - target sending message's type
   * @param {string} chatId - target chat id
   * @param {*} content - content of message
   * @param {UserContact} [whisperingUser] - target whisper user
   * @param {Message} [replyingMessage] - target replying message
   * @param {boolean} [isToggledSecureMessage] - if message is secured
   * @param {Function} [callback] - custom callback
   * @memberof ChatRoomService
   */
  sendMessgeHub(messageType: number, chatId: string, content: any, whisperingUser?: UserContact, replyingMessage?: Message, isToggledSecureMessage?: boolean, callback?: Function, mentionedMembers?: MessageMention[], importantLevel?: number, isSms?: boolean): void {
    switch (messageType) {
      case MessageTypeConstant.TEXT:
        this.sendTextMessage(chatId, content, whisperingUser, replyingMessage, isToggledSecureMessage, callback, mentionedMembers, importantLevel, isSms);
        break;
      case MessageTypeConstant.ATTACHMENT:
        this.uploadSendAttachment(chatId, content, whisperingUser, replyingMessage, isToggledSecureMessage, callback);
        break;
      case MessageTypeConstant.LOCATION:
        this.sendLocationMessage(chatId, content, whisperingUser, replyingMessage, isToggledSecureMessage, callback);
        break;
      case MessageTypeConstant.STICKER:
        this.sendStickerMessage(chatId, content, whisperingUser, replyingMessage, isToggledSecureMessage, callback);
        break;
    }
  }

  // Send Text
  getTextMessageBody(text: string, replyingMessage?: Message, isToggledSecureMessage?: boolean, mentionedMembers?: MessageMention[], importantLevel?: number, isSms?: boolean): MessageTextBody {
    // Check mentioned member again
    let validMentions: MessageMention[] = [];
    let tempText = _.clone(text);

    if (mentionedMembers) {
      for (let i = 0; i < mentionedMembers.length; i++) {
        let mention = mentionedMembers[i];
        if (tempText.substr(mention.location, mention.length) == mention.name) {
          validMentions.push(mention);
          continue;
        }
        let strIndex = tempText.indexOf(mention.name);
        if (strIndex == -1) {
          // name doesn't exist
          continue;
        }
        while (strIndex != -1) {
          validMentions.push({
            location: strIndex,
            length: mention.name.length,
            name: mention.name,
            user_id: mention.user_id
          });
          tempText = tempText.substr(0, strIndex) + _.pad("-", mention.name.length) + tempText.substr(strIndex + mention.name.length);
          strIndex = tempText.indexOf(mention.name);
        }
      }
    }

    // console.error(tempText, validMentions);

    return {
      message: text,
      comment_parent_id: replyingMessage ? replyingMessage.message_id : "",
      is_encrypted: isToggledSecureMessage ? 1 : 0,
      message_mention: _.uniq(validMentions),
      important_level: importantLevel,
      force_fallback: isSms ? 2 : 0
    };
  }
  /**
   * Send text message
   * 
   * @param {string} chatId - target chat id
   * @param {string} text - text message content
   * @param {UserContact} [whisperingUser] 
   * @param {Message} [replyingMessage] 
   * @param {boolean} [isToggledSecureMessage] 
   * @param {Function} [callback] 
   * @memberof ChatRoomService
   */
  sendTextMessage(chatId: string, text: string, whisperingUser?: UserContact, replyingMessage?: Message, isToggledSecureMessage?: boolean, callback?: Function, mentionedMembers?: MessageMention[], importantLevel?: number, isSms?: boolean): void {
    let body = this.getTextMessageBody(text, replyingMessage, isToggledSecureMessage, mentionedMembers, importantLevel, isSms);
    this._dataManagerService.sendMessageToChat(chatId, body, MessageTypeConstant.TEXT, whisperingUser, callback);
  }

  // Send Attachment
  /**
   * Upload and send attachment
   * 
   * @param {string} chatId - target chat id
   * @param {File[]} files - all to be uploaded files
   * @param {UserContact} [whisperingUser] 
   * @param {Message} [replyingMessage] 
   * @param {boolean} [isToggledSecureMessage] 
   * @param {Function} [callback] 
   * @memberof ChatRoomService
   */
  uploadSendAttachment(chatId: string, files: File[], whisperingUser?: UserContact, replyingMessage?: Message, isToggledSecureMessage?: boolean, callback?: Function): void {
    // Upload first
    _.each(files, (f) => {
      this._fileManagerService.apiUploadFile(
        f, 
        (fileId, imageCaption) => {
          // Callback function of upload, send message now!
          this.sendAttachmentMessage(chatId, fileId, f, imageCaption, whisperingUser, replyingMessage, isToggledSecureMessage, callback);
        },
        true
      );
    });
  }

  getAttachmentMessageBody(fileId: string, file: File, imageCaption: string, replyingMessage?: Message, isToggledSecureMessage?: boolean, filename?: string): MessageAttachmentBody {
    return {
      attachment_id: fileId,
      size: file.size,
      content_type: file.type,
      filename: filename || file.name,
      message: imageCaption,
      comment_parent_id: replyingMessage ? replyingMessage.message_id : "",
      is_encrypted: isToggledSecureMessage ? 1 : 0
    }
  }
  /**
   * Send attachment message
   * 
   * @param {string} chatId - target chat id
   * @param {string} fileId - file id
   * @param {File} file - file
   * @param {string} imageCaption - image caption
   * @param {UserContact} [whisperingUser] 
   * @param {Message} [replyingMessage] 
   * @param {boolean} [isToggledSecureMessage] 
   * @param {Function} [callback] 
   * @memberof ChatRoomService
   */
  sendAttachmentMessage(chatId: string, fileId: string, file: any, imageCaption: string, whisperingUser?: UserContact, replyingMessage?: Message, isToggledSecureMessage?: boolean, callback?: Function, filename?: string): void {
    let body = this.getAttachmentMessageBody(fileId, file, imageCaption, replyingMessage, isToggledSecureMessage, filename);
    this._dataManagerService.sendMessageToChat(chatId, body, MessageTypeConstant.ATTACHMENT, whisperingUser, callback);
  }
  // Send Location
  getLocationMessageBody(location: MessageLocationBody, replyingMessage?: Message, isToggledSecureMessage?: boolean): MessageLocationBody {
    if (replyingMessage) {
      location.comment_parent_id = replyingMessage ? replyingMessage.message_id : "";
    }
    location.is_encrypted = isToggledSecureMessage ? 1 : 0;
    return location;
  }
  /**
   * Send location message
   * 
   * @param {string} chatId - target chat id
   * @param {MessageLocationBody} location - parsed location body
   * @param {UserContact} [whisperingUser] 
   * @param {Message} [replyingMessage] 
   * @param {boolean} [isToggledSecureMessage] 
   * @param {Function} [callback] 
   * @memberof ChatRoomService
   */
  sendLocationMessage(chatId: string, location: MessageLocationBody, whisperingUser?: UserContact, replyingMessage?: Message, isToggledSecureMessage?: boolean, callback?: Function): void {
    location = this.getLocationMessageBody(location, replyingMessage, isToggledSecureMessage);
    this._dataManagerService.sendMessageToChat(chatId, location, MessageTypeConstant.LOCATION, whisperingUser, callback);
  }

  getStickerMessageBody(sticker: MessageStickerBody, replyingMessage?: Message, isToggledSecureMessage?: boolean): MessageStickerBody {
    if (replyingMessage) {
      sticker.comment_parent_id = replyingMessage ? replyingMessage.message_id : "";
    }
    sticker.is_encrypted = isToggledSecureMessage ? 1 : 0;
    return sticker;
  }
  sendStickerMessage(chatId: string, sticker: MessageStickerBody, whisperingUser?: UserContact, replyingMessage?: Message, isToggledSecureMessage?: boolean, callback?: Function): void {
    sticker = this.getStickerMessageBody(sticker, replyingMessage, isToggledSecureMessage);
    this._dataManagerService.sendMessageToChat(chatId, sticker, MessageTypeConstant.STICKER, whisperingUser, callback);
  }

  // Send Read
  /**
   * Try to send batch read under chat
   * 
   * @param {string} chatId - target chat id
   * @memberof ChatRoomService
   */
  sendBatchReadUnderChat(chatId: string): void {
    // find all unread message id under chat
    let messages = this._chatMessageService.getAllChatMessagesUnderChat(chatId);
    let messageIds = [];
    _.each(messages, (m) => {
      if (!m.isSentByMe) {
        if (!this._infoMessageService.checkIfMessageIsReadByMe(m.message_id)) {
          // Only send read for those not yet requested
          if (_.indexOf(this.requestedReadMessageIds, m.message_id) === -1) {
            // If enabled AUDIO_MESSAGE_PLAY_TO_READ, do not send audio messages read during batch read
            let isAddToBatchRead = true;

            if (this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.AUDIO_MESSAGE_PLAY_TO_READ) {
              // check if message is audio message.
              if (m.type == MessageTypeConstant.ATTACHMENT) {
                let attachmentType = this._fileManagerService.getAttachmentType(m.attachments[0].attachment_id);
                if (attachmentType == AttachmentTypeConstant.AUDIO) {
                  isAddToBatchRead = false;
                }
              }
            }
            // m.isLocalRead = true;

            if (isAddToBatchRead) {
              messageIds.push(m.message_id);
              this.requestedReadMessageIds.push(m.message_id);
            }
          } else {
            // rechecking the unread message after WebSocket is connected
            // i.e the duplicated messages are the messages that need to re-send read again
            messageIds.push(m.message_id);
          }
        }
      }
    });
    if (messageIds.length) {
      this._messagesService.sendMessageRead(chatId, messageIds);
    }
  }

  sendReadForAudioMessage(messageId: string): void {
    if (!messageId) {
      return;
    }
    if (!this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.AUDIO_MESSAGE_PLAY_TO_READ) {
      return;
    }
    this.sendReadForSingleMessage(messageId);
  }

  sendReadForSingleMessage(messageId: string): void {
    let msg = this._messagesService.getMessageByMessageId(messageId);
    if (!msg) {
      return;
    }
    if (!msg.isSentByMe) {
      if (!this._infoMessageService.checkIfMessageIsReadByMe(messageId)) {
        if (_.indexOf(this.requestedReadMessageIds, messageId) === -1) {
          this.requestedReadMessageIds.push(messageId);
        } 

        this._messagesService.sendMessageRead(msg.chat_ids[0], [messageId]);
      }
    }
  }

  sendAckForSingleMessage(m: Message): void {
    let msg = this._messagesService.getMessageByMessageId(m.message_id);
    if (!msg) {
      return;
    }
    if (!msg.isSentByMe) {
      if (!this._infoMessageService.checkIfMessageIsAcked(m)) {

        this._messagesService.sendMessageAck(msg.chat_ids[0], [m.message_id]);
      }
    }
  }

  // Forward
  /**
   * Forward behavior:

   *  if forward to one contact only, after press forward, go into that contact's chatroom (just like current behavior)
   *  if forward to multiple contacts, after press forward, show a message that messages are forwarded (Messages forwarded / 訊息已轉發 / 信息已转发), then just go back to the original chatroom
   * @param targets 
   * @param message 
   */
  forwardMessage(targets: any[], message: Message, messageBody?: any, messageType?: any, callback?: Function): void {
    let body = messageBody;
    let type = messageType;
    if (message) {
      body = JSON.parse(message.body);
      type = message.type;
    }
    delete body.comment_parent_id;

    // remove the 'acknowledgement' field when forwaring message
    if (body.acknowledgement) {
      delete body.acknowledgement
    }

    let isSingleTarget = targets.length == 1;
    let targetChatRoom = null;

    _.each(targets, (target) => {
      if (target.chat_id) {
        // Target is chat group, send directly
        this._dataManagerService.sendMessageToChat(target.chat_id, body, type);
        targetChatRoom = target;
      } else {
        // Target is user contact, find target chat
        let chat = this._chatService.getIndividualChatByUserId(target.user_id);
        if (chat) {
          // Chat exist already, send directly
          this._dataManagerService.sendMessageToChat(chat.chat_id, body, type);
          targetChatRoom = chat;
        } else {
          // Chat doesn't exist, declare first
          this._chatService.declareIndividualChatByUserId(target.user_id, (chat) => {
            targetChatRoom = chat;
            this._dataManagerService.sendMessageToChat(chat.chat_id, body, type);
          }, true);
        }
      }
    });

    if (isSingleTarget) {
      // clear the target message of searching mode / starred messages
      this._chatService.updateTargetMessageSubject(null)
      this._chatService.updateActiveChatSubject(targetChatRoom);
      
      if (callback) {
        callback()
      }
    } else {
      this._tnNotificationService.showCustomInfoByTranslateKey('WEBCLIENT.CHATROOM.FORWARD.SUCCESS_MSG');
    }
  }

  // API
  // Recall
  /**
   * Recall message 
   * 
   * @param {string} messageId - target recall mesasge id
   * @memberof ChatRoomService
   */
  recallMessage(messageId: string): void {
    let url = TeamNoteApiConstant.MESSAGE.RECALL;
    let params = {
      message_id: messageId
    };
    this._teamnoteApiService.callApi(url, params,
      resp => {
        this._tnNotificationService.showCustomInfoByTranslateKey('WEBCLIENT.CHATROOM.RECALL.SUCCESS');
      },
      err => {
        this._tnNotificationService.showCustomErrorByTranslateKey('WEBCLIENT.CHATROOM.RECALL.FAIL');
      }
    );
  }
  // Report
  /**
   * Report message
   * 
   * @param {string} messageId - target report message id
   * @memberof ChatRoomService
   */
  reportMessage(messageId: string): void {
    let url = TeamNoteApiConstant.MESSAGE.REPORT;
    let params = {
      message_id: messageId
    };
    this._teamnoteApiService.callApi(url, params,
      resp => {
        // TODO: show success msg
      },
      err => {

      }
    );
  }

  // Export message
  exportChatMessage(chatId: string, fromTime: string, toTime: string, success: Function, failure: Function, exportRaw: number, withTimestamp: number): void {
    let url = TeamNoteApiConstant.MESSAGE.EXPORT;
    let params = {
      chat_id: chatId,
      message_time: fromTime,
      to_message_time: toTime,
      export_raw: exportRaw,
      with_timestamp: withTimestamp
    };
    this._teamnoteApiService.callApi(url, params, success, failure);
  }

  // Delete message
  deleteMessage(chatId: string, messageId: string): void {
    this._messagesService.sendMessageDelete(chatId, [messageId]);
  }

  starMessage(chatId: string, messageId: string): void {
    this._messagesService.sendMessageStar(chatId, [messageId]);
  }

  unstarMessage(chatId: string, messageId: string): void {
    this._messagesService.sendMessageUnStar(chatId, [messageId]);
  }

  // Send testing messages
  sendTestingMessageToChatByChatIdAndCount(chatId: string, count: number): void {
    if (count == 0 || !chatId) {
      return;
    }

    let randomMessage = Math.random().toString(36).substr(2);
    this.sendTextMessage(chatId, count + " - " + randomMessage);

    setTimeout(() => {
      this.sendTestingMessageToChatByChatIdAndCount(chatId, --count);
    }, 100);
  }

  getChatTags(chatId: string, success: Function, failure: Function): void {
    let url = TeamNoteApiConstant.GROUP.TAGS;
    let params = {
      chat_id: chatId
    };
    this._teamnoteApiService.callApi(url, params, success, failure);
  }

  updateReplyingMessage(m: Message) {
    this.replyingMessage = m;
    this.replyingMessage$.next(m);
  }

}
