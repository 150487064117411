import { Injectable } from '@angular/core';
import { TnDialogService } from '../../../utilities/tn-dialog/tn-dialog.service';
import { CorporateMaterialPickerComponent } from './corporate-material-picker.component';

@Injectable()
export class CorporateMaterialPickerService {

  constructor(
    private _tnDialogService: TnDialogService
  ) { }

  openCorporateMaterialPicker(header: string, isFile: boolean, isUrl: boolean, isEbook: boolean, callback: Function, fileAllowTypes?: string[]) {
    let dialogRef = this._tnDialogService.openTnDialog(CorporateMaterialPickerComponent, {
      header: header,
      isFile: isFile,
      isUrl: isUrl,
      isEbook: isEbook,
      callback: callback,
      fileAllowTypes: fileAllowTypes
    }, {
      // panelClass: "no-padding-dialog-panel"
    });
  }
}
