import { Injectable } from '@angular/core';
import { TeamnoteApiService } from '../../api/teamnote-api.service';
import { LocalStorageManagerService } from '../../utilities/local-storage/local-storage-manager.service';
import { TeamNoteLocalStorageKeyConstants } from '../../constants/local-storage-key.constant';
import { TeamNoteApiConstant } from '../../constants/api.constant';

@Injectable({
  providedIn: 'root'
})
export class VideoStreamingService {

  constructor(
    private _teamnoteApiService: TeamnoteApiService,
    private _localStorageManagerService: LocalStorageManagerService
  ) { }

  getGlobalStreamingChatId(): string {
    return `news.company.${this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.DOMAIN)}`;
  }

  getSrsActiveStreams(success: Function, failure: Function): void {
    let url = TeamNoteApiConstant.VIDEO_STREAMING.ACTIVE_STREAMS;
    let params = {
      chat_id: this.getGlobalStreamingChatId()
    };
    this._teamnoteApiService.callApi(url, params, success, failure);
  }

  getParsedStreamingVideoUrl(stream: any): string {
    return stream += `?access_token=${this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN)}`;
  }
}
