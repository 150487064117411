export const ZhChtCustomFieldStrings = {
  LIST_FORMAT: {
    IMAGE: {
      ONE: "圖片",
      OTHER: " 圖片"
    },
    FILE: {
      ONE: "文件",
      OTHER: " 文件"
    },
    USER: {
      ONE: " 用戶",
      OTHER: " 用戶"
    },
    ATTACHMENT: {
      ONE: " 附件",
      OTHER: " 附件"
    },
    SIGNATURE: {
      SIGNED: "已簽名",
      NOT_SIGNED: "沒有簽名"
    }
  },

  ATTACHMENT: {
    SELECT_TN_CORPORATE_MATERIAL: "從團信檔案分享中選擇"
  }
}