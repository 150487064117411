<div class="tn-content">
  <div class="tn-content-full">
    <tn-page-title [title]="'WEBCLIENT.MENU.' + pageTitleKey | translate"></tn-page-title>

    <div class="news-category-list">
      <div virtualScroller
        #newsCategoryWrapper
        class="news-category-wrapper"
        *ngIf="newsCategories.length > 0"
        [ssrChildHeight]="80"
        [items]="newsCategories"
        (vsUpdate)="scrollItems = $event">
        <ng-container *ngFor="let category of scrollItems">
          <tn-news-category-cell
            [category]="category"
            (categoryOnClick)="categoryOnClick($event)">
          </tn-news-category-cell>
        </ng-container>
      </div>

      <ng-container *ngIf="newsCategories.length == 0">
        <tn-empty-page></tn-empty-page>
      </ng-container>
    </div>
  </div>
</div>