export class WebViewCustomRouteParam {
  link?: string;
  title?: string;
  titleTranslateKey?: string;

  constructor(link?: string, title?: string, titleTranslateKey?: string) {
    this.link = link;
    this.title = title;
    this.titleTranslateKey = titleTranslateKey;
  }
}