<div class="text-message">
  <!-- Make it in one line only instead of normal structure in order to remove "space" between each span-->
  <ng-container *ngFor="let text of texts">
    <ng-container *ngIf="text.isMention">
      <span class="text multiline-text-display text-clickable" (click)="mentionClick(text)">{{text.text}}</span>
    </ng-container>
    <ng-container *ngIf="text.isHashtag">
      <span class="text multiline-text-display text-clickable" (click)="hashtagClick(text)">{{text.text}}</span>
    </ng-container>
    <ng-container *ngIf="text.isStr && !text.isMention && !text.isHashtag"><span class="text multiline-text-display preserve-spaces">{{text.text}}</span></ng-container>
    <ng-container *ngIf="!text.isStr && !text.isMention && !text.isHashtag"><span class="text multiline-text-display" [innerHTML]="text.text"></span>
    </ng-container>
  </ng-container>
</div>
