import { Injectable } from '@angular/core';

import { TeamnoteConfigService } from '../../configs/teamnote-config.service';
import { HttpClient } from '@angular/common/http';
import { TN_CONFIG_MODEL } from '../../configs/models/config';
import { LoggerService } from '../logger/logger.service';


var addMinutes = require('date-fns/add_minutes');
var isAfter = require('date-fns/is_after');
import { TnNotificationService } from '../tn-notification/tn-notification.service';

@Injectable()
export class VersionCheckingService {

  lastVersionCheckDate: Date = null;

  latestVersion: string = '';

  dialogRef: any = null;

  constructor(
    private _teamnoteConfigService: TeamnoteConfigService,
    private _http: HttpClient,
    private _loggerService: LoggerService,
    private _tnNotificationService: TnNotificationService
  ) { }

  setLastVersionCheckingTimestamp() {
    this._loggerService.debug("Setting last version checking timestamp...");
    this.lastVersionCheckDate = new Date();
  }

  checkVersion() {
    if (this.dialogRef) {
      return;
    }
    
    if (isAfter(addMinutes(this.lastVersionCheckDate, this._teamnoteConfigService.config.GENERAL.VERSION_CHECK_INTERVAL_MIN), new Date())) {
      return;
    }

    this._loggerService.debug("Getting latest TN_CONFIG.json...");
    this._http.get(this._teamnoteConfigService.configJsonPath)
      .subscribe(
        (configJson: TN_CONFIG_MODEL) => {
          this.performRealVersionChecking(configJson);
        },
        (err) => {
          this._loggerService.error("TN_CONFIG.json not found");
          // Do nothing
        }
      );
  }

  performRealVersionChecking(configJson: TN_CONFIG_MODEL): void {
    if (configJson && configJson.VERSION && configJson.VERSION != this._teamnoteConfigService.config.VERSION.VERSION) {
      this._loggerService.debug("Newer version exists! Prompt update dialog.");
      this.latestVersion = configJson.VERSION;
      this.openVersionUpdateDialog();
    } else {
      this._loggerService.debug("No version update");
      this.setLastVersionCheckingTimestamp();
    }
  }

  openVersionUpdateDialog() {
    this._tnNotificationService.showAlert(
      null,
      "GENERAL.NEW_VERSION_MSG",
      null,
      "GENERAL.CONFIRM",
      () => {
        location.reload();
      }
    );
  }
}
