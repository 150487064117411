import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'tn-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent implements OnInit {
  @Input() audioUrl: any;
  @Input() mimeType: any;
  
  constructor(
    private _sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.audioUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.audioUrl);
  }

  ngOnChanges() {

  }

}
