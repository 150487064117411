export const EmojiAppConstant = {
  CATEGORIES: {
    Recents: {
      emoji: '🕒',
      description: 'three o’clock',
      category: 'Recents'
    },
    'Smileys & Emotion': {
      emoji: '😀',
      description: 'grinning face',
      category: 'Smileys & Emotion'
    },
    'People & Body': {
      emoji: '👌',
      description: 'OK hand',
      category: 'People & Body'
    },
    'Animals & Nature': {
      emoji: '🐻',
      description: 'bear',
      category: 'Animals & Nature'
    },
    'Food & Drink': {
      emoji: '🍔',
      description: 'hamburger',
      category: 'Food & Drink'
    },
    'Travel & Places': {
      emoji: '🌍',
      description: 'globe showing Europe-Africa',
      category: 'Travel & Places'
    },
    Activities: {
      emoji: '⚽',
      description: 'soccer ball',
      category: 'Activities'
    },
    Objects: {
      emoji: '💡',
      description: 'light bulb',
      category: 'Objects'
    },
    Symbols: {
      emoji: '⬆️',
      description: 'up arrow',
      category: 'Symbols'
    },
    Flags: {
      emoji: '🏳️',
      description: 'white flag',
      category: 'Flags'
    }
  },
  EMOJI_MENU: {
    LABEL: 'EMOJI_MENU',
    EMOJI: [
      {
        emoji: '❤️',
        description: 'red heart'
      },
      {
        emoji: '👍',
        description: 'thumbs up'
      },
      {
        emoji: '👎',
        description: 'thumbs down'
      },
      {
        emoji: '😂',
        description: 'face with tears of joy'
      },
      {
        emoji: '😯',
        description: 'hushed face'
      },
      {
        emoji: '😢',
        description: 'crying face'
      }
    ]
  }
}
