import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BsDatepickerConfig } from '../../../../../node_modules/ngx-bootstrap/datepicker';
import { Store, StoreReportType } from '../models/job-report';
import { JobReportService } from '../job-report.service';

import * as _ from 'lodash';
var subDays = require('date-fns/sub_days');
import { ClientReportExportApiParam } from './report-export-param';
import { TimestampService } from '../../../utilities/timestamp/timestamp.service';
import { CronjobTrackerService } from '../../../utilities/cronjob-tracker/cronjob-tracker.service';
import { CronJobTaskInitialResponse, CronJobType, CronjobExportReportJob } from '../../../utilities/cronjob-tracker/models/cronjob';
import { TnNotificationService } from '../../../utilities/tn-notification/tn-notification.service';

@Component({
  selector: 'tn-report-export',
  templateUrl: './report-export.component.html',
  styleUrls: ['./report-export.component.scss']
})
export class ReportExportComponent implements OnInit {

  isJob: boolean = false;

  // Time
  timePeriod: Date[] = [subDays(new Date(), 7), new Date()];
  dateInputConfig: Partial<BsDatepickerConfig>;

  // Options
  storeOptions: Store[];
  selectedStore: any;

  typeOptions: StoreReportType[];
  selectedType: any;

  allOption: any = {isAll: true};

  constructor(
    public dialogRef: MatDialogRef<ReportExportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _jobReportService: JobReportService,
    private _timestampService: TimestampService,
    private _cronjobTrackerService: CronjobTrackerService,
    private _tnNotificationService: TnNotificationService
  ) { }

  ngOnInit() {
    this.dialogRef.updateSize('50vw');

    this.dateInputConfig = Object.assign({}, { 
      containerClass: "theme-default",
      rangeInputFormat: "YYYY-MM-DD"
    });

    this.isJob = this.data.isJob;

    this.getOptions();
  }

  getOptions(): void {
    this._jobReportService.getStoreReportOptions(
      (resp) => {
        this.storeOptions = this._jobReportService.storeOptions;
        this.storeOptions = _.union([this.allOption], this.storeOptions);
        this.selectedStore = this.allOption;

        this.typeOptions = this._jobReportService.typeOptions;
        this.typeOptions = _.union([this.allOption], this.typeOptions);
        this.selectedType = this.allOption;
      }
    );
  }

  cancel(): void {
    this.dialogRef.close();
  }


  export(): void {
    let param = new ClientReportExportApiParam(
      this.isJob,
      this._timestampService.getStartOfDateSecondStringByDateObj(this.timePeriod[0]),
      this._timestampService.getEndOfDateSecondStringByDateObj(this.timePeriod[1]),
      this.selectedStore.isAll ? null : this.selectedStore.store_id,
      this.selectedType.isAll ? null : this.selectedType.type_id
    );

    this._jobReportService.exportFormOrJob(
      param,
      this.isJob,
      (resp: CronJobTaskInitialResponse) => {
        let timeDisplay = this._timestampService.dateObjToTimeDisplay(this.timePeriod[0], false) + ' - ' + this._timestampService.dateObjToTimeDisplay(this.timePeriod[1], false);

        let fileNameComponents = [
          this.isJob ? "export_job" : "export_report",
          timeDisplay
        ];
        if (!this.selectedStore.isAll) {
          fileNameComponents.push(this.selectedStore.name);
        }
        if (!this.selectedType.isAll) {
          fileNameComponents.push(this.selectedType.name);
        }

        this._cronjobTrackerService.startTrackingCronjob(
          resp.job_id,
          CronJobType.EXPORT_REPORT_JOB,
          new CronjobExportReportJob(
            this.isJob,
            timeDisplay,
            this.selectedStore.isAll ? "GENERAL.ALL" : null,
            !this.selectedStore.isAll ? this.selectedStore.name : null,
            this.selectedType.isAll ? "GENERAL.ALL" : null,
            !this.selectedType.isAll ? this.selectedType.name : null,
            fileNameComponents.join("_"),
            "WEBCLIENT.JOB_REPORT.EXPORT.EXPORT_READY_MSG"
          ),
          null,
          this.isJob ? "WEBCLIENT.JOB_REPORT.EXPORT.JOB" : "WEBCLIENT.JOB_REPORT.EXPORT.REPORT"
        );
        this._tnNotificationService.showCustomInfoByTranslateKey("WEBCLIENT.JOB_REPORT.EXPORT.IN_PROGRESS_MSG");
        this.cancel();
      }
    );
  }

}
