import {Injectable} from '@angular/core';

import {TeamnoteApiService} from '../../api/teamnote-api.service';

import * as _ from 'lodash';
import * as FileSaver from 'file-saver';
// import {unescape} from 'querystring';
// import {unescape} from 'qs';
import {ImageEditorService} from '../image-editor/image-editor.service';
import {TeamnoteConfigService} from '../../configs/teamnote-config.service';
import {AttachmentTypeConstant} from '../../constants/attachment-type.constant';
import {ImageHelperService} from '../image-helper/image-helper.service';
import {downloadZip} from 'client-zip';
import {LoggerService} from '../logger/logger.service';
import {TnNotificationService} from '../tn-notification/tn-notification.service';

@Injectable()
export class FileManagerService {

  constructor(
    private _teamnoteApiService: TeamnoteApiService,
    private _imageEditorService: ImageEditorService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _imageHelperService: ImageHelperService,
    private _loggerService: LoggerService,
    private _tnNotificationService: TnNotificationService
  ) {
  }

  getAttachmentType(attachmentId: string): number {
    const split = attachmentId.split('.');
    const extension = _.last(split).toLowerCase();
    if (_.includes(AttachmentTypeConstant.IMAGE_TYPES, extension)) {
      return AttachmentTypeConstant.IMAGE;
    }
    if (_.includes(AttachmentTypeConstant.AUDIO_TYPES, extension)) {
      return AttachmentTypeConstant.AUDIO;
    }
    if (_.includes(AttachmentTypeConstant.VIDEO_TYPES, extension)) {
      return AttachmentTypeConstant.VIDEO;
    }
    if (_.includes(AttachmentTypeConstant.PDF_TYPES, extension)) {
      return AttachmentTypeConstant.PDF;
    }
    if (!this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.IS_ALLOW_ALL_FILE_TYPE) {
      if (_.includes(AttachmentTypeConstant.DOC_TYPES, extension)) {
        return AttachmentTypeConstant.PDF;
      }
    }
    return AttachmentTypeConstant.GENERAL;
  }

  checkIfAttachmentIsPdf(attachmentId: string): boolean {
    const split = attachmentId.split('.');
    const extension = _.last(split).toLowerCase();
    if (_.includes(AttachmentTypeConstant.PDF_TYPES, extension)) {
      return true;
    }
    return false;
  }

  getAvailableFileExtensionByType(type: number): string[] {
    switch (type) {
      case AttachmentTypeConstant.IMAGE:
        return AttachmentTypeConstant.IMAGE_TYPES;
      case AttachmentTypeConstant.VIDEO:
        return AttachmentTypeConstant.VIDEO_TYPES;
      case AttachmentTypeConstant.PDF:
        return _.union(AttachmentTypeConstant.PDF_TYPES, AttachmentTypeConstant.DOC_TYPES, AttachmentTypeConstant.AUDIO_TYPES);
    }
    return [];
  }

  getAudioDuration(duration: number): string {
    const min = Math.floor((duration / 1000) / 60);
    let sec: any = Math.ceil((duration / 1000) % 60);
    if (sec < 10) {
      sec = '0' + sec;
    }
    return min + ':' + sec;
  }

  getFileSize(size: number): string {
    const suffix = ['B', 'KB', 'MB', 'GB', 'TB'];
    let suffixIndex = 0;
    while (size >= 1024) {
      suffixIndex++;
      size = size / 1024;
    }
    return '' + Math.floor(size * 100) / 100 + suffix[suffixIndex];
  }

  getBackgroundImgSrcByBase64(base64: string, contentType?: string): any {
    const dataType = contentType ? contentType : 'image/png';
    const url = 'url(data:' + dataType + ';base64,' + base64 + ')';
    return url;
  }

  getBackgroundImgSrcBySrc(src: string): any {
    const url = 'url(' + src + ')';
    return url;
  }

  getBackgroundImgSrcByAttachmentId(attachmentId: string): any {
    const url = `url(${this._teamnoteConfigService.config.HOST.API_HOST}/static/${attachmentId})`;
    return url;
  }

  getImgSrcByBase64(base64: string, contentType?: string): any {
    const dataType = contentType ? contentType : 'image/png';
    const dataSrc = 'data:' + dataType + ';base64,' + base64;
    return dataSrc;
  }

  fileToDataUrl(file: any, callback: Function): void {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      callback(e.target.result);
    };
    reader.readAsDataURL(file);
  }

  dataUrlToFile(dataUrl: string, fileName: string): any {
    let byteString;
    if (dataUrl.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataUrl.split(',')[1]);
    } else {
      byteString = unescape(dataUrl.split(',')[1]);
    }

    // separate out the mime component
    const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    const blob: any = new Blob([ia], {type: mimeString});

    // IE & Edge doesn't support File constructor
    // let file = new File([blob], fileName, {type: mimeString});
    // return file;

    blob.lastModifiedDate = new Date();
    blob.name = fileName;
    return blob;
  }

  compressImageByFile(file: any, callback: Function, isNeedDateTimeOverlay: boolean, targetWidth?: number, targetHeight?: number, targetQuality?: number): void {
    const originalImage = new Image();
    originalImage.onload = () => {
      const maxWidth = targetWidth ? targetWidth : this._teamnoteConfigService.config.GENERAL.IMAGE_COMPRESSION.MAX_WIDTH_PIXEL;
      const maxHeight = targetHeight ? targetHeight : this._teamnoteConfigService.config.GENERAL.IMAGE_COMPRESSION.MAX_HEIGHT_PIXEL;

      let width = originalImage.naturalWidth;
      let height = originalImage.naturalHeight;

      if (width > maxWidth || height > maxHeight) {
        if (width > height) {
          height = height * maxWidth / width;
          width = width > maxWidth ? maxWidth : width;
        } else {
          width = width * maxHeight / height;
          height = height > maxHeight ? maxHeight : height;
        }
      }

      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      // handle png transparent image, give it a white background
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, width, height);
      ctx.drawImage(originalImage, 0, 0, width, height);

      // Real draw date time str to image
      if (isNeedDateTimeOverlay && this._teamnoteConfigService.config.WEBCLIENT.WATERMARK.IS_NEED_DATE_TIME) {
        this._imageHelperService.drawDateTimeToImageCtxByDateObj(ctx, file.dateObj, width, height);
      }

      const newImageDataUrl = canvas.toDataURL(this._teamnoteConfigService.config.GENERAL.IMAGE_COMPRESSION.MIME_TYPE, targetQuality ? targetQuality : this._teamnoteConfigService.config.GENERAL.IMAGE_COMPRESSION.QUALITY_RATIO);

      // if it's .png, change it to .jpg
      const newName = file.name.toLowerCase().replace('.png', '.jpg');
      const newFile = this.dataUrlToFile(newImageDataUrl, newName);
      callback(newFile, file.caption);
    };
    originalImage.src = URL.createObjectURL(file);
  }

  // Send Attachments
  realUploadFile(file: any, success: Function): void {
    this._teamnoteApiService.upload(
      file,
      success,
      (err) => {
        console.error(err);
      }
    );
  }

  apiUploadFile(file: File, callback: Function, isNeedDateTimeOverlay?: boolean): void {
    const fileType = this.getAttachmentType(file.name);
    if (fileType === AttachmentTypeConstant.IMAGE) {
      this.compressImageByFile(
        file,
        (file, caption) => {
          this.realUploadFile(file, (fileId) => callback(fileId, caption));
        },
        isNeedDateTimeOverlay
      );
    } else {
      this.realUploadFile(file, callback);
    }
  }

  uploadFileHub(file: File, callback: Function, isNeedDateTimeOverlay: boolean, targetAction?: string) {
    const fileType = this.getAttachmentType(file.name);

    if (fileType === AttachmentTypeConstant.IMAGE) {
      this.uploadImageHub(file, callback, isNeedDateTimeOverlay, targetAction);
    } else {
      this.apiUploadFile(file, callback);
    }
  }

  apiUploadImage(file: File, callback: Function, imageCaption: string, isNeedDateTimeOverlay: boolean) {
    this.apiUploadFile(
      file,
      (fileId) => {
        callback(fileId, imageCaption);
      },
      isNeedDateTimeOverlay
    );
  }

  uploadImageHub(file: File, callback: Function, isNeedDateTimeOverlay: boolean, targetAction?: string) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const imagePreview = e.target.result;

      this._imageEditorService.openImageEditorModal(
        imagePreview,
        (imageDataUrl, imageCaption) => {
          const newFile = this.dataUrlToFile(imageDataUrl, file.name);
          this.apiUploadImage(newFile, callback, imageCaption, isNeedDateTimeOverlay);
        },
        isNeedDateTimeOverlay,
        targetAction
      );
    };
    reader.readAsDataURL(file);
  }

  getHTMLContentByAttachmentId(attachmentId: string, callback: Function): void {
    const url = '/static/' + attachmentId;
    this._teamnoteApiService.callApi(
      url,
      {},
      callback,
      (err) => {

      },
      false,
      true
    );
  }

  isOS(): boolean {
    var agent = navigator.userAgent.toLowerCase();
    var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    if (agent.indexOf('win32') >= 0 || agent.indexOf('wow32') >= 0) {
      return false;
    }
    if (agent.indexOf('win64') >= 0 || agent.indexOf('wow64') >= 0) {
      return false;
    }
    if (isMac) {
      return true;
    }
  }

  async saveAs(data: { blob: Blob; filename: string, ext: string }) {
    if ('showSaveFilePicker' in window && this.isOS()) {
      try {
        await this.exportNativeFileSystem(data);
        this._tnNotificationService.showCustomSuccessByTranslateKey(
          'GENERAL.DOWNLOADED_TIP',
          // { FILE_NAME: data.filename }
        );
      } catch (error) {
        this._loggerService.debug('The user aborted a showSaveFilePicker request');
      } finally {
        return
      }
    }

    let { blob, filename } = data;
    // return this.download(data);
    return FileSaver.saveAs(blob, filename);
  }


  async getNewFileHandle({ blob, filename, ext }: { blob: Blob; filename: string; ext: string }): Promise<FileSystemFileHandle> {
  
    const opts = {
      suggestedName: filename,
      types: [],
    };


    /* for window ??? */
    // if (!this.isOS() && ext) {
    //   opts = {
    //     suggestedName: filename,
    //     types: [
    //       {
    //         description: 'Files',
    //         accept: {
    //           [blob.type || '*/*']: []
    //         },
    //       },
    //     ]
    //   }

    //   if (_.includes(AttachmentTypeConstant.DOC_TYPES, ext)) {
    //     _.forEach(AttachmentTypeConstant.MIME_TYPE_MAPPING, (exts, mime) => {
    //       if (_.includes(exts, ext)) {
    //         opts.types[0].accept = {
    //           [mime]: []
    //         }
    //       }
    //     })
    //   }
    // }

    return await showSaveFilePicker(opts);
  }


  async exportNativeFileSystem({ blob, filename, ext }: { blob: Blob; filename: string; ext?: string }) {
    const fileHandle: FileSystemFileHandle = await this.getNewFileHandle({ blob, filename, ext });


    if (!fileHandle) {
      throw new Error('Cannot access filesystem');
    }


    await this.writeFile({ fileHandle, blob });
  }


  async writeFile({ fileHandle, blob }: { fileHandle: FileSystemFileHandle; blob: Blob }) {
    const writer = await fileHandle.createWritable();
    await writer.write(blob);
    await writer.close();
  }


  downloadFileByAttachmentId(attachmentId: string, fileName?: string): void {
    // Use POST instead
    const url = '/static/' + attachmentId;
    this._teamnoteApiService.getFileByUrl(
      url,
      {},
      (blob, exportName) => {
        let name: string;
        if (fileName) {
          // Check if extension need to be added
          const fileNameLastDot = fileName.lastIndexOf('.');
          const lastDot = exportName.lastIndexOf('.');
          let extension = exportName.substr(lastDot).toLowerCase();
          if (fileNameLastDot !== -1) {
            const fileNameExtension = fileName.substr(fileNameLastDot).toLowerCase();
            if (extension === fileNameExtension) {
              extension = '';
            }
          }

          name = fileName + extension;
        } else {
          name = exportName;
        }


        const split = attachmentId.split('.');
        const extension = _.last(split).toLowerCase();
        
        this.saveAs({ blob: blob, filename: name, ext: extension })
        // FileSaver.saveAs(blob, name);
      },
      (err) => {

      }
    );
  }

  async downloadFilesByAttachmentIds(attachmentIds, zipName, filenameGetter?) {
    const files = [];
    const apiService = this._teamnoteApiService;

    for (let i = 0; i < attachmentIds.length; i++) {
      const attachmentId = attachmentIds[i];

      files.push(await new Promise((resolve, reject) => {
        const url = '/static/' + attachmentId;
        apiService.getFileByUrl(
          url,
          {},
          (blob, exportName) => {
            resolve({
              name: filenameGetter ? filenameGetter(attachmentId): exportName,
              input: blob,
            });
          },
          (err) => {
            console.log(err);
          }
        );
      }));
    }

    const blob = await downloadZip(files).blob();
    let fname = zipName || `export_${new Date().toISOString()}.zip`

    if ('showSaveFilePicker' in window && this.isOS()) {
      // this.saveAs({ blob: blob, filename: zipName })
      try {
        await this.exportNativeFileSystem({ blob: blob, filename: fname });
        this._tnNotificationService.showCustomSuccessByTranslateKey(
          'GENERAL.DOWNLOADED_TIP',
          // { FILE_NAME: fname }
        );
      } catch (error) {
        this._loggerService.debug('The user aborted a showSaveFilePicker request');
      } finally {
        return
      }
    } else {
      const link = document.createElement('a');
      link.download = fname;
      link.href = URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
