export const EnLoginStrings = {

  GET_STATE: {
    DOMAIN: {
      INPUT_TIP: "Input domain",
      DOMAIN: "Domain"
    },
    MOBILE: {
      INPUT_TIP: "Input your phone number",
      PHONE_NUMBER: "Phone number"
    },
    USERNAME: {
      INPUT_TIP: "Input your Username",
      USERNAME: "Username"
    },
    EMPTY_ERROR: "Please input login information",
    FAIL_ERROR: "Unregistered account",
    ACCOUNT_LOCKED: "Your account has been locked",
    SUBMIT: "SUBMIT",
    QR_CODE: {
      BUTTON: "Login with QR Code",
      TIP: `<p>
      To use TeamNote on your computer:
      </p>
      <ol>
        <li>Open TeamNote on your phone</li>
        <li>(Android) Tap More... → Login web client<br>
          (iOS) Tap More... → About → Login web client</li>
        <li>Point your phone to this screen to capture the QR code</li>
      </ol>`
    },
    INVALID_DOMAIN: "Invalid Domain"
  },
  OTP: {
    INPUT_TIP: "Input One-Time-Password (OTP)",
    OTP: "OTP",
    FAIL_ERROR: "Invalid OTP"
  },
  SECOND_FACTOR: {
    OTP_EMAIL: "One-Time-Password (OTP) will be provided via email",
    OTP_SMS: "One-Time-Password (OTP) will be provided via sms",
    EMAIL: {
      TITLE: "Email",
      INPUT_TIP: "Input your email"
    },
    MOBILE: {
      TITLE: "Mobile number",
      INPUT_TIP: "Input your mobile number"
    },
    HKID: {
      TITLE: "HKID",
      INPUT_TIP: "Input your HKID"
    },
    FULLNAME: {
      TITLE: "Full name",
      INPUT_TIP: "Input your full name"
    },
    LDAP_PW: {
      TITLE: "Password",
      INPUT_TIP: "Input your password"
    },
    STAFFID: {
      TITLE: "Staff ID",
      INPUT_TIP: "Input your Staff ID"
    },
    STAFF_ID: {
      TITLE: "Staff ID",
      INPUT_TIP: "Input your Staff ID"
    },
    FAIL_ERROR: "Invalid input information"
  },
  PASSWORD: {
    INPUT_TIP: "Input your password",
    NEW_PASSWORD_INPUT_TIP: "Setup new password",
    CONFIRM: "Confirm password",
    NEW_PASSWORD: "New password",
    PASSWORD: "Password",
    SIGN_IN: "SIGN IN",
    FORGET_PASSWORD: "Forget password",
    SUBMIT: "SUBMIT",
    LOGIN_FAIL: "Login failed. Please note whether you have changed your password and use the latest one to login",
    LOGIN_FAIL_AUTH: "Login failed.",
    EMPTY_ERROR: "Please input password",
    NOT_MATCH: "Password not match",
    NOT_MATCH_POLICY: "Password must be at least {{NUM_CHAR}} characters long with lowercase and uppercase characters",
    DEVICE_TOKEN_WIPE: "Login failed, device token just wiped, please try again",
    MAX_REG_DEVICE: "You have reached the maximum allowed devices",
    EXPIRY_REMINDER: "Your password will be expired in {{NUM_DAY}} days, please change your password",
    PASSWORD_REUSED: "The password is used before, please use a new one",
    ACCOUNT_SUSPENDED: "Your account has been suspended",
  }

}