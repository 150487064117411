export const ZhChsQuestionnaireStrings = {
  ALREADY_COMPLETED: "你已经完成过此问卷，此问卷只可以填写一次",
  EXPIRED: "此问卷已过期",

  SUBMITTED_MSG: "感谢您的意见",
  END: "-- 完结 --",
  SUBMIT: "提交",
  ITEM_OTHER: "其他",
  INPUT_ALL_FIELDS: "请回答所有问题",
  ITEM_RAITNG_CLICK: "点击星星以评分",
  ITEM_RATING_COMMENT: "其他意见（选填）:",
  ITEM_UNFINISHED_INDICATOR: "***"
};
