<div class="job-report-view-container">
  <tn-page-title [title]="'WEBCLIENT.JOB_REPORT.REPORT_DETAIL.TITLE' | translate">
    <div class="left-control">
      <div class="back-button clickable" (click)="reportViewBack(false)">
        <i class="fa fa-fw fa-angle-left"></i>
      </div>
    </div>

    <div class="right-control">
      <button mat-button 
        (click)="assign()" 
        *ngIf="report.is_dispatchable && isViewingStoreReport">
        {{'WEBCLIENT.JOB_REPORT.ACTION.ASSIGN' | translate}}
      </button>     
    </div>
  </tn-page-title>

  <div #jobReportViewBody class="job-report-view-body">
    <tn-report-info-header
      [store]="report.store"
      [type]="report.type">
    </tn-report-info-header>

    <div class="report-body">
      <tn-custom-field 
        [field]="field" 
        [report]="report"
        *ngFor="let field of report.mappedCustomFields">
      </tn-custom-field>

      <tn-job-dispatch-info 
        [job]="report.job_dispatch[0]" 
        [report]="report"
        [isViewingStoreReport]="isViewingStoreReport">
      </tn-job-dispatch-info>

      <tn-job-dispatch-action 
        [job]="report.job_dispatch[0]" 
        (onJobAction)="onJobAction($event)"
        *ngIf="!isViewingStoreReport"></tn-job-dispatch-action>

    </div>

  </div>
</div>