export const TeamNoteTrainingConstant = {
  TYPE: {
    FOLDER: 'folder',
    IMG: 'img',
    PDF: 'pdf',
    VIDEO: 'video',
    DOC: 'doc'
  },
  ROOT_FOLDER_NAME: "ROOT"
}
