import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordReloginComponent } from './password-relogin.component';
import { PasswordReloginService } from './password-relogin.service';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonMaterialModule } from '../common-material/common-material.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        CommonMaterialModule
    ],
    providers: [
        PasswordReloginService
    ],
    declarations: [PasswordReloginComponent],
    exports: [PasswordReloginComponent]
})
export class PasswordReloginModule { }
