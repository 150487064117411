import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Store, StoreReportType, StoreReportCustomFieldsMapped, StoreReportCustomFieldsAnswer, JobDispatch, StoreReportCustomRouteParam, StoreReport } from '../models/job-report';
import { JobReportService } from '../job-report.service';

import * as _ from 'lodash';
import { JobReportConstant } from '../constants/job-report.constants';
import { TnNotificationService } from '../../../utilities/tn-notification/tn-notification.service';
import { FieldAttachmentDocumentParsedAnswer } from '../custom-field/field-attachment-document/field-attachment-document.component';
import { FileManagerService } from '../../../utilities/file-manager/file-manager.service';
import { FieldAttachmentParsedAnswer } from '../custom-field/field-attachment/field-attachment.component';

@Component({
  selector: 'tn-report-submit',
  templateUrl: './report-submit.component.html',
  styleUrls: ['./report-submit.component.scss']
})
export class ReportSubmitComponent implements OnInit {

  selectedStore: Store = null;
  selectedType: StoreReportType = null;

  isFinishSelection: boolean = false;

  mappedInputCustomFields: StoreReportCustomFieldsMapped[];

  parsedAnswers: StoreReportCustomFieldsAnswer[] = [];
  pendingAttachmentFields: StoreReportCustomFieldsMapped[] = [];

  @Input() job: JobDispatch;
  @Input() jobActionCode: number;
  @Input() customParam: StoreReportCustomRouteParam;

  @Output() onBack: EventEmitter<boolean> = new EventEmitter<boolean>(null);
  @Output() onJobResponseSubmitBack: EventEmitter<boolean> = new EventEmitter<boolean>(null);

  report: StoreReport = null;

  constructor(
    private _jobReportService: JobReportService,
    private _fileManagerService: FileManagerService,
    private _tnNotificationService: TnNotificationService
  ) { }

  ngOnInit() {

  }

  ngOnChanges() {
    if (this.job) {
      this.selectedStore = this._jobReportService.getStoreByStoreId(this.job.report.store_id);
      this.selectedType = this._jobReportService.getTypeByTypeId(this.job.type_id);
      this.isFinishSelection = true;
      this.mappedInputCustomFields = this._jobReportService.prepareMappedReportFieldInputByTypeId(this.selectedType.type_id, true);

      this.report = this.job.report;
    }
  }

  reportSubmitBack() {
    if (this.job) {
      this.jobResponseSubmitBack(false);
    } else {
      this.onBack.emit(false);
    }
  }
  jobResponseSubmitBack(isSuccess) {
    this.onJobResponseSubmitBack.emit(isSuccess);
  }

  // Define report form

  onFinishSelect() {
    this.isFinishSelection = true;
    this.prepareInputFields();
  }

  prepareInputFields() {
    this.mappedInputCustomFields = this._jobReportService.prepareMappedReportFieldInputByTypeId(this.selectedType.type_id);
  }

  // Submit

  checkReportAnswers(index) {
    if (index == this.mappedInputCustomFields.length) {
      this.checkUploadAttachments(0);
      return;
    }
    let field = this.mappedInputCustomFields[index];
    if (!field.definition.is_nullable && (field.answer.value === "" || field.answer.value.length == 0)) {
      if (field.definition.field_type != JobReportConstant.CUSTOM_FIELD_TYPE.TEXT_DISPLAY) {
        this._tnNotificationService.showCustomErrorByTranslateKey('WEBCLIENT.JOB_REPORT.NEW_REPORT.INPUT_MANDATORY');
        return;
      }
    }

    switch (field.definition.field_type) {
      case JobReportConstant.CUSTOM_FIELD_TYPE.ATTACHMENT_PHOTO:
      case JobReportConstant.CUSTOM_FIELD_TYPE.ATTACHMENT_DOCUMENT:
        this.pendingAttachmentFields.push(field);
        break;
      default:
        this.parsedAnswers.push(field.answer);
        break;
    }

    // if (field.definition.field_type != JobReportConstant.CUSTOM_FIELD_TYPE.ATTACHMENT_PHOTO) {
    //   this.parsedAnswers.push(field.answer);
    // } else {
    //   this.pendingAttachmentFields.push(field);
    // }
    this.checkReportAnswers(++index);
  }

  checkUploadAttachments(fieldIndex) {
    if (fieldIndex == this.pendingAttachmentFields.length) {
      this.realSubmit();
      return;
    }
    let attachmentField = this.pendingAttachmentFields[fieldIndex];
    if (attachmentField.answer.value == "" || attachmentField.answer.value.length == 0) {
      this.checkUploadAttachments(++fieldIndex);
      return;
    }
    this.uploadAttachmentsOfField(
      attachmentField,
      0,
      [],
      (answerArr) => {
        this.parsedAnswers.push({
          field_id: attachmentField.definition.field_id,
          value: JSON.stringify(answerArr)
        });
        this.checkUploadAttachments(++fieldIndex);
      }
    )

  }
  uploadAttachmentsOfField(field: StoreReportCustomFieldsMapped, fileIndex: number, answerArr: any[], finishedCallback: Function) {
    if (fileIndex == field.answer.files.length) {
      finishedCallback(answerArr);
      return;
    }
    let file = field.answer.files[fileIndex];
    this._fileManagerService.apiUploadImage(
      file,
      (fileId) => {
        switch (field.definition.field_type) {
          case JobReportConstant.CUSTOM_FIELD_TYPE.ATTACHMENT_PHOTO:
            answerArr.push(new FieldAttachmentParsedAnswer(null, fileId, field.answer.value[fileIndex]));
            break;
          case JobReportConstant.CUSTOM_FIELD_TYPE.ATTACHMENT_DOCUMENT:
            answerArr.push(new FieldAttachmentDocumentParsedAnswer(fileId, file.name));
            break;
        }
        this.uploadAttachmentsOfField(field, ++fileIndex, answerArr, finishedCallback);
      },
      null,
      true
    );
  }

  realSubmit() {
    // TODO: do a final prompt to confirm submitting reprot
    if (!this.jobActionCode) {
      this._jobReportService.submitStoreReport(
        this.selectedStore.store_id, 
        this.selectedType.type_id, 
        JSON.stringify(this.parsedAnswers), 
        (resp) => {
          this.onBack.emit(true);
        }
      );
    } else {
      switch (this.jobActionCode) {
        case JobReportConstant.JOB_DISPATCH_ACTION_CODE.END:
          this._jobReportService.endJobDispatch(
            this.job.job_id,
            () => {
              this.jobResponseSubmitBack(true);
            },
            JSON.stringify(this.parsedAnswers)
          );
          break;
        case JobReportConstant.JOB_DISPATCH_ACTION_CODE.NEED_FOLLOW_UP:
          this._jobReportService.followUpJobDispatch(
            this.job.job_id,
            () => {
              this.jobResponseSubmitBack(true);
            },
            JSON.stringify(this.parsedAnswers)
          );
          break;
      }
    }
  }

  submit() {
    this.checkReportAnswers(0);
  }


}
