import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LocalStorageManagerService } from '../../utilities/local-storage/local-storage-manager.service';
import { TeamNoteLocalStorageKeyConstants } from '../../constants/local-storage-key.constant';

import * as _ from 'lodash';

@Component({
  selector: 'tn-teamflare-app',
  templateUrl: './teamflare-app.component.html',
  styleUrls: ['./teamflare-app.component.scss']
})
export class TeamflareAppComponent implements OnInit {

  sub: any
  teamflareAppUrl: SafeResourceUrl
  sessionToken: string
  @ViewChild('teamflare', {static: false}) teamflare: ElementRef;

  constructor(
    private sanitizer: DomSanitizer,
    private _route: ActivatedRoute,
    private _localStorageManagerService: LocalStorageManagerService,
  ) { }

  iframeLoaded() {
    if (this.teamflare) {
    }
  }

  ngOnInit() {
    this.sub = this._route.params.subscribe((params)=>{
      // console.log(params);
      this.teamflareAppUrl = this.sanitizer.bypassSecurityTrustResourceUrl(params.url);
    });

    this.sessionToken = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN);
  }

  ngOnDestroy() {
    // this.sub.unsubscribe();
  }
}
