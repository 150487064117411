<!-- 'background-color': item.webview?.navbar_color ? item.webview.navbar_color : '' -->
<div
  class="nav-item-wrapper"
  *ngFor="let item of sideNavItems"
>
  <ng-container *ngIf="item.isDisplay">
    <!-- Module type nav item -->
    <a
      *ngIf="item.type === 'module'"
      class="nav-item"
      [routerLink]="item.link"
      (click)="changeRoute(item)"
      [matTooltip]="item.nameStatic ? item.nameStatic : (item.name | translate)"
      matTooltipPosition="right"
      matTooltipClass="side-nav-tooltip"
      [ngClass]="{ 'nav-item-active': item.isActive || item.key == currentNavItemKey }"
      [ngStyle]="{ 'pointer-events': item.link ? 'unset' : 'none' }"
    >
      <div class="nav-label">
        <img class="label-img" [src]="item.imgUrl" *ngIf="item.imgUrl" />
        <i class="label-icon fa fa-fw fa-{{ item.iconClass }}" *ngIf="!item.imgUrl"></i>
        <span class="label-title" *ngIf="item.name">{{ item.name | translate }}</span>
        <span class="label-title" *ngIf="item.nameStatic">{{ item.nameStatic }}</span>
      </div>

      <div class="nav-count unread-bubble" *ngIf="item.count > 0">
        {{ item.count }}
      </div>
    </a>

    <!-- Feature type nav item - display in external tab -->
    <a
      *ngIf="item.type === 'super_connector' && item.module_display === 'external_tab'"
      href="{{ item.url ? item.url : '#' }}"
      [ngStyle]="{ 'pointer-events': item.url ? 'unset' : 'none' }"
      [matTooltip]="item.nameStatic ? item.nameStatic : (item.name | translate)"
      matTooltipPosition="right"
      matTooltipClass="side-nav-tooltip"
      class="nav-item"
      [ngClass]="{ 'nav-item-active': item.isActive || item.key == currentNavItemKey }"
    >
      <div class="nav-label">
        <img class="label-img" [src]="item.imgUrl" *ngIf="item.imgUrl" />
        <i class="show-label-icon label-icon fa fa-fw fa-{{ item.iconClass }}" *ngIf="!item.imgUrl"></i>
        <span class="label-title" *ngIf="item.name && !isCheckPlaceholder(item.name)">{{ item.name | translate }}</span>
        <span class="label-title" *ngIf="item.name && isCheckPlaceholder(item.name)"
          >&nbsp;&nbsp;{{ item.name | translate }}</span
        >
      </div>
    </a>

    <!-- Feature type nav item - display in embed iframe -->
    <!-- [routerLink]="[item.link, item.url]" -->
    <a
      *ngIf="item.type === 'super_connector' && item.module_display === 'embed_iframe'"
      (click)="changeTeamflarePageRoute(item)"
      [matTooltip]="item.nameStatic ? item.nameStatic : (item.name | translate)"
      matTooltipPosition="right"
      matTooltipClass="side-nav-tooltip"
      class="nav-item"
      [ngClass]="{ 'nav-item-active': item.isActive || item.key == currentNavItemKey }"
    >
      <div class="nav-label">
        <img class="label-img" [src]="item.imgUrl" *ngIf="item.imgUrl" />
        <i class="show-label-icon label-icon fa fa-fw fa-{{ item.iconClass }}" *ngIf="!item.imgUrl"></i>
        <span class="label-title" *ngIf="item.name && !isCheckPlaceholder(item.name)">{{ item.name | translate }}</span>
        <span class="label-title" *ngIf="item.name && isCheckPlaceholder(item.name)"
          >&nbsp;&nbsp;{{ item.name | translate }}</span
        >
      </div>
    </a>

    <!-- Label type nav item -->
    <a *ngIf="item.type === 'label'" class="nav-label-item">
      <div class="nav-module-label">
        <img class="label-img" [src]="item.imgUrl" *ngIf="item.imgUrl" />
        <span class="label-title" *ngIf="item.name">{{ item.name | translate }}</span>
        <span class="label-title" *ngIf="item.nameStatic">{{ item.nameStatic }}</span>
      </div>
    </a>

    <!-- Separator -->
    <div *ngIf="item.type === 'separator'" class="nav-label nav-separator theme-color-primary"></div>
  </ng-container>
</div>
