import { CustomFieldsDefinition, CustomFieldsAnswer } from "../../shared/custom-field/models/custom-field";

import * as _ from 'lodash';
import { UserContact } from "../../../models/user-contact";

export class WorkflowOptions {
  teams: WorkflowTeam[];
  form_types: WorkflowType[];
}

export class WorkflowTeam {
  company_domain: string;
  create_date: string;
  extension: string;
  extra: any;
  is_manager: number;
  is_store: number;
  name: string;
  role_groups: WorkflowTeamRoleGroup[];
  store_code: string;
  team_code: string;
  team_id: string;
  template_id: string;
  udpate_date: string;
  user_group_id: string;
  enabled_form_type_ids: string[];
}

export class WorkflowTeamRoleGroup {
  company_domain: string;
  create_date: string;
  extension: string;
  extra: any;
  is_manager: number;
  is_store: number;
  name: string;
  role: WorkflowTeamRoleGroup[];
  role_group_id: string;
  role_id: string;
  store_code: string;
  team_id: string;
  udpate_date: string;
  user_group_id: string;
}

export class WorkflowTeamRoleGroupRole {
  description: string;
  name: string;
  role_id: string;
  template_id: string;
}

export class WorkflowTeamTemplate {
  company_domain: string;
  description: string;
  name: string;
  template_id: string;
}

export class WorkflowType {
  company_domain: string;
  form_no_format: string;
  list_format: string;
  name: string;
  stages: WorkflowTypeStage[];
  team_template: WorkflowTeamTemplate;
  template_id: string;
  type_id: string;
  visible_in_app_menu: number;
  visible_to_member: number;
  enabled_team_ids: string[];
}

export class WorkflowTypeStage {
  custom_fields: WorkflowTypeStageCustomField[];
  name: string;
  order: number;
  pic_method: string;
  pic_notify: number;
  stage_id: string;
  stage_pic_roles: WorkflowTypeStagePicRole[];
  type_id: string;
}

export class WorkflowTypeStageCustomField extends CustomFieldsDefinition {
  stage_id: string;
}

export class WorkflowTypeStagePicRole {
  role_id: string;
  stage_id: string;
}

export class WorkflowForm {
  active_stage_id: string;
  created_by: string;
  creator: UserContact;
  is_deleted: number;
  form_id: string;
  form_no: string;
  company_domain: string;
  team: WorkflowTeam;
  team_id: string;
  type: WorkflowType;
  type_id: string;
  create_date: string;
  update_date: string;
  custom_fields: WorkflowFormCustomFieldAnswer[];
  form_stage_pic: WorkflowFormStagePic[];
  observer_ids: string[];
  pic_target_groups: string[];

  // local
  isFormActive?: boolean;
  order?: number;
  isFormPending?: boolean;
}

export class WorkflowFormCustomFieldAnswer extends CustomFieldsAnswer {
  stage_id: string;
}

export class WorkflowFormStagePic {
  form_id: string;
  stage_id: string;
  user_id: string
}

export class WorkflowCustomRouteParam {
  typeId?: string;

  constructor(typeId?: string) {
    this.typeId = typeId;
  }
}

export class WorkflowFormFilterParam {
  createDateFrom?: string;
  createDateTo?: string;
  teamId?: string;
  typeId?: string;
  stageId?: string;
  content?: string;

  constructor(
    createDateFrom?: string,
    createDateTo?: string,
    teamId?: string,
    typeId?: string,
    stageId?: string,
    content?: string
  ) {
    this.createDateFrom = createDateFrom;
    this.createDateTo = createDateTo;
    this.teamId = teamId;
    this.typeId = typeId;
    this.stageId = stageId;
    this.content = content;
  }
}