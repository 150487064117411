import { Component, OnInit, Input } from '@angular/core';
import { TrainingFolder } from '../models/training';

@Component({
  selector: 'tn-training-folder',
  templateUrl: './training-folder.component.html',
  styleUrls: ['./training-folder.component.scss']
})
export class TrainingFolderComponent implements OnInit {

  @Input() folder: TrainingFolder;

  constructor() { }

  ngOnInit() {
  }

}
