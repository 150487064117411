export const EnJobReportStrings = {
  ITEM_REF: "Ref: {{REF}}",

  LIST_FORMAT: {
    IMAGE: {
      ONE: "Image",
      OTHER: " Images"
    },
    FILE: {
      ONE: "File",
      OTHER: " Files"
    },
    USER: {
      ONE: " User",
      OTHER: " Users"
    },
    ATTACHMENT: {
      ONE: " Attachment",
      OTHER: " Attachments"
    }
  },

  EXPORT: {
    REPORT: "Export Reports",
    JOB: "Export Jobs",
    TIME_PERIOD: "Time",
    IN_PROGRESS_MSG: "Data is Exporting, you can check the progress in notification at the top-right hand corner. You will be notified when the file is ready.",
    EXPORT_READY_MSG: "File is ready, you can download it in notification center."
  },

  STATUS: {
    NOT_ASSIGNED: "Not Assigned",
    PENDING: "Pending",
    ACCEPTED: "Accepted",
    REJECTED: "Rejected",
    STARTED: "Started",
    FINISHED: "Finished",
    NEED_FOLLOW_UP: "Needs follow up"
  },

  NEW_REPORT: {
    STORE: "Store",
    TYPE: "Template",
    TITLE: "New Report",
    SUBMIT: "Submit",
    INPUT_MANDATORY: "Please input all mandatory fields",
    CONFIRM_SUBMIT: "Confirm submit this report?",
    SUBMIT_SUCCESS: "Report submitted",
    FREE_TEXT_OPTION: "Others (Please Specify)"
  },

  MSG: {
    NO_STORE_OPTION: "No available store options",
    NO_TYPE_OPTION: "No available type options",
    ASSIGN_SUCCESS: "Assign success",
    ASSIGN_NO_PERMISSION: "No permission"
  },

  REPORT_DETAIL: {
    TITLE: "Report Details",
    WORKER: "Actioned by",
    STATUS: "Status",
    START_TIME: "Start Time",
    END_TIME: "End Time",
    JOB_RESPONSE: "Job Response"
  },
  
  ACTION: {
    ACCEPT_BTN: "Accept",
    REJECT_BTN: "Reject",
    START_BTN: "Start Job",
    END_BTN: "End Job",
    NEED_FOLLOW_UP_BTN: "Reassign",
    ASSIGN: "Assign"
  },

  FILTER: {
    REPORT: "Filter Reports",
    JOB: "Filter Jobs",
    REPORT_DATE: "Report Date",
    JOB_DISPATCH_DATE: "Job Dispatch Date",
    TO: "To",
    STORE: "Store",
    TEMPLATE: "Template",
    STATUS: "Status",
    APPLY: "Apply",
    CONTENT: "Content"
  }


}
