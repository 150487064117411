/**
 * Corporate Material Ebook pages display
 * 
 * - Font size control
 * - Cantonese / mandarin
 * - play / pause
 * - ebook page navigations
 * 
 * Created by Shan - 2018-05-07
 */

import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { CorporateMaterialEbookPage, CorporateMaterialEbookPageAudio, CorporateMaterialEbook } from '../models/corporate-material';

import * as _ from 'lodash';
import { TeamNoteCorporateMaterialConstant } from '../constants/corporate-material.constant';
import { CorporateMaterialService } from '../corporate-material.service';
import { AudioPlayerService } from '../../../utilities/audio-player/audio-player.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FileManagerService } from '../../../utilities/file-manager/file-manager.service';
import { FileFactoryService } from '../../../utilities/file-factory/file-factory.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'tn-material-ebook',
  templateUrl: './material-ebook.component.html',
  styleUrls: ['./material-ebook.component.scss']
})
export class MaterialEbookComponent implements OnInit {

  @Input() ebook: CorporateMaterialEbook;
  @Output() onEbookBack: EventEmitter<CorporateMaterialEbookPage> = new EventEmitter<CorporateMaterialEbookPage>();

  // Page
  currentPageNum: number = 1;
  page: CorporateMaterialEbookPage;

  // Content
  pageHTMLContent: any = "";
  isFontSizeEnlarged: boolean = false;

  // Audio selection
  audioLangs: string[] = _.keys(TeamNoteCorporateMaterialConstant.AUDIO_LANG);
  currentLangKey: string = _.first(this.audioLangs);

  // Audio play/pause
  isPlayingAudio: boolean = false;

  isDialog: boolean = false;

  constructor(
    private _fileManagerService: FileManagerService,
    private _corporateMaterialService: CorporateMaterialService,
    private _audioPlayerService: AudioPlayerService,
    private _domSanitizer: DomSanitizer,
    private _fileFactoryService: FileFactoryService,
    public dialogRef: MatDialogRef<MaterialEbookComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if (this.data.ebookId) {
      this.dialogRef.updateSize('60vw', '80vh')
      this.getFullEbook(this.data.ebookId);
      this.isDialog = true;
    }
  }

  /**
   * When ebook object is changed, try to restore prev page
   * 
   * @memberof MaterialEbookComponent
   */
  ngOnChanges() {
    if (!this.ebook) {
      return;
    }
    this.getFullEbook();
    this.restorePrevPage();
  }

  /**
   * When component is destroyed, pause audio.
   * 
   * @memberof MaterialEbookComponent
   */
  ngOnDestroy() {
    this.page = null;
    this.pauseAudio();
  }

  getFullEbook(ebookId?: string): void {
    this._corporateMaterialService.getCorporateMaterialEbook(
      ebookId ? ebookId : this.ebook.book_id,
      (data: CorporateMaterialEbook) => {
        data.pages = _.orderBy(data.pages, ['page_no', 'name']);
        this.ebook = data;
        this.restorePrevPage();
      },
      (err) => {
        console.log(err)
      }
    )
  }

  /**
   * Try to restore prev. page, if not exist, go to first page
   * 
   * @returns {void} 
   * @memberof MaterialEbookComponent
   */
  restorePrevPage(): void {
    // If no pages, just set null
    if (!this.ebook.pages || this.ebook.pages.length == 0) {
      this.goToPageByPageNumber(0);
      return;
    }

    // get page num from cookie
    let pagePointer = this._corporateMaterialService.getEbookPagePointerByBookId(this.ebook.book_id);
    if (pagePointer) {
      this.goToPageByPageNumber(pagePointer.p);
    } else {
      // If no prev page num, go to first page
      this.goToPageByPageNumber(1);
    }
  }

  /**
   * Go back and dismiss current ebook page view
   * 
   * @memberof MaterialEbookPageComponent
   */
  ebookBack(): void {
    this.onEbookBack.emit(null);
  }

  /**
   * Go to target page
   * 
   * @param {number} num - target page number
   * @memberof MaterialEbookComponent
   */
  goToPageByPageNumber(num: number): void {
    // Try to pause audio first
    this.pauseAudio();

    this.currentPageNum = num;
    if (num == 0) {
      this.page = null;
    } else {
      this.page = this.ebook.pages[num - 1];
    }

    // store current page in cookies
    this._corporateMaterialService.setEbookPagePointer(this.ebook.book_id, this.currentPageNum);

    // Get html content of page
    this.getHTMLContentOfEbookPage();

    // Auto play audio
    this.playAudio();
  }

  /**
   * Go to prev ebook page
   * 
   * - Min = 1
   * 
   * @returns {void} 
   * @memberof MaterialEbookComponent
   */
  goToPrevPage(): void {
    if (this.currentPageNum <= 1) {
      return;
    }
    this.goToPageByPageNumber(--this.currentPageNum);
  }

  /**
   * Go to next ebook page
   * 
   * - Max = pages length
   * 
   * @returns {void} 
   * @memberof MaterialEbookComponent
   */
  goToNextPage(): void {
    if (this.currentPageNum == this.ebook.pages.length) {
      return;
    }
    this.goToPageByPageNumber(++this.currentPageNum);
  }

  /**
   * Get HTML content
   * 
   * @returns {void} 
   * @memberof MaterialEbookComponent
   */
  getHTMLContentOfEbookPage(): void {
    // If no page exists, set as null
    if (!this.page) {
      this.pageHTMLContent = null;
      return;
    }
    this._fileManagerService.getHTMLContentByAttachmentId(
      this.page.attachment_id,
      (html) => {
        // update pageHTMLContent
        this.pageHTMLContent = html ? this._domSanitizer.bypassSecurityTrustHtml(html) : "";
      }
    );
  }

  /**
   * Toggle html content font size (by css class)
   * 
   * @memberof MaterialEbookComponent
   */
  toggleFontSize(): void {
    this.isFontSizeEnlarged = !this.isFontSizeEnlarged;
  }

  /**
   * Update current audio lang key.
   * Play audio again if it was playing a different lang audio before.
   * 
   * @param {string} langKey - target lang key
   * @memberof MaterialEbookComponent
   */
  selectAudioLang(langKey: string): void {
    let prevLangKey = this.currentLangKey;
    this.currentLangKey = langKey;
    if (this.isPlayingAudio && prevLangKey != langKey) {
      this.playAudio();
    }
  }

  /**
   * Play audio
   * 
   * - Pause current audio first.
   * - Get target ebook page audio object
   * - get its src url and play!
   * 
   * @returns {void} 
   * @memberof MaterialEbookComponent
   */
  playAudio(): void {
    this.pauseAudio();
    if (!this.page) {
      return;
    }
    let targetAudioObj: CorporateMaterialEbookPageAudio = _.find(this.page.audio_tracks, (a) => {
      return a.audio_type == TeamNoteCorporateMaterialConstant.AUDIO_LANG[this.currentLangKey];
    });
    if (!targetAudioObj) {
      return;
    }

    this._fileFactoryService.getFileByAttachmentId(
      targetAudioObj.attachment_id,
      (blobUrl, fileName) => {
        this._audioPlayerService.playAudio(blobUrl);
        this.isPlayingAudio = true;
      },
      (err) => {},
      true
    );
  }

  /**
   * Pause audio
   * 
   * @memberof MaterialEbookComponent
   */
  pauseAudio(): void {
    this._audioPlayerService.pauseAudio();
    this.isPlayingAudio = false;
  }


}
