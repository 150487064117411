import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContextMenuComponent } from './context-menu.component';
import { ContextMenuService } from './context-menu.service';

// Translate
import { TranslateModule } from '@ngx-translate/core';
import { HoverDropdownMenuComponent } from './hover-dropdown-menu/hover-dropdown-menu.component';
import { CommonNgxBootstrapModule } from '../common-ngx-bootstrap/common-ngx-bootstrap.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CommonNgxBootstrapModule
  ],
  providers: [
    
  ],
  declarations: [ContextMenuComponent, HoverDropdownMenuComponent],
  exports: [
    ContextMenuComponent,
    HoverDropdownMenuComponent
  ]
})
export class ContextMenuModule { }
