import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StoreReportFilterParam, Store, StoreReportType, StoreReportCustomRouteParam } from '../models/job-report';
import { JobReportService } from '../job-report.service';

import * as _ from 'lodash';
import { BsDatepickerConfig } from '../../../../../node_modules/ngx-bootstrap/datepicker';
import { TimestampService } from '../../../utilities/timestamp/timestamp.service';

@Component({
  selector: 'tn-report-filter',
  templateUrl: './report-filter.component.html',
  styleUrls: ['./report-filter.component.scss']
})
export class ReportFilterComponent implements OnInit {
  @Input() isViewingStoreReport: boolean;
  @Input() customParam: StoreReportCustomRouteParam;

  @Output() onReportFilterApply: EventEmitter<StoreReportFilterParam> = new EventEmitter<StoreReportFilterParam>(null);
  @Output() onBack: EventEmitter<boolean> = new EventEmitter<boolean>(null);

  titleTranslateKey: string;
  param: StoreReportFilterParam;

  dateFrom: Date = null;
  dateTo: Date = null;
  dateInputConfig: Partial<BsDatepickerConfig>;

  storeOptions: Store[];
  selectedStore: any;

  typeOptions: StoreReportType[];
  selectedType: any;

  statusOptions: any[];
  selectedStatus: any;

  contentKeyword: string = "";

  allOption: any = {isAll: true, store_id: null, type_id: null, statusTranslateKey: null};

  constructor(
    private _jobReportService: JobReportService,
    private _timestampService: TimestampService
  ) { }

  ngOnInit() {
    if (this.isViewingStoreReport) {
      this.titleTranslateKey = 'WEBCLIENT.JOB_REPORT.FILTER.REPORT';
    } else {
      this.titleTranslateKey = 'WEBCLIENT.JOB_REPORT.FILTER.JOB';
    }

    this.dateInputConfig = Object.assign({}, { 
      containerClass: "theme-default",
      dateInputFormat: "YYYY-MM-DD"
    });

    // get prev param
    this.param = this._jobReportService.getCachedReportFilterParam(!this.isViewingStoreReport);
    
    this.getOptions();
    this.getDates();
    this.getStatusOptions();
    this.getContentKeyword();
  }

  getDates(): void {
    if (this.isViewingStoreReport) {
      if (this.param.reportDateFrom) {
        this.dateFrom = this._timestampService.secondToDateObj(this.param.reportDateFrom);
      }
      if (this.param.reportDateTo) {
        this.dateTo = this._timestampService.secondToDateObj(this.param.reportDateTo);
      }
    } else {
      if (this.param.jobDispatchDateFrom) {
        this.dateFrom = this._timestampService.secondToDateObj(this.param.jobDispatchDateFrom);
      }
      if (this.param.jobDispatchDateTo) {
        this.dateTo = this._timestampService.secondToDateObj(this.param.jobDispatchDateTo);
      }
    }
  }

  getOptions(): void {
    this._jobReportService.getStoreReportOptions(
      (resp) => {
        this.storeOptions = this._jobReportService.storeOptions;
        this.storeOptions = _.union([this.allOption], this.storeOptions);
        if (!this.param.storeId) {
          this.selectedStore = this.allOption;
        } else {
          this.selectedStore = _.find(this.storeOptions, {store_id: this.param.storeId});
        }

        if (!this.customParam) {
          this.typeOptions = _.filter(this._jobReportService.typeOptions, (type) => {
            return type.visible_in_app_menu == 0;
          });
          this.typeOptions = _.union([this.allOption], this.typeOptions);
        } else {
          this.typeOptions = [this._jobReportService.getTypeByTypeId(this.customParam.typeId)];
        }

        if (!this.param.typeId) {
          this.selectedType = this.typeOptions[0];
        } else {
          this.selectedType = _.find(this.typeOptions, {type_id: this.param.typeId});
        }
      }
    );
  }

  getStatusOptions(): void {
    this.statusOptions = this._jobReportService.getAllReportStatus();
    this.statusOptions = _.union([this.allOption], this.statusOptions);
    if (!this.param.statusTranslateKey) {
      this.selectedStatus = this.allOption;
    } else {
      this.selectedStatus = _.find(this.statusOptions, (s) => {
        return s.statusTranslateKey == this.param.statusTranslateKey
      });
    }
  }

  getContentKeyword(): void {
    this.contentKeyword = this.param.content ? this.param.content : "";
  }
  
  reportFilterBack(): void {
    this.onBack.emit(false);
  }

  apply(): void {
    // date
    let from = this.dateFrom ? this._timestampService.getStartOfDateSecondStringByDateObj(this.dateFrom) : null;
    let to = this.dateTo ? this._timestampService.getEndOfDateSecondStringByDateObj(this.dateTo) : null;
    if (this.isViewingStoreReport) {
      this.param.reportDateFrom = from;
      this.param.reportDateTo = to;
    } else {
      this.param.jobDispatchDateFrom = from;
      this.param.jobDispatchDateTo = to;
    }

    this.param.storeId = this.selectedStore.isAll ? null : this.selectedStore.store_id;
    this.param.typeId = this.selectedType.isAll ? null : this.selectedType.type_id;

    if (this.selectedType.isAll || this.selectedType.allow_job_dispatch) {
      // Only pass back status filter option if filtering all types, or target type allows job dispatch
      this.param.statusTranslateKey = this.selectedStatus.isAll ? null : this.selectedStatus.statusTranslateKey;
    } else {
      this.param.statusTranslateKey = null;
    }

    this.param.content = this.contentKeyword;

    this.onReportFilterApply.emit(this.param);
    this.reportFilterBack();
  }
}
