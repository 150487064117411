import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { TimestampService } from '../../../../utilities/timestamp/timestamp.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DateService } from '../../../../utilities/date/date.service';
import { CustomFieldsMapped } from '../models/custom-field';

@Component({
  selector: 'tn-field-date',
  templateUrl: './field-date.component.html',
  styleUrls: ['./field-date.component.scss']
})
export class FieldDateComponent implements OnInit {

  @Input() field: CustomFieldsMapped;
  @Input() isInput: boolean = false;

  @Output() onFieldUpdate: EventEmitter<CustomFieldsMapped> = new EventEmitter<CustomFieldsMapped>(null);

  dateDisplay: string;
 
  dateInput: Date = null;
  dateInputConfig: Partial<BsDatepickerConfig>;

  constructor(
    private _timestampService: TimestampService,
    private _dateService: DateService,
    private _changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.dateInputConfig = Object.assign({}, { 
      containerClass: "theme-default",
      dateInputFormat: "YYYY-MM-DD"
    });
  }

  ngOnChanges() {
    if (this.field.answer.value) {
      this.dateDisplay = this._dateService.getDateDisplayWithKey(
        this.field.answer.value,
        "FORM_DETAIL_REPORT_DATE"
      );
      this.dateInput = this._timestampService.secondToDateObj(this.field.answer.value);
      this._changeDetectorRef.detectChanges();
    }
  }

  ngAfterViewInit() {
    this._changeDetectorRef.detectChanges();
  }

  getAnswerValue(): string {
    return this.dateInput ? this._timestampService.dateObjToSecondString(this.dateInput) : "";
  }

  onChange(newVal) {
    this.dateInput = newVal;
    this.field.answer.value = this.getAnswerValue();
    this.onFieldUpdate.emit(this.field);
  }

}
