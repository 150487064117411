import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { CronjobTracker, CronJob, CronJobStatus } from './models/cronjob';
import { TeamnoteApiService } from '../../api/teamnote-api.service';
import { LoggerService } from '../logger/logger.service';
import { TeamNoteApiConstant } from '../../constants/api.constant';
import { NotificationCenterService } from '../notification-center/services/notification-center.service';
import { TnNotificationService } from '../tn-notification/tn-notification.service';

@Injectable()
export class CronjobTrackerService {

  cronjobTrackers: CronjobTracker[] = [];

  finishedCronjobs: any[] = [];

  trackingInterval: any;
  trackingIntervalSecond: number = 10;

  constructor(
    private _teamnoteApiService: TeamnoteApiService,
    private _loggerService: LoggerService,
    private _notificationCenterService: NotificationCenterService,
    private _tnNotificationService: TnNotificationService
  ) { }

  addTrackingCronjobId(jobId: string, jobType: string, jobInfo: any, jobTitle?: string, jobTitleTranslateKey?: string): void {
    let newTracker = new CronjobTracker(jobId, jobType, jobInfo, jobTitle, jobTitleTranslateKey);
    this.cronjobTrackers.push(newTracker);
    this.getCronjobStatus(newTracker);
  }

  clearTrackingInterval(): void {
    clearInterval(this.trackingInterval);
    this._loggerService.debug("Cleared cronjob tracking interval");
  }

  startTrackingInterval(): void {
    this._loggerService.debug("Start cronjob tracking interval");
    this.trackingInterval = setInterval(
      () => {
        this.checkCronjobsStatus();
      },
      this.trackingIntervalSecond * 1000
    );
  }

  startTrackingCronjob(jobId: string, jobType: string, jobInfo: any, jobTitle?: string, jobTitleTranslateKey?: string): void {
    this.addTrackingCronjobId(jobId, jobType, jobInfo, jobTitle, jobTitleTranslateKey);
    
    this.clearTrackingInterval();
    this.startTrackingInterval();
  }

  updateTrackingCronjob(jobTracker: CronjobTracker, job: CronJob): void {
    jobTracker.job = job;
    jobTracker.isFinished = job.status == CronJobStatus.FINISHED || job.status == CronJobStatus.ERROR;

    if (jobTracker.isFinished) {
      this._tnNotificationService.showCustomInfoByTranslateKey(jobTracker.jobInfo.finishMsgTranlsteKey);
      this._tnNotificationService.showBrowserNotificationByTranslateKey(jobTracker.jobInfo.finishMsgTranlsteKey);
    }

    // update notification center
    this._notificationCenterService.updateCronjobNotification(
      jobTracker,
      () => {
        this.cronJobResultOnClick(jobTracker);
      }
    );

    // check if all jobs are finished
    this.checkIfJobsFinished();
  }

  checkIfJobsFinished(): void {
    let unfishedJobs = _.filter(this.cronjobTrackers, {isFinished: false});
    if (unfishedJobs.length == 0) {
      this._loggerService.debug("All cronjobs are finished, clearing tracking interval.")
      this.clearTrackingInterval();
    }
  }

  getCronjobStatus(jobTracker: CronjobTracker): void {
    let url = TeamNoteApiConstant.CRONJOB.GET;
    let params = {
      job_id: jobTracker.jobId
    };
    this._teamnoteApiService.callApi(
      url,
      params,
      (resp: CronJob) => {
        this.updateTrackingCronjob(jobTracker, resp);
      },
      (err) => {

      },
      null,
      null,
      true
    );
  }

  checkCronjobsStatus(): void {
    _.each(this.cronjobTrackers, (jobTracker) => {
      if (!jobTracker.isFinished) {
        this.getCronjobStatus(jobTracker);
      }
    });
  }

  cronJobResultOnClick(jobTracker: CronjobTracker): void {
    if (!jobTracker.isFinished) {
      return;
    }
    console.error(jobTracker);
  }
}
