import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoStreamingComponent } from './video-streaming.component';
import { UtilitiesModule } from '../../utilities/utilities.module';

@NgModule({
  declarations: [VideoStreamingComponent],
  imports: [
    CommonModule,
    UtilitiesModule
  ],
  exports: [
    
  ]
})
export class VideoStreamingModule { }
