<div class="attachment-pdf-grid-wrapper">
  <div class="modal-header">
    <h4 class="text-color-primary">{{'WEBCLIENT.CHATROOM.DOCUMENT' | translate}}</h4>
  </div>
  <div class="attachment-grid-body">
    <div *ngFor="let monthAttachments of timelinedAttachments">
      <tn-attachment-timelined [year]="monthAttachments.year" [month]="monthAttachments.month"></tn-attachment-timelined>
      <div class="attachment-grid">
        <div class="pdf-preview-wrapper clickable"
          (click)="onClick(pdf)"
          *ngFor="let pdf of monthAttachments.attachments">
          
          <ng-container [ngSwitch]="getFileTypeByFileIds(pdf.attachment_id)">
            <div class="pdf-icon" *ngSwitchCase="ATTACHMENT_TYPE.PDF"></div>
            <div class="doc-icon" *ngSwitchDefault></div>
          </ng-container>
          
          <div class="pdf-info">
            <div class="pdf-name text-color-primary">
              {{pdf.filename}}
            </div>
            <div class="pdf-date text-color-secondary">
              {{pdf.timestampDisplay}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <tn-empty-page 
      [msgKey]="'GENERAL.NO_DOCUMENT'" 
      *ngIf="attachments.length == 0">
    </tn-empty-page>
  </div>
</div>