<div class="corporate-material-picker-wrapper">
  <!-- <div class="picker-header">
    <h4>{{header}}</h4>
  </div> -->

  <div class="picker-body">
    <tn-corporate-material-list
      [folder]="currentFolder"
      [isRootFolder]="isRootFolder"
      [rootTranslateKey]="rootTranslateKey"
      [fileAllowTypes]="fileAllowTypes"
      (onItemClick)="onItemClick($event)"
      (onFolderBack)="onFolderBack($event)"
      (searchFolderByKeyword)="searchFolderByKeyword($event)">
    </tn-corporate-material-list>


    <div class="preview-txt" *ngIf="isPreviewTxt">
      <div class="header">Preview ({{previewingTxtFile.name}})</div>
      <div class="content">{{previewingTxtContent}}</div>
      <div class="buttons">
        <button class="btn tn-button-primary" (click)="onMaterialConfirm(previewingTxtFile)">
          Select
        </button>
      </div>
    </div>
  </div>
</div>