<div class="starred-messages-cell">
  <div class="starred-messages-info text-color-secondary">
    <div class="sender-info">
      <div class="sender-avatar-wrapper clickable">
        <div
          class="avatar default-contact-avatar"
          (click)="onUserAvatarClick(starredMessage.senderContact)"
          [ngStyle]="{ 'background-image': starredMessage.senderContact.avatarImageSrc }"
        ></div>
      </div>
      <div 
        class="sender-name"
        [ngClass]="{
          'important-user': isEnableImportantUsers,
          'global-vip': starredMessage.senderContact.importantLevel === IMPORTANT_LEVEL.GLOBAL,
          'individual-vip': starredMessage.senderContact.importantLevel === IMPORTANT_LEVEL.INDIVIDUAL
        }"
      >
        {{ starredMessage.senderContact.name }}
        <!-- <ng-container *ngIf="showUserField && starredMessage.senderContact[userField]"
          >({{ starredMessage.senderContact[userField] }})</ng-container
        > -->
        <ng-container *ngIf="isEnableImportantUsers">
          <div class="global-vip-icon" *ngIf="starredMessage.senderContact.importantLevel === IMPORTANT_LEVEL.GLOBAL"></div>
          <div class="individual-vip-icon" *ngIf="starredMessage.senderContact.importantLevel === IMPORTANT_LEVEL.INDIVIDUAL"></div>
        </ng-container>
        <ng-container *ngIf="starredMessage.senderContact.deleted">
          {{ 'WEBCLIENT.CONTACT.DELETED_POSTFIX' | translate }}
        </ng-container>
      </div>
      <ng-container *ngIf="!isInChat">
        <div class="sender-group-name" *ngIf="starredMessage.chat && starredMessage.chat.isGroup">
          <span class="dot">·</span>{{ starredMessage.chat.displayName }}
        </div>
      </ng-container>
    </div>

    <div class="message-timestamp">
      {{ starredMessage.timestamp | tnDate : 'STARRED_MESSAGES_DATE' }}
    </div>
  </div>

  <div
    class="starred-message-row-wrapper"
    *ngIf="
      starredMessage.type == MSG_TYPE.TEXT ||
      starredMessage.type == MSG_TYPE.ATTACHMENT ||
      starredMessage.type == MSG_TYPE.LOCATION ||
      starredMessage.type == MSG_TYPE.STICKER
    "
  >
    <div matRipple class="jump-button clickable" (click)="goToMessageInChat(starredMessage)">
      <i class="fa fa-fw fa-2x fa-angle-right"></i>
    </div>

    <div
      class="starred-message-row"
      [ngClass]="{
        'message-by-me': starredMessage.isSentByMe,
        sticker: starredMessage.type == MSG_TYPE.STICKER && !starredMessage.comment_parent
      }"
    >
      <div class="message-body">
        <div
          class="message-content-row"
          [ngClass]="{
            'message-by-me': starredMessage.isSentByMe,
            'message-whisper': starredMessage.isWhisper
          }"
        >
          <div class="message-bubble-tip" [ngClass]="{ 'bubble-rotate': starredMessage.isSentByMe }"></div>

          <div
            class="message-content"
            [ngClass]="{
              text: starredMessage.type == MSG_TYPE.TEXT && !starredMessage.comment_parent && !starredMessage.parsedBody.is_encrypted,
              'horizontal-hover': starredMessage.type == MSG_TYPE.TEXT || starredMessage.type == MSG_TYPE.LOCATION,
              'angled-hover': starredMessage.type == MSG_TYPE.ATTACHMENT || starredMessage.comment_parent
            }"
            [ngStyle]="{ color: starredMessage.isSentByMe ? '#fff' : '#000' }"
          >
            <ng-container *ngIf="starredMessage.parsedBody.is_encrypted">
              <!-- encrypted message preview -->
              <div class="encrypted-message">
                <div class="encrypted-message-lock-icon"></div>
                <div class="encrypted-message-preview">
                  {{ 'WEBCLIENT.CHATROOM.ENCRYPTED_MESSAGE.STARRED_MESSAGE_PREVIEW' | translate }}
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="!starredMessage.parsedBody.is_encrypted">
              <div class="displaying-content">
                <!-- replying message body -->
                <tn-message-parent
                  class="clickable parent-message-bgc"
                  [message]="starredMessage.comment_parent"
                  *ngIf="starredMessage.comment_parent"
                >
                </tn-message-parent>

                <!-- message body -->
                <tn-text-message
                  [ngClass]="{
                    'link-style-other': !starredMessage.isSentByMe,
                    'link-style-me': starredMessage.isSentByMe
                  }"
                  [body]="starredMessage.parsedBody"
                  *ngIf="starredMessage.type == MSG_TYPE.TEXT"
                >
                </tn-text-message>

                <tn-attachment-message
                  [isSentByMe]="starredMessage.isSentByMe"
                  [body]="starredMessage.parsedBody"
                  [attachments]="starredMessage.attachments"
                  [messageId]="starredMessage.message_id"
                  (onAttachmentClick)="onAttachmentClick($event)"
                  [isClickable]="true"
                  *ngIf="starredMessage.type == MSG_TYPE.ATTACHMENT"
                >
                </tn-attachment-message>

                <tn-location-message
                  class="clickable"
                  (click)="onLocationClick(starredMessage.parsedBody)"
                  [body]="starredMessage.parsedBody"
                  *ngIf="starredMessage.type == MSG_TYPE.LOCATION"
                >
                </tn-location-message>

                <tn-sticker-message [body]="starredMessage.parsedBody" *ngIf="starredMessage.type == MSG_TYPE.STICKER">
                </tn-sticker-message>
              </div>

              <div class="hovering-menu">
                <tn-hover-dropdown-menu
                  *ngIf="starredMessage.message_id"
                  [id]="starredMessage.message_id"
                  (click)="onContextMenuClick($event, starredMessage)"
                >
                </tn-hover-dropdown-menu>
              </div>
            </ng-container>
          </div>

          <div
            class="message-info text-color-secondary"
            [ngClass]="{ 'read-ticks': isEnableReadTicks }"
            *ngIf="starredMessage.message_id"
          >
            <!-- default 'Read' mark -->
            <ng-container *ngIf="starredMessage.isSentByMe && !isEnableReadTicks">
              <div class="read-by-count" *ngIf="starredMessage.readByOtherCount > 0">
                <span *ngIf="!starredMessage.chat.isGroup">{{ 'WEBCLIENT.CHATROOM.READ' | translate }}</span>
                <span *ngIf="starredMessage.chat.isGroup">
                  {{ 'WEBCLIENT.CHATROOM.READ_GROUP' | translate : { NUM: starredMessage.readByOtherCount } }}
                </span>
              </div>
            </ng-container>

            <!-- <div class="message-time">
              {{ starredMessage.timestamp | tnDate : 'CHAT_MSG_TIME' }}
            </div> -->

            <div class="message-time">
              <div class="message-starred" *ngIf="isEnableStarMessage">
                <i class="text-color-primary fa fa-star"></i>
              </div>

              <span>
                {{ starredMessage.timestamp | tnDate : 'CHAT_MSG_TIME' }}
              </span>
            </div>

            <!-- two ticks -->
            <ng-container *ngIf="starredMessage.isSentByMe && isEnableReadTicks">
              <ng-container *ngIf="!starredMessage.chat.isGroup">
                <div *ngIf="starredMessage.readByOtherCount === 0">
                  <div class="message-single-tick" *ngIf="starredMessage.deliverByOtherCount === 0"></div>
                  <div class="message-deliver-tick" *ngIf="starredMessage.deliverByOtherCount > 0"></div>
                </div>

                <div *ngIf="starredMessage.readByOtherCount > 0">
                  <div class="message-read-tick"></div>
                </div>
              </ng-container>
              <ng-container *ngIf="starredMessage.chat.isGroup">
                <div *ngIf="starredMessage.readByOtherCount !== getTotalChatMembersCount(starredMessage.chat)">
                  <div
                    class="message-single-tick"
                    *ngIf="starredMessage.deliverByOtherCount < getTotalChatMembersCount(starredMessage.chat)"
                  ></div>
                  <div
                    class="message-deliver-tick"
                    *ngIf="starredMessage.deliverByOtherCount === getTotalChatMembersCount(starredMessage.chat)"
                  ></div>
                </div>

                <div
                  class="message-read-tick"
                  *ngIf="starredMessage.readByOtherCount === getTotalChatMembersCount(starredMessage.chat)"
                ></div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
