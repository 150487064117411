import { ModuleKeyDefinition, Module } from "../../constants/module.constant";
import { PageUrlConstant } from "../../constants/page-url.constant";
import { NewsCustomRouteParam } from "../../models/news";
import { NewsCategoryCustomRouteParam } from "../../webclient/news-category/models/news-category";
import { TeamNoteConfig } from "../../configs/models/config";

let MODULE_AA_GENERAL_NEWS = "AA_GENERAL_NEWS";
let MODULE_AA_NOTICE_BOARD = "AA_NOTICE_BOARD";
let MODULE_AA_IDEA_SOURCING = "AA_IDEA_SOURCING";

let CHAT_ID_KEY_GENERAL_NEWS = "general_news";
let CHAT_ID_KEY_NOTICE_BOARD = "notice_board";
let PREFIX_IDEA_SOURCING = "HKIA Innovator |";

export const HkaaConfig: TeamNoteConfig = {
  HOST: {
    DOMAIN: "hkaa"
  },
  GENERAL: {
    PAGE_TITLE: "EMM",
    ATTACHMENT_SIZE_LIMIT: 25
  },
  IMAGES: {
    NOTIFICATION_ICON: "assets/images/clients/hkaa/notification_icon.png",
    FAVICON: "assets/images/clients/hkaa/favicon.png",
    FAVICON_ALERT: "assets/images/clients/hkaa/favicon_alert.png"
  },
  NOTIFICATION: {
    ENABLE_NOTIFICATION_CENTER: true
  },
  MAP: {
    API_KEY: "AIzaSyDLjMXVOPX9NHtY5WwnC4Kv6Hg9dzOnwk4"
  },
  WEBCLIENT: {
    CHATROOM: {
      ENABLE_ENCRYPTED_MESSAGE: true,
      SHIFT_ENTER_TO_SEND: true,
      MUTE: {
        ONE_HOUR: true,
        TWO_HOURS: true,
        EIGHT_HOURS: true,
        ONE_WEEK: true,
        ONE_YEAR: true
      }
    },
    SIDE_NAV: {
      SIDE_NAV_ORDERING: [
        // AA HOME
        // MODULE_AA_GENERAL_NEWS,
        MODULE_AA_NOTICE_BOARD,
        // MODULE_AA_IDEA_SOURCING,

        ModuleKeyDefinition.CHAT,
        ModuleKeyDefinition.CONTACT,
        ModuleKeyDefinition.QUESTIONNAIRE,
        ModuleKeyDefinition.JOB_DISPATCH,
        ModuleKeyDefinition.STORE_REPORT,
        // DUTY ROSTER,
        ModuleKeyDefinition.STORE_SCHEDULE,
        ModuleKeyDefinition.TRAINING,
        ModuleKeyDefinition.CORPORATE_MATERIAL
      ],
      SIDE_NAV_UNREAD_COUNTS_MODULES: [
        ModuleKeyDefinition.CHAT,
        ModuleKeyDefinition.NEWS,
        // MODULE_AA_GENERAL_NEWS,
        MODULE_AA_NOTICE_BOARD,
        // MODULE_AA_IDEA_SOURCING
      ],
      SIDE_NAV_EXTRA_MODULES: [
        // new Module(
        //   MODULE_AA_GENERAL_NEWS,
        //   PageUrlConstant.WEBCLIENT.NEWS,
        //   "newspaper-o",
        //   MODULE_AA_GENERAL_NEWS,
        //   new NewsCustomRouteParam(
        //     null,
        //     CHAT_ID_KEY_GENERAL_NEWS,
        //     MODULE_AA_GENERAL_NEWS,
        //     true
        //   ),
        //   ModuleKeyDefinition.NEWS
        // ),
        new Module(
          MODULE_AA_NOTICE_BOARD,
          PageUrlConstant.WEBCLIENT.NEWS,
          "newspaper-o",
          MODULE_AA_NOTICE_BOARD,
          new NewsCustomRouteParam(
            null,
            CHAT_ID_KEY_NOTICE_BOARD,
            MODULE_AA_NOTICE_BOARD,
            true
          ),
          ModuleKeyDefinition.NEWS
        ),
        // new Module(
        //   MODULE_AA_IDEA_SOURCING,
        //   PageUrlConstant.WEBCLIENT.NEWS_CATEGORY,
        //   "newspaper-o",
        //   MODULE_AA_IDEA_SOURCING,
        //   new NewsCategoryCustomRouteParam(
        //     PREFIX_IDEA_SOURCING,
        //     MODULE_AA_IDEA_SOURCING
        //   )
        // )
      ]
    }
  }
};
