import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TeamnoteConfigService } from '../../../../configs/teamnote-config.service';

export class MutePeriodOption {
  key: string;
  ttl: number;

  constructor(key: string, ttl: number) {
    this.key = key;
    this.ttl = ttl;
  }
}

@Component({
  selector: 'tn-mute-group',
  templateUrl: './mute-group.component.html',
  styleUrls: ['./mute-group.component.scss']
})
export class MuteGroupComponent implements OnInit {

  mutePeriodOptions: MutePeriodOption[] = [];
  selectedMutePeriodOption: MutePeriodOption;

  constructor(
    public dialogRef: MatDialogRef<MuteGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _teamnoteConfigService: TeamnoteConfigService
  ) { }

  ngOnInit() {
    this.dialogRef.disableClose = true;
    this.dialogRef.updateSize("30vw");

    this.buildMuteOptions();
  }

  buildMuteOptions(): void {
    this.mutePeriodOptions = [];

    if (this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.MUTE.ONE_HOUR) {
      // 1 hour
      this.mutePeriodOptions.push(
        new MutePeriodOption(
          "WEBCLIENT.CHAT.CHAT_GROUP.NOTIFICATION.ONE_HOUR",
          60 * 60 * 1
        )
      );
    }

    if (this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.MUTE.TWO_HOURS) {
      // 2 hours
      this.mutePeriodOptions.push(
        new MutePeriodOption(
          "WEBCLIENT.CHAT.CHAT_GROUP.NOTIFICATION.TWO_HOURS",
          60 * 60 * 2
        )
      );
    }

    if (this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.MUTE.EIGHT_HOURS) {
      // 8 hours
      this.mutePeriodOptions.push(
        new MutePeriodOption(
          "WEBCLIENT.CHAT.CHAT_GROUP.NOTIFICATION.EIGHT_HOURS",
          60 * 60 * 8
        )
      );
    }

    if (this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.MUTE.ONE_WEEK) {
      // 1 week
      this.mutePeriodOptions.push(
        new MutePeriodOption(
          "WEBCLIENT.CHAT.CHAT_GROUP.NOTIFICATION.ONE_WEEK",
          60 * 60 * 24 * 7
        )
      );
    }

    if (this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.MUTE.ONE_YEAR) {
      // 1 year
      this.mutePeriodOptions.push(
        new MutePeriodOption(
          "WEBCLIENT.CHAT.CHAT_GROUP.NOTIFICATION.ONE_YEAR",
          60 * 60 * 24 * 365
        )
      );
    }

    // set default
    this.selectedMutePeriodOption = this.mutePeriodOptions[0];
  }

  cancel(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    this.data.confirmCallback(this.selectedMutePeriodOption);
    this.dialogRef.close();
  }

}
