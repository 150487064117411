<div class="sticker-selector">
  <div class="sticker-category-selector">
    <div class="sticker-category-wrapper" (click)="onRecentClick()">
      <div class="sticker-category clickable hover-scale recent">
        
      </div>
    </div>
    <div class="sticker-category-wrapper" 
      (click)="onCategoryClick(cat)"
      *ngFor="let cat of stickerCategoryArr">
      <div class="sticker-category clickable hover-scale"
        [ngStyle]="{'background-image': cat.iconBgUrl}"
      ></div>
    </div>
  </div>
  <div class="sticker-selector-container">
    <div class="sticker-container" *ngIf="isShowingRecent">
      <div class="sticker-wrapper"
        (click)="onClick(sticker)"
        *ngFor="let sticker of recentStickers">
        <div class="sticker clickable hover-scale"
          [ngStyle]="{'background-image': sticker.stickerBgUrl}"
        ></div>
      </div>
    </div>

    <div class="sticker-container" *ngIf="!isShowingRecent">
      <div class="sticker-wrapper" 
        (click)="onClick(sticker)"
        *ngFor="let sticker of displayingStickerArr">
        <div class="sticker clickable hover-scale"
          [ngStyle]="{'background-image': sticker.stickerBgUrl}"
        ></div>
      </div>
    </div>
  </div>
</div>