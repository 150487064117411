import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ParsedFile } from '../file-uploader/file-uploader.component';

import * as Konva from 'konva';
import { FileManagerService } from '../file-manager/file-manager.service';

export const SignatureConstants = {
  default: {
    width: 1000,
    height: 600,
    name: "signature-image.jpg"
  }
};

@Component({
  selector: 'tn-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit {

  signatureFile: ParsedFile;
  saveCallbackWithDataUrlAndIsSigned: Function;

  isSigned: boolean = false;

  image: HTMLImageElement;
  signatureConfigs = SignatureConstants;

  // Constant Settings
  IMAGE_CONTAINER_ID = 'signature-image-container';
  MAX_IMAGE_HEIGHT_RATIO = 0.7;
  MAX_IMAGE_WIDTH_RATIO = 0.7;

  // Konva
  stage: Konva.Stage;
  signatureLayer: Konva.Layer;

  signatureDrawingCanvas: any = null;
  signatureDrawingCtx: CanvasRenderingContext2D = null;
  isPainting: boolean = false;
  lastPointerPosition: any;

  signatureImageObj: Konva.Image;

  constructor(
    public dialogRef: MatDialogRef<SignatureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fileManagerService: FileManagerService
  ) { }

  ngOnInit() {
    this.init();
  }

  init(): void {
    this.signatureFile = this.data.signatureFile;
    this.isSigned = this.data.isSigned;
    this.saveCallbackWithDataUrlAndIsSigned = this.data.saveCallbackWithDataUrlAndIsSigned;

    this.prepareSignatureCanvas(this.signatureFile.imagePreview);
  }

  prepareSignatureCanvas(dataUrl): void {
    this.image = new Image();
    this.image.src = dataUrl;
    this.image.onload = () => {
      if (this.image.width > this.signatureConfigs.default.width || this.image.height > this.signatureConfigs.default.height) {
        this.resizeAFileToDefaultSize(this.signatureFile.file);
      } else {
        this.createKonvaObjects();
      }
    };
  }

  createKonvaObjects(): void {
    this.createKonvaStage();
    this.createKonvaLayer();
    this.prepareSignatureDrawActions();
  }

  createKonvaStage(): void {
    this.stage = new Konva.Stage({
      container: this.IMAGE_CONTAINER_ID,
      width: this.signatureConfigs.default.width,
      height: this.signatureConfigs.default.height,
    });
  }

  createKonvaLayer(): void {
    this.signatureLayer = new Konva.Layer();
    this.stage.add(this.signatureLayer);
  }

  prepareSignatureDrawActions(): void {
    this.signatureDrawingCanvas = document.createElement("canvas");
    this.signatureDrawingCanvas.width = this.signatureConfigs.default.width;
    this.signatureDrawingCanvas.height = this.signatureConfigs.default.height;
    this.signatureDrawingCtx = this.signatureDrawingCanvas.getContext("2d");
    this.signatureDrawingCtx.lineJoin = "round";
    this.signatureDrawingCtx.fillStyle = "white";
    this.signatureDrawingCtx.fillRect(0, 0, this.signatureDrawingCanvas.width, this.signatureDrawingCanvas.height);

    this.signatureDrawingCtx.strokeStyle = "black";
    this.signatureDrawingCtx.lineWidth = 3;
    this.signatureDrawingCtx.globalCompositeOperation = 'source-over';

    // Draw original image at center
    this.signatureDrawingCtx.drawImage(
      this.image,
      this.signatureConfigs.default.width / 2 - this.image.width / 2,
      this.signatureConfigs.default.height / 2 - this.image.height / 2
    );

    this.signatureImageObj = new Konva.Image({
      x: 0,
      y: 0,
      width: this.signatureDrawingCanvas.width,
      height: this.signatureDrawingCanvas.height,
      image: this.signatureDrawingCanvas
    });
    this.signatureLayer.add(this.signatureImageObj);
    this.signatureLayer.draw();

    this.stage.on('contentMousedown.proto', () => {
      this.isPainting = true;
      this.lastPointerPosition = this.stage.getPointerPosition();
    });
    this.stage.on('contentMouseup.proto', () => {
      this.isPainting = false;
    });
    this.stage.on('contentMousemove.proto', () => {
      if (!this.isPainting) {
        return;
      }
      this.signatureDrawingCtx.beginPath();

      //line from last position
      this.signatureDrawingCtx.moveTo(this.lastPointerPosition.x / this.stage.scaleX(), this.lastPointerPosition.y / this.stage.scaleY());
      //draw to current position
      let pos = this.stage.getPointerPosition();
      this.signatureDrawingCtx.lineTo(pos.x / this.stage.scaleX(), pos.y / this.stage.scaleY());

      this.signatureDrawingCtx.closePath();
      this.signatureDrawingCtx.stroke();

      this.lastPointerPosition = pos;
      this.stage.batchDraw();
      this.isSigned = true;
    });
    
    this.stage.batchDraw();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  clear(): void {
    this.signatureDrawingCtx.fillRect(0, 0, this.signatureDrawingCanvas.width, this.signatureDrawingCanvas.height);

    this.isSigned = false;
    this.stage.batchDraw();
  }

  save(): void {
    let signatureImg = this.stage.toDataURL(
      {
        callback: (dataUrl) => {
          this.saveCallbackWithDataUrlAndIsSigned(dataUrl, this.isSigned);
          this.cancel();
        },
        mimeType: 'image/jpeg'
      }
    );
  }

  handleFileInputChange(e): void {
    let files = e.target.files;

    let firstFile = files[0];
    this.resizeAFileToDefaultSize(firstFile);
  }

  resizeAFileToDefaultSize(file: File): void {
    this._fileManagerService.compressImageByFile(
      file,
      (resizedFile) => {
        this._fileManagerService.fileToDataUrl(
          resizedFile,
          (dataUrl) => {
            this.prepareSignatureCanvas(dataUrl);
            this.isSigned = true;
          }
        );
      },
      false,
      this.signatureConfigs.default.width,
      this.signatureConfigs.default.height,
      1
    );
  }

}
