import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'tn-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  msg: string;
  confirmCallback: Function;
  cancelCallback: Function;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.dialogRef.disableClose = true;
    this.dialogRef.updateSize('40vw');

    this.msg = this.data.msg;
    this.confirmCallback = this.data.confirmCallback;
    this.cancelCallback = this.data.cancelCallback;
  }

  cancel() {
    if (this.cancelCallback) {
      this.cancelCallback();
    }
    this.dialogRef.close();
  }

  confirm() {
    this.confirmCallback();
    this.dialogRef.close();
  }

}
