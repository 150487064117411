import { Injectable } from '@angular/core';

import { LocalStorageManagerService } from '../local-storage/local-storage-manager.service';

import * as _ from 'lodash';
import { EmojiConstant } from '../../constants/emoji.constant';
import { EmojiAppConstant } from '../../constants/emoji_app.constant';
import { TeamNoteLocalStorageKeyConstants } from '../../constants/local-storage-key.constant';
import { FileManagerService } from '../file-manager/file-manager.service';
import { Message } from '../../models/message';
import { EmojiMenuItem } from '../../utilities/context-menu/context-menu.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EmojiService {
  recentEmoji: any[] = [];

  specialMapping = EmojiConstant.SPECIAL_MAPPING;
  emojiMenu: any;

  emojiJsonPath = 'assets/images/emoji_app/emoji_list.json'
  appEmojiSet: any = {};

  constructor(
    private _localStorageManagerSerive: LocalStorageManagerService,
    private _fileManagerService: FileManagerService,
    private _http: HttpClient,
  ) { 

  }

  installAppEmojiSet(): void {
    this.emojiJsonPath = window.location.pathname + this.emojiJsonPath;
    
    this._http.get(this.emojiJsonPath).subscribe(
      (emojiJson: any) => {
        this.appEmojiSet = emojiJson;
        this.buildAppEmojiSet();
      },
      (err) => {

      }
    )
  }

  buildAppEmojiSet(): void {
    let tempEmojiSet = {}

    _.forEach(this.appEmojiSet, (emojiSet, key) => {

      if (!tempEmojiSet[key]) {
        tempEmojiSet[key] = {}
      }
      // filter emoji of ios_version < 11
      let filtered = _.filter(emojiSet, (emojiObj) => Number(emojiObj.ios_version) < 11)

      tempEmojiSet[key].LABEL = key;
      tempEmojiSet[key].BODY = EmojiAppConstant.CATEGORIES[key];
      tempEmojiSet[key].EMOJI = filtered;
      
    })

    this.appEmojiSet = _.cloneDeep(tempEmojiSet);
  }

  getAppEmojiSet(): any {
    return this.appEmojiSet;
  }

  decodeUtf8(unicode: string): string {
    if (!_.includes(unicode, '\\x')) {
      return unicode
    }

    let emojiCode = unicode.split("\\x").join('%');
    return decodeURIComponent(emojiCode)
  }

  getFileName(emoji: any): string {
    if (!emoji) {
      return;
    }
    if (this.specialMapping[emoji]) {
      return this.specialMapping[emoji];
    }
    let file;
    if (typeof emoji === "string") {
      file = this.getFileNameFromUnicode(emoji);
    } else if (typeof emoji === "number") {
      file = this.getFileNameFromHex(emoji);
    }
    while (file.length < 4) {
      file = '0' + file;
    }
    return file;
  }

  getFileNameFromUnicode(code: string): string {
    // return code.codePointAt(0).toString(16);     //IE not supporting codePointAt
    let result;
    if (code.charCodeAt(1)) {
      result = ((((code).charCodeAt(0) - 0xD800) * 0x400) + ((code).charCodeAt(1) - 0xDC00) + 0x10000).toString(16);
    } else {
      result = code.charCodeAt(0).toString(16);
    }
    return result;
  }

  getFileNameFromHex(hex: number): string {
    return hex.toString(16);
  }

  getUnicodeByEmoji(emoji): string {
    if (this.specialMapping[emoji]) {
      return emoji;
    }
    return this.getUnicodeFromHex(emoji);
  }
  
  getUnicodeFromHex(hex): string {
    // return String.fromCodePoint(hex);        //IE not supporting fromCodePoint
    let chars = [], point, offset, units, i;
    for (i = 0; i < arguments.length; ++i) {
      point = arguments[i];
      offset = point - 0x10000;
      units = point > 0xFFFF ? [0xD800 + (offset >> 10), 0xDC00 + (offset & 0x3FF)] : [point];
      chars.push(String.fromCharCode.apply(null, units));
    }
    return chars.join("");
  }

  getEmojiPathByFileName(fileName: string): string {
    return EmojiConstant.EMOJI_BASE_PATH_PREFIX + fileName + EmojiConstant.EMOJI_BASE_PATH_POSTFIX;
  }

  // Recents
  setRecentEmojiCookies() {
    this._localStorageManagerSerive.setCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.EMOJI, _.join(this.recentEmoji, ","));
  }
  getRecentEmojiCookies(): any {
    return this._localStorageManagerSerive.getCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.EMOJI);
  }
  addToRecent(emoji): void {
    this.recentEmoji = _.without(this.recentEmoji, emoji);
    this.recentEmoji = _.union([emoji], this.recentEmoji);
    this.setRecentEmojiCookies();
  }
  getRecentEmojis(): any {
    var temp =  this.getRecentEmojiCookies();
    var tempArr = temp ? _.split(temp, ",") : [];
    // this.recentEmoji = _.map(tempArr, (data) => {
    //   return _.toInteger(data);
    // });
    this.recentEmoji = tempArr;
    return this.recentEmoji;
  }

  buildEmojiMenuInDropDownMenu(m: Message, emojiClickCallback?: Function, emojiSendCallback?: Function): EmojiMenuItem {
    this.emojiMenu = _.cloneDeep(EmojiAppConstant.EMOJI_MENU)

    this.emojiMenu.isSentEmoji = m.isSentEmoji

    this.emojiMenu.emojiDisplays = []

    this.emojiMenu.emojiDisplays = _.map(this.emojiMenu.EMOJI, (e) => ({ ...e }));

    if (emojiClickCallback) {
      this.emojiMenu.emojiClickCallback = emojiClickCallback
    }

    if (emojiSendCallback) {
      this.emojiMenu.emojiSendCallback = emojiSendCallback
    }

    return this.emojiMenu;
  }

}
