<div class="training-list-container" *ngIf="folder">
  <tn-page-title [title]="isRootFolder ? ('WEBCLIENT.MENU.TRAINING' | translate) : folder.name">
    <div class="left-control">
      <div class="back-button clickable" *ngIf="!isRootFolder" (click)="folderBack()">
        <i class="fa fa-fw fa-angle-left"></i>
      </div>
    </div>
  </tn-page-title>

  <div class="training-list">
    <ng-container *ngIf="(folder.folders && folder.folders.length > 0) || (folder.files && folder.files.length > 0)">
      <tn-training-folder 
        *ngFor="let f of folder.folders" 
        [folder]="f" 
        (click)="folderClick(f)">
      </tn-training-folder>
      <tn-training-file 
        *ngFor="let f of folder.files" 
        [file]="f" 
        (click)="fileClick(f)">
      </tn-training-file>
    </ng-container>

    <tn-empty-page *ngIf="(folder.folders && folder.folders.length == 0) && (folder.files && folder.files.length == 0)"></tn-empty-page>
  </div>
</div>