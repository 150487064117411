<div class="vote-modal" *ngIf="vote">
  <div class="modal-body vote-modal-body">
    <div class="avatar-wrapper">
      <div class="avatar" [ngStyle]="{'background-image': iconImgSrc}"></div>
    </div>
    <div class="vote-content-wrapper">
      <div class="title">{{vote.title}}</div>
      <div class="create-date text-color-secondary">{{vote.vote_start | tnDate: 'NEWS_DATE'}}</div>
      <div class="content">
        <p [innerHTML]="vote.htmlContent"></p>
      </div>

      <div class="vote" *ngIf="isVoting">
        <div class="voted-error-msg" *ngIf="!vote.isAllowSubmit">
          {{'WEBCLIENT.NEWS.ERROR_MSG.VOTED' | translate}}
        </div>

        <div class="option-list">
          <div class="option-cell" *ngFor="let option of vote.options">
            <label class="option-label">
              <input 
                type="radio" 
                class="option-selector"
                [(ngModel)]="vote.radioOptionId" 
                [value]="option.option_id"
                [disabled]="!vote.isAllowSubmit"
                *ngIf="!vote.allow_multiple_choice">

              <input 
                type="checkbox" 
                class="option-selector"
                [(ngModel)]="option.value" 
                (change)="updateSelection(option.value, option.option_id)"
                [disabled]="!vote.isAllowSubmit"
                *ngIf="vote.allow_multiple_choice">

              <tn-vote-option class="option-content" [option]="option"></tn-vote-option>
            </label>
          </div>
        </div>
        <div class="submit-button-wrapper" *ngIf="vote.isAllowSubmit">
          <button class="submit-button" 
                  (click)="submitVote()" 
                  [disabled]="!vote.radioOptionId && vote.checkboxOptionsIds.length === 0">
              {{'WEBCLIENT.NEWS.VOTE.VOTE_BTN' | translate}}
          </button>
        </div>
      </div>

      <div class="result" *ngIf="!isVoting">

        <div class="option-wrapper" *ngFor="let option of vote.options">
          <div class="option-content">
            <tn-vote-option [option]="option"></tn-vote-option>
          </div>
          <div class="option-description">
            <span *ngIf="option.count <= 1">
              {{'WEBCLIENT.NEWS.VOTE.VOTE_OPTION_DESC.ONE' | translate: {'PERCENTAGE': option.percentage, 'COUNT': option.count} }}
            </span>
            <span *ngIf="option.count > 1">
              {{'WEBCLIENT.NEWS.VOTE.VOTE_OPTION_DESC.MULTIPLE' | translate: {'PERCENTAGE': option.percentage, 'COUNT': option.count} }}
            </span>
          </div>
          <div class="option-result-bar">
            <div class="voted-highlight vote-result-bar" [ngStyle]="{width: option.percentage + '%'}"></div>
          </div>
        </div>

      </div>


    </div>
  </div>
</div>