<div class="job-dispatch-list">
  <ng-container *ngIf="jobDispatchs.length > 0">
    <div virtualScroller
      class="full-height"
      [ssrChildHeight]="80"
      [items]="jobDispatchs"
      (vsUpdate)="scrollItems = $event">
      <ng-container *ngFor="let j of scrollItems">
        <tn-job-report-cell 
          [job]="j"
          [viewingJob]="viewingJob"
          [isEnabledJobDispatch]="isEnabledJobDispatch"
          (onJobCellClick)="onJobCellClick($event)">
        </tn-job-report-cell>
      </ng-container>
    </div>
  </ng-container>
  
  <tn-empty-page *ngIf="jobDispatchs.length == 0"></tn-empty-page>
</div>