export class CustomLoginResponse {
  isSuccess: boolean; // Indicate if API call is success or not
  nextApiCall: string; // function name of next api call

  // if isSuccess = true
  nextPageUrl: string; // Target page for next action
  params: {}; // Optional params to be passed to next page

  // if isSuccess = false
  errorStatus: number; // HTTP error status
  errorMsg: string; // Error msg to be displayed
}
