import { v4 as uuidv4 } from 'uuid';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomFieldsMapped } from '../models/custom-field';

export class FieldMultipleChoiceDetail {
  options: string[];
}

@Component({
  selector: 'tn-field-multiple-choice',
  templateUrl: './field-multiple-choice.component.html',
  styleUrls: ['./field-multiple-choice.component.scss']
})

export class FieldMultipleChoiceComponent implements OnInit {

  @Input() field: CustomFieldsMapped;
  @Input() isInput: boolean = false;

  @Output() onFieldUpdate: EventEmitter<CustomFieldsMapped> = new EventEmitter<CustomFieldsMapped>(null);

  detail: FieldMultipleChoiceDetail;
  selectedValue: string;
  fieldId: string;

  constructor() { }

  ngOnInit() {
    this.fieldId = uuidv4();
  }

  ngOnChanges() {
    this.detail = JSON.parse(this.field.definition.detail);
    this.selectedValue = this.field.answer.value;
  }

  getAnswerValue(): string {
    return this.selectedValue;
  }

  onChange() {
    this.field.answer.value = this.getAnswerValue();
    this.onFieldUpdate.emit(this.field);
  }

}
