import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AccountService {

  name: string = '';
  userId: string = '';
  userName: string = '';
  picId: string = '';

  userAccount: any = null;
  userAccount$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  
  fullLoginResponse: any = null;

  constructor() { }

  init(): void {
    this.name = '';
    this.userId = '';
    this.userName = '';
    this.picId = '';
  }

  setUserAccount(name: string, picId: string, userId: string, userName?: string): void {
    if (name) {
      this.name = name;
    }
    if (picId) {
      this.picId = picId;
    }
    if (userId) {
      this.userId = userId;
    }
    if (userName) {
      this.userName = userName;
    }
    this.updateUserAccountSubject();
  }

  setFullLoginResponse(resp: any): void {
    this.fullLoginResponse = resp;
  }

  updateUserAccountSubject(): void {
    this.userAccount$.next(this.name);
  }

}
