import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BroadcastService } from '../../broadcast.service';
import { BroadcastGroup } from '../../models/broadcast';
import { FtsService } from '../../../../utilities/fts/fts.service';
import { SearchFilterService } from '../../../../utilities/search-filter/search-filter.service';
import { TnNotificationService } from '../../../../utilities/tn-notification/tn-notification.service';

@Component({
  selector: 'tn-broadcast-list',
  templateUrl: './broadcast-list.component.html',
  styleUrls: ['./broadcast-list.component.scss']
})
export class BroadcastListComponent implements OnInit {

  @Output() onEditGroup: EventEmitter<string> = new EventEmitter<string>();

  broadcastGroups: BroadcastGroup[] = [];
  filteredGroups: BroadcastGroup[] =[];

  constructor(
    private _broadcastService: BroadcastService,
    private _ftsService: FtsService,
    private _searchFilterService: SearchFilterService,
    private _tnNotificationService: TnNotificationService
  ) { }

  ngOnInit() {
    this.getBroadcastGroupList();
  }

  getBroadcastGroupList(): void {
    this._broadcastService.getBroadcastGroupList(
      resp => {
        this.broadcastGroups = resp;
        this.searchGroup();
      },
      err => {

      }
    );
  }

  searchGroup(keyword?: string): void {
    this.filteredGroups = this._ftsService.tnFtsFiltering(this.broadcastGroups, keyword ? keyword : "", ['name']);
    this.filteredGroups = this._searchFilterService.sortDataByRelevanceByName(this.filteredGroups, keyword ? keyword : "");
  }

  editBroadcastGroup(broadcastGroup: BroadcastGroup): void {
    this.onEditGroup.emit(broadcastGroup.user_group_id);
  }

  deleteBroadcastGroup(broadcastGroup: BroadcastGroup): void {
    this._tnNotificationService.showConfirmByTranslateKey(
      "WEBCLIENT.BROADCAST.MANAGE.DELETE_BROADCAST_LIST_CONFIRM",
      resp => {
        this._broadcastService.deleteBroadcastGroupById(
          broadcastGroup.user_group_id,
          resp => {
            this.getBroadcastGroupList();
          },
          err => {

          }
        )
      },
      null,
      {NAME: broadcastGroup.name}
    );
  }

  addBroadcastGroup(): void {
    this.onEditGroup.emit(null);
  }

}
