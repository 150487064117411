/**
 * ISA Configs
 * 
 * Created by Shan - 2018-11-14
 */

import { TeamNoteConfig } from "../../configs/models/config";
import { TeamNotePasswordPolicyConstant } from "../../constants/password-policy.constant";

export const IsaConfig: TeamNoteConfig = {
  HOST: {
    DOMAIN: "isa",
    WEB_CLIENT_PORT: 9443
  },
  GENERAL: {
    PASSWORD_POLICY_LENGTH: 4,
    PASSWORD_POLICY_REGEX: TeamNotePasswordPolicyConstant.NONE,
    IMAGE_COMPRESSION: {
      QUALITY_RATIO: 0.75
    }
  },
  IMAGES: {
    NOTIFICATION_ICON: "assets/images/clients/isa/notification_icon.png",
    FAVICON: "assets/images/clients/isa/favicon.png",
    FAVICON_ALERT: "assets/images/clients/isa/favicon_alert.png"
  },
  MAP: {
    API_KEY: 'AIzaSyBcGeH1rDqFD1BWkCxKy7ql-TphpB6Nnok'
  },
  WEBCLIENT: {
    
  }
};
