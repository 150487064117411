import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Question } from '../../models/questionnaire';
import { TeamNoteQuestionnaireConstant } from '../../constants/questionnaire.constant';

@Component({
  selector: 'tn-question-checkbox',
  templateUrl: './question-checkbox.component.html',
  styleUrls: ['./question-checkbox.component.scss']
})
export class QuestionCheckboxComponent implements OnInit {

  @Input() question: Question;
  @Output() questionChange: EventEmitter<Question> = new EventEmitter<Question>(null);

  EXTRA_FREE_TEXT = TeamNoteQuestionnaireConstant.EXTRA_FREE_TEXT;

  constructor() { }

  ngOnInit() {
  }

  onChange(newVal) {
    this.questionChange.emit(this.question);
  }

}
