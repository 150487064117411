import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BroadcastComponent } from './broadcast.component';
import { BroadcastService } from './broadcast.service';
import { BroadcastSendComponent } from './broadcast-send/broadcast-send.component';
import { UtilitiesModule } from '../../utilities/utilities.module';
import { ContactModule } from '../contact/contact.module';
import { BroadcastManageComponent } from './broadcast-manage/broadcast-manage.component';
import { BroadcastSetComponent } from './broadcast-manage/broadcast-set/broadcast-set.component';
import { BroadcastListComponent } from './broadcast-manage/broadcast-list/broadcast-list.component';
import { BroadcastRecipientSelectorComponent } from './broadcast-recipient-selector/broadcast-recipient-selector.component';

@NgModule({
    imports: [
        CommonModule,
        UtilitiesModule,
        ContactModule
    ],
    declarations: [BroadcastComponent, BroadcastSendComponent, BroadcastManageComponent, BroadcastSetComponent, BroadcastListComponent, BroadcastRecipientSelectorComponent],
    providers: [
        BroadcastService
    ]
})
export class BroadcastModule { }
