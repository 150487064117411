<div class="rating-container">
  <div class="rating-wrapper">
    <div class="rating-header rating-row">
      <div class="rating-title"></div>
      <div class="rating-star-wrapper">
        <div class="star-content" *ngFor="let index of question.ratingDetail.indexCounter">
          <ng-container *ngIf="index == 1">
            {{question.ratingDetail.one_star_caption}}
          </ng-container>
          <ng-container *ngIf="index == question.ratingDetail.max_star">
            {{question.ratingDetail.max_star_caption}}
          </ng-container>
          <ng-container *ngIf="index != 1 && index != question.ratingDetail.max_star"></ng-container>
        </div>
      </div>
    </div> <!-- rating header -->

    <div class="rating-section rating-row" *ngFor="let o of question.ratingDetail.options">
      <div class="rating-title">
        <span class="mandatory-label" *ngIf="o.hasError">
          {{'WEBCLIENT.QUESTIONNAIRE.ITEM_UNFINISHED_INDICATOR' | translate}}
        </span>
        {{o.label}}. {{o.section}}
      </div>

      <div class="rating-star-wrapper">
        <div class="star-content" 
          (click)="updateSection(o, index)"
          (mouseenter)="updateSectionHighlight(o, index)"
          (mouseleave)="updateSectionHighlight(o, 0)"
          *ngFor="let index of question.ratingDetail.indexCounter">
          <i class="fa fa-fw fa-star" 
            [ngClass]="{'unselected': (o.hover > 0 ? o.hover < index : o.rate < index), 'selected': (o.hover > 0 ? o.hover >= index : o.rate >= index)}">
          </i>
        </div>
      </div>

    </div>
  </div>
</div>