import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AttachmentService } from '../../../../utilities/attachment/attachment.service';
import { FileUploaderService } from '../../../../utilities/file-uploader/file-uploader.service';
import { ParsedFile } from '../../../../utilities/file-uploader/file-uploader.component';

import * as _ from 'lodash';
import { FileUploadTarget } from '../../../../utilities/file-uploader/file-upload-target';
import { CustomFieldsMapped } from '../models/custom-field';

export class FieldAttachmentDocumentParsedAnswer {
  attachment_id: string;
  name: string;
  file: ParsedFile;

  constructor(file?: ParsedFile, attachmentId?: string, name?: string) {
    this.file = file;
    this.attachment_id = attachmentId;
    this.name = name;
  }
}

@Component({
  selector: 'tn-field-attachment-document',
  templateUrl: './field-attachment-document.component.html',
  styleUrls: ['./field-attachment-document.component.scss'],
  host: { 'component-root': 'shared' }
})
export class FieldAttachmentDocumentComponent implements OnInit {

  @Input() field: CustomFieldsMapped;
  @Input() isInput: boolean = false;

  @Output() onFieldUpdate: EventEmitter<CustomFieldsMapped> = new EventEmitter<CustomFieldsMapped>(null);

  answers: FieldAttachmentDocumentParsedAnswer[] = [];

  fileInputs: ParsedFile[];
  localAnsObj: FieldAttachmentDocumentParsedAnswer[] = [];

  constructor(
    private _attachmentService: AttachmentService,
    private _fileUploaderService: FileUploaderService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.field.answer.value) {
      try {
        this.answers = JSON.parse(this.field.answer.value);
        this.answers.sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });

        if (this.isInput) {
          this.localAnsObj = this.answers;
        }
      } catch (err) {
        this.localAnsObj = [];
        this.answers = [];
      };
      this.onChange();
    }
  }

  openAttachment(ans: FieldAttachmentDocumentParsedAnswer): void {
    this._attachmentService.prepareAttachmentModalContentByFileId([ans.attachment_id]);
  }

  openFileUploader() {
    this._fileUploaderService.openFileUploaderModal(
      FileUploadTarget.DOCUMENT,
      (files: ParsedFile[]) => {
        // this.fileInputs = files;
        _.each(files, (file) => {
          this.localAnsObj.push(new FieldAttachmentDocumentParsedAnswer(file, null, file.name));
        });
        this.onChange();
      },
      _.map(this.fileInputs, 'file'),
      true
    );
  }

  removeAttachment(ansObj) {
    // this.fileInputs = _.without(this.fileInputs, file);
    this.localAnsObj = _.without(this.localAnsObj, ansObj);
    this.onChange();
  }

  getAnswerValue(): any {
    return this.localAnsObj;
  }

  // getAnswerValue(): any {
  //   return _.map(this.fileInputs, 'file');
  // }

  onChange() {
    this.field.answer.value = this.getAnswerValue();
    // this.field.answer.files = this.getAnswerValue();
    this.onFieldUpdate.emit(this.field);
  }

}
