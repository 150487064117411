import { Injectable } from "@angular/core";

import * as _ from 'lodash';

@Injectable()
export class SearchFilterService {
  constructor() {}

  search($haystack, $fields, $keyword) {
    // Origianl $searchFilter
    var filtered = $haystack;

    if ($keyword) {
      var keywords = $keyword.split(" ");

      var searchingFields = $fields;

      for (var j = 0; j < keywords.length; j++) {
        var temp = [];
        var keyword = keywords[j];
        for (var i = 0; i < filtered.length; i++) {
          var record = filtered[i];
          if (!record) {
            continue;
          }
          for (var k = 0; k < searchingFields.length; k++) {
            var field = searchingFields[k];
            var components = field.split(".");
            var target = record[components.shift()];
            while (components.length && target) {
              target = target[components.shift()];
            }
            if (target && target.toLowerCase().indexOf(keyword.toLowerCase()) != -1) {
              temp.push(record);
              break;
            }
          }
        }

        filtered = temp;
      }
    }
    // Origianl $searchFitler ends
    return filtered;
  }

  /**
   * Sort data by relevance with given key (lowerCasedSortKey)
   * 
   * @param {any[]} data - Original data array
   * @param {string} keyword - Target search keyword
   * @param {string} key - Target sorting field key name
   * @returns {any[]} - Sorted data array
   * @memberof SearchFilterService
   */
  sortDataByRelevanceByKey(data: any[], keyword: string, key: string): any[] {
    data = _.each(data, (d) => {
      if (d && d[key]) {
        let index = d[key].toLowerCase().indexOf(keyword);
        d.order = index != -1 ? index : data.length;
        d.lowerCasedSortKey = d[key].toLowerCase();
      }
    });

    return _.sortBy(data, [
      'order',
      'lowerCasedSortKey'
    ]);
  }

  /**
   * Sort data by relevance by attribute "name"
   * 
   * Calls general sortDataByRelevanceByKey function with key "name"
   * 
   * @param {any[]} data - Original data array
   * @param {string} keyword - Target search keyword
   * @returns {any[]} - Sorted data array by "name"
   * @memberof SearchFilterService
   */
  sortDataByRelevanceByName(data: any[], keyword: string): any[] {
    return this.sortDataByRelevanceByKey(data, keyword, "name");
  }
}
