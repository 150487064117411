<div class="recipient-selector">
  <div class="recipient-column">
      <div class="recipient-desc">
        <div class="recipient-count text-color-primary">
          {{'WEBCLIENT.BROADCAST.RECIPIENTS' | translate}}
        </div>
        <div class="recipient-remove">
          <button class="btn btn-danger" (click)="removeAllSelected()">
            {{'WEBCLIENT.BROADCAST.RECIPIENT_REMOVE' | translate: {NUM: selectedContacts.length} }}
          </button>
        </div>
      </div>
      <div class="selection-list">
        <tn-contact-list
          class="tn-contact-list-wrapper"
          [contacts]="selectedContacts"
          [allowLocalSearchContact]="true"
          [isShowSearchBar]="true"
          [noJumpToStart]="true"
          (contactCellOnClick)="unSelectMember($event)">
        </tn-contact-list>
      </div>
  </div>
  <div class="selection-column">
    <div class="selection-options">
      <div 
        class="option clickable" 
        [ngClass]="{'active': currentSelectionTarget == selectionTargets.teamNoteContact}"
        (click)="toggleSelectTeamNote()">
        {{'WEBCLIENT.BROADCAST.ADD_FROM_TEAMNOTE' | translate}}
      </div>
      <div 
        class="option clickable" 
        [ngClass]="{'active': currentSelectionTarget == selectionTargets.broadcastGroup}"
        (click)="toggleSelectBroadcastList()">
        {{'WEBCLIENT.BROADCAST.ADD_FROM_BROADCAST_LIST' | translate}}
      </div>
    </div>

    <div class="selection-list">
      <tn-contact-list
        *ngIf="currentSelectionTarget == selectionTargets.teamNoteContact"
        class="tn-contact-list-wrapper"
        [contacts]="unSelectedContacts"
        [isShowSearchBar]="true"
        [allowLocalSearchContact]="true"
        [noJumpToStart]="true"
        (contactCellOnClick)="selectMember($event)">
      </tn-contact-list>
      <tn-contact-list
        *ngIf="currentSelectionTarget == selectionTargets.broadcastGroup"
        class="tn-contact-list-wrapper"
        [userGroups]="broadcastGroups"
        [isShowSearchBar]="true"
        [allowSearchUserGroup]="true"
        [noJumpToStart]="true"
        (userGroupCellOnClick)="selectBroadcastGroup($event)">
      </tn-contact-list>
    </div>
  </div>
</div>