<tn-search-bar 
  class="tn-search-bar" 
  *ngIf="isShowSearchBar" 
  (onKeywordChanged)="search($event)">
</tn-search-bar>

<div class="tn-contact-list">
  <div virtualScroller 
    #contactListWrapper
    class="contact-list-wrapper"
    *ngIf="displayingList.length > 0"
    [ssrChildHeight]="80"
    [items]="displayingList" 
    (vsUpdate)="scrollItems = $event">
    <ng-container *ngFor="let contact of scrollItems">
      <tn-contact-cell 
        [contact]="contact"
        [isEnableImportantUsers]="isEnableImportantUsers" 
        (contactOnClick)="contactOnClick($event)"
        (userGroupOnClick)="userGroupOnClick($event)">
      </tn-contact-cell>
    </ng-container>
  </div>

  <ng-container *ngIf="displayingList.length == 0">
    <tn-empty-page></tn-empty-page>
  </ng-container>

  <ng-content select=".select-all-overlay" *ngIf="filteredContacts.length > 0"></ng-content>
</div>