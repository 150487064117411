export class TrainingFile {
  app_icon: string;
  company_domain: string;
  create_date: string;
  file_ids: string;
  folder: string;
  name: string;
  status: string;
  training_id: string;
  type: string;
  update_date: string;

  // Display
  fileType: string;
}

export class TrainingFolder {
  name: string;
  type: string;
  folders: TrainingFolder[];
  files: TrainingFile[];
}