import { TeamNoteConfig } from "../../configs/models/config";

export const ApptaskConfig: TeamNoteConfig = {
  HOST: {
    DOMAIN: "apptask"
  },
  MANIFEST: {
    PATH: "manifest/teamnote.json"
  },
  GENERAL: {
    IS_ENABLE_DEBUG: true,
    IS_LOGIN_MODE_MOBILE: true
  },
  LOGIN: {
    PWA_LOGIN_REDIRECT_URL: 'https://tntsgw01.teamnoteapp.com/sso/saml/web-client/apptask?auth_name=adfs',
    PWA_LOGIN_REDIRECT_IPS: [],
  },
  WEBCLIENT: {
    GENERAL: {
      IS_USE_WEBSOCKET: true,
      IS_ALLOW_AUTO_EXTEND_SESSION: true,
      IS_ENABLE_CHAT_LIST_MESSAGE_SEARCH: true,
    },
    CHATROOM: {
      ENABLE_ENCRYPTED_MESSAGE: false,
      ENABLE_EXPORT_CHAT: true,
      ENABLE_WHISPER: true,
      IS_ENABLE_SEARCH: true,
      IS_ENABLE_HASHTAG: true,
      IS_ENABLE_MENTION: true
    },
    WATERMARK: {
      IS_NEED_DATE_TIME: false,
      IS_SHOW_OPEN_TIME: false
    },
    FORMS: {
      IS_ALLOW_EXPORT_FORMS: true
    }
  },
  NOTIFICATION: {
    ENABLE_NOTIFICATION_CENTER: true
  }
};
