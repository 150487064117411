export const TeamNoteWebDavMaterialConstant = {
  TYPE: {
    FOLDER: 'folder',
    EBOOK: 'ebook',
    EBOOK_PAGE: 'ebook_page',
    FILE: 'file',
    URL: 'url',
    IMG: 'img',
    PDF: 'pdf',
    VIDEO: 'video',
    DOC: 'doc',
    TXT: 'txt',
    AUDIO: 'audio'
  },
  TYPE_KEY: {
    EBOOKS: 'ebooks',
    FILES: 'files',
    URLS: 'urls'
  },
  ROOT_LEVEL_ID: '/',
  AUDIO_LANG: {
    CANTONESE: 'cantonese',
    MANDARIN: 'mandarin'
  }
};
