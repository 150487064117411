<div class="contact-picker-wrapper">
  <div class="picker-header">
    <h4>{{header | translate}}</h4>
  </div>
  <tn-search-bar  
    (onKeywordChanged)="search($event)">
  </tn-search-bar>

  <div class="select-all-container" *ngIf="isAllowSelectAll && fullDisplayList.length !== 1">
    <div class="contact-select-all-button clickable" (click)="selectAllOnClick($event)">
    </div>
  </div>

  <div 
    virtualScroller 
    class="picker-body"
    [ngClass]="{'multiple': isMultiple}"
    [bufferAmount]="10"
    [enableUnequalChildrenSizes]="true"
    [useMarginInsteadOfTranslate]="true"
    [items]="fullDisplayList"
    (vsUpdate)="scrollItems = $event">
    <ng-container *ngFor="let item of scrollItems">

      <ng-container *ngIf="item.isLabel; else chatContactCell">
        <div class="category-label clickable" (click)="toggleCategoryDisplay(item.key)">
          <div class="category-name">
            {{item.label | translate}} ({{item.itemsLength}})
          </div>
          <div class="category-arrow">
            <i class="fa fa-angle-up fa-fw" *ngIf="item.isShow"></i>
            <i class="fa fa-angle-down fa-fw" *ngIf="!item.isShow"></i>
          </div>
        </div>
      </ng-container>

      <ng-template #chatContactCell>
        <tn-chat-cell 
          [chat]="item" 
          (chatOnClick)="onChatClick($event)"
          *ngIf="item.chat_id">
          <div class="additional-selection" *ngIf="isMultiple">
            <i class="additional-selection-icon fa fa-fw clickable"
              [ngClass]="{
                'fa-square-o': !item.isSelected, 
                'chatroom-main-color fa-check-square-o': item.isSelected
              }">
            </i>
          </div>
        </tn-chat-cell>

        <tn-contact-cell
          [contact]="item"
          (contactOnClick)="onContactClick($event)"
          (userGroupOnClick)="onContactClick($event)"
          *ngIf="item.user_id || item.user_group_id">
          <div class="additional-selection" *ngIf="isMultiple"
               (click)="onContactClick(item)">
            <i class="additional-selection-icon fa fa-fw clickable"
              [ngClass]="{
                'fa-square-o': !item.isSelected, 
                'chatroom-main-color fa-check-square-o': item.isSelected
              }">
            </i>
          </div>
        </tn-contact-cell>
      </ng-template>
    </ng-container>
  </div>
  <div class="selected-targets" *ngIf="isMultiple">
    <div class="tn-bottom-button-wrapper">
      <div class="action-button cancel clickable" (click)="cancel()">
        {{'GENERAL.CANCEL' | translate}}
      </div>
      <div
        class="action-button confirm"
        [ngClass]="{
          clickable: selectedTargets.length > 0 || isFilterMode,
          unclickable: selectedTargets.length == 0 && !isFilterMode
        }"
        (click)="confirm()"
      >
        {{ 'GENERAL.CONFIRM' | translate }} ({{ selectedTargets.length }})
      </div>
    </div>
  </div>
</div>
