<div class="report-export-container">

  <div class="modal-header text-color-primary">
    <h4>
      <ng-container *ngIf="!isJob">{{'WEBCLIENT.JOB_REPORT.EXPORT.REPORT' | translate}}</ng-container>
      <ng-container *ngIf="isJob">{{'WEBCLIENT.JOB_REPORT.EXPORT.JOB' | translate}}</ng-container>
    </h4>
  </div>

  <div class="modal-body report-export-body">

    <form novalidate>
      <div class="form-group">
        <label>{{'WEBCLIENT.JOB_REPORT.EXPORT.TIME_PERIOD' | translate}}</label>
        <div class="range-picker">
          <input type="text"
            [placeholder]="'WEBCLIENT.JOB_REPORT.EXPORT.TIME_PERIOD' | translate"
            class="form-control"
            name="time-period"
            bsDaterangepicker
            [bsConfig]="dateInputConfig"
            [(ngModel)]="timePeriod">
        </div>
      </div>
      <div class="form-group">
        <label>{{'WEBCLIENT.JOB_REPORT.NEW_REPORT.STORE' | translate}}</label>
        <div class="option">
          <ng-select
            [clearable]="false"
            [items]="storeOptions"
            [(ngModel)]="selectedStore"
            appendTo="body"
            bindLabel="name"
            name="store"
            [placeholder]="'WEBCLIENT.JOB_REPORT.NEW_REPORT.STORE' | translate">
            <ng-template ng-label-tmp ng-option-tmp let-item="item">
              <ng-container *ngIf="item.isAll">
                {{'GENERAL.ALL' | translate}}
              </ng-container>
              <ng-container *ngIf="!item.isAll">
                {{item.name}}
              </ng-container>
            </ng-template>
          </ng-select>
        </div>
      </div>
    
      <div class="form-group">
        <label>{{'WEBCLIENT.JOB_REPORT.NEW_REPORT.TYPE' | translate}}</label>
        <div class="option">
          <ng-select
            [clearable]="false"
            [items]="typeOptions"
            [(ngModel)]="selectedType"
            appendTo="body"
            bindLabel="name"
            name="type"
            [placeholder]="'WEBCLIENT.JOB_REPORT.NEW_REPORT.TYPE' | translate">
            <ng-template ng-label-tmp ng-option-tmp let-item="item">
              <ng-container *ngIf="item.isAll">
                {{'GENERAL.ALL' | translate}}
              </ng-container>
              <ng-container *ngIf="!item.isAll">
                {{item.name}}
              </ng-container>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </form>

  </div>

  <div class="modal-footer">
    <button class="btn btn-default" (click)="cancel()">
      {{"GENERAL.CANCEL" | translate}}
    </button>
    <button class="btn tn-button-primary" (click)="export()">
      {{"GENERAL.EXPORT" | translate}}
    </button>
  </div>

</div>