import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageEditorComponent } from './image-editor.component';

import * as Konva from 'konva';
import { ImageEditorService } from './image-editor.service';
import { TranslateModule } from '@ngx-translate/core';
import { CommonMaterialModule } from '../common-material/common-material.module';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        CommonMaterialModule
    ],
    providers: [
        ImageEditorService
    ],
    declarations: [ImageEditorComponent]
})
export class ImageEditorModule { }
