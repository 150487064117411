import { Injectable } from '@angular/core';
import { AdvertisementComponent } from './advertisement/advertisement.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import * as _ from 'lodash';


@Injectable()
export class TnDialogService {

  constructor(
    private _matDialog: MatDialog
  ) { }

  openTnDialog(component: any, params?: any, configs?: MatDialogConfig): MatDialogRef<any> {
    let dialogConfigs = {
      width: '80vw',
      autoFocus: false,
      data: params
    };

    if (configs) {
      dialogConfigs = _.extend(dialogConfigs, configs);
    }

    return this._matDialog.open(component, dialogConfigs);
  }

  closeAllDialogs() {
    this._matDialog.closeAll();
  }

  openTnAdvertisement(adImageSrc: string, adOnClickCallback: Function): void {
    this.openTnDialog(
      AdvertisementComponent,
      {
        adImageSrc: adImageSrc,
        adOnClickCallback: adOnClickCallback
      },
      {
        panelClass: 'transparent-dialog-panel'
      }
    );
  }

}
