<div class="info-row" [ngClass]="{'new-msg-indicator': message.isNewMsgIndicator}" *ngIf="message">
  <div class="info-msg">
    <div class="message-date" *ngIf="message.date">{{message.date}}</div>
    
    <ng-container *ngIf="message.isNewMsgIndicator">
      {{'WEBCLIENT.CHATROOM.UNREAD_MESSAGE_HEADER' | translate}}
    </ng-container>

    <ng-container *ngIf="message.isRecalled">
      <span *ngIf="message.isSentByMe">
        {{'WEBCLIENT.CHATROOM.MESSAGE_RECALLED.ME' | translate}}
      </span>
      <span *ngIf="!message.isSentByMe">
        {{'WEBCLIENT.CHATROOM.MESSAGE_RECALLED.OTHERS' | translate: {'USER': message.senderContact.name} }}
      </span>
    </ng-container>

    <ng-container *ngIf="message.type == MESSAGE_TYPE.JOIN_CHAT">
      {{'WEBCLIENT.CHATROOM.JOIN_CHAT' | translate: {'ACTOR': message.parsedBody.actor, 'USERS': message.parsedBody.user_names.join(', ')} }}
    </ng-container>

    <ng-container *ngIf="message.type == MESSAGE_TYPE.LEAVE_CHAT">
      {{'WEBCLIENT.CHATROOM.LEAVE_GROUP' | translate: {'USERS': message.parsedBody.user_names.join(', ')} }}
    </ng-container>

    <ng-container *ngIf="message.type == MESSAGE_TYPE.CHANGE_CHAT_ADMIN">
      <!-- INSERTED -->
      <ng-container *ngIf="message.parsedBody.inserted">
        <ng-container *ngIf="message.parsedBody.inserted.length == 1">
            {{'WEBCLIENT.CHATROOM.ADMIN_CHANGE.ONE' | translate: {'USER': message.parsedBody.inserted} }}
        </ng-container>
        <ng-container *ngIf="message.parsedBody.inserted.length > 1">
            {{'WEBCLIENT.CHATROOM.ADMIN_CHANGE.MULTIPLE' | translate: {'USERS': message.parsedBody.inserted.join(', ')} }}
        </ng-container>
      </ng-container>
      <!-- REMOVED -->
      <ng-container *ngIf="message.parsedBody.removed">
        <ng-container *ngIf="message.parsedBody.removed.length == 1">
            {{'WEBCLIENT.CHATROOM.ADMIN_REMOVED.ONE' | translate: {'USER': message.parsedBody.removed} }}
        </ng-container>
        <ng-container *ngIf="message.parsedBody.removed.length > 1">
            {{'WEBCLIENT.CHATROOM.ADMIN_REMOVED.MULTIPLE' | translate: {'USERS': message.parsedBody.removed.join(', ')} }}
        </ng-container>
      </ng-container>
    </ng-container>
  
  </div>
</div>