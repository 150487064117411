import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OutOfOfficeComponent } from './out-of-office.component';
import { UtilitiesModule } from '../../utilities/utilities.module';

@NgModule({
    imports: [
        CommonModule,
        UtilitiesModule
    ],
    declarations: [OutOfOfficeComponent]
})
export class OutOfOfficeModule { }
