<div class="tn-content">
  <div class="tn-content-full">
    <tn-page-title [title]="'WEBCLIENT.MENU.VIDEO_STREAMING' | translate">
      <div class="right-control">
        <div class="video-control-container">
          <div class="stream-control">
            <i class="clickable fa fa-fw fa-arrow-left" (click)="viewTargetStream(activeStreamIndex - 1)"></i>
            {{activeStreamIndex + 1}} / {{streams.length}}
            <i class="clickable fa fa-fw fa-arrow-right" (click)="viewTargetStream(activeStreamIndex + 1)"></i>
          </div>
          <div class="clickable refresh-control" (click)="refreshList()">
            <i class="fa fa-fw fa-refresh"></i>
          </div>
        </div>
      </div>
    </tn-page-title>
    <div class="video-container">
      <div class="no-video" *ngIf="!streams || streams.length == 0">
        {{'WEBCLIENT.VIDEO.NO_ACTIVE_STREAMS' | translate}}
      </div>
      <video
        id="videojs_stream"
        class="video-js video-body"
        controls 
        preload="auto">
      </video> 
    </div>
  </div>
</div>