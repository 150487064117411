import { Component, OnInit } from '@angular/core';
import { ModuleManagerService } from '../services/module/module-manager.service';
import { StoreReportOptions, StoreReport, JobDispatch, Store, StoreReportJobType, StoreReportType, StoreReportCustomFieldsMapped, StoreReportFilterParam, StoreReportCustomRouteParam } from './models/job-report';
import { JobReportService } from './job-report.service';
import { JobReportConstant } from './constants/job-report.constants';

import * as _ from 'lodash';
import { ModuleKeyDefinition } from '../../constants/module.constant';
import { TeamnoteConfigService } from '../../configs/teamnote-config.service';
import { TnDialogService } from '../../utilities/tn-dialog/tn-dialog.service';
import { ReportExportComponent } from './report-export/report-export.component';
import { RouteParamService } from '../../utilities/route-param/route-param.service';
import { DataManagerService } from '../services/data/data-manager.service';
import { LoggerService } from '../../utilities/logger/logger.service';

@Component({
  selector: 'tn-job-report',
  templateUrl: './job-report.component.html',
  styleUrls: ['./job-report.component.scss']
})
export class JobReportComponent implements OnInit {

  paramSub: any;
  customParam: StoreReportCustomRouteParam = new StoreReportCustomRouteParam();

  pageTitle: string;
  pageTitleTranslateKey: string;

  isEnabledJobDispatch: boolean;
  isEnabledExport: boolean = false;

  isViewingStoreReport: boolean = true;

  storeReports: StoreReport[] = [];
  jobDispatchs: JobDispatch[] = [];
  jobCount: number = 0;

  currentMainView: number;
  MAIN_VIEW_CODE = {
    NONE: 0,
    VIEW_REPORT: 1,
    NEW_REPORT: 2,
    JOB_RESPONSE_FORM: 3,
    FILTER: 4
  };

  viewingReport: StoreReport = null;
  viewingJob: JobDispatch = null;

  // job dispatch
  jobActionCode: number;

  constructor(
    private _moduleManagerService: ModuleManagerService,
    private _jobReportService: JobReportService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _tnDialogService: TnDialogService,
    private _routeParamService: RouteParamService,
    private _loggerService: LoggerService
  ) { 
  }

  ngOnInit() {
    this.paramSub = this._routeParamService.currentRouteParam$.subscribe(
      param => {
        let isParamUpdated = this.customParam != param;
        this.customParam = param;
        if (isParamUpdated) {
          this.getJobReportsInfo();
        }
      }
    );
  }

  ngOnDestroy() {
    this.paramSub.unsubscribe();
  }

  getJobReportsInfo(): void {
    this.storeReports = [];
    this.jobDispatchs = [];

    this.getStoreReportOptions();
    this.currentMainView = this.MAIN_VIEW_CODE.NONE;

    this.isViewingStoreReport = true;

    this.isEnabledExport = this._teamnoteConfigService.config.WEBCLIENT.FORMS.IS_ALLOW_EXPORT_FORMS;

    // clear filter param
    this._jobReportService.clearReportFilterParamCache();
  }

  checkIfAllowJobDispatch(): void {
    this.isEnabledJobDispatch = this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.JOB_DISPATCH);
    if (this.customParam && this.customParam.typeId) {
      let type = this._jobReportService.getTypeByTypeId(this.customParam.typeId);
      if (type) {
        this.applyLatestTypeConfig(type);
      } else {
        if (!this._jobReportService.typeOptions || this._jobReportService.typeOptions.length == 0) {
          // only try to check if no type is avilable yet.
          this.tryToCheckTypeRepeatedly();
        }
      }
    }
  }

  applyLatestTypeConfig(type: StoreReportType): void {
    this.isEnabledJobDispatch = !!type.allow_job_dispatch;
    // If type is current param type, and is not in app menu now, redirect to base store report type
    if (this.customParam && this.customParam.typeId == type.type_id) {
      if (!type.visible_in_app_menu) {
        this._jobReportService.handleInitialPreviousSession();
      }
    }
  }

  tryToCheckTypeRepeatedly(): void {
    this._loggerService.debug("Trying to check store report type existence repeatedly.." + this.customParam.typeId);
    var typeOptionCheckInterval = setInterval(
      () => {
        if (this.customParam && this.customParam.typeId) {
          let type = this._jobReportService.getTypeByTypeId(this.customParam.typeId);
          if (type) {
            this.applyLatestTypeConfig(type);
            this._loggerService.debug("Store report type is found, clearing the interval...");
            clearInterval(typeOptionCheckInterval);
          } else {
            if (this._jobReportService.typeOptions && this._jobReportService.typeOptions.length > 0) {
              // types are avialable already, but still cant find the type, stop the checking
              this._loggerService.debug("Store report types are avialble but target not found, clearing the interval...");
              clearInterval(typeOptionCheckInterval);
            } else {
              this._loggerService.debug("Store report type is not yet found, still checking...");
            }
          }
        } else {
          this._loggerService.debug("Store report has no custom param, clearing the interval...");
          clearInterval(typeOptionCheckInterval);
        }
      },
      500
    );
  }

  getAllReportAndJobs(): void {
    this.getStoreReportList();
    if (this.isEnabledJobDispatch) {
      this.getJobDispatchList();
    }
  }

  updatePageTitle(): void {
    if (this.customParam) {
      this.pageTitle = this._jobReportService.getTypeByTypeId(this.customParam.typeId).name;
      this.pageTitleTranslateKey = null;
    } else {
      this.pageTitle = null;
      this.pageTitleTranslateKey = "WEBCLIENT.MENU.STORE_REPORT";
    }
  }

  getStoreReportOptions() {
    this._jobReportService.getStoreReportOptions(
      () => {
        this.updatePageTitle();
        this.checkIfAllowJobDispatch();
        this.getAllReportAndJobs();
      }
    );
  }
  getStoreReportList() {
    this._jobReportService.getStoreReportList(
      (resp: StoreReport[]) => {
        this.storeReports = _.filter(resp, (report) => {
          if (this.customParam && this.customParam.typeId) {
            return true;
          }
          return !report.type.visible_in_app_menu;
        });
      },
      this.customParam ? this.customParam.typeId : null
    );
  }
  getJobDispatchList() {
    this._jobReportService.getJobDispatchList(
      (resp: JobDispatch[]) => {
        this.jobDispatchs = _.filter(resp, (job) => {
          if (this.customParam && this.customParam.typeId) {
            return job.type_id == this.customParam.typeId;
          }
          return !this._jobReportService.getTypeByTypeId(job.type_id).visible_in_app_menu;
        });

        this.jobCount = this._jobReportService.getUnfinishedJobCount(this.jobDispatchs);        

        if (this.viewingJob && !this.isViewingStoreReport) {
          this.onJobDispatchClick(_.find(this.jobDispatchs, (j) => {
            return j.job_id == this.viewingJob.job_id;
          }));
        }
      },
      this.customParam ? this.customParam.typeId : null
    );
  }

  onViewingTabChanged(isStoreReport) {
    if (this.isViewingStoreReport != isStoreReport) {
      this.clearViewingReport(false);
    }
    this.isViewingStoreReport = isStoreReport;
    // clear filter param
    this._jobReportService.clearReportFilterParamCache();
    if (this.isViewingStoreReport) {
      this.getStoreReportList();
    } else {
      this.getJobDispatchList();
    }
  }

  onStoreReportClick(report: StoreReport) {
    this.viewingReport = report;
    this.currentMainView = this.MAIN_VIEW_CODE.VIEW_REPORT;
  }

  clearViewingReport(isRefresh: boolean) {
    this.viewingReport = null;
    this.viewingJob = null;
    this.currentMainView = this.MAIN_VIEW_CODE.NONE;
    if (isRefresh) {
      this.getStoreReportList();
    }
  }

  onJobDispatchClick(job: JobDispatch) {
    this.viewingJob = job;
    if (!job) {
      this.clearViewingReport(false);
    } else {
      let parsedReport = new StoreReport;
      parsedReport = job.report;
      parsedReport.job_dispatch = [job];
      this.viewingReport = parsedReport;
      this.currentMainView = this.MAIN_VIEW_CODE.VIEW_REPORT;
    }
  }

  onJobActionClick(actionCode: number) {
    let job = this.viewingReport.job_dispatch[0];
    let jobId = job.job_id;
    this.jobActionCode = actionCode;

    switch (actionCode) {
      case JobReportConstant.JOB_DISPATCH_ACTION_CODE.ACCEPT:
        this._jobReportService.acceptJobDispatch(jobId, () => this.getJobDispatchList());
        break;
      case JobReportConstant.JOB_DISPATCH_ACTION_CODE.REJECT:
        this._jobReportService.rejectJobDispatch(jobId, () => this.getJobDispatchList());
        break;
      case JobReportConstant.JOB_DISPATCH_ACTION_CODE.START:
        this._jobReportService.startJobDispatch(jobId, () => this.getJobDispatchList());
        break;
      case JobReportConstant.JOB_DISPATCH_ACTION_CODE.END:
        if (!this._jobReportService.checkIfJobNeedJobResponse(job)) {
          this._jobReportService.endJobDispatch(jobId, () => this.getJobDispatchList())
        } else {
          this.jobResponseInput();
        }
        break;
      case JobReportConstant.JOB_DISPATCH_ACTION_CODE.NEED_FOLLOW_UP:
        if (!this._jobReportService.checkIfJobNeedJobResponse(job)) {
          this._jobReportService.followUpJobDispatch(jobId, () => this.getJobDispatchList())
        } else {
          this.jobResponseInput();
        }
        break;
    }
  }

  jobResponseInput() {
    this.currentMainView = this.MAIN_VIEW_CODE.JOB_RESPONSE_FORM;
  }

  createNewReport() {
    this.clearViewingReport(false);
    this.currentMainView = this.MAIN_VIEW_CODE.NEW_REPORT;
  }

  onReportSubmitBack(isRefresh) {
    this.clearViewingReport(isRefresh);
  }
  onJobResponseSubmitBack(isSuccess) {
    if (isSuccess) {
      this.getJobDispatchList();
      this.jobActionCode = null;
      return;
    }
    this.currentMainView = this.MAIN_VIEW_CODE.VIEW_REPORT;
  }

  openExportReportOrJobDialog(isJob: boolean): void {
    this._tnDialogService.openTnDialog(ReportExportComponent, {
      isJob: isJob
    });
  }


  // Filter
  filterReportOrJob(): void {
    this.currentMainView = this.MAIN_VIEW_CODE.FILTER;
  }
  onReportFilterApply(filter: StoreReportFilterParam): void {
    this._jobReportService.cacheReportFilterParam(!this.isViewingStoreReport, filter);
    if (this.isViewingStoreReport) {
      this.storeReports = this._jobReportService.applyFilterOnReport(this.customParam);
    } else {
      this.jobDispatchs = this._jobReportService.applyFilterOnJob(this.customParam);
    }
  }
  onReportFilterBack(isRefresh): void {
    this.clearViewingReport(isRefresh);
  }

}
