export const EnWorkflowStrings = {
  NEW_FORM: {
    TITLE: "New Form",
    TEAM: "Team",
    TYPE: "Form Type",
    NO_PERMISSION: "You do not have permission to submit {{name}}"
  },

  FILTER: {
    TITLE: "Filter",
    CREATE_DATE: "Submission date",
    TEAM: "Team",
    TYPE: "Form type",
    STAGE: "Stage",
    CONTENT: "Content",
    APPLY: "Apply"
  },

  EXPORT: {
    TITLE: "Export Forms",
    FORMS: "Form(s)",
    TIME_PERIOD: "Time",
    OPTION: {
      AUTOMATIC: "Displaying forms",
      MANUAL: "Configure manually"
    },
    IN_PROGRESS_MSG: "Data is Exporting, you can check the progress in notification at the top-right hand corner. You will be notified when the file is ready.",
    EXPORT_READY_MSG: "File is ready, you can download it in notification center."
  },

  DETAIL: {
    TITLE: "Form Detail",
    OBSERVER_SUCCESS_MSG: "Observers are added successfully.",
    ACCEPT_SUCCESS_MSG: "Form is accepted successfully."
  },

  OPTIONS: {
    REJECT: "Reject",
    SAVE_DRAFT: "Save draft",
    SUBMIT: "Submit",
    REASSIGN: "Reassign",
    STAGES: "Stages",
    ASSIGN: "Assign",
    ADD_OBSERVER: "Add observer",
    ACCEPT: "Accept"
  }
}