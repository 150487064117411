/**
 * FWD Strings
 * 
 * Created by Shan - 2018-05-11
 */

import { LanguageConstant } from "../../constants/language.constant";
import { LanguageString } from "../../strings/string-helper.service";

export const FwdStrings: LanguageString[] = [
  {
    key: LanguageConstant.EN.key,
    strings: {
      WEBCLIENT: {
        MENU: {
          FWD_AUDIO_EBOOK: "IIQE Audio eBook",
          FWD_CORPORATE_MATERIAL: "Document Sharing",
          CHATS: "Communication"
        }
      },
      LOGIN: {
        GET_STATE: {
          USERNAME: {
            INPUT_TIP: "Input your Login ID",
            USERNAME: "Login ID"
          }
        }
      }
    }
  },
  {
    key: LanguageConstant.ZH_CHT.key,
    strings: {
      WEBCLIENT: {
        MENU: {
          FWD_AUDIO_EBOOK: "IIQE電子書",
          FWD_CORPORATE_MATERIAL: "檔案分享",
          CHATS: "溝通群組"
        }
      },
      LOGIN: {
        GET_STATE: {
          USERNAME: {
            INPUT_TIP: "輸入登入名稱",
            USERNAME: "登入名稱"
          }
        }
      }
    }
  },
  {
    key: LanguageConstant.ZH_CHS.key,
    strings: {
      WEBCLIENT: {
        MENU: {
          FWD_AUDIO_EBOOK: "IIQE电子书",
          FWD_CORPORATE_MATERIAL: "档案分享",
          CHATS: "沟通群组"
        }
      },
      LOGIN: {
        GET_STATE: {
          USERNAME: {
            INPUT_TIP: "输入登入名称",
            USERNAME: "登入名称"
          }
        }
      }
    }
  }
];