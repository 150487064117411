import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WorkflowCustomRouteParam, WorkflowFormFilterParam, WorkflowTeam, WorkflowType, WorkflowTypeStage } from '../model/workflow';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { WorkflowService } from '../services/workflow.service';
import { TimestampService } from '../../../utilities/timestamp/timestamp.service';

import * as _ from 'lodash';

@Component({
  selector: 'tn-form-filter',
  templateUrl: './form-filter.component.html',
  styleUrls: ['./form-filter.component.scss']
})
export class FormFilterComponent implements OnInit {

  @Input() customParam: WorkflowCustomRouteParam = {};

  @Output() onFilterApply: EventEmitter<WorkflowFormFilterParam> = new EventEmitter<WorkflowFormFilterParam>(null);
  @Output() onBack: EventEmitter<boolean> = new EventEmitter<boolean>(null);

  param: WorkflowFormFilterParam = new WorkflowFormFilterParam();

  dateFrom: Date = null;
  dateTo: Date = null;
  dateInputConfig: Partial<BsDatepickerConfig>;

  teams: WorkflowTeam[] = [];
  team: any = null;

  types: WorkflowType[] = [];
  type: any = null;

  stages: WorkflowTypeStage[] = [];
  stage: any = null;

  contentKeyword: string = "";

  allOption: any = {isAll: true, team_id: null, type_id: null, stage_id: null};

  constructor(
    private _workflowService: WorkflowService,
    private _timestampService: TimestampService
  ) { }

  ngOnInit() {
    this.dateInputConfig = Object.assign({}, { 
      containerClass: "theme-default",
      dateInputFormat: "YYYY-MM-DD"
    });

    // get prev param
    this.param = this._workflowService.getCachedWorkflowFilterParam();

    this.getDates();
    this.getOptions();
    this.getContentKeyword();

  }

  getDates(): void {
    if (this.param.createDateFrom) {
      this.dateFrom = this._timestampService.secondToDateObj(this.param.createDateFrom);
    }
    if (this.param.createDateTo) {
      this.dateTo = this._timestampService.secondToDateObj(this.param.createDateTo);
    }
  }

  getOptions(): void {
    this._workflowService.getWorkflowOptions(
      (resp) => {
        this.teams = this._workflowService.teams;
        this.teams = _.union([this.allOption], this.teams);
        if (!this.param.teamId) {
          this.team = this.allOption;
        } else {
          this.team = _.find(this.teams, {team_id: this.param.teamId});
        }

        if (!this.customParam) {
          this.types = _.filter(this._workflowService.types, (type: WorkflowType) => {
            return type.visible_in_app_menu == 0;
          });
          this.types = _.union([this.allOption], this.types);
        } else {
          this.types = [this._workflowService.getTypeByTypeId(this.customParam.typeId)];
        }

        if (!this.param.typeId) {
          this.type = this.types[0];
        } else {
          this.type = _.find(this.types, {type_id: this.param.typeId});
        }

        this.stages = [this.allOption];
        this.stage = this.stages[0];

        if (this.type) {
          this.onTypeOptionChange(this.type);
          if (this.param.stageId) {
            this.stage = _.find(this.stages, {stage_id: this.param.stageId});
          }
        }
      }
    );
  }

  getContentKeyword(): void {
    this.contentKeyword = this.param.content ? this.param.content : "";
  }

  onTypeOptionChange(type: WorkflowType): void {
    this.stages = _.union([this.allOption], type.stages);
    this.stage = this.stages[0];
  }

  formFilterBack(): void {
    this.onBack.emit(false);
  }

  apply(): void {
    let from = this.dateFrom ? this._timestampService.getStartOfDateSecondStringByDateObj(this.dateFrom) : null;
    let to = this.dateTo ? this._timestampService.getEndOfDateSecondStringByDateObj(this.dateTo) : null;

    this.param.createDateFrom = from;
    this.param.createDateTo = to;

    this.param.teamId = this.team.isAll ? null : this.team.team_id;
    this.param.typeId = this.type.isAll ? null : this.type.type_id;
    this.param.stageId = this.stage.isAll ? null : this.stage.stage_id;

    this.param.content = this.contentKeyword;

    this.onFilterApply.emit(this.param);
    this.formFilterBack();

  }

}
