import { Injectable } from '@angular/core';
import { Message } from '../../../models/message';
import { MessagesService } from '../../services/data/messages/messages.service';
import { NewsMessageService } from '../../services/data/messages/news-message/news-message.service';
import { TnDialogService } from '../../../utilities/tn-dialog/tn-dialog.service';
import { NewsModalComponent } from './news-modal.component';
import { NewsService } from '../news.service';

import * as _ from 'lodash';

@Injectable()
export class NewsModalService {

  constructor(
    private _messageService: MessagesService,
    private _newsMessageService: NewsMessageService,
    private _tnDialogService: TnDialogService,
    private _newsService: NewsService
  ) { 
    if (!this._newsService.checkNewsHttp()){
      this._newsMessageService.activeMessage$.subscribe(message => {
        if (message) {
          this.openNewsModal(message);
        }
      });
    }
  }

  openNewsModal(message: Message, isEnableEmoji?: boolean, isEnableComment?: boolean): void {
    // get most updated message
    let latestMessage = this._newsService.parseMessageForDisplay(message, true);
    // let latestMessage = message
    this._messageService.deleteActiveMessageSubject();
    this._messageService.initActiveMessageSubject(message.message_id);

    let data = {
      newsMessage: message,
      parsedMessageWithComment: latestMessage,
      isEnableEmoji: isEnableEmoji,
      isEnableComment: isEnableComment
    };

    this._messageService.activeMessage$.subscribe(msg => {
      if (_.isEmpty(msg)) {
        return;
      }
      latestMessage = this._newsService.parseMessageForDisplay(msg, true);
      if (newsDialogRef && newsDialogRef.componentInstance) {
        // data.newsMessage = latestMessage;
        data.newsMessage = msg;
        data.parsedMessageWithComment = latestMessage
        newsDialogRef.componentInstance.data = data;
      }
    });

    if (isEnableEmoji || isEnableComment) {
      this._newsMessageService.subscribeMessageDetail(message.message_id);
    }

    let newsDialogRef = this._tnDialogService.openTnDialog(NewsModalComponent, data);
    newsDialogRef.afterClosed().subscribe(result => {
      this._messageService.deleteActiveMessageSubject();
    });
  }
  //News enhancement
  openNewsHttpModal(news: any, isEnableEmoji?: boolean, isEnableComment?: boolean): void {
    // get most updated message
    let latestNews = this._newsService.parseNewsHttpForDisplay(news, true);
    // let latestMessage = message

    let data = {
      newsHttp: news,
      parsedNewsWithComment: latestNews,
      isEnableEmoji: isEnableEmoji,
      isEnableComment: isEnableComment,
      isEnableNewsHttp: this._newsService.checkNewsHttp(),
    };
    // if (isEnableEmoji || isEnableComment) {
    //   this._newsMessageService.subscribeMessageDetail(message.message_id);
    // }

    let newsDialogRef = this._tnDialogService.openTnDialog(NewsModalComponent, data);
  }
}
