import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomFieldsMapped, CustomFieldMagicStringMapping } from './models/custom-field';
import { CustomFieldConstant } from './constants/custom-field.constants';

@Component({
  selector: 'tn-custom-field',
  templateUrl: './custom-field.component.html',
  styleUrls: ['./custom-field.component.scss']
})
export class CustomFieldComponent implements OnInit {

  @Input() field: CustomFieldsMapped;
  // @Input() store: Store;
  // @Input() report: StoreReport;
  @Input() availableUserIds: string[] = [];
  @Input() allCustomFields: CustomFieldsMapped[] = [];
  @Input() staticStringMappings: CustomFieldMagicStringMapping = {};
  @Input() isInput: boolean = false;

  @Output() onFieldChange: EventEmitter<CustomFieldsMapped> = new EventEmitter<CustomFieldsMapped>(null);

  FIELD_TYPE = CustomFieldConstant.CUSTOM_FIELD_TYPE;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {

  }

  onFieldUpdate(newVal) {
    this.onFieldChange.emit(this.field);
  }

}
