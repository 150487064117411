export const LOGIN_ACTIONS = {
  TO_GET_STATE_PAGE: "get-state",
  TO_OTP_PAGE: "otp",
  TO_PASSWORD_PAGE: "password",
  TO_SECOND_FACTOR_PAGE: "second-factor",
  TO_SET_PASSWORD_PAGE: "set-password",
  TO_WEBCLIENT_PAGE: "webclient",
  API_REG_INIT: "API_REG_INIT",
  API_WEBCLIENT_REG_DEVICE: "API_WEBCLIENT_REG_DEVICE",
  API_WEBCLIENT_LOGIN: "API_WEBCLIENT_LOGIN"
};
