import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Questionnaire } from '../models/questionnaire';

@Component({
  selector: 'tn-questionnaire-list',
  templateUrl: './questionnaire-list.component.html',
  styleUrls: ['./questionnaire-list.component.scss']
})
export class QuestionnaireListComponent implements OnInit {

  @Input() questionnaires: Questionnaire[];
  @Input() activeQuestionnaire: Questionnaire;
  @Output() onItemClick: EventEmitter<Questionnaire> = new EventEmitter<Questionnaire>(null);

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {

  }

  questionnaireClick(q) {
    this.onItemClick.emit(q);
  }

}
