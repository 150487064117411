import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactPickerComponent } from './contact-picker.component';
import { ContactPickerService } from './contact-picker.service';
import { ContactModule } from '../contact/contact.module';
import { ChatModule } from '../chat/chat.module';
import { UtilitiesModule } from '../../utilities/utilities.module';

@NgModule({
    imports: [
        CommonModule,
        UtilitiesModule,
        ContactModule,
        ChatModule
    ],
    providers: [
        ContactPickerService
    ],
    declarations: [ContactPickerComponent]
})
export class ContactPickerModule { }
