import { Injectable } from '@angular/core';
import { TeamnoteApiService } from '../../api/teamnote-api.service';
import { TrainingFile, TrainingFolder } from './models/training';

import * as _ from 'lodash';
import { TeamNoteTrainingConstant } from './constants/training.constant';
import { TeamNoteApiConstant } from '../../constants/api.constant';
import { AttachmentTypeConstant } from '../../constants/attachment-type.constant';

@Injectable()
export class TrainingService {

  ROOT_FOLDER_NAME: string = TeamNoteTrainingConstant.ROOT_FOLDER_NAME;

  constructor(
    private _teamnoteApiService: TeamnoteApiService
  ) { }

  getTrainingList(success: Function, failure: Function) {
    let url = TeamNoteApiConstant.TRAINING.GET_TRAINING_LIST;
    this._teamnoteApiService.callApi(
      url, 
      {}, 
      success,
      failure
    );
  }

  parseTrainingFilesIntoFolderTree(files: TrainingFile[]): TrainingFolder {
    let rootFolder = new TrainingFolder;
    rootFolder.name = this.ROOT_FOLDER_NAME;
    rootFolder.folders = [];
    rootFolder.files = [];

    let allFolders = _.without(_.uniq(_.map(files, 'folder')), "");

    // handle files with folder
    _.each(allFolders, (folderName) => {
      let childFiles = _.filter(files, (f) => {
        return f.folder == folderName;
      });
      let newFolder = new TrainingFolder;
      newFolder.name = folderName;
      newFolder.type = 'folder';
      newFolder.files = _.sortBy(childFiles, (f) => {
        return f.name.toLowerCase();
      });

      rootFolder.folders.push(newFolder);
    });

    // handle files without folder
    let filesWithoutFolder = _.filter(files, (f) => {
      return !f.folder || f.folder == "";
    });
    rootFolder.files = _.sortBy(filesWithoutFolder, (f) => {
      return f.name.toLowerCase();
    });
    rootFolder.folders = _.sortBy(rootFolder.folders, (f) => {
      return f.name.toLowerCase();
    });

    return rootFolder;
  }

  getFileTypeByFileIds(fileIds: string): string {
    let fileId = fileIds.split(",")[0];
    let split = fileId.split(".");
    let extension = _.last(split).toLowerCase();

    if (_.includes(AttachmentTypeConstant.IMAGE_TYPES, extension)) {
      return TeamNoteTrainingConstant.TYPE.IMG;
    }
    if (_.includes(AttachmentTypeConstant.VIDEO_TYPES, extension)) {
      return TeamNoteTrainingConstant.TYPE.VIDEO;
    }
    if (_.includes(AttachmentTypeConstant.PDF_TYPES, extension)) {
      return TeamNoteTrainingConstant.TYPE.PDF;
    }
    if (_.includes(AttachmentTypeConstant.DOC_TYPES, extension)) {
      return TeamNoteTrainingConstant.TYPE.DOC;
    }

  }

}
