<div class="attachment-video-grid-wrapper">
  <div class="modal-header">
    <h4 class="text-color-primary">{{'WEBCLIENT.CHATROOM.VIDEO' | translate}}</h4>
  </div>
  <div class="attachment-grid-body">
    <div *ngFor="let monthAttachments of timelinedAttachments">
      <tn-attachment-timelined [year]="monthAttachments.year" [month]="monthAttachments.month"></tn-attachment-timelined>
      <div class="attachment-grid">
        <div class="video-preview-wrapper"
          (click)="onClick(video)"
          *ngFor="let video of monthAttachments.attachments">
          <div class="video-preview clickable"
            [ngStyle]="{'background-image': video.imgSrc}">
          </div>
          <div class="video-duration">
            <i class="fa fa-fw fa-video-camera"></i>
            <span>{{video.durationDisplay}}</span>
          </div>
        </div>
      </div>
    </div>

    <tn-empty-page 
      [msgKey]="'GENERAL.NO_VIDEO'" 
      *ngIf="attachments.length == 0">
    </tn-empty-page>
  </div>
</div>