import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { LocalStorageManagerService } from '../../../../app/utilities/local-storage/local-storage-manager.service';
import { TeamNoteLocalStorageKeyConstants } from '../../../../app/constants/local-storage-key.constant';
import { WebDavMaterialService } from '../webdav-material.service';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tn-webdav-material-login',
  templateUrl: './webdav-material-login.component.html',
  styleUrls: ['./webdav-material-login.component.scss']
})
export class WebDavMaterialLoginComponent {

  userName = '';
  password = '';

  @ViewChild('userNameInput', {static: false}) userNameInput: ElementRef;
  @ViewChild('passwordInput', {static: false}) passwordInput: ElementRef;
  constructor(
    private dialogRef: MatDialogRef<WebDavMaterialLoginComponent>,
    private _localStorageManagerService: LocalStorageManagerService,
  ) {
  }
  save() {
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.WEBDAV.USERNAME, this.userName.toString());
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.WEBDAV.PASSWORD, this.password.toString());
    // this.data.isSignIn = true;
    this.dialogRef.close(true);
  }
  close() {
    // this.data.isSignIn = false;
    this.dialogRef.close(false);
  }
}
