import { Injectable } from '@angular/core';
import { WorkflowType, WorkflowTypeStage, WorkflowTypeStageCustomField, WorkflowFormCustomFieldAnswer, WorkflowForm, WorkflowTeam } from '../model/workflow';
import { CustomFieldsMapped, CustomFieldsAnswer } from '../../shared/custom-field/models/custom-field';

import * as _ from 'lodash';
import { CustomFieldConstant } from '../../shared/custom-field/constants/custom-field.constants';
import { TnNotificationService } from '../../../utilities/tn-notification/tn-notification.service';
import { FileManagerService } from '../../../utilities/file-manager/file-manager.service';
import { FieldAttachmentPhotoParsedAnswer } from '../../shared/custom-field/field-attachment-photo/field-attachment-photo.component';
import { FieldAttachmentDocumentParsedAnswer } from '../../shared/custom-field/field-attachment-document/field-attachment-document.component';
import { FieldAttachmentParsedAnswer } from '../../shared/custom-field/field-attachment/field-attachment.component';
import { FieldSignatureAnswer } from '../../shared/custom-field/field-signature/field-signature.component';
import { WorkflowService } from '../services/workflow.service';

@Injectable()
export class FormHelperService {

  constructor(
    private _tnNotificationService: TnNotificationService,
    private _fileManagerService: FileManagerService,
    private _workflowService: WorkflowService
  ) { }

  prepareMappedInputFieldsByTypeAndStageId(type: WorkflowType, stageId: string, answers: CustomFieldsAnswer[]): CustomFieldsMapped[] {
    let stage = _.find(type.stages, (s: WorkflowTypeStage) => {
      return s.stage_id == stageId;
    });
    let fields = [];
    if (!stage) {
      return fields;
    }

    fields = _.orderBy(stage.custom_fields, "field_index");

    return _.map(fields, (f: WorkflowTypeStageCustomField) => {
      let mapped = new CustomFieldsMapped;
      mapped.definition = f;
      let ans = _.find(answers, (ans: CustomFieldsAnswer) => {
        return ans.field_id == f.field_id;
      });
      if (ans) {
        mapped.answer = ans;
      } else {
        mapped.answer = {
          field_id: f.field_id,
          value: ""
        };
      }
      return mapped;
    });
  }

  submitAWorkflowForm(
    mappedInputCustomFields: CustomFieldsMapped[], 
    activeStage: WorkflowTypeStage, 
    type: WorkflowType, 
    team: WorkflowTeam, 
    callback: Function, 
    form?: WorkflowForm
  ): void {
    this.checkFormAnswersForSubmit(
      mappedInputCustomFields,
      (parsedAnswers) => {
        let workflowFormAnswers: WorkflowFormCustomFieldAnswer[] = _.map(parsedAnswers, (ans) => {
          ans.stage_id = activeStage.stage_id;
          return ans;
        });

        let nextStage = this._workflowService.getNextStageByCurrentStageIdAndTypeId(
          activeStage.stage_id,
          type.type_id
        );

        this._workflowService.selectNewPicByTargetStageAndTeamId(
          nextStage,
          team.team_id,
          (userId, userGroupIds) => {
            let picUserIds = [];
            if (userId) {
              picUserIds.push(userId);
            }

            this._workflowService.setWorkflowForm(
              team.team_id,
              type.type_id,
              workflowFormAnswers,
              nextStage.stage_id,
              picUserIds,
              (resp) => {
                callback();
              },
              form ? form.form_id : null,
              userGroupIds
            );
          }
        );
      }
    );
  }

  checkFormAnswersForSubmit(mappedFields: CustomFieldsMapped[], callbackWithParsedAnswers: Function): void {
    let parsedAnswers: CustomFieldsAnswer[] = [];
    let pendingAttachmentFields: CustomFieldsMapped[] = [];
    this.checkFormAnswerByIndex(mappedFields, 0, parsedAnswers, pendingAttachmentFields, callbackWithParsedAnswers);
  }

  checkFormAnswerByIndex(mappedFields: CustomFieldsMapped[], index: number, parsedAnswers: CustomFieldsAnswer[], pendingAttachmentFields: CustomFieldsMapped[], callbackWithParsedAnswers: Function): void {
    if (index == mappedFields.length) {
      this.checkFormUploadAttachmentByIndex(0, parsedAnswers, pendingAttachmentFields, callbackWithParsedAnswers);
      return;
    }

    let field = mappedFields[index];
    if (!field.definition.is_nullable && (field.answer.value === "" || field.answer.value.length == 0)) {
      if (field.definition.field_type != CustomFieldConstant.CUSTOM_FIELD_TYPE.TEXT_DISPLAY) {
        this._tnNotificationService.showCustomErrorByTranslateKey('WEBCLIENT.JOB_REPORT.NEW_REPORT.INPUT_MANDATORY');
        return;
      }
    }

    switch (field.definition.field_type) {
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.ATTACHMENT_PHOTO:
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.ATTACHMENT_DOCUMENT:
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.ATTACHMENT:
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.SIGNATURE:
        pendingAttachmentFields.push(field);
        break;
      default:
        if (field.answer.value && field.answer.value.length > 0) {
          parsedAnswers.push(field.answer);
        }
        break;
    }

    this.checkFormAnswerByIndex(mappedFields, ++index, parsedAnswers, pendingAttachmentFields, callbackWithParsedAnswers);
  }

  checkFormUploadAttachmentByIndex(index: number, parsedAnswers: CustomFieldsAnswer[], pendingAttachmentFields: CustomFieldsMapped[], callbackWithParsedAnswers: Function): void {
    if (index == pendingAttachmentFields.length) {
      callbackWithParsedAnswers(parsedAnswers);
      return;
    }
    let attachmentField = pendingAttachmentFields[index];
    if (attachmentField.answer.value == "" || attachmentField.answer.value.length == 0) {
      this.checkFormUploadAttachmentByIndex(++index, parsedAnswers, pendingAttachmentFields, callbackWithParsedAnswers);
      return;
    }

    let defaultAns: any = [];
    switch (attachmentField.definition.field_type) {
      case CustomFieldConstant.CUSTOM_FIELD_TYPE.SIGNATURE:
        defaultAns = {};
        break;
      default:
        break;
    }

    this.uploadAttachmentsOfField(
      attachmentField,
      0,
      defaultAns,
      (answerArr) => {
        parsedAnswers.push({
          field_id: attachmentField.definition.field_id,
          value: JSON.stringify(answerArr)
        });
        this.checkFormUploadAttachmentByIndex(++index, parsedAnswers, pendingAttachmentFields, callbackWithParsedAnswers);
      }
    );
  }

  uploadAttachmentsOfField(field: CustomFieldsMapped, fileIndex: number, answerArr: any, finishedCallback: Function) {
    if (fileIndex == field.answer.value.length) {
      finishedCallback(answerArr);
      return;
    }

    let answerObj: any = field.answer.value[fileIndex];

    let successWithFileId = (fileId) => {
      switch (field.definition.field_type) {
        case CustomFieldConstant.CUSTOM_FIELD_TYPE.ATTACHMENT_PHOTO:
          let photoAns = new FieldAttachmentPhotoParsedAnswer(null, fileId, answerObj.caption);
          delete photoAns.file;
          answerArr.push(photoAns);
          break;
        case CustomFieldConstant.CUSTOM_FIELD_TYPE.ATTACHMENT_DOCUMENT:
          let docAns = new FieldAttachmentDocumentParsedAnswer(null, fileId, answerObj.name);
          delete docAns.file;
          answerArr.push(docAns);
          break;
        case CustomFieldConstant.CUSTOM_FIELD_TYPE.ATTACHMENT:
          let attachmentAns = new FieldAttachmentParsedAnswer(null, fileId, answerObj.description, answerObj.name);
          delete attachmentAns.file;
          answerArr.push(attachmentAns);
          break;
        case CustomFieldConstant.CUSTOM_FIELD_TYPE.SIGNATURE:
          let ans = new FieldSignatureAnswer(null, fileId);
          delete ans.file;
          answerArr = ans;
          break;
      }
      this.uploadAttachmentsOfField(field, ++fileIndex, answerArr, finishedCallback);
    };

    if (answerObj.attachment_id) {
      successWithFileId(answerObj.attachment_id);
      return;
    } else {
      let file = answerObj.file.file;
      this._fileManagerService.apiUploadFile(
        file,
        (fileId) => {
          successWithFileId(fileId);
          return;
        },
        true
      );
    }
  }

}
