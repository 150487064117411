export const ZhChtNewsStrings = {
  NEWS: {
    NEWS_DATE: "NEWS_DATE_ZH",
    LIKE: "讚好",
    UNLIKE: "取消讚好",
    COMMENT: "留言",
    REPLY: "回覆",
    REPLY_COUNT: {
      ONE: "{{NUM}}個回覆",
      MULTIPLE: "{{NUM}}個回覆"
    },
    ATTACHMENTS_COUNT: {
      ONE: "{{NUM}}個附件",
      MULTIPLE: "{{NUM}}個附件"
    },
    REMOVE_COMMENT_SUCCESS_MSG: "刪除留言成功"
  },

  VOTE: {
    VOTE_BTN: "投票",
    RESULT_BTN: "結果",
    VOTE_OPTION_DESC: {
      ONE: "({{PERCENTAGE}}%, {{COUNT}}個投票)",
      MULTIPLE: "({{PERCENTAGE}}%, {{COUNT}}個投票)"
    },
    SUCCESS_MSG: "投票成功"
  },

  ERROR_MSG: {
    VOTE_NOT_YET_START: "投票尚未開始。請稍後再試",
    VOTE_EXPIRED: "投票已經過時",
    VOTE_RESULT_HIDDEN: "本次投票結果不可公開瀏覽",
    VOTED: "你已經投過票了，答案不可更改",
    THREAD_LOCKED: "主題已鎖定"
  }
};
