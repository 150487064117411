import { Component, Inject, OnInit } from '@angular/core';
import { CorporateMaterialService } from './corporate-material.service';
import { CorporateMaterialFolder, CorporateMaterialItem, CorporateMaterialFile, CorporateMaterialUrl, CorporateMaterialEbook, CorporateMaterialEbookPage, CorporateMaterialCustomRouteParam } from './models/corporate-material';
import { TeamNoteCorporateMaterialConstant } from './constants/corporate-material.constant';

import * as _ from 'lodash';
import { AttachmentService } from '../../utilities/attachment/attachment.service';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageManagerService } from '../../utilities/local-storage/local-storage-manager.service';
import { TeamNoteLocalStorageKeyConstants } from '../../constants/local-storage-key.constant';
import { RouteParamService } from '../../utilities/route-param/route-param.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'tn-corporate-material',
  templateUrl: './corporate-material.component.html',
  styleUrls: ['./corporate-material.component.scss']
})
export class CorporateMaterialComponent implements OnInit {

  // Param
  paramSub: any;
  customParam: CorporateMaterialCustomRouteParam = null;

  currentFolder: CorporateMaterialFolder = null;
  isRootFolder: boolean = false;
  rootTranslateKey: string = "";

  folderIdStack: string[] = [];

  ROOT_LEVEL_ID: string = TeamNoteCorporateMaterialConstant.ROOT_LEVEL_ID;

  // Ebook
  currentViewingEbook: CorporateMaterialEbook = null;

  isDialog: boolean = false;

  constructor(
    private _corporateMaterialService: CorporateMaterialService,
    private _attachmentService: AttachmentService,
    private _route: ActivatedRoute,
    private _localStorageManagerService: LocalStorageManagerService,
    private _routeParamService: RouteParamService,
    public dialogRef: MatDialogRef<CorporateMaterialComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.paramSub = this._routeParamService.currentRouteParam$.subscribe(
      params => {
        this.customParam = params;
        this.getCoporateMaterialList();
        // Reset viewing ebook when param changed
        this.currentViewingEbook = null;
      }
    );
    if (this.data.folderId) {
      this.dialogRef.updateSize('60vw', '80vh')
      this.isDialog = true
      this.ROOT_LEVEL_ID = this.data.folderId
      this.prepareCurrentFolderForDialog(this.data.folderId)
    }

  }

  ngOnDestroy() {
    this.paramSub.unsubscribe();
  }

  prepareCurrentFolderForDialog(folderId): void {
    this.currentFolder = this._corporateMaterialService.getFolderContentByFolderId(folderId);
  }

  /**
   * Get full coporate material list and default display ROOT LEVEL
   * 
   * @returns {void}
   * @memberof CorporateMaterialComponent
   */
  getCoporateMaterialList(): void {
    this.folderIdStack = [];
    this._corporateMaterialService.getCorporateMaterialList(
      (data) => {
        // Handle custom defined root folder
        if (this.customParam && this.customParam.folderName) {
          this.handleOnInitFolderByName(this.customParam.folderName);
        } else {
          this.handleDefaultFolder();
        }

        if (this.customParam) {
          if (this.customParam.folderId) {
            this.handleOnInitFolderById(this.customParam.folderId);
          }
        }

        this.getCorporateMaterialFolder();

        this.handleOnInitMaterialById();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  handleOnInitFolderByName(folderName: string): void {
    let targetFolder = this._corporateMaterialService.getFolderByFolderName(folderName);
    this.folderIdStack.push(targetFolder.folder_id);
    this.rootTranslateKey = this.customParam.titleTranslateKey;
  }

  handleOnInitFolderById(folderId: string): void {
    if (!folderId) {
      return;
    }
    let allParents = this._corporateMaterialService.getFolderParentTreeByFolderId(folderId);
    this.folderIdStack = _.union([this.ROOT_LEVEL_ID], allParents);
  }

  handleOnInitMaterialById(): void {
    if (!this.customParam || !this.customParam.materialId) {
      return;
    }
    let target = _.find(this.currentFolder.childMaterials, (m) => {
      return m.material_id == this.customParam.materialId;
    });
    if (target) {
      this.onItemClick(target);
    }
    this.customParam.materialId = null;
    this._routeParamService.setRouteParam(this.customParam);
  }

  handleDefaultFolder(): void {
    this.rootTranslateKey = 'CORPORATE_MATERIAL';
    this.folderIdStack.push(this.ROOT_LEVEL_ID);
  }

  /**
   * Get sub level corporate material list by folderIdStack
   * now support keyword, try to get existing search keyword from top folder
   * 
   * @memberof CorporateMaterialComponent
   */
  getCorporateMaterialFolder(): void {
    this.isRootFolder = this.folderIdStack.length == 1;
    this.searchFolderByKeyword(this._corporateMaterialService.getSearchKeywordByFolderId(_.last(this.folderIdStack)));
  }

  /**
   * If keyword exists, 
   *    get searched result under top folder,
   * else,
   *    get normal folder content
   *
   * @param {string} keyword - search keyword, or stored keyword
   * @memberof CorporateMaterialComponent
   */
  searchFolderByKeyword(keyword: string): void {
    if (keyword && keyword.length > 0) {
      this.currentFolder = this._corporateMaterialService.getSearchedFolderContentByFolderId(_.last(this.folderIdStack), keyword);
    } else {
      this.currentFolder = this._corporateMaterialService.getFolderContentByFolderId(_.last(this.folderIdStack));
    }
  }

  /**
   * On item click event - can be folder or material
   * 
   * @param {*} item - clicked item
   * @memberof CorporateMaterialComponent
   */
  onItemClick(item: any): void {
    if (item.folder_id) {
      this.onFolderClick(item);
    } else {
      this.onMaterialClick(item);
    }
  }

  /**
   * Handle folder click event, add to folderIdStack and get its list
   * Also clear stored search keyword of the poping folder
   * 
   * @param {CorporateMaterialFolder} folder - target folder
   * @memberof CorporateMaterialComponent
   */
  onFolderClick(folder: CorporateMaterialFolder): void {
    this.folderIdStack.push(folder.folder_id);
    this._corporateMaterialService.clearSearchKeywordByFolderId(folder.folder_id);
    
    this.getCorporateMaterialFolder();
    this.onEbookBack(null);
  }

  /**
   * Handle "back" on folder level
   * 
   * - Go back one level and get its list
   * - Destroy viewing ebook
   * 
   * @param {any} event - (useless)
   * @memberof CorporateMaterialComponent
   */
  onFolderBack(event): void {
    this.folderIdStack.pop();
    this.getCorporateMaterialFolder();
    this.onEbookBack(null);
  }

  /**
   * Handle Material click event
   * 
   * - find target function by type
   * 
   * @param {*} material - target material
   * @memberof CorporateMaterialComponent
   */
  onMaterialClick(material: any): void {
    
    switch (material.type) {
      case TeamNoteCorporateMaterialConstant.TYPE.FILE:
        this.onMaterialFileClick(material);
        break;
      case TeamNoteCorporateMaterialConstant.TYPE.URL:
        this.onMaterialUrlClick(material);
        break;
      case TeamNoteCorporateMaterialConstant.TYPE.EBOOK:
        this.onMaterialEbookClick(material);
        break;
    }
  }

  /**
   * Handle file click event, open file attachment
   * 
   * @param {CorporateMaterialFile} file - target file
   * @memberof CorporateMaterialComponent
   */
  onMaterialFileClick(file: CorporateMaterialFile): void {
    this._attachmentService.prepareAttachmentModalContentByFileId(
      [file.attachment_id],
      null,
      null,
      file.enable_watermark ? false : true,
      file.name,
      file.allow_download ? false : true,
      false
    );
  }
  
  /**
   * Handle URL click event, open new window with url
   * 
   * @param {CorporateMaterialUrl} url - target url
   * @memberof CorporateMaterialComponent
   */
  onMaterialUrlClick(url: CorporateMaterialUrl): void {
    window.open(url.url, "_blank");
  }

  /**
   * Handle ebook click event, get ebook with pages.
   * Display target ebook on the right
   * 
   * @param {CorporateMaterialEbook} ebook - target ebook
   * @memberof CorporateMaterialComponent
   */
  onMaterialEbookClick(ebook: CorporateMaterialEbook): void {
    this._corporateMaterialService.getCorporateMaterialEbook(
      ebook.book_id,
      (data: CorporateMaterialEbook) => {
        data.pages = _.orderBy(data.pages, ['page_no', 'name']);
        this.currentViewingEbook = data;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   * Handle "back" of ebook, either from ebook back button or folder navigations
   * 
   * @param {CorporateMaterialEbook} ebook - (useless)
   * @memberof CorporateMaterialComponent
   */
  onEbookBack(ebook: CorporateMaterialEbook): void {
    this.currentViewingEbook = null;
  }

}
