/**
 * Hover Dropdown Menu component
 * 
 * This will only show the hover-display dropdown button.
 * The real openContextMenu action is done in parent component.
 * 
 * Accept [id] in order to check if this button should be shown.
 * Toggle local state [isDropdownToggled] when clicked on button.
 * 
 * Subscribe to menu$ subject and check if context menu is dismissed or another component's context menu is opened, [isDropdownToggled] becomes false when it is not the current context menu parent.
 * 
 * Created by Shan - 2018-04-30
 */

import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { ContextMenu, ContextMenuItem, ContextMenuService } from '../context-menu.service';

@Component({
  selector: 'tn-hover-dropdown-menu',
  templateUrl: './hover-dropdown-menu.component.html',
  styleUrls: ['./hover-dropdown-menu.component.scss']
})
export class HoverDropdownMenuComponent implements OnInit {

  @Input() id: string = "";

  isDropdownToggled: boolean = false;

  constructor(
    private _contextMenuService: ContextMenuService
  ) { 
    this._contextMenuService.menu$.subscribe((menu: ContextMenu) => {
      if (!menu.isShow || menu.id != this.id) {
        this.isDropdownToggled = false;
      }
    });
  }

  ngOnInit() {
  }

  /**
   * Toggle local state to true.
   * 
   * @memberof HoverDropdownMenuComponent
   */
  toggle(): void {
    this.isDropdownToggled = true;
  }
}
