import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StoreReportCustomFieldsMapped } from '../../models/job-report';

export class FieldDropdownDetail {
  options: string[];
  is_extra_text: number;
}

@Component({
  selector: 'tn-field-dropdown',
  templateUrl: './field-dropdown.component.html',
  styleUrls: ['./field-dropdown.component.scss']
})
export class FieldDropdownComponent implements OnInit {

  @Input() field: StoreReportCustomFieldsMapped;
  @Input() isInput: boolean = false;

  @Output() onFieldUpdate: EventEmitter<StoreReportCustomFieldsMapped> = new EventEmitter<StoreReportCustomFieldsMapped>(null);

  detail: FieldDropdownDetail;
  answer: string;

  EXTRA_TEXT_OPTION: string = "EXTRA_TEXT";
  extraTextInput: string = '';

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.field.definition.detail) {
      this.detail = JSON.parse(this.field.definition.detail);
    } else {
      this.detail = {
        options: [],
        is_extra_text: 0
      };
    }
    this.answer = this.field.answer.value;
  }

  getAnswerValue(): string {
    if (this.answer != this.EXTRA_TEXT_OPTION) {
      return this.answer;
    } else {
      return this.extraTextInput;
    }
  }

  onChange() {
    this.field.answer.value = this.getAnswerValue();
    this.onFieldUpdate.emit(this.field);
  }

}
