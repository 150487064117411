/**
 * Full Text Search module
 * 
 * Create by Shan - 2018-04-26
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FtsService } from './fts.service';
import { FtsContactService } from './contact/fts-contact.service';
import { FtsChatService } from './chat/fts-chat.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    FtsService,
    FtsContactService,
    FtsChatService
  ]
})
export class FtsModule { }
