/**
 * [INFO] - Information provided by server API / socket
 * [LOG] - User's action / Visible page changes
 * [DEBUG] - Trace of data processing
 * [WARN] - Warning, may not be important
 * [ERROR] - Error, likely to cause visible bugs
 */

import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

import * as _ from 'lodash';
import { TeamnoteConfigService } from '../../configs/teamnote-config.service';

@Injectable()
export class LoggerService {
  logs: any[] = [];

  isLoggingOn = true;
  isEnableLogger = true;

  constructor(
    private _teamnoteConfigService: TeamnoteConfigService
  ) {
    this._teamnoteConfigService.config$.subscribe((config) => {
      if (environment.production) {
        this.isLoggingOn = config.GENERAL.IS_ENABLE_DEBUG;
      }

      this.isEnableLogger = config.LOGGER.ENABLE_LOGGER;
    });
  }

  storeLogMessageObj(type: string, ms: any, dateTime: string, msg: string) {
    this.logs.push({
      type: type,
      timestamp: ms,
      dateTime: dateTime,
      msg: msg
    });
  }

  log(msg: string) {
    if (!this.isEnableLogger) {
      return;
    }

    const ms = new Date().getTime();
    const dateTime = new Date().toString();
    const type = '[LOG]';
    const full = _.join([type, dateTime, ms], '-') + '\n' + msg;
    this.storeLogMessageObj(type, ms, dateTime, msg);
    if (!this.isLoggingOn) {
      return;
    }
    console.log(full);
  }

  debug(msg: string) {
    if (!this.isEnableLogger) {
      return;
    }

    const ms = new Date().getTime();
    const dateTime = new Date().toString();
    const type = '[DEBUG]';
    const full = _.join([type, dateTime, ms], '-') + '\n' + msg;
    this.storeLogMessageObj(type, ms, dateTime, msg);
    if (!this.isLoggingOn) {
      return;
    }
    console.debug(full);
  }

  info(msg: string) {
    if (!this.isEnableLogger) {
      return;
    }

    const ms = new Date().getTime();
    const dateTime = new Date().toString();
    const type = '[INFO]';
    const full = _.join([type, dateTime, ms], '-') + '\n' + msg;
    this.storeLogMessageObj(type, ms, dateTime, msg);
    if (!this.isLoggingOn) {
      return;
    }
    console.info(full);
  }

  warn(msg: string) {
    const ms = new Date().getTime();
    const dateTime = new Date().toString();
    const type = '[WARN]';
    const full = _.join([type, dateTime, ms], '-') + '\n' + msg;

    if (this.isEnableLogger) {
      this.storeLogMessageObj(type, ms, dateTime, msg);
    }

    if (!this.isLoggingOn) {
      return;
    }
    console.warn(full);
  }

  error(msg: string) {
    const ms = new Date().getTime();
    const dateTime = new Date().toString();
    const type = '[ERROR]';
    const full = _.join([type, dateTime, ms], '-') + '\n' + msg;
    if (this.isEnableLogger) {
      this.storeLogMessageObj(type, ms, dateTime, msg);
    }
    if (!this.isLoggingOn) {
      return;
    }
    console.error(full);
  }

  logToConsole(msg: string) {
    const ms = new Date().getTime();
    const dateTime = new Date().toString();
    console.debug(dateTime + ' - ' + ms + '\n' + msg);
    this.logs.push({
      timestamp: ms,
      dateTime: dateTime,
      msg: msg
    });
  }

  errorToConsole(err: string) {
    const ms = new Date().getTime();
    const dateTime = new Date().toString();
    console.error(dateTime + ' - ' + ms + '\n' + err);
    this.logs.push({
      timestamp: ms,
      dateTime: dateTime,
      msg: err
    });
  }
}
