<div class="field-attachment-wrapper">
  <div class="attachment-wrapper" 
    [ngClass]="{
      'attachment-photo-wrapper': ansObj.type == attachmentTypes.photo,
      'attachment-video-wrapper': ansObj.type == attachmentTypes.video,
      'attachment-file-wrapper': ansObj.type == attachmentTypes.file || ansObj.type == attachmentTypes.audio,
      'input-mode': isInput
    }"
    *ngFor="let ansObj of localAnsObj">
    <div class="attachment-row">
      <div class="attachment" (click)="openAttachments(ansObj)">
        <div class="photo" *ngIf="ansObj.type == attachmentTypes.photo">
          <div class="photo-view" 
            *ngIf="ansObj.file"
            [ngStyle]="{'background-image': 'url(' + ansObj.file.imagePreview + ')'}">
          </div>
          <div class="photo-view" 
            *ngIf="ansObj.src" 
            [ngStyle]="{'background-image': ansObj.src}">
          </div>
        </div>
        <div class="video" *ngIf="ansObj.type == attachmentTypes.video">
          <div class="file-name">
            <div class="file-icon form-attachment-video-icon"></div>
            {{ansObj.name}}
          </div>
        </div>
        <div class="file" *ngIf="ansObj.type == attachmentTypes.file || ansObj.type == attachmentTypes.audio">
          <div class="file-name">
            <div class="file-icon form-attachment-file-icon"></div>
            {{ansObj.name}}
          </div>
        </div>
      </div>

      <div class="action-icon-wrapper" *ngIf="isInput">
        <div class="form-delete-icon" (click)="removeAttachment(ansObj)"></div>
      </div>
    </div>

    <div class="description-row">
      <ng-container *ngIf="isInput">
        <input type="text" class="form-control" [(ngModel)]="ansObj.description" (ngModelChange)="onChange()">
      </ng-container>
      <ng-container *ngIf="!isInput">
        {{ansObj.description}}
      </ng-container>
    </div>
  </div>

  <div class="attachment-button-row" *ngIf="isInput">
    <div class="buttons-wrapper" *ngIf="!detail.allow_type.file || !isAllowCorporateMaterial" (click)="openFileUploader()">
      <div class="button" *ngIf="detail.allow_type.photo">
        <div class="form-attachment-photo-icon"></div>
      </div>
      <div class="button" *ngIf="detail.allow_type.video">
        <div class="form-attachment-video-icon"></div>
      </div>
      <div class="button" *ngIf="detail.allow_type.file">
        <div class="form-attachment-file-icon"></div>
      </div>
    </div>


    <div class="btn-group tn-dropdown-group" dropdown [dropup]="true"
      *ngIf="detail.allow_type.file && isAllowCorporateMaterial">
      <button dropdownToggle type="button" class="dropdown-toggle tn-dropdown-button">
        <div class="buttons-wrapper">
          <div class="button" *ngIf="detail.allow_type.photo">
            <div class="form-attachment-photo-icon"></div>
          </div>
          <div class="button" *ngIf="detail.allow_type.video">
            <div class="form-attachment-video-icon"></div>
          </div>
          <div class="button" *ngIf="detail.allow_type.file">
            <div class="form-attachment-file-icon"></div>
          </div>
        </div>
      </button>
      <ul *dropdownMenu class="dropdown-menu" role="menu">
        <li role="menuitem">
          <div class="tn-dropdown-item" 
            (click)="openFileUploader()">
            {{'GENERAL.UPLOAD' | translate}}
          </div>
        </li>
        <li role="menuitem">
          <div class="tn-dropdown-item"
            (click)="openCorporateMatieralSelector()">
            {{'UTILITY.CUSTOM_FIELD.ATTACHMENT.SELECT_TN_CORPORATE_MATERIAL' | translate}}
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>