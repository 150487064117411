export const ZhChtBroadcastStrings = {
  TITLE: "廣播",

  RECIPIENTS: "收件人",
  RECIPIENT_COUNT: {
    ONE: "{{NUM}} 收件人",
    MULTIPLE: "{{NUM}} 收件人"
  },
  RECIPIENT_REMOVE: "全部刪除 ({{NUM}})",
  ADD_FROM_TEAMNOTE: "從團信新增",
  ADD_FROM_BROADCAST_LIST: "從廣播列表新增",

  SEND: {
    TITLE: "發送廣播消息",
  },

  MANAGE: {
    TITLE: "管理廣播列表",
    ADD_BROADCAST_LIST: "新增廣播列表",
    EDIT_BROADCAST_LIST: "更改廣播列表",
    DELETE_BROADCAST_LIST_CONFIRM: "刪除廣播列表 - {{NAME}} ?"
  }
}
