import { Injectable } from '@angular/core';

import * as _ from 'lodash';

export class CollapsibleListLabel {
  isLabel: boolean;
  label?: string;
  key: string;
  isShow: boolean;
  itemsLength: number;
  unreadCounts?: number;
  allowCollapse?: boolean;
}

export class CollapsibleList {
  isDisplay: boolean;
  isCollapsed: boolean;
  labelTranslateKey: string;
  items: any[];
  allowCollapse?: boolean;

  unreadCounts?: number;
}

export class CollapsibleLists {
  [key: string]: CollapsibleList
}

export const CHAT_LIST_CATEGORY_KEYS_LABEL = {
  FAVOURITE: {
    key: "FAVOURITE",
    label: "WEBCLIENT.CHAT.HEADER_FAVORITE",
    isGroup: true,
    isTime: true,
    allowCollapse: true
  },
  ANNOUNCEMENT: {
    key: "ANNOUNCEMENT",
    label: "WEBCLIENT.CHAT.HEADER_ANNOUNCEMENT",
    isGroup: true,
    isTime: false,
    allowCollapse: true
  },
  GROUP: {
    key: "GROUP",
    label: "WEBCLIENT.CHAT.HEADER_GROUP",
    isGroup: true,
    isTime: false,
    allowCollapse: true
  },
  INDIVIDUAL: {
    key: "INDIVIDUAL",
    label: "WEBCLIENT.CHAT.HEADER_INDIVIDUAL",
    isGroup: true,
    isTime: false,
    allowCollapse: true
  },
  ALL: {
    key: "ALL",
    label: "WEBCLIENT.MENU.CHATS",
    isGroup: false,
    isTime: true,
    allowCollapse: false
  },
  CONTACT: {
    key: "CONTACT",
    label: "WEBCLIENT.CHAT.HEADER_INDIVIDUAL",
    isGroup: false,
    isTime: false,
    allowCollapse: true
  },
  USER: {
    key: "USER",
    label: "WEBCLIENT.CHAT.HEADER_INDIVIDUAL",
    isGroup: false,
    isTime: false,
    allowCollapse: true
  },
  USER_GROUP: {
    key: "USER_GROUP",
    label: "WEBCLIENT.CHAT.HEADER_GROUP",
    isGroup: false,
    isTime: false,
    allowCollapse: true
  }
};

@Injectable()
export class CollapsibleListService {

  constructor() { }

  createCollapsibleList(labelKey: string, items: any[], allowCollapse?: boolean): CollapsibleList {
    return {
      isDisplay: true,
      isCollapsed: false,
      labelTranslateKey: labelKey,
      items: items,
      allowCollapse: allowCollapse
    };
  }

  createListLabel(labelKey: string, key: string, isShow: boolean, length?: number, unreadCounts?: number, allowCollapse?: boolean): CollapsibleListLabel {
    return {
      isLabel: true,
      label: labelKey,
      key: key,
      isShow: isShow,
      itemsLength: length,
      unreadCounts: unreadCounts,
      allowCollapse: allowCollapse
    };
  }

  buildFullDisplayListByLists(lists: CollapsibleLists): any[] {
    let full = [];
    _.each(lists, (obj, key) => {
      if (obj.isDisplay) {
        full.push(this.createListLabel(obj.labelTranslateKey, key, !obj.isCollapsed, obj.items.length, obj.unreadCounts, obj.allowCollapse));

        if (!obj.isCollapsed) {
          full = _.union(full, obj.items);
        }
      }
    });
    return full;
  }



}
