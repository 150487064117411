import { Injectable } from '@angular/core';
import { TnDialogService } from '../tn-dialog/tn-dialog.service';
import { SignatureComponent, SignatureConstants } from './signature.component';
import { ParsedFile } from '../file-uploader/file-uploader.component';
import { FileManagerService } from '../file-manager/file-manager.service';
import { AttachmentTypeConstant } from '../../constants/attachment-type.constant';

@Injectable()
export class SignatureService {

  constructor(
    private _tnDialogService: TnDialogService,
    private _fileManagerService: FileManagerService
  ) { }

  openSignatureDialog(signatureFile: ParsedFile, saveCallbackWithDataUrlAndIsSigned?: Function, isSigned?: boolean): void {
    let dialogRef = this._tnDialogService.openTnDialog(SignatureComponent, {
      signatureFile: signatureFile,
      saveCallbackWithDataUrlAndIsSigned: saveCallbackWithDataUrlAndIsSigned,
      isSigned: isSigned
    });
  }

  getDefaultSignatureFile(): ParsedFile {
    let signatureName = SignatureConstants.default.name;

    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    canvas.width = SignatureConstants.default.width;
    canvas.height = SignatureConstants.default.height;

    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    let dataUrl = canvas.toDataURL("image/jpeg");

    let file = this._fileManagerService.dataUrlToFile(dataUrl, signatureName);

    return {
      file: file,
      type: AttachmentTypeConstant.IMAGE,
      name: signatureName,
      imagePreview: dataUrl,
      isValid: true
    };
  }

}
