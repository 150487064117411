import { Injectable } from '@angular/core';
import { TeamnoteApiService } from '../../api/teamnote-api.service';
import { StoreReport, JobDispatch, Store, StoreReportType, StoreReportOptions, StoreReportCustomFieldsMapped, StoreReportCustomFieldsAnswer, StoreReportFilterParam, StoreReportCustomRouteParam } from './models/job-report';

import * as _ from 'lodash';
import { JobReportConstant } from './constants/job-report.constants';
import { TnNotificationService } from '../../utilities/tn-notification/tn-notification.service';
import { DataManagerService } from '../services/data/data-manager.service';
import { TeamNoteApiConstant } from '../../constants/api.constant';
import { TimestampService } from '../../utilities/timestamp/timestamp.service';
import { SideNavService, SideNavItem } from '../../utilities/tn-side-nav/side-nav.service';
import { ModuleKeyDefinition } from '../../constants/module.constant';
import { PageUrlConstant } from '../../constants/page-url.constant';
import { TeamNoteLocalStorageKeyConstants } from '../../constants/local-storage-key.constant';
import { LocalStorageManagerService } from '../../utilities/local-storage/local-storage-manager.service';
import { TeamnoteConfigService } from '../../configs/teamnote-config.service';
import { WebclientRoutingService } from '../route/webclient-routing.service';
import { RouteParamService } from '../../utilities/route-param/route-param.service';
import { JobFilterHelperService } from './job-filter-helper.service';

@Injectable()
export class JobReportService {

  storeOptions: Store[];

  typeOptions: StoreReportType[];
  visibleInAppMenuTypes: StoreReportType[] = [];
  baseSideNavKey = ModuleKeyDefinition.STORE_REPORT;
  currentNavKeys: string[] = [];

  storeReports: StoreReport[];
  jobDispatchs: JobDispatch[];

  reportFilterParam: StoreReportFilterParam;
  jobFilterParam: StoreReportFilterParam;

  reportSearchableIndex: {[reportId: string]: string} = {};
  jobSearchableIndex: {[jobId: string]: string} = {};

  constructor(
    private _teamnoteApiService: TeamnoteApiService,
    private _tnNotificationService: TnNotificationService,
    private _dataManagerService: DataManagerService,
    private _timestampService: TimestampService,
    private _sideNavService: SideNavService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _webclientRoutingService: WebclientRoutingService,
    private _routeParamService: RouteParamService,
    private _jobFilterHelperService: JobFilterHelperService
  ) { }

  // handle the side navs
  handleInitialStoreReportContent(isNeedJobDispatch: boolean): void {
    // get options and add side nav
    this.getStoreReportOptions(
      (resp) => {
        // Handle previous session.
        this.handleInitialPreviousSession();

        if (isNeedJobDispatch) {
          this.handleInitialJobDispatchContent();
        }
      }
    );
  }

  handleInitialPreviousSession(): void {
    let prev: string = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE_MODULE);
    if (!prev || prev.indexOf(ModuleKeyDefinition.STORE_REPORT) == -1) {
      // prev session is not store report page. skip.
      return;
    }

    let goToDefaultPage = () => {
      this._sideNavService.updateActiveSideNav(this._teamnoteConfigService.config.WEBCLIENT.GENERAL.WEBCLIENT_DEFAULT_PAGE_KEY);
      this._webclientRoutingService.goToDefaultPage();
    };

    let goToBaseStoreReportPage = () => {
      // go to base store report page, clear all route param
      this._sideNavService.updateActiveSideNav(ModuleKeyDefinition.STORE_REPORT);
      this._routeParamService.setRouteParam(null);
      if (this.visibleInAppMenuTypes.length == this.typeOptions.length) {
        // now all types are in app menu, no base store report page, route to default page instead.
        goToDefaultPage();
        return;
      }
    };

    if (prev == ModuleKeyDefinition.STORE_REPORT) {
      // prev session is base store report page.
      goToBaseStoreReportPage();
    }

    let typeId = _.last(prev.split("_"));
    if (!this.getTypeByTypeId(typeId)) {
      // prev session is a typed store report page, but this type no longer exist, route to base report page instead.
      goToBaseStoreReportPage();
      return;
    }

    if (!this.checkIfTypeIsVisibleInAppMenuByTypeId(typeId)) {
      // prev session is a typed store report page, this type still exists but no longer visible in app menu, go to base report page instead.
      goToBaseStoreReportPage();
      return;
    }
  }

  handleInitialJobDispatchContent(): void {
    this.getJobDispatchList(
      (jobs: JobDispatch[]) => {
        this.updateAllJobCountByJobs(jobs);
      }
    );
  }

  // Handle Typed Side Nav
  handleTypeSideNavChanges(): void {
    // remomve all current extra side navs first
    _.each(this.currentNavKeys, (key) => {
      this._sideNavService.removeModuleByModuleKey(key);
    });

    this.visibleInAppMenuTypes = _.sortBy(_.filter(this.typeOptions, {visible_in_app_menu: 1}), "name");
    if (this.visibleInAppMenuTypes.length > 0) {
      _.each(this.visibleInAppMenuTypes, (type, index) => {
        let key = [this.baseSideNavKey, type.type_id].join("_");
        let fractionIndex = (index + 1) / (this.visibleInAppMenuTypes.length * 2);
        let newSideNavItem = new SideNavItem(
          key,
          PageUrlConstant.WEBCLIENT.STORE_REPORT,
          "edit",
          null,
          type.name,
          null,
          new StoreReportCustomRouteParam(type.type_id),
          true
        );
        this._sideNavService.appendNewSideNavFromBaseKey(
          newSideNavItem,
          this.baseSideNavKey,
          fractionIndex
        );
        this.currentNavKeys.push(key);
      });
    }

    if (this.visibleInAppMenuTypes.length == this.typeOptions.length) {
      // remove base store report tab
      this._sideNavService.removeModuleByModuleKey(this.baseSideNavKey);
    } else {
      // show base store report tab
      this._sideNavService.showModuleByModuleKey(this.baseSideNavKey);
    }
  }

  // APIs - data gathering
  getStoreReportOptions(success?: Function) {
    let url = TeamNoteApiConstant.JOB_REPORT.STORE_REPORT.GET_STORE_REPORT_OPTIONS;
    let params = {};
    this._teamnoteApiService.callApi(
      url,
      params,
      (resp: StoreReportOptions) => {
        this.storeOptions = _.orderBy(resp.stores, 'name');
        this.typeOptions = _.orderBy(resp.types, 'name');
        this.handleTypeSideNavChanges();
        if (success) {
          success();
        }
      },
      (err) => {
        this._tnNotificationService.showSystemError();
      }
    );
  }
  getStoreReportList(success: Function, typeId?: string) {
    let url = TeamNoteApiConstant.JOB_REPORT.STORE_REPORT.GET_STORE_REPORT_LIST;
    let params = {
      type_id: typeId
    };
    this._teamnoteApiService.callApi(
      url,
      params,
      (resp: StoreReport[]) => {
        this.storeReports = this.sortStoreReport(resp);
        this.buildReportSeachableIndex(resp);
        success(this.storeReports);
      },
      (err) => {
        this._tnNotificationService.showSystemError();
      }
    );
  }
  submitStoreReport(storeId: string, typeId: string, customFields: any, success: Function) {
    let url = TeamNoteApiConstant.JOB_REPORT.STORE_REPORT.SUBMIT_STORE_REPORT;
    let params = {
      store_id: storeId,
      type_id: typeId,
      custom_fields: customFields
    };
    this._teamnoteApiService.callApi(
      url,
      params,
      (resp) => {
        success(resp);
      },
      (err) => {
        this._tnNotificationService.showSystemError();
      }
    )
  }

  getJobDispatchList(success: Function, typeId?: string) {
    let url = TeamNoteApiConstant.JOB_REPORT.JOB_DISPATCH.GET_JOB_DISPATCH_LIST;
    let params = {
      type_id: typeId
    };
    this._teamnoteApiService.callApi(
      url,
      params,
      (resp: JobDispatch[]) => {
        let jobsNotUpdated = _.filter(this.jobDispatchs, (job) => {
          return !_.find(resp, {job_id: job.job_id});
        });
        resp = _.union(jobsNotUpdated, resp);

        this.updateAllJobCountByJobs(resp);
        this.buildJobSeachableIndex(resp);
        this.jobDispatchs = this.sortJobDispatch(resp);
        success(this.jobDispatchs);
      },
      (err) => {
        this._tnNotificationService.showSystemError();
      }
    );
  }
  assignJobDispatch(reportId: string, userId: string, success: Function) {
    let url = TeamNoteApiConstant.JOB_REPORT.JOB_DISPATCH.DISPATCH_JOB;
    let params = {
      report_id: reportId,
      worker_id: userId
    };
    this._teamnoteApiService.callApi(
      url,
      params,
      (resp) => {
        this._tnNotificationService.showCustomInfoByTranslateKey('WEBCLIENT.JOB_REPORT.MSG.ASSIGN_SUCCESS');
        success(resp);
      },
      (err) => {
        this._tnNotificationService.showSystemError();
      }
    );
  }
  acceptJobDispatch(jobId: string, success: Function) {
    let url = TeamNoteApiConstant.JOB_REPORT.JOB_DISPATCH.ACCEPT_JOB;
    let params = {
      job_id: jobId
    };
    this._teamnoteApiService.callApi(
      url,
      params,
      success,
      (err) => {
        this._tnNotificationService.showSystemError();
      }
    );
  } 
  rejectJobDispatch(jobId: string, success: Function) {
    let url = TeamNoteApiConstant.JOB_REPORT.JOB_DISPATCH.DECLINE_JOB;
    let params = {
      job_id: jobId
    };
    this._teamnoteApiService.callApi(
      url,
      params,
      success,
      (err) => {
        this._tnNotificationService.showSystemError();
      }
    );
  } 
  startJobDispatch(jobId: string, success: Function) {
    let url = TeamNoteApiConstant.JOB_REPORT.JOB_DISPATCH.START_JOB;
    let params = {
      job_id: jobId
    };
    this._teamnoteApiService.callApi(
      url,
      params,
      success,
      (err) => {
        this._tnNotificationService.showSystemError();
      }
    );
  } 
  endJobDispatch(jobId: string, success: Function, customFields?: any) {
    let url = TeamNoteApiConstant.JOB_REPORT.JOB_DISPATCH.FINISH_JOB;
    let params = {
      job_id: jobId,
      custom_fields: customFields
    };
    this._teamnoteApiService.callApi(
      url,
      params,
      success,
      (err) => {
        this._tnNotificationService.showSystemError();
      }
    );
  } 
  followUpJobDispatch(jobId: string, success: Function, customFields?: any) {
    let url = TeamNoteApiConstant.JOB_REPORT.JOB_DISPATCH.FOLLOW_UP_JOB;
    let params = {
      job_id: jobId,
      custom_fields: customFields
    };
    this._teamnoteApiService.callApi(
      url,
      params,
      success,
      (err) => {
        this._tnNotificationService.showSystemError();
      }
    );
  } 

  // Store
  getStoreByStoreId(storeId: string): Store {
    return _.find(this.storeOptions, (s) => {
      return s.store_id == storeId;
    });
  }

  // Types
  getTypeByTypeId(typeId: string): StoreReportType {
    return _.find(this.typeOptions, (t) => {
      return t.type_id == typeId;
    });
  }

  checkIfTypeIsVisibleInAppMenuByTypeId(typeId: string): boolean {
    let type = this.getTypeByTypeId(typeId);
    if (type && type.visible_in_app_menu) {
      return true;
    }
    return false;
  }

  /**
   * Get report status string
   * The string is used to map status display string and status component's class name
   * @param status 
   */
  getReportStatusStr(status: number): string {
    let str = '';
    _.each(JobReportConstant.JOB_DISPATCH_STATUS, (s, key) => {
      if (s == status) {
        str = key;
      }
    });
    return str;
  }
  
  getAllReportStatus(): any[] {
    return _.map(JobReportConstant.JOB_ORDERING, (s) => {
      return {
        statusTranslateKey: this.getReportStatusStr(s),
        status: s
      };
    });
  }

  prepareMappedReportFieldInputByTypeId(typeId: string, isJobResponse?: boolean): StoreReportCustomFieldsMapped[] {
    let type = this.getTypeByTypeId(typeId);
    let targetFields = isJobResponse ? type.job_type.custom_fields : type.custom_fields;
    let sortedFields = _.orderBy(targetFields, 'field_index');

    let mappedCustomFields = _.map(sortedFields, (f) => {
      let mapped = new StoreReportCustomFieldsMapped;
      mapped.definition = f;
      mapped.answer = {
        field_id: f.field_id,
        value: ''
      };
      return mapped;
    });
    return mappedCustomFields;
  }

  getMappedReportFieldsByTypeIdAndAnswers(typeId: string, customFieldsAnswers: StoreReportCustomFieldsAnswer[], isJobResponse?: boolean): StoreReportCustomFieldsMapped[] {
    let type = this.getTypeByTypeId(typeId);
    let targetFields = isJobResponse ? type.job_type.custom_fields : type.custom_fields;
    let sortedFields = _.orderBy(targetFields, 'field_index');

    let mappedCustomFields = _.map(sortedFields, (f) => {
      let mapped = new StoreReportCustomFieldsMapped;
      mapped.definition = f;
      mapped.answer = _.find(customFieldsAnswers, (a) => {
        return f.field_id == a.field_id;
      });
      return mapped;
    });

    return mappedCustomFields;
  }

  sortStoreReport(reports: StoreReport[]): StoreReport[] {
    _.each(reports, (r) => {
      if (r.job_dispatch.length == 0) {
        r.reportStatus = JobReportConstant.JOB_DISPATCH_STATUS.NOT_ASSIGNED;
      } else {
        // Sort job_dispatch array and get latest job
        r.job_dispatch = _.orderBy(r.job_dispatch, 'dispatch_date', 'desc');
        r.reportStatus = r.job_dispatch[0].accept_status;
        _.each(r.job_dispatch, (j) => {
          j.reportStatusStr = this.getReportStatusStr(j.accept_status);
        });
      }
      r.reportStatusStr = this.getReportStatusStr(r.reportStatus);
      r.order = _.indexOf(JobReportConstant.JOB_ORDERING, r.reportStatus);
    });
    return _.orderBy(reports, ['order', 'create_date'], ['asc', 'desc']);
  }

  sortJobDispatch(jobs: JobDispatch[]): JobDispatch[] {
    _.each(jobs, (j) => {
      j.reportStatusStr = this.getReportStatusStr(j.accept_status);
      j.report.reportStatusStr = j.reportStatusStr;
      j.order = _.indexOf(JobReportConstant.JOB_ORDERING, j.accept_status);
    });
    return _.orderBy(jobs, ['order', 'dispatch_date'], ['asc', 'desc']);
  }

  updateAllJobCountByJobs(jobs: JobDispatch[]): void {
    _.each(this.visibleInAppMenuTypes, (type) => {
      let key = [this.baseSideNavKey, type.type_id].join("_");
      let jobsUnderType = _.filter(jobs, {type_id: type.type_id});
      this._sideNavService.updateSideNavCountByKey(
        key,
        this.getUnfinishedJobCount(jobsUnderType)
      );
    });

    let otherJobs = _.filter(jobs, (job) => {
      if (this.checkIfTypeIsVisibleInAppMenuByTypeId(job.type_id)) {
        return false;
      }
      return true;
    });

    this._sideNavService.updateSideNavCountByKey(
      this.baseSideNavKey,
      this.getUnfinishedJobCount(otherJobs)
    );
  }

  getUnfinishedJobCount(jobs: JobDispatch[]): number {
    let count = 0;
    _.each(jobs, (j) => {
      if (_.indexOf(JobReportConstant.UNFINISHED_JOB_STATUS, j.accept_status) !== -1) {
        count++;
      }
    });
    return count;
  }

  checkIfJobNeedJobResponse(job: JobDispatch): boolean {
    let type = this.getTypeByTypeId(job.type_id);
    if (type.job_type.custom_fields.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  // Exports
  exportFormOrJob(param: any, isJob: boolean, success: Function): void {
    let url = TeamNoteApiConstant.JOB_REPORT.STORE_REPORT.EXPORT_STORE_REPORT;
    if (isJob) {
      url = TeamNoteApiConstant.JOB_REPORT.JOB_DISPATCH.EXPORT_JOB;
    }
    this._teamnoteApiService.callApi(
      url,
      param,
      success,
      (err) => {
        this._tnNotificationService.showSystemError();
      }
    );    
  }

  // Filter
  clearReportFilterParamCache(): void {
    this.jobFilterParam = new StoreReportFilterParam();
    this.reportFilterParam = new StoreReportFilterParam();
  }
  cacheReportFilterParam(isJob: boolean, param: StoreReportFilterParam): void {
    if (isJob) {
      this.jobFilterParam = param;
    } else {
      this.reportFilterParam = param;
    }
  }
  getCachedReportFilterParam(isJob: boolean): StoreReportFilterParam {
    if (isJob) {
      return this.jobFilterParam;
    } else {
      return this.reportFilterParam;
    }
  }
  applyFilterOnReport(customParam: StoreReportCustomRouteParam): StoreReport[] {
    let filter = this.reportFilterParam;
    let filtered = this.storeReports;

    if (filter.reportDateFrom) {
      filtered = _.filter(filtered, (report) => {
        return this._timestampService.checkIfTimeCorrectOrder(filter.reportDateFrom, report.create_date);
      });
    }

    if (filter.reportDateTo) {
      filtered = _.filter(filtered, (report) => {
        return this._timestampService.checkIfTimeCorrectOrder(report.create_date, filter.reportDateTo);
      });
    }

    if (filter.storeId) {
      filtered = _.filter(filtered, (report) => {
        return report.store_id == filter.storeId;
      });
    }

    if (filter.typeId) {
      filtered = _.filter(filtered, (report) => {
        return report.type_id == filter.typeId;
      });
    }
    if (!customParam) {
      filtered = _.filter(filtered, (report) => {
        return report.type.visible_in_app_menu == 0;
      });
    }

    if (filter.statusTranslateKey) {
      filtered = _.filter(filtered, (report) => {
        return report.reportStatusStr == filter.statusTranslateKey;
      });
    }

    if (filter.content && filter.content.length > 0) {
      filtered = _.filter(filtered, (report) => {
        return this.reportSearchableIndex[report.report_id].indexOf(filter.content.toLowerCase()) != -1;
      });
    }

    return filtered;
  }

  applyFilterOnJob(customParam: StoreReportCustomRouteParam): JobDispatch[] {
    let filter = this.jobFilterParam;
    let filtered = this.jobDispatchs;

    if (filter.jobDispatchDateFrom) {
      filtered = _.filter(filtered, (job) => {
        return this._timestampService.checkIfTimeCorrectOrder(filter.jobDispatchDateFrom, job.dispatch_date);
      });
    }

    if (filter.jobDispatchDateTo) {
      filtered = _.filter(filtered, (job) => {
        return this._timestampService.checkIfTimeCorrectOrder(job.dispatch_date, filter.jobDispatchDateTo);
      });
    }

    if (filter.storeId) {
      filtered = _.filter(filtered, (job) => {
        return job.report.store_id == filter.storeId;
      });
    }

    if (filter.typeId) {
      filtered = _.filter(filtered, (job) => {
        return job.type_id == filter.typeId;
      });
    }
    if (!customParam) {
      filtered = _.filter(filtered, (job) => {
        return this.getTypeByTypeId(job.type_id).visible_in_app_menu == 0;
      });
    }

    if (filter.statusTranslateKey) {
      filtered = _.filter(filtered, (job) => {
        return job.reportStatusStr == filter.statusTranslateKey;
      });
    }

    if (filter.content && filter.content.length > 0) {
      filtered = _.filter(filtered, (job) => {
        return this.jobSearchableIndex[job.job_id].indexOf(filter.content.toLowerCase()) != -1 || this.reportSearchableIndex[job.report_id].indexOf(filter.content.toLowerCase()) != -1;
      });
    }

    return filtered;
  }

  // Search Indexes
  buildReportSeachableIndex(reports: StoreReport[]): void {
    _.each(reports, (report) => {
      let mappedFields = this.getMappedReportFieldsByTypeIdAndAnswers(report.type_id, report.custom_fields);
      let fieldKeywords = _.map(mappedFields, (mappedField) => {
        return this._jobFilterHelperService.getSearchKeywordOfReportByMappedField(mappedField).toLowerCase();
      });
      this.reportSearchableIndex[report.report_id] = fieldKeywords.join(" ");
    });
  }

  buildJobSeachableIndex(jobs: JobDispatch[]): void {
    _.each(jobs, (job) => {
      let jobMappedFields = this.getMappedReportFieldsByTypeIdAndAnswers(job.type_id, job.custom_fields, true);
      let fieldKeywords = _.map(jobMappedFields, (mappedField) => {
        return this._jobFilterHelperService.getSearchKeywordOfReportByMappedField(mappedField).toLowerCase();
      });
      this.jobSearchableIndex[job.job_id] = fieldKeywords.join(" ");
    });
  }

}
