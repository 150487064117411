import { Injectable } from '@angular/core';

import { Attachment } from '../../models/attachment';
import { MessageLocationBody } from '../../models/message-location-body';

import { FileManagerService } from '../file-manager/file-manager.service';

import { AttachmentComponent } from './attachment.component';
import { TnDialogService } from '../tn-dialog/tn-dialog.service';

import * as _ from 'lodash';
import { AttachmentTypeConstant } from '../../constants/attachment-type.constant';
import { WatermarkService } from '../watermark/watermark.service';

import getYear from 'date-fns/get_year';
import getMonth from 'date-fns/get_month';
import { TimestampService } from '../timestamp/timestamp.service';
import { TranslateManagerService } from '../translate/translate-manager.service';


export class TimelinedAttachment {
  year: any;
  month: any;
  attachments: Attachment[];
}

@Injectable()
export class AttachmentService {

  constructor(
    private _fileManagerService: FileManagerService,
    private _tnDialogService: TnDialogService,
    private _watermarkService: WatermarkService,
    private _timestampService: TimestampService,
    private _translateManagerService: TranslateManagerService
  ) { }

  openDialogWithContent(content: any): void {
    this._watermarkService.updateWatemarkOverlayZIndexForDialogs(true);
    const dialogRef = this._tnDialogService.openTnDialog(AttachmentComponent, content);
    dialogRef.beforeClosed().subscribe(result => {
      this._watermarkService.updateWatemarkOverlayZIndexForDialogs(false);
    });
  }

  openAttachmentModal(isAttachment: boolean, body: any, filename?: string, isChatMessage?: boolean, filenameMap?: any, isDisableDownload?: boolean): void {
    const content = this.prepareAttachmentModalContent(isAttachment, body, filename, isChatMessage, filenameMap, isDisableDownload);

    // Do not open modal if there is no content
    if (!content) {
      return;
    }

    this.openDialogWithContent(content);
  }

  prepareAttachmentModalContent(isAttachment: boolean, body: any, filename?: string, isChatMessage?: boolean, filenameMap?: any, isDisableDownload?: boolean) {
    let type;
    let attachments: Attachment[];
    let location: MessageLocationBody;
    if (isAttachment) {
      attachments = body;
      type = this._fileManagerService.getAttachmentType(attachments[0].attachment_id);
    } else {
      location = body;
    }

    if (type === AttachmentTypeConstant.AUDIO) {
      // return false/null for audio, as we are playing them directly now.
      return false;
    }


    const attachmentIds = _.map(attachments, 'attachment_id');
    return {
      isAttachment: isAttachment,
      attachments: attachments,
      attachmentIds: attachmentIds,
      type: type,
      location: location,
      filename: filename,
      isChatMessage: isChatMessage,
      filenameMap: filenameMap,
      isDisableDownload: isDisableDownload
    };
  }

  prepareNewsMediaModalContent(attachment: Attachment[]): void {
    this.openAttachmentModal(true, attachment);
  }

  prepareAttachmentModalContentByFileId(
    fileIds: string[],
    defaultFileId?: string,
    editImageCallbackWithImageUrlAndCaption?: Function,
    isDisableWatermark?: boolean,
    filename?: string,
    isDisableDownload?: boolean,
    isChatMessage?: boolean,
    filenameMap?: any,
  ): void {
    const type = this._fileManagerService.getAttachmentType(fileIds[0]);
    const content = {
      isAttachment: true,
      attachmentIds: fileIds,
      type: type,
      defaultFileId: defaultFileId,
      isDisableWatermark: isDisableWatermark,
      filename: filename,
      isDisableDownload: isDisableDownload,
      isChatMessage: isChatMessage,
      editImageCallbackWithImageUrlAndCaption: editImageCallbackWithImageUrlAndCaption,
      filenameMap: filenameMap,
    };
    this.openDialogWithContent(content);
  }
  prepareAttachmentModalContentByFileId2(
    fileIds: string[],
    defaultFileId?: string,
    editImageCallbackWithImageUrlAndCaption?: Function,
    isDisableWatermark?: boolean,
    filename?: string,
    isDisableDownload?: boolean,
    isChatMessage?: boolean
  ): void {
    const type = this._fileManagerService.getAttachmentType(filename);
    const content = {
      isAttachment: true,
      attachmentIds: fileIds,
      type: type,
      defaultFileId: defaultFileId,
      isDisableWatermark: isDisableWatermark,
      filename: filename,
      isDisableDownload: isDisableDownload,
      isChatMessage: isChatMessage,
      editImageCallbackWithImageUrlAndCaption: editImageCallbackWithImageUrlAndCaption
    };
    this.openDialogWithContent(content);
  }

  buildTimelinedAttachments(attachments): TimelinedAttachment[] {
    const grouped = _.groupBy(attachments, (attachment) => {
      const date = this._timestampService.secondToDateObj(attachment.timestamp);
      const year = getYear(date);
      const month = getMonth(date);
      return `${year},${month}`;
    });

    const timelinedAttachments: TimelinedAttachment[] = [];

    const nowYear = getYear(new Date()).toString();

    // tslint:disable-next-line:forin
    for (const index in grouped) {
      const yearMonth = index.split(',');
      let year = '';
      if (yearMonth[0] !== nowYear) {
        year = yearMonth[0];
      }
      const month = parseInt(yearMonth[1], 10) + 1;

      this._translateManagerService.getTranslationByKey(
        `DATE.MONTH.${month}`,
        (s) => {
          timelinedAttachments.push({
            year: year,
            month: s,
            attachments: grouped[index]
          });
        }
      );
    }

    return timelinedAttachments;
  }

}
