export const LoginMethodConstant = {
  AUTH_TYPE: {
    TEAMNOTE: "teamnote",
    OAUTH: "oauth",
    SAML: "saml"
  },

  OAUTH: {
    ID_TOKEN: "id_token="
  },

  SAML: {
    SAML_RESPONSE: "SAMLResponse",
    SIG_ALG: "SigAlg",
    SIGNATURE: "Signature"
  }
}