import { Component, OnInit, Input, KeyValueDiffers, KeyValueDiffer } from '@angular/core';

import { Message } from '../../../models/message';
import { NewsService } from '../news.service';

import { News } from '../../../models/news';
import { NewsModalService } from '../news-modal/news-modal.service';
import { LoggerService } from '../../../utilities/logger/logger.service';
import { MessageTypeConstant } from '../../../constants/message-type.constant';
import _ from 'lodash'

interface EmojiDisplay {
  emojiStr: string;
  filePath: string;
  count: any;
  isSent: boolean;
}

@Component({
  selector: 'tn-news-cell',
  templateUrl: './news-cell.component.html',
  styleUrls: ['./news-cell.component.scss']
})
export class NewsCellComponent implements OnInit {
  @Input() message: Message;

  // Controls
  @Input() isInModal: boolean;

  emojiMenuIcon: string = 'assets/images/emoji/263a.png';   //TODO:
  @Input() isEnableEmoji: boolean;
  @Input() isEnableComment: boolean;
  @Input() isEnableNewsContentPreview: boolean;

  messageDiffer: KeyValueDiffer<string, any>;
  news: News = null;

  //News enhancement
  @Input() newsHttp;
  @Input() isEnableNewsHttp: boolean;
  newsDiffer: KeyValueDiffer<string, any>;
  @Input() isCarousel: boolean;

  ignoreKeys = ["emojis", "comments", "read"];

  constructor(
    private _newsService: NewsService,
    private _newsModalService: NewsModalService,
    private _loggerService: LoggerService,
    private _keyValueDiffers: KeyValueDiffers
  ) { }

  ngOnInit() {
    
    if (this.isEnableNewsHttp){
      this.newsDiffer = this._keyValueDiffers.find(this.newsHttp).create();
    }else {
      this.messageDiffer = this._keyValueDiffers.find(this.message).create();
    }
    this.news = null;
    this.updateNewsCellDisplay();

  }

  ngOnChanges() {
  }

  ngDoCheck(): void {

    if (this.isEnableNewsHttp){
      const changes = this.newsDiffer.diff(this.newsHttp);
      if (changes) {
        let isNeedUpdate = false;
        changes.forEachChangedItem((record) => {
          if (this.ignoreKeys.indexOf(record.key) == -1) {
            if (JSON.stringify(record.previousValue) != JSON.stringify(record.currentValue)) {
              isNeedUpdate = true;
            }
          }
        });

        if (isNeedUpdate) {
          this.updateNewsCellDisplay();
        }
      }
    }else{
      const changes = this.messageDiffer.diff(this.message);
      if (changes) {
        let isNeedUpdate = false;
        changes.forEachChangedItem((record) => {
          if (this.ignoreKeys.indexOf(record.key) == -1) {
            if (JSON.stringify(record.previousValue) != JSON.stringify(record.currentValue)) {
              isNeedUpdate = true;
            }
          }
        });

        if (isNeedUpdate) {
          this.updateNewsCellDisplay();
        }
      }
    }
  }

  updateNewsCellDisplay(): void {
    if (!this.message) {
      this.news = this.parseNewsHttp(this.newsHttp);
    }else{
      this.news = this.parseMessage(this.message);
    }
  }
  
  parseMessage(message: Message): News {
    if (!message) {
      return;
    }
    if (message.type != MessageTypeConstant.NEWS && message.type != MessageTypeConstant.VOTE) {
      // Not News / Vote
      return;
    }
    if (this._newsService.checkIfNewsExpired(message)) {
      // Expired
      return;
    }

    return this._newsService.parseMessageForDisplay(message);
  }

  parseNewsHttp(news: any): News {
    if (!news) {
      return;
    }
    if (news.type != "News" && news.type != "Vote"){
      return;
    }
    return this._newsService.parseNewsHttpForDisplay(news);
  }

  onImageClick(): void {
    if (this.isEnableNewsHttp){
      if (this.isInModal) {
        this._newsService.openNewsHttpMedia(this.newsHttp);
      } else {
        this.onDetailClick();
      }
    } else{
      this._loggerService.log("Clicked news cell's image of News: " + this.message.message_id);
      if (this.isInModal) {
        this._newsService.openNewsMedia(this.message);
      } else {
        this.onDetailClick();
      }
    }
  }

  onDetailClick(): void {
    if (this.isInModal) {
      return;
    }
    if (!this.news.isNews) {
      // this.onVoteClick(true);
      if (this.isEnableNewsHttp){
        this._newsService.tryOpenNewsHttpVoteModal(this.newsHttp);
      } else {
        this._newsService.tryOpenVoteModal(this.message);
      }
      
      return;
    }
    if (!this.isEnableNewsHttp){
      this._loggerService.log("Clicked news cell's detail of News: " + this.message.message_id);
    }
    
    this.openNewsModal();
  }

  onEmojiClick(emojiDis: EmojiDisplay): void {
    if (!this._newsService.checkIfThreadOpenByMessageId(this.message.message_id)) {
      return;
    }
    this._loggerService.log("Clicked news cell's emoji of News: " + this.message.message_id + " Emoji: " + emojiDis.emojiStr);
    this._newsService.onEmojiClick(this.message, emojiDis.isSent, emojiDis.emojiStr);
  }

  onEmojiMenuClick(): void {
    if (!this._newsService.checkIfThreadOpenByMessageId(this.message.message_id)) {
      return;
    }
    this._loggerService.log("Clicked news cell's emoji menu of News: " + this.message.message_id);
    this._newsService.openEmojiMenu(this.message);
  }

  onVoteClick(isVoting: boolean): void {
    if (this.isEnableNewsHttp){
      this._newsService.openNewsHttpVoteModal(this.newsHttp, isVoting);
    } else {
      this._loggerService.log("Clicked news cell's vote option of News: " + this.message.message_id);
      this._newsService.openVoteModal(this.message, isVoting);
    }

  }

  onLikeClick(): void {
    if (!this._newsService.checkIfThreadOpenByMessageId(this.message.message_id)) {
      return;
    }
    this._loggerService.log("Clicked news cell's Like button of News: " + this.message.message_id);
    this._newsService.onLikeClick(this.message, this.news.isLiked);
  }

  onCommentClick(): void {
    if (this.isInModal) {
      return;
    }
    this._loggerService.log("Clicked news cell's comment button of News: " + this.message.message_id);
    this.openNewsModal();
  }

  openNewsModal(): void {
    if (this.isEnableNewsHttp){
      this._newsModalService.openNewsHttpModal(this.newsHttp, this.isEnableEmoji, this.isEnableComment);
    } else {
      this._newsModalService.openNewsModal(this.message, this.isEnableEmoji, this.isEnableComment);
    }
  }
}
