import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'tn-broadcast',
  templateUrl: './broadcast.component.html',
  styleUrls: ['./broadcast.component.scss']
})
export class BroadcastComponent implements OnInit {

  broadcastActions = {
    send: 'send',
    manage: 'manage'
  };

  currentAction: string;
  editingBroadcastUserGroupId: string = null;

  constructor(
    public dialogRef: MatDialogRef<BroadcastComponent>
  ) { }

  ngOnInit() {
    this.goToSend();
  }

  goToSend() {
    this.currentAction = this.broadcastActions.send;
  }

  goToManage() {
    this.currentAction = this.broadcastActions.manage;
  }

  close() {
    this.dialogRef.close();
  }

}
