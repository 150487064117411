import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CorporateMaterialFolder, CorporateMaterialItem } from '../models/corporate-material';
import { TeamNoteCorporateMaterialConstant } from '../constants/corporate-material.constant';
import { TeamnoteConfigService } from '../../../configs/teamnote-config.service';

@Component({
  selector: 'tn-corporate-material-list',
  templateUrl: './corporate-material-list.component.html',
  styleUrls: ['./corporate-material-list.component.scss']
})
export class CorporateMaterialListComponent implements OnInit {

  @Input() folder: CorporateMaterialFolder;
  @Output() onItemClick: EventEmitter<any> = new EventEmitter<any>(null);
  @Output() onFolderBack: EventEmitter<string> = new EventEmitter<string>(null);
  @Output() searchFolderByKeyword: EventEmitter<string> = new EventEmitter<string>(null);

  @Input() isRootFolder: boolean;
  @Input() rootTranslateKey: string;
  @Input() fileAllowTypes: string[];

  isGridView: boolean = false;
  isAllowChangeViewMode: boolean = false;

  constructor(
    private _teamnoteConfigService: TeamnoteConfigService
  ) { }

  ngOnInit() {
    this.isGridView = this._teamnoteConfigService.config.WEBCLIENT.CORPORATE_MATERIAL.IS_GRID_VIEW;
    this.isAllowChangeViewMode = this._teamnoteConfigService.config.WEBCLIENT.CORPORATE_MATERIAL.IS_ALLOW_CHANGE_VIEW_MODE;
  }

  ngOnChanges() {
    if (!this.folder) {
      return;
    }
  }

  folderClick(folder: CorporateMaterialFolder) {
    this.onItemClick.emit(folder);
  }

  folderBack() {
    this.onFolderBack.emit();
  }

  materialClick(material: CorporateMaterialItem) {
    this.onItemClick.emit(material);
  }

  search(keyword: string): void {
    this.searchFolderByKeyword.emit(keyword);
  }

  toggleViewMode(): void {
    this.isGridView = !this.isGridView;
  }

}
