import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TnDatePipe } from './tn-date/tn-date.pipe';
import { RelativeDateTimePipe } from './relative-date-time/relative-date-time.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    TnDatePipe,
    RelativeDateTimePipe
  ],
  exports: [
    TnDatePipe,
    RelativeDateTimePipe
  ]
})
export class DateModule { }
