<div class="news-image-wrapper clickable" [ngClass]="{carousel: isCarousel}" *ngIf="numOfAttachment > 0" (click)="onClick()">
  <div class="news-image" *ngIf="imgSrc">
    <img [ngClass]="{carousel: isCarousel, 'img-in-modal': isInModal}" [src]="imgSrc" alt=""/>
   <div class="attachment-count" *ngIf="numOfAttachment > 1 || isInModal">
     <span *ngIf="numOfAttachment == 1">
       {{'WEBCLIENT.NEWS.NEWS.ATTACHMENTS_COUNT.ONE' | translate: {'NUM': numOfAttachment} }}
     </span>
     <span *ngIf="numOfAttachment > 1">
       {{'WEBCLIENT.NEWS.NEWS.ATTACHMENTS_COUNT.MULTIPLE' | translate: {'NUM': numOfAttachment} }}
     </span>
   </div>
    <div class="video-play-overlay" *ngIf="isInModal && isVideo">
      <div class="video-play-icon fa fa-play-circle-o fa-5x"></div>
    </div>
  </div>
</div>