import { Injectable } from '@angular/core';
import { FileManagerService } from '../file-manager/file-manager.service';

@Injectable()
export class PasteImageHelperService {

  constructor(
    private _fileManagerService: FileManagerService
  ) { }

  getImageFromClipboardAsBlob(pasteEvent: ClipboardEvent, callback: Function) {
    if (!pasteEvent.clipboardData) {
      callback(undefined);
    }

    let items = pasteEvent.clipboardData.items;

    if(items == undefined) {
      callback(undefined);
    }

    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") == -1) {
        continue;
      }
      let blob = items[i].getAsFile();
      callback(blob);
    }
  }

  getImageFromClipboardAsDataUrl(pasteEvent: ClipboardEvent, callback: Function) {
    if (!pasteEvent.clipboardData) {
      callback(undefined);
    }

    let items = pasteEvent.clipboardData.items;

    if(items == undefined) {
      callback(undefined);
    }

    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") == -1) {
        continue;
      }
      let blob = items[i].getAsFile();
      
      this._fileManagerService.fileToDataUrl(
        blob,
        (dataUrl) => {
          callback(dataUrl);
        }
      );
    }
  }
}
