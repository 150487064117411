<div class="form-submit-container">
  <tn-page-title [title]="'WEBCLIENT.WORKFLOW.NEW_FORM.TITLE' | translate">
    <div class="left-control">
      <div class="back-button clickable" (click)="formSubmitBack()">
        <i class="fa fa-fw fa-angle-left"></i>
      </div>
    </div>

    <div class="right-control">
      <div class="form-submit-button-wrapper" 
        (click)="submit()"
        *ngIf="isFinishSelection">
        <div class="form-submit-button"></div>
      </div>
    </div>

  </tn-page-title>

  <div class="form-input-container">
    <tn-form-option 
      [customParam]="customParam"
      [(team)]="team" 
      [(type)]="type" 
      (onFinishSelect)="onFinishSelect($event)"
      *ngIf="!isFinishSelection">
    </tn-form-option>

    <tn-form-detail
      [team]="team"
      [type]="type"
      [stage]="activeStage"
      [isInput]="true"
      [staticStringMappings]="staticStringMappings"
      [(mappedFields)]="mappedInputCustomFields"
      *ngIf="isFinishSelection">
    </tn-form-detail>
  </div>
</div>