import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { WebclientLoginService } from '../webclient-login.service';
import { InputValidationService } from '../../utilities/input-validation/input-validation.service';

import { LoginComponent } from '../login.component';

import { CustomLoginResponse } from '../models/custom-login-response';
import { LOGIN_ACTIONS } from '../constants/login-actions.constant';
import { TnNotificationService } from '../../utilities/tn-notification/tn-notification.service';
import { TeamnoteConfigService } from '../../configs/teamnote-config.service';
import { LocalStorageManagerService } from '../../utilities/local-storage/local-storage-manager.service';
import { TeamNoteLocalStorageKeyConstants } from '../../constants/local-storage-key.constant';
import { TeamnoteApiService } from '../../api/teamnote-api.service';
import { UtilitiesService } from '../../utilities/service/utilities.service';

@Component({
  selector: 'tn-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
  // Login Params
  password: string;
  passwordConfirm: string;

  publicKey: string = null;

  @ViewChild('passwordInput', {static: false}) passwordInput: ElementRef;
  @ViewChild('passwordConfirmInput', {static: false}) passwordConfirmInput: ElementRef;

  errorMsg: string;

  isResetPw = false;

  isExtAuth = true;
  extAuthType: string = null;

  constructor(
    private _webclientLoginService: WebclientLoginService,
    private _inputValidationService: InputValidationService,
    private _loginComponent: LoginComponent,
    private _tnNotificationService: TnNotificationService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _teamnoteApiService: TeamnoteApiService,
    private _utilitiesService: UtilitiesService
  ) {}

  ngOnInit() {
    const externalAuthType = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.AUTH_TYPE);

    if (externalAuthType) {
      this.isExtAuth = true;
      this.extAuthType = externalAuthType;
      this.webclientLogin();
    } else {
      this.isExtAuth = false;
      switch (this._webclientLoginService.setPasswordPageState) {
        case this._webclientLoginService.SET_PASSWORD_PAGE_STATE.PENDING_REG_FINISH:
          this.isResetPw = false;
          break;
        case this._webclientLoginService.SET_PASSWORD_PAGE_STATE.PENDING_CHANGE_PW:
          this.isResetPw = true;
          break;
      }
    }
    this.getE2EEPublicKey();
  }

  ngAfterViewInit() {
    this.focusPasswordInput();
  }

  getE2EEPublicKey(): void {
    this._teamnoteApiService.getE2EEPublicKey(
      resp => {
        this.publicKey = resp;
      },
      err => {

      }
    );
  }

  focusPasswordInput(): void {
    if (this.passwordInput) {
      this.passwordInput.nativeElement.focus();
    }
  }

  showErrorWithTranslateKey(key: string) {

  }

  submit() {
    const isValid = this._inputValidationService.checkIsNotEmpty(this.password);
    if (!isValid) {
      this._tnNotificationService.showCustomWarningByTranslateKey('LOGIN.PASSWORD.EMPTY_ERROR');
      this.passwordInput.nativeElement.focus();
      return;
    }

    const isValidConfirm = this._inputValidationService.checkIsNotEmpty(this.passwordConfirm);
    if (!isValidConfirm) {
      this._tnNotificationService.showCustomWarningByTranslateKey('LOGIN.PASSWORD.EMPTY_ERROR');
      this.passwordConfirmInput.nativeElement.focus();
      return;
    }

    const isPasswordMatch = this.password === this.passwordConfirm;
    if (!isPasswordMatch) {
      this._tnNotificationService.showCustomWarningByTranslateKey('LOGIN.PASSWORD.NOT_MATCH');
      this.passwordInput.nativeElement.focus();
      return;
    }

    const isPasswordValid = this._inputValidationService.checkLoginPasswordPolicy(
      this.password
    );
    if (!isPasswordValid) {
      this._tnNotificationService.showCustomWarningByTranslateKey('LOGIN.PASSWORD.NOT_MATCH_POLICY', {NUM_CHAR: this._teamnoteConfigService.config.GENERAL.PASSWORD_POLICY_LENGTH});
      this.passwordInput.nativeElement.focus();
      return;
    }

    if (!this.isResetPw) {
      this.webclientRegisterFinish();
    } else {
      this.webclientChangePw();
    }
  }

  back() {
    this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_NAME);
    this.toNextPage(LOGIN_ACTIONS.TO_GET_STATE_PAGE);
  }

  webclientRegisterFinish() {
    let encryptedPassword = null;
    if (this.publicKey) {
      encryptedPassword = this._utilitiesService.getEncryptedValueByPublicKey(this.password, this.publicKey);
    }

    let password = null;
    // if (!this.publicKey) {
      password = this.password;
    // }

    this._webclientLoginService.registrationFinish(
      password,
      resp => {
        const response = this._webclientLoginService.handleRegistrationFinishSuccessResponse(
          resp
        );
        this.handleCustomLoginResponse(response);
      },
      err => {
        const response = this._webclientLoginService.handleRegistrationFinishErrorResponse(
          err
        );
        this.handleCustomLoginResponse(response);
      },
      encryptedPassword
    );
  }

  webclientChangePw() {
    let encryptedPassword = null;
    if (this.publicKey) {
      encryptedPassword = this._utilitiesService.getEncryptedValueByPublicKey(this.password, this.publicKey);
    }

    let password = null;
    // if (!this.publicKey) {
      password = this.password;
    // }

    this._webclientLoginService.registrationChangePassword(
      password,
      resp => {
        const response = this._webclientLoginService.handleRegistrationChangePasswordSuccessResponse(
          resp
        );
        this.handleCustomLoginResponse(response);
      },
      err => {
        const response = this._webclientLoginService.handleRegistrationChangePasswordErrorResponse(
          err
        );
        this.handleCustomLoginResponse(response);
      },
      encryptedPassword
    );
  }

  webclientRegisterDevice() {
    this._webclientLoginService.webclientRegisterDevice(
      resp => {
        const response = this._webclientLoginService.handleWebclientRegisterDeviceSuccessResponse(
          resp
        );
        this.handleCustomLoginResponse(response);
      },
      err => {
        const response = this._webclientLoginService.handleWebclientRegisterDeviceErrorResponse(
          err
        );
        this.handleCustomLoginResponse(response);
      }
    );
  }

  webclientLogin() {
    this._webclientLoginService.webclientLogin(
      resp => {
        const response = this._webclientLoginService.handleWebclientLoginSuccessResponse(
          resp
        );
        this.handleCustomLoginResponse(response);
      },
      err => {
        const response = this._webclientLoginService.handleWebclientLoginErrorResponse(
          err
        );
        this.handleCustomLoginResponse(response);
      },
      this.password,
      this.extAuthType
    );
  }

  handleCustomLoginResponse(resp: CustomLoginResponse) {
    if (resp.nextApiCall === LOGIN_ACTIONS.API_WEBCLIENT_REG_DEVICE) {
      this.webclientRegisterDevice();
      return;
    }
    if (resp.nextApiCall === LOGIN_ACTIONS.API_WEBCLIENT_LOGIN) {
      this.webclientLogin();
      return;
    }
    if (!resp.isSuccess) {
      this._tnNotificationService.showCustomErrorByTranslateKey(resp.errorMsg);
    }
    if (resp.nextPageUrl) {
      this.toNextPage(resp.nextPageUrl);
      return;
    }

    this.focusPasswordInput();
  }

  toNextPage(nextPageUrl: string) {
    this._loginComponent.goToTargetPage(nextPageUrl);
  }
}
