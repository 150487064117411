import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TnLoaderComponent } from './tn-loader.component';
import { TnLoaderService } from './tn-loader.service';
import { CommonMaterialModule } from '../common-material/common-material.module';

// Translate
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    CommonMaterialModule,
    TranslateModule
  ],
  providers: [
    
  ],
  declarations: [TnLoaderComponent],
  exports: [
    TnLoaderComponent
  ]
})
export class TnLoaderModule { }
