import { TeamNoteConfig } from "../../configs/models/config";
import { Module, ModuleKeyDefinition } from '../../constants/module.constant';
import { PageUrlConstant } from '../../constants/page-url.constant';
import { NewsCustomRouteParam } from '../../models/news';

export const TestConfig: TeamNoteConfig = {
  HOST: {
    // DOMAIN: "shan"
  },
  GENERAL: {
    // PAGE_TITLE: "This is 'shan' page title"
  },
  IMAGES: {
    // NOTIFICATION_ICON: "assets/images/clients/fwd/notification_icon.png",
    // FAVICON: "assets/images/clients/fwd/favicon.png",
    // FAVICON_ALERT: "assets/images/clients/fwd/favicon_alert.png"
  },
  WEBCLIENT: {
    GENERAL: {
      WEBCLIENT_DEFAULT_PAGE: PageUrlConstant.WEBCLIENT.CONTACT,
      WEBCLIENT_DEFAULT_PAGE_KEY: ModuleKeyDefinition.CONTACT
    },
    SIDE_NAV: {
      SIDE_NAV_ORDERING: [
        'test_shan_category_news',
        ModuleKeyDefinition.CHAT,
        ModuleKeyDefinition.CONTACT,
      ],
      SIDE_NAV_EXTRA_MODULES: [
        new Module(
          'test_shan_category_news',
          PageUrlConstant.WEBCLIENT.NEWS,
          'newspaper-o',
          'test_shan_category_news',
          new NewsCustomRouteParam(
            null,
            'zEMt9I3gEeq7lwAMKfsQNw',
            'test_shan_category_news',
            true
          ),
          ModuleKeyDefinition.NEWS
        )
      ]
    }
  }
};
