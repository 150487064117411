<div class="attachment-image-wrapper">
  <div class="modal-header image-header">
    <div class="download-button" *ngIf="isAllowAttachmentSave && !isDisableDownload" >
      <i class="fa fa-fw fa-save text-color-primary clickable" (click)="downloadImage()"></i>
    </div>
    <div class="image-count">
      {{imageNum}} / {{attachmentIds.length}}
    </div>
    <div class="edit-button" *ngIf="editImageCallbackWithImageUrlAndCaption">
      <i class="fa fa-fw fa-edit text-color-primary clickable" (click)="editImage()"></i>
    </div>
  </div>

  <div class="image-view-container">
    <div class="image-navigation" 
      [ngClass]="{'active clickable': imageNum > 1}"
      (click)="prevImage()">
      <i class="fa fa-chevron-left"
        *ngIf="imageNum > 1">
      </i>
    </div>

    <div class="image-canvas-wrapper">
      <canvas #imageWatermarkCanvas></canvas>
      <div class="image-loading-placeholder" *ngIf="!realViewingImageDataUrl">
        <i class="fa fa-spinner fa-pulse loading-icon fa-fw"></i>
        {{'LOADING.LOADING' | translate}}
      </div>
      <img [src]="realViewingImageDataUrl" (contextmenu)="onRightClick($event)" *ngIf="realViewingImageDataUrl">
      <div class="image-caption-wrapper" *ngIf="currentCaption">
        <div class="image-caption">
          {{currentCaption}}
        </div>
      </div>
    </div>
    
    <div class="image-navigation" 
      [ngClass]="{'active clickable': imageNum < attachmentIds.length}"
      (click)="nextImage()">
      <i class="fa fa-chevron-right clickable"
        *ngIf="imageNum < attachmentIds.length">
      </i>
    </div>
  </div>
</div>