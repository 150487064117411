import { NgModule } from '@angular/core'
import { UtilitiesModule } from '../../utilities/utilities.module'
import { CommonModule } from '@angular/common'
import { StarredMessagesComponent } from './starred-messages.component'
import { StarredMessagesListComponent } from './starred-messages-list/starred-messages-list.component'
import { ChatRoomModule } from '../chat/chat-room/chat-room.module'
import { StarredMessagesService } from './starred-messages.service'
import { StarredMessagesCellComponent } from './starred-messages-cell/starred-messages-cell.component'

@NgModule({
  imports: [CommonModule, UtilitiesModule, ChatRoomModule],
  declarations: [StarredMessagesComponent, StarredMessagesListComponent, StarredMessagesCellComponent],
  providers: [StarredMessagesService],
  exports: [
    StarredMessagesComponent,
    StarredMessagesListComponent,
    StarredMessagesCellComponent
  ]
})
export class StarredMessagesModule {}
