<div class="export-message-wrapper">
  <div class="modal-header text-color-primary">
    <h4>{{"WEBCLIENT.CHATROOM.EXPORT_CHAT.TITLE" | translate}}</h4>
  </div>
  <div class="modal-body">
    <div class="info-msg">
      {{"WEBCLIENT.CHATROOM.EXPORT_CHAT.CHAT_INFO" | translate }}
      <span class="text-color-primary">{{chat.displayName}}</span>
    </div>
    <div class="info-msg">
      {{"WEBCLIENT.CHATROOM.EXPORT_CHAT.RANGE_SELECT_MSG" | translate}}
    </div>
    <div class="range-picker">
      <input type="text"
        [placeholder]="'WEBCLIENT.CHATROOM.EXPORT_CHAT.EXPORT_TIME_PERIOD' | translate"
        class="form-control"
        placement="top"
        bsDaterangepicker
        [bsConfig]="dateInputConfig"
        [(ngModel)]="timePeriod">
    </div>
    <div class="info-msg">
      <div>
        <label>
          {{"WEBCLIENT.CHATROOM.EXPORT_CHAT.EXPORT_WITH_WATERMARK.TITLE" | translate}}
          <input type="checkbox" [(ngModel)]="exportWithWatermark" [disabled]="!isAllowExportRaw">
        </label>
      </div>
      <div class="tip">
        {{"WEBCLIENT.CHATROOM.EXPORT_CHAT.EXPORT_WITH_WATERMARK.TIP" | translate}}
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button class="btn btn-default" (click)="cancel()">
      {{"GENERAL.CANCEL" | translate}}
    </button>
    <button class="btn tn-button-primary" (click)="export()">
      {{"GENERAL.EXPORT" | translate}}
    </button>
  </div>
</div>