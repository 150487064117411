<div class="report-option-container">
  <div class="option">
    <ng-select
      [clearable]="false"
      [items]="storeOptions"
      [(ngModel)]="selectedStore"
      bindLabel="name"
      [placeholder]="'WEBCLIENT.JOB_REPORT.NEW_REPORT.STORE' | translate">
    </ng-select>
  </div>

  <div class="option" *ngIf="isShowTypeOption">
    <ng-select
      [clearable]="false"
      [items]="typeOptions"
      [(ngModel)]="selectedType"
      bindLabel="name"
      [placeholder]="'WEBCLIENT.JOB_REPORT.NEW_REPORT.TYPE' | translate">
    </ng-select>
  </div>

  <div class="report-option-action">
    <button class="btn tn-button-primary" 
      [disabled]="!selectedStore || !selectedType" 
      (click)="reportOptionDone()">
      {{'GENERAL.CONFIRM' | translate}}
    </button>
  </div>

</div>
