import { ModuleKeyDefinition, Module } from "../../constants/module.constant";
import { CorporateMaterialCustomRouteParam } from "../../webclient/corporate-material/models/corporate-material";
import { PageUrlConstant } from "../../constants/page-url.constant";
import { TeamNoteConfig } from "../../configs/models/config";

let MODULE_FWD_AUDIO_EBOOK = "FWD_AUDIO_EBOOK";
let MODULE_FWD_CORPORATE_MATERIAL = "FWD_CORPORATE_MATERIAL";

let FOLDER_AUDIO_EBOOK = "audio ebook";
let FOLDER_CORPORATE_MATERIAL = "corporate material";

export const FwdConfig: TeamNoteConfig = {
  HOST: {
    DOMAIN: "fwd"
  },
  GENERAL: {
    PAGE_TITLE: "FWD Training",
    ATTACHMENT_SIZE_LIMIT: 100
  },
  IMAGES: {
    NOTIFICATION_ICON: "assets/images/clients/fwd/notification_icon.png",
    FAVICON: "assets/images/clients/fwd/favicon.png",
    FAVICON_ALERT: "assets/images/clients/fwd/favicon_alert.png"
  },
  WEBCLIENT: {
    SIDE_NAV: {
      SIDE_NAV_ORDERING: [
        // FWD HOME,
        ModuleKeyDefinition.NEWS,
        ModuleKeyDefinition.CHAT,
        MODULE_FWD_AUDIO_EBOOK,
        // Training course,
        MODULE_FWD_CORPORATE_MATERIAL,
        // online application,
        // portal,
        // about fwd,
        // e-learning,
        ModuleKeyDefinition.CONTACT,
        // profile
        // more
        ModuleKeyDefinition.QUESTIONNAIRE,
        ModuleKeyDefinition.JOB_DISPATCH,
        ModuleKeyDefinition.STORE_REPORT,
        // DUTY ROSTER,
        ModuleKeyDefinition.STORE_SCHEDULE,
        ModuleKeyDefinition.TRAINING,
        // ModuleKeyDefinition.CORPORATE_MATERIAL
      ],
      SIDE_NAV_EXTRA_MODULES: [
        new Module(
          MODULE_FWD_AUDIO_EBOOK, 
          PageUrlConstant.WEBCLIENT.CORPORATE_MATERIAL, 
          "book", 
          MODULE_FWD_AUDIO_EBOOK, 
          new CorporateMaterialCustomRouteParam(
            FOLDER_AUDIO_EBOOK, 
            MODULE_FWD_AUDIO_EBOOK
          ), 
          ModuleKeyDefinition.CORPORATE_MATERIAL
        ),
        new Module(
          MODULE_FWD_CORPORATE_MATERIAL, 
          PageUrlConstant.WEBCLIENT.CORPORATE_MATERIAL, 
          "book", 
          MODULE_FWD_CORPORATE_MATERIAL, 
          new CorporateMaterialCustomRouteParam(
            FOLDER_CORPORATE_MATERIAL, 
            MODULE_FWD_CORPORATE_MATERIAL
          ), 
          ModuleKeyDefinition.CORPORATE_MATERIAL
        )
      ]
    }
  }
};
