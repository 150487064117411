<div class="form-detail-container" [ngClass]="{'form-detail-view': !isInput}">
  <div class="form-info theme-color-primary">
    <div class="info team">
      {{team.name}}
    </div>
    <div class="info type">
      {{type.name}}
    </div>
    <div class="info form-no" *ngIf="formNo">
      {{formNo}}
    </div>
    <br>
    <div class="info stage" *ngIf="stage">
      {{stage.name}}
    </div>
    <div class="info pic" *ngIf="pic">
      {{pic.name}}
    </div>
  </div>

  <div class="form-body">
    <tn-custom-field
      [(field)]="mappedFields[i]"
      [isInput]="isInput"
      [availableUserIds]="availableUserIds"
      [allCustomFields]="allCustomFields"
      [staticStringMappings]="staticStringMappings"
      (onFieldChange)="onFieldChange($event)"
      *ngFor="let field of mappedFields; let i = index">
    </tn-custom-field>
  </div>
</div>