import { Module } from "../../constants/module.constant";
import { PageUrlConstant } from "../../constants/page-url.constant";
import { WebViewCustomRouteParam } from "../../webclient/webview/models/webview";
import { TeamNoteConfig } from "../../configs/models/config";
import { TeamNotePasswordPolicyConstant } from '../../constants/password-policy.constant';

let MODULE_AVSECO_INTRANET = "AVSECO_INTRANET";
let AVSECO_INTRANET_URL = "https://172.16.7.77";

let MODULE_AVSECO_ESS = "AVSECO_ESS";
let AVSECO_ESS_URL = "https://172.16.7.112:8001/sap/bc/ui5_ui5/sap/arsrvc_suite_pb/main.html?page=HR_SELFSERVICES&sap-client=703&sap-language=EN";

export const AvsecoConfig: TeamNoteConfig = {
  HOST: {
    DOMAIN: "avseco"
  },
  GENERAL: {
    IS_RESTRICT_CLIPBOARD: true,
    PAGE_TITLE: "EMP",
    SYSTEM_LABEL: "Enterprise Messaging Platform (EMP)",
    SITE_LABEL: "Web Client",
    BUILD_LABEL: "Test Environment",
    NAV_UAT_LABEL: "EMP TEST",
  },
  IMAGES: {
    NOTIFICATION_ICON: "assets/images/clients/avseco/notification_icon.png",
    FAVICON: "assets/images/clients/avseco/favicon.png",
    FAVICON_ALERT: "assets/images/clients/avseco/favicon_alert.png"
  },
  WEBCLIENT: {
    SIDE_NAV: {
      SIDE_NAV_EXTRA_MODULES: [
        // new Module(
        //   MODULE_AVSECO_INTRANET,
        //   PageUrlConstant.WEBCLIENT.WEBVIEW,
        //   "desktop",
        //   MODULE_AVSECO_INTRANET,
        //   new WebViewCustomRouteParam(
        //     AVSECO_INTRANET_URL,
        //     "",
        //     MODULE_AVSECO_INTRANET
        //   )
        // ),
        // new Module(
        //   MODULE_AVSECO_ESS,
        //   PageUrlConstant.WEBCLIENT.WEBVIEW,
        //   "user-plus",
        //   MODULE_AVSECO_ESS,
        //   new WebViewCustomRouteParam(
        //     AVSECO_ESS_URL,
        //     "",
        //     MODULE_AVSECO_ESS
        //   )
        // )
      ]
    },
    CHATROOM: {
      AUDIO_MESSAGE_PLAY_TO_READ: true,
      IS_ENABLE_ATTACH_CORPORATE_MATERIAL: false
    }
  }
}

export const AvsecoProdConfig: TeamNoteConfig = {
  HOST: {
    DOMAIN: "avseco"
  },
  GENERAL: {
    IS_RESTRICT_CLIPBOARD: true,
    PAGE_TITLE: "EMP",
    SYSTEM_LABEL: "Enterprise Messaging Platform (EMP)",
    SITE_LABEL: "Web Client",
    NAV_LABEL: "EMP",
    BUILD_LABEL: "",
    NAV_UAT_LABEL: "",
    ATTACHMENT_SIZE_LIMIT: 2,
    PASSWORD_POLICY_REGEX: TeamNotePasswordPolicyConstant.AVSECO
  },
  IMAGES: {
    NOTIFICATION_ICON: "assets/images/clients/avseco/notification_icon.png",
    FAVICON: "assets/images/clients/avseco/favicon.png",
    FAVICON_ALERT: "assets/images/clients/avseco/favicon_alert.png"
  },
  WEBCLIENT: {
    SIDE_NAV: {
      SIDE_NAV_EXTRA_MODULES: [
        
      ]
    },
    CHATROOM: {
      AUDIO_MESSAGE_PLAY_TO_READ: true,
      IS_ENABLE_ATTACH_CORPORATE_MATERIAL: false
    }
  }
}