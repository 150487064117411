import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BaseRoutingService } from "./base-routing.service";
import { LoginComponent } from "../login/login.component";
import { WebclientComponent } from "../webclient/webclient.component";

export const ROUTES: Routes = [
  // { path: "login", loadChildren: 'app/login/login.module#LoginModule' },
  // { path: "", redirectTo: "login", pathMatch: "full" },
  // { path: "login", component: LoginComponent },
  // { path: "webclient", loadChildren: 'app/webclient/webclient.module#WebclientModule' }
  // { path: "webclient", component: WebclientComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      enableTracing: false,
      // initialNavigation: false,
      initialNavigation: 'disabled'
    })
  ],
  exports: [RouterModule],
  providers: [BaseRoutingService]
})
export class AppRoutingModule {}
