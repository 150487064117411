<div class="password-relogin base-bg">
  <div class="cancel-button clickable" (click)="cancel()">
    <i class="fa fa-fw fa-times"></i>
  </div>
  <div class="relogin-view-wrapper">
    <div class="message-wrapper">
      {{titleTranslateKey | translate}}
    </div>
    <div class="input-wrapper">
      <form *ngIf="!currentLoginMethod">
        <!-- 
          === Workaround for Chrome "SMART" autofilling password behaviour ===

          Chrome will check for every first visible type="password" <input> element inside <body>, <form> and <iframe> and apply its autofill logic
          To skip this autofill, put a dummy password input imeediate after <body> or <form>. 
          Do not hide using css property display:none.
        -->
        <input type="password" style="width: 0;height: 0; visibility: hidden;position:absolute;left:0;top:0;"/>

        <input type="password" 
          name="password-relogin"
          autocomplete="false"
          class="form-control" 
          [(ngModel)]="passwordInput"
          [placeholder]="'LOGIN.PASSWORD.PASSWORD' | translate"
          (keydown.enter)="inputKeyEnter(false)" 
          (keydown.shift.enter)="inputKeyEnter(true)">
      </form>

      <button class="btn btn-info" 
        *ngIf="currentLoginMethod" 
        (click)="onLoginMethodClick(currentLoginMethod)">
        Login with {{currentLoginMethod.auth_name}}
      </button>
    </div>
    <div class="action-wrapper" *ngIf="!currentLoginMethod">
      <button class="btn tn-button-primary" (click)="relogin()" [disabled]="!passwordInput">
        {{'SUBMIT' | translate}}
      </button>
    </div>
  </div>
</div>