<div class="questionnaire-view-container" *ngIf="questionnaire">
  <tn-page-title [title]="questionnaire.title">
    <div class="left-control" *ngIf="!isDialog">
      <div class="back-button clickable" (click)="questionnaireBack()">
        <i class="fa fa-fw fa-angle-left"></i>
      </div>
    </div>
  </tn-page-title>

  <div class="questionnaire-view">
    <div class="question" *ngFor="let q of questions; let i = index">
      <div class="title-wrapper">
        <div class="mandatory-label" *ngIf="q.hasError">
          {{'WEBCLIENT.QUESTIONNAIRE.ITEM_UNFINISHED_INDICATOR' | translate}}
        </div>
        <div class="index">{{q.question_index}}.&nbsp;</div>
        <div class="title multiline-text-display">{{q.title}}</div>
      </div>

      <ng-container [ngSwitch]="q.type">
        <tn-question-free-text 
          [(question)]="questions[i]" 
          *ngSwitchCase="CONSTANT.QUESTION_TYPE.FREE_TEXT">
        </tn-question-free-text>

        <tn-question-multiple-choice 
          [(question)]="questions[i]" 
          *ngSwitchCase="CONSTANT.QUESTION_TYPE.MC">
        </tn-question-multiple-choice>

        <tn-question-checkbox 
          [(question)]="questions[i]" 
          *ngSwitchCase="CONSTANT.QUESTION_TYPE.CHECKBOX">
        </tn-question-checkbox>

        <tn-question-rating 
          [(question)]="questions[i]" 
          *ngSwitchCase="CONSTANT.QUESTION_TYPE.RATING">
        </tn-question-rating>

        <tn-question-attachment
          [(question)]="questions[i]"
          *ngSwitchCase="CONSTANT.QUESTION_TYPE.ATTACHMENT">
        </tn-question-attachment>
      </ng-container>

    </div> <!-- question -->

    <div class="questionnaire-footer">
      <div class="end">
        {{'WEBCLIENT.QUESTIONNAIRE.END' | translate}}
      </div>
      <div class="submit-button">
        <button class="btn tn-button-primary" (click)="submit()">
          {{'WEBCLIENT.QUESTIONNAIRE.SUBMIT' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>