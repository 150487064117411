export class ClientReportExportApiParam {
  create_date: string[];
  dispatch_date: string[];
  store_id: string;
  type_id: string;

  constructor(isJob: boolean, startDateSec: string, endDateSec: string, storeId: string, typeId: string) {
    if (isJob) {
      this.dispatch_date = [startDateSec, endDateSec];
      this.create_date = [];
    } else {
      this.create_date = [startDateSec, endDateSec];
      this.dispatch_date = [];
    }
    this.store_id = storeId;
    this.type_id = typeId;
  }
}