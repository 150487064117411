import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Question, RatingOption } from '../../models/questionnaire';

@Component({
  selector: 'tn-question-rating',
  templateUrl: './question-rating.component.html',
  styleUrls: ['./question-rating.component.scss']
})
export class QuestionRatingComponent implements OnInit {

  @Input() question: Question;
  @Output() questionChange: EventEmitter<Question> = new EventEmitter<Question>(null);

  constructor() { }

  ngOnInit() {
  }

  onChange() {
    this.questionChange.emit(this.question);
  }

  // Rating
  updateSection(option: RatingOption, index: number) {
    option.rate = index;
    this.onChange();
  }
  updateSectionHighlight(option: RatingOption, index: number) {
    option.hover = index;
  }

}
