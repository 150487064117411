import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { UtilitiesModule } from '../../../utilities/utilities.module';

import { ChatRoomComponent } from './chat-room.component';
import { MessagesComponent } from './messages/messages.component';

import { ChatRoomService } from './chat-room.service';
import { TextMessageComponent } from './messages/text-message/text-message.component';
import { AttachmentMessageComponent } from './messages/attachment-message/attachment-message.component';
import { LocationMessageComponent } from './messages/location-message/location-message.component';
import { InfoMessageComponent } from './messages/info-message/info-message.component';
import { MessageInfoComponent } from './message-info/message-info.component';
import { ContactModule } from '../../contact/contact.module';
import { MessageParentComponent } from './messages/message-parent/message-parent.component';
import { ExportMessageComponent } from './export-message/export-message.component';
import { StickerMessageComponent } from './messages/sticker-message/sticker-message.component';
import { MessageEmojiInfoComponent } from './message-emoji-info/message-emoji-info.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UtilitiesModule,
        ContactModule
    ],
    declarations: [
        ChatRoomComponent,
        MessagesComponent,
        TextMessageComponent,
        AttachmentMessageComponent,
        LocationMessageComponent,
        InfoMessageComponent,
        MessageInfoComponent,
        MessageEmojiInfoComponent,
        MessageParentComponent,
        ExportMessageComponent,
        StickerMessageComponent,
    ],
    exports: [
        ChatRoomComponent,
        TextMessageComponent,
        AttachmentMessageComponent,
        LocationMessageComponent,
        StickerMessageComponent,
        MessagesComponent,
        MessageParentComponent
    ],
    providers: [
        ChatRoomService
    ]
})
export class ChatRoomModule { }
