<div class="field-attachment-document-wrapper">
  <ng-container *ngIf="!isInput">
    <div 
      class="attachment-document text-color-primary clickable"
      *ngFor="let ans of answers"
      (click)="openAttachment(ans)">
      {{ans.name}}
    </div>
  </ng-container>

  <ng-container *ngIf="isInput">
    <div class="btn tn-button-primary" (click)="openFileUploader()">
      {{'GENERAL.SELECT_FILE' | translate}}
    </div>
    <div
      class="attachment-document text-color-primary"
      *ngFor="let file of fileInputs">
      {{file.name}}
      <span class="remove-document-button text-color-secondary clickable" 
        (click)="removeAttachment(file)">
        <i class="fa fa-fw fa-times"></i>
      </span>
    </div>
  </ng-container>
</div>