import {Component, Inject, Injectable, OnInit} from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'tn-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})

@Injectable()
export class ImageModalComponent {
  imgUrl: any;

  constructor(
    public dialogRef: MatDialogRef<ImageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.imgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.imgSrc);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}