import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Message } from '../../../models/message';
import { Chat } from '../../../models/chat';
import { ChatService } from '../../services/data/chat/chat.service';
import { UserContact } from '../../../models/user-contact';
import { ChatMessageService } from '../../services/data/messages/chat-message/chat-message.service';
import { MessageTypeConstant } from '../../../constants/message-type.constant';
import { PresenceTypeConstant } from '../../../constants/presence-type.constant';
import { LinkifyService } from '../../../utilities/linkify/linkify.service';
import { AccountManagerService } from '../../../webclient/services/account/account-manager.service';
import { UserContactService } from '../../../webclient/services/data/user-contact/user-contact.service';
import { UserConstant } from '../../../constants/user.constant';
import * as _ from 'lodash';

@Component({
  selector: 'tn-chat-message-cell',
  templateUrl: './chat-message-cell.component.html',
  styleUrls: ['./chat-message-cell.component.scss']
})
export class ChatMessageCellComponent implements OnInit {

  @Input() isEnableImportantUsers: boolean;
  @Input() isInChat: Message;
  @Input() keyword: string;
  @Input() message: Message;
  @Output() messageOnClick: EventEmitter<Message> = new EventEmitter<Message>();

  chat: Chat = null;
  chatName: string = "";
  messageContent: string = "";
  msgFragment: string[] = [];
  messageTime: string = "";
  messageBody: any = "";

  senderContact: UserContact = null

  MSG_TYPE = MessageTypeConstant;
  PRESENCE_TYPE = PresenceTypeConstant;
  IMPORTANT_LEVEL = UserConstant.IMPORTANT_LEVEL;

  constructor(
    private _chatService: ChatService,
    private _chatMessageService: ChatMessageService,
    private _linkifyService: LinkifyService,
    private _userContactService: UserContactService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (!this.message) {
      return;
    }

    if (!this.keyword || this.keyword.length === 0) {
      return
    }

    if (this.isInChat) {
      this.senderContact = this._chatMessageService.getUserContact(this.message.sent_by)
      this.senderContact.importantLevel = this._userContactService.checkIfUserIsImportant(this.senderContact.user_id)
    } else {
      this.chat = this._chatService.getChatRoomByChatId(this.message.chat_ids[0]);
      if (!this.chat.isGroup) {
        this.chat.importantLevel = this._chatService.checkImportantLevelForChat(this.chat)
      }
    }

    // console.log(this.message)

    this.messageBody = JSON.parse(this.message.body);
    this.messageContent = this.messageBody.message;
    this.msgFragment = this.getMsgFragments(this.messageContent, this.keyword)
    this.messageTime = this.message.timestamp;
  }

  getMsgFragments(messageContent: string, keyword: string): string[] {
    if (messageContent) {
      return this._linkifyService.getMessageFragments(messageContent, keyword);
    } 

    switch (_.toInteger(this.message.type)) {
      case this.MSG_TYPE.LOCATION:
        return this._linkifyService.getMessageFragments(this.messageBody.address, keyword);

      case this.MSG_TYPE.ATTACHMENT:
        return this._linkifyService.getMessageFragments(this.messageBody.filename, keyword);
    }
  }

  messageClick(event: any): void {
    this.messageOnClick.emit(this.message);
  }

}
