import { Component, OnInit, Input } from '@angular/core';
import { Message } from '../../../../models/message';

@Component({
  selector: 'tn-news-notification',
  templateUrl: './news-notification.component.html',
  styleUrls: ['./news-notification.component.scss']
})
export class NewsNotificationComponent implements OnInit {

  @Input() body: Message;

  constructor() { }

  ngOnInit() {
  }

}
