<div class="notification-cell-container">
  <tn-chat-notification
    [body]="notification.body"
    *ngIf="notification.type == notificationTypes.CHAT">
  </tn-chat-notification>

  <tn-news-notification
    [body]="notification.body"
    *ngIf="notification.type == notificationTypes.NEWS">
  </tn-news-notification>

  <tn-cronjob-notification
    [body]="notification.body"
    *ngIf="notification.type == notificationTypes.CRONJOB">
  </tn-cronjob-notification>

</div>