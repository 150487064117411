import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFieldComponent } from './custom-field.component';
import { FieldMultipleChoiceComponent } from './field-multiple-choice/field-multiple-choice.component';
import { FieldFreeTextComponent } from './field-free-text/field-free-text.component';
import { FieldCheckboxComponent } from './field-checkbox/field-checkbox.component';
import { FieldDropdownComponent } from './field-dropdown/field-dropdown.component';
import { FieldContactComponent } from './field-contact/field-contact.component';
import { FieldAttachmentPhotoComponent } from './field-attachment-photo/field-attachment-photo.component';
import { FieldDateComponent } from './field-date/field-date.component';
import { FieldTimeComponent } from './field-time/field-time.component';
import { FieldGeolocationInputComponent } from './field-geolocation-input/field-geolocation-input.component';
import { FieldGeolocationDetectionComponent } from './field-geolocation-detection/field-geolocation-detection.component';
import { FieldTitleComponent } from './field-title/field-title.component';
import { UtilitiesModule } from '../../../utilities/utilities.module';
import { FieldTextDisplayComponent } from './field-text-display/field-text-display.component';
import { FieldAttachmentDocumentComponent } from './field-attachment-document/field-attachment-document.component';
import { FieldHelperService } from './helper/field-helper.service';
import { FilterHelperService } from './helper/filter-helper.service';
import { FieldAttachmentComponent } from './field-attachment/field-attachment.component';
import { FieldSignatureComponent } from './field-signature/field-signature.component';

@NgModule({
  imports: [
    CommonModule,
    UtilitiesModule
  ],
  exports: [CustomFieldComponent],
  declarations: [CustomFieldComponent, FieldMultipleChoiceComponent, FieldFreeTextComponent, FieldCheckboxComponent, FieldDropdownComponent, FieldContactComponent, FieldAttachmentPhotoComponent, FieldDateComponent, FieldTimeComponent, FieldGeolocationInputComponent, FieldGeolocationDetectionComponent, FieldTitleComponent, FieldTextDisplayComponent, FieldAttachmentDocumentComponent, FieldAttachmentComponent, FieldSignatureComponent],
  providers: [
    FieldHelperService,
    FilterHelperService
  ]
})
export class CustomFieldModule { }
