import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tn-attachment-timelined',
  templateUrl: './attachment-timelined.component.html',
  styleUrls: ['./attachment-timelined.component.scss']
})
export class AttachmentTimelinedComponent implements OnInit {

  @Input() year: any = '';
  @Input() month: any = '';

  constructor() { }

  ngOnInit() {
  }

}
