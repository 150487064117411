export const ZhChtChatroomStrings = {
  SETTINGS: "設定",
  PHOTO: "相片",
  VIDEO: "影片",
  DOCUMENT: "文件",
  MESSAGE_STAR: '星號信息',
  
  EXPORT_CHAT: {
    TITLE: "導出聊天訊息",
    CHAT: "聊天室: ",
    TIME: "時間: ",
    CHAT_INFO: "聊天室: ",
    EXPORT_TIME_PERIOD: "時間範圍",
    RANGE_SELECT_MSG: "請選擇時間範圍，在此範圍內的訊息將被導出。",
    NO_TIME_PERIOD_ERROR_MSG: "請選擇時間範圍",
    CONFIRM_MSG: "確定導出由 {{FROM}} 至 {{TO}} 的訊息？",
    IN_PROGRESS_MSG: "聊天訊息導出中，你可以在右上角的通知中心查看進度，你將在檔案準備完成後收到通知。",
    EXPORT_DONE_DOWNLOAD: "完成，按此下載。",
    EXPORT_READY_MSG: "聊天訊息檔案已準備完成，你可以在通知中心下載檔案。",
    EXPORT_WITH_WATERMARK: {
      TITLE: "在附件上添加水印? ",
      TIP: "(如選擇添加水印，只有相片會被導出。)"
    }
  },

  SEND: "傳送",
  TEXTAREA_PLACEHOLDER: {
    IE: "輸入訊息 / 拖曳文件",
    OTHERS: "輸入訊息 / 拖曳文件 / 貼上圖片",
    TEXT_ONLY: "輸入訊息",
    DROP_FILE_TIPS: "拖曳文件"
  },
  MESSAGE_OPTION: {
    UPLOAD: "上載",
    LOCATION: "地點",
    ATTACH: "附件",
    IMPORTANT: "重要",
    SMS: "短訊",
    SELECT_FROM_DOCUMENT_SHARING: "從檔案分享中選擇"
  },

  WHISPER: "私訊",
  NAME_WHISPER_LABEL: "{{USER}} (私訊)",

  READ: "已閱",
  READ_GROUP: "已閱 {{NUM}}",

  NEW_MESSAGE: {
    ONE: "新訊息 ({{NUM}})",
    MULTIPLE: "新訊息 ({{NUM}})"
  },
  UNREAD_MESSAGE_HEADER: "新訊息",

  ONLINE: "在線",
  LAST_SEEN: "最後在線時間: ",
  NOT_EXIST: "聊天室不存在",
  
  JOIN_CHAT: "{{ACTOR}} 把 {{USERS}} 加入群組",
  LEAVE_GROUP: "{{USERS}} 已離開群組",
  ADMIN_CHANGE: {
    ONE: "{{USER}} 已成為群組管理員",
    MULTIPLE: "{{USERS}} 已成為群組管理員"
  },
  ADMIN_REMOVED: {
    ONE: "{{USER}} 已不再是群組管理員",
    MULTIPLE: "{{USERS}} 已不再是群組管理員"
  },
  MESSAGE_RECALLED: {
    ME: "你已收回一則訊息",
    OTHERS: "{{USER}} 已收回一則訊息"
  },

  CLASSIFICATION_LEVEL: {
    AUTH_TITLE: "您正在進入一個機密聊天室，訊息將在離開此聊天室時刪除。請再次進行驗證以接受並進入聊天室。",
    DESC: "訊息分級: ",
    LEVEL0: "一般",
    LEVEL1: "內部", // origin: 限閱
    LEVEL2: "限閱" // origin: 機密
  },

  ENCRYPTED_MESSAGE: {
    AUTH_TITLE: "查看安全訊息前請先進行驗證",
    MESSAGE_PREVIEW: "安全訊息，點擊查看",
    STARRED_MESSAGE_PREVIEW: "安全訊息，請在聊天室內點擊查看"
  },

  OUT_OF_OFFICE: {
    UNTIL: "不在辦公室直到 ",
    COUNT: {
      ONE: "{{NUM}} 人不在辦公室",
      MULTIPLE: "{{NUM}} 人不在辦公室"
    }
  },

  REPLY: {
    YOU: "您",
    IMAGE: "相片",
    VIDEO: "影片",
    AUDIO: "語音訊息",
    DOCUMENT: "文件",
    LOCATION: "位置",
    STICKER: "貼圖"
  },

  GROUP_INFO_ITEM: {
    ADMIN: "群組管理員",
    OUT_OF_OFFICE: "不在辦公室"
  },

  MESSAGE_OPTIONS: {
    INFO: "訊息詳情",
    ACKNOWLEDGEMENT_INFO: '訊息回報詳情',
    FORWARD: "轉發",
    SELECT: "選擇訊息",
    DOWNLOAD: "下載",
    RECALL: "收回訊息",
    REPLY: "回覆",
    REPORT: "回報訊息",
    STAR: '標上星號',
    UNSTAR: '移除星號',
    COPY: '複製',
    EMOJI: '發送表情',
    DELETE: "刪除"
  },

  EMOJI_INFO: {
    TITLE: "表情詳情",
    PREVIEW_TITLE: "所有 {{emoji_count}} 個表情"
  },

  MESSAGE_INFO: {
    TITLE: "訊息詳情",
    READ_HEADER: "已讀取",
    DELIVERY_HEADER: "已傳送",
    UNREAD_HEADER: "未讀取",
    ACKNOWLEDGED_HEADER: '已確認',
    SMS_HEADER: "短訊狀態"
  },

  FORWARD: {
    CONFIRM_MSG: "確定轉發訊息到 {{chat}}?",
    SUCCESS_MSG: "訊息已轉發"
  },

  DELETE: {
    CONFIRM_MSG: "是否刪除此訊息?"
  },

  ACK: {
    ACKED: "已確認",
    ACK: "確認"
  },

  FALLBACK_STATUS: {
    PENDING: '等候傳送',
    SENT: '已傳送',
    ERROR: '發生錯誤'
  }


}
