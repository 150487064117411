<tn-page-title [title]="title ? title : 'WEBCLIENT.MENU.CONTACTS' | translate">
  <div class="left-control">
    <div class="back-button clickable" *ngIf="isInsideUserGroup" (click)="userGroupBack()">
      <i class="fa fa-fw fa-angle-left"></i>
    </div>
  </div>
</tn-page-title>

<tn-contact-list 
  class="tn-contact-list-wrapper"
  [contacts]="contacts"
  [userGroups]="userGroups"
  [excludingUsers]="excludingUsers"
  [isShowSearchBar]="isShowSearchBar"
  (contactCellOnClick)="contactCellOnClick($event)"
  (userGroupCellOnClick)="userGroupCellOnClick($event)">
  
  <div class="select-all-overlay" *ngIf="isAllowSelectAll">
    <div class="contact-select-all-button clickable" (click)="selectAllOnClick()">
    </div>
  </div>
</tn-contact-list>