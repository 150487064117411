import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FileManagerService } from '../../file-manager/file-manager.service';
import { AttachmentService, TimelinedAttachment } from '../attachment.service';
import { Attachment } from '../../../models/attachment';

import * as _ from 'lodash';

@Component({
  selector: 'tn-attachment-video-grid',
  templateUrl: './attachment-video-grid.component.html',
  styleUrls: ['./attachment-video-grid.component.scss']
})
export class AttachmentVideoGridComponent implements OnInit {

  attachments: Attachment[] = []

  timelinedAttachments: TimelinedAttachment[] = [];

  constructor(
    public dialogRef: MatDialogRef<AttachmentVideoGridComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fileManagerService: FileManagerService,
    private _attachmentService: AttachmentService
  ) { }

  ngOnInit() {
    this.attachments = _.orderBy(this.data.attachments, 'timestamp', 'desc');
    this.parseVideoPreviews();

    this.timelinedAttachments = this._attachmentService.buildTimelinedAttachments(this.attachments);
  }

  parseVideoPreviews(): void {
    _.each(this.attachments, (a) => {
      a.imgSrc = this._fileManagerService.getBackgroundImgSrcByBase64(a.preview_image, a.content_type);
      a.durationDisplay = this._fileManagerService.getAudioDuration(a.duration);
    });
  }

  onClick(video: Attachment): void {
    this._attachmentService.prepareAttachmentModalContentByFileId([video.attachment_id]);
  }
}
