import { Injectable } from '@angular/core';
import { TeamnoteConfigService } from '../../configs/teamnote-config.service';
import { TnDateFormat, TnDateFormatConfiguration, TIME_TWELVE_HOUR_FORMATS, TIME_TWENTY_FOUR_HOUR_FORMAT } from './date';

import * as _ from 'lodash';
var format = require('date-fns/format');

@Injectable()
export class DateService {

  tnDateFormats: {[key: string]: TnDateFormat} = {};

  constructor(
    private _teamnoteConfigService: TeamnoteConfigService
  ) { }

  initTnDateFormats(): void {
    _.each(TnDateFormatConfiguration, (format, key) => {
      if (this._teamnoteConfigService.config.WEBCLIENT.DATES.IS_24_HOURS_FORMAT) {
        format = this.convertToTwentyFourHourFormat(format);
      }

      this.tnDateFormats[key] = new TnDateFormat(key, format);
    });
  }

  convertToTwentyFourHourFormat(twelveHourFormat: string): string {
    let format = twelveHourFormat;
    _.each(TIME_TWELVE_HOUR_FORMATS, (targetFormat) => {
      format = format.replace(targetFormat, TIME_TWENTY_FOUR_HOUR_FORMAT);
    });
    return format;
  }

  getDateDisplayWithKey(date: any, key: string): string {
    if (typeof date == "object") {
      return format(date, this.tnDateFormats[key].format);
    }
    return format(parseFloat(date) * 1000, this.tnDateFormats[key].format);
  }
}
