import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamflareAppComponent } from './teamflare-app.component';
import { UtilitiesModule } from '../../utilities/utilities.module';

@NgModule({
  declarations: [TeamflareAppComponent],
  imports: [
    CommonModule,
    UtilitiesModule
  ],
  exports: [
    
  ]
})
export class TeamflareAppModule { }
