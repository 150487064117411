import { Component, OnInit, Input } from '@angular/core';
import { JobDispatch, StoreReport } from '../models/job-report';
import { JobReportService } from '../job-report.service';
import { TimestampService } from '../../../utilities/timestamp/timestamp.service';
import { UserContactService } from '../../services/data/user-contact/user-contact.service';
import { DateService } from '../../../utilities/date/date.service';

@Component({
  selector: 'tn-job-dispatch-info',
  templateUrl: './job-dispatch-info.component.html',
  styleUrls: ['./job-dispatch-info.component.scss']
})
export class JobDispatchInfoComponent implements OnInit {

  @Input() job: JobDispatch;
  @Input() report: StoreReport;
  @Input() isViewingStoreReport: boolean;

  defaultDisplay = "--";

  workerName: string = this.defaultDisplay;
  startTimeDisplay: string = this.defaultDisplay;
  endTimeDisplay: string = this.defaultDisplay;

  constructor(
    private _jobReportService: JobReportService,
    private _timestampService: TimestampService,
    private _userContactService: UserContactService,
    private _dateService: DateService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.job) {
      this.mapJobDispatchFields();
      this.getWorkerName();
      this.getStartTimeDisplay();
      this.getEndTimeDisplay();
    } else {
      this.workerName = this.defaultDisplay;
      this.startTimeDisplay = this.defaultDisplay;
      this.endTimeDisplay = this.defaultDisplay;
    }
  }

  getWorkerName() {
    let contact = this._userContactService.getUserContactByUserId(this.job.worker_id);
    this.workerName = contact ? contact.name : this.defaultDisplay;
  }

  getStartTimeDisplay() {
    this.startTimeDisplay = this.job.start_time ? 
    this._dateService.getDateDisplayWithKey(this.job.start_time, "JOB_DISPATCH_DETAIL_START_END_TIME") : this.defaultDisplay;
  }

  getEndTimeDisplay() {
    this.endTimeDisplay = this.job.end_time ? this._dateService.getDateDisplayWithKey(this.job.end_time, "JOB_DISPATCH_DETAIL_START_END_TIME") : this.defaultDisplay;
  }

  mapJobDispatchFields() {
    this.job.mappedCustomFields = this._jobReportService.getMappedReportFieldsByTypeIdAndAnswers(this.job.type_id, this.job.custom_fields, true);
  }

}
