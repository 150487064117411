import { Component, OnInit, Input } from '@angular/core';

import { Attachment } from '../../../models/attachment';

import { TeamnoteConfigService } from '../../../configs/teamnote-config.service';
import { AudioPlayerService } from '../../audio-player/audio-player.service';
import { FileFactoryService } from '../../file-factory/file-factory.service';

@Component({
  selector: 'tn-attachment-audio',
  templateUrl: './attachment-audio.component.html',
  styleUrls: ['./attachment-audio.component.scss']
})
export class AttachmentAudioComponent implements OnInit {
  @Input() attachment: Attachment;
  @Input() attachmentId: string;

  audioUrl: any;
  mimeType: string = "audio/mpeg";

  constructor(
    private _teamnoteConfigService: TeamnoteConfigService,
    private _audioPlayerService: AudioPlayerService,
    private _fileFactoryService: FileFactoryService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (!this.attachmentId) {
      return;
    }
    if (this._teamnoteConfigService.isBrowserIE || this._teamnoteConfigService.isBrowserFirefox) {
      this._audioPlayerService.convertAudioToMp3(
        this.attachmentId,
        (dataUrl) => {
          this.audioUrl = dataUrl;
          this.mimeType = "audio/mp3";
        }
      );
    } else {
      this._fileFactoryService.getFileByAttachmentId(
        this.attachmentId,
        (dataUrl) => {
          this.audioUrl = dataUrl;
        },
        (err) => {},
        false
      );
    }
  }

}

