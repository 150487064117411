import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export class TnLoaderState {
  isLoading: boolean;
  isShowSpinner: boolean;
  message: string;
  isShowingAlert: boolean;

  constructor(isLoading, isShowSpinner, message, isShowingAlert?) {
    this.isLoading = isLoading;
    this.isShowSpinner = isShowSpinner;
    this.message = message;
    this.isShowingAlert = isShowingAlert;
  }
}

@Injectable()
export class TnLoaderService {

  loader: TnLoaderState = new TnLoaderState(false, false, '', false);
  loader$: BehaviorSubject<TnLoaderState> = new BehaviorSubject<TnLoaderState>(this.loader);

  constructor() { 
  }

  showLoading() {
    this.loader = new TnLoaderState(true, false, '', this.loader.isShowingAlert);
    this.loader$.next(this.loader);
  }

  hideLoading() {
    if (this.loader.isShowSpinner) {
      return;
    }
    this.loader = new TnLoaderState(false, false, '', this.loader.isShowingAlert);
    this.loader$.next(this.loader);
  }

  showSpinner(msg?: string) {
    this.loader = new TnLoaderState(true, true, msg ? msg : '', this.loader.isShowingAlert);
    this.loader$.next(this.loader);
  }

  hideSpinner() {
    this.loader = new TnLoaderState(false, false, '', this.loader.isShowingAlert);
    this.loader$.next(this.loader);
  }

  showAlertHandling() {
    this.loader = new TnLoaderState(
      this.loader.isLoading,
      this.loader.isShowSpinner,
      this.loader.message,
      true
    );
    this.loader$.next(this.loader);
  }

  hideAlertHandling() {
    this.loader = new TnLoaderState(
      this.loader.isLoading,
      this.loader.isShowSpinner,
      this.loader.message,
      false
    );
    this.loader$.next(this.loader);
  }

}
