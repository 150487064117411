import { Component, OnInit, Input } from '@angular/core';
import { CronjobTracker, CronJobStatus, CronJobType, ExportAttachmenetCronJobResult, CronjobExportChatMessage } from '../../../cronjob-tracker/models/cronjob';
import { NotificationCenterService } from '../../services/notification-center.service';
import { FileManagerService } from '../../../file-manager/file-manager.service';

@Component({
  selector: 'tn-cronjob-notification',
  templateUrl: './cronjob-notification.component.html',
  styleUrls: ['./cronjob-notification.component.scss']
})
export class CronjobNotificationComponent implements OnInit {

  @Input() body: CronjobTracker;

  cronjobStatus = CronJobStatus;
  cronjobType = CronJobType;


  constructor(
    private _notificationCenterService: NotificationCenterService,
    private _fileManagerService: FileManagerService
  ) { }

  ngOnInit() {
    
  }

  onClick(): void {
    if (this.body.job.status == this.cronjobStatus.FINISHED) {
      this.download();
    }
  }

  download(): void {
    // Only try to download if filename is specified
    if (!this.body.jobInfo.fileName) {
      return;
    }
    let downloadResult: ExportAttachmenetCronJobResult = JSON.parse(this.body.job.result);

    this._fileManagerService.downloadFileByAttachmentId(downloadResult.attachment_id, this.body.jobInfo.fileName);

    this._notificationCenterService.closeNotificationCenter();
  }
}
