import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { WebclientLoginService } from "../webclient-login.service";
import { RegistrationStateConstant } from "../constants/registration-state.constant";
import { InputValidationService } from "../../utilities/input-validation/input-validation.service";
import { TnNotificationService } from "../../utilities/tn-notification/tn-notification.service";
import { LoginComponent } from "../login.component";
import { CustomLoginResponse } from "../models/custom-login-response";

@Component({
  selector: "tn-second-factor",
  templateUrl: "./second-factor.component.html",
  styleUrls: ["./second-factor.component.scss"]
})
export class SecondFactorComponent implements OnInit {

  secondFactor: string = "";
  isForgetPassword: boolean;
  @ViewChild('secondFactorInput', {static: true}) secondFactorInput: ElementRef;

  inputTipKey: string = "LOGIN.SECOND_FACTOR.{{key}}.INPUT_TIP";
  inputPlaceholderKey: string = "LOGIN.SECOND_FACTOR.{{key}}.TITLE";

  constructor(
    private _webclientLoginService: WebclientLoginService,
    private _inputValidationService: InputValidationService,
    private _tnNotificationService: TnNotificationService,
    private _loginComponent: LoginComponent
  ) {}

  ngOnInit() {
    let key = "";
    switch (this._webclientLoginService.secondFactorType) {
      case RegistrationStateConstant.REQUIRE.EMAIL:
        key = "EMAIL";
        break;
      case RegistrationStateConstant.REQUIRE.MOBILE:
        key = "MOBILE";
        break;
      case RegistrationStateConstant.REQUIRE.HKID:
        key = "HKID";
        break;
      case RegistrationStateConstant.REQUIRE.FULLNAME:
        key = "FULLNAME";
        break;
      case RegistrationStateConstant.REQUIRE.LDAP_PW:
        key = "LDAP_PW";
        break;
      case RegistrationStateConstant.REQUIRE.STAFF_ID:
        key = "STAFF_ID";
        break;
    }
    this.inputTipKey = this.inputTipKey.replace("{{key}}", key);
    this.inputPlaceholderKey = this.inputPlaceholderKey.replace("{{key}}", key);
    this.focusInputBox();
  }

  focusInputBox(): void {
    this.secondFactorInput.nativeElement.focus();
  }

  submit(): void {
    let isValid = this._inputValidationService.checkIsNotEmpty(this.secondFactor);
    if (!isValid) {
      this._tnNotificationService.showCustomWarningByTranslateKey("LOGIN.SECOND_FACTOR.FAIL_ERROR");
      return;
    }

    this._webclientLoginService.registrationInit(
      resp => {
        let response = this._webclientLoginService.handleRegistrationInitSuccessResponse(
          resp
        );
        this.handleCustomLoginResponse(response);
      },
      err => {
        let response = this._webclientLoginService.getGeneralSystemErrorResposne();
        this.handleCustomLoginResponse(response);
      },
      this.isForgetPassword,
      this.secondFactor
    );
  }

  handleCustomLoginResponse(resp: CustomLoginResponse) {
    if (!resp.isSuccess) {
      this._tnNotificationService.showCustomErrorByTranslateKey(resp.errorMsg);
    }
    if (resp.nextPageUrl) {
      this.toNextPage(resp.nextPageUrl);
      return;
    }
    this.focusInputBox();
  }

  toNextPage(nextPageUrl: string) {
    this._loginComponent.goToTargetPage(nextPageUrl);
  }
}
