import { Component, OnInit } from '@angular/core';
import { TrainingService } from './training.service';
import { TrainingFolder, TrainingFile } from './models/training';
import { TeamNoteTrainingConstant } from './constants/training.constant';
import { AttachmentService } from '../../utilities/attachment/attachment.service';

@Component({
  selector: 'tn-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit {

  rootFolder: TrainingFolder = null;

  currentFolder: TrainingFolder = null;

  constructor(
    private _trainingService: TrainingService,
    private _attachmentService: AttachmentService
  ) { }

  ngOnInit() {
    this.getTrainingList();
  }

  getTrainingList() {
    this._trainingService.getTrainingList(
      (data) => {
        this.rootFolder = this._trainingService.parseTrainingFilesIntoFolderTree(data);
        this.currentFolder = this.rootFolder;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onFolderBack(event) {
    this.currentFolder = this.rootFolder;
  }

  onItemClick(item: any) {
    if (item.type == TeamNoteTrainingConstant.TYPE.FOLDER) {
      this.onFolderClick(item);
    } else {
      this.onFileClick(item);
    }
  }

  onFolderClick(folder: TrainingFolder) {
    this.currentFolder = folder;
  }
  
  onFileClick(file: TrainingFile) {
    this._attachmentService.prepareAttachmentModalContentByFileId(file.file_ids.split(","));
  }


}
